import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  Grid,
  Modal,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { NavLink, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useEffect } from 'react';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import SearchIcon from '@mui/icons-material/Search';
import Success from '../../images/undraw/undraw_completed_tasks_vs6q.svg';
import ServerDown from '../../images/undraw/undraw_server_down_s-4-lk.svg';
import ReConfirm from '../../images/undraw/undraw_confirmation_re_b6q5.svg';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2564b8',
      darker: '#2564b8',
    },
  },
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  outline: 'none',
};

const AdminNotice = () => {
  const navigate = useNavigate();
  const adminInfo = secureLocalStorage.getItem('admin-info');
  const token = adminInfo?.token;
  const [updating, setUpdating] = useState(false);
  const [updateNotice, setUpdateNotice] = useState({});
  const [editNotice, setEditNotice] = useState(false);
  const handleOpen = (data) => {
    setUpdateNotice(data);
    setEditNotice(true);
  };
  const handleClose = () => setEditNotice(false);
  let agentId = adminInfo?.uid;
  const [search, setSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [allNotice, setAllNotice] = useState([]);
  const [notice, setNotice] = useState([]);
  // todo: pagination handle
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 20;

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://api-b2b.projectota.com/agent/notice/all`)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setAllNotice(data);
        setNotice(data);
        setPageCount(Math.ceil(data?.length / size));
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: 'Failed! to Fetch',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Try Another Time',
        }).then(() => {
          setIsLoading(false);
          navigate('/dashboard');
        });
      });
  }, [refetch]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearch(allNotice.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleUpdateData = (e) => {
    setUpdateNotice({
      ...updateNotice,
      [e.target.name]: e.target.value,
    });
  };

  const noticeDeleted = (id) => {
    const url = `https://api-b2b.projectota.com/admin/notice/${id}`;

    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
      title: 'You want to delete this notice?',
      showCancelButton: true,
      confirmButtonColor: 'red',
      confirmButtonText: 'Delete',
      cancelButtonColor: 'var(--primary-color)',
      cancelButtonText: 'Cancel',
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        try {
          const response = await fetch(url, {
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            Swal.fire({
              imageUrl: Success,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: 'Custom image',
              title: 'Notice Deleted!',
              confirmButtonColor: 'var(--primary-color)',
              confirmButtonText: 'Ok',
            }).then(() => {
              setRefetch(!refetch);
            });
          } else {
            throw new Error('Failed to delete notice');
          }
        } catch (err) {
          console.error(err);
          Swal.fire({
            imageUrl: ServerDown,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: 'Custom image',
            title: 'Failed!',
            confirmButtonColor: 'var(--primary-color)',
            confirmButtonText: 'Try Another Time',
          }).then(() => {
            setRefetch(!refetch);
          });
        }
      }
    });
  };

  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== '') {
      const filterData = allNotice.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setNotice(filterData);
    } else if (searchInput === '') {
      setNotice(allNotice);
    }
  };

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '24px',
                color: 'var(--mateBlack)',
              }}
            >
              Notices
            </Typography>

            <Box sx={{ display: 'flex', gap: '5px' }}>
              <Box
                sx={{
                  background: 'var(--primary-color)',
                  height: '35px',
                  borderRadius: '20px',
                  input: {
                    border: 'none',
                    background: 'none',
                    outline: 'none',
                    float: 'left',
                    padding: 0,
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 'normal',
                    transition: 'all 0.4s ease-in-out',
                    lineHeight: '35px',
                    width: '0px',
                  },
                  'input::placeholder': { color: 'white' },
                  '&:hover input': {
                    width: '235px',
                    padding: '0 10px',
                  },
                  '&:hover button': {
                    background: 'white',
                    color: 'black',
                  },
                  button: {
                    border: 'none',
                    color: 'white',
                    float: 'right',
                    minHeight: 'fit-content',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                    background: 'var(--primary-color)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: 'all 0.4s ease-in-out',
                    cursor: 'pointer',
                    textDecoration: 'none',
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Search....."
                  onChange={handelSearchItems}
                />
                <button>
                  <SearchIcon />
                </button>
              </Box>
              <Button
                onClick={() => navigate('/dashboard/addnotice')}
                style={{
                  color: '#fff',
                  background: 'var(--secondary-color)',
                  padding: '5px 20px',
                  borderRadius: '4px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                }}
              >
                Add Notice
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              mt: '5px',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '0px',
                background: 'var(--secondary-color)',
              },
              height: 'calc(100vh - 180px)',
              overflowY: 'scroll',
              table: {
                width: '100%',
                position: 'relative',
                borderCollapse: 'collapse',
              },
              th: {
                position: 'sticky',
                top: 0,
                zIndex: '999',
                background: 'var(--primary-color)',
                color: 'white',
                border: '1px solid #ddd',
                padding: '8px 12px',
                fontSize: '14px',
                fontWeight: '500',
              },
              td: {
                textAlign: 'center',
                verticalAlign: 'middle',
                border: '1px solid #ddd !important',
                padding: '5px !important',
                fontSize: '12px',
              },
              'tr:nth-child(even)': {
                background: '#f2f2f2',
              },
              'tr:hover': {
                background: '#ddd',
              },
            }}
          >
            {isLoading === false ? (
              <table>
                <tr>
                  <th>Notice</th>
                  <th>Create Time</th>
                  <th>Operation</th>
                </tr>

                <tbody>
                  {notice?.map((data, index) => (
                    <tr key={index}>
                      <td>{ReactHtmlParser(data?.notice)}</td>
                      <td>{data?.created_at}</td>
                      <td>
                        <DeleteIcon
                          style={{
                            color: 'tomato',
                            fontSize: '22px',
                            cursor: 'pointer',
                          }}
                          onClick={() => noticeDeleted(data?.uid)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '38vh',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              my: 1,
              py: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '8px',
              borderTop: '1px solid var(--primary-color)',
            }}
          >
            <Typography style={{ fontSize: '15px', color: '#222222' }}>
              Showing Results {page} - {pageCount}
            </Typography>
            <ThemeProvider theme={theme}>
              <Stack spacing={2}>
                <Pagination
                  size="small"
                  count={pageCount}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </ThemeProvider>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminNotice;
