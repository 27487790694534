import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Outlet, NavLink } from 'react-router-dom';
import TollIcon from '@mui/icons-material/Toll';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import MuiListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useAuthentication from '../../hooks/useAuthentication';
import './AdminDashboardSideBar.css';
import secureLocalStorage from 'react-secure-storage';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';

// Active color ListItem start

const ListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: 'var(--white)',
      color: 'var(--secondary-color)',
      width: '90%',
      borderRadius: '0 5px 5px 0',
      '& .MuiListItemIcon-root': {
        color: 'var(--secondary-color)',
      },
    },
    '&$selected:hover': {
      backgroundColor: 'var(--white)',
      color: 'var(--secondary-color)',
      width: '90%',
      borderRadius: '0 5px 5px 0',
      '& .MuiListItemIcon-root': {
        color: 'var(--secondary-color)',
      },
    },
  },
  selected: {},
})(MuiListItem);

const SubListItem = withStyles({
  subselected: {},
})(MuiListItem);

// Active color ListItem end

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 20px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const AdminDashboardSideBar = () => {
  const user = secureLocalStorage.getItem('admin-info');
  const admin = user?.role === 'Super admin';
  const staff = user?.role === 'Staff';
  const accountant = user?.role === 'Accountant';

  const [open, setOpen] = useState(false);
  const [dashBoard, setDashBoard] = useState(false);
  const [manage, setManage] = useState(false);
  const [agent, setAgent] = useState(false);
  const [booking, setBooking] = useState(false);
  const [payment, setPayment] = useState(false);
  const [account, setAccount] = useState(false);

  const { logout } = useAuthentication();

  //   -------------------submenu start here ----------------

  const [selectedIndex, setSelectedIndex] = useState();
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index === 0) {
      setOpen(true);
      setDashBoard(true);
      setManage(false);
      setAgent(false);
      setBooking(false);
      setPayment(false);
    } else if (index === 1) {
      setOpen(true);
      setDashBoard(false);
      setManage(true);
      setAgent(false);
      setBooking(false);
      setPayment(false);
    } else if (index === 2) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setAgent(false);
      setBooking(false);
      setPayment(false);
    } else if (index === 3) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setAgent(true);
      setBooking(false);
      setPayment(false);
    } else if (index === 4) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setAgent(false);
      setBooking(true);
      setPayment(false);
    } else if (index === 5) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setAgent(false);
      setBooking(false);
      setPayment(true);
    } else if (index === 6) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setAgent(false);
      setBooking(false);
      setPayment(false);
      setAccount(true);
    }
  };

  //   -------------------

  return (
    <Box sx={{ display: 'flex' }} className="admin-sideBar-user">
      <CssBaseline />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{
            display: 'block',
            backgroundColor: 'var(--secondary-color)',
          }}
        >
          <Box onClick={() => setOpen(!open)}>
            <MenuIcon
              style={{
                color: 'var(--white)',
                fontSize: '28px',
                margin: '20px 15px 10px 15px',
                cursor: 'pointer',
              }}
            />
          </Box>
        </DrawerHeader>

        <Box backgroundColor="var(--secondary-color)" height="100vh">
          <List>
            {/* //todo: dashboard */}

            <NavLink
              to="/dashboard"
              style={{ display: 'block', marginBottom: '10px' }}
            >
              <ListItem
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
                style={{ padding: '0px' }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    p: '5px 3px 5px 20px',
                  }}
                >
                  <DashboardIcon />
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      ml: 1,
                    }}
                  >
                    Dashboard
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            {/* //todo: Manage */}
            {admin && (
              <NavLink
                to="notice"
                style={{ display: 'block', marginBottom: '10px' }}
              >
                <ListItem
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                  style={{ padding: '0px' }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      p: '5px 3px 5px 20px',
                    }}
                  >
                    <CircleNotificationsIcon />
                    <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                      Notice
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            )}
            {/* 
            {selectedIndex === 1 && manage && (
              <Box
                className="admin-dash-sub-menu"
                style={{ display: open === false ? 'none' : '' }}
              >
                <Box sx={{ opacity: open ? 1 : 0 }}>
                  <NavLink to="markup" style={{ display: 'block' }}>
                    <SubListItem style={{ padding: '0px' }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? 'initial' : 'center',
                          p: '0px 3px 0px 18px',
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Mark Up
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink to="notice" style={{ display: 'block' }}>
                    <SubListItem style={{ padding: '0px' }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? 'initial' : 'center',
                          p: '0px 3px 0px 18px',
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Notice
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink to="/admin/hotdeal" style={{ display: 'block' }}>
                    <SubListItem style={{ padding: '0px' }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? 'initial' : 'center',
                          p: '0px 3px 0px 18px',
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Hot Deal
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink to="/admin/offer" style={{ display: 'block' }}>
                    <SubListItem style={{ padding: '0px' }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? 'initial' : 'center',
                          p: '0px 3px 0px 18px',
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Offer
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                </Box>
              </Box>
            )} */}

            {/* <NavLink
              to="/admin/license"
              style={{ display: 'block', marginBottom: '10px' }}
            >
              <ListItem
                selected={selectedIndex === 8}
                onClick={(event) => handleListItemClick(event, 8)}
                style={{ padding: '0px' }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    p: '5px 3px 5px 20px',
                  }}
                >
                  <SecurityIcon />
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      ml: 1,
                    }}
                  >
                    D. License
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              to="/admin/visa"
              style={{ display: 'block', marginBottom: '10px' }}
            >
              <ListItem
                selected={selectedIndex === 9}
                onClick={(event) => handleListItemClick(event, 9)}
                style={{ padding: '0px' }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    p: '5px 3px 5px 20px',
                  }}
                >
                  <ArticleIcon />
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      ml: 1,
                    }}
                  >
                    Visa
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink> */}
            {/* //todo: Agent */}
            {admin && (
              <NavLink
                to="agentmanagement"
                style={{ display: 'block', marginBottom: '10px' }}
              >
                <ListItem
                  selected={selectedIndex === 3}
                  onClick={(event) => handleListItemClick(event, 3)}
                  style={{ padding: '0px' }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      p: '5px 3px 5px 20px',
                    }}
                  >
                    <ManageAccountsIcon />
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        ml: 1,
                      }}
                    >
                      Agent
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            )}

            {/* //todo: Booking */}
            <NavLink
              to="allbooking"
              style={{ display: 'block', marginBottom: '10px' }}
            >
              <ListItem
                selected={selectedIndex === 10}
                onClick={(event) => handleListItemClick(event, 10)}
                style={{ padding: '0px' }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    p: '5px 3px 5px 20px',
                  }}
                >
                  <TollIcon />
                  <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                    All Booking
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
            {selectedIndex === 10 && booking && (
              <Box
                className="admin-dash-sub-menu"
                style={{ display: open === false ? 'none' : '' }}
              >
                <Box sx={{ opacity: open ? 1 : 0 }}>
                  <NavLink to="bookingroute" style={{ display: 'block' }}>
                    <SubListItem style={{ padding: '0px' }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? 'initial' : 'center',
                          p: '0px 3px 0px 18px',
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Air Ticket
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                </Box>
              </Box>
            )}
            {/* //todo: Payment */}
            {!staff && (
              <NavLink
                to="paymentmanagement"
                style={{ display: 'block', marginBottom: '10px' }}
              >
                <ListItem
                  selected={selectedIndex === 5}
                  onClick={(event) => handleListItemClick(event, 5)}
                  style={{ padding: '0px' }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      p: '5px 3px 5px 20px',
                    }}
                  >
                    <PriceCheckIcon />
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        ml: 1,
                      }}
                    >
                      Agent Deposits
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            )}

            {/* //todo: Account */}
            {admin && (
              <NavLink
                to="/dashboard/account"
                style={{ display: 'block', marginBottom: '10px' }}
              >
                <ListItem
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6)}
                  style={{ padding: '0px' }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      p: '5px 3px 5px 20px',
                    }}
                  >
                    <TollIcon />
                    <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                      Account
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            )}

            {selectedIndex === 6 && account && (
              <Box
                className="admin-dash-sub-menu"
                style={{ display: open === false ? 'none' : '' }}
              >
                <Box sx={{ opacity: open ? 1 : 0 }}>
                  <NavLink to="account" style={{ display: 'block' }}>
                    <SubListItem style={{ padding: '0px' }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? 'initial' : 'center',
                          p: '0px 3px 0px 18px',
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          User Account
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>

                  <NavLink to="allbank" style={{ display: 'block' }}>
                    <SubListItem style={{ padding: '0px' }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? 'initial' : 'center',
                          p: '0px 3px 0px 18px',
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Bank Account
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                </Box>
              </Box>
            )}

            <NavLink to="/" style={{ display: 'block', marginTop: '60%' }}>
              <ListItem
                selected={selectedIndex === 7}
                onClick={logout}
                style={{ padding: '0px' }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    p: '5px 3px 5px 20px',
                  }}
                >
                  <ExitToAppIcon />
                  <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                    Log Out
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
          </List>
        </Box>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet></Outlet>
      </Box>
    </Box>
  );
};

export default AdminDashboardSideBar;
