/* eslint-disable no-restricted-globals */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Container } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Box, Grid, Button, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { addDays } from 'date-fns/esm';
import { format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import secureLocalStorage from 'react-secure-storage';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Search from '../../images/undraw/undraw_web_search_re_efla.svg';
import SingleFlight from '../../components/SingleFlight/SingleFlight';
import SessionTimer from '../../components/Shared/SessionTimer/SessionTimer';
import FlightSearchBox from '../../components/FlightSearchBox/FlightSearchBox';
import Header from '../../components/Header/Header';

import '../SearchReslut/SearchResult.css';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import FilterLoader from '../../components/Loader/FilterLoader';
import ResultLoader from '../../components/Loader/ResultLoader';
import CustomPagination from './CustomPagination';
import { debounce } from 'lodash';
import HeaderSlider from '../../components/AirlineSlider/HeaderSlider';
import AirlinesFilter from '../../components/AirlinesFilter/AirlinesFilter';

const modalStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { lg: 'fit-content', md: 'fit-content', sm: 'fit-content', xs: '95%' },
  height: 'fit-content',
  padding: { xs: '5px', sm: '10px', md: '20px' },
  background: '#fff',
  borderRadius: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  overFlowY: 'scroll',
};

const SearchResult = () => {
  const [quotetionArr, setQuotetionArr] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [filterPageIndex, setFilterPageIndex] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [selectedStops, setSelectedStops] = useState([]);
  const [selectedAirlins, setSelectedAirlins] = useState([]);
  const [selectedRefundable, setSelectedRefundable] = useState([]);
  const [selectedDepartTime, setSelectedDepartTime] = useState({
    name: '',
    type: '',
    startTime: '',
    endTime: '',
  });
  const [selectedArrivalTime, setSelectedArrivalTime] = useState({
    name: '',
    type: '',
    startTime: '',
    endTime: '',
  });
  const [selectedBackDepartTime, setSelectedBackDepartTime] = useState({
    name: '',
    type: '',
    startTime: '',
    endTime: '',
  });
  const [selectedBackArrivalTime, setSelectedBackArrivalTime] = useState({
    name: '',
    type: '',
    startTime: '',
    endTime: '',
  });

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [selectedLayover, setSelectedLayover] = useState([]);

  // --------------new Sate end --------------------------------
  // --------------functionality start  --------------------------------

  const handleResetData = useCallback(() => {
    setPageIndex(0);
    setFilterPageIndex(null);
    setSelectedProvider([]);
    setSelectedAirlins([]);
    setSelectedStops([]);
    setSelectedRefundable([]);
    setSelectedDepartTime({ name: '', type: '', startTime: '', endTime: '' });
    setSelectedArrivalTime({ name: '', type: '', startTime: '', endTime: '' });
    setSelectedBackDepartTime({
      name: '',
      type: '',
      startTime: '',
      endTime: '',
    });
    setSelectedBackArrivalTime({
      name: '',
      type: '',
      startTime: '',
      endTime: '',
    });
    setSelectedLayover([]);
    setFilterData(data);
  }, [data]);
  // --------------func end --------------------------------

  const searchData = secureLocalStorage.getItem('search-data');
  const user = secureLocalStorage.getItem('user-info');
  const uid = user?.uid;
  const navigate = useNavigate();
  const location = useLocation();

  const requiredSearchData = location.state;

  const {
    toSendData,
    adultCount,
    childCount,
    departureDate,
    returningDate,
    infant,
    tripType,
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    fromSendData,
    className,
    directFlightOnly,
    kids,
    brandedFare,
    extraBaggage,
    currency,
    connectionValue,
    airlineValue,
    nonStop,
  } = requiredSearchData;

  const [changeState, setChangeState] = useState(
    requiredSearchData?.changeState
  );

  const [multiCitySearchData, setMultiCitySearchData] = useState({
    adultcount: adultCount,
    childcount: childCount,
    infantcount: infant,
    connection: 1,
    cabinclass: className || 'Y',
    kidscount: kids,
    currency,
    brandedfare: brandedFare || true,
    extrabaggage: extraBaggage || true,
    directflight: nonStop || false,
    airline: airlineValue || '',
    segments: [
      {
        id: 0,
        openFrom: false,
        depfrom: fromSendData.trim(),
        depFromText: fromSearchText.trim(),
        arrto: 'DXB',
        arrToText: 'Dubai Intl Airport [ DXB ]',
        openTo: false,
        depdate: new Date().toLocaleDateString('sv'),
        openDate: false,
        open: false,
      },
    ],
  });

  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change

  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });

  //todo: End for retrigger useEffect
  const [changeStateSession, setChangeStateSession] = useState(null);
  const [type, setType] = React.useState('flight');
  const [value, setValue] = React.useState(tripType);
  const [oneWayFromSearchText, setOneWayFromSearchText] =
    useState(fromSearchText);
  const [oneWayToSearchText, setOneWayToSearchText] = useState(toSearchText);

  const now = useRef(new Date(departureDate));
  const [from, setFrom] = useState(now.current);
  const [to, setTo] = useState(addDays(now.current, 3));
  const [fromSearchDate, setFromSearchDate] = useState(new Date(departureDate));
  const [oneWayFaddress, setOneWayFaddress] = useState(faddress);
  const [oneWayToAddress, setOneWayToAddress] = useState(toAddress);
  const [oneWayFromSendData, setOneWayFromSendData] = useState(fromSendData);
  const [oneWayToSendData, setOneWayToSendData] = useState(toSendData);
  const [oneWayAdultCount, setOneWayAdultCount] = useState(adultCount);
  const [oneWayChildCount, setOneWayChildCount] = useState(childCount);
  const [oneWayInfant, setOneWayInfant] = useState(infant);
  const [result, setResult] = useState(adultCount + childCount + infant);
  const [oneWayClassName, setOneWayClassName] = useState(className);
  const [isPrevClicked, setIsPrevCliked] = useState(false);
  const [isNextClicked, setIsNextCliked] = useState(false);
  const [directFlight, setDirectFlight] = useState(directFlightOnly);
  //end

  //CM Box States

  const [agentFarePrice, setAgentFarePrice] = useState(true);
  const [commisionFarePrice, setCommisionFarePrice] = useState(true);

  const [customerFare, setCustomerFare] = useState(true);

  const [modifyOpen, setModifyOpen] = useState(false);
  const modifyHandleOpen = () => setModifyOpen(true);
  const modifyHandleClose = () => setModifyOpen(false);
  const [searchDate, setSearchDate] = useState(
    new Date(from).toLocaleDateString('sv')
  );
  const [data2, setData2] = useState([]);

  // todo:next day previous day variables
  let tomorrow = new Date(fromSearchDate);
  tomorrow.setDate(tomorrow.getDate() + 1);
  let yesterday = new Date(fromSearchDate);
  yesterday.setDate(yesterday.getDate() - 1);

  // Sets the state of the const for the given page and state.
  const [setPage] = useState(1);
  let size = 30;

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setData2(data?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  //todo Searches for flights today's date.
  // useEffect(() => {
  //   let unSubscribed = false;
  //   setIsLoaded(false);
  //   modifyHandleClose();
  //   let url = `https://api-b2b.projectota.com/agent/flight/search/${uid}`;
  //   // let body = {
  //   //   adultcount: adultCount,
  //   //   childcount: childCount,
  //   //   infantcount: infant,
  //   //   connection: directFlightOnly ? 1 : '',
  //   //   cabinclass: oneWayClassName || 'Y',
  //   //   segments: [
  //   //     {
  //   //       depfrom: `${fromSendData?.replace(/\s+/g, '')}`,
  //   //       arrto: toSendData,
  //   //       depdate: `${new Date(departureDate).toLocaleDateString('sv')}`,
  //   //     },
  //   //   ],
  //   // };
  //   let body={

  //  adultcount: adultCount,
  //     childcount: childCount,
  //     infantcount: infant,
  //     kidscount:kids,
  //     connection: connectionValue,
  //     currency,
  //     brandedfare:brandedFare,
  //     extrabaggage:extraBaggage,
  //     airline:airlineValue,
  //     cabinclass: oneWayClassName || 'Y',
  //     directflight:nonStop,
  //     segments: [
  //       {
  //         depfrom: `${fromSendData?.replace(/\s+/g, '')}`,
  //         arrto: toSendData,
  //         depdate: `${new Date(departureDate).toLocaleDateString('sv')}`,
  //       },
  //     ],

  //     // brandedFare,
  //     // extraBaggage,
  //     // currency,
  //     // connectionValue,
  //     // airlineValue,

  //   }

  //   fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(body),
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })

  //     .then((data) => {
  //       console.log(data)
  //       if (!unSubscribed) {
  //         if (data.length !== 0) {
  //           const uniqueData = data;
  //           // setPageCount(pageNumber);
  //           setData(uniqueData);
  //           setFilterData(uniqueData);
  //           setData2(uniqueData);
  //           setIsLoaded(true);
  //         } else {
  //           throw new Error();
  //         }
  //       }
  //     })
  //     .catch(async (err) => {
  //       await Swal.fire({
  //         imageUrl: Search,
  //         imageWidth: 400,
  //         imageHeight: 200,
  //         imageAlt: 'Custom image',
  //         title: 'No Flights Found',
  //         confirmButtonText: 'Search Again...',
  //         confirmButtonColor: 'var(--primary-color)',
  //       }).then(function () {
  //         navigate(-1);
  //       });
  //     });
  //   return () => {
  //     unSubscribed = true;
  //   };
  // }, [
  //   changeState,
  //   changeStateSession,
  //   size,
  //   departureDate,
  //   fromSendData,
  //   toSendData,
  //   adultCount,
  //   childCount,
  //   infant,
  //   tripType,
  //   requiredSearchData,
  //   navigate,
  // ]);

  console.log(filterData);

  useEffect(() => {
    setIsLoaded(false);
    let body = {
      adultcount: adultCount,
      childcount: childCount,
      infantcount: infant,
      kidscount: kids,
      connection: connectionValue,
      currency,
      brandedfare: brandedFare || true,
      extrabaggage: extraBaggage || true,
      directflight: nonStop || false,
      airline: airlineValue || '',
      cabinclass: oneWayClassName || 'Y',
      segments: [
        {
          depfrom: `${fromSendData?.replace(/\s+/g, '')}`,
          arrto: toSendData,
          depdate: `${new Date(departureDate).toLocaleDateString('sv')}`,
        },
      ],

      //     // brandedFare,
      //     // extraBaggage,
      //     // currency,
      //     // connectionValue,
      //     // airlineValue,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api-b2b.projectota.com/agent/flight/search/${uid}`,
          {
            method: 'POST', // specifying the method
            headers: {
              'Content-Type': 'application/json', // setting the headers if needed
              // add other headers if necessary
            },
            body: JSON.stringify(body),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.length > 0) {
          setData(data);
          setFilterData(data);
          setData2(data);
          setIsLoaded(true);
        }
        // handle the data
      } catch (error) {
        console.error('Error fetching data:', error);
        Swal.fire({
          imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          confirmButtonText: 'Search Again...',
          confirmButtonColor: 'var(--primary-color)',
        }).then(function () {
          navigate(-1);
        });
      }
    };

    fetchData();
  }, [
    changeState,
    changeStateSession,
    size,
    departureDate,
    fromSendData,
    toSendData,
    adultCount,
    childCount,
    infant,
    tripType,
    requiredSearchData,
    navigate,
    uid,
  ]);

  useEffect(() => {
    handleResetData();
  }, [data]);

  // ----------------Functionality start ----------------
  // uniqueLayover
  const uniqueLayover = Array.from(
    new Set(
      data
        ?.filter((item) => item.AllLegsInfo[0].Segments.length > 1)
        .map((item) => ({
          name:
            item.AllLegsInfo[0].Segments.length > 2
              ? item.AllLegsInfo[0].Segments[2].DepAirPort
              : item.AllLegsInfo[0].Segments[1].DepAirPort,
          code:
            item.AllLegsInfo[0].Segments.length > 2
              ? item.AllLegsInfo[0].Segments[2].DepFrom
              : item.AllLegsInfo[0].Segments[1].DepFrom,
        }))
        .map((entry) => JSON.stringify(entry))
    )
  ).map((str) => JSON.parse(str));
  console.log(uniqueLayover);
  // ----------------filter-------------

  const handleSelection = (name, setSelected) => {
    setSelected((prevSelected) => {
      const isSelected = prevSelected.includes(name);
      return isSelected
        ? prevSelected.filter((item) => item !== name)
        : [...prevSelected, name];
    });

    setFilterPageIndex(name); // 2 or 3.. move page after filter setdata
  };

  const handleProvider = (name) => {
    handleSelection(name, setSelectedProvider);
  };
  const handleStops = (name) => {
    handleSelection(name, setSelectedStops);
  };
  const handleAirLine = (name) => {
    handleSelection(name, setSelectedAirlins);
  };
  const handleRefundable = (name) => {
    handleSelection(name, setSelectedRefundable);
  };
  // Date Filter
  const handleDepartTime = useCallback(
    (type, name, startTime, endTime) => {
      const startDate = new Date(`1970-01-01T${startTime}`);
      const endDate = new Date(`1970-01-01T${endTime}`);
      switch (type) {
        case 'Depart':
          setSelectedDepartTime(
            name === selectedDepartTime.name
              ? { name: '', type: '', startTime: '', endTime: '' }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        case 'Arrival':
          setSelectedArrivalTime(
            name === selectedArrivalTime.name
              ? { name: '', type: '', startTime: '', endTime: '' }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        // for roundway
        case 'returnDepart':
          setSelectedBackDepartTime(
            name === selectedBackDepartTime.name
              ? { name: '', type: '', startTime: '', endTime: '' }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        case 'returnArrival':
          setSelectedBackArrivalTime(
            name === selectedBackArrivalTime.name
              ? { name: '', type: '', startTime: '', endTime: '' }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        default:
          break;
      }
    },
    [
      selectedDepartTime,
      selectedArrivalTime,
      selectedBackDepartTime,
      selectedBackArrivalTime,
    ]
  );

  const handleLayover = (name) => {
    handleSelection(name, setSelectedLayover);
  };
  const filter = () => {
    let updateData = data;

    if (selectedProvider.length > 0) {
      updateData = updateData.filter((item) =>
        selectedProvider.includes(item.System)
      );
    }
    if (selectedStops.length > 0) {
      updateData = updateData.filter((item) =>
        selectedStops.includes(item.AllLegsInfo[0].Segments.length)
      );
    }
    if (selectedAirlins.length > 0) {
      updateData = updateData.filter((item) =>
        selectedAirlins.includes(item.Carrier)
      );
    }
    if (selectedRefundable.length > 0) {
      updateData = updateData.filter((item) =>
        selectedRefundable.includes(item.Refundable)
      );
    }

    if (
      selectedDepartTime.type === 'Depart' &&
      selectedDepartTime.name.length > 0
    ) {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[0][0].DepTime.slice(
            11,
            19
          )}`
        );
        return (
          time >= selectedDepartTime.startTime &&
          time <= selectedDepartTime.endTime
        );
      });
    }

    if (selectedArrivalTime.type === 'Arrival') {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[0][
            item.AllLegsInfo[0].Segments[0].length - 1
          ].ArrTime.slice(11, 19)}`
        );
        return (
          time >= selectedArrivalTime.startTime &&
          time <= selectedArrivalTime.endTime
        );
      });
    }

    if (selectedBackDepartTime.type === 'returnDepart') {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[
            item.AllLegsInfo[0].Segments.length - 1
          ][
            item.AllLegsInfo[0].Segments[
              item.AllLegsInfo[0].Segments.length - 1
            ].length - 1
          ].DepTime.slice(11, 19)}`
        );
        return (
          time >= selectedBackDepartTime.startTime &&
          time <= selectedBackDepartTime.endTime
        );
      });
    }

    if (selectedBackArrivalTime.type === 'returnArrival') {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[
            item.AllLegsInfo[0].Segments.length - 1
          ][
            item.AllLegsInfo[0].Segments[
              item.AllLegsInfo[0].Segments.length - 1
            ].length - 1
          ].ArrTime.slice(11, 19)}`
        );
        return (
          time >= selectedBackArrivalTime.startTime &&
          time <= selectedBackArrivalTime.endTime
        );
      });
    }

    if (selectedLayover.length > 0) {
      updateData = updateData.filter((item) => {
        const segments = item.AllLegsInfo[0].Segments;
        const DepFrom =
          (segments.length === 2 && segments[1].DepFrom) ||
          (segments.length > 2 && segments[2].DepFrom);
        return selectedLayover.includes(DepFrom);
      });
    }
    setFilterData(updateData);
  };

  const itemsPerPage = innerWidth > 600 ? 20 : 10;
  const startIndex = filterPageIndex ? 0 : pageIndex;

  const pageData =
    isLoaded &&
    (filterData || []).slice(
      startIndex * itemsPerPage,
      startIndex * itemsPerPage + itemsPerPage
    );

  const gotoPage = useCallback((value) => {
    setFilterPageIndex(0);
    setPageIndex(value);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  const pageLength = innerWidth > 600 ? 20 : 10;
  const pageCount = useMemo(
    () => Math.ceil(filterData.length / pageLength),
    [filterData.length]
  );
  const canPreviousPage = useMemo(() => pageIndex > 0, [pageIndex]);
  const canNextPage = useMemo(
    () => pageIndex < pageCount - 1,
    [pageIndex, pageCount]
  );

  const debouncedFilter = debounce(filter, 300);
  useEffect(() => {
    debouncedFilter();
    return () => {
      debouncedFilter.cancel();
    };
  }, [
    selectedProvider,
    selectedRefundable,
    selectedAirlins,
    selectedLayover,
    selectedStops,
    selectedDepartTime,
    selectedArrivalTime,
    selectedBackDepartTime,
    selectedBackArrivalTime,
    data,
  ]);

  // slider shorting
  const uniqueCarriers = Array.from(
    new Set(data.filter((item) => item.Carrier).map((item) => item.Carrier))
  );
  const flightresults = uniqueCarriers.map((carrier) => {
    const filteredData = data.filter((item) => item.Carrier === carrier);
    const minPrice = Math.min(
      ...filteredData.map((item) => parseInt(item.GrossFare))
    );
    const carrierName = filteredData[0].CarrierName;
    // change this property after add
    return {
      code: carrier,
      name: carrierName,
      price: minPrice.toString(),
    };
  });
  const handleBox = useCallback(
    (data) => {
      setQuotetionArr([]);
    },
    [quotetionArr]
  ); // The empty dependency array ensures that the callback is only created once

  const totalFlight = data?.length;
  return (
    <Box>
      {/* <Header /> */}
      <Box mt={{ xs: 12, sm: 10, md: 2 }}>
        <Container sx={{ position: 'relative', px: { xs: 1, sm: 2 } }}>
          <Grid container justifyContent="space-between" columnSpacing={2}>
            <Grid
              item
              sx={{
                p: 0,
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'none',
                  lg: 'block',
                },
              }}
              md={2.7}
            >
              {isLoaded ? (
                <AirlinesFilter
                  flightData={data}
                  tripType={tripType}
                  selectedProvider={selectedProvider}
                  handleProvider={handleProvider}
                  handleRefundable={handleRefundable}
                  handleAirLine={handleAirLine}
                  selectedAirlins={selectedAirlins}
                  handleStops={handleStops}
                  selectedStops={selectedStops}
                  selectedRefundable={selectedRefundable}
                  selectedLayover={selectedLayover}
                  handleDepartTime={handleDepartTime}
                  selectedDepartTime={selectedDepartTime}
                  selectedArrivalTime={selectedArrivalTime}
                  selectedBackDepartTime={selectedBackDepartTime}
                  selectedBackArrivalTime={selectedBackArrivalTime}
                  handleLayover={handleLayover}
                  uniqueLayover={uniqueLayover}
                  handleResetData={handleResetData}
                  uniqueCarriers={flightresults}
                />
              ) : (
                <FilterLoader />
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={9.3}>
              <Box
                sx={{
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                  padding: '24px',
                  borderRadius: '10px',
                }}
              >
                <Box
                  sx={{
                    // display: 'flex',
                    // alignItems: 'center',

                    bgcolor: 'var(--bgcolor)',
                    overflow: 'hidden',
                    px: { xs: 1, md: 2 },
                    py: { xs: 1, md: 1.5 },
                    mb: 2,
                    borderRadius: '5px',
                  }}
                >
                  <Grid xs={12} item container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        sx={{
                          color: 'var(--secondary)',
                          fontSize: '13px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {format(new Date(fromSearchDate), 'dd MMM yyyy')} (
                        {fromSendData}&nbsp;
                        <TrendingFlatIcon
                          sx={{ color: 'var(--secondary)', fontSize: 16 }}
                        />
                        &nbsp;
                        {toSendData})
                      </Typography>
                      <Typography
                        sx={{
                          color: 'var(--secondary-color)',
                          fontSize: '13px',
                        }}
                      >
                        Total passenger{' '}
                        {adultCount > 0 && `Adult(${adultCount})`}
                        {childCount > 0 && `Child(${childCount})`}
                        {infant > 0 && `Infant(${infant})`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'space-between', md: 'end' },
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      {/* //todo:session timer  */}
                      <Tooltip title="Session Time">
                        <Button
                          size="small"
                          style={{
                            width: 'fit-content',
                            border: '1.2px solid var(--primary-color)',
                            color: 'var(--primary-color)',
                          }}
                        >
                          <SessionTimer />
                        </Button>
                      </Tooltip>
                      <Button
                        size="small"
                        onClick={modifyHandleOpen}
                        style={{
                          backgroundColor: 'var(--primary-color)',
                          color: 'var(--white)',
                          padding: '5px 10px',
                          cursor: 'pointer',
                          fontSize: 14,
                          textTransform: 'capitalize',
                        }}
                      >
                        Modify Search
                      </Button>
                      <Box>
                        <Modal open={modifyOpen} onClose={modifyHandleClose}>
                          <Container>
                            <Box sx={modalStyle}>
                              <FlightSearchBox
                                options={options}
                                setOptions={setOptions}
                                type={type}
                                setType={setType}
                                value={value}
                                setValue={setValue}
                                fromSearchText={oneWayFromSearchText}
                                setFromSearchText={setOneWayFromSearchText}
                                toSearchText={oneWayToSearchText}
                                setToSearchText={setOneWayToSearchText}
                                from={from}
                                setFrom={setFrom}
                                to={to}
                                setTo={setTo}
                                faddress={oneWayFaddress}
                                setfaddress={setOneWayFaddress}
                                toAddress={oneWayToAddress}
                                setToAddress={setOneWayToAddress}
                                fromSendData={oneWayFromSendData}
                                setFromSendData={setOneWayFromSendData}
                                toSendData={oneWayToSendData}
                                setToSendData={setOneWayToSendData}
                                adultCount={oneWayAdultCount}
                                setAdultCount={setOneWayAdultCount}
                                childCount={oneWayChildCount}
                                setChildCount={setOneWayChildCount}
                                infant={oneWayInfant}
                                setInfant={setOneWayInfant}
                                result={result}
                                setResult={setResult}
                                className={oneWayClassName}
                                setClassName={setOneWayClassName}
                                changeFrom={changeFrom}
                                setChangeFrom={setChangeFrom}
                                searchData={multiCitySearchData}
                                setSearchData={setMultiCitySearchData}
                                directFlightOnly={directFlight}
                                setDirectFlightOnly={setDirectFlight}
                                changeState={changeState}
                                setChangeState={setChangeState}
                                // handleSearch={handleSearch}
                              />
                            </Box>
                          </Container>
                        </Modal>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  {/* //todo: show search result section*/}

                  {/* //todo:main search result */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {isLoaded ? (
                      <Box>
                        <HeaderSlider
                          uniqueCarriers={flightresults}
                          selectedAirlins={selectedAirlins}
                          handleAirLine={handleAirLine}
                        />
                        {!filterData?.length > 0 ? (
                          <Box
                            sx={{
                              height: '50Vh',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: 'var(--crimson)',
                            }}
                          >
                            No Flight Available
                          </Box>
                        ) : (
                          <Box mt={2}>
                            {pageData.map((data, index) => {
                              return (
                                <SingleFlight
                                  indexValue={index}
                                  pageData={pageData}
                                  key={index}
                                  flightData={data}
                                  tripType={tripType}
                                  adultCount={adultCount}
                                  childCount={childCount}
                                  infant={infant}
                                  from={fromSendData}
                                  to={toSendData}
                                  fromAddress={faddress}
                                  toAddress={toAddress}
                                  dDate={searchDate}
                                  agentFarePrice={agentFarePrice}
                                  setAgentFarePrice={setAgentFarePrice}
                                  commisionFarePrice={commisionFarePrice}
                                  setCommisionFarePrice={setCommisionFarePrice}
                                  customerFare={customerFare}
                                  setCustomerFare={setCustomerFare}
                                  quotetionArr={quotetionArr}
                                  setQuotetionArr={setQuotetionArr}
                                />
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <ResultLoader count={8} />
                    )}
                  </Grid>
                  {/* //todo: pagination*/}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        width: '100%',
                        my: 3,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Box sx={{ mt: 2 }}>
                        <CustomPagination
                          pageIndex={pageIndex}
                          pageCount={pageCount}
                          gotoPage={gotoPage}
                          canPreviousPage={canPreviousPage}
                          canNextPage={canNextPage}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box>
        {quotetionArr?.length ? (
          <Box
            sx={{
              position: 'fixed',
              bottom: '0',
              width: '100%',
              background: 'var(--secondary-color)',
              padding: '10px 0px',
              boxShadow: '0px -1px 5px 0px rgba(0,0,0,0.75)',
            }}
          >
            <Container>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  size="small"
                  onClick={() => {
                    navigate('/user/quotationsend', {
                      state: {
                        quotetionArr,
                        adultCount,
                        childCount,
                        infant,
                        tripType,
                      },
                    });
                  }}
                  sx={{
                    textTransform: 'capitalize',
                    background: 'var(--primary-color)',
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: '500',
                    padding: '5px 10px',
                    '&:hover': {
                      background: 'var(--primary-color)',
                    },
                  }}
                >
                  Make Quotation {`(${quotetionArr?.length}) Selected`}
                </Button>
              </Box>
              {/* <Box>
                <CancelIcon
                  sx={{
                    color: 'crimson',
                  }}
                  onClick={() => handleBox()}
                />
              </Box> */}
            </Container>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default SearchResult;
