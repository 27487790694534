import {
  Box,
  Button,
  Container,
  Pagination,
  Typography,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'date-fns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import commaNumber from 'comma-number';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2564b8',
      darker: '#2564b8',
    },
  },
});

const AgentBooking = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [queuesData, setQueuesData] = useState([]);
  const [allQueuesData, setAllQueuesData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const size = 15;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    fetchQueuesData();
  }, []);

  const fetchQueuesData = () => {
    setIsLoading(true);
    fetch(`https://api-b2b.projectota.com/agent/booking/${uid}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        const count = data?.length || 0;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setQueuesData(data);
        setAllQueuesData(data);
      })
      .catch((error) => console.error('Error fetching data:', error))
      .finally(() => setIsLoading(false));
  };

  const handleChangeOption = (e) => {
    const status = e.target.value?.replaceAll(/\s/g, '')?.toLowerCase();
    setStatusFilter(status);
  };

  const handleSearchItems = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };

  const sendToQueuesDetails = (uid, BookingId, tripType, pnr, gds) => {
    navigate('/dashboard/queuesdetail', {
      state: { uid, BookingId, tripType, pnr, gds, path: 'queues' },
    });
  };

  const filteredQueuesData = queuesData?.filter((item) => {
    const normalizedStatus = item.Status?.replaceAll(/\s/g, '')?.toLowerCase();
    const includesSearchInput = Object.values(item)
      .join('')
      .toLowerCase()
      .includes(searchInput.toLowerCase());

    return (
      (statusFilter === 'all' || normalizedStatus === statusFilter) &&
      includesSearchInput
    );
  });

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box mt={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              m: '0px 0px 5px',
            }}
          >
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '24px',
                fontWeight: '500',
              }}
            >
              Queue Management
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  background: 'var(--primary-color)',
                  height: '35px',
                  borderRadius: '20px',
                  input: {
                    border: 'none',
                    background: 'none',
                    outline: 'none',
                    float: 'left',
                    padding: 0,
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 'normal',
                    transition: 'all 0.4s ease-in-out',
                    lineHeight: '35px',
                    width: '0px',
                  },
                  'input::placeholder': { color: 'white' },
                  '&:hover input': {
                    width: '235px',
                    padding: '0 10px',
                  },
                  '&:hover button': {
                    background: 'white',
                    color: 'black',
                  },
                  button: {
                    border: 'none',
                    color: 'white',
                    float: 'right',
                    minHeight: '0px',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                    background: 'var(--primary-color)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: 'all 0.4s ease-in-out',
                    cursor: 'pointer',
                    textDecoration: 'none',
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Search....."
                  onChange={handleSearchItems}
                />
                <button>
                  <SearchIcon />
                </button>
              </Box>
              <select
                onChange={handleChangeOption}
                style={{
                  width: '150px',
                  borderRadius: '4px',
                  height: '100%',
                  padding: '5px 10px',
                  color: 'var(--primary-color)',
                  fontWeight: '500',
                  borderColor: 'var(--secondary-color)',
                }}
              >
                <option value="all">&nbsp;Show All</option>
                <option value="hold">&nbsp;Hold</option>
                <option value="Issue In Processing">
                  &nbsp;Issue In Processing
                </option>
                <option value="Ticketed">&nbsp;Ticketed</option>
                <option value="Void In Processing">
                  &nbsp;Void In Processing
                </option>
                <option value="Voided">&nbsp;Voided</option>
                <option value="Void Rejected">&nbsp;Void Rejected</option>
                <option value="Reissue In Processing">
                  &nbsp;Reissue In Processing
                </option>
                <option value="Reissued">&nbsp;Reissued</option>
                <option value="Reissue Rejected">&nbsp;Reissue Rejected</option>
                <option value="Refund In Processing">
                  &nbsp;Refund In Processing
                </option>
                <option value="Refunded">&nbsp;Refunded</option>
                <option value="Refund Rejected">&nbsp;Refund Rejected</option>
                <option value="Issue Rejected">&nbsp;Issue Rejected</option>
                <option value="cancelled">&nbsp;Cancelled</option>
              </select>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '0px',
                  background: 'var(--secondary-color)',
                },
                height: 'calc(100vh - 230px)',
                overflowY: 'scroll',
                table: {
                  width: '100%',
                  position: 'relative',
                  borderCollapse: 'collapse',
                },
                th: {
                  position: 'sticky',
                  top: 0,
                  zIndex: '999',
                  background: 'var(--primary-color)',
                  color: 'white',
                  border: '1px solid #ddd',
                  padding: '8px 12px',
                  fontSize: '14px',
                  fontWeight: '500',
                },
                td: {
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  border: '1px solid #ddd !important',
                  padding: '5px !important',
                  fontSize: '12px',
                },
                'tr:nth-child(even)': {
                  background: '#f2f2f2',
                },
                'tr:hover': {
                  background: '#ddd',
                },
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>ReferenceId</th>
                    <th>Airlines</th>
                    <th>Status</th>
                    <th>Route</th>
                    <th>Type</th>
                    <th>Pax</th>
                    <th>BookedDate</th>
                    <th>FlightDate</th>
                    <th>Passenger</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredQueuesData?.map((bookingDetail, index) => (
                    <tr key={index}>
                      <td>
                        <Button
                          disabled={
                            bookingDetail.Status === 'Cancelled' ? true : false
                          }
                          sx={{
                            border: 'none',
                            cursor: 'pointer',
                            background: '#d1e9ff',
                            color: 'var(--secondary-color)',
                            padding: '5px 10px',
                            textDecoration: 'underline',
                            borderRadius: '4px',
                            fontSize: '12px',
                            '&:hover': {
                              background: '#d1e9ff',
                              color: '#003566',
                            },
                          }}
                          onClick={() =>
                            sendToQueuesDetails(
                              bookingDetail?.AgentId,
                              bookingDetail?.BookingId,
                              bookingDetail?.TripType,
                              bookingDetail?.Pnr,
                              bookingDetail?.gdsSystem
                            )
                          }
                        >
                          {bookingDetail.BookingId ?? 'Reference No'}
                        </Button>
                      </td>
                      <td>{bookingDetail.Career ?? 'Airlines'}</td>
                      <td>
                        <Typography
                          sx={{ fontSize: '12px', textAlign: 'center' }}
                          className={`${bookingDetail.Status?.toLowerCase()
                            ?.split(' ')
                            ?.join('-')}-btn`}
                        >
                          {bookingDetail.Status}
                        </Typography>
                      </td>
                      <td>
                        {bookingDetail.DepFrom ?? 'From'} -{' '}
                        {bookingDetail.ArrTo ?? 'To'}
                      </td>
                      <td>{bookingDetail.TripType ?? 'Flight Type'}</td>
                      <td>{bookingDetail.TotalPax ?? 'Total Pax'}</td>

                      <td>
                        {bookingDetail?.created_at !== '' || 'undefined' || null
                          ? format(
                              new Date(bookingDetail?.created_at),
                              'dd MMM yy hh:mm a'
                            )
                          : 'Booked Date'}
                      </td>
                      <td>{bookingDetail?.journeyDate || 'Flight Date'}</td>
                      <td>{bookingDetail?.Name || 'Passenger Name'}</td>
                      <td>
                        {commaNumber(bookingDetail.InvoicePrice) + 'AED' ||
                          'Gross Cost'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            <Box
              sx={{
                width: '100%',
                py: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  size="small"
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AgentBooking;
