import { Box, Grid } from '@mui/material'
import React from 'react'
import ProfilePic from './ProfilePic/ProfilePic'
import ChangePassword from './ChangePassword/ChangePassword'
import Security from './Security/Security'
import ProfileStatus from './ProfileStatus/ProfileStatus'
import AccountManager from './AccountManager/AccountManager'
import PartialPayment from './PartialPayment/PartialPayment'

const Setting = () => {
  return (
    <>
<Box sx={{mx:4,mt:2}}>
<Box sx={{height:'100vh'}}>
    <Grid container spacing={2}>
<Grid item lg={3}>
    <ProfilePic/>
    <ChangePassword/>
    <Security/>
</Grid>
<Grid item  lg={9}>
    <Box sx={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",p:3,background:'var(--white)',height:'100vh'}}>
    <ProfileStatus/>
    <AccountManager/>
    <PartialPayment/>
    </Box>
    
</Grid>
    </Grid>

</Box>
</Box>
    </>
  )
}

export default Setting