import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Stack,
  Tooltip,
} from '@mui/material';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format } from 'date-fns';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import secureLocalStorage from 'react-secure-storage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#2564b8',
      darker: '#2564b8',
    },
  },
});

const AgentManagement = () => {
  const [isLoading, setIsloading] = useState(false);
  const [allAgentData, setAllAgentData] = useState([]);
  const [agentData, setAgentData] = useState([]);
  const [details, setDetails] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [rowLoading, setRowLoading] = useState({});
  const [refetch, setRefetch] = useState(false);

  const handleClose = () => setOpen(false);
  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 15;

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setAllAgentData(allAgentData.slice((value - 1) * size, value * size));
  };
  const adminInfo = secureLocalStorage.getItem('admin-info');
  const token = adminInfo.token;
  useEffect(() => {
    setIsloading(true);
    fetch(`https://api-b2b.projectota.com/agent`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        const count = data?.length;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setAllAgentData(data);
        setAgentData(data);
        setIsloading(false); // Set loading to false on successful data fetch
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsloading(false); // Set loading to false in case of an error
      });
  }, [refetch]);

  const handleActive = async (data, status) => {
    const url = `https://api-b2b.projectota.com/admin/agent/${data?.uid}`;

    let body = JSON.stringify({
      email: data?.email,
      company: data?.company,
      status: status,
      password: '12345678',
      markuptype: data?.markuptype,
      markup: data?.markup,
    });

    try {
      setRowLoading((prevLoading) => ({
        ...prevLoading,
        [data.uid]: true,
      }));
      const response = await fetch(url, {
        method: 'PATCH', // Use PATCH method
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the Authorization header with the bearer token
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: `Agent ${status} Successfully`,
        confirmButtonText: 'OK',
      }).then(function () {
        setRefetch(!refetch);
        setRowLoading((prevLoading) => ({
          ...prevLoading,
          [data.uid]: false,
        }));
      });
    } catch (error) {
      console.error('Error activating agent:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.message,
        confirmButtonText: 'OK',
      }).then(function () {
        setRowLoading((prevLoading) => ({
          ...prevLoading,
          [data.uid]: false,
        }));
      });
    }
  };

  //  handle search
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== '') {
      const filterData = allAgentData.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setAgentData(filterData);
    } else if (searchInput === '') {
      setAgentData(allAgentData);
    }
  };

  const handleChangeOption = (e) => {
    const status = e.target.value?.replaceAll(/\s/g, '')?.toLowerCase();
    if (status !== '') {
      const filterData = allAgentData.filter((item) => {
        return item?.status?.replaceAll(/\s/g, '')?.toLowerCase() === status;
      });

      setAgentData(filterData);
    } else {
      setAgentData(allAgentData);
    }
  };

  //todo: for handle Filter
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), 'dd MMM yyyy'));
  const [endDate, setEndDate] = useState(format(new Date(), 'dd MMM yyyy'));

  const handleFilter = (e) => {
    e.preventDefault();
    const filterData = allAgentData.filter((item) => {
      return (
        new Date(item?.created_at).getDate() >= new Date(startDate).getDate() &&
        new Date(item?.created_at).getDate() <= new Date(endDate).getDate()
      );
    });
    setAgentData(filterData);
  };

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontWeight: 400,
              fontSize: '24px',
              color: '#222222',
            }}
          >
            Agent Management
          </Typography>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <Box
              sx={{
                background: 'var(--primary-color)',
                height: '35px',
                borderRadius: '20px',
                input: {
                  border: 'none',
                  background: 'none',
                  outline: 'none',
                  float: 'left',
                  padding: 0,
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: 'normal',
                  transition: 'all 0.4s ease-in-out',
                  lineHeight: '35px',
                  width: '0px',
                },
                'input::placeholder': { color: 'white' },
                '&:hover input': {
                  width: '150px',
                  padding: '0 10px',
                },
                '&:hover button': {
                  background: 'white',
                  color: 'black',
                },
                button: {
                  border: 'none',
                  color: 'white',
                  float: 'right',
                  minHeight: 'fit-content',
                  width: '35px',
                  height: '35px',
                  borderRadius: '50%',
                  background: 'var(--primary-color)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  transition: 'all 0.4s ease-in-out',
                  cursor: 'pointer',
                  textDecoration: 'none',
                },
              }}
            >
              <input
                type="text"
                placeholder="Search....."
                onChange={handelSearchItems}
              />
              <button>
                <SearchIcon />
              </button>
            </Box>
            <select
              onChange={handleChangeOption}
              style={{
                color: 'var(--primary-color)',
                fontSize: '14px',
                padding: '5px 10px',
                borderRadius: '4px',
                outline: 'none',
              }}
            >
              <option value="">&nbsp;Show All</option>
              <option value="Pending">&nbsp;Pending</option>
              <option value="Active">&nbsp;Active</option>
              <option value="Reject">&nbsp;Reject</option>
              <option value="Deactivate">&nbsp;Deactivate</option>
            </select>
            <Box>
              <form onSubmit={handleFilter}>
                <Grid
                  container
                  spacing={1}
                  alignItems="end"
                  justifyContent="end"
                  sx={{
                    zIndex: '9999',
                    input: {
                      width: '100%',
                      padding: '5px 10px',
                      border: '1px solid var(--secondary-color)',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      outline: 'none',
                    },
                  }}
                >
                  <Grid
                    style={{ position: 'relative' }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Box>
                      <input
                        title="Enter Start Date"
                        required
                        id="startDate"
                        name="startDate"
                        type="text"
                        value={startDate}
                        readOnly
                        onClick={() => {
                          setOpenEndDate(false);
                          setOpenStartDate((prev) => !prev);
                        }}
                      />
                    </Box>
                    {openStartDate && (
                      <Calendar
                        color={'var(--primary-color)'}
                        date={new Date(startDate)}
                        onChange={(date) => {
                          setStartDate(format(new Date(date), 'dd MMM yyyy'));
                          setOpenStartDate(false);
                        }}
                        maxDate={new Date()}
                        months={1}
                        className="new-dashboard-calendar"
                        name="dashboard-calendar"
                      />
                    )}
                  </Grid>

                  <Grid
                    style={{ position: 'relative' }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Box>
                      <input
                        title="Enter End Date"
                        required
                        id="endDate"
                        name="endDate"
                        type="text"
                        value={endDate}
                        readOnly
                        onClick={() => {
                          setOpenEndDate((prev) => !prev);
                          setOpenStartDate(false);
                        }}
                      />
                    </Box>
                    {openEndDate && (
                      <Calendar
                        color="var(--primary-color)"
                        date={new Date(endDate)}
                        onChange={(date) => {
                          setEndDate(format(new Date(date), 'dd MMM yyyy'));
                          setOpenEndDate(false);
                        }}
                        months={1}
                        className="new-dashboard-calendar"
                      />
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'end',
                      gap: '5px',
                    }}
                  >
                    <Button
                      title="Click to Filter"
                      sx={{
                        background: 'var(--secondary-color)',
                        color: 'white',
                        padding: '5px 20px !important',
                        fontSize: '12px !important',
                        width: '100%',
                        '&:hover': {
                          background: 'var(--secondary-color)',
                          color: 'white',
                        },
                      }}
                      type="submit"
                    >
                      FILTER
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'end',
                      gap: '5px',
                    }}
                  >
                    <Button
                      title="Click to Reset"
                      sx={{
                        background: 'var(--secondary-color)',
                        color: 'white',
                        padding: '5px 20px !important',
                        fontSize: '12px !important',
                        width: '100%',
                        '&:hover': {
                          background: 'var(--secondary-color)',
                          color: 'white',
                        },
                      }}
                      onClick={() => setAgentData(allAgentData)}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              mt: '5px',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '0px',
                background: 'var(--secondary-color)',
              },
              height: 'calc(100vh - 180px)',
              overflowY: 'scroll',
              table: {
                width: '100%',
                position: 'relative',
                borderCollapse: 'collapse',
              },
              th: {
                position: 'sticky',
                top: 0,
                zIndex: '999',
                background: 'var(--primary-color)',
                color: 'white',
                border: '1px solid #ddd',
                padding: '8px 12px',
                fontSize: '14px',
                fontWeight: '500',
              },
              td: {
                textAlign: 'center',
                verticalAlign: 'middle',
                border: '1px solid #ddd !important',
                padding: '5px !important',
                fontSize: '12px',
              },
              'tr:nth-child(even)': {
                background: '#f2f2f2',
              },
              'tr:hover': {
                background: '#ddd',
              },
            }}
          >
            {!isLoading ? (
              <table>
                <tr>
                  <th>AgentId</th>
                  <th>AgentName</th>
                  <th>Status</th>
                  <th>Company</th>
                  <th>Address</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Operation</th>
                  <th>Details</th>
                  {/* <th>Login</th> */}
                  <th>Call</th>
                  {/* <th>Login</th> */}
                </tr>
                <tbody>
                  {agentData?.map((data, index) => (
                    <tr key={index}>
                      <td>{data?.agentId || 'AgentId'}</td>
                      <td>
                        <Tooltip title={data?.name}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: 'black',
                            }}
                          >
                            {data?.name || 'Name'}
                          </Typography>
                        </Tooltip>
                      </td>
                      <td>{data?.status || 'Status'}</td>

                      <td>
                        <Tooltip title={data?.company}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: 'black',
                            }}
                          >
                            {data?.company?.slice(0, 10)}...
                          </Typography>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip title={data?.address}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: 'black',
                            }}
                          >
                            {data?.address?.slice(0, 10)}...
                          </Typography>
                        </Tooltip>
                      </td>
                      <td>{data?.email}</td>

                      <td>{data?.phone}</td>
                      <td>
                        {data?.status === 'active' ? (
                          <button
                            style={{
                              backgroundColor: '#E1241A',
                              color: '#FFFFFF',
                              border: 'none',
                              borderRadius: '3px',
                              width: '73px',
                              height: '30px',
                              cursor: 'pointer',
                              fontSize: '12px',
                            }}
                            onClick={() => handleActive(data, 'deactive')}
                            disabled={rowLoading[data.uid]}
                          >
                            {rowLoading[data.uid] ? (
                              <CircularProgress
                                style={{ height: '15px', width: '15px' }}
                              />
                            ) : (
                              'Deactivate'
                            )}
                          </button>
                        ) : data?.status === 'reject' ? (
                          <button
                            disabled
                            style={{
                              backgroundColor: 'var(--gray)',
                              color: '#FFFFFF',
                              border: 'none',
                              borderRadius: '3px',
                              width: '73px',
                              height: '30px',
                              fontSize: '12px',
                            }}
                          >
                            Reject
                          </button>
                        ) : (
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <IconButton
                              sx={{ color: '#0E8749' }}
                              onClick={() => handleActive(data, 'active')}
                              disabled={rowLoading[data.uid]}
                            >
                              {rowLoading[data.uid] ? (
                                <CircularProgress
                                  style={{ height: '15px', width: '15px' }}
                                />
                              ) : (
                                <CheckCircleIcon />
                              )}
                            </IconButton>
                            <IconButton
                              sx={{ color: 'var(--remove-color)' }}
                              onClick={() => handleActive(data, 'reject')}
                              disabled={rowLoading[data.uid]}
                            >
                              {rowLoading[data.uid] ? (
                                <CircularProgress
                                  style={{ height: '15px', width: '15px' }}
                                />
                              ) : (
                                <CancelIcon />
                              )}
                            </IconButton>
                          </Box>
                        )}
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            navigate('/dashboard/agentdetails', {
                              state: data,
                            })
                          }
                          style={{
                            width: '100%',
                            background: 'var(--secondary-color)',
                            color: 'white',
                            fontSize: '12px',
                            textTransform: 'capitalize',
                            padding: '5px 10px',
                          }}
                        >
                          Details
                        </Button>
                      </td>
                      {/* <td>
                        <Tooltip title={'Click To Login'}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: 'black',
                              '&:hover': {
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              },
                            }}
                            onClick={() => {
                              window.open(
                                `https://agent.flyjatt.com/signin/${data?.email}/${data?.password}`,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }}
                          >
                            Click
                          </Typography>
                        </Tooltip>
                      </td> */}

                      <td>
                        <a
                          href={`tel:+${data?.phone}`}
                          style={{ marginRight: '5px' }}
                        >
                          <PhoneIcon
                            style={{ color: '#003566', fontSize: '18px' }}
                          />
                        </a>
                        <a
                          href={`https://wa.me/+${data?.phone}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <WhatsAppIcon
                            style={{ color: 'green', fontSize: '18px' }}
                          />
                        </a>
                      </td>
                      {/* <td>
                        <Button
                          style={{
                            backgroundColor: "#d3143c",
                            color: "white",
                            fontSize: "11px",
                          }}
                          onClick={() => {
                            window.open(
                              `https://flyfarint.com/login/${data?.email}/${data?.password}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          Login
                        </Button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '38vh',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              my: 1,
              py: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '8px',
              borderTop: '1px solid var(--primary-color)',
            }}
          >
            <Typography style={{ fontSize: '15px', color: '#222222' }}>
              Showing Results {page} - {pageCount}
            </Typography>
            <ThemeProvider theme={theme}>
              <Stack spacing={2}>
                <Pagination
                  size="small"
                  count={pageCount}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </ThemeProvider>
          </Box>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{ ...style, width: 600 }}
            style={{ border: 'none', borderRadius: '5px' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ width: '100%' }}>
                {' '}
                <Typography
                  sx={{ color: 'var(--secondary-color0', fontSize: '17px' }}
                >
                  Agent ID:{details?.agentId} {}
                </Typography>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    fontSize: '25px',
                    color: '#003566',
                    fontWeight: 500,
                  }}
                ></Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{
                    mt: 2,
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span sx={{ width: '40%' }}>Agent Name: </span>{' '}
                  <span
                    style={{
                      width: '60%',
                      textAlign: 'left',
                      marginLeft: '10%',
                    }}
                  >
                    {' '}
                    {details?.name}
                  </span>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{
                    mt: 2,
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span style={{ width: '30%' }}>Email: </span>{' '}
                  <span
                    style={{
                      width: '60%',
                      textAlign: 'left',
                      marginLeft: '10%',
                    }}
                  >
                    {details?.email}
                  </span>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{
                    mt: 2,
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span sx={{ width: '40%' }}>Phone: </span>{' '}
                  <span
                    style={{
                      width: '60%',
                      textAlign: 'left',
                    }}
                  >
                    {details?.phone}
                  </span>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{
                    mt: 2,
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span sx={{ width: '40%' }}>Address: </span>{' '}
                  <span
                    style={{
                      width: '60%',
                      textAlign: 'left',
                    }}
                  >
                    {details?.address}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

export default AgentManagement;
