import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';

const UpcommingTrips = ({ url, text, slice }) => {
  const location = useLocation();
  const urls = url === undefined ? location?.state?.url : url;
  const texts = text === undefined ? location?.state?.text : text;
  // const slices = url === undefined ? 6 ;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(urls);
      setData(response.data);
    } catch (error) {
      setError(error.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const sendToQueuesDetails = (item) => {
    navigate(
      `/user/congratulation/${item?.uid}/${item.bookingId}/${item?.triptype}`,
      {
        state: {
          data: item,
          pnr: item?.pnr,
          queues: 'queues',
        },
      }
    );
  };
  return (
    <Box>
      {location?.state ? <Header /> : ''}

      {!loading ? (
        <Box>
          <Typography
            sx={{
              color: 'var(--black)',
              fontSize: 16,
              fontWeight: 500,
              mt: 4,
              mb: 2,
              display:"   flex ",
           justifyContent:"start",
           alignItems:'center',
           gap:"8px"
              
            }}
          >
          <span>  {texts} Trips{' '}</span>
            {data?.length > 0 ? (
              <span
                style={{
                  color: 'var(--white)',
                  fontSize: 14,
                  background: '#F8874A',
                  borderRadius: '50%',
                  height: '30px',
                  width: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {data?.length || ''}
              </span>
            ) : (
              ''
            )}
          </Typography>
          <Grid container spacing={2}>
            {data
              ?.slice(0, location?.state ? data?.length : 6)
              ?.map((item, i, arr) => (
                <Grid item xs={12} sm={6} md={3} lg={4} key={i}>
                  <Box
                    sx={{
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      background: 'linear-gradient(180deg, #FFFFFF 0%, #E4E4E4 100%)',
                      p: { xs: 1, sm: 2 },
                      borderRadius: '10px',
                    }}
                  >
                    <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                      <Box>
                      <Typography
                      sx={{ fontSize: 16, color: 'var(--secondary-color)' }}
                    >
                      {item.depfrom}-{item.arrto}{' '}
                      <span style={{ fontSize: 10 }}>{item.triptype}</span>
                    </Typography>
                    <Typography
                        sx={{ fontSize: 14, color: 'var(--primary-color)' }}
                      >
                        {moment(item.flightdate).format('ddd DD-MMM-YYYY')}
                      </Typography>
                      </Box>
                      <Box sx={{textAlign:'right'}}>
                      <Typography
                        sx={{ fontSize: 12 }}
                        className={`${item.status.toLowerCase()}-btn`}
                      >
                        {item.status}
                      </Typography>

                      {/* <Typography sx={{ fontSize: 12, color: 'var(--gray)' }}>
                        {item.bookingId}
                      </Typography> */}
                      <Typography
                        sx={{ fontSize: 12, color: 'var(--gray)' }}
                      >
                        PNR: {item.pnr}
                      </Typography>
                        </Box>
                    </Box>



                 {/* <Box>
                 <Typography
                      sx={{ fontSize: 16, color: 'var(--secondary-color)' }}
                    >
                      {item.depfrom}-{item.arrto}{' '}
                      <span style={{ fontSize: 10 }}>{item.triptype}</span>
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      mtt={2}
                    >
                      <Typography
                        sx={{ fontSize: 14, color: 'var(--primary-color)' }}
                      >
                        {moment(item.flightdate).format('ddd DD-MMM-YYYY')}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12 }}
                        className={`${item.status.toLowerCase()}-btn`}
                      >
                        {item.status}
                      </Typography>
                    </Stack>
                 </Box> */}























                    {/* <Stack
                      direction="row"
                      justifyContent="space-between"
                      mtt={2}
                    >
                      <Typography sx={{ fontSize: 12, color: 'var(--gray)' }}>
                        {item.bookingId}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12, color: 'var(--primary-color)' }}
                      >
                        PNR: {item.pnr}
                      </Typography>
                    </Stack> */}








                    
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      mt={6}
                      alignItems="center"
                    >
                      <Box>
                      <Typography sx={{ fontSize: 12, color: 'var(--gray)' }}>
                        {item.bookingId}
                      </Typography>
                      <Typography sx={{ fontSize: 12, color: 'orange' }}>
                        {item.carrier}
                      </Typography>
                      </Box>
                      <Button
                        size="small"
                        sx={{
                          fontSize: 12,
                          color: 'var(--white)',
                          textTransform: 'capitalize',
                          bgcolor: 'var(--primary-color)',
                          '&:hover': {
                            bgcolor: 'var(--primary-color)',
                          },
                        }}
                        disabled={item.status === 'Cancelled' ? true : false}
                        onClick={() => sendToQueuesDetails(item)}
                      >
                        View Itinerary
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
              ))}
          </Grid>
          <Box textAlign={'end'}>
            {!location?.state ? (
              <>
                {data?.length > 6 && (
                  <Button
                    sx={{ color: 'orange' }}
                    onClick={() => {
                      navigate('/user/tripsdata', { state: { url, text } });
                    }}
                  >
                    View More{' '}
                  </Button>
                )}
              </>
            ) : null}
          </Box>
        </Box>
      ) : (
        <Box mt={3}>
          <CircularProgress sx={{ color: 'var(--primary-color)' }} />
        </Box>
      )}
    </Box>
  );
};

export default UpcommingTrips;
