import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import PeopleIcon from '@mui/icons-material/People';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LanguageIcon from '@mui/icons-material/Language';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import './LiveDashboard.css';
import secureLocalStorage from 'react-secure-storage';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import MoneyIcon from './money.png'
import RightTashIcon from './rightash.png'
import CrossedRedIcon from './crossred.png'
import DashboardIcon from './chart.png'
const LiveDashboard = () => {
  const navigate = useNavigate();
  const aminInfo = secureLocalStorage.getItem('admin-info');
  let token = aminInfo?.token;
  const [isLoading, setIsLoading] = useState(false);
  const [queuesData, setQueuesData] = useState([]);
  const [allQueuesData, setAllQueuesData] = useState([]);
  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const fetchQueuesData = () => {
      setIsLoading(true);

      fetch(`https://api-b2b.projectota.com/admin/report/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          setQueuesData(data);
          setAllQueuesData(data);
          setIsLoading(true);
        })
        .catch((error) => console.error('Error fetching data:', error))
        .finally(() => setIsLoading(false));
    };

    fetchQueuesData();
  }, []);

  const sendToQueuesDetails = (data) => {
    navigate(`/dashboard/bookingdetails/${data?.uid}`, {
      state: {
        data,
      },
    });
  };

  return (
    <Box className="dashboard-live">
      {/* <AdminHeader /> */}
      <Box sx={{mx:4}}>
        {/* -------------------------- */}
        <Grid
          container
          spacing={0}
          sx={{ overflowY: 'hidden', maxHeight: '40vh' }}
        >
          <Grid item xs={12} md={8}>
            <Typography sx={{ fontSize: 16, fontWeight: 500,  }}>
              Air Ticket Live Booking
            </Typography>
            <Box
              sx={{
                overflowY: 'auto',
                boxShadow:' 0px 2px 12px 0px #0000000F',
                table: {
                  borderCollapse: 'collapse',
                  width: '100%',
                },
                th: {
                  border: 'none',
                  // padding: '5px 8px',
                  textAlign: 'left',
                  background: 'var(--white)',
                  color: 'var(--black)',
                  fontSize: '14px',
                  fontWeight: 400,
                },
                td: {
                  border: 'none',
                  padding: '5px',
                  fontSize: '12px',
                },
                // 'tr:nth-child(even)': {
                //   background: '#f2f2f2',
                // },
                'tr:hover': { background: '#ddd' },
              }}
            >
              <table>
                <thead>
                  <tr style={{borderBottom:"1px solid  #DEE2E6"}}>
                    <th>BookingId</th>
                    <th>Status</th>
                    <th>BookedAt</th>
                    <th>Passenger</th>
                    <th>Route</th>
                    <th>Type</th>
                    {/* <th>TravelDate</th> */}
                    <th>Airline</th>
                  </tr>
                </thead>

                <tbody>
                  {queuesData?.TotalBookingData?.map((bookingDetail, index) => (
                    <tr key={index} style={{borderBottom:"1px solid  #DEE2E6"}}>
                      <td>
                        <button
                          style={{
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            // backgroundColor: '#d1e9ff',
                            padding: '5px 15px',
                            color: '#F8874A',
                            // textDecoration: 'underline',
                          }}
                          onClick={() => sendToQueuesDetails(bookingDetail)}
                        >
                          {bookingDetail.bookingId ?? 'Reference No'}
                        </button>
                      </td>
                      <td>{bookingDetail.status}</td>
                      <td>
                        {new Date(bookingDetail?.created_at).toLocaleString(
                          'en-GB',
                          {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false,
                          }
                        )}
                        {/* {bookingDetail?.created_at !== "" || "undefined" || null
                          ? format(
                              new Date(bookingDetail?.created_at),
                              "dd MMM yy hh:mm a"
                            )
                          : "Booked Date"} */}
                      </td>
                      <td>{bookingDetail?.name || 'Passenger Name'}</td>
                      <td>
                        {bookingDetail.depfrom ?? 'From'} -{' '}
                        {bookingDetail.arrto ?? 'To'}
                      </td>
                      <td>{bookingDetail.triptype ?? 'Flight Type'}</td>

                      {/* <td>{bookingDetail?.journeyDate || "Flight Date"}</td> */}

                      <td>{bookingDetail.carrier ?? 'Airline'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              overflowY: 'hidden',
              maxHeight: { xs: '40%', sm: '40vh' },
              pl: 2,
            }}
          >
            <Box
              style={{
                background: '#D1E9FF',
                padding: '5px 10px',
                borderRadius: '3px',
              }}
            >
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Total Booking
              </Typography>
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '25px',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalBooking || 0}
              </Typography>
            </Box>

            <Box
              sx={{
                background: '#F7D800',
                padding: '5px 10px',
                borderRadius: '3px',
                mt: 1,
              }}
            >
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Total Ticketed
              </Typography>
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '25px',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.Ticketed || 0}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                background: '#525371',
                padding: '5px 10px',
                borderRadius: '3px',
                my: 1,
              }}
            >
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Void
              </Typography>
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '25px',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.Void || 0}
              </Typography>
            </Box> */}
            <Box
              sx={{
                background: 'crimson',
                padding: '5px 10px',
                borderRadius: '3px',
                my: 1,
              }}
            >
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Total Cancelled
              </Typography>
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '25px',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.Cancelled || 0}
              </Typography>
            </Box>

            <Box
              sx={{
                background: 'gray',
                padding: '5px 10px',
                borderRadius: '3px',
              }}
            >
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Total Agent
              </Typography>
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '25px',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalAgent || 0}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* --------------------------------- */}

        <Grid
          container
          spacing={2}
          my={2}
          sx={{ overflowY: 'hidden',  }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ overflowY: 'hidden',  }}
          >

<Grid container spacing={2}>
<Grid item lg={6}>
  <Box sx={{background: '#D3E9FF',
  height:"235px",
  boxShadow: '0px 2px 6px 0px #00000040',
  display:'flex',
  justifyContent:"center",alignItems:'center',
  borderRadius:"32px"
  
}}>
<Box sx={{textAlign:'center'}}>
  <img src={DashboardIcon} width='37px' height='37px'/>
<Box>
<Typography sx={{fontSize:"18px"}}>Total</Typography>
<Typography sx={{fontSize:"14px",color:""}}>Deposit</Typography>
<Typography sx={{fontSize:"14px",color:"#172B4D",mt:6,}}>6</Typography>
</Box>
</Box>
</Box>
  </Grid>

  <Grid item lg={6}>
  <Box sx={{background: '#FDF3AF',
  boxShadow: '0px 2px 6px 0px #00000040',
  height:"235px",
  display:'flex',
  justifyContent:"center",alignItems:'center',
  borderRadius:"32px"
}}>
<Box sx={{textAlign:'center'}}>
  <img src={MoneyIcon} width='37px' height='37px'/>
<Box>
<Typography sx={{fontSize:"18px"}}>Total</Typography>
<Typography sx={{fontSize:"14px",color:""}}>Deposited Pending</Typography>
<Typography sx={{fontSize:"14px",color:"#172B4D",mt:6,}}>6</Typography>
</Box>
</Box>
</Box>
  </Grid>


  <Grid item lg={6}>
  <Box sx={{background: '#E0E0E0',
  height:"235px",
  boxShadow: '0px 2px 6px 0px #00000040',
  display:'flex',
  justifyContent:"center",alignItems:'center',
  borderRadius:"32px"
}}>
<Box sx={{textAlign:'center'}}>
  <img src={RightTashIcon} width='37px' height='37px'/>
<Box>
<Typography sx={{fontSize:"18px"}}>Total</Typography>
<Typography sx={{fontSize:"14px",color:""}}>Deposit Approve </Typography>
<Typography sx={{fontSize:"14px",color:"#172B4D",mt:6,}}>6</Typography>
</Box>
</Box>
</Box>
  </Grid>


  <Grid item lg={6}>
  <Box sx={{background: '#FFC6D1',
  height:"235px",
  boxShadow: '0px 2px 6px 0px #00000040',
  display:'flex',
  justifyContent:"center",alignItems:'center',
  borderRadius:"32px"
}}>
<Box sx={{textAlign:'center'}}>
  <img src={CrossedRedIcon} width='37px' height='37px'/>
<Box>
<Typography sx={{fontSize:"18px"}}>Total</Typography>
<Typography sx={{fontSize:"14px",color:""}}>Deposit Reject</Typography>
<Typography sx={{fontSize:"14px",color:"#172B4D",mt:6,}}>6</Typography>
</Box>
</Box>
</Box>
  </Grid>

</Grid>




















            {/* <Box
              style={{
                background: '#D1E9FF',
                padding: '5px 10px',
                borderRadius: '3px',
              }}
            >
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Total Deposit
              </Typography>
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '25px',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalDeposit || 0}
              </Typography>
            </Box>
            <Box
              sx={{
                background: '#F7D800',
                padding: '5px 10px',
                borderRadius: '3px',
                my: 1,
              }}
            >
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Total Deposit Pending
              </Typography>
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '25px',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalDepositPending || 0}
              </Typography>
            </Box>
            <Box
              sx={{
                background: '#525371',
                padding: '5px 10px',
                borderRadius: '3px',
                mb: 1,
              }}
            >
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Total Deposit Approved
              </Typography>
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '25px',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalDepositApproved || 0}
              </Typography>
            </Box>
            <Box
              style={{
                background: 'crimson',
                padding: '5px 10px',
                borderRadius: '3px',
              }}
            >
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Total Deposit Rejected
              </Typography>
              <Typography
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '25px',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalDepositRejected || 0}
              </Typography>
            </Box> */}
          </Grid>
          <Grid item xs={12} md={8}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, }}>
                  Current Deposit
                </Typography>
                <Box
                  sx={{
                    overflowY: 'auto',
                    maxHeight: '38vh',
                    table: {
                      borderCollapse: 'collapse',
                      width: '100%',
                    },
                    th: {
                      border: 'none',
                      padding: '5px 8px',
                      textAlign: 'left',
                      background: 'var(--white)',
                      color: 'var(--black)',

                      fontSize: '14px',
                      fontWeight: 400,
                    },
                    td: {
                      border: 'none',
                      padding: '5px',
                      fontSize: '12px',
                    },
                    'tr:nth-child(even)': {
                      // background: '#f2f2f2',
                    },
                    'tr:hover': { background: '#ddd' },
                  }}
                >
                  <table>
                    <thead>
                      <tr style={{borderBottom:"1px solid  #DEE2E6"}}>
                        <th>Deposit&nbsp;Id</th>
                        <th>Status</th>
                        <th>Sender</th>
                        <th>Amount</th>
                        <th>Time</th>
                      </tr>
                    </thead>

                    <tbody>
                      {allQueuesData?.TotalDepositData?.map((data, index) => (
                        <tr key={index} style={{borderBottom:"1px solid  #DEE2E6"}}>
                          <td>
                            <button
                              style={{
                                background: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                backgroundColor: 'var(--white)',
                                padding: '5px 15px',
                                color: '#F8874A',
                              }}
                            >
                              {data.depositId ?? 'Deposit Id'}
                            </button>
                          </td>
                          <td>{data.status}</td>
                          <td>{data?.sender}</td>
                          <td>{data?.amount}</td>
                          <td>
                            {new Date(data?.created_at).toLocaleString(
                              'en-uk',
                              {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
           
          </Grid>
        </Grid>







<Box sx={{mt:4}}>



<Grid container spacing={2}>
<Grid item lg={8}>
<Typography sx={{ fontSize: 16, fontWeight: 500,color:'#3A3939'}}>
                  Agent Data
                </Typography>
                <Box
                  sx={{
         
                    overflowY: 'auto',
                    maxHeight: '38vh',

                    table: {
                      borderCollapse: 'collapse',
                      width: '100%',
                    },
                    th: {
                      border: 'none',
                      padding: '5px 8px',
                      textAlign: 'left',
                      background: 'var(--white)',
                      color: 'var(--black)',
borderBottom:"1px solid #DEE2E6",
                      fontSize: '14px',
                      fontWeight: 400,
                    },
                    td: {
                      border: 'none',
                      padding: '5px',
                      fontSize: '12px',
                    },
                    'tr:nth-child(even)': {
                      background: '#f2f2f2',
                    },
                    'tr:hover': { background: '#ddd' },
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        {/* <th>Agent&nbsp;Id</th> */}
                        <th >Company</th>
                        <th >Phone</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {allQueuesData?.AgentData?.map((data, index) => (
                        <tr>
                          {/* <td>{data?.agentId || "agentId"}</td> */}
                          <td>{data?.company || 'Company Name'}</td>
                          <td>{data.phone || 'phone'}</td>
                          <td style={{color:data?.status==='active'?"green":"red",textTransform:'capitalize'}}>{data?.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
</Grid>
<Grid item lg={4}>

</Grid>
</Grid>

</Box>














      </Box>
    </Box>
  );
};

export default LiveDashboard;
