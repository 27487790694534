import { Container, Typography } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Tab } from '@mui/material';
import CashTab from './AddDepositTabs/CashTab';
import Header from '../Header/Header';

const AddDeposite = () => {
  const [value, setValue] = React.useState('cash');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 12, md: 2 } }}>
        <Box sx={{ width: '100%' }}>
          <TabContext value={value}>
            <Box
              sx={{
                width: '100%',
                height: { md: 'fit-content', sm: '100%', xs: '100%' },
                display: { xs: 'block', sm: 'flex' },
                justifyContent: {
                  xs: 'space-between',
                },
                alignItems: 'center',
                opacity: '1',
                '& .MuiTabs-flexContainer': {
                  flexWrap: 'wrap',
                  gap: '5px',
                },
                '.MuiTabs-root': {
                  minHeight: 'fit-content',
                },
                '& button': {
                  opacity: '1',
                  background: 'transparent',
                  color: 'var(--secondary-color)',
                  border: '1px solid var(--secondary-color)',
                  width: 'fit-content',
                  minHeight: 'fit-content',
                  padding: '10px 5px',
                  fontSize: '12px',
                  borderRadius: '4px',
                },
                '& button.Mui-selected': {
                  background: 'var(--primary-color)',
                  border: '1px solid var(--priamry-color)',
                  color: 'var(--white) !important',
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: 20, sm: 24 },
                  color: '#222222',
                  mb: { xs: 1, sm: '18px' },
                }}
              >
                Add Deposit
              </Typography>
              <TabList
                onChange={handleChange}
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
              >
                <Tab label="Cash" value="cash" />
                <Tab label="Bank Transfer" value="banktransfer" />
              </TabList>
            </Box>

            <TabPanel value="cash" style={{ padding: '0px' }}>
              <CashTab />
            </TabPanel>
            <TabPanel value="banktransfer" style={{ padding: '0' }}>
              <CashTab value="banktransfer" />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Box>
  );
};

export default AddDeposite;
