/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  const date = new Date().getFullYear();

  return (
    <Box sx={{mx:4,mt:5}}>

    <Box sx={{bgcolor:'var(--primary-color )',py:4,borderTopLeftRadius:"20px",borderTopRightRadius:"20px"}}>
       <Container>
          <Box
            style={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "var(--white)",
                fontWeight: "normal",
                textAlign: "right",
                fontSize: "12px",
              }}
              noWrap
            >
              &copy; @ 2023 All right reserved by TailDash
            </Typography>
          </Box>
        </Container>
    </Box>
    </Box>
  );
};

export default Footer;
