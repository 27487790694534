import {
  Box,
  Container,
  createTheme,
  Fade,
  Grid,
  Modal,
  Pagination,
  Stack,
  ThemeProvider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Backdrop from '@mui/material/Backdrop';
import Swal from 'sweetalert2';
import Success from '../../images/undraw/undraw_completed_tasks_vs6q.svg';
import ServerDown from '../../images/undraw/undraw_server_down_s-4-lk.svg';
import ReConfirm from '../../images/undraw/undraw_confirmation_re_b6q5.svg';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import secureLocalStorage from 'react-secure-storage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2564b8',
      darker: '#2564b8',
    },
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AdminBankAccount = () => {
  const navigate = useNavigate();
  const adminInfo = secureLocalStorage.getItem('admin-info');
  const token = adminInfo?.token;
  const [updating, setUpdating] = useState(false);
  const [updateBank, setUpdateBank] = useState({});
  const [editBank, setEditBank] = useState(false);
  const handleOpen = (data) => {
    setUpdateBank(data);
    setEditBank(true);
  };
  const handleClose = () => setEditBank(false);
  const [bankData, setBankData] = useState([]);
  const [allBankData, setAllBankData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(false);

  let size = 20;
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setBankData(allBankData.slice((value - 1) * size, value * size));
  };

  const handleUpdateData = (e) => {
    setUpdateBank({
      ...updateBank,
      [e.target.name]: e.target.value,
    });
  };
  const handleUpdateBank = async (e) => {
    e.preventDefault();
    setUpdating(true);

    const url = `https://api-b2b.projectota.com/admin/banklist/${updateBank?.uid}`;
    const body = JSON.stringify({
      uid: updateBank?.id,
      bankname: updateBank?.bankname,
      accountname: updateBank?.accountname,
      accountnumber: updateBank?.accountnumber,
    });
    const config = {
      method: 'PATCH',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include the token in the header
      },
      body: body,
    };

    try {
      const response = await fetch(url, config);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setEditBank(false);
      Swal.fire({
        imageUrl: Success,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        title: 'Bank Details Updated!',
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
      }).then(() => {
        setUpdating(false);
        setRefetch(!refetch);
      });
    } catch (error) {
      console.error('Error updating bank details:', error);
      setEditBank(false);
      Swal.fire({
        imageUrl: ServerDown,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        title: error?.message,
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Try Another Time',
      }).then(() => {
        setUpdating(false);
      });
    }
  };

  const bankAccountDelete = (id) => {
    const url = `https://api-b2b.projectota.com/admin/banklist/${id}`;

    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
      title: 'You want to delete Bank Details?',
      showCancelButton: true,
      confirmButtonColor: 'red',
      confirmButtonText: 'Delete',
      cancelButtonColor: 'var(--primary-color)',
      cancelButtonText: 'Cancel',
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        fetch(url, {
          method: 'DELETE',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
          })
          .then((data) => {
            Swal.fire({
              imageUrl: Success,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: 'Custom image',
              title: 'Bank Deleted!',
              confirmButtonColor: 'var(--primary-color)',
              confirmButtonText: 'Ok',
            }).then(() => {
              setRefetch(!refetch);
            });
          })
          .catch((err) => {
            console.error('Error deleting bank details:', err);
            Swal.fire({
              imageUrl: ServerDown,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: 'Custom image',
              title: err?.message,
              confirmButtonColor: 'var(--primary-color)',
              confirmButtonText: 'Try Another Time',
            });
          });
      }
    });
  };

  useEffect(() => {
    const apiUrl = 'https://api-b2b.projectota.com/admin/banklist/all';

    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the header
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setBankData(data);
        setAllBankData(data);
        setPageCount(Math.ceil(data?.length / size));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [refetch]);

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '24px',
              color: '#222222',
              fontFamily: 'poppins',
            }}
          >
            Bank Account
          </Typography>

          <Box sx={{ display: 'flex', gap: '5px' }}>
            <Box
              sx={{
                background: 'var(--primary-color)',
                height: '35px',
                borderRadius: '20px',
                input: {
                  border: 'none',
                  background: 'none',
                  outline: 'none',
                  float: 'left',
                  padding: 0,
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: 'normal',
                  transition: 'all 0.4s ease-in-out',
                  lineHeight: '35px',
                  width: '0px',
                },
                'input::placeholder': { color: 'white' },
                '&:hover input': {
                  width: '235px',
                  padding: '0 10px',
                },
                '&:hover button': {
                  background: 'white',
                  color: 'black',
                },
                button: {
                  border: 'none',
                  color: 'white',
                  float: 'right',
                  minHeight: 'fit-content',
                  width: '35px',
                  height: '35px',
                  borderRadius: '50%',
                  background: 'var(--primary-color)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  transition: 'all 0.4s ease-in-out',
                  cursor: 'pointer',
                  textDecoration: 'none',
                },
              }}
            >
              <input
                type="text"
                placeholder="Search....."
                // onChange={handelSearchItems}
              />
              <button>
                <SearchIcon />
              </button>
            </Box>
            <Button
              style={{
                padding: '5px 10px',
                color: 'var(--white)',
                background: 'var(--secondary-color)',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '4px',
                textTransform: 'capitalize',
                fontSize: '14px',
              }}
              onClick={() => {
                navigate('/dashboard/addbank');
              }}
            >
              Add Bank
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            mt: '5px',
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '0px',
              background: 'var(--secondary-color)',
            },
            height: 'calc(100vh - 180px)',
            overflowY: 'scroll',
            table: {
              width: '100%',
              position: 'relative',
              borderCollapse: 'collapse',
            },
            th: {
              position: 'sticky',
              top: 0,
              zIndex: '999',
              background: 'var(--primary-color)',
              color: 'white',
              border: '1px solid #ddd',
              padding: '8px 12px',
              fontSize: '14px',
              fontWeight: '500',
            },
            td: {
              textAlign: 'center',
              verticalAlign: 'middle',
              border: '1px solid #ddd !important',
              padding: '5px !important',
              fontSize: '12px',
            },
            'tr:nth-child(even)': {
              background: '#f2f2f2',
            },
            'tr:hover': {
              background: '#ddd',
            },
          }}
        >
          <table>
            <tr>
              <th>Bank Name</th>
              <th>Account Name</th>
              <th>IAccount Number</th>
              <th>Action</th>
            </tr>

            <tbody>
              {bankData?.map((data, index) => (
                <tr key={index}>
                  <td>{data?.bankname || 'Bank Name'}</td>
                  <td>{data?.accountname || 'Account Name'}</td>
                  <td>{data?.accountnumber || ''}</td>
                  <td>
                    <EditIcon
                      style={{
                        color: 'var(--primary-color)',
                        fontSize: '22px',
                        marginRight: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleOpen(data)}
                    />
                    <DeleteIcon
                      style={{
                        color: 'tomato',
                        fontSize: '22px',
                        cursor: 'pointer',
                      }}
                      onClick={() => bankAccountDelete(data?.uid)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <Box
          sx={{
            width: '100%',
            my: 1,
            py: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '8px',
            borderTop: '1px solid var(--primary-color)',
          }}
        >
          <Typography style={{ fontSize: '15px', color: '#222222' }}>
            Showing Results {page} - {pageCount}
          </Typography>
          <ThemeProvider theme={theme}>
            <Stack spacing={2}>
              <Pagination
                size="small"
                count={pageCount}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </ThemeProvider>
        </Box>
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editBank}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editBank}>
          <Box sx={style}>
            <Box mt={2}>
              <Typography
                sx={{
                  fontWeight: '500px',
                  fontSize: '23px',
                  color: '#003566',
                  textAlign: 'center',
                }}
                mb={2}
              >
                Update Account
              </Typography>

              <form onSubmit={handleUpdateBank}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={2} md={6}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                      >
                        Bank Name
                      </Typography>
                      <input
                        required
                        type="text"
                        name="bankname"
                        value={updateBank?.bankname || ''}
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                        placeholder="Bank Name"
                        onChange={handleUpdateData}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={6}>
                    <Box>
                      <Typography
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                        mb={1}
                      >
                        Account Name
                      </Typography>
                      <input
                        required
                        type="text"
                        name="accountname"
                        value={updateBank?.accountname || ''}
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                        placeholder="Account Name"
                        onChange={handleUpdateData}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={6}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                      >
                        Account Number
                      </Typography>
                      <input
                        required
                        type="text"
                        name="accountnumber"
                        value={updateBank?.accountnumber || ''}
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                        placeholder="Account Number"
                        onChange={handleUpdateData}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Button
                  disabled={updating ? true : false}
                  sx={{
                    background: 'var(--mateBlack)',
                    color: '#FFFFFF',
                    width: '370px',
                    mt: '4rem',
                    '&:hover': {
                      backgroundColor: 'var(--mateBlack)',
                    },
                  }}
                  type="submit"
                >
                  {updating ? 'Wait...' : 'Update Bank Account'}
                </Button>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AdminBankAccount;
