import { PDFDownloadLink } from '@react-pdf/renderer';
import { Stack } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PDFPageDesign from './PDFPageDesign';

const AllPDF = ({
  bookingId,
  flightData,
  fareTotal,
  passengerData,
  allData,
  tripType,
  stateData,
  qeuesData,
}) => {
  let bookingwp = `Booking with price`;
  let bookingwop = `Booking without price`;

  let eticketp = `e-Ticket with price`;
  let eticketwop = `e-Ticket without price`;

  return (
    <div>
      {stateData?.Status === 'Hold' ||
      stateData?.Status === 'Cancelled' ||
      stateData?.Status === 'Issue In Processing' ? (
        <Stack direction="row" justifyContent="space-between">
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Booking Copy"
                check="1"
                tripType={tripType}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={bookingwp}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: '#fff',
                    background: '#222222',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontSize: 11,
                    padding: '8px',
                    borderRadius: '5px',
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> With Price
                </a>
              )
            }
          </PDFDownloadLink>
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Booking Copy"
                check="2"
                tripType={tripType}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={bookingwop}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: '#fff',
                    background: '#222222',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontSize: 11,
                    padding: '8px',
                    borderRadius: '5px',
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> Without Price
                </a>
              )
            }
          </PDFDownloadLink>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="space-between">
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="e - Ticket"
                check="1"
                tripType={tripType}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={eticketp}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: '#fff',
                    background: '#222222',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontSize: 11,
                    padding: '8px',
                    borderRadius: '5px',
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> With Price
                </a>
              )
            }
          </PDFDownloadLink>
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="e - Ticket"
                check="2"
                tripType={tripType}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={eticketwop}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: '#fff',
                    background: '#222222',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontSize: 11,
                    padding: '8px',
                    borderRadius: '5px',
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> Without Price
                </a>
              )
            }
          </PDFDownloadLink>
        </Stack>
      )}
    </div>
  );
};

export default AllPDF;
