import { Box } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';

const Marque = () => {
  const user = secureLocalStorage.getItem('user-info');
  const uid = user?.uid;
  const apiUrl = 'https://api-b2b.projectota.com';
  const apiEndpoint = '/agent/notice/all';
  const [marqueText, setMarqueText] = useState('');
  useEffect(() => {
    const url = `https://api-b2b.projectota.com/agent/myaccount/${uid}`;
    const config = {
      method: 'GET',
      header: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    };
    fetch(url, config)
      .then((res) => res.json())
      .catch((err) => console.log(err.message));

    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}${apiEndpoint}`);
        setMarqueText(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [uid]);
  return (
    <Box
      sx={{
        overflowX: 'hidden',
        display: { xs: 'none', md: 'block' },
        borderTop: '1px solid white',
      }}
    >
      <marquee
        style={{
          //   boxShadow:
          //     '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
          background: 'var(--rgb-color)',
          color: 'var(--white)',
          padding: '6px 0',
          fontSize: '14px',
        }}
      >
        {marqueText && marqueText?.map((item) => item.notice)}
      </marquee>
    </Box>
  );
};

export default Marque;
