import { Box, Container, Typography } from '@mui/material';
import commaNumber from 'comma-number';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import Loader from '../../images/loader/Render.gif';
import Header from '../Header/Header';
import axios from 'axios';
import { createMRTColumnHelper } from 'material-react-table';
import CummonTable from '../CommonTable/CummonTable';

const TransactionReport = () => {
  const columnHelper = createMRTColumnHelper();
  const users = secureLocalStorage.getItem('user-info');
  let agentId = users?.uid;

  const [refetch, setRefetch] = useState(false);

  const [allData, setAllData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    columnHelper.accessor('trxtype', {
      header: 'Type',
      size: 10,
      Cell: ({ row }) => (
        <Typography
          sx={{ fontSize: '12px', textAlign: 'center' }}
          className={`${row.original?.trxtype
            ?.toLowerCase()
            ?.split(' ')
            ?.join('-')}-btn`}
        >
          {row.original?.trxtype || 'Status'}
        </Typography>
      ),
    }),
    columnHelper.accessor('refId', {
      header: 'TransactionId',
      size: 20,
      // Cell: ({ row }) =>
      //   `${commaNumber(Number.parseInt(row.original?.amount) || 0)} AED`,
    }),
    columnHelper.accessor('amount', {
      header: 'Amount',
      size: 200,
      Cell: ({ row }) =>
        `${commaNumber(Number.parseInt(row.original?.amount) || 0)} AED`,
    }),
    columnHelper.accessor('created_at', {
      header: 'Date',
      size: 50,
      Cell: ({ row }) =>
        `${
          row.original?.created_at !== '' || 'undefined' || null
            ? format(new Date(row.original?.created_at), 'dd MMM yy hh:mm')
            : 'Birth Date'
        }`,
    }),

    columnHelper.accessor('remarks', {
      header: 'Remarks',
      size: 300,
      Cell: ({ row }) =>
        `${row.original?.details === '' ? 'N/A' : row.original?.details}`,
    }),
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api-b2b.projectota.com/agent/report/ledger/${agentId}`
        );
        setRefetch(!refetch);
        setAllData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Make sure to add any dependencies if needed
  if (isLoading) {
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '70vh',
          width: '70vw',
          marginInline: 'auto',
        }}
      >
        <Box
          style={{
            width: '50%',
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: '100%',
              objectFit: 'center',
            }}
          />
        </Box>
      </Box>
    );
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 11, md: 2 } }}>
        <Box
          sx={{
            margin: '0px 0px 5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              color: 'var(--secondary-color)',
              fontSize: { xs: 18, sm: 18, md: '24px' },
              fontWeight: '500',
            }}
          >
            General Ledger
          </Typography>
        </Box>
      </Container>
      <Box
        sx={{
          p: { xs: 1, md: 2 },
          boxSizing: 'border-box',
        }}
      >
        <CummonTable data={allData} columns={columns} />
      </Box>
    </Box>
  );
};

export default TransactionReport;
