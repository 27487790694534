import {
  Box,
  Button,
  Container,
  Typography,
  CircularProgress,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import Success from '../../images/undraw/undraw_completed_tasks_vs6q.svg';
import Invalid from '../../images/undraw/undraw_warning_re_eoyh.svg';
import { AdminHeader } from '../AdminHeader/AdminHeader';

const AddNotice = () => {
  const adminInfo = secureLocalStorage.getItem('admin-info');
  const token = adminInfo?.token;
  const navigate = useNavigate();
  const location = useLocation();

  const [title, setTitle] = useState(location?.state?.data?.title || '');
  const id = location?.state?.data?.id;
  const editor = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [overview, setOverview] = useState('');

  const addedit = location?.state?.edit || 'add';

  const updateData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = 'https://api-b2b.projectota.com/admin/notice';
    const body = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        notice: overview,
      }),
    };

    try {
      const response = await fetch(url, body);
      const data = await response.json();

      setIsLoading(false);

      if (response.ok) {
        Swal.fire({
          imageUrl: Success,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: 'Notice Added',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          caches
            .keys()
            .then((keyList) =>
              Promise.all(keyList.map((key) => caches.delete(key)))
            );
          navigate(-1);
        });
      } else {
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: data.message || 'Something went wrong',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          caches
            .keys()
            .then((keyList) =>
              Promise.all(keyList.map((key) => caches.delete(key)))
            );
        });
      }
    } catch (err) {
      setIsLoading(false);
      Swal.fire({
        imageUrl: Invalid,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        title: err.message || 'Something went wrong',
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
      }).then(function () {
        caches
          .keys()
          .then((keyList) =>
            Promise.all(keyList.map((key) => caches.delete(key)))
          );
      });
    }
  };

  return (
    <Box>
      <AdminHeader />
      <Container sx={{ pt: 4 }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '24px',
            color: 'var(--mateBlack)',
          }}
        >
          Add New Notices
        </Typography>

        <form onSubmit={updateData}>
          <textarea
            required
            type="text"
            name="notice"
            rows={4}
            value={overview || ''}
            style={{
              border: '1px solid var(--black)',
              width: '100%',
              outline: 'none',
            }}
            placeholder="Notice"
            onChange={(e) => setOverview(e.target.value)}
          />
          <Button
            type="submit"
            sx={{
              background: 'var(--secondary-color)',
              color: 'var(--white)',
              fontSize: '14px',
              '&:hover': {
                background: 'var(--secondary-color)',
                color: 'var(--white)',
              },
              margin: '5px 0px',
            }}
          >
            {isLoading ? (
              <CircularProgress style={{ width: '20px', height: '20px' }} />
            ) : (
              'Save'
            )}
          </Button>
        </form>
      </Container>
    </Box>
  );
};

export default AddNotice;
