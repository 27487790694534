import { Grid, Box, Typography, Tooltip, Stack } from '@mui/material';
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import LiquorIcon from '@mui/icons-material/Liquor';
import TvIcon from '@mui/icons-material/Tv';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UsbIcon from '@mui/icons-material/Usb';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import WifiIcon from '@mui/icons-material/Wifi';
const FlightDetails = ({ data }) => {
  const minitConvert = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };
  return (
    <Grid container spacing={{ xs: 1, sm: 2 }} sx={{background:' linear-gradient(180deg, #FFFFFF 0%, #EAFFFD 100%)'
    }}>
      <Grid
        item
        xs={5}
        sm={6}
        md={2.5}
        textAlign={{ xs: 'start', md: 'start' }}
      >
        <Box>
          <Box
            sx={{
              width: {
                xs: '30px',
                sm: '40px',
              },
              height: {
                xs: '30px',
                sm: '40px',
              },
            }}
          >
            <img
              src={
                data?.MarketingCarrier === 'XY'
                  ? `https://airlineimages.s3.ap-southeast-1.amazonaws.com/128/XY.png`
                  : `https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.MarketingCarrier}.png`
              }
              alt={data?.MarketingCarrier}
              width="100%"
              height="100%"
            />
          </Box>
          <Typography
            sx={{
              color: 'var(--primary-color)',
              fontWeight: 500,
              fontSize: '12px',
            }}
            noWrap
          >
            {data?.MarketingCarrierName}
            <br />
            <span
              style={{
                color: 'var(--fontcolor)',
              }}
            >
              {data?.MarketingCarrier} {data?.MarketingFlightNumber} &{' '}
              {data?.SegmentCode?.bookingCode}
            </span>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={7} sm={6} md={3.5} textAlign={{ xs: 'end', md: 'start' }}>
        <Typography fontSize={{ xs: 13, sm: 20 }} noWrap>
          <span
            style={{
              color: 'var(--primary-color)',
            }}
          >
            {data?.DepLocation?.slice(0, -2)}
          </span>
          <span
            style={{
              color: 'var(--primary-color)',
              fontSize: '12px',
            }}
          >
            {data?.DepFrom}
          </span>
          <br />
          <Tooltip title={`${data?.DepAirPort}`}>
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: { xs: 12, sm: 13 },
              }}
              noWrap
            >
              {data?.DepAirPort}
            </Typography>
          </Tooltip>

          <Typography
            style={{
              color: 'var(--fontcolor)',
              fontSize: '12px',
            }}
          >
            {moment(data?.DepTime?.split('+')[0])?.format('DD MMM YYYY h:mmA')}
          </Typography>
        </Typography>
        {/* aminities */}
      {/* icons */}
      <Box sx={{display:'flex',jusitfyContent:"space-between",alignItems:'center',gap:"10px",mt:3}}>
        <Tooltip
            title={` Alcoholic Cost: ${
              data?.Amenities?.beverage?.alcoholicCost
            }
            
            Non Alcoholic Cost: ${
              data?.Amenities?.beverage?.nonAlcoholicCost
            }
          Exist: ${
            data?.Amenities?.beverage?.exists
            }
            
            
            
            `}
          >
                < LiquorIcon sx={{fontSize:"16px",color:'var(--fontcolor)'}}/>
          </Tooltip>
   

          <Tooltip
            title={`Cost: ${
              data?.Amenities?.entertainment?.cost
            }
            
            Type: ${
              data?.Amenities?.entertainment?.type
            }
          Exist: ${
            data?.Amenities?.beverage?.exists
            }
            
            
            
            `}
          >
           <TvIcon sx={{fontSize:"16px",color:'var(--fontcolor)'}}/>
          </Tooltip>








          <Tooltip
            title={`Cost: ${
              data?.Amenities?.food?.cost
            }
            
            Type: ${
              data?.Amenities?.food?.type
            }
          Exist: ${
            data?.Amenities?.food?.exists
            }
            
            
            
            `}
          >
< FastfoodIcon sx={{fontSize:"16px",color:'var(--fontcolor)'}}/>
</Tooltip>



<Tooltip
            title={`Row Layout: ${
              data?.Amenities?.layout?.rowLayout
            }
            
            Direct Aisle Access: ${
              data?.Amenities?.layout?.directAisleAccess
            }
         
            
            
            
            `}
          >
            <DashboardIcon  sx={{fontSize:"16px",color:'var(--fontcolor)'}}/>
</Tooltip>





<Tooltip
            title={`Cost: ${
              data?.Amenities?.power?.cost
            }
            
            Distribution: ${
              data?.Amenities?.power?.distribution
            }
         
            Type: ${
              data?.Amenities?.power?.type
            }
         
            
            
            `}
          >

<UsbIcon sx={{fontSize:"16px",color:'var(--fontcolor)'}}/>
          </Tooltip>






          <Tooltip
            title={`Pitch: ${
             data?.Amenities?.seat?.pitch
            }
           
         
            Type: ${
              data?.Amenities?.seat?.type
            }
         
            
            
            `}>
              <EventSeatIcon sx={{fontSize:"16px",color:'var(--fontcolor)'}}/>
            </Tooltip>
            <Tooltip
            title={`
         
            Exists: ${
              data?.Amenities?.wifi?.exists
            }
         
            
            
            `}>
<WifiIcon sx={{fontSize:"16px",color:'var(--fontcolor)'}}/>
</Tooltip>
        </Box>
      </Grid>
      <Grid item xs={5} sm={6} md={2.5} margin="auto">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            // boxShadow: 'rgba(153, 206, 201, 0.384) 0px 7px 29px 0px',
            borderRadius: '5px',
            py: 0.5,
          }}
        >
          <Box>
            <Box
              sx={{
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                width: { xs: '25px', md: '40px' },
                height: { xs: '25px', md: '40px' },
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                ml: {
                  xs: 0,
                  md: data?.length - 1 > 0 ? 1 : 0.5,
                },
              }}
            >
              <EastRoundedIcon
                sx={{
                  color: 'var(--fontcolor)',
                  bgcolor: 'var(--bgcolor)',
                  borderRadius: '50%',
                  p: 0.5,
                  fontSize: { xs: '20px', md: '30px' },
                }}
              />
            </Box>
            <Typography
              sx={{
                color: 'var(--primary-color)',
                fontWeight: 500,
                fontSize: {
                  xs: '10px',
                  md: '12px',
                },
              }}
            >
              {minitConvert(data?.Duration)}
            </Typography>
          </Box>
          {/* <Box className="stop-bar-parent">
            <CircleIcon
              sx={{
                color: 'var(--transit)',
                fontSize: '15px',
              }}
            />
            <Box className="stop-bar-line-details"></Box>
            <CircleIcon
              sx={{
                color: 'var(--transit)',
                fontSize: '15px',
              }}
            />
          </Box> */}
        </Stack>
      </Grid>
      <Grid item xs={7} sm={6} md={3.5} textAlign="end">
        <Typography fontSize={{ xs: 13, sm: 20 }} noWrap>
          <span
            style={{
              color: 'var(--primary-color)',
            }}
          >
            {data?.ArrLocation?.slice(0, -2)}
          </span>
          <span
            style={{
              color: 'var(--primary-color)',
              fontSize: '12px',
            }}
          >
            {data?.ArrTo}
          </span>
          <br />

          <Tooltip title={`${data?.ArrAirPort}`}>
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: { xs: 12, sm: 13 },
              }}
              noWrap
            >
              {data?.ArrAirPort}
            </Typography>
          </Tooltip>

          <Typography
            style={{
              color: 'var(--fontcolor)',
              fontSize: '12px',
            }}
          >
            {moment(data?.ArrTime?.split('+')[0])?.format('DD MMM YYYY h:mmA')}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FlightDetails;
