import React, { useState } from 'react';
import { Box } from '@mui/system';
import Oneway from './Oneway';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Multicity from '../MultiCity/Multicity';
import { Collapse, Grid, Tab, Typography } from '@mui/material';
import './FlightSearchBox.css';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import ServerDown from '../../images/undraw/undraw_server_down_s-4-lk.svg';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AirlinesData from '../../Admin/AdminBooking/Airlines';
const FlightSearchBox = ({
  options,
  setOptions,
  value,
  setValue,
  fromSearchText,
  setFromSearchText,
  toSearchText,
  setToSearchText,
  from,
  setFrom,
  to,
  setTo,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  kids,
  setKids,
  result,
  setResult,
  className,
  setClassName,
  changeFrom,
  setChangeFrom,
  changeState,
  directFlightOnly,
  setDirectFlightOnly,
  searchData,
  setSearchData,
  setChangeState,
}) => {
  const user = secureLocalStorage.getItem('user-info');
  // const data = AirlinesData;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [extraBaggage, setExtraBaggage] = useState(false);
  const [brandedFare, setBrandedFare] = useState(false);
  const [nonStop, setNonStop] = useState(false);
  const [shortConnection, setShortConnection] = useState(false);
  const [longConnection, setLongConnection] = useState(false);

  const [connectionValue, setConnectionValue] = useState(0);

  const [currency, setCurrency] = useState('');
  const [airlineValue, setAirlineValue] = useState('');

  const handleChangeCurrency = (event) => {
    setCurrency(event.target.value);
  };

  const handleChangeOptions = (event) => {
    setDirectFlightOnly(event.target.checked);
  };
  const handleClassName = (event) => {
    setClassName(event.target.value);
    setSearchData({ ...searchData, cabinclass: event.target.value });
  };

  const [openAirlines, setOpenAirlines] = useState(false);

  let handleOpenPreferedAirlines = () => {
    setOpenAirlines(!openAirlines);
  };
  let handleSetAirlineValue = (value) => {
    console.log(value);
    setAirlineValue(value);
    setOpenAirlines(false);
  };

  // const [directFlightOnly, setDirectFlightOnly] = useState(false);
  // const handleChangeOptions = (event) => {
  //   setDirectFlightOnly(event.target.checked);
  // };

  //------------------ for Multicity Search Data

  // const [searchData, setSearchData] = useState({
  //   agentId: user?.user?.agentId || "",
  //   adultCount: adultCount,
  //   childCount: childCount,
  //   infantCount: infant,
  //   connection: directFlightOnly ? 1 : 0,
  //   cabinclass: className || "Y",
  //   segments: [
  //     {
  //       id: 0,
  //       openFrom: false,
  //       DepFrom: fromSendData.trim(),
  //       depFromText: fromSearchText.trim(),
  //       ArrTo: "DXB",
  //       arrToText: "Dubai Intl Airport [DXB]",
  //       openTo: false,
  //       Date: new Date().toLocaleDateString("sv"),
  //       openDate: false,
  //       open: false,
  //     },
  //   ],
  // });
  // const user = secureLocalStorage.getItem("user-info");

  const navigate = useNavigate();
  // //todo: state for from date change
  // const [changeFrom, setChangeFrom] = useState(false);
  // const [changeState, setChangeState] = useState(false);
  // //todo: End state for from date change
  // const [value, setValue] = React.useState("oneway");
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const [directFlightOnly, setDirectFlightOnly] = useState(false);
  // const handleChangeOptions = (event) => {
  //   setDirectFlightOnly(event.target.checked);
  // };
  // const [departureDate, setDepartureDate] = useState(
  //   format(addDays(new Date(), 1), "dd MMM yy")
  // );
  // const [returningDate, setReturningDate] = useState(
  //   format(addDays(new Date(departureDate), 3), "dd MMM yy")
  // );
  // const [travelDate, setTravelDate] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 3),
  //     key: "selection",
  //   },
  // ]);

  // const now = useRef(new Date());
  // const [from, setFrom] = useState(addDays(now.current, 1));
  // const [to, setTo] = useState(addDays(now.current, 3));
  // const [fromSendData, setFromSendData] = useState("RUH");
  // const [fromSearchText, setFromSearchText] = useState("King Khaled Intl[RUH]");
  // const [faddress, setfaddress] = useState("Riyadh,SAUDI ARABIA");
  // const [toSendData, setToSendData] = useState("JED");
  // const [toSearchText, setToSearchText] = useState("Jeddah Intl[JED]");
  // const [toAddress, setToAddress] = useState("Jeddah,SAUDI ARABIA");
  // const [adultCount, setAdultCount] = useState(1);
  // const [childCount, setChildCount] = useState(0);
  // const [infant, setInfant] = useState(0);
  // const [result, setResult] = useState(1);

  // const [className, setClassName] = useState("Y");
  // const handleClassName = (event) => {
  //   setClassName(event.target.value);
  // };

  // const [options, setOptions] = useState({
  //   showCalendarAvailability: false,
  //   directFlightOnly: false,
  //   includeCheckedBaggage: false,
  // });

  // const [searchData, setSearchData] = useState({
  //   agentId: user?.user?.agentId || "",
  //   adultCount: adultCount,
  //   childCount: childCount,
  //   infantCount: infant,
  //   connection: 1,
  //   cabinclass: className || "Y",
  //   segments: [
  //     {
  //       id: 0,
  //       openFrom: false,
  //       DepFrom: fromSendData.trim(),
  //       depFromText: fromSearchText.trim(),
  //       ArrTo: "DXB",
  //       arrToText: "Dubai Intl Airport [DXB]",
  //       openTo: false,
  //       Date: new Date().toLocaleDateString("sv"),
  //       openDate: false,
  //       open: false,
  //     },
  //   ],
  // });

  // --------------------New Search Function Start --------------------

  console.log({
    adultcount: adultCount,
    kidscount: kids,
    childcount: childCount,
    infantcount: infant,
    cabinclass: className,
    brandedfare: brandedFare,
    extrabaggage: extraBaggage,
    currency: currency,
    connection: connectionValue,
    airline: airlineValue,
  });
  async function handleSearch() {
    if (value === 'oneway') {
      navigate('/user/searchresult', {
        state: {
          faddress,
          toAddress,
          fromSearchText,
          toSearchText,
          departureDate: format(new Date(from), 'dd MMM yy'),

          // adultcount:adultCount,
          // kidscount: kids,
          // childcount:childCount,
          // infantcount:infant,
          // cabinclass:className,
          // brandedfare:brandedFare,
          // extrabaggage:extraBaggage,
          // currency:currency,
          // connection:connectionValue,
          // airline:airlineValue,
          nonStop,

          brandedFare,
          extraBaggage,
          currency,
          connectionValue,
          airlineValue,

          adultCount,
          childCount,
          infant,
          kids,
          tripType: value,
          fromSendData,
          toSendData,
          className,
          changeState,
          directFlightOnly,
        },
      });
    } else if (value === 'return') {
      navigate('/user/roundsearchresult', {
        state: {
          faddress,
          toAddress,
          departureDate: format(new Date(from), 'dd MMM yy'),
          returningDate: format(new Date(to), 'dd MMM yy'),
          adultCount,
          childCount,
          infant,
          tripType: value,
          fromSendData,
          toSendData,
          fromSearchText,
          toSearchText,
          className,
          changeState,
          directFlightOnly,
        },
      });
    } else if (value === 'multicity') {
      navigate('/user/multicityaftersearch', {
        state: {
          faddress,
          toAddress,
          fromSearchText,
          toSearchText,
          departureDate: format(new Date(from), 'dd MMM yy'),
          adultCount,
          childCount,
          infant,
          tripType: value,
          fromSendData,
          toSendData,
          className,
          searchData,
          changeState,
          directFlightOnly,
        },
      });
    } else {
      Swal.fire({
        imageUrl: ServerDown,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
        confirmButtonText: 'Search Again...',
        confirmButtonColor: 'var(--primary-color)',
      }).then(() => {
        // setClick(false);
        navigate('/user/dashboard');
      });
    }
  }

  const handleExtraCheckboxChange = (event) => {
    if (event.target.checked) {
      setExtraBaggage(event.target.checked);
    } else {
      setExtraBaggage(false);
    }
  };

  const handleBrandedFareChange = (event) => {
    if (event.target.checked) {
      setBrandedFare(event.target.checked);
    } else {
      setBrandedFare(false);
    }
  };

  const handleNonStopChange = (event) => {
    if (event.target.checked) {
      setNonStop(event.target.checked);
    } else {
      setNonStop(false);
    }
  };

  const handleShortConnectionChange = (event) => {
    if (event.target.checked) {
      setConnectionValue(2);
      setShortConnection(event.target.checked);
      setLongConnection(false);
    } else {
      setConnectionValue(0);
      setShortConnection(false);
    }
  };

  const handleLongConnectionChange = (event) => {
    if (event.target.checked) {
      setConnectionValue(4);
      setLongConnection(event.target.checked);
      setShortConnection(false);
    } else {
      setConnectionValue(0);
      setLongConnection(false);
    }
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchAirlines = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAirlines = AirlinesData.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log(airlineValue);

  return (
    <Box sx={{ width: '100%', padding: '10px 0px' }}>
      {/* airlines */}
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={extraBaggage}
                onChange={(e) => handleExtraCheckboxChange(e)}
                sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
              />
            }
            label={'Extra Baggage'}
            sx={{ color: 'white' }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={brandedFare}
                onChange={(e) => handleBrandedFareChange(e)}
                sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
              />
            }
            label={'Branded Fare'}
            sx={{ color: 'white' }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={nonStop}
                onChange={(e) => handleNonStopChange(e)}
                sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
              />
            }
            label={'Non Stop'}
            sx={{ color: 'white' }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={shortConnection}
                onChange={(e) => handleShortConnectionChange(e)}
                sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
              />
            }
            label={'Short Connection'}
            sx={{ color: 'white' }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={longConnection}
                onChange={(e) => handleLongConnectionChange(e)}
                sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
              />
            }
            label={'Long Connection'}
            sx={{ color: 'white' }}
          />
        </FormGroup>

        <Box
          onClick={() => handleOpenPreferedAirlines()}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: '5px',
            position: 'relative',
          }}
        >
          {openAirlines && (
            <Box
              sx={{
                position: 'absolute',
                top: '90%',
                left: '0',
                right: '0',
                width: '100%',
                backgroundColor: 'var( --white)',
                height: 'fit-content',
                borderBottom: '1px solid var(  --gray)',
                borderLeft: '1px solid var(  --gray)',
                borderRight: '2px solid var(  --gray)',
                borderRadius: '0px 0px 5px 5px',
                zIndex: '999',
                padding: '3px 5px 0px',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'var(--secondary-color)',
                  zIndex: 10,
                }}
                backgroundColor="#fff"
              >
                <input
                  autoComplete="off"
                  autoFocus
                  value={searchTerm}
                  onChange={handleSearchAirlines}
                  placeholder="Search a airlines..."
                  className="customPlaceholder"
                  style={{
                    color: 'var(--secondary-color)',
                    fontWeight: 500,
                    // paddingLeft: '20px',
                    width: '100%',
                    height: '40px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    outline: 'none',
                  }}
                />
              </Box>

              {filteredAirlines?.slice(0, 5).map((item, index) => (
                <Box
                  onClick={() => handleSetAirlineValue(item.code)}
                  key={index}
                  sx={{ mb: 2 }}
                >
                  <Typography>{item.code}</Typography>
                </Box>
              ))}
            </Box>
          )}

          <Typography sx={{ color: 'var(--white)' }}>
            Prefered Airlines:
          </Typography>
          <Box>
            <Typography sx={{ color: 'var(--white)' }}>
              {airlineValue ? airlineValue : 'Select Airlines'}
            </Typography>
          </Box>
        </Box>
      </Box>

      <TabContext value={value}>
        <Grid container spacing={2}>
          <Grid item md={2} sx={{ borderRight: '1px solid #808080' }}>
            <Box
              sx={{
                width: '100%',
                height: { md: 'fit-content', sm: '100%', xs: '100%' },
                display: 'flex',
                justifyContent: {
                  md: 'space-between',
                  sm: 'center',
                  xs: 'center',
                },
                alignItems: 'center',
                opacity: '1',
                '.MuiTabs-root': {
                  minHeight: 'fit-content',
                },
                '& button': {
                  opacity: '1',
                  borderRadius: '5px',
                  background: 'transparent',
                  color: 'var(--secondary-color)',
                  marginRight: '2px',
                  width: 'fit-content',
                  minHeight: 'fit-content',
                  padding: '10px',
                  fontSize: { xs: 12, md: 14 },
                },
                '& button.Mui-selected,& button.Mui-selected >svg': {
                  background: 'var(--white)',
                  color: 'var(--primary-color) !important',
                },
              }}
            >
              <TabList
                variant="scrollable"
                orientation="vertical"
                scrollButtons
                allowScrollButtonsMobile
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
              >
                <Tab label="One Way" value="oneway" />
                <Tab label="Round Way" value="return" />
                <Tab label="Multi City" value="multicity" />
              </TabList>
              {/* <FormGroup
            row
            sx={{
              fontSize: '10px',
              '& .MuiCheckbox-root.Mui-checked,.MuiCheckbox-root.Mui-checked > .MuiSvgIcon-root':
                {
                  color: 'var(--secondary-color)',
                },
              '.MuiSvgIcon-root': {
                color: 'var(--secondary-color)',
              },
              gap: '5px',
              color: 'var(--primary-color)',
              display: {
                xs: 'none',
                md: value === 'multicity' ? 'none' : 'block',
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={directFlightOnly}
                  onChange={handleChangeOptions}
                  name="directFlightOnly"
                />
              }
              label="Direct FLights"
            />
          </FormGroup> */}
            </Box>
          </Grid>
          <Grid item md={10}>
            <TabPanel value="oneway" style={{ padding: '10px 0px 0px' }}>
              <Oneway
                currency={currency}
                setCurrency={setCurrency}
                handleChangeCurrency={handleChangeCurrency}
                tripType={value}
                iconColor={'#DC143C'}
                bgColor={'#fff'}
                bordercolor={'#003566'}
                faddress={faddress}
                fromSendData={fromSendData}
                setFromSendData={setFromSendData}
                toSendData={toSendData}
                setToSendData={setToSendData}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                fromSearchText={fromSearchText}
                setFromSearchText={setFromSearchText}
                toSearchText={toSearchText}
                setToSearchText={setToSearchText}
                setValue={setValue}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                setInfant={setInfant}
                kids={kids}
                setKids={setKids}
                result={result}
                setResult={setResult}
                className={className}
                handleClassName={handleClassName}
                to={to}
                setTo={setTo}
                from={from}
                setFrom={setFrom}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
                directFlightOnly={directFlightOnly}
                setChangeState={setChangeState}
                changeState={changeState}
                // setDirectFlightOnly={setDirectFlightOnly}
                handleSearch={handleSearch}
              />
            </TabPanel>
            <TabPanel value="return" style={{ padding: '10px 0px 0px' }}>
              <Oneway
                tripType={value}
                iconColor={'#DC143C'}
                bgColor={'#fff'}
                bordercolor={'#003566'}
                faddress={faddress}
                fromSendData={fromSendData}
                setFromSendData={setFromSendData}
                toSendData={toSendData}
                setToSendData={setToSendData}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                fromSearchText={fromSearchText}
                setFromSearchText={setFromSearchText}
                toSearchText={toSearchText}
                setToSearchText={setToSearchText}
                setValue={setValue}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                kids={kids}
                setKids={setKids}
                setInfant={setInfant}
                result={result}
                setResult={setResult}
                className={className}
                handleClassName={handleClassName}
                to={to}
                setTo={setTo}
                from={from}
                setFrom={setFrom}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
                directFlightOnly={directFlightOnly}
                setChangeState={setChangeState}
                changeState={changeState}
                // setDirectFlightOnly={setDirectFlightOnly}
                handleSearch={handleSearch}
              />
            </TabPanel>

            <TabPanel value="multicity" style={{ padding: '10px 0px 0px' }}>
              <Multicity
                tripType={value}
                iconColor={'#DC143C'}
                bgColor={'#fff'}
                bordercolor={'#003566'}
                faddress={faddress}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                fromSendData={fromSendData}
                setFromSendData={setFromSendData}
                toSendData={toSendData}
                setToSendData={setToSendData}
                fromSearchText={fromSearchText}
                setFromSearchText={setFromSearchText}
                toSearchText={toSearchText}
                setToSearchText={setToSearchText}
                setValue={setValue}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                setInfant={setInfant}
                kids={kids}
                setKids={setKids}
                result={result}
                setResult={setResult}
                className={className}
                setClassName={setClassName}
                handleClassName={handleClassName}
                to={to}
                setTo={setTo}
                from={from}
                setFrom={setFrom}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
                searchData={searchData}
                setSearchData={setSearchData}
                directFlightOnly={directFlightOnly}
                setDirectFlightOnly={setDirectFlightOnly}
                handleSearch={handleSearch}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </TabContext>
    </Box>
  );
};

export default FlightSearchBox;
