import { Box, Typography } from '@mui/material'
import React from 'react'

const AccountManager = () => {
  return (
    <>
    <Box sx={{mt:2}}>
    <Box sx={{borderBottom:"1px solid var(--primary-color)",pb:2}}>
        <Typography sx={{color:'var(--primary-color)',fontSize:"21px",fontWeight:'500'}}> Account Manager </Typography>
    </Box>

    <Box sx={{mt:2}}>
<Box sx={{borderBottom:"1px solid var(--primary-color)",display:"flex",justifyContent:"space-between",alignItems:'center',pb:1}}>
       <Box sx={{flex:1,display:"flex",justifyContent:"space-between",alignItems:'center'}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> Name</Typography>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> :</Typography>
       </Box>
       <Box sx={{flex:1}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> Profile Status</Typography>
       </Box>
    </Box>




    <Box sx={{borderBottom:"1px solid var(--primary-color)",display:"flex",justifyContent:"space-between",alignItems:'center',pb:1,mt:1}}>
       <Box sx={{flex:1,display:"flex",justifyContent:"space-between",alignItems:'center'}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> Email</Typography>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> :</Typography>
       </Box>
       <Box sx={{flex:1}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> Profile Status</Typography>
       </Box>
    </Box>







    <Box sx={{borderBottom:"1px solid var(--primary-color)",display:"flex",justifyContent:"space-between",alignItems:'center',pb:1,mt:1}}>
       <Box sx={{flex:1,display:"flex",justifyContent:"space-between",alignItems:'center'}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}>  Mobile</Typography>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> :</Typography>
       </Box>
       <Box sx={{flex:1}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> Profile Status</Typography>
       </Box>
    </Box>








    <Box sx={{borderBottom:"1px solid var(--primary-color)",display:"flex",justifyContent:"space-between",alignItems:'center',pb:1,mt:1}}>
       <Box sx={{flex:1,display:"flex",justifyContent:"space-between",alignItems:'center'}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> Office</Typography>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> :</Typography>
       </Box>
       <Box sx={{flex:1}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> Profile Status</Typography>
       </Box>
    </Box>




    <Box sx={{borderBottom:"1px solid var(--primary-color)",display:"flex",justifyContent:"space-between",alignItems:'center',pb:1,mt:1}}>
       <Box sx={{flex:1,display:"flex",justifyContent:"space-between",alignItems:'center'}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> Member Since</Typography>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> :</Typography>
       </Box>
       <Box sx={{flex:1}}>
       <Typography sx={{color:'var(--secondary-color)',fontSize:"13px",fontWeight:'500'}}> Profile Status</Typography>
       </Box>
    </Box>












</Box>

    </Box>
    </>
  )
}

export default AccountManager