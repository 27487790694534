import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import commaNumber from 'comma-number';
import { addDays, format } from 'date-fns';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import Header from '../Header/Header';
import CummonTable from '../CommonTable/CummonTable';
import { createMRTColumnHelper } from 'material-react-table';

const LedgerReport = () => {
  const columnHelper = createMRTColumnHelper();
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem('user-info');
  let uid = user?.uid;

  const [ledgerData, setLedgerData] = useState([]);
  const [startDate, setStartDate] = useState(format(new Date(), 'dd MMM yyyy'));
  const [endDate, setEndDate] = useState(
    format(addDays(new Date(), 1), 'dd MMM yyyy')
  );
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  const columns = [
    columnHelper.accessor('trxtype', {
      header: 'Type',
      size: 10,
      Cell: ({ row }) => (
        <Typography
          sx={{ fontSize: '12px', textAlign: 'center' }}
          className={`${row.original?.trxtype
            ?.toLowerCase()
            ?.split(' ')
            ?.join('-')}-btn`}
        >
          {row.original?.trxtype || 'Status'}
        </Typography>
      ),
    }),
    columnHelper.accessor('refId', {
      header: 'TransactionId',
      size: 20,
      // Cell: ({ row }) =>
      //   `${commaNumber(Number.parseInt(row.original?.amount) || 0)} AED`,
    }),
    columnHelper.accessor('amount', {
      header: 'Amount',
      size: 200,
      Cell: ({ row }) =>
        `${commaNumber(Number.parseInt(row.original?.amount) || 0)} AED`,
    }),
    columnHelper.accessor('created_at', {
      header: 'Date',
      size: 50,
      Cell: ({ row }) =>
        `${
          row.original?.created_at !== '' || 'undefined' || null
            ? format(new Date(row.original?.created_at), 'dd MMM yy hh:mm')
            : 'Birth Date'
        }`,
    }),

    columnHelper.accessor('remarks', {
      header: 'Remarks',
      size: 300,
      Cell: ({ row }) =>
        `${row.original?.details === '' ? 'N/A' : row.original?.details}`,
    }),
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = `https://api-b2b.projectota.com/agent/report/${uid}/${new Date(
      startDate
    ).toLocaleDateString('sv')}/${new Date(endDate).toLocaleDateString('sv')}`;
    await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setLedgerData(data);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          navigate('/user/dashboard');
        });
      });
  };

  const handleClickAway = () => {
    setOpenEndDate(false);
    setOpenStartDate(false);
  };

  return (
    <Box>
      {/* <Header /> */}
      <Box sx={{ mx:4}}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              position: 'relative',
              display: { xs: 'block', sm: 'flex', md: 'flex' },
              justifyContent: 'space-between',
              alignItems: 'end',
              mb: 1,
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: '28px',
                color: 'var(--secondary-color)',
              }}
            >
              Report
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                alignItems="end"
                justifyContent={'start'}
              >
                <Grid
                  style={{ position: 'relative' }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Tooltip title="Enter Start Date">
                      <input
                        required
                        id="startDate"
                        name="startDate"
                        style={{
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px',
                          padding: '5px 10px',
                          cursor: 'pointer',
                        }}
                        type="text"
                        value={startDate}
                        readOnly
                        onClick={() => {
                          setOpenEndDate(false);
                          setOpenStartDate((prev) => !prev);
                        }}
                      />
                    </Tooltip>
                  </Box>
                  {openStartDate && (
                    <Calendar
                      color={'var(--primary-color)'}
                      date={new Date(startDate)}
                      onChange={(date) => {
                        setStartDate(format(new Date(date), 'dd MMM yyyy'));
                        setOpenStartDate(false);
                      }}
                      maxDate={addDays(new Date(), -1)}
                      months={1}
                      className="new-dashboard-calendar"
                      name="dashboard-calendar"
                    />
                  )}
                </Grid>

                <Grid
                  style={{ position: 'relative' }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Tooltip title="Enter End Date">
                      <input
                        required
                        id="endDate"
                        name="endDate"
                        style={{
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px',
                          padding: '5px 10px',
                          cursor: 'pointer',
                        }}
                        type="text"
                        value={endDate}
                        readOnly
                        onClick={() => {
                          setOpenEndDate((prev) => !prev);
                          setOpenStartDate(false);
                        }}
                      />
                    </Tooltip>
                  </Box>
                  {openEndDate && (
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(endDate)}
                      onChange={(date) => {
                        setEndDate(format(new Date(date), 'dd MMM yyyy'));
                        setOpenEndDate(false);
                      }}
                      months={1}
                      className="new-dashboard-calendar"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end',
                    gap: '5px',
                  }}
                >
                  <Button
                    sx={{
                      background: 'var(--secondary-color)',
                      color: 'white',
                      padding: '5px 20px !important',
                      fontSize: '12px !important',
                      width: '100%',
                      '&:hover': {
                        background: 'var(--secondary-color)',
                        color: 'white',
                      },
                    }}
                    type="submit"
                  >
                    PROCEED
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </ClickAwayListener>
      </Box>
      {ledgerData.length !== 0 ? (
        <Box
          sx={{
            p: { xs: 1, md: 2 },
            boxSizing: 'border-box',
          }}
        >
          <CummonTable data={ledgerData} columns={columns} />
        </Box>
      ) : (
        <Container mt={1}>
          <Typography style={{ background: '#ccc', padding: '4px 8px' }}>
            No Records found...!
          </Typography>
        </Container>
      )}
    </Box>
  );
};

export default LedgerReport;
