import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import secureLocalStorage from 'react-secure-storage';
import './TravelCalender.css';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';

const TravelCalender = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date().getDate());
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [allBookingData, setAllBookingData] = useState([]);
  const user = secureLocalStorage.getItem('user-info');
  const agentId = user?.uid;
  const [month, setMonth] = useState(moment(new Date()).format('YYYY-MM'));
  const [thatDayBookings, setThatDayBookings] = useState(
    allBookingData
      ?.filter(
        (item) => new Date(item?.date).getDate() === new Date().getDate()
      )
      ?.map((data) => data?.data) || []
  );

  const handleSelectDate = (date) => {
    setSelectedDate(new Date(date).getDate());
  };

  const handleNextMonth = (months) => {
    const nextMonth = moment(months).add(1, 'month').format('YYYY-MM');
    setMonth(nextMonth);
    setThatDayBookings([]);
  };

  const handlePrevMonth = (months) => {
    const prevMonth = moment(months).subtract(1, 'month').format('YYYY-MM');
    setMonth(prevMonth);
    setThatDayBookings([]);
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    const url = `https://api-b2b.projectota.com/agent/booking/calendare/${agentId}/${month}`;
    try {
      const response = await axios.get(url);
      setRefetch(true);
      setBookingData(response.data);
      setAllBookingData(response.data);
    } catch (error) {
      setError(error.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };
  console.log(month);
  useEffect(() => {
    fetchData();
  }, [month]);

  const sendToQueuesDetails = (item) => {
    navigate(
      `/user/congratulation/${item?.uid}/${item.bookingId}/${item?.triptype}`,
      {
        state: {
          data: item,
          pnr: item?.pnr,
          queues: 'queues',
        },
      }
    );
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 3, sm: 1 }}>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ width: '100%' }}>
          <Box
            sx={{
              Height: { xs: 'auto', sm: '40vh', md: '40vh' },
              width: '100%',
            }}
          >
            <Typography
              sx={{
                width: '100%',
                display: 'flex',
                alignItem: 'center',
                justifyContent: 'space-between',
                color: 'var(--primary-color)',
                fontSize: '20px',
                fontWeight: '500',
                my: '5px',
              }}
            >
              <ArrowBackIosIcon
                sx={{ cursor: 'pointer' }}
                fontSize="20px"
                onClick={() => {
                  handlePrevMonth(month);
                }}
              />
              {moment(month).format('MMM YYYY')}
              <ArrowForwardIosIcon
                sx={{ cursor: 'pointer' }}
                fontSize="20px"
                onClick={() => {
                  handleNextMonth(month);
                }}
              />
            </Typography>
            {!loading ? (
              <Grid container spacing={0.5}>
                {bookingData?.map((item, index) => (
                  <Grid
                    item
                    xs={2}
                    sm={1.71}
                    md={2}
                    letterSpacing={1.7}
                    key={index}
                  >
                    <Box
                      onClick={() => {
                        handleSelectDate(item?.date);
                        setThatDayBookings(item?.data);
                      }}
                      sx={{
                        position: 'relative',
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow:
                          item?.count !== 0
                            ? '0 0 10px rgba(9, 152, 138, 0.5)'
                            : 0,
                        borderRadius: '4px',
                        padding: '10px',
                        background: item?.count === 0 ? '#dddd' : 'white',
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'gray',
                          fontSize: '14px',
                          fontWeight: '500',
                        }}
                      >
                        {moment(item?.date).format('DD')}
                      </Typography>
                      {item?.count !== 0 ? (
                        <Typography
                          sx={{
                            position: 'absolute',
                            top: -5,
                            right: -5,
                            background: '#54B435',
                            color: 'white',
                            borderRadius: '50%',
                            height: '20px',
                            width: '20px',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            zIndex: 9999,
                            fontSize: '12px',
                          }}
                        >
                          {item?.count}
                        </Typography>
                      ) : null}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box width="100%">
                <CircularProgress sx={{ color: 'var(--primary-color)' }} />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ width: '100%' }}>
          <Box
            sx={{
              Height: { xs: 'auto', sm: '35vh', md: '35vh' },
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {thatDayBookings?.length !== 0 ? (
              thatDayBookings?.map((booking, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      boxShadow: ' 0 0 10px rgba(9, 152, 138, 0.5)',
                      m: '5px',
                      p: '5px 10px',
                      borderRadius: '5px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => sendToQueuesDetails(booking)}
                  >
                    <Box
                      sx={{
                        width: '75%',
                        display: 'flex',
                        flexDirection: 'column',
                        '.MuiTypography-root': {
                          textAlign: 'start',
                          fontSize: '12px',
                        },
                      }}
                    >
                      <Typography>{booking?.bookingId}</Typography>
                      <Typography>{booking?.name}</Typography>
                      <Typography>{booking?.carrier}</Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '25%',
                        display: 'flex',
                        flexDirection: 'column',
                        '.MuiTypography-root': {
                          textAlign: 'end',
                          fontSize: '12px',
                          color: 'var(--primary-color)',
                          fontWeight: '500',
                        },
                      }}
                    >
                      <Typography>{booking?.depfrom}</Typography>
                      <Typography>{booking?.arrto}</Typography>
                      <Typography sx={{ color: 'orange !important' }}>
                        View
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box>
                <Typography
                  sx={{
                    width: '100%',
                    background: 'var(--tab-bg)',
                    color: 'gray',
                    padding: '5px 0px',
                    borderRadius: '4px',
                    textAlign: 'center',
                  }}
                >
                  No Booking Found
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TravelCalender;
