import { Box, Grid, Link, Typography } from '@mui/material';
import commaNumber from 'comma-number';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import ConfirmImg from '../../images/homePage/approved.png';
import DollerImg from '../../images/homePage/coin.png';
import SearchImg from '../../images/homePage/search.png';
import CalenderImg from '../../images/homePage/weekly-calendar.png';

const HomePageStack = () => {
  const navigate = useNavigate();
  const [allQueuesData, setAllQueuesData] = useState({});
  const users = secureLocalStorage.getItem('user-info');
  let agentId = users?.uid;
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/Stats/index.php?agentId=${agentId}`)
      .then((res) => res.json())
      .then((data) => {
        setAllQueuesData(data);
      });
    //todo: end of fetch all data
  }, [agentId]);

  useEffect(() => {
    const url = `https://api-b2b.projectota.com/agent/myaccount/${agentId}`;
    // const body = JSON.stringify({
    //   agentId,
    // });
    const config = {
      method: 'GET',
      header: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      // body,
    };
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => setBalance(data?.balance))
      .catch((err) => console.log(err.message));
  }, [balance, agentId]);

  return (
    <Box>
      <Box
        sx={{
          
          // width: '100%',
          // height: '100%',
          // display: 'flex',
          // flexDirection: 'column',
          // gap: '20px',
          // justifyContent: 'start',
          // alignItems: 'space-around',
        }}
      >
<Grid container columnSpacing={5} >
  <Grid item lg={2.6}>



        <Box
          sx={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            padding:"16px",
            borderRadius:"24px",
            height:'136px'
            // textDecoration: 'none',
            // width: '100%',
            // height: 'fit-content',
            // background: 'var(--white)',
            // borderRadius: '10px',
            // display: 'flex',
            // flexDirection:'column',
            // justifyContent: 'start',
            // alignItems: 'center',
            // padding: '5px 15px',
            // gap: '10px',
          }}
        >
           <Box
            // sx={{
            //   width: '80%',
            // }}
          >
      
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '500',
              }}
            >
         Total Booking Today
            </Typography>
          </Box>
        <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <Box
            // sx={{
            //   // width: '20%',
            //   display: 'flex',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            // }}
          >
            <img src={CalenderImg} alt="..." width="40px" height='40px' />
          </Box>
          <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '1.5rem',

                fontWeight: '500',
              }}
            >
              {allQueuesData?.TodayBooking || 0}
            </Typography>
        </Box>
         
        </Box>
        </Grid>
        <Grid item lg={2.6}>
        <Box
       sx={{
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        padding:"16px",
        borderRadius:"24px",
        height:'136px'
        // textDecoration: 'none',
        // width: '100%',
        // height: 'fit-content',
        // background: 'var(--white)',
        // borderRadius: '10px',
        // display: 'flex',
        // flexDirection:'column',
        // justifyContent: 'start',
        // alignItems: 'center',
        // padding: '5px 15px',
        // gap: '10px',
      }}
        >
                  <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '500',
              }}
            >
              Ticket Issued Today
            </Typography>
 

          <Box
          sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}
          >
                     <Box
            // sx={{
            //   width: '20%',
            //   display: 'flex',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            // }}
          >
            <img src={ConfirmImg} alt="..." width="40px" height='40px' />
          </Box>
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '1.5rem',

                fontWeight: '500',
              }}
            >
              {allQueuesData?.TodayTicketed || 0}
            </Typography>
    
          </Box>
        </Box>
        </Grid>
        <Grid  item lg={2.6}>
        <Box
         sx={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          padding:"16px",
          borderRadius:"24px",
          height:'136px'
          // textDecoration: 'none',
          // width: '100%',
          // height: 'fit-content',
          // background: 'var(--white)',
          // borderRadius: '10px',
          // display: 'flex',
          // flexDirection:'column',
          // justifyContent: 'start',
          // alignItems: 'center',
          // padding: '5px 15px',
          // gap: '10px',
        }}

        >
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '500',
              }}
            >
              Today's Search
            </Typography>
      

          <Box
            // sx={{
            //   width: '80%',
            // }}
            sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}
          >
                <Box
            // sx={{
            //   width: '20%',
            //   display: 'flex',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            // }}
          >
            <img src={SearchImg} alt="..." width="40px" height='40px' />
          </Box>
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '1.5rem',

                fontWeight: '500',
              }}
            >
              {allQueuesData?.TodaySearch || 0}
            </Typography>
          
          </Box>
        </Box>
        </Grid>
        <Grid  item lg={4}>
        <Box
          sx={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            padding:"16px",
            borderRadius:"24px",
            height:'136px'
            // textDecoration: 'none',
            // width: '100%',
            // height: 'fit-content',
            // background: 'var(--white)',
            // borderRadius: '10px',
            // display: 'flex',
            // flexDirection:'column',
            // justifyContent: 'start',
            // alignItems: 'center',
            // padding: '5px 15px',
            // gap: '10px',
          }}
        >
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '20px',
                fontWeight: '500',
                width: '100%',
              }}
              noWrap
            >
              {`${commaNumber(balance || 0)} AED`}
            </Typography>
      <Box sx={{display:'flex',justifyContent:'start',alignItems:'center'}}>

      <Box
            // sx={{
            //   width: '20%',
            //   display: 'flex',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            // }}
          >
            <img src={DollerImg} alt="..." width="40px" hright='40px' />
          </Box>

          <Box
            // sx={{
            //   width: '80%',
            // }}
          >
       <Typography sx={{fontSize:"14px"}}>
       AED. Current Balance
       </Typography>
         
            <Link
              underline="hover"
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '500',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/user/adddeposit')}
            >
              Request Top Up
            </Link>
          </Box>








      </Box>
        </Box>
        </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HomePageStack;
