import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/Logout';
import TollIcon from '@mui/icons-material/Toll';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// import QueuesPage from "../Queues/QueuesPage";
import './UserDashboardSideBar.css';
import useAuthentication from '../../hooks/useAuthentication';
import DeskTopDrawer from './DesktopDrawer';
import { Tooltip } from '@mui/material';
import Footer from '../Footer/Footer';
import NewHeader from '../Header/NewHeader';

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 20px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const UserDashboardSideBar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [subManu, setSubmenu] = useState('');
  const [subManuActive, setSubMenuActive] = useState('');

  const { logout } = useAuthentication();

  return (
    <>
    <Box sx={{ display: 'flex',flexDirection:'column' }} className="sideBar-user">
      
      <CssBaseline />
      {/* For DeskTop and lg device Sidebar */}
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          display: { md: 'none', sm: 'none', xs: 'none' },
          boxShadow:
            ' rgb(9, 152, 138) 0px 50px 100px -20px,  rgb(9, 152, 138) 0px 30px 60px -30px,  rgb(9, 152, 138) 0px -2px 6px 0px inset',
        }}
      >
        <DrawerHeader
          style={{
            background: 'var(--primary-color)',
            display: 'block',
            // borderRight: "5px solid var(--primary-color)",
          }}
        >
          <Box onClick={() => setOpen(!open)}>
            <MenuIcon
              style={{
                color: 'var(--white)',
                fontSize: '28px',
                margin: '20px 15px 10px 15px',
              }}
            />
          </Box>
        </DrawerHeader>

        <List
          style={{
            height: '100vh',
            overflowY: 'hidden',
            overflowX: 'hidden',
            background: 'var(--primary-color',
            borderRight: '5px solid var(--primary-color)',
          }}
        >
          {/* dashboard  home */}

          <ListItem disablePadding sx={{ display: 'block' }}>
            <NavLink
              to="/user/dashboard"
              className={({ isActive }) =>
                isActive ? 'active-nav' : 'normal-nav'
              }
              onClick={() => setSubmenu('Home')}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  width: '90%',
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <HomeIcon
                    id="NavIcon"
                    style={{
                      fontSize: '28px',
                      margin: '10px 0px',
                      color: 'var(--white)',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id="NavText"
                  primary="Search pad"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: 'var(--white)',
                  }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <NavLink
              to="/user/home"
              className={({ isActive }) =>
                isActive ? 'active-nav' : 'normal-nav'
              }
              onClick={() => setSubmenu('Dashboard')}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  width: '90%',
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <HomeIcon
                    id="NavIcon"
                    style={{
                      fontSize: '28px',
                      margin: '10px 0px',
                      color: 'var(--white)',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id="NavText"
                  primary="Dashboard"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: 'var(--white)',
                  }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          {/* //todo: dashboard */}
          {/* <ListItem disablePadding sx={{ display: "block" }}>
            <NavLink
              to="/user/home"
              className={({ isActive }) =>
                isActive ? "active-nav" : "normal-nav"
              }
              onClick={() => setSubmenu("Dashboard")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  width: "90%",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <DashboardIcon
                    id="NavIcon"
                    style={{
                      fontSize: "28px",
                      margin: "10px 0px",
                      color: "var(--white)",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id="NavText"
                  primary="Dashboard"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "var(--white)",
                  }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem> */}

          {/* Booking  */}
          <Box>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NavLink
                to={'/user/queues'}
                className={({ isActive }) =>
                  isActive ? 'active-nav' : 'normal-nav'
                }
                onClick={() => {
                  setSubmenu('Booking');
                  setSubMenuActive('AirTicket');
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    width: '90%',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <TollIcon
                      onClick={() => setOpen(!open)}
                      id="NavIcon"
                      style={{
                        fontSize: '28px',
                        margin: '10px 0px',
                        color: 'var(--white)',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="My Booking"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: 'var(--white)',
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
            {/* <Box
              style={{
                display: subManu === 'Booking' ? '' : 'none',
                background: open ? 'var(--white)' : '',
              }}
              className="user-DashSubManu"
            >
              <Box
                className="user-DashSubManuChild"
                sx={{ opacity: open ? 1 : 0 }}
              >
                <NavLink
                  to={'/user/queues'}
                  style={{
                    display: 'block',
                    background:
                      subManuActive === 'AirTicket'
                        ? 'var(--primary-color)'
                        : '',
                    color: subManuActive === 'AirTicket' ? 'var(--white)' : '',
                  }}
                  onClick={() => {
                    setSubMenuActive('AirTicket');
                  }}
                >
                  My Booking
                </NavLink>
                <NavLink
                  to={'/user/pnrimport'}
                  style={{
                    display: 'block',
                    background:
                      subManuActive === 'Other' ? 'var(--primary-color)' : '',
                    color: subManuActive === 'Other' ? 'var(--white)' : '',
                  }}
                  onClick={() => {
                    setSubMenuActive('Other');
                  }}
                >
                  PNR Import
                </NavLink>
              </Box>
            </Box> */}
          </Box>
          {/* manage  */}

          <Box>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NavLink
                to={'/user/traveller'}
                className={({ isActive }) =>
                  isActive ? 'active-nav' : 'normal-nav'
                }
                onClick={() => {
                  setSubmenu('Manage');
                  setSubMenuActive('Traveler');
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    width: '90%',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ManageAccountsIcon
                      onClick={() => setOpen(!open)}
                      id="NavIcon"
                      style={{
                        fontSize: '28px',
                        margin: '10px 0px',
                        color: 'var(--white)',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="Traveler"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: 'var(--white)',
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
            {/* <Box
              style={{
                display: subManu === "Manage" ? "" : "none",
                background: open ? "var(--white)" : "",
              }}
              className="user-DashSubManu"
            >
              <Box
                className="user-DashSubManuChild"
                sx={{ opacity: open ? 1 : 0 }}
              >
                <NavLink
                  to={"/user/traveller"}
                  style={{
                    display: "block",
                    background:
                      subManuActive === "Traveler"
                        ? "var(--primary-color)"
                        : "",
                    color: subManuActive === "Traveler" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("Traveler");
                  }}
                >
                  Traveler
                </NavLink>
              </Box>
            </Box> */}
          </Box>

          {/* Account */}
          <Box>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NavLink
                to="/user/myaccount"
                className={({ isActive }) =>
                  isActive ? 'active-nav' : 'normal-nav'
                }
                onClick={() => {
                  setSubmenu('myaccount');
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    width: '90%',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <AccountBoxIcon
                      onClick={() => setOpen(!open)}
                      id="NavIcon"
                      style={{
                        fontSize: '26px',
                        margin: '10px 0px',
                        color: 'var(--white)',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="My Account"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: 'var(--white)',
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </Box>
          {/* Deposit */}
          <Box>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NavLink
                to="/user/deposit"
                className={({ isActive }) =>
                  isActive ? 'active-nav' : 'normal-nav'
                }
                onClick={() => {
                  setSubmenu('Account');
                  setSubMenuActive('AddBank');
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    width: '90%',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <AccountBalanceIcon
                      onClick={() => setOpen(!open)}
                      id="NavIcon"
                      style={{
                        fontSize: '26px',
                        margin: '10px 0px',
                        color: 'var(--white)',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="Deposit"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: 'var(--white)',
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </Box>
          {/* Staff */}

          <Box>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NavLink
                to={'/user/transaction'}
                className={({ isActive }) =>
                  isActive ? 'active-nav' : 'normal-nav'
                }
                onClick={() => setSubmenu('Report')}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    width: '90%',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ReceiptLongIcon
                      id="NavIcon"
                      style={{
                        fontSize: '26px',
                        margin: '10px 0px',
                        color: 'var(--white)',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="Transaction"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: 'var(--white)',
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </Box>
          <Box>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NavLink
                to={'/user/ledger'}
                className={({ isActive }) =>
                  isActive ? 'active-nav' : 'normal-nav'
                }
                onClick={() => setSubmenu('Ledger')}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    width: '90%',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <AssessmentIcon
                      id="NavIcon"
                      style={{
                        fontSize: '26px',
                        margin: '10px 0px',
                        color: 'var(--white)',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="Ledger Report"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: 'var(--white)',
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </Box>


















          
          <Box>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <NavLink
                to="/user/staff"
                className={({ isActive }) =>
                  isActive ? 'active-nav' : 'normal-nav'
                }
                onClick={() => setSubmenu('My Staffs')}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    width: '90%',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <PeopleAltIcon
                      id="NavIcon"
                      style={{
                        fontSize: '26px',
                        margin: '10px 0px',
                        color: 'var(--white)',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="My Staffs"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: 'var(--white)',
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </Box>























          {/* Report  */}

          {/* logout */}

          <Box pt={3}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Tooltip title="Log Out">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'active-nav' : 'normal-nav'
                  }
                  to={'/'}
                  onClick={logout}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      width: '90%',
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <LogoutIcon
                        id="NavIcon"
                        style={{
                          fontSize: '28px',
                          margin: '10px 0px',
                          color: 'var(--white)',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id="NavText"
                      primary="Logout"
                      sx={{
                        opacity: open ? 1 : 0,
                        color: 'var(--white)',
                      }}
                    />
                  </ListItemButton>
                </NavLink>
              </Tooltip>
            </ListItem>
          </Box>
        </List>
      </Drawer>

      {/* For Mobile and sm device Sidebar */}
      <Box sx={{ display: { md: 'none', sm: 'block', xs: 'block' } }}>
        <DeskTopDrawer />
      </Box>



      <NewHeader/>




      <Box
        sx={{
          // flexGrow: 1,
          // py: { xs: 0, md: 2 },
          bgcolor: 'var(--body)',
          // width: 'calc(100% - 240px)',
        }}
      >
      
        <Outlet></Outlet>
       
    
      </Box>

    </Box>
    </>
  );
};

export default UserDashboardSideBar;
