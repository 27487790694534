import { Box, Container, Grid, Link, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import airplane from '../../images/landingPage/airplane.png';
import hotel from '../../images/landingPage/hotel.png';
import holiday from '../../images/landingPage/holiday.png';
import visa from '../../images/landingPage/visa.png';
import steeringWheel from '../../images/landingPage/steering-wheel.png';
import './home.css';
import Header from '../../components/Header/Header';
import HomePageStack from '../../components/HomePageStack/HomePageStack';
import TravelCalender from '../../components/TravelCalender/TravelCalender';
import LiveQueues from '../../components/LiveQueues/LiveQueues';
import { useNavigate } from 'react-router-dom';
import VisaForm from '../../components/VisaForm/VisaForm';
import License from '../../components/License/License';
import UpcommingTrips from './UpcommingTrips';
import CustomCalendar from '../../components/TravelCalender/CustomCalendar';
import secureLocalStorage from 'react-secure-storage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  background: 'var(--light-gray)',
  borderRadius: '20px',
  boxShadow: 24,
  px: 4,
  py: 2,
};

const Home = () => {
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem('user-info');
  let agentId = users?.uid;
  const url = `https://api-b2b.projectota.com/agent/booking/upcoming/${agentId}`;
  const url1 = `https://api-b2b.projectota.com/agent/booking/past/${agentId}`;

  return (
    <Box>
      {/* <Header /> */}
      <Box sx={{ mt: { xs: 12, sm: 12, md: 5, lg: 2 },mx:4 }} >
        {/* <Box
          sx={{
            width: "100%",
            display: { xs: "none", sm: "none", md: "flex" },
            gap: "40px",
            justifyContent: "center",
            alignItems: "center",
            my: "30px",
          }}
        >
          <Box
            onClick={() => navigate("/user/dashboard")}
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              textDecoration: "none",
              width: "120px",
              height: "120px",
              background: "var(--white)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                background:
                  "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={airplane} alt="..." width={"90%"} />
            </Box>

            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "20px",
                textTransform: "uppercase",
                fontWeight: "500",
              }}
            >
              flights
            </Typography>
          </Box>
          <Box
            onClick={() => navigate("/user/dashboard")}
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              textDecoration: "none",
              width: "120px",
              height: "120px",
              background: "var(--white)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                background:
                  "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={hotel} alt="..." width={"90%"} />
            </Box>

            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "20px",
                textTransform: "uppercase",
                fontWeight: "500",
              }}
            >
              hotel
            </Typography>
          </Box>
          <Box
            onClick={() => navigate("/user/dashboard")}
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              textDecoration: "none",
              width: "120px",
              height: "120px",
              background: "var(--white)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                background:
                  "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={holiday} alt="..." width={"90%"} />
            </Box>

            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "20px",
                textTransform: "uppercase",
                fontWeight: "500",
              }}
            >
              holiday
            </Typography>
          </Box>
          <Box
            onClick={handleOpenVisa}
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              textDecoration: "none",
              width: "120px",
              height: "120px",
              background: "var(--white)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                background:
                  "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={visa} alt="..." width={"90%"} />
            </Box>

            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "20px",
                textTransform: "uppercase",
                fontWeight: "500",
              }}
            >
              visa
            </Typography>
          </Box>
          <Box
            onClick={handleOpenLicense}
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              width: "120px",
              height: "120px",
              background: "var(--white)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                background:
                  "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={steeringWheel} alt="..." width={"90%"} />
            </Box>

            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "20px",
                textTransform: "uppercase",
                fontWeight: "500",
              }}
            >
              license
            </Typography>
          </Box>
        </Box> */}
        <Box>
          <Grid container columnSpacing={5}>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <HomePageStack />
              </Box>
              <Box sx={{mt:3,background: 'rgba(241, 241, 241, 1)',paddingTop:'32px',borderRadius:"20px"
}}>
             <Container>
            <Typography sx={{fontSize:"24px",color:"var(--black)",fontWeight:"600"}}>Booking Schedule</Typography>
             </Container>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  padding: '10px',
                }}
              >
                <TravelCalender />
                {/* <CustomCalendar /> */}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <UpcommingTrips url={url} text="Upcomming" />
          <UpcommingTrips url={url1} text="Past" />
        </Box>

        {/* <Box>
          <LiveQueues totalData={totalData} setTotalData={setTotalData} />
        </Box> */}
      </Box>
      {/* <Modal open={openVisa} onClose={handleCloseVisa}>
        <Box sx={style}>
          <VisaForm handleCloseVisa={handleCloseVisa} />
        </Box>
      </Modal>
      <Modal open={openLicense} onClose={handleCloseLicense}>
        <Box sx={style}>
          <License handleCloseLicense={handleCloseLicense} />
        </Box>
      </Modal> */}
    </Box>
  );
};

export default Home;
