import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Box, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import FlightUserInfoSabre from '../../components/FlightUserinfo/FlightUserInfoSabre';
import Loader from '../../images/loader/Render.gif';
import NotFound from '../../images/undraw/undraw_not_found_re_bh2e.svg';
import FlightInfoDetails from '../../components/FlightInfoDetails/FlightInfoDetails';
import './FlightInformation.css';
import Header from '../../components/Header/Header';
import FlightDetails from '../../components/SingleFlight/FlightDetails';
import secureLocalStorage from 'react-secure-storage';

const FlightInformation = (props) => {
  const location = useLocation();
  const [loadData, setLoadData] = useState([]);
  const { adultCount, childCount, infant } = location.state;
  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const users = secureLocalStorage.getItem('user-info');
  const uid = users?.uid;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const data = location.state?.flightData;

  let url = `https://api-b2b.projectota.com/agent/flight/revalidation/${uid}`;
  let body = data;

  useEffect(() => {
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 500) {
          throw new Error('Data is empty');
        } else if (data) {
          setIsLoaded(false);
          setLoadData(data);
        } else {
          throw new Error('Data is empty');
        }
      })
      .catch((err) => {
        Swal.fire({
          imageUrl: NotFound,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: `${err?.message}`, // Use 'err' instead of 'data' for error message
          confirmButtonText: 'Search Another Flights...',
          confirmButtonColor: 'var(--primary-color)',
        }).then(function () {
          navigate(-1);
        });
      });
  }, []);

  return (
    <Box>
      {/* <Header /> */}
      {!isLoaded ? (
        <Box sx={{ mt: { xs: 10, sm: 6, md: 2 } ,mx:4}}>
          <Grid container mt={4} spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8.5}
              order={{ xs: 2, sm: 2, md: 1 }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: 16, sm: 22 },
                    color: 'var(--secondary-color)',
                    fontWeight: 500,
                  }}
                >
                  Flight Information Details
                </Typography>
                <Box>
                  {data?.AllLegsInfo?.map((data, i, arr) => (
                    <Box my={2} key={i}>
                      {data?.Segments?.map((item, j) => (
                        <Box key={j} my={2}>
                          <FlightDetails data={item} />
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
                <Box>
                  <FlightUserInfoSabre
                    adultCount={adultCount}
                    childCount={childCount}
                    infantCount={infant}
                    flightData={data}
                    loadData={loadData}
                    isLoaded={isLoaded}
                    setIsLoaded={setIsLoaded}
                  />
                </Box>
                {/* <FlightUserInfoSabre
                  loadData={loadData}
                  flightData={location.state.flightData}
                  userData={location.state}
                  searchResult={loadData}
                  adultPrice={adultPrice}
                  childPrice={childPrice}
                  infPrice={infPrice}
                  adultTaxPrice={adultTaxPrice}
                  childTaxPrice={childTaxPrice}
                  infTaxPrice={infTaxPrice}
                  serviceFeeAdult={serviceFeeAdult}
                  serviceFeeChild={serviceFeeChild}
                  serviceFeeInfant={serviceFeeInfant}
                  inTotalBaseFare={inTotalBaseFare}
                  totalBaseFare={totalBaseFare}
                  totalTax={totalTax}
                  totalFare={totalFare}
                  limitTime={limitTime}
                  isLoaded={isLoaded}
                  setIsLoaded={setIsLoaded}
                  clientFare={location.state.clientFare}
                  coupon={coupon}
                  setCoupon={setCoupon}
                  couponAppliedMessage={couponAppliedMessage}
                  setCouponAppliedMessage={setCouponAppliedMessage}
                  adultBaggage={adultBaggage}
                  setAdultBaggage={setAdultBaggage}
                  childBaggage={childBaggage}
                  setChildBaggage={setChildBaggage}
                  infantBaggage={infantBaggage}
                  setInfantBaggage={setInfantBaggage}
                /> */}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3.5}
              order={{ xs: 1, sm: 1, md: 3 }}
            >
              <FlightInfoDetails
                adultCount={adultCount}
                childCount={childCount}
                infantCount={infant}
                flightData={data}
                loadData={loadData}
                isLoaded={isLoaded}
                setIsLoaded={setIsLoaded}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
            width: '50vw',
            marginInline: 'auto',
          }}
        >
          <Box
            style={{
              width: '50%',
              height: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Loader}
              alt="loader"
              style={{
                width: '100%',
                objectFit: 'center',
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default FlightInformation;
