import React from 'react';
import { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  SwipeableDrawer,
  Stack,
} from '@mui/material';
import commaNumber from 'comma-number';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FlightDetails from './FlightDetails';
import moment from 'moment';
import LiquorIcon from '@mui/icons-material/Liquor';
import TvIcon from '@mui/icons-material/Tv';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UsbIcon from '@mui/icons-material/Usb';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import WifiIcon from '@mui/icons-material/Wifi';
const MoreFlight = ({
  flightData,
  adultCount,
  childCount,
  infant,
  FlightInformation,
}) => {
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  return (
    <Box>
      {['right'].map((anchor) => (
        <Box key={anchor}>
          <Typography
            sx={{
              fontSize: '14px',
              color: 'var(--secondary-color)',
              fontWeight: 500,
              display: { xs: 'block', md: 'none' },
            }}
          >
            BDT {commaNumber(Math.round(flightData?.NetFare))}
          </Typography>
          <Button
            size="small"
            onClick={toggleDrawer(anchor, true)}
            sx={{
              color: 'var(--primary-color)',
              fontWeight: 500,
              fontSize: '11px',
              padding: 0,
              marginTop: { xs: 0, sm: '5px' },
              textTransform: 'capitalize',
            }}
          >
            Flight Details
            <PlayArrowIcon style={{ padding: '0', fontSize: '18px' }} />
          </Button>
          {/* ----new start flight details */}
          <SwipeableDrawer
            style={{ margin: '0px', padding: '0px' }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <Box
              bgcolor="var(--drawer-bgcolor)"
              sx={{
                width: { xs: '85vw', sm: '70vw', md: '50vw' },
              }}
            >
              <Box>
                <Box margin={{ xs: '15px', md: '2vw 0 2vw 3vw' }}>
                  <Typography
                    sx={{ fontSize: { xs: '12px', sm: '20px' } }}
                    color="var(--secondary-color)"
                    fontWeight={500}
                  >
                    {flightData?.AllLegsInfo[0]?.Segments[0]?.DepLocation?.slice(
                      0,
                      -3
                    )}
                    {' - '}
                    {flightData?.AllLegsInfo[0]?.Segments[
                      flightData?.AllLegsInfo[0]?.Segments?.length - 1
                    ]?.ArrLocation?.slice(0, -3)}
                  </Typography>
                  <Typography fontSize={{ xs: 10, sm: 14 }}>
                    Oneway Flight <span>|</span>{' '}
                    {adultCount > 0 && `Adult(${adultCount})`}
                    {childCount > 0 && `Children(${childCount})`}
                    {infant > 0 && `Infant(${infant})`} <span>|</span>{' '}
                    {moment(
                      flightData?.AllLegsInfo[0]?.Segments[0]?.DepTime?.split(
                        '+'
                      )[0]
                    )?.format('DD MMM YYYY')}{' '}
                    {' | '}
                    {moment(
                      flightData?.AllLegsInfo[0]?.Segments[
                        flightData?.AllLegsInfo[0]?.Segments?.length - 1
                      ]?.ArrTime?.split('+')[0]
                    )?.format('DD MMM YYYY')}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      backgroundColor: 'var(--primary-color)',
                      padding: '4px 0 4px 3vw',
                      color: 'var(--white)',
                      fontWeight: 500,
                      width: { xs: '100%', sm: '30%', md: '25%' },
                    }}
                  >
                    Flight Details
                  </Typography>
                  <Box
                    bgcolor="var(--white)"
                    margin={{ xs: '15px', md: '2vw 3vw' }}
                    padding={{ xs: '7px 10px', sm: '8px 20px' }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      // borderBottom="2px solid var(--primary-color)"
                    >
                      <Grid item>
                        <Stack direction="row" spacing={2}>
                          <Typography
                            sx={{
                              color: 'var(--secondary-color)',
                              fontSize: { xs: 12, sm: 14 },
                              fontWeight: 500,
                            }}
                          >
                            Depart:{' '}
                            <span
                              style={{
                                color: 'var(--primary-color)',
                              }}
                            >
                              {moment(
                                flightData?.AllLegsInfo[0]?.Segments[0]?.DepTime?.split(
                                  '+'
                                )[0]
                              )?.format('DD MMM YYYY')}
                            </span>{' '}
                          </Typography>
                          <Typography
                            sx={{
                              color: 'var(--primary-color)',
                              fontSize: { xs: 12, sm: 14 },
                            }}
                          >
                            {flightData?.AllLegsInfo[0]?.Segments?.length ===
                            1 ? (
                              <>Non </>
                            ) : (
                              <>
                                {flightData?.AllLegsInfo[0]?.Segments?.length -
                                  1}{' '}
                              </>
                            )}
                            Stop{' '}
                          </Typography>
                          <Typography
                            sx={{
                              color: 'var(--primary-color)',
                              fontSize: { xs: 12, sm: 14 },
                            }}
                          >
                            Seat:{' '}
                            {
                              flightData?.AllLegsInfo[0]?.Segments[0]
                                ?.SegmentCode?.seatsAvailable
                            }
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{
                            color: 'var(--gray)',
                            fontSize: { xs: 11, sm: 12 },
                          }}
                        >
                          {flightData?.AllLegsInfo[0]?.Segments?.map((data) => (
                            <>
                              {data?.MarketingCarrier !==
                              data?.OperatingCarrier ? (
                                <>Operated By: {data?.OperatingCarrierName}</>
                              ) : null}
                            </>
                          ))}
                        </Typography>
                      </Grid>
                    </Grid>

                    {flightData?.AllLegsInfo?.map((data, i, arr) => (
                      <Box my={2} key={i}>
                        {data?.Segments?.map((item, j) => (
                          <Box key={j} my={2}>
                            <FlightDetails data={item} />
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      backgroundColor: 'var(--primary-color)',
                      padding: '4px 0 4px 3vw',
                      color: 'var(--white)',
                      fontWeight: 500,
                      width: { xs: '100%', sm: '30%', md: '25%' },
                    }}
                  >
                    Baggage
                  </Typography>
                  <Box
                    bgcolor="var(--white)"
                    margin={{ xs: '15px', md: '2vw 3vw' }}
                    padding={{ xs: '7px 10px', sm: '8px 20px' }}
                  >
                    <Box className="flight-search-table">
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          width: '100%',
                        }}
                      >
                        <tr>
                          <th>Baggage</th>
                          <th>Check-In</th>
                          <th>Cabin</th>
                        </tr>
                        {flightData?.PriceBreakDown?.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {data?.PaxType === 'ADT'
                                ? 'Adult'
                                : data?.PaxType === 'INF'
                                ? 'Infant'
                                : 'Child'}
                            </td>
                            <td>
                              {data?.Bag[0]?.Allowance?.pieceCount === undefined
                                ? `${data?.Bag[0]?.Allowance?.weight} Kg`
                                : `${data?.Bag[0]?.Allowance?.pieceCount} Piece`}
                            </td>
                            <td>7Kg</td>
                          </tr>
                        ))}
                      </table>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      backgroundColor: 'var(--primary-color)',
                      padding: '4px 0 4px 3vw',
                      color: 'var(--white)',
                      fontWeight: 500,
                      width: { xs: '100%', sm: '30%', md: '25%' },
                    }}
                  >
                    Price Breakdown
                  </Typography>
                  <Box
                    bgcolor="var(--white)"
                    margin={{ xs: '15px', md: '2vw 3vw' }}
                    padding={{ xs: '7px 10px', sm: '8px 20px' }}
                  >
                    <Grid container className="flight-search-table">
                      <Grid item xs={12}>
                        <table
                          style={{
                            borderCollapse: 'collapse',
                            width: '100%',
                          }}
                        >
                          <tr>
                            <th width="10%">Pax&nbsp;Type</th>
                            <th width="20%">Base&nbsp;Fare</th>
                            <th width="20%">Tax&nbsp;+&nbsp;Fees</th>
                            <th width="30%">Per&nbsp;Passenger</th>
                            <th width="20%">Total&nbsp;Cost</th>
                          </tr>
                          {flightData?.PriceBreakDown?.map((data) => (
                            <tr>
                              <td>
                                {data.PaxType === 'ADT'
                                  ? 'Adult'
                                  : data.PaxType === 'CNN'
                                  ? 'Child'
                                  : 'Infant'}
                              </td>
                              <td>AED&nbsp;{commaNumber(data?.BaseFare)}</td>
                              <td>
                                AED&nbsp;
                                {commaNumber(
                                  parseInt(data?.Taxes) +
                                    parseInt(
                                      data?.ServiceFee ? data?.ServiceFee : 0
                                    )
                                )}
                              </td>

                              <td>
                                AED&nbsp;({commaNumber(data?.BaseFare)}
                                &nbsp;
                                {'*'}&nbsp;{data?.PaxCount})
                              </td>

                              <td>
                                AED&nbsp;
                                {commaNumber(parseInt(data?.TotalFare))}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="space-between"
                      padding="20px"
                    >
                      <Grid item>
                        <Typography
                          mb="5px"
                          sx={{
                            color: 'var(--black)',
                            fontSize: '12px',
                          }}
                        >
                          Total (
                          {flightData?.PriceBreakDown?.length === 3 ? (
                            <>
                              {parseInt(
                                flightData?.PriceBreakDown[0].PaxCount
                              ) +
                                parseInt(
                                  flightData?.PriceBreakDown[1].PaxCount
                                ) +
                                parseInt(
                                  flightData?.PriceBreakDown[2].PaxCount
                                )}
                            </>
                          ) : flightData?.PriceBreakDown?.length === 2 ? (
                            <>
                              {parseInt(
                                flightData?.PriceBreakDown[0].PaxCount
                              ) +
                                parseInt(
                                  flightData?.PriceBreakDown[1].PaxCount
                                )}
                            </>
                          ) : (
                            <>
                              {parseInt(flightData?.PriceBreakDown[0].PaxCount)}
                            </>
                          )}{' '}
                          Traveler)
                        </Typography>
                        {/* <Typography
                          sx={{
                            color: 'var(--total-text-color)',
                            fontSize: '12px',
                          }}
                        >
                          Commission & Your Saving{' '}
                        </Typography> */}
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            color: 'var(--black)',
                            fontSize: '12px',
                          }}
                        >
                          AED{' '}
                          {commaNumber(
                            Math.round(flightData?.NetFare?.toFixed(2))
                          )}
                        </Typography>

                        {/* <Typography
                          sx={{
                            color: 'var(--total-text-color)',
                            fontSize: '12px',
                          }}
                        >
                          AED{' '}
                          {commaNumber(
                            Math.round(
                              flightData?.GrossFare - flightData?.NetFare
                            )
                          )}
                        </Typography> */}
                      </Grid>
                    </Grid>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      bgcolor="var(--primary-color)"
                      padding={{ xs: '7px 10px', sm: '8px 20px' }}
                    >
                      <Typography color="var(--white)" fontSize="13px">
                        Total Payable
                      </Typography>
                      <Typography color="var(--white)" fontSize="13px">
                        AED {commaNumber(Math.round(flightData?.NetFare))}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      backgroundColor: 'var(--primary-color)',
                      padding: '4px 0 4px 3vw',
                      color: 'var(--white)',
                      fontWeight: 500,
                      width: { xs: '100%', sm: '30%', md: '25%' },
                    }}
                  >
                    Fare Policy
                  </Typography>
                  <Box
                    bgcolor="var(--white)"
                    margin={{ xs: '15px', md: '2vw 3vw' }}
                    padding={{ xs: '7px 10px', sm: '8px 20px' }}
                  >
                    <Typography
                      sx={{
                        color: 'var(--fontcolor)',
                        fontSize: '12px',
                        fontWeight: 500,
                        padding: { xs: '5px', sm: '20px' },
                      }}
                    >
                      Pay attention to the following notifications in the
                      CANCELLATIONS section:
                      <br />
                      <br />
                      TICKET IS NON-REFUNDABLE — the ticket is non-refundable;
                      <br />
                      TICKET IS NON-REFUNDABLE FOR CANCEL/REFUND — the ticket is
                      non-refundable;
                      <br />
                      REFUND IS NOT PERMITTED — the ticket is non-refundable;
                      <br />
                      ANY TIME TICKET IS NON-REFUNDABLE — the ticket is
                      non-refundable;
                      <br />
                      TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW — the ticket
                      cannot be refunded in case of no-show.
                      <br />
                      Change rules are described in the section with the CHANGES
                      subtitle.
                      <br />
                      <br />
                      The CHANGES ARE NOT PERMITTED line means that you cannot
                      make any changes and in such a case, you are not allowed
                      to change the date/time/route of the flight.
                    </Typography>
                  </Box>
                </Box>

                {/* -------new end */}
              </Box>
              <Box textAlign="Right" px={5} pb={4}>
                <Button
                  size="large"
                  className="shine-effect"
                  style={{
                    color: 'var(--white)',
                    fontWeight: 500,
                    backgroundColor: 'var(--primary-color)',
                    borderRadius: '5px',
                  }}
                  onClick={() => FlightInformation()}
                >
                  BOOK NOW
                </Button>
              </Box>
            </Box>
          </SwipeableDrawer>
        </Box>
      ))}
    </Box>
  );
};

export default React.memo(MoreFlight);
