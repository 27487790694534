import {  Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../images/logo/logo.png';
import useAuthentication from '../../hooks/useAuthentication';
const NewHeader = () => {
  const { logout } = useAuthentication();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate=useNavigate()
  return (
    <Box sx={{bgcolor:'var(--primary-color)',py:1,}}>

    {/* <Container maxWidth='xxl'  sx={{display:"flex",justifyContent:"space-between",alignItems:'center'}}>
<Box>
    <MenuIcon sx={{color:"var(--white)"}}/>
</Box>
<Box sx={{display:"flex",justifyContent:'end',alignItems:'center',gap:"219px"}}>
    <Button  variant='contained' sx={{borderRadius:"8px",boxShadow:'none',bgcolor:"var(--white)",color:"var(--black)",px:3,py:1}}   startIcon={<AccountBalanceWalletIcon/>}>
    65,215 AED
    </Button>
    <Box sx={{display:'flex',justifyContent:'start',alignItems:"center",gap:'32px'}} >
    <button style={{
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      backgroundColor: "var(--notification-color)", // Example background color
      color: "white",
      cursor: "pointer"
    }}>
      <NotificationsIcon sx={{color:'var(--white)'}} />
    </button>
<Box sx={{display:'flex',justifyContent:'start',alignItems:"center",gap:'5px'}}>
<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{widht:"40px",height:"40px"}} />
<Typography sx={{fontSize:"12px",color:"var(--white)",fontWeight:"500"}}>Russel</Typography>
</Box>
    </Box>
</Box>
    </Container> */}

    <Box sx={{mx:4,display:'flex',justifyContent:"center",alignItems:'center'}}>
    {/* <Box
              sx={{
                width: { md: '15%', sm: '50%', xs: '50%' },
                // background: 'var(--white)',
                height: '100%',
                display: 'flex',
                justifyContent: {
                  lg: 'start',
                  md: 'start',
                  sm: 'center',
                  xs: 'center',
                },
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/user/dashboard')}
            >
              <Box
                sx={{
                  height: '80px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    height: '100%',
                  }}
                />
              </Box>
            </Box> */}
<Box sx={{display:'flex',justifyContent:'end',alignItems:'center',gap:"20px"}}>
<NavLink to='/'>
<Typography sx={{color:"white"}}>Home</Typography>
</NavLink>

<NavLink to='/user/dashboard'>
<Typography sx={{color:"white"}}>Search pad</Typography>
</NavLink>

<NavLink to='/user/queues'>
<Typography sx={{color:"white"}}>Bookings</Typography>
</NavLink>

 <NavLink to='/user/myaccount'>
 <Typography sx={{color:"white"}}>Accounts</Typography>
 </NavLink>

<NavLink to='/user/ledger'>
<Typography sx={{color:"white"}}>Reports</Typography>
</NavLink>
 {/* <Typography sx={{color:"white"}}>Dashboard</Typography> */}
 {/* <Typography sx={{color:"white"}}>My Booking</Typography> */}
 {/* <Typography sx={{color:"white"}}>Traveler</Typography> */}
 {/* <Typography sx={{color:"white"}}>My Account</Typography> */}
 {/* <Typography sx={{color:"white"}}>Deposit</Typography>
 <Typography sx={{color:"white"}}>Transaction</Typography> */}

 {/* <Typography sx={{color:"white"}}>My Staffs</Typography> */}

 {/* <Button  variant='contained' sx={{borderRadius:"8px",boxShadow:'none',bgcolor:"var(--white)",color:"var(--black)",px:3,py:1}}   startIcon={<AccountBalanceWalletIcon/>}>
    65,215 AED
    </Button> */}
 <Box  id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}  sx={{borderRadius:"8px",display:'flex',justifyContent:'start',alignItems:"center",gap:'5px', bgcolor:"var(--white)",px:5,py:1}}>
<Avatar  alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{widht:"38px",height:"38px"}} />
<Typography sx={{fontSize:"12px",color:"var(--black)",fontWeight:"500"}}>Russel</Typography>


</Box>
<Menu
  sx={{ justifyContent: 'center'}}
  PaperProps={{sx: {width: '200px'}}}
  id="basic-menu"
  anchorEl={anchorEl}
  open={open}
  onClose={handleClose}
  MenuListProps={{
    'aria-labelledby': 'basic-button',
  }}
>
<MenuItem sx={{ textAlign: 'center', justifyContent: 'center',fontWeight:"500" }} onClick={handleClose}>Balance:65,215 AED</MenuItem>

<NavLink to='/user/myaccount'>
<MenuItem sx={{ textAlign: 'center', justifyContent: 'center',fontWeight:"500" }} onClick={handleClose}>Profile</MenuItem>
</NavLink>
  {/* <MenuItem sx={{ textAlign: 'center', justifyContent: 'center',fontWeight:"500" }} onClick={handleClose}>My account</MenuItem>
  <MenuItem sx={{ textAlign: 'center', justifyContent: 'center',fontWeight:"500" }} onClick={handleClose}>My Booking</MenuItem> */}
  <NavLink to='/user/setting'>
    <MenuItem sx={{ textAlign: 'center', justifyContent: 'center',fontWeight:"500" }} onClick={handleClose}>
      Setting
      </MenuItem>
      </NavLink>

      <NavLink to='/user/staff'>
  <MenuItem sx={{ textAlign: 'center', justifyContent: 'center',fontWeight:"500" }} onClick={handleClose}>

    My Staffs

  </MenuItem>
  </NavLink>
  <MenuItem sx={{ textAlign: 'center', justifyContent: 'center',fontWeight:"500" }} onClick={()=>{
    logout()
    
    handleClose()}}>Logout</MenuItem>
</Menu>
</Box>
    </Box>
    </Box>
  )
}

export default NewHeader