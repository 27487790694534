import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'

const ProfilePic = () => {
  return (
    <Box sx={{background:'var(--white)'  ,borderRadius:"5px",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",py:2}}>
<Container>
<Box sx={{textAlign:'center'}}>
    <img src='https://marketplace.canva.com/EAF21qlw744/1/0/1600w/canva-blue-modern-facebook-profile-picture-mtu4sNVuKIU.jpg' style={{width:"180px",height:"180px",borderRadius:"50%",border:"5px solid var(--primary-color)"}}/>
</Box>
<Box sx={{mt:2}}>
    <Box sx={{border:'2px solid var(--primary-color)',borderRadius:"5px",display:'flex',justifyContent:"start",alignItems:'center'}}>
<Button variant='contained' sx={{boxShadow:"none",bgcolor:'var(--primary-color)'}}>
Choose File
</Button>
<Box sx={{textAlign:'center',flex:1}}>
    <Typography sx={{color:'var(--grey-color)'}}>No file  chosen</Typography>
</Box>
    </Box>

    <Box sx={{textAlign:'center',mt:3}}>
        <Typography sx={{fontWeight:"500",textTransform:"uppercase",fontSize:"21px",color:'var(--primary-color)'}}>Kayes Fahmid</Typography>
        <Typography sx={{fontWeight:"500",fontSize:"16px",color:'var(--primary-color)'}}>Member of fifty SHare</Typography>
    </Box>
</Box>
</Container>
    </Box>
  )
}

export default ProfilePic