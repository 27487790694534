/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Grow,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import commaNumber from 'comma-number';
import './SingleFlight.css';
import MoreFlight from './MoreFlight';
import FlightLayout from './FlightLayout';
import Modal from '@mui/material/Modal';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';















const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
 
  boxShadow: 24,
  p: 4,
};
const SingleFlight = ({
  pageData,
  flightData,
  adultCount,
  childCount,
  infant,
  quotetionArr,
  setQuotetionArr,
  indexValue
}) => {

  const [open, setOpen] = React.useState(false);
  const [extraService,setExtraService]=useState({})
  const handleOpen = (indx) => {
    console.log(indx)
    setOpen(true)
setExtraService(pageData.find((item,index)=>index===indx))
  };
  console.log( extraService)
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const FlightInformation = (data) => {
    navigate('/user/flightinformation', {
      state: {
        flightData,
        data,
        adultCount,
        childCount,
        infant,
      },
    });
  };

  const [checked, setChecked] = useState(false);
  const handleBox = (data) => {
    const isChecked = !checked; // Use the inverse of the current state
    setChecked(isChecked);

    if (isChecked) {
      setQuotetionArr([...quotetionArr, data]); // Use 'data' instead of 'flightData'
    } else {
      const filteredArr = quotetionArr.filter(
        (item) => item.Carrier !== data.Carrier
      );
      setQuotetionArr(filteredArr);
    }
  };

  const PaxCount = adultCount + childCount + infant;
  let count = [];
  for (let i = 0; i < PaxCount; i++) {
    count.push(i);
  }
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // ----   --------Copy form ALL.js end----------
  return (
    <>
    <Box mb={2.5}>
      <Grid
        container
        sx={{
          boxShadow:
            '-0.452679px 4.97947px 36px rgba(0, 0, 0, 0.09), -0.0905357px 0.995893px 5.85px rgba(0, 0, 0, 0.045)',
          borderRadius: '10px',
        }}
      >
        <Grid item sm={12} md={10} lg={10} p={2}>
          {flightData?.AllLegsInfo?.map((data, index, arr) => (
            <Box key={index}>
              <FlightLayout
                checked={checked}
                handleBox={handleBox}
                disabled={false}
                arr={arr}
                flightData={data}
                allData={flightData}
                index={index}
              />
            </Box>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          pt={{ xs: 0.5, md: 2 }}
          pb={{ xs: 0.5, md: 2 }}
          pr={2}
          textAlign={{ xs: 'center', md: 'right' }}
          bgcolor="var(--bgcolor)"
          sx={{
            overflow: 'hidden',
            borderTopRightRadius: { xs: '', md: '10px' },
            borderBottomRightRadius: { xs: '', md: '10px' },
          }}
        >
          <Box textAlign="end" mr={{ xs: '5px', md: '0px' }}>
            <Typography
              sx={{
                fontSize: '18px',
                color: 'var(--secondary-color)',
                fontWeight: 500,
                display: { xs: 'none', md: 'block' },
              }}
            >
              <span style={{ fontSize: '13px' }}>BDT</span>&nbsp;
              {commaNumber(Math.round(flightData?.NetFare))}
            </Typography>
          </Box>

          <Box
            textAlign="end"
            mt={{ xs: 0, md: 1 }}
            px={{ xs: 2, md: '0' }}
            display={{ xs: 'flex', md: 'block' }}
            justifyContent="space-between"
            alignItems={{ xs: 'center', md: '' }}
          >
            <Button
              size="small"
              sx={{
                color: 'var(--white)',
                fontWeight: 500,
                backgroundColor: 'var(--button)',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                borderRadius: '5px',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: 'var(--button)',
                },
                padding: { xs: '3px 10px', md: '3px 15px' },
              }}
              onClick={() => FlightInformation(flightData?.AllLegs)}
            >
              Book Now
            </Button>


     <Box sx={{textAlign:'right',mt:1}}>
     <Typography
onClick={()=>handleOpen(indexValue)}
                    sx={{
                      
                     cursor:"ponter",
                   color:"var(--primary-color)",
                      fontWeight: 500,
                      fontSize:"12px"
                     
                    }}
                  >
                   Extra Services
                  </Typography>
     </Box>



      





            <MoreFlight
              flightData={flightData}
              adultCount={adultCount}
              childCount={childCount}
              infant={infant}
              FlightInformation={FlightInformation}
            />
          </Box>
        </Grid>
      </Grid>
      <Box></Box>
    </Box>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
<Box >


          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mt:2}}>
        Penalties
          </Typography>

<Box sx={{ maxHeight: '500px', overflowY: 'auto' }} >

<TableContainer component={Paper} sx={{boxShadow:"none"}}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow sx={{bgcolor:"var(--primary-color)",color:'var(--white)'}}>
            <TableCell>Type</TableCell>
            
            <TableCell align="left">Before Departure</TableCell>
            <TableCell align="left">After Departure</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {extraService?.PriceBreakDown?.map((item, index) => (
            <TableRow key={index}>
              <TableCell align="left">{item?.PaxType}</TableCell>
              <TableCell align="left">
                <Box sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {item?.Penalties?.penalties?.filter((penalty) => penalty.applicability === 'Before').map((penalty, idx) => (
                    <Box key={idx} sx={{ mb: 1, pb: 1, borderBottom: '1px solid var(--primary-color)' }}>
                      <p><strong>Type:</strong> {penalty?.type}</p>
                      {/* <p><strong>Applicability:</strong> {penalty?.applicability}</p> */}
                      <p><strong>Changeable:</strong> {penalty?.changeable ? "Yes" : "No"}</p>
                      {penalty?.conditionsApply !== undefined && (
                        <p><strong>Conditions Apply:</strong> {penalty?.conditionsApply ? "Yes" : "No"}</p>
                      )}
                      {penalty?.amount !== undefined && (
                        <p><strong>Amount:</strong> {penalty?.amount} {penalty?.currency}</p>
                      )}
                    </Box>
                  ))}
                </Box>
              </TableCell>
              <TableCell align="left">
                <Box sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {item?.Penalties?.penalties?.filter((penalty) => penalty.applicability === 'After').map((penalty, idx) => (
                    <Box key={idx} sx={{ mb: 1, pb: 1, borderBottom: '1px solid var(--primary-color)' }}>
                      <p><strong>Type:</strong> {penalty?.type}</p>
                      {/* <p><strong>Applicability:</strong> {penalty?.applicability}</p> */}
                      <p><strong>Changeable:</strong> {penalty?.changeable ? "Yes" : "No"}</p>
                      {penalty?.conditionsApply !== undefined && (
                        <p><strong>Conditions Apply:</strong> {penalty?.conditionsApply ? "Yes" : "No"}</p>
                      )}
                      {penalty?.amount !== undefined && (
                        <p><strong>Amount:</strong> {penalty?.amount} {penalty?.currency}</p>
                      )}
                    </Box>
                  ))}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
</Box>




<Typography id="modal-modal-title" variant="h6" component="h2" sx={{mt:2}}>
          Extra Services
          </Typography>
{extraService?.ExtraService?.ancillaryFees?   <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" >
            <Tab label="Baggage" value="1" />
            <Tab label="Meal" value="2" />
            <Tab label="Seat" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{bgcolor:'var(--primary-color)'}}>
            <TableCell>Code </TableCell>
            <TableCell >Name</TableCell>
            <TableCell >Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
      {extraService?.ExtraService?.ancillaryFees?.filter((item)=>item.name==='BAGGAGE').map((item,index)=><TableRow>
      <TableCell>{item?.code} </TableCell>
            <TableCell >{item?.name}</TableCell>
            <TableCell >{item?.message}</TableCell>
      </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
        </TabPanel>
        <TabPanel value="2">
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Code </TableCell>
            <TableCell >Name</TableCell>
            <TableCell >Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
      {extraService?.ExtraService?.ancillaryFees?.filter((item)=>item.name==='MEAL/BEVERAGE').map((item,index)=><TableRow>
      <TableCell>{item?.code} </TableCell>
            <TableCell >{item?.name}</TableCell>
            <TableCell >{item?.message}</TableCell>
      </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
        </TabPanel>
        <TabPanel value="3">
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Code </TableCell>
            <TableCell >Name</TableCell>
            <TableCell >Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
      {extraService?.ExtraService?.ancillaryFees?.filter((item)=>item.name==='SEAT').map((item,index)=><TableRow>
      <TableCell>{item?.code} </TableCell>
            <TableCell >{item?.name}</TableCell>
            <TableCell >{item?.message}</TableCell>
      </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
        </TabPanel>
      </TabContext>
    </Box>
:extraService?.ExtraService?.message}
       










</Box>

        </Box>
      </Modal>
    </>
  );
};

export default React.memo(SingleFlight);
