import { Box, Container, Typography,Button } from '@mui/material'
import React from 'react'

const Security = () => {
  return (
    <>
    
    <Box sx={{background:'var(--white)'  ,borderRadius:"5px",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",py:2,mt:2}}>
<Container>
    <Box sx={{borderBottom:"1px solid var(--primary-color)",pb:2}}>
        <Typography sx={{color:'var(--primary-color)',fontSize:"21px",fontWeight:'500'}}> Security</Typography>
    </Box>
<Box sx={{textAlign:'center',mt:2}}>
    <Typography sx={{fontSize:"13px"}}> Use Two factor authentication to secure your account</Typography>
    <Button variant='contained' sx={{bgcolor:'var(--primary-color)',color:'var(--white)',fontWeight:'500',mt:2,boxShadow:"none"}}>Add 2FA</Button>
</Box>
</Container>
    </Box>
    
    
    </>
  )
}

export default Security