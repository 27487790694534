import {
  Box,
  Typography,
  Container,
  Grid,
  Modal,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import { AdminHeader } from '../AdminHeader/AdminHeader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Agentdetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const adminInfo = secureLocalStorage.getItem('user-info');
  const token = adminInfo.token;
  const adminUser = adminInfo?.name;
  const agentId = location?.state?.agentId;
  const uid = location?.state?.uid;
  const [otherData, setotherData] = useState({});
  const [image, setImage] = useState();
  const [refetch] = useState(false);

  const handleOthersData = (e) => {
    setotherData({ ...otherData, [e.target.name]: e.target.value });
  };
  const [open, setOpen] = useState(false);
  const [allData, setAllData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://api-b2b.projectota.com/agent/myaccount/${uid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setIsLoading(false);
        setAllData(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false in case of an error
      });
  }, [refetch]);

  const handleOtherSubmit = async (e) => {
    const formData = new FormData();
    formData.append('agentId', agentId);
    formData.append('reference', otherData.reference);
    formData.append('details', otherData.details);
    formData.append('amount', otherData.amount);
    formData.append('createdBy', adminUser);
    formData.append('file', image);

    e.preventDefault();
    const url = 'https://api.flyjatt.com/v1/Agent/addother.php';

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    axios.post(url, formData, config).then((res) => {
      setOpen(false);
      if (res?.data?.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: res?.data?.status,
          html: res?.data?.message,
          confirmButtonText: 'ok',
          confirmButtonColor: 'var(--primary-color)',
        }).then(() => {
          navigate(0);
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: res?.data?.status,
          html: res?.data?.message,
          confirmButtonText: 'ok',
          confirmButtonColor: 'var(--primary-color)',
        }).then(() => {
          navigate(0);
        });
      }
    });
  };

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  sx={{
                    color: 'var(--secondary-color)',
                    fontSize: '17px',
                    fontWeight: 500,
                  }}
                >
                  <Box mb={0.5}>Company</Box>
                  <Box mb={0.5}>Balance</Box>
                  <Box mb={0.5}>Agent Id</Box>
                  <Box mb={0.5}>Agent&nbsp;Name</Box>
                  <Box mb={0.5}>Email</Box>
                  <Box mb={0.5}>Phone</Box>
                  <Box mb={0.5}>Address</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                {isLoading ? (
                  'Loading...'
                ) : (
                  <Typography
                    sx={{
                      color: 'var(--secondary-color)',
                      fontSize: '17px',
                      fontWeight: 400,
                    }}
                  >
                    <Box mb={0.5}>
                      <Tooltip title={allData?.company}>
                        <Box>{allData?.company || 'N/A'}</Box>
                      </Tooltip>
                    </Box>
                    
                    <Box mb={0.5}> {allData?.agentId || 'N/A'}</Box>
                    <Box mb={0.5}> {allData?.name || 'N/A'}</Box>
                    <Box mb={0.5}> {allData?.phone || 'N/A'}</Box>
                    <Box mb={0.5}> {allData?.email || 'N/A'}</Box>
                    <Box mb={0.5}> {allData?.address || 'N/A'}</Box>
                  </Typography>
                )}
              </Grid>
            </Grid>
            
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography
              sx={{
                textAlign: 'center',
                py: 0.8,
                color: '#fff',
                fontWeight: '500',
                cursor: 'pointer',
                mb: 2,
                bgcolor: 'var(--primary-color)',
              }}
              onClick={() =>
                navigate('/dashboard/agentbooking', {
                  state: uid,
                })
              }
            >
              View Booking
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                py: 0.8,
                color: '#fff',
                fontWeight: '500',
                cursor: 'pointer',
                mb: 2,
                bgcolor: 'var(--primary-color)',
              }}
              onClick={() =>
                navigate('/dashboard/agentdeposit', {
                  state: uid,
                })
              }
            >
              View Deposit{' '}
            </Typography>
            
          </Grid>
        </Grid>
        

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{ ...style, xs: '100%', sm: '80%', md: '60%' }}
            style={{ border: 'none', borderRadius: '5px' }}
          >
            <Typography
              my={2}
              style={{
                color: 'var(--mateBlack)',
                fontSize: '22px',
                fontWeight: '500',
              }}
            >
              Balance (+) or (-)
            </Typography>
            <Box>
              <form onSubmit={handleOtherSubmit}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    '& input': {
                      width: '100%',
                      outline: 'none',
                      border: '1px solid var(--secondary-color)',
                      borderRadius: '4px !important',
                    },
                  }}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <input
                      name="reference"
                      value={otherData?.reference || ''}
                      type="text"
                      placeholder="Reference..."
                      required
                      style={{
                        width: '100%',
                        padding: '5px 10px',
                        outline: 'none',
                        border: '1px solid var(--secondary-color)',
                        borderRadius: '4px !important',
                      }}
                      onChange={handleOthersData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <input
                      name="details"
                      value={otherData?.details || ''}
                      type="text"
                      placeholder="Details..."
                      required
                      style={{
                        width: '100%',
                        padding: '5px 10px',
                        outline: 'none',
                        border: '1px solid var(--secondary-color)',
                        borderRadius: '4px !important',
                      }}
                      onChange={handleOthersData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <input
                      name="amount"
                      value={otherData?.amount || ''}
                      type="text"
                      placeholder="Amount value Ex: (-10) or (10)"
                      required
                      style={{
                        width: '100%',
                        padding: '5px 10px',
                        outline: 'none',
                        border: '1px solid var(--secondary-color)',
                        borderRadius: '4px !important',
                      }}
                      onChange={handleOthersData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <input
                      name="file"
                      //   value={otherData?.file || ""}
                      type="file"
                      required
                      style={{
                        width: '100%',
                        padding: '5px 10px',
                        outline: 'none',
                        border: '1px solid var(--secondary-color)',
                        borderRadius: '4px !important',
                      }}
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <button
                      type="submit"
                      style={{
                        width: '100%',
                        background: 'var(--secondary-color)',
                        color: 'white',
                        outline: 'none',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        border: '1px solid var(--secondary-color)',
                      }}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

export default Agentdetails;
