import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import 'react-phone-input-2/lib/style.css';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import AccCreated from '../../images/undraw/undraw_happy_announcement_re_tsm0.svg';
import ServerDown from '../../images/undraw/undraw_server_down_s-4-lk.svg';
import Invalid from '../../images/undraw/undraw_warning_re_eoyh.svg';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import NoEncryptionGmailerrorredOutlinedIcon from '@mui/icons-material/NoEncryptionGmailerrorredOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import Logo from '../../images/logo/logo.png';
import PhoneIcon from '@mui/icons-material/Phone';

import './SingUp.css';

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [checked, setChecked] = useState(false);
  const [registerData, setRegisterData] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    password: '',
    cPassword: '',
    company: '',
    address: '',
  });

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newRegisterData = { ...registerData };
    newRegisterData[field] = value;
    setRegisterData(newRegisterData);
  };
  const body = {
    name: registerData.firstname + ' ' + registerData.lastname,
    phone: registerData.phone,
    email: registerData.email,
    password: registerData.password,
    company: registerData.company,
    address: registerData.address,
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      const response = await fetch('https://api-b2b.projectota.com/auth/signup', {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Check for success based on the HTTP status code
      if (response.status === 200 || response.status === 201) {
        await Swal.fire({
          imageUrl: AccCreated,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: 'Account Created Successfully!',
          html: `<div>Dear: <strong>${registerData.firstname} ${registerData.lastname}</strong> Your Account: <strong>${registerData.company}</strong> is Successfully Created. Your Account will be Active within 24 Hours.</div>
            <strong>For any Query contact contact@flyjatt.com or call <strong>+971509517784</strong>`,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        });
        setIsLoading(false);
        navigate('/');
      } else {
        await Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: data?.message,
          html: `<strong>For any Query contact contact@flyjatt.com or call<strong>+971509517784.</strong>`,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Please Try Again!',
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err.message);
      await Swal.fire({
        imageUrl: ServerDown,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        title: err.message,
        html: `<strong>For any Query contact contact@flyjatt.com or call <strong>+971509517784.</strong>`,
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Please Try Again!',
      });
      setIsLoading(false);
    }

    e.target.reset();
  };

  return (
    <Box>
      <Grid container height>
        <Grid item md={12} width="100%">
          <Box className="bg-images" height="100vh">
            <Container sx={{ p: 1 }}>
              <Stack direction="row" justifyContent="space-between">
                <Box
                  sx={{
                    height: '80px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={Logo}
                    alt="logo"
                    style={{
                      height: '100%',
                    }}
                  />
                </Box>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems="center"
                  spacing={2}
                >
                  <Typography
                    sx={{
                      color: '#222222',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 14,
                    }}
                  >
                    <PhoneIcon color="#222222" />
                    &nbsp;+971509517784
                  </Typography>
                  <Link
                    to="/signin"
                    style={{
                      textDecoration: 'none',
                      color: 'var(--black)',
                      border: '2px solid #222222',
                      padding: '5px 15px',
                      borderRadius: '20px',
                    }}
                  >
                    <span style={{ fontSize: '13px' }}>Login as an Agent</span>
                  </Link>
                </Stack>
              </Stack>

              <Box
                sx={{
                  padding: { md: '0px', sm: '0px 10px', xs: '0px 10px' },
                  position: 'absolute',
                  top: { lg: '50%', xs: '18%' },
                  left: { lg: '50%', xs: '0' },
                  transform: {
                    lg: 'translate(-50%, -50%)',
                    xs: 'translate(0%, 0%)',
                  },
                }}
              >
                <Box
                  sx={{
                    background: 'var(--white)',
                    padding: '0px',
                    borderRadius: '20px',
                    width: { xs: '100%', md: '90%', lg: '80%' },
                    margin: 'auto',
                  }}
                >
                  <form onSubmit={handleSubmit} autocomplete="off">
                    <Box
                      my={2}
                      sx={{
                        width: '100%',
                        height: '60px',
                        background: 'var(--primary-color)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '20px 20px 0px 0px',
                        // marginTop: { xs: "20px", md: "0px" },
                      }}
                    >
                      <Typography
                        style={{
                          color: 'var(--secondary-color)',
                          fontSize: '20px',
                          fontWeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        Agent Registration
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={1}
                      sx={{ padding: '0px 20px 20px' }}
                    >
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography
                          style={{
                            color: 'var(--secondary-color)',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            textTransform: 'capitalize',
                            textAlign: 'center',
                          }}
                        >
                          Registration with Project OTA as an agent Please make
                          sure that you Enter the correct information.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ position: 'relative' }}>
                          <input
                            required
                            type="text"
                            maxLength={20}
                            placeholder="First Name"
                            name="firstname"
                            value={registerData?.firstname}
                            autocomplete="off"
                            onChange={handleOnChange}
                            style={{
                              height: '40px',
                              width: 'calc(100% - 20px)',
                              outline: 'none',
                              border: '1px solid black',
                              borderRadius: '20px',
                              padding: '0px 10px',
                              color: 'var(--secondary-color)',
                              background: 'transparent',
                              fontSize: '16px',
                            }}
                          />
                          <PermIdentityOutlinedIcon
                            sx={{
                              color: 'var(--primary-color)',
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translate(-5px,-50%)',
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ position: 'relative' }}>
                          <input
                            required
                            type="text"
                            name="lastname"
                            maxLength={20}
                            placeholder="Last Name"
                            autocomplete="off"
                            onChange={handleOnChange}
                            value={registerData?.lastname}
                            style={{
                              height: '40px',
                              width: 'calc(100% - 20px)',
                              outline: 'none',
                              border: '1px solid black',
                              borderRadius: '20px',
                              padding: '0px 10px',
                              color: 'var(--secondary-color)',
                              background: 'transparent',
                              fontSize: '16px',
                            }}
                          />
                          <PermIdentityOutlinedIcon
                            sx={{
                              color: 'var(--primary-color)',
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translate(-5px,-50%)',
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={12}>
                        <Box sx={{ position: 'relative' }}>
                          <input
                            required
                            type="text"
                            placeholder="Company Name"
                            name="company"
                            autocomplete="off"
                            onChange={handleOnChange}
                            value={registerData?.company}
                            style={{
                              height: '40px',
                              width: 'calc(100% - 20px)',
                              outline: 'none',
                              border: '1px solid black',
                              borderRadius: '20px',
                              padding: '0px 10px',
                              color: 'var(--secondary-color)',
                              background: 'transparent',
                              fontSize: '16px',
                            }}
                          />
                          <BusinessCenterOutlinedIcon
                            sx={{
                              color: 'var(--primary-color)',
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translate(-5px,-50%)',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={12}>
                        <Box sx={{ position: 'relative' }}>
                          <input
                            required
                            type="text"
                            placeholder="Company Address"
                            name="address"
                            value={registerData?.address}
                            autoComplete="off"
                            onChange={handleOnChange}
                            style={{
                              height: '40px',
                              width: 'calc(100% - 20px)',
                              outline: 'none',
                              border: '1px solid black',
                              borderRadius: '20px',
                              padding: '0px 10px',
                              color: 'var(--secondary-color)',
                              background: 'transparent',
                              fontSize: '16px',
                            }}
                          />
                          <LocationOnOutlinedIcon
                            sx={{
                              color: 'var(--primary-color)',
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translate(-5px,-50%)',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={12}>
                        <Box sx={{ position: 'relative' }}>
                          <input
                            required
                            name="phone"
                            placeholder="Enter Phone Number"
                            value={registerData?.phone}
                            onChange={handleOnChange}
                            style={{
                              height: '40px',
                              width: 'calc(100% - 20px)',
                              outline: 'none',
                              border: '1px solid black',
                              borderRadius: '20px',
                              padding: '0px 10px',
                              color: 'var(--secondary-color)',
                              background: 'transparent',
                              fontSize: '16px',
                            }}
                          />
                          <CallOutlinedIcon
                            sx={{
                              color: 'var(--primary-color)',
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translate(-5px,-50%)',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ position: 'relative' }}>
                          <input
                            required
                            type="email"
                            name="email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            placeholder="Email Address"
                            value={registerData?.email}
                            onChange={handleOnChange}
                            style={{
                              height: '40px',
                              width: 'calc(100% - 20px)',
                              outline: 'none',
                              border: '1px solid black',
                              borderRadius: '20px',
                              padding: '0px 10px',
                              color: 'var(--secondary-color)',
                              background: 'transparent',
                              fontSize: '16px',
                            }}
                          />
                          <EmailOutlinedIcon
                            sx={{
                              color: 'var(--primary-color)',
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translate(-5px,-50%)',
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ position: 'relative' }}>
                          <input
                            required
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Choose Your Password "
                            autocomplete="off"
                            value={registerData.password}
                            onChange={handleOnChange}
                            style={{
                              height: '40px',
                              width: 'calc(100% - 20px)',
                              outline: 'none',
                              border: '1px solid black',
                              borderRadius: '20px',
                              padding: '0px 10px',
                              color: 'var(--secondary-color)',
                              background: 'transparent',
                              fontSize: '16px',
                            }}
                          />
                          {showPassword ? (
                            <LockOutlinedIcon
                              onClick={handleClickShowPassword}
                              sx={{
                                color: 'var(--primary-color)',
                                position: 'absolute',
                                top: '50%',
                                right: '5px',
                                transform: 'translate(-5px,-50%)',
                              }}
                            />
                          ) : (
                            <NoEncryptionGmailerrorredOutlinedIcon
                              onClick={handleClickShowPassword}
                              sx={{
                                color: 'var(--primary-color)',
                                position: 'absolute',
                                top: '50%',
                                right: '5px',
                                transform: 'translate(-5px,-50%)',
                              }}
                            />
                          )}
                        </Box>
                        <Box
                          pl={1}
                          color="red"
                          style={{ fontSize: '14px' }}
                          margin="10px 0px"
                        >
                          {registerData.password.length < 8
                            ? '*Password at least 8 characters'
                            : null}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ position: 'relative' }}>
                          <input
                            required
                            name="cPassword"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Confirm Password "
                            value={registerData?.cPassword}
                            onChange={handleOnChange}
                            style={{
                              height: '40px',
                              width: 'calc(100% - 20px)',
                              outline: 'none',
                              border: '1px solid black',
                              borderRadius: '20px',
                              padding: '0px 10px',
                              color: 'var(--secondary-color)',
                              background: 'transparent',
                              fontSize: '16px',
                            }}
                          />

                          {showPassword ? (
                            <LockOutlinedIcon
                              onClick={handleClickShowPassword}
                              sx={{
                                color: 'var(--primary-color)',
                                position: 'absolute',
                                top: '50%',
                                right: '5px',
                                transform: 'translate(-5px,-50%)',
                              }}
                            />
                          ) : (
                            <NoEncryptionGmailerrorredOutlinedIcon
                              onClick={handleClickShowPassword}
                              sx={{
                                color: 'var(--primary-color)',
                                position: 'absolute',
                                top: '50%',
                                right: '5px',
                                transform: 'translate(-5px,-50%)',
                              }}
                            />
                          )}
                        </Box>
                        <Box color="red" fontSize="12px">
                          {registerData.password === registerData.cPassword
                            ? ''
                            : '*Passwords do not match'}
                        </Box>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            sx={{ color: 'var(--primary-color)' }}
                            type="checkbox"
                            control={
                              <Checkbox
                                checked={checked}
                                onClick={() => setChecked((prev) => !prev)}
                                sx={{ color: 'black', marginLeft: '5px' }}
                              />
                            }
                            label="Agree Terms and Conditions"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Button
                          type="submit"
                          disabled={isLoading ? true : false}
                          sx={{
                            width: '100%',
                            borderRadius: '20px',
                            background: 'var(--primary-color)',
                            color: 'var(--white)',
                            '&:hover': {
                              background: 'var(--primary-color)',
                              color: 'var(--white)',
                            },
                          }}
                        >
                          {isLoading ? (
                            <CircularProgress
                              style={{ height: '20px', width: '20px' }}
                            />
                          ) : (
                            'Register →'
                          )}
                        </Button>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            color: 'var(--black)',
                            display: 'flex',
                            gap: '5px',
                          }}
                        >
                          Already have account?
                          <Link
                            to="/signin"
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '14px',
                                color: 'var(--primary-color)',
                              }}
                            >
                              Login
                            </span>
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUp;
