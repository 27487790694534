import React, { useState } from 'react';
import { Box, Button, Grid, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import QuotationPDF from './QuotationPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Header from './../Header/Header';
import FlightLayout from '../SingleFlight/FlightLayout';
import commaNumber from 'comma-number';

const MakeQuotation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const { adultCount, childCount, infant, quotetionArr } = location.state;

  const [newQuotetionArr, setNewQuotetionArr] = useState(quotetionArr);
  const [markupPrice, setMarkupPrice] = useState(0);
  const [updatedPrice, setUpdatedPrice] = useState(0);
  const [checked, setChecked] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setUpdatedPrice(Math.abs(Number.parseInt(markupPrice || 0)));
  };

  const handleBox = (data) => {
    const event = window.event;

    if (newQuotetionArr?.length > 1) {
      setChecked(event.target.checked);
    }
    if (!event.target.checked) {
      const filter = newQuotetionArr?.filter(
        (item) => item?.Carrier !== data.Carrier
      );
      setNewQuotetionArr(filter);
    }
  };
  return (
    <Box>
      <Header />
      <Container
        sx={{
          padding: '10px 0px',
          background: 'var(--rgb-primary)',
          mt: { xs: 12, sm: 10, md: 3 },
          px: { xs: 1, md: 3 },
        }}
      >
        {newQuotetionArr.map((item, i) => (
          <Box
            key={i}
            sx={{
              boxShadow:
                '-0.452679px 4.97947px 36px rgba(0, 0, 0, 0.09), -0.0905357px 0.995893px 5.85px rgba(0, 0, 0, 0.045)',
              borderRadius: '10px',
              p: 2,
              mb: 2,
            }}
          >
            {item?.AllLegsInfo?.map((data, index, arr) => (
              <Box key={index}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={10}>
                    <FlightLayout
                      checked={checked}
                      handleBox={handleBox}
                      disabled={newQuotetionArr.length > 1 ? false : true}
                      flightData={data}
                      allData={item}
                      index={index}
                      arr={arr}
                      icon={'icon'}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    {index === 0 && (
                      <Box
                        // textAlign="end"
                        mr={{ xs: '5px', md: '0px' }}
                        sx={{
                          display: { xs: 'flex', md: 'unset' },
                          justifyContent: { xs: 'space-between', md: 'unset' },
                          width: '100%',
                          border: { xs: 'unset', md: '1px solid' },
                          backgroundColor: {
                            xs: 'var(--bgcolor)',
                            md: 'unset',
                          },
                          px: { xs: 2, md: '0' },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '18px',
                            color: 'var(--secondary-color)',
                            fontWeight: 500,
                          }}
                        >
                          <span style={{ fontSize: '13px' }}>BDT</span>&nbsp;
                          {commaNumber(
                            Math.round(item?.NetFare) + updatedPrice
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        ))}
        <Box my={5}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Box>
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: 'var(--secondary-color)',
                    fontWeight: '500',
                    mb: 2,
                  }}
                >
                  Click the button below to download the PDF.
                </Typography>

                {newQuotetionArr?.map((data, index) => (
                  <Box key={index} mb={2}>
                    <Box mb={1.3}>
                      <PDFDownloadLink
                        document={
                          <QuotationPDF
                            quotationData={data}
                            markupPrice={updatedPrice}
                            tripType={data?.TripType}
                            price="Price"
                          />
                        }
                        fileName={`${data?.AllLegsInfo[0].DepFrom} -
                        ${data?.AllLegsInfo[0].ArrTo}
                      -${data?.TripType} ${data?.CarrierName} `}
                        style={{ textDecoration: 'none', fontSize: '12px' }}
                      >
                        {({ fileName, loading }) =>
                          loading ? (
                            'Loading document...'
                          ) : (
                            <a
                              href={fileName}
                              target="_blank"
                              style={{
                                cursor: 'pointer',
                                color: 'var(--black)',
                                textDecoration: 'none',
                                fontSize: '13px',
                                backgroundColor: 'var(--primary-color)',
                                padding: '5px 10px',
                                borderRadius: '5px',
                              }}
                              rel="noreferrer"
                            >
                              {`Download with price ${data?.AllLegsInfo[0].DepFrom} -
                        ${data?.AllLegsInfo[0].ArrTo}-${data?.CarrierName}`}
                            </a>
                          )
                        }
                      </PDFDownloadLink>
                    </Box>
                    <Box>
                      <PDFDownloadLink
                        document={
                          <QuotationPDF
                            quotationData={data}
                            markupPrice={updatedPrice}
                            tripType={data?.TripType}
                          />
                        }
                        fileName={`${data?.AllLegsInfo[0].DepFrom} -
                        ${data?.AllLegsInfo[0].ArrTo}
                      -${data?.TripType} ${data?.CarrierName} `}
                        style={{ textDecoration: 'none', fontSize: '12px' }}
                      >
                        {({ fileName, loading }) =>
                          loading ? (
                            'Loading document...'
                          ) : (
                            <a
                              href={fileName}
                              target="_blank"
                              style={{
                                cursor: 'pointer',
                                color: 'var(--black)',
                                textDecoration: 'none',
                                fontSize: '13px',
                                backgroundColor: 'var(--primary-color)',
                                padding: '5px 10px',
                                borderRadius: '5px',
                              }}
                              rel="noreferrer"
                            >
                              {`Download with out price ${data?.AllLegsInfo[0].DepFrom} -
                        ${data?.AllLegsInfo[0].ArrTo}-${data?.CarrierName}`}
                            </a>
                          )
                        }
                      </PDFDownloadLink>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item>
              <form onSubmit={handleSubmit} style={{ padding: '0px 16px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3px',
                    label: {
                      fontSize: '16px',
                      color: 'var(--secondary-color)',
                      fontWeight: '500',
                    },
                    input: {
                      padding: '5px',
                      background: 'var(--bgcolor)',
                      outline: 'none',
                      border: 'none',
                      borderRadius: '5px',
                    },
                  }}
                >
                  <label htmlFor="markupPrice">Add Markup</label>
                  <Box sx={{ width: '100%', display: 'flex', gap: '5px' }}>
                    <input
                      type="number"
                      id="markupPrice"
                      placeholder="Enter Markup Amount"
                      value={markupPrice}
                      onChange={(e) => {
                        setMarkupPrice(
                          Math.abs(Number.parseInt(e.target.value))
                        );
                      }}
                    />
                    <Button
                      type="submit"
                      style={{
                        color: 'white',
                        background: 'var(--primary-color)',
                        fontSize: '12px',
                        borderRadius: '5px',
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '10px' }}
                  >
                    *Markup will be added with base price
                  </Typography>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default MakeQuotation;
