import { Box, Container } from '@mui/material';
import React from 'react';
import AccountDetails from './AccountDetails';
import { AdminHeader } from '../AdminHeader/AdminHeader';

const AdminAccount = () => {
  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box>
          <AccountDetails />
        </Box>
      </Container>
    </Box>
  );
};

export default AdminAccount;
