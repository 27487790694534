import {
  Box,
  ClickAwayListener,
  Grid,
  Typography,
  Button,
  Collapse,
  Tooltip,
  Stack,
  Grow,
} from '@mui/material';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import secureLocalStorage from 'react-secure-storage';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import GroupsIcon from '@mui/icons-material/Groups';
import flightData from '../flightData';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import { format } from 'date-fns';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto var(--secondary-color)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'var(--primary-color)',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      'radial-gradient(var(--white),var(--white) 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: 'var(--secondary-color)',
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const Multicity = ({
  tripType,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSearchText,
  setFromSearchText,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  toSearchText,
  setToSearchText,
  departureDate,
  setDepartureDate,
  setValue,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  setClassName,
  handleClassName,
  from,
  setFrom,
  to,
  setTo,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  searchData,
  setSearchData,
  directFlightOnly,
  setDirectFlightOnly,
  handleSearch,
}) => {
  const data = flightData; // json data from flight Data
  const navigate = useNavigate();
  // todo: multiCity search Body

  const addCity = () => {
    const tempSearchData = [...searchData.segments];
    tempSearchData.push({
      id: tempSearchData.length,
      depfrom: tempSearchData[tempSearchData.length - 1].arrto,
      depFromText: tempSearchData[tempSearchData.length - 1].arrToText,
      arrto: 'JFK',
      arrToText: 'John F Kennedy Intl [JFK]',
      openTo: false,
      depdate: new Date().toLocaleDateString('sv'),
      openDate: false,
      open: false,
    });
    setSearchData({
      ...searchData,
      segments: tempSearchData,
    });
  };
  const removeCity = (id) => {
    const tempSearchData = searchData.segments.filter((item) => item.id !== id);
    setSearchData({
      ...searchData,
      segments: tempSearchData,
    });
  };
  // todo: end multiCity search Body

  const initialData = [
    {
      code: 'RUH',
      name: 'King Khaled Intl ',
      Address: 'Riyadh,SAUDI ARABIA',
    },
    { code: 'JED', name: 'Jeddah Intl ', Address: 'Jeddah,SAUDI ARABIA' },
    {
      code: 'ABT',
      name: 'Al Aqiq Airport',
      Address: 'Al Baha,SAUDI ARABIA',
    },
    {
      code: 'AHB',
      name: 'Abha Intl Airport',
      Address: 'Abha,SAUDI ARABIA',
    },
    { code: 'AJF', name: 'JOUF Airport', Address: 'Al Jouf,SAUDI ARABIA' },
    {
      code: 'AQI',
      name: 'Qaisumah Intl Airport',
      Address: 'Qaisumah,SAUDI ARABIA',
    },
    { code: 'DHA', name: 'Dhahran Intl ', Address: 'Dhahran,SAUDI ARABIA' },
    {
      code: 'DMM',
      name: 'King Fahad Intl Airport',
      Address: 'Dammam,SAUDI ARABIA',
    },
    {
      code: 'DWD',
      name: 'Dawadmi Intl Airport',
      Address: 'Dawadmi Intl Airport,SAUDI ARABIA',
    },
  ];

  //todo: is Click state
  const [click, setClick] = useState(false);
  //todo: end of click state
  const [fromSuggest, setFromSuggest] = useState(initialData);
  const [toSuggest, setToSuggest] = useState(initialData);

  // Opens the dialog when the user clicks.
  const handleClickOpen = (index, segment) => {
    const tempSearchData = [...searchData.segments];
    tempSearchData[index] = {
      ...tempSearchData[index],
      openTo: false,
      openDate: false,
      open: !segment.open,
    };
    setSearchData({ ...searchData, segments: tempSearchData });
  };

  // Closes the child process.
  const handleClose = (index) => {
    const tempSearchData = [...searchData.segments];
    tempSearchData[index] = {
      ...tempSearchData[index],
      openTo: false,
      openDate: false,
      open: false,
    };
    setSearchData({ ...searchData, segments: tempSearchData });
    // setResult(adultCount + childCount + infant);
  };

  // Sets the number of children.
  function adultInclement(e) {
    e.preventDefault();
    if (adultCount < 9 - (childCount + infant)) {
      setAdultCount(adultCount + 1);
      setSearchData({ ...searchData, adultCount: adultCount + 1 });
    }
  }

  // Decrement the count of children.
  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      setSearchData({ ...searchData, adultCount: adultCount - 1 });
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
          setSearchData({ ...searchData, infant: infant - 1 });
        }
      }
    }
  }

  function childInclement(e) {
    e.preventDefault();
    if (childCount < 9 - (adultCount + infant)) {
      setChildCount(childCount + 1);
      setSearchData({ ...searchData, childCount: childCount + 1 });
    }
  }

  function childDecrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
      setSearchData({ ...searchData, childCount: childCount - 1 });
    }
  }

  // Increment the default value if the value is not a child.
  function infantIncrement(e) {
    e.preventDefault();
    if (infant < 9 - (adultCount + childCount)) {
      if (infant < adultCount) {
        setInfant(infant + 1);
        setSearchData({ ...searchData, infantCount: infant + 1 });
      }
    }
  }

  // Decrement the infant by 1.
  function infantDecrement(e) {
    e.preventDefault();
    if (infant > 0) {
      setInfant(infant - 1);
      setSearchData({ ...searchData, infantCount: infant - 1 });
    }
  }

  const formOnChange = (e) => {
    const searchvalue = e.target.value;

    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setFromSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setFromSuggest(suggestion);
      }
    } else {
      setFromSuggest(initialData);
    }
  };

  const fromGetSuggetion = (index) => {
    const fromSuggestedText = (name, code, address) => {
      const tempSearchData = [...searchData.segments];

      tempSearchData[index] = {
        ...tempSearchData[index],
        depfrom: code.trim(),
        depFromText: `${name} (${code.trim()})`,
        openFrom: false,
        openTo: true,
      };
      setSearchData({
        ...searchData,
        segments: tempSearchData,
      });
      setFromSendData(code);
      setFromSearchText(`${name} (${code})`);
      setFromSuggest([]);
      setfaddress(address);
    };

    return (
      <Box
        style={{
          height: 'fit-content',
          position: 'relative',
          width: '100%',
          zIndex: '100',
        }}
      >
        <Box
          sx={{
            maxHeight: '230px',
            overflowY: 'auto',
            background: '#fff',
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            '&::-webkit-scrollbar': { width: '5px' },
          }}
        >
          {fromSuggest.length !== 0 ? (
            fromSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: '20px',
                    paddingRight: '10px',
                    backgroundColor: 'var( --secondary-color)',
                    transition: 'all .5s ease-in-out',
                    '&:hover': {
                      backgroundColor: 'var(--primary-color)',
                      color: 'var(--white)',
                    },
                    '&:hover .address': { color: 'var(--white)' },
                  }}
                >
                  <Box
                    sx={{
                      margin: '0px 0px',
                      padding: '5px 0px',
                      cursor: 'pointer',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => {
                      fromSuggestedText(
                        ` ${item.name}`,
                        ` ${item.code} `,
                        `${item.Address}`
                      );
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: '12px',
                          color: 'var(--primary-color)',
                          display: 'block',
                          textAlign: 'left',
                          fontWeight: '500',
                        }}
                      >
                        {item.Address}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '11px',
                          display: 'block',
                          textAlign: 'left',
                          color: 'var(--white)',
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '13px',
                          display: 'block',
                          textAlign: 'left',
                          paddingRight: '5px',
                          color: '#999',
                          fontWeight: 400,
                        }}
                      >
                        {item.code}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: 'var(--primary-color)',
                  fontWidth: 'bold',
                  paddingLeft: '10px',
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  const toOnChange = (e) => {
    const searchvalue = e.target.value;
    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setToSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setToSuggest(suggestion);
      }
    } else {
      setToSuggest(initialData);
    }
  };

  const toGetSuggetion = (index) => {
    const toSuggestedText = (name, code, address) => {
      const tempSearchData = [...searchData.segments];
      if (index === tempSearchData.length - 1) {
        tempSearchData[index] = {
          ...tempSearchData[index],
          arrto: code.trim(),
          arrToText: `${name} (${code.trim()})`,
          openFrom: false,
          openTo: false,
          openDate: true,
        };
      } else {
        tempSearchData[index] = {
          ...tempSearchData[index],
          arrto: code.trim(),
          arrToText: `${name} (${code.trim()})`,
          openFrom: false,
          openTo: false,
          openDate: true,
        };
        tempSearchData[index + 1] = {
          ...tempSearchData[index + 1],
          depfrom: code.trim(),
          depFromText: `${name} (${code.trim()})`,
        };
      }
      setSearchData({ ...searchData, segments: tempSearchData });
      setToSendData(code);
      setToSearchText(`${name} (${code})`);
      setToSuggest([]);
      setToAddress(address);
    };
    return (
      <Box
        style={{
          height: 'fit-content',
          position: 'relative',
          width: '100%',
          zIndex: '100',
        }}
      >
        <Box
          sx={{
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            maxHeight: '230px',
            overflowY: 'auto',
            background: '#fff',
            '&::-webkit-scrollbar': { width: '5px' },
          }}
        >
          {toSuggest.length !== 0 ? (
            toSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: '20px',
                    paddingRight: '5px',
                    backgroundColor: 'var( --secondary-color)',
                    transition: 'all .5s ease-in-out',
                    '&:hover': {
                      backgroundColor: 'var(--primary-color)',
                      color: 'var(--white)',
                    },
                    '&:hover .address': { color: 'var(--white)' },
                  }}
                >
                  <Box
                    sx={{
                      margin: '0px 0px',
                      padding: '5px 0px',
                      cursor: 'pointer',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                    onClick={() =>
                      toSuggestedText(
                        ` ${item.name}`,
                        `${item.code}`,
                        `${item.Address}`
                      )
                    } //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: '12px',
                          color: 'var(--primary-color)',
                          display: 'block',
                          textAlign: 'left',
                          fontWeight: '500',
                        }}
                      >
                        {item.Address}
                      </Typography>
                      <span
                        style={{
                          fontSize: '11px',
                          display: 'block',
                          color: 'var(--white)',
                          textAlign: 'left',
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '13px',
                          paddingRight: '10px',
                          display: 'block',
                          textAlign: 'left',
                          color: '#999',
                          fontWeight: 400,
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'var(--primary-color)',
                  fontWidth: 'bold',
                  paddingLeft: '10px',
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const handleSelect = (date, index) => {
    const tempSearchData = [...searchData.segments];
    tempSearchData[index] = {
      ...tempSearchData[index],
      depdate: new Date(date).toLocaleDateString('sv'),
      openDate: false,
      openFrom: false,
      openTo: false,
    };
    setSearchData({ ...searchData, segments: tempSearchData });
  };
  setResult(adultCount + childCount + infant);
  const handleClickAway = (index) => {
    const tempSegment = [...searchData.segments];
    tempSegment[index] = {
      ...tempSegment[index],
      openFrom: false,
      openTo: false,
      openDate: false,
      open: false,
    };
    setSearchData({
      ...searchData,
      cabinclass: className,
      segments: tempSegment,
    });
  };

  console.log('multicity compo', searchData);

  return (
    <Box sx={{ position: 'relative' }}>
      <form>
        <Box
        // sx={{
        //   overflowY: { xs: "scroll", sm: "" },
        //   height: { xs: 300, sm: "" },
        // }}
        >
          {searchData.segments.map((segment, index, arr) => (
            <Box key={index} sx={{ mb: 1 }}>
              <ClickAwayListener onClickAway={() => handleClickAway(index)}>
                <Grid
                  key={index}
                  sx={{
                    height: 'fit-content',
                    width: { xs: '100%', sm: '550px', md: '600', lg: '600px' },
                    position: 'relative',
                    mt: { md: '0px', sm: '5px', xs: '5px' },
                  }}
                  container
                  alignItems="center"
                  columnSpacing={0}
                >
                  {/* //todo destination section */}
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    sx={{
                      borderRadius: '10px',
                      // backgroundColor: 'var(--white)',
                    }}
                  >
                    {/* //todo: Departure City section */}



                    <Grid  item
              xs={12}
              sm={12}
              md={12} sx={{mb:1.5,display:'flex',justifyContent:'start',   flexDirection:{xs:'column',sm:"row"}}}>
<Box
                          sx={{
                            borderRadius: '10px 0px 0px 0px',
                            py: 0.9,
                            px: 1.5,
                            // bgcolor: 'var(--primary-color)',
                            width: '72px',
                            color: 'var(--white)',
                          }}
                        >
                          From:
                        </Box>

<Grid
                      item
                      xs={12}
                      style={{
                        position: 'relative',
                        // border: '1px solid var(--gray)',
                        // borderRadius: '10px 10px 0px 0px',
                        height: '40px',
                        background:"var(--white)",
                        borderRadius:"8px"
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          height: '100%',
                          
                        }}
                        onClick={() => {
                          const tempSegment = [...searchData.segments];
                          tempSegment[index] = {
                            ...tempSegment[index],
                            openFrom: !segment.openFrom,
                            openTo: false,
                            openDate: false,
                          };
                          setSearchData({
                            ...searchData,
                            segments: tempSegment,
                          });
                        }}
                      >
                        {/* <Box
                          sx={{
                            borderRadius: '10px 0px 0px 0px',
                            py: 0.95,
                            px: 1.5,
                            bgcolor: 'var(--primary-color)',
                            width: '72px',
                            color: 'var(--white)',
                          }}
                        >
                          From
                        </Box> */}

                        <Typography
                          sx={{
                            width: '100%',
                            fontSize: '14px',
                            fontWeight: '500',
          color: 'var(--primary-color)',
                            py: 1.2,
                            px: 1.5,
                          }}
                          noWrap
                        >
                          {segment.depFromText}
                        </Typography>
                      </Box>
                      <Collapse
                        in={segment.openFrom}
                        timeout="auto"
                        unmountOnExit
                        sx={{ width: '100%' }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '90%',
                            left: '0',
                            width: '100%',
                            backgroundColor: 'var( --secondary-color)',
                            height: 'fit-content',
                            borderBottom: '1px solid var(  --gray)',
                            borderLeft: '1px solid var(  --gray)',
                            borderRight: '2px solid var(  --gray)',
                            borderRadius: '0px 0px 5px 5px',
                            zIndex: '999',
                            padding: '5px 5px 0',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: 'var(--secondary-color)',
                              zIndex: 10,
                            }}
                            backgroundColor="var(--white)"
                          >
                            <input
                              autoComplete="off"
                              autoFocus
                              onChange={formOnChange}
                              className="customPlaceholder"
                              placeholder="Search a airport..."
                              style={{
                                color: 'var(--secondary-color)',
                                fontWeight: 400,
                                paddingLeft: '20px',
                                width: '100%',
                                height: '40px',
                                backgroundColor: 'transparent',
                                border: 'none',
                                outline: 'none',
                              }}
                            />
                          </Box>
                          <Box>{fromGetSuggetion(index)}</Box>
                        </Box>
                      </Collapse>
                    </Grid>
</Grid>


               



































                    {/* //todo: Arrival City section */}

                    <Grid  item
              xs={12}
              sm={12}
              md={12} sx={{mb:1.5,display:'flex',justifyContent:'start',   flexDirection:{xs:'column',sm:"row"}}}>
                <Box
                          sx={{
                            borderRadius: '10px 0px 0px 0px',
                            py: 0.9,
                            px: 1.5,
                            // bgcolor: 'var(--primary-color)',
                            width: '72px',
                            color: 'var(--white)',
                          }}
                        >
                         To:
                        </Box>
                    <Grid
                      item
                      xs={12}
                      style={{
                        position: 'relative',
                        // border: '1px solid var(--gray)',
                        // borderRadius: '10px 10px 0px 0px',
                        height: '40px',
                        background:"var(--white)",
                        borderRadius:"8px"
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          height: '100%',
                          
                        }}
                        onClick={() => {
                          const tempSegment = [...searchData.segments];
                          tempSegment[index] = {
                            ...tempSegment[index],
                            openFrom: false,
                            openTo: !segment.openTo,
                            openDate: false,
                          };
                          setSearchData({
                            ...searchData,
                            segments: tempSegment,
                          });
                        }}
                      >
                        {/* <Box
                          sx={{
                            borderRadius: '0px 0px 0px 10px',
                            py: 0.9,
                            px: 1.5,
                            bgcolor: 'var(--primary-color)',
                            width: { xs: '75px', sm: '72px' },
                            color: 'var(--white)',
                          }}
                        >
                          To
                        </Box> */}

                        <Typography
                          sx={{
                            width: '100%',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: 'var(--primary-color)',
                            py: 1,
                            px: 1.5,
                          }}
                          noWrap
                        >
                          {segment.arrToText}
                        </Typography>
                      </Box>
                      <Collapse
                        in={segment.openTo}
                        timeout="auto"
                        unmountOnExit
                        sx={{ width: '100%' }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '90%',
                            left: '0',
                            width: '100%',
                            backgroundColor: 'var( --secondary-color)',
                            height: 'fit-content',
                            borderBottom: '1px solid var(  --gray)',
                            borderLeft: '1px solid var(  --gray)',
                            borderRight: '2px solid var(  --gray)',
                            borderRadius: '0px 0px 5px 5px',
                            zIndex: '999',
                            padding: '5px 5px 0',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: 'var(--secondary-color)',
                              zIndex: 10,
                            }}
                            backgroundColor="var(--white)"
                          >
                            <input
                              autoComplete="off"
                              autoFocus
                              onChange={toOnChange}
                              className="customPlaceholder"
                              placeholder="Search a airport..."
                              style={{
                                color: 'var(--secondary-color)',
                                fontWeight: 400,
                                paddingLeft: '20px',
                                width: '100%',
                                height: '40px',
                                backgroundColor: 'transparent',
                                border: 'none',
                                outline: 'none',
                              }}
                            />
                          </Box>
                          <Box>{toGetSuggetion(index)}</Box>
                        </Box>
                      </Collapse>
                    </Grid>

                    </Grid>











                  </Grid>
                  {/* //todo:Travel Date */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      position: 'relative',
                      mt: { xs: 1, md: 0.5 },
                      display:'flex',
                      flexDirection:{xs:'column',sm:"row"}
                    }}
                  >
                     <Box
                 sx={{
                  // borderRadius: '0px 0px 0px 10px',
                  py: 0.9,
                  px: 1.5,
                  // bgcolor: 'var(--primary-color)',
                  width: { xs: '75px', sm: '72px' },
                  color: 'var(--white)',
                }}
                >
               
                </Box>

                    <Box
                      // style={{
                      //   border: '1px solid var(--gray)',
                      //   borderRadius: '10px',
                      //   background: 'var(--white)',
                      //   height: '100%',
                      //   width: '100%',
                      //   display: 'flex',
                      //   justifyContent: 'center',
                      //   alignItems: 'center',
                      // }}
                      style={{
                        // border: '1px solid var(--gray)',
                        borderRadius: '10px',
                        // background: 'var(--white)',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px"
                      }}
                    >
                      <Box
                        style={{
                          background: 'var(--white)',
                     // borderRight: '1px solid var(--gray)',
                     borderRadius:"8px",
                     width: '50%',
                   }}
                      >
                        <Stack
                          sx={{ px: 1.5, py: 1 }}
                          direction="row"
                          justifyContent="start"
                          alignItems="center"
                          onClick={() => {
                            const tempSearchData = [...searchData.segments];
                            tempSearchData[index] = {
                              ...tempSearchData[index],
                              openFrom: false,
                              openTo: false,
                              openDate: !segment.openDate,
                            };
                            setSearchData({
                              ...searchData,
                              segments: tempSearchData,
                            });
                          }}
                        >
                             <CalendarMonthIcon
                            sx={{
                              fontSize: 25,
                              color: 'var(--primary-color)',
                            }}
                          />
                          <Typography
                            sx={{
                              color: 'var(--primary-color)',
                              fontSize: 14,
                              fontWeight: '500',
                            }}
                          >
                            {`${format(
                              new Date(segment?.depdate),
                              'dd MMMM yy'
                            )}`}
                          </Typography>{' '}
                       
                        </Stack>

                        <Grow in={segment.openDate}>
                          <Box
                            sx={{
                              position: 'absolute',
                              left: 0,
                              top: 28,
                              zIndex: 999,
                            }}
                          >
                            <Calendar
                              color="#09988a"
                              // date={new Date(from)}
                              onChange={(date) => handleSelect(date, index)}
                              months={1}
                              minDate={
                                index === 0
                                  ? new Date()
                                  : new Date(arr[index - 1].depdate)
                              }
                              className={'dashboard-calendar'}
                            />
                          </Box>
                        </Grow>
                      </Box>
                      
                      <Box
                        style={{
                          width: '50%',
                          background: 'var(--white)',
                          borderRadius:"8px"
                        }}
                      >
                        <Stack
                          sx={{ px: 1.5, py: 1 }}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Button
                            size="small"
                            title="Remove City"
                            disabled={arr.length === 1 ? true : false}
                            sx={{
                              py: 0,
                              visibility: index === 0 ? 'hidden' : 'visible',
                              backgroundColor: 'var(--tomato-color)',
                              color: 'var(--white)',
                              '&:hover': {
                                backgroundColor: 'var(--tomato-color)',
                                color: 'var(--white)',
                                cursor: 'pointer',
                              },
                              zIndex: '1',
                            }}
                            onClick={() => removeCity(segment.id)}
                          >
                            <RemoveIcon sx={{ zIndex: '1' }} />
                          </Button>
                          <Button
                            size="small"
                            title="Add City"
                            disabled={arr.length > 3 ? true : false}
                            sx={{
                              py: 0,
                              backgroundColor: 'var(--primary-color)',
                              color: 'var(--white)',
                              '&:hover': {
                                backgroundColor: 'var(--primary-color)',
                                color: 'var(--white)',
                                cursor: 'pointer',
                              },
                              zIndex: '1',
                            }}
                            onClick={addCity}
                          >
                            <AddIcon sx={{ zIndex: '1' }} />
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>

                  {/* //todo: Passenger Box section */}
                  {arr.length - 1 === index ? (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        position: 'relative',
                        mt: { xs: 1, md: 1.5 },
                        zIndex: '9',
                      }}
                    >
                              <Collapse
                          in={segment.open}
                          timeout="auto"
                          unmountOnExit
                          sx={{ width: '100%' }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              top: {
                                lg: '110%',
                                md: '110%',
                                sm: '100%',
                                xs: '100%',
                              },
                              right: '0px',
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: 'var(--secondary-color)',
                                padding: '15px',
                                overflow: 'hidden',
                                width: '300px',
                                border: '1px solid var(--gray)',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'start',
                              }}
                            >
                              <Box>
                                <Box>
                                  <Typography
                                    style={{
                                      textAlign: 'left',
                                      marginBottom: '5px',
                                      color: 'var(--primary-color)',
                                      fontFamily: 'poppins',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Passenger
                                  </Typography>
                                </Box>

                                <Box>
                                  <Stack direction="row" spacing={1}>
                                    <Box
                                      sx={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: 'var(--white)',
                                        borderRadius: '5px',
                                        width: '25px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                      }}
                                      onClick={adultDecrement}
                                    >
                                      -
                                    </Box>
                                    <Box
                                      sx={{
                                        color: 'var(--white)',
                                        borderRadius: '5px',
                                        width: '20px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {adultCount}
                                    </Box>
                                    <Box
                                      onClick={adultInclement}
                                      sx={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: 'var(--white)',
                                        borderRadius: '5px',
                                        width: '25px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      +
                                    </Box>

                                    <Typography
                                      sx={{
                                        color: 'var(--white)',
                                        fontSize: '12px',
                                      }}
                                    >
                                      Adult (12+ Years)
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" spacing={1} mt={1}>
                                    <Box
                                      sx={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: 'var(--white)',
                                        borderRadius: '5px',
                                        width: '25px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                      }}
                                      onClick={childDecrement}
                                    >
                                      -
                                    </Box>
                                    <Box
                                      sx={{
                                        color: 'var(--white)',
                                        borderRadius: '5px',
                                        width: '20px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {childCount}
                                    </Box>
                                    <Box
                                      onClick={childInclement}
                                      sx={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: 'var(--white)',
                                        borderRadius: '5px',
                                        width: '25px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      +
                                    </Box>

                                    <Typography
                                      sx={{
                                        color: 'var(--white)',
                                        fontSize: '12px',
                                      }}
                                    >
                                      Children (2 - 11 years)
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" spacing={1} mt={1}>
                                    <Box
                                      sx={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: 'var(--white)',
                                        borderRadius: '5px',
                                        width: '25px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                      }}
                                      onClick={infantDecrement}
                                    >
                                      -
                                    </Box>
                                    <Box
                                      sx={{
                                        color: 'var(--white)',
                                        borderRadius: '5px',
                                        width: '20px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {infant}
                                    </Box>
                                    <Box
                                      onClick={infantIncrement}
                                      sx={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: 'var(--white)',
                                        borderRadius: '5px',
                                        width: '25px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      +
                                    </Box>

                                    <Typography
                                      sx={{
                                        color: 'var(--white)',
                                        fontSize: '12px',
                                      }}
                                    >
                                      Infant (Under 2 years)
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Box>
                              <Box my={1} sx={{ width: '100%' }}>
                                <hr />
                              </Box>
                              <Box>
                                <Box
                                  sx={{
                                    span: {
                                      fontSize: 12,
                                    },
                                  }}
                                >
                                  <FormControl>
                                    <RadioGroup
                                      value={className}
                                      row
                                      onChange={handleClassName}
                                    >
                                      <FormControlLabel
                                        value="Y"
                                        control={<BpRadio />}
                                        label="Economy"
                                        sx={{
                                          color: 'var(--white)',
                                        }}
                                      />
                                      <FormControlLabel
                                        value="S"
                                        control={<BpRadio />}
                                        label="Premium Economy"
                                        sx={{
                                          color: 'var(--white)',
                                        }}
                                      />
                                      <FormControlLabel
                                        value="C"
                                        control={<BpRadio />}
                                        label="Business"
                                        sx={{
                                          mr: 2.5,
                                          color: 'var(--white)',
                                        }}
                                      />
                                      <FormControlLabel
                                        value="J"
                                        control={<BpRadio />}
                                        label="Premium Business"
                                        sx={{
                                          color: 'var(--white)',
                                        }}
                                      />
                                      <FormControlLabel
                                        value="P"
                                        control={<BpRadio />}
                                        label="First Class"
                                        sx={{
                                          color: 'var(--white)',
                                        }}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Box>
                                <Box mt={2} style={{ textAlign: 'right' }}>
                                  <Button
                                    size="small"
                                    // onClick={handleClose}
                                    className="shine-effect"
                                    style={{
                                      backgroundColor: 'var(--primary-color)',
                                      color: 'var(--white)',
                                    }}
                                    onClick={() =>
                                      handleClickOpen(index, segment)
                                    }
                                  >
                                    DONE
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Collapse>
                      <Box
                        sx={{
                          position: 'relative',
                          display:'flex',
                          justifyContent:'start',alignItems:'center',
                          flexDirection:{xs:'column',sm:"row"}
                        }}
                      >
                        <Box sx={{width:"74px"}}>

</Box>

                        <Box
                          // sx={{
                          //   display: 'flex',
                          //   justifyContent: 'center',
                          //   alignItems: 'center',
                          //   width: '100%',
                          //   borderRadius: '10px',
                          //   background: 'var(--white)',
                          //   border: '1px solid var(--gray)',
                          // }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            borderRadius: '10px',
                            gap:"10px",
          
                            // background: 'var(--white)',
                            // border: '1px solid var(--gray)',
                          }}
                          onClick={() => handleClickOpen(index, segment)}
                        >
                          {/* //todo: Class section */}
                          <Box
                            sx={{
                              width: '50%',
                              background: 'var(--white)',
                // borderRight: '1px solid var(--gray)',
                display: 'flex',
                alignItems: 'center',
                px: 1.5,
                py: 1,
                borderRadius:"8px"
                            }}
                          >
                            <AirlineSeatReclineNormalIcon
                              sx={{
                                fontSize: '25px',
                                color: 'var(--primary-color)',
                              }}
                            />
                            <Typography
                              sx={{
                                color: 'var(--primary-color)',
                                fontSize: '14px',
                                fontWeight: '500',
                              }}
                              noWrap
                            >
                              {`${
                                className === 'S'
                                  ? 'Premium Economy'
                                  : className === 'C'
                                  ? 'Business'
                                  : className === 'J'
                                  ? 'Premium Business'
                                  : className === 'P'
                                  ? 'First Class'
                                  : 'Economy'
                              }`}
                            </Typography>
                          </Box>
                          {/* //todo: Traveler Section */}
                          <Box
                            sx={{
                              width: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              py: 1,
                              px: 1.5,
                              bgcolor:'var(--white)',
                              borderRadius:"8px"
                            }}
                          >
                            <GroupsIcon
                              sx={{
                                fontSize: '25px',
                                color: 'var(--primary-color)',
                              }}
                            />
                            &nbsp;&nbsp;
                            <Typography
                              sx={{
                                color: 'var(--primary-color)',
                                fontSize: '14px',
                                fontWeight: '500',
                              }}
                            >
                              {result} Traveler
                            </Typography>
                          </Box>
                        </Box>
                        {/* //todo: Passenger Box */}
                
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </ClickAwayListener>
            </Box>
          ))}
          {/* //todo: Search Button */}
          {/* <Grid md={12} lg={12} sm={12} xs={12}>
          
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={handleSearch}
                className="shine-effect"
                sx={{
                  fontSize: '14px',
                  width: {
                    xs: '100%',
                  },
                  backgroundColor: 'var(--primary-color)',
                  color: 'var(--white)',
                  textTransform: 'capitalize',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: 'var(--primary-color)',
                    cursor: 'pointer',
                  },
                }}
              >
                <SearchIcon style={{ fontSize: '25px' }} />
                {click ? 'Wait...' : 'Search'}
              </Button>
            </Box>
          </Grid> */}
            <Grid item sx={{ mt: { xs: 1, md: 5 },display:'flex',   height: 'fit-content',
                    width: { xs: '100%', sm: '550px', md: '600', lg: '600px' },
                    position: 'relative',
                    flexDirection:{xs:'column',sm:"row"}
                  }}  >
          <Box sx={{         width: '72px',}}>
            
          </Box>
            <Box
              sx={{
                zIndex: 1,
                width: '100%',
              }}
            >
              <Tooltip title="Click To Search">
                <Button
                  // type="submit"
                  variant='contained'
                  onClick={handleSearch}
                  disabled={
                    faddress?.split(',')[0] === toAddress?.split(',')[0] &&
                    !click
                      ? true
                      : faddress?.split(',')[0] !== toAddress?.split(',')[0] &&
                        click
                      ? true
                      : false
                  }
                  sx={{
                    width: '100%',
                    borderRadius: '10px',
                    mt: { md: '0px', sm: '10px', xs: '10px' },
                    backgroundColor: 'var(--search-btn)',
                    py:2,
                    color: 'var(--white)',
                    '&:hover': {
                      backgroundColor: 'var(--primary-color)',
                      color: 'var(--white)',
                      cursor: 'pointer',
                    },
                  }}
                >
                  {click ? (
                    'Wait...'
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <SearchIcon sx={{ fontSize: 25 }} />
                      <Typography
                        sx={{ fontSize: '16px', color: 'var(--white)' }}
                      >
                        Search
                      </Typography>
                    </Box>
                  )}
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default Multicity;
