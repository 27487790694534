import React from 'react';
import { Box } from '@mui/material';

const MarkupDataTable = ({ markUpData, isLoading, Loader }) => {
  return (
    <Box
      sx={{
        mt: '5px',
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '0px',
          background: 'var(--secondary-color)',
        },
        height: 'calc(100vh - 180px)',
        overflowY: 'scroll',
        table: {
          width: '100%',
          position: 'relative',
          borderCollapse: 'collapse',
        },
        th: {
          position: 'sticky',
          top: 0,
          zIndex: '999',
          background: 'var(--primary-color)',
          color: 'white',
          border: '1px solid #ddd',
          padding: '8px 12px',
          fontSize: '14px',
          fontWeight: '500',
        },
        td: {
          textAlign: 'center',
          verticalAlign: 'middle',
          border: '1px solid #ddd !important',
          padding: '5px !important',
          fontSize: '12px',
        },
        'tr:nth-child(even)': {
          background: '#f2f2f2',
        },
        'tr:hover': {
          background: '#ddd',
        },
      }}
    >
      {!isLoading ? (
        <table>
          <tr>
            <th>API Markup Type</th>
            <th>API Markup Price</th>
            <th>Void Markup Type</th>
            <th>Void Price</th>
            <th>Refund Markup Type</th>
            <th>Refund Price</th>
            <th>Reissue Markup Type</th>
            <th>Reissue Price</th>
          </tr>

          <tbody>
            {markUpData?.map((data, index) => (
              <tr key={index}>
                <td>{data?.markuptype || 'N/A'}</td>
                <td>{data?.markup || '0'}</td>
                <td>{data?.voidmarkuptype || 'N/A'}</td>
                <td>{data?.voidmarkup || '0'}</td>
                <td>{data?.refundmarkuptype || 'N/A'}</td>
                <td>{data?.refundmarkup || '0'}</td>
                <td>{data?.reissuemarkuptype || 'N/A'}</td>
                <td>{data?.reissuemarkup || '0'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70vh',
            width: '70vw',
            marginInline: 'auto',
          }}
        >
          <Box
            style={{
              width: '50%',
              height: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Loader}
              alt="loader"
              style={{
                width: '100%',
                objectFit: 'center',
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MarkupDataTable;
