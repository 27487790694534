import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { IdleSessionTimeout } from "idle-session-timeout";

function SessionTimeout({ sessionTimeout }) {
  const navigate = useNavigate();

  //time out in 5 min on inactivity
  let session = new IdleSessionTimeout(sessionTimeout);

  session.onTimeOut = () => {
    // here you can call your server to log out the user

    Swal.fire({
      icon: "warning",
      title: "Session has timed out",
      text: "Your session has timed out. You have been logged out.",
      confirmButtonText: "Ok",
      confirmButtonColor: "var(--primary-color)",
    }).then(() => {
      secureLocalStorage.clear();
      navigate("/");
    });
  };
}

export default SessionTimeout;
