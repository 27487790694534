import { Container, Modal } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { addDays, format } from 'date-fns';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FlightSearchBox from '../FlightSearchBox/FlightSearchBox';

import './HomeSearchBox.css';
import secureLocalStorage from 'react-secure-storage';
import License from '../License/License';
import VisaForm from '../VisaForm/VisaForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'var(--light-gray)',
  borderRadius: '20px',
  boxShadow: 24,
  px: { xs: 2, sm: 4 },
  py: 2,
};

const HomeSearchBox = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem('user-info');
  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(null);
  const [changeState, setChangeState] = useState(null);
  //todo: End state for from date change
  const [type, setType] = React.useState('flight');
  const [fromSearchText, setFromSearchText] = useState(
    'Hazrat Shahjalal Intl Airport [ DAC ]'
  );
  const [toSearchText, setToSearchText] = useState('Dubai Int Airport [ DXB ]');

  const [departureDate, setDepartureDate] = useState(
    format(addDays(new Date(), 1), 'dd MMM yy')
  );
  const [returningDate, setReturningDate] = useState(
    format(addDays(new Date(departureDate), 3), 'dd MMM yy')
  );
  const [travelDate, setTravelDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 3),
      key: 'selection',
    },
  ]);
  // for multiCity
  const now = useRef(new Date());
  const [value, setValue] = React.useState('oneway');
  const [className, setClassName] = useState('Y');
  const [from, setFrom] = useState(addDays(now.current, 1));
  const [to, setTo] = useState(addDays(now.current, 3));
  const [faddress, setfaddress] = useState('Hazrat Shahjalal Intl Airport');
  const [toAddress, setToAddress] = useState('Dubai Int Airport');
  const [fromSendData, setFromSendData] = useState('DAC');
  const [toSendData, setToSendData] = useState('DXB');
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infant, setInfant] = useState(0);
  const [kids, setKids] = useState(0);
  const [result, setResult] = useState(1);
  const [directFlightOnly, setDirectFlightOnly] = useState(false);

  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };

  const [searchData, setSearchData] = useState({
    adultcount: adultCount,
    childcount: childCount,
    infantcount: infant,
    connection: 1,
    cabinclass: className || 'Y',
    kidscount: kids,
    currency: '',
    brandedfare: true,
    extrabaggage: true,
    directflight: false,
    airline: '',
    segments: [
      {
        id: 0,
        openFrom: false,
        depfrom: fromSendData.trim(),
        depFromText: fromSearchText.trim(),
        arrto: 'DXB',
        arrToText: 'Dubai Intl Airport [ DXB ]',
        openTo: false,
        depdate: new Date().toLocaleDateString('sv'),
        openDate: false,
        open: false,
      },
    ],
  });

  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });

  // ------------------------

  const [openLicense, setOpenLicense] = useState(false);
  const handleOpenLicense = () => setOpenLicense(true);
  const handleCloseLicense = () => setOpenLicense(false);

  const [openVisa, setOpenVisa] = useState(false);
  const handleOpenVisa = () => setOpenVisa(true);
  const handleCloseVisa = () => setOpenVisa(false);

  return (
    <Box>
      <Container>
        <Box
          sx={{
            // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            background: 'rgba(7, 152, 137, 0.5)',
            // boxShadow:
            //   ' rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
            p: { xs: 1, sm: 3, md: 3 },
            my: 3,
            borderRadius: '10px',
            // width: 'fit-content',
          }}
        >
          <FlightSearchBox
            options={options}
            setOptions={setOptions}
            value={value}
            setValue={setValue}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            departureDate={departureDate}
            setDepartureDate={setDepartureDate}
            returningDate={returningDate}
            setReturningDate={setReturningDate}
            travelDate={travelDate}
            setTravelDate={setTravelDate}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            faddress={faddress}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            kids={kids}
            setKids={setKids}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            setClassName={setClassName}
            changeFrom={changeFrom}
            setChangeState={setChangeState}
            changeState={changeState}
            setChangeFrom={setChangeFrom}
            directFlightOnly={directFlightOnly}
            setDirectFlightOnly={setDirectFlightOnly}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        </Box>
      </Container>
      {/* //todo: Visa Modal */}
      <Modal open={openVisa} onClose={handleCloseVisa}>
        <Box
          sx={{
            ...style,
            width: { xs: '90%', sm: '80%', md: '50%' },
            height: { xs: 400, sm: 400, md: 'auto' },
            overflowY: { xs: 'scroll', sm: 'scroll', md: 'auto' },
          }}
        >
          <VisaForm handleCloseVisa={handleCloseVisa} />
        </Box>
      </Modal>
      {/* //todo: License Modal */}
      <Modal open={openLicense} onClose={handleCloseLicense}>
        <Box
          sx={{
            ...style,
            width: { xs: '90%', sm: '80%', md: '50%' },
            height: { xs: 400, sm: 400, md: 'auto' },
            overflowY: { xs: 'scroll', sm: 'scroll', md: 'auto' },
          }}
        >
          <License handleCloseLicense={handleCloseLicense} />
        </Box>
      </Modal>
    </Box>
  );
};

export default HomeSearchBox;
