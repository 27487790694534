/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { memo, useState } from 'react';
import './AirlinesFilter.css';
import { ReactComponent as ResetSvg } from './reset.svg';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
const stops = [
  { label: 'Direct', value: 1 },
  { label: '1 Stop', value: 2 },
  { label: '1+ Stop', value: 3 },
];
const type = [
  { label: 'Departure', value: 1 },
  { label: 'Arrival', value: 2 },
];

const refundable = [
  { name: 'Non Refundable', value: false },
  { name: 'Refundable', value: true },
];
const providers = [{ name: 'Sabre' }, { name: 'Galileo' }];
const departureTime = [
  {
    value: '1',
    label: '12 AM - 06 AM',
    startDate: '00:00:00',
    endDate: '06:00:00',
  },
  {
    value: '2',
    label: '06 AM - 12 PM',
    startDate: '06:00:00',
    endDate: '12:00:00',
  },
  {
    value: '3',
    label: '12 PM - 06 PM',
    startDate: '12:00:00',
    endDate: '18:00:00',
  },
  {
    value: '4',
    label: '06 PM - 12 AM',
    startDate: '18:00:00',
    endDate: '24:00:00',
  },
];

// eslint-disable-next-line react/display-name
const AirlinesFilter = memo(
  ({
    flightData,
    tripType,
    uniqueLayover,
    handleResetData,
    handleRefundable,
    handleProvider,
    selectedProvider,
    handleAirLine,
    handleStops,
    selectedStops,
    handleLayover,
    handleDepartTime,
    selectedDepartTime,
    selectedArrivalTime,
    selectedBackDepartTime,
    selectedBackArrivalTime,
    selectedAirlins,
    selectedRefundable,
    selectedLayover,
    uniqueCarriers,
  }) => {
    // const segmentArray = flightData[0]?.segments;
    // const firstSegmentData = segmentArray[0][0];
    // const lastSegmentData = segmentArray[0][segmentArray[0]?.length - 1];

    const [dateState, setDateState] = useState(1);
    const handleChange = (value) => {
      setDateState(value);
    };

    return (
      <Box className="label-css " sx={{borderRadius:"10px",padding:"16px",paddingBottom:"116px",bgcolor:'var(--white)',  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',}}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            borderBottom: '1px solid #808080', 
         
            py: 1,
            mb: 2,
            // borderRadius: '5px',
          }}
        >

          <Typography
            sx={{
              color: 'var(--primary-color)',
              fontSize: { xs: 15 },
              fontWeight: 500,
              display:'flex',justifyContent:'start',alignItems:'center',gap:"4px"
            }}
          >
            <FilterAltOutlinedIcon sx={{      color: 'var(--primary-color)',}} />
           <span> Filter</span>
          </Typography>
          <button
            style={{
              cursor:'pointer',
              // border: '1px solid var(--primary-color)',
              borderRadius: '5px',
              background:'none',
              border:"none",
              display:'flex',
              justifyContent:'center',alignItems:'center'
              // color: 'var(--primary-color)',
            }}
            onClick={() => handleResetData()}
          >
            <ResetSvg/>
          </button>
        </Stack>

        <Box
          sx={{
            // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            borderBottom: '1px solid #808080', 
            py: 1,
            mb: 2,
           
          }}
        >
          <Typography
            sx={{
              color: 'var(--primary-color)',
              fontSize: { xs: 14 },
              fontWeight: 500,
              py: 1,
            }}
          >
            Stops
          </Typography>

          <Box>
            {stops.map((provider, i) => (
              <div key={i}>
                <label htmlFor={provider.value} className="form-control" style={{color:"#808080"}}>
                  <input
                    type="checkbox"
                    id={provider.value}
                    name={provider.value}
                    checked={selectedStops.includes(provider.value)}
                    onChange={() => handleStops(provider.value)}
                  />
                  {provider.label}
                </label>
              </div>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            py: 1,
            mb: 2,
            borderBottom: '1px solid #808080', 
          }}
        >
          <Typography
            sx={{
              color: 'var(--primary-color)',
              fontSize: { xs: 14 },
              fontWeight: 500,
              py: 1,
              mt: 1,
            }}
          >
            Airlines
          </Typography>

          <Box>
            {uniqueCarriers?.map((provider, i) => (
              <div key={i}>
                <label htmlFor={provider.code} className="form-control" style={{color:"#808080"}}>
                  <input
                    type="checkbox"
                    id={provider.code}
                    checked={selectedAirlins.includes(provider.code)}
                    onChange={() => handleAirLine(provider.code)}
                  />
                  {provider.name}
                </label>
              </div>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            py: 1,
            mb: 2,
            borderBottom: '1px solid #808080', 
          }}
        >
          <Typography
            sx={{
              color: 'var(--primary-color)',
              fontSize: { xs: 14 },
              fontWeight: 500,
              py: 1,
              mt: 1,
            }}
          >
            Refundable 
          </Typography>

          <Box>
            {refundable.map((provider, i) => (
              <div key={i}>
                <label htmlFor={provider.name} className="form-control" style={{color:"#808080"}}>
                  <input
                    type="checkbox"
                    id={provider.name}
                    checked={selectedRefundable.includes(provider.value)}
                    onChange={() => handleRefundable(provider.value)}
                  />
                  {provider.name}
                </label>
              </div>
            ))}
          </Box>
        </Box>

        {/* <Box pt={1}>
          <ButtonFilters
            data={type}
            buttonState={dateState}
            handleChange={handleChange}
            bgcolor="var(--bgcolor)"
            px={3}
          />
        </Box> */}

        {/* {dateState === 1 ? (
          <Box
            sx={{
              button: {
                textTransform: 'capitalize',
                fontWeight: 400,
                fontSize: 12,
                px: 1,
                borderRadius: '5px',
                py: 0.5,
              },
            }}
          >
            <Typography
              sx={{
                color: 'var(--black)',
                fontSize: { xs: 13 },
                fontWeight: 500,
                py: 1,
              }}
              noWrap
            >
              Departure Time {firstSegmentData.departureLocation?.split(',')[0]}
            </Typography>

            <Grid container spacing={{ xs: 0.5 }}>
              {departureTime.map((button, i) => (
                <ButtonTimeFilter
                  key={i}
                  button={button}
                  selected={selectedDepartTime.name}
                  type="Depart"
                  handleTimeFilter={handleDepartTime}
                />
              ))}
            </Grid>
            {tripType === 'roundway' && (
              <>
                <Typography
                  sx={{
                    color: 'var(--black)',
                    fontSize: { xs: 13 },
                    fontWeight: 500,
                    py: 1,
                  }}
                  noWrap
                >
                  Departure Time{' '}
                  {lastSegmentData.arrivalLocation?.split(',')[0]}
                </Typography>
                <Grid container spacing={{ xs: 0.5 }}>
                  {departureTime.map((button, i) => (
                    <ButtonTimeFilter
                      key={i}
                      button={button}
                      selected={selectedBackDepartTime.name}
                      type="returnDepart"
                      handleTimeFilter={handleDepartTime}
                    />
                  ))}
                </Grid>
              </>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              button: {
                textTransform: 'capitalize',
                fontWeight: 400,
                fontSize: 12,
                px: 1,
                borderRadius: '5px',
                py: 0.5,
              },
            }}
          >
            <Typography
              sx={{
                color: 'var(--black)',
                fontSize: { xs: 13 },
                fontWeight: 500,
                py: 1,
              }}
              noWrap
            >
              Arrival Time {lastSegmentData.arrivalLocation?.split(',')[0]}
            </Typography>

            <Grid container spacing={{ xs: 0.5 }}>
              {departureTime.map((button, i) => (
                <ButtonTimeFilter
                  key={i}
                  button={button}
                  selected={selectedArrivalTime.name}
                  type="Arrival"
                  handleTimeFilter={handleDepartTime}
                />
              ))}
            </Grid>

            {tripType === 'roundway' && (
              <>
                {' '}
                <Typography
                  sx={{
                    color: 'var(--black)',
                    fontSize: { xs: 13 },
                    fontWeight: 500,
                    py: 1,
                  }}
                  noWrap
                >
                  Arrival Time{' '}
                  {firstSegmentData.departureLocation?.split(',')[0]}
                </Typography>
                <Grid container spacing={{ xs: 0.5 }}>
                  {departureTime.map((button, i) => (
                    <ButtonTimeFilter
                      key={i}
                      button={button}
                      selected={selectedBackArrivalTime.name}
                      type="returnArrival"
                      handleTimeFilter={handleDepartTime}
                    />
                  ))}
                </Grid>
              </>
            )}
          </Box>
        )} */}
        <Box
          sx={{
            // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            py: 1,
            mb: 2,
            borderBottom: '1px solid #808080', 
          }}
        >
          {uniqueLayover?.length > 0 ? (
            <Box>
              <Typography
                sx={{
                  color: 'var(--primary-color)',
                  fontSize: { xs: 14 },
                  fontWeight: 500,
                  py: 1,
                  mt: 1,
                }}
              >
                Layover Airport
              </Typography>

              <Box>
                {uniqueLayover.map((provider, i) => (
                  <div key={i}>
                    <label htmlFor={provider.code} className="form-control" style={{color:"#808080"}}>
                      <input
                        type="checkbox"
                        id={provider.code}
                        checked={selectedLayover.includes(provider.code)}
                        onChange={() => handleLayover(provider.code)}
                      />
                      {provider.name}
                    </label>
                  </div>
                ))}
              </Box>
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
    );
  }
);

export default AirlinesFilter;
