import { Box, Grid } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';

const AgentMarkup = ({
  account,
  reFetch,
  setRefetch,
  setIsSubmitting,
  isSubmitting,
}) => {
  const users = secureLocalStorage.getItem('user-info');
  let uid = users?.uid;
  const [markupData, setMarupData] = useState({});

  const handleMarkupData = (e) => {
    setMarupData({ ...markupData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e, logo) => {
    let body = {
      company: account?.company,
      name: account?.name,
      phone: account?.phone,
      address: account?.address,
      password: account?.password,
      markuptype: markupData?.markuptype,
      markup: markupData?.amount,
      logo: account?.logo,
    };

    window.event.preventDefault();
    setIsSubmitting(true);
    let url = `https://api-b2b.projectota.com/agent/myaccount/${uid}`;

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        e.target.reset();
        Swal.fire({
          title: 'Markup Updated',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(() => {
          setRefetch(!reFetch);
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      setIsSubmitting(false);
    } catch (err) {
      e.target.reset();
      Swal.fire({
        title: err.message,
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Try Again',
      }).then(() => {
        setRefetch(!reFetch);
      });
      setIsSubmitting(false);
    }
  };
  const handleReset = async (e, logo) => {
    let body = {
      company: account?.company,
      name: account?.name,
      phone: account?.phone,
      address: account?.address,
      password: account?.password,
      markuptype: '',
      markup: 0,
      logo: account?.logo,
    };

    let url = `https://api-b2b.projectota.com/agent/myaccount/${uid}`;

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setRefetch(!reFetch);
        e.target.reset();
        Swal.fire({
          title: 'Markup Updated',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(() => {
          setRefetch(!reFetch);
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (err) {
      e.target.reset();
      Swal.fire({
        title: err.message,
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Try Again',
      }).then(() => {
        setRefetch(!reFetch);
      });
    }
  };

  return (
    <div>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={9} md={9}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <select
                    style={{
                      width: '100%',
                      padding: '5px 10px',
                      outline: 'none',
                      border: '1px solid var(--secondary-color)',
                      borderRadius: '4px !important',
                    }}
                    required
                    name="markuptype"
                    value={markupData?.markuptype || ''}
                    onChange={handleMarkupData}
                  >
                    <option value="">Markup Type</option>
                    <option value="amount">Amount</option>
                    <option value="percent">Percent(%)</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <input
                    name="amount"
                    value={markupData?.amount || ''}
                    type="number"
                    placeholder="Amount"
                    required
                    style={{
                      width: '100%',
                      padding: '5px 10px',
                      outline: 'none',
                      border: '1px solid var(--secondary-color)',
                      borderRadius: '4px !important',
                    }}
                    onChange={handleMarkupData}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <button
                    type="submit"
                    style={{
                      width: '100%',
                      background: 'var(--secondary-color)',
                      color: 'white',
                      outline: 'none',
                      padding: '5px 10px',
                      borderRadius: '4px',
                      fontSize: '14px',
                      cursor: 'pointer',
                      border: '1px solid var(--secondary-color)',
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <button
              style={{
                width: '100%',
                background: 'var(--secondary-color)',
                color: 'white',
                outline: 'none',
                padding: '5px 10px',
                borderRadius: '4px',
                cursor: 'pointer',

                fontSize: '14px',
                border: '1px solid var(--secondary-color)',
              }}
              onClick={handleReset}
            >
              Reset
            </button>
          </Grid>
        </Grid>

        <Grid container mt={4}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                mt: '5px',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '0px',
                  background: 'var(--secondary-color)',
                },
                height: 'calc(100vh - 180px)',
                overflowY: 'scroll',
                table: {
                  width: '100%',
                  position: 'relative',
                  borderCollapse: 'collapse',
                },
                th: {
                  position: 'sticky',
                  top: 0,
                  zIndex: '999',
                  background: 'var(--primary-color)',
                  color: 'white',
                  border: '1px solid #ddd',
                  padding: '8px 12px',
                  fontSize: '14px',
                  fontWeight: '500',
                },
                td: {
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  border: '1px solid #ddd !important',
                  padding: '5px !important',
                  fontSize: '12px',
                },
                'tr:nth-child(even)': {
                  background: '#f2f2f2',
                },
                'tr:hover': {
                  background: '#ddd',
                },
              }}
            >
              <table>
                <tr>
                  <th>Markup Type</th>
                  <th>Amount</th>
                </tr>
                <tbody>
                  <tr>
                    <td> {account?.markuptype || 'N/A'}</td>
                    <td>{account?.markup || 0}</td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AgentMarkup;
