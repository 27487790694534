import { Box, Button, Container, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import secureLocalStorage from 'react-secure-storage';

const AdminAddStaff = () => {
  const navigate = useNavigate();
  const adminInfo = secureLocalStorage.getItem('admin-info');
  const token = adminInfo?.token;
  const [fName, setFname] = useState('');
  const [lName, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [contact, setContact] = useState('');
  const [role, setRole] = useState('Admin');

  const handleAdd = (e) => {
    e.preventDefault();

    let url = `https://api-b2b.projectota.com/admin`;
    let body = JSON.stringify({
      firstname: fName,
      lastname: lName,
      email: email,
      password: password,
      phone: contact,
      role: role,
    });

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include the token in the header
      },
      body: body,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Added Successfully',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate(-1);
        });
      })
      .catch((error) => {
        console.error('Error adding data:', error);
        Swal.fire({
          icon: 'error',
          title: error?.message,
          text: 'Failed to add data. Please try again.',
          confirmButtonText: 'OK',
        });
      });
  };

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box mt={2}>
          <Typography
            sx={{ fontWeight: '500px', fontSize: '23px', color: '#003566' }}
          >
            Add Admin
          </Typography>

          <form onSubmit={handleAdd}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: 'var(--primary-color)', fontSize: '14px' }}
                  >
                    First Name
                  </Typography>
                  <input
                    required
                    type="text"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid var(--black)',
                      width: '100%',
                      outline: 'none',
                    }}
                    placeholder="First Name"
                    onChange={(e) => setFname(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    style={{ color: 'var(--primary-color)', fontSize: '14px' }}
                    mb={1}
                  >
                    Last Name
                  </Typography>
                  <input
                    required
                    type="text"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid var(--black)',
                      width: '100%',
                      outline: 'none',
                    }}
                    placeholder="Last Name"
                    onChange={(e) => setLname(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: 'var(--primary-color)', fontSize: '14px' }}
                  >
                    Email
                  </Typography>
                  <input
                    required
                    type="text"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid var(--black)',
                      width: '100%',
                      outline: 'none',
                    }}
                    placeholder="Email Name"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: 'var(--primary-color)', fontSize: '14px' }}
                  >
                    Password
                  </Typography>
                  <input
                    required
                    type="password"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid var(--black)',
                      width: '100%',
                      outline: 'none',
                    }}
                    placeholder="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: 'var(--primary-color)', fontSize: '14px' }}
                  >
                    Contact Number
                  </Typography>
                  <input
                    required
                    type="text"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid var(--black)',
                      width: '100%',
                      outline: 'none',
                    }}
                    placeholder="Contact Number"
                    onChange={(e) => setContact(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: 'var(--primary-color)', fontSize: '14px' }}
                  >
                    Role
                  </Typography>

                  <select
                    onChange={(e) => setRole(e.target.value)}
                    style={{
                      border: 'none',
                      borderBottom: '1px solid var(--black)',
                      width: '100%',
                      outline: 'none',
                    }}
                  >
                    <option value={'Admin'}>Admin</option>
                    <option value={'Accountant'}>Accountant</option>
                    <option value={'Staff'}>Staff</option>
                  </select>
                </Box>
              </Grid>
            </Grid>

            <Button
              sx={{
                background: 'var(--mateBlack)',
                color: '#FFFFFF',
                width: '370px',
                mt: '4rem',
                '&:hover': {
                  backgroundColor: 'var(--mateBlack)',
                },
              }}
              type="submit"
            >
              Add This Account
            </Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminAddStaff;
