import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import secureLocalStorage from 'react-secure-storage';
import CircularProgress from '@mui/material/CircularProgress';
import addAccount from '../../images/undraw/undraw_credit_card_re_blml.svg';
import invalidInfo from '../../images/undraw/undraw_warning_re_eoyh.svg';
import { AdminHeader } from '../AdminHeader/AdminHeader';

const AdminAddBank = () => {
  const navigate = useNavigate();
  const adminInfo = secureLocalStorage.getItem('admin-info');
  const [isLoading, setIsLoading] = useState(false);
  let token = adminInfo?.token;
  const [bankName, setBankName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const apiUrl = 'https://api-b2b.projectota.com/admin/banklist';

    if (!token) {
      console.error('Token is missing');
      setIsLoading(false);
      return;
    }

    let body = JSON.stringify({
      bankname: bankName,
      accountname: accountName,
      accountnumber: accountNumber,
    });

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the header
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      Swal.fire({
        imageUrl: addAccount,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        title: 'Bank Account Added Successfully!',
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
      }).then(() => {
        navigate(-1);
      });
    } catch (error) {
      console.error('Error submitting data:', error);
      Swal.fire({
        imageUrl: invalidInfo,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        text: 'Invalid Information',
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
      }).then(() => {
        navigate(0);
      });
    }

    setIsLoading(false);
    e.target.reset();
  };

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box
          sx={{
            margin: '0px 0px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontWeight: 500,
              fontSize: '24px',
              color: '#222222',
              fontFamily: 'poppins',
            }}
          >
            Add Bank Account
          </Typography>
        </Box>

        <Box className="balance-transaction" marginTop={'2px'}>
          <form onSubmit={handleSubmit}>
            <Box className="passengerInput1">
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Typography>Bank Name</Typography>
                  <Box style={{ marginTop: '5px' }}>
                    <input
                      required
                      type="text"
                      placeholder="Bank Name"
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Typography>Account Name</Typography>
                  <Box style={{ marginTop: '5px' }}>
                    <input
                      required
                      type="text"
                      placeholder="Account Name"
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Typography>Account Number</Typography>
                  <Box style={{ marginTop: '5px' }}>
                    <input
                      required
                      type="text"
                      placeholder="Account Number"
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Button
              sx={{
                fontFamily: 'poppins',
                fontWeight: '400',
                fontSize: '14px',
                textTransform: 'capitalize',
                borderRadius: '2px',
                background: 'var(--mateBlack)',
                color: '#FFFFFF',
                width: '370px',
                mt: '3rem',
                '&:hover': {
                  backgroundColor: 'var(--mateBlack)',
                },
              }}
              type="submit"
            >
              {!isLoading ? (
                'Add This Account'
              ) : (
                <CircularProgress
                  size="1.5rem"
                  sx={{
                    color: '#fff',
                  }}
                />
              )}
            </Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminAddBank;
