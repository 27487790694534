import React from 'react';
import Airlines from './Airlines';

const AirlineNames = ({ code }) => {
  const flightName = Airlines;
  let airname;
  {
    flightName?.map((item) => {
      if (item?.code === code?.replace(/ /g, '')) {
        airname = item?.name;
      }
    });
  }
  return airname;
};

export default AirlineNames;
