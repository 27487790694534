import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  Typography,
} from '@mui/material';
import commaNumber from 'comma-number';
import Loader from '../../images/loader/Render.gif';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'date-fns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Calendar } from 'react-date-range';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import secureLocalStorage from 'react-secure-storage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2564b8',
      darker: '#2564b8',
    },
  },
});

const AllBooking = () => {
  const navigate = useNavigate();
  const aminInfo = secureLocalStorage.getItem('admin-info');
  let token = aminInfo?.token;
  const [isLoading, setIsLoading] = useState(false);
  const [queuesData, setQueuesData] = useState([]);
  const [allQueuesData, setAllQueuesData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 10;
  //todo: for handle Filter
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), 'dd MMM yyyy'));
  const [endDate, setEndDate] = useState(format(new Date(), 'dd MMM yyyy'));

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  // data load from booking api
  useEffect(() => {
    setIsLoading(true);
    const url = `https://api-b2b.projectota.com/admin/booking`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setPageCount(Math.ceil(data.length / size));
        setQueuesData(data);
        setAllQueuesData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        // Handle the error, show a message, or perform other actions
      });
  }, [page]);

  //  short functionality handle
  const handleChangeOption = (e) => {
    const status = e.target.value?.replaceAll(/\s/g, '')?.toLowerCase();
    if (status !== 'all') {
      const filterData = allQueuesData.filter((item) => {
        return item?.status?.replaceAll(/\s/g, '')?.toLowerCase() === status;
      });

      setQueuesData(filterData);
    } else {
      setQueuesData(allQueuesData);
    }
  };

  const handelSearchItems = (e) => {
    const searchInput = e.target.value;
    if (searchInput !== '') {
      const filterData = allQueuesData.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setQueuesData(filterData);
    } else if (searchInput === '') {
      setQueuesData(allQueuesData);
    }
  };
  const sendToQueuesDetails = (data) => {
    navigate(`/dashboard/bookingdetails/${data?.uid}`, {
      state: {
        data,
      },
    });
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const filterData = allQueuesData.filter((item) => {
      return (
        new Date(item?.created_at).getDate() >= new Date(startDate).getDate() &&
        new Date(item?.created_at).getDate() <= new Date(endDate).getDate()
      );
    });
    setQueuesData(filterData);
  };

  return (
    <Box>
      <AdminHeader />
      <Box>
        <Container>
          {!isLoading ? (
            <Box>
              <Box>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  spacing={2}
                >
                  <Grid item xs={12} md={3}>
                    <Typography
                      sx={{ color: 'var(--mateBlack)', fontSize: '23px' }}
                    >
                      All Booking
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        justifyContent: 'end',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          background: 'var(--primary-color)',
                          height: '35px',
                          borderRadius: '20px',
                          input: {
                            border: 'none',
                            background: 'none',
                            outline: 'none',
                            float: 'left',
                            padding: 0,
                            color: 'white',
                            fontSize: '16px',
                            fontWeight: 'normal',
                            transition: 'all 0.4s ease-in-out',
                            lineHeight: '35px',
                            width: '0px',
                          },
                          'input::placeholder': { color: 'white' },
                          '&:hover input': {
                            width: '235px',
                            padding: '0 10px',
                          },
                          '&:hover button': {
                            background: 'white',
                            color: 'black',
                          },
                          button: {
                            border: 'none',
                            color: 'white',
                            float: 'right',
                            minHeight: '0px',
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%',
                            background: 'var(--primary-color)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transition: 'all 0.4s ease-in-out',
                            cursor: 'pointer',
                            textDecoration: 'none',
                          },
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Search....."
                          onChange={handelSearchItems}
                        />
                        <button>
                          <SearchIcon />
                        </button>
                      </Box>
                      <Box>
                        <form onSubmit={handleFilter}>
                          <Grid
                            container
                            spacing={1}
                            alignItems="end"
                            justifyContent="end"
                            sx={{
                              zIndex: '9999',
                              input: {
                                width: '100%',
                                padding: '5px 10px',
                                border: '1px solid var(--secondary-color)',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                outline: 'none',
                              },
                            }}
                          >
                            <Grid
                              style={{ position: 'relative' }}
                              item
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Box>
                                <input
                                  title="Enter Start Date"
                                  required
                                  id="startDate"
                                  name="startDate"
                                  type="text"
                                  value={startDate}
                                  readOnly
                                  onClick={() => {
                                    setOpenEndDate(false);
                                    setOpenStartDate((prev) => !prev);
                                  }}
                                />
                              </Box>
                              {openStartDate && (
                                <Calendar
                                  color={'var(--primary-color)'}
                                  date={new Date(startDate)}
                                  onChange={(date) => {
                                    setStartDate(
                                      format(new Date(date), 'dd MMM yyyy')
                                    );
                                    setOpenStartDate(false);
                                  }}
                                  maxDate={new Date()}
                                  months={1}
                                  className="new-dashboard-calendar"
                                  name="dashboard-calendar"
                                />
                              )}
                            </Grid>

                            <Grid
                              style={{ position: 'relative' }}
                              item
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Box>
                                <input
                                  title="Enter End Date"
                                  required
                                  id="endDate"
                                  name="endDate"
                                  type="text"
                                  value={endDate}
                                  readOnly
                                  onClick={() => {
                                    setOpenEndDate((prev) => !prev);
                                    setOpenStartDate(false);
                                  }}
                                />
                              </Box>
                              {openEndDate && (
                                <Calendar
                                  color="var(--primary-color)"
                                  date={new Date(endDate)}
                                  onChange={(date) => {
                                    setEndDate(
                                      format(new Date(date), 'dd MMM yyyy')
                                    );
                                    setOpenEndDate(false);
                                  }}
                                  months={1}
                                  className="new-dashboard-calendar"
                                />
                              )}
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={2}
                              sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'end',
                                gap: '5px',
                              }}
                            >
                              <Button
                                title="Click to Filter"
                                sx={{
                                  background: 'var(--secondary-color)',
                                  color: 'white',
                                  padding: '5px 20px !important',
                                  fontSize: '12px !important',
                                  width: '100%',
                                  '&:hover': {
                                    background: 'var(--secondary-color)',
                                    color: 'white',
                                  },
                                }}
                                type="submit"
                              >
                                FILTER
                              </Button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={2}
                              sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'end',
                                gap: '5px',
                              }}
                            >
                              <Button
                                disabled={
                                  allQueuesData?.status === 'Cancelled'
                                    ? true
                                    : false
                                }
                                title="Click to Reset"
                                sx={{
                                  background: 'var(--secondary-color)',
                                  color: 'white',
                                  padding: '5px 20px !important',
                                  fontSize: '12px !important',
                                  width: '100%',
                                  '&:hover': {
                                    background: 'var(--secondary-color)',
                                    color: 'white',
                                  },
                                }}
                                onClick={() => setQueuesData(allQueuesData)}
                              >
                                Reset
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Box>

                      <Box>
                        <select
                          onChange={handleChangeOption}
                          style={{
                            color: 'var(--primary-color)',
                            fontSize: '14px',
                            padding: '5px 10px',
                            borderRadius: '4px',
                            outline: 'none',
                          }}
                        >
                          <option value="all">&nbsp;Show All</option>
                          <option value="hold">&nbsp;Hold</option>
                          <option value="Issue In Processing">
                            &nbsp;Issue In Processing
                          </option>
                          <option value="Ticketed">&nbsp;Ticketed</option>

                          <option value="Void In Processing">
                            &nbsp;Void In Processing
                          </option>
                          <option value="Voided">&nbsp;Voided</option>
                          <option value="Void Rejected">
                            &nbsp;Void Rejected
                          </option>

                          <option value="Reissue In Processing">
                            &nbsp;Reissue In Processing
                          </option>
                          <option value="Reissued">&nbsp;Reissued</option>
                          <option value="Reissue Rejected">
                            &nbsp;Reissue Rejected
                          </option>

                          <option value="Refund In Processing">
                            &nbsp;Refund In Processing
                          </option>
                          <option value="Refunded">&nbsp;Refunded</option>
                          <option value="Refund Rejected">
                            &nbsp;Refund Rejected
                          </option>
                          <option value="Issue Rejected">
                            &nbsp;Issue Rejected
                          </option>
                          <option value="cancelled">&nbsp;Cancelled</option>
                        </select>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Box
                  sx={{
                    mt: '5px',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '0px',
                      background: 'var(--secondary-color)',
                    },
                    height: 'calc(100vh - 180px)',
                    overflowY: 'scroll',
                    table: {
                      width: '100%',
                      position: 'relative',
                      borderCollapse: 'collapse',
                    },
                    th: {
                      position: 'sticky',
                      top: 0,
                      zIndex: '999',
                      background: 'var(--primary-color)',
                      color: 'white',
                      border: '1px solid #ddd',
                      padding: '8px 12px',
                      fontSize: '14px',
                      fontWeight: '500',
                    },
                    td: {
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      border: '1px solid #ddd !important',
                      padding: '5px !important',
                      fontSize: '12px',
                    },
                    'tr:nth-child(even)': {
                      background: '#f2f2f2',
                    },
                    'tr:hover': {
                      background: '#ddd',
                    },
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>ReferenceId</th>
                        <th>Airlines</th>
                        <th>status</th>
                        <th>Route</th>
                        <th>Type</th>
                        <th>Pax</th>
                        <th>BookedDate</th>
                        <th>FlightDate</th>
                        <th>Passenger</th>
                        <th>Cost</th>
                      </tr>
                    </thead>

                    <tbody>
                      {queuesData?.map((bookingDetail, index) => (
                        <tr key={{ index }}>
                          <td>
                            <Button
                              disabled={
                                bookingDetail.status === 'Cancelled'
                                  ? true
                                  : false
                              }
                              sx={{
                                fontSize: '12px',
                                border: 'none',
                                cursor: 'pointer',
                                background: '#d1e9ff',
                                color: '#003566',
                                padding: '5px 10px',
                                textDecoration: 'underline',
                                borderRadius: '4px',
                                '&:hover': {
                                  background: '#d1e9ff',
                                  color: '#003566',
                                },
                              }}
                              onClick={() => sendToQueuesDetails(bookingDetail)}
                            >
                              {bookingDetail?.bookingId ?? 'Reference No'}
                            </Button>
                          </td>
                          <td>{bookingDetail?.carrier ?? 'Airlines'}</td>
                          <td>
                            {bookingDetail.status ? (
                              <Typography
                                style={{
                                  border: 'none',
                                  borderRadius: '5px',
                                  width: '100%',
                                  fontSize: '12px',
                                }}
                                className={`${bookingDetail.status
                                  ?.toLowerCase()
                                  ?.split(' ')
                                  ?.join('-')}-btn`}
                              >
                                {bookingDetail.status}
                              </Typography>
                            ) : (
                              'status'
                            )}
                          </td>
                          <td>
                            {bookingDetail.depfrom ?? 'From'} -{' '}
                            {bookingDetail.arrto ?? 'To'}
                          </td>
                          <td>{bookingDetail.triptype ?? 'Flight Type'}</td>
                          <td>{bookingDetail.totalpax ?? 'Total Pax'}</td>

                          <td>
                            {bookingDetail?.created_at !== '' ||
                            'undefined' ||
                            null
                              ? format(
                                  new Date(bookingDetail?.created_at),
                                  'dd MMM yy hh:mm a'
                                )
                              : 'Booked Date'}
                          </td>
                          <td>{bookingDetail?.flightdate || 'Flight Date'}</td>
                          <td>{bookingDetail?.name || 'Passenger Name'}</td>
                          <td>{commaNumber(bookingDetail.netfare || 0)} AED</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    my: 1,
                    py: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '8px',
                    borderTop: '1px solid var(--primary-color)',
                  }}
                >
                  <Typography style={{ fontSize: '15px', color: '#222222' }}>
                    Showing Results {page} - {pageCount}
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Stack spacing={2}>
                      <Pagination
                        size="small"
                        count={pageCount}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </Stack>
                  </ThemeProvider>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70vh',
                width: '70vw',
                marginInline: 'auto',
              }}
            >
              <Box
                style={{
                  width: '50%',
                  height: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: '100%',
                    objectFit: 'center',
                  }}
                />
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default AllBooking;
