const flightData = [
  {
    code: "BER",
    name: "Berlin Brandenburg Airport",
    Address: "Berlin,Germany",
  },
  {
    code: "BZL",
    name: "Barishal Airport",
    Address: "Barishal,Bangladesh",
  },
  {
    code: "JSR",
    name: "Jashore Airport",
    Address: "Jashore,Bangladesh",
  },
  {
    code: "RJH",
    name: "Shah Makhdum Airport",
    Address: "Rajshahi,Bangladesh",
  },
  {
    code: "SPD",
    name: "Saidpur Airport",
    Address: "Saidpur,Bangladesh",
  },
  {
    code: "AAM",
    name: "Mala Mala Intl Airport",
    Address: "Mala Mala,SOUTH AFRICA",
  },

  {
    code: "CXB",
    name: "Cox's Bazar Airport",
    Address: "Cox's Bazar,Bangladesh",
  },
  {
    code: "AAH",
    name: "Aachen Merzbruck Intl Airport",
    Address: "Aachen,GERMANY",
  },
  {
    code: "AAF",
    name: "Apalachicola Municipal Intl Airport",
    Address: "Apalachicola,UNITED STATES",
  },
  {
    code: "AAE",
    name: "Les Salines Intl Airport",
    Address: "Annaba,ALGERIA",
  },
  {
    code: "JFK",
    name: "John F Kennedy Intl ",
    Address: "New York,UNITED STATES",
  },
  {
    code: "AAG",
    name: "Arapoti Intl Airport",
    Address: "Arapoti,BRAZIL",
  },
  {
    code: "AAL",
    name: "Aalborg Intl Airport",
    Address: "Aalborg,DENMARK",
  },
  {
    code: "AAN",
    name: "Al Ain Intl Airport",
    Address: "Al Ain,UNITED ARAB EMIRATES",
  },
  { code: "AAQ", name: "Anapa Intl Airport", Address: "Anapa,RUSSIA" },
  { code: "ZID", name: "Aarhus Bus Service", Address: "Aarhus,DENMARK" },
  {
    code: "AAR",
    name: "Tirstrup Intl Airport",
    Address: "Aarhus,DENMARK",
  },
  { code: "AAT", name: "Altay Intl Airport", Address: "Altay,CHINA" },
  {
    code: "AAY",
    name: "Al Ghaydah Intl Airport",
    Address: "Al Ghaydah,YEMEN",
  },
  { code: "ABA", name: "Abakan Intl Airport", Address: "Abakan,RUSSIA" },
  {
    code: "ABC",
    name: "Los Llanos Intl Airport",
    Address: "Albacete,SPAIN",
  },
  { code: "ABD", name: "Abadan Intl Airport", Address: "Abadan,IRAN" },
  {
    code: "ABE",
    name: "Lehigh Valley Intl Airport",
    Address: "Allentown,UNITED STATES",
  },
  {
    code: "ABI",
    name: "Abilene Municipal Intl Airport",
    Address: "Abilene,UNITED STATES",
  },
  {
    code: "ABL",
    name: "Ambler Intl Airport",
    Address: "Ambler,UNITED STATES",
  },
  {
    code: "ABM",
    name: "Bamaga Intl Airport",
    Address: "Bamaga,AUSTRALIA",
  },
  {
    code: "ABQ",
    name: "Albuquerque Intl Airport",
    Address: "Albuquerque,UNITED STATES",
  },
  {
    code: "ABR",
    name: "Aberdeen Intl Airport",
    Address: "Aberdeen,UNITED STATES",
  },
  {
    code: "ABS",
    name: "Abu Simbel Intl Airport",
    Address: "Abu Simbel,EGYPT",
  },
  {
    code: "ABT",
    name: "Al Aqiq Airport",
    Address: "Al Baha,SAUDI ARABIA",
  },
  { code: "ABV", name: "Abuja Intl ", Address: "Abuja,NIGERIA" },
  {
    code: "ABX",
    name: "Albury Intl Airport",
    Address: "Albury,AUSTRALIA",
  },
  {
    code: "ABY",
    name: "Dougherty Cty Intl Airport",
    Address: "Albany,UNITED STATES",
  },
  {
    code: "ZXA",
    name: "Aberdeen ScotRail Station",
    Address: "Aberdeen,UNITED KINGDOM",
  },
  {
    code: "ABZ",
    name: "Dyce Intl Airport",
    Address: "Aberdeen,UNITED KINGDOM",
  },
  {
    code: "ACA",
    name: "Alvarez Intl Airport",
    Address: "Acapulco,MEXICO",
  },
  { code: "ACC", name: "Kotoka Intl Airport", Address: "Accra,GHANA" },
  {
    code: "ACE",
    name: "Lanzarote Intl Airport",
    Address: "Lanzarote,SPAIN",
  },
  {
    code: "ACH",
    name: "Altenrhein Intl Airport",
    Address: "Altenrhein,SWITZERLAND",
  },
  {
    code: "ACI",
    name: "The Blaye Intl Airport",
    Address: "Alderney,UNITED KINGDOM",
  },
  {
    code: "ACK",
    name: "Nantucket Memorial",
    Address: "Nantucket,UNITED STATES",
  },
  {
    code: "ACO",
    name: "Ascona Intl Airport",
    Address: "Ascona,SWITZERLAND",
  },
  {
    code: "ACS",
    name: "Achinsk Intl Airport",
    Address: "Achinsk Intl Airport,RUSSIA",
  },
  {
    code: "ACT",
    name: "Madison Cooper Intl Airport",
    Address: "Waco,UNITED STATES",
  },
  {
    code: "ACV",
    name: "Arcata Eureka Intl Airport",
    Address: "Eureka,UNITED STATES",
  },
  { code: "ACX", name: "Xingyi Intl Airport", Address: "Xingyi,CHINA" },
  { code: "ADA", name: "Adana Intl Airport", Address: "Adana,TURKEY" },
  {
    code: "ADD",
    name: "Bole Intl Airport",
    Address: "Addis Ababa,ETHIOPIA",
  },
  { code: "ADE", name: "Yemen Intl Airport", Address: "Aden,YEMEN" },
  {
    code: "ADF",
    name: "Adiyaman Intl Airport",
    Address: "Adiyaman,TURKEY",
  },
  {
    code: "ADG",
    name: "Lenawee County Intl Airport",
    Address: "Adrian,UNITED STATES",
  },
  {
    code: "ADH",
    name: "Aldan Intl Airport",
    Address: "Aldan Intl Airport,RUSSIA",
  },
  {
    code: "ADK",
    name: "Adak Island Ns",
    Address: "Adak Island,UNITED STATES",
  },
  {
    code: "ADL",
    name: "Adelaide Intl Airport",
    Address: "Adelaide,AUSTRALIA",
  },
  {
    code: "ADM",
    name: "Ardmore Municipal Intl Airport",
    Address: "Ardmore,UNITED STATES",
  },
  {
    code: "KDK",
    name: "Kodiak Municipal",
    Address: "Kodiak,UNITED STATES",
  },
  {
    code: "ADQ",
    name: "Kodiak Intl Airport",
    Address: "Kodiak,UNITED STATES",
  },
  {
    code: "ADT",
    name: "Ada Municipal Intl Airport",
    Address: "Ada OK,UNITED STATES",
  },
  { code: "ADU", name: "Ardabil Intl Airport", Address: "Ardabil,IRAN" },
  {
    code: "ADW",
    name: "Andrews Air Force Base",
    Address: "Camp Springs,UNITED STATES",
  },
  {
    code: "ADX",
    name: "Leuchars Intl Airport",
    Address: "St Andrews,UNITED KINGDOM",
  },
  {
    code: "ADZ",
    name: "San Andres Island Intl Airport",
    Address: "San Andres,COLOMBIA",
  },
  {
    code: "AEI",
    name: "Algeciras Intl Airport",
    Address: "Algeciras,SPAIN",
  },
  {
    code: "AEL",
    name: "Albert Lea Intl Airport",
    Address: "Albert Lea,UNITED STATES",
  },
  {
    code: "AER",
    name: "Alder Sochi Intl Airport",
    Address: "Adler Sochi,RUSSIA",
  },
  { code: "AES", name: "Vigra Intl Airport", Address: "Aalesund,NORWAY" },
  {
    code: "AEX",
    name: "Alexandria Intl Airport",
    Address: "Alexandria,UNITED STATES",
  },
  {
    code: "ESF",
    name: "Esler Field Airport",
    Address: "Alexandria,UNITED STATES",
  },
  { code: "AEY", name: "Akureyri Airport", Address: "Akureyri,ICELAND" },
  {
    code: "AFA",
    name: "San Rafael Intl Airport",
    Address: "San Rafael,ARGENTINA",
  },
  {
    code: "AFK",
    name: "Kondavattavan Tank Intl Airport",
    Address: "Ampara,SRI LANKA",
  },
  {
    code: "AFL",
    name: "Alta Floresta Intl Airport",
    Address: "Alta Floresta,BRAZIL",
  },
  {
    code: "AFY",
    name: "Afyon Intl Airport",
    Address: "Afyon Intl Airport,TURKEY",
  },
  {
    code: "AGA",
    name: "Agadir Almassira Intl Airport",
    Address: "Agadir,MOROCCO",
  },
  {
    code: "AGB",
    name: "Muehlhausen Intl Airport",
    Address: "Augsburg,GERMANY",
  },
  {
    code: "AGE",
    name: "Flugplatz Intl Airport",
    Address: "Wangerooge,GERMANY",
  },
  {
    code: "AGF",
    name: "La Garenne Intl Airport",
    Address: "Agen,FRANCE",
  },
  {
    code: "AGH",
    name: "Angelholm Helsingborg Intl Airport",
    Address: "Angelholm,SWEDEN",
  },
  {
    code: "AGN",
    name: "Angoon Intl Airport",
    Address: "Angoon,UNITED STATES",
  },
  {
    code: "AGO",
    name: "Magnolia Municipal",
    Address: "Magnolia,UNITED STATES",
  },
  { code: "AGP", name: "Malaga Intl Airport", Address: "Malaga,SPAIN" },
  {
    code: "AGQ",
    name: "Agrinion Intl Airport",
    Address: "Agrinion,GREECE",
  },
  { code: "AGR", name: "Kheria Intl Airport", Address: "Agra,INDIA" },
  {
    code: "DNL",
    name: "Daniel Intl Airport",
    Address: "Augusta,UNITED STATES",
  },
  { code: "AGS", name: "Bush Field", Address: "Augusta,UNITED STATES" },
  {
    code: "AGT",
    name: "Alejo Garcia Intl Airport",
    Address: "Ciudad Del Este,PARAGUAY",
  },
  {
    code: "AGU",
    name: "Aguascalientes Municipal Intl Airport",
    Address: "Aguascalientes,MEXICO",
  },
  {
    code: "AHB",
    name: "Abha Intl Airport",
    Address: "Abha,SAUDI ARABIA",
  },
  {
    code: "AHI",
    name: "Amahai Intl Airport",
    Address: "Amahai,INDONESIA",
  },
  {
    code: "AHN",
    name: "Athens Municipal Airport",
    Address: "Athens,UNITED STATES",
  },
  {
    code: "AHO",
    name: "Fertilia Intl Airport",
    Address: "Alghero,ITALY",
  },
  {
    code: "AHU",
    name: "Charif Al Idrissi Intl Airport",
    Address: "Al Hoceima,MOROCCO",
  },
  {
    code: "AIA",
    name: "Alliance Municipal Airport",
    Address: "Alliance,UNITED STATES",
  },
  {
    code: "AID",
    name: "Anderson Municipal Intl Airport",
    Address: "Anderson,UNITED STATES",
  },
  {
    code: "AIK",
    name: "Aiken Municipal Intl Airport",
    Address: "Aiken,UNITED STATES",
  },
  {
    code: "AIN",
    name: "Wainwright Municipal Intl Airport",
    Address: "Wainwright,UNITED STATES",
  },
  {
    code: "AIT",
    name: "Aitutaki Intl Airport",
    Address: "Aitutaki,COOK ISLANDS",
  },
  {
    code: "AIY",
    name: "Bader Field Airport",
    Address: "Atlantic City,UNITED STATES",
  },
  {
    code: "ACY",
    name: "Pomona Field Airport",
    Address: "Atlantic City,UNITED STATES",
  },
  {
    code: "AIZ",
    name: "Lee C Fine Memorial Airport",
    Address: "Lake Of The Ozarks,UNITED STATES",
  },
  {
    code: "AJA",
    name: "Campo Dell Oro Intl Airport",
    Address: "Ajaccio,FRANCE",
  },
  { code: "AJF", name: "JOUF Airport", Address: "Al Jouf,SAUDI ARABIA" },
  { code: "AJI", name: "Agri Intl Airport", Address: "Agri,TURKEY" },
  {
    code: "AJR",
    name: "Arvidsjaur Intl Airport",
    Address: "Arvidsjaur,SWEDEN",
  },
  {
    code: "AJU",
    name: "Santa Maria Intl Airport",
    Address: "Aracaju,BRAZIL",
  },
  {
    code: "AKJ",
    name: "Asahikawa Intl Airport",
    Address: "Asahikawa,JAPAN",
  },
  {
    code: "AKL",
    name: "Auckland Intl Airport",
    Address: "Auckland,NEW ZEALAND",
  },
  {
    code: "AKN",
    name: "King Salmon Intl Airport",
    Address: "King Salmon,UNITED STATES",
  },
  { code: "AKU", name: "Aksu Intl Airport", Address: "Aksu,CHINA" },
  {
    code: "AKV",
    name: "Akulivik Intl Airport",
    Address: "Akulivik,CANADA",
  },
  {
    code: "AKX",
    name: "Aqtobe Intl Airport",
    Address: "Aqtobe,KAZAKHSTAN",
  },
  {
    code: "ALA",
    name: "Almaty Intl Airport",
    Address: "Almaty,KAZAKHSTAN",
  },
  {
    code: "ALB",
    name: "Albany Cty Intl Airport",
    Address: "Albany,UNITED STATES",
  },
  {
    code: "ALC",
    name: "Alicante Intl Airport",
    Address: "Alicante,SPAIN",
  },
  { code: "ALE", name: "Alpine Aprt", Address: "Alpine,UNITED STATES" },
  {
    code: "ALF",
    name: "Elvebakken Intl Airport",
    Address: "Alta,NORWAY",
  },
  {
    code: "ALG",
    name: "Houari Boumedienne Intl Airport",
    Address: "Algiers,ALGERIA",
  },
  {
    code: "ALH",
    name: "Albany Intl Airport",
    Address: "Albany,AUSTRALIA",
  },
  {
    code: "ALJ",
    name: "Kortdoorn Intl Airport",
    Address: "Alexander Bay,SOUTH AFRICA",
  },
  { code: "ALL", name: "Albenga Intl Airport", Address: "Albenga,ITALY" },
  {
    code: "ALM",
    name: "Alamogordo Municipal Airport",
    Address: "Alamogordo,UNITED STATES",
  },
  {
    code: "ALO",
    name: "Livingston Betsworth Fld Airport",
    Address: "Waterloo,UNITED STATES",
  },
  { code: "ALP", name: "Nejrab Intl Airport", Address: "Aleppo,SYRIA" },
  {
    code: "ALR",
    name: "Alexandra Intl Airport",
    Address: "Alexandra,NEW ZEALAND",
  },
  {
    code: "ALS",
    name: "Alamosa Municipal Airport",
    Address: "Alamosa,UNITED STATES",
  },
  {
    code: "ALW",
    name: "Walla Walla City County",
    Address: "Walla Walla,UNITED STATES",
  },
  {
    code: "ALX",
    name: "Thomas C Russell Field Airport",
    Address: "Alexander City,UNITED STATES",
  },
  {
    code: "ALY",
    name: "El Nouzha Intl Airport",
    Address: "Alexandria,EGYPT",
  },
  {
    code: "TDW",
    name: "Tradewind Intl Airport",
    Address: "Amarillo,UNITED STATES",
  },
  {
    code: "AMA",
    name: "Amarillo Intl Airport",
    Address: "Amarillo,UNITED STATES",
  },
  {
    code: "AMD",
    name: "Sardar Vallabh Bhai Patel Intl Airport",
    Address: "Ahmedabad,INDIA",
  },
  {
    code: "AMI",
    name: "Selaparang Intl Airport",
    Address: "Mataram,INDONESIA",
  },
  {
    code: "ADJ",
    name: "Civil Marka Intl Airport",
    Address: "Amman,JORDAN",
  },
  {
    code: "AMM",
    name: "Queen Alia Intl Airport",
    Address: "Amman,JORDAN",
  },
  {
    code: "AMQ",
    name: "Pattimura Intl Airport",
    Address: "Ambon,INDONESIA",
  },
  {
    code: "ZYA",
    name: "Amsterdam Central Rail Station",
    Address: "Amsterdam,NETHERLANDS",
  },
  {
    code: "AMS",
    name: "Schiphol Intl Airport",
    Address: "Amsterdam,NETHERLANDS",
  },
  {
    code: "AMV",
    name: "Amderma Intl Airport",
    Address: "Amderma Intl Airport,RUSSIA",
  },
  {
    code: "AMW",
    name: "Ames Minicipal Intl Airport",
    Address: "Ames,UNITED STATES",
  },
  {
    code: "ANA",
    name: "Anaheim Intl Airport",
    Address: "Anaheim,UNITED STATES",
  },
  {
    code: "ANB",
    name: "Anniston Municipal Intl Airport",
    Address: "Anniston,UNITED STATES",
  },
  {
    code: "ANC",
    name: "Anchorage Intl Airport",
    Address: "Anchorage,UNITED STATES",
  },
  {
    code: "AND",
    name: "Anderson Intl Airport",
    Address: "Anderson,UNITED STATES",
  },
  { code: "ANE", name: "Marce Intl Airport", Address: "Angers,FRANCE" },
  {
    code: "ANF",
    name: "Cerro Moreno Intl Airport",
    Address: "Antofagasta,CHILE",
  },
  { code: "ANG", name: "Brie Champniers", Address: "Angouleme,FRANCE" },
  {
    code: "ANI",
    name: "Aniak Intl Airport",
    Address: "Aniak,UNITED STATES",
  },
  {
    code: "ESB",
    name: "Esenboga Intl Airport",
    Address: "Ankara,TURKEY",
  },
  {
    code: "ANP",
    name: "Lee Annapolis Intl Airport",
    Address: "Annapolis,UNITED STATES",
  },
  {
    code: "ANR",
    name: "Antwerp Brussels North",
    Address: "Antwerp,BELGIUM",
  },
  {
    code: "ANU",
    name: "V C Bird Intl Airport",
    Address: "Antigua,ANTIGUA AND BARBUDA",
  },
  {
    code: "ANW",
    name: "Ainsworth Minicipal Intl Airport",
    Address: "Ainsworth,UNITED STATES",
  },
  {
    code: "ANX",
    name: "Andenes Intl Airport",
    Address: "Andenes,NORWAY",
  },
  {
    code: "AOC",
    name: "Altenburg Nobitz Intl Airport",
    Address: "Altenburg,GERMANY",
  },
  {
    code: "AOE",
    name: "Anadolu University Intl Airport",
    Address: "Eskisehir,TURKEY",
  },
  {
    code: "AOH",
    name: "Allen County Intl Airport",
    Address: "Lima,UNITED STATES",
  },
  {
    code: "AOI",
    name: "Falconara Intl Airport",
    Address: "Ancona,ITALY",
  },
  { code: "AOJ", name: "Aomori Intl Airport", Address: "Aomori,JAPAN" },
  {
    code: "AOK",
    name: "Karpathos Intl Airport",
    Address: "Karpathos,GREECE",
  },
  {
    code: "AOL",
    name: "Paso De Los Libres Intl Airport",
    Address: "Paso De Los Libres,ARGENTINA",
  },
  {
    code: "AOO",
    name: "Blair Cty Intl Airport",
    Address: "Altoona,UNITED STATES",
  },
  {
    code: "AOR",
    name: "Sultan Abdul Hamlin Intl Airport",
    Address: "Alor Setar,MALAYSIA",
  },
  {
    code: "AOT",
    name: "Corrado Gex Intl Airport",
    Address: "Aosta,ITALY",
  },
  {
    code: "APC",
    name: "Napa Cty Intl Airport",
    Address: "Napa,UNITED STATES",
  },
  {
    code: "APF",
    name: "Naples Municipal",
    Address: "Naples,UNITED STATES",
  },
  {
    code: "APL",
    name: "Nampula Intl Airport",
    Address: "Nampula,MOZAMBIQUE",
  },
  {
    code: "APN",
    name: "Phelps Collins Intl Airport",
    Address: "Alpena,UNITED STATES",
  },
  {
    code: "APO",
    name: "Apartado Intl Airport",
    Address: "Apartado,COLOMBIA",
  },
  {
    code: "APS",
    name: "Anapolis Intl Airport",
    Address: "Anapolis,BRAZIL",
  },
  { code: "APW", name: "Apia Intl Airport", Address: "Apia,SAMOA" },
  { code: "FGI", name: "Fagali Intl Airport", Address: "Apia,SAMOA" },
  {
    code: "AQA",
    name: "Araraquara Intl Airport",
    Address: "Araraquara,BRAZIL",
  },
  { code: "AQG", name: "Anqing Intl Airport", Address: "Anqing,CHINA" },
  {
    code: "AQI",
    name: "Qaisumah Intl Airport",
    Address: "Qaisumah,SAUDI ARABIA",
  },
  { code: "AQJ", name: "Aqaba Intl Airport", Address: "Aqaba,JORDAN" },
  {
    code: "AQP",
    name: "Rodriguez Ballon Intl Airport",
    Address: "Arequipa,PERU",
  },
  {
    code: "ARB",
    name: "Ann Arbor Municipal",
    Address: "Ann Arbor,UNITED STATES",
  },
  {
    code: "ARH",
    name: "Arkhangelsk Intl Airport",
    Address: "Arkhangelsk,RUSSIA",
  },
  {
    code: "ARI",
    name: "Chacalluta Intl Airport",
    Address: "Arica,CHILE",
  },
  {
    code: "ARK",
    name: "Arusha Intl Airport",
    Address: "Arusha,TANZANIA",
  },
  {
    code: "ARM",
    name: "Armidale Intl Airport",
    Address: "Armidale,AUSTRALIA",
  },
  {
    code: "ART",
    name: "Watertown Intl Airport",
    Address: "Watertown,UNITED STATES",
  },
  {
    code: "ARU",
    name: "Aracatuba Intl Airport",
    Address: "Aracatuba,BRAZIL",
  },
  {
    code: "ARV",
    name: "Noble F Lee Memorial Field",
    Address: "Minocqua,UNITED STATES",
  },
  { code: "ARW", name: "Arad Intl Airport", Address: "Arad,ROMANIA" },
  {
    code: "ARY",
    name: "Ararat Intl Airport",
    Address: "Ararat,AUSTRALIA",
  },
  {
    code: "ASB",
    name: "Ashgabat Intl Airport",
    Address: "Ashgabat,TURKMENISTAN",
  },
  {
    code: "ASD",
    name: "Andros Town Intl Airport",
    Address: "Andros Town,BAHAMAS",
  },
  {
    code: "ASE",
    name: "Pitkin Cty Intl Airport t Sardy Fld",
    Address: "Aspen,UNITED STATES",
  },
  {
    code: "ASF",
    name: "Astrakhan Intl Airport",
    Address: "Astrakhan,RUSSIA",
  },
  {
    code: "ASH",
    name: "Boire Field Intl Airport",
    Address: "Nashua,UNITED STATES",
  },
  {
    code: "ASJ",
    name: "Amami O Shima Intl Airport",
    Address: "Amami O Shima,JAPAN",
  },
  {
    code: "ASK",
    name: "Yamoussoukro Intl Airport",
    Address: "Yamoussouro,COTE D'IVOIRE",
  },
  {
    code: "ASL",
    name: "Harrison County Intl Airport",
    Address: "Marshall,UNITED STATES",
  },
  { code: "ASM", name: "Asmara Intl Airport", Address: "Asmara,ERITREA" },
  {
    code: "ASN",
    name: "Talladega Intl Airport",
    Address: "Talladega,UNITED STATES",
  },
  {
    code: "ASP",
    name: "Alice Springs Intl Airport",
    Address: "Alice Springs,AUSTRALIA",
  },
  {
    code: "ASR",
    name: "Kayseri Intl Airport",
    Address: "Kayseri,TURKEY",
  },
  {
    code: "AST",
    name: "Astoria Intl Airport",
    Address: "Astoria,UNITED STATES",
  },
  {
    code: "ASU",
    name: "Salvio Pettirosse Intl Airport",
    Address: "Asuncion,PARAGUAY",
  },
  { code: "ASW", name: "Daraw Intl Airport", Address: "Aswan,EGYPT" },
  {
    code: "ATC",
    name: "Arthurs Town Intl Airport",
    Address: "Arthurs Town,BAHAMAS",
  },
  {
    code: "ATH",
    name: "Eleftherios Venizelos Intl Airport",
    Address: "Athens,GREECE",
  },
  {
    code: "HEW",
    name: "Athens Hellinikon Intl Airport",
    Address: "Athens,GREECE",
  },
  {
    code: "ATI",
    name: "Artigas Intl Airport",
    Address: "Artigas,URUGUAY",
  },
  {
    code: "PDK",
    name: "Dekalb Peachtree",
    Address: "Atlanta,UNITED STATES",
  },
  {
    code: "FTY",
    name: "Fulton Cty Intl Airport",
    Address: "Atlanta,UNITED STATES",
  },
  {
    code: "ATL",
    name: "Hartsfield Jackson Intl Airport",
    Address: "Atlanta,UNITED STATES",
  },
  {
    code: "ATM",
    name: "Altamira Intl Airport",
    Address: "Altamira,BRAZIL",
  },
  {
    code: "ATO",
    name: "Ohio University Intl Airport",
    Address: "Athens,UNITED STATES",
  },
  {
    code: "ATQ",
    name: "Raja Sansi Intl Airport",
    Address: "Amritsar,INDIA",
  },
  {
    code: "ATR",
    name: "Mouakchott Intl Airport",
    Address: "Atar,MAURITANIA",
  },
  {
    code: "ATW",
    name: "Outagamie Cty Intl Airport",
    Address: "Appleton,UNITED STATES",
  },
  {
    code: "ATY",
    name: "Watertown Municipal",
    Address: "Watertown,UNITED STATES",
  },
  {
    code: "AUA",
    name: "Reina Beatrix Intl Airport",
    Address: "Aruba,ARUBA",
  },
  {
    code: "AUG",
    name: "Maine State Intl Airport",
    Address: "Augusta,UNITED STATES",
  },
  {
    code: "ZVJ",
    name: "Abu Dhabi-bus Station",
    Address: "Abu Dhabi,UNITED ARAB EMIRATES",
  },
  {
    code: "AUH",
    name: "Dhabi Intl Airport",
    Address: "Abu Dhabi,UNITED ARAB EMIRATES",
  },
  {
    code: "AUO",
    name: "Auburn Opelika",
    Address: "Auburn,UNITED STATES",
  },
  {
    code: "AUQ",
    name: "Atuona Intl Airport",
    Address: "Atuona,FRENCH POLYNESIA",
  },
  {
    code: "AUR",
    name: "Tronquieres Intl Airport",
    Address: "Aurillac,FRANCE",
  },
  {
    code: "AUS",
    name: "Bergstrom Intl Airport",
    Address: "Austin,UNITED STATES",
  },
  {
    code: "AUU",
    name: "Aurukin Intl Airport",
    Address: "Aurukun Mission,AUSTRALIA",
  },
  {
    code: "AUW",
    name: "Wausau Municipal Intl Airport",
    Address: "Wausau,UNITED STATES",
  },
  {
    code: "CWA",
    name: "Central Wisconsin Intl Airport",
    Address: "Wausau,UNITED STATES",
  },
  {
    code: "AUX",
    name: "Araguaina Intl Airport",
    Address: "Araguaina,BRAZIL",
  },
  {
    code: "AUZ",
    name: "Aurora Municipal Intl Airport",
    Address: "Aurora,UNITED STATES",
  },
  {
    code: "AVI",
    name: "Maximo Gomez Intl Airport",
    Address: "Ciego De Avila,CUBA",
  },
  {
    code: "AVL",
    name: "Asheville Municipal Intl Airport",
    Address: "Asheville,UNITED STATES",
  },
  {
    code: "XZN",
    name: "Avignon Railway Station",
    Address: "Avignon,FRANCE",
  },
  {
    code: "AVN",
    name: "Avignon Caumont Intl Airport",
    Address: "Avignon,FRANCE",
  },
  {
    code: "AVP",
    name: "Wilkes Barre Scranton Intl Airport",
    Address: "Scranton,UNITED STATES",
  },
  {
    code: "SXC",
    name: "Catalina Intl Airport",
    Address: "Catalina Island,UNITED STATES",
  },
  {
    code: "AXA",
    name: "Wallblake Municipal",
    Address: "Anguilla,ANGUILLA",
  },
  {
    code: "AXD",
    name: "Dhmokritos Intl Airport",
    Address: "Alexandroupolis,GREECE",
  },
  {
    code: "AXM",
    name: "El Eden Intl Airport",
    Address: "Armenia,COLOMBIA",
  },
  { code: "AXT", name: "Akita Intl Airport", Address: "Akita,JAPAN" },
  {
    code: "AXV",
    name: "Neil Armstrong Intl Airport",
    Address: "Wapakoneta,UNITED STATES",
  },
  {
    code: "AYP",
    name: "Yanamilla Intl Airport",
    Address: "Ayacucho,PERU",
  },
  {
    code: "AYQ",
    name: "Connellan Intl Airport",
    Address: "Ayers Rock,AUSTRALIA",
  },
  { code: "AYR", name: "Ayr Intl Airport", Address: "Ayr Au,AUSTRALIA" },
  {
    code: "AYS",
    name: "Ware County Intl Airport",
    Address: "Waycross,UNITED STATES",
  },
  {
    code: "AYT",
    name: "Antalya Intl Airport",
    Address: "Antalya,TURKEY",
  },
  { code: "AZD", name: "Yazd Intl Airport", Address: "Yazd,IRAN" },
  {
    code: "AZG",
    name: "Apatzingan Intl Airport",
    Address: "Apatzingan,MEXICO",
  },
  { code: "AZN", name: "Andizhan", Address: "Andizhan,UZBEKISTAN" },
  {
    code: "AZO",
    name: "Kalamazoo Cty Intl Airport",
    Address: "Kalamazoo,UNITED STATES",
  },
  {
    code: "AZS",
    name: "Samana Intl Airport",
    Address: "Samana,DOMINICAN REPUBLIC",
  },
  {
    code: "BAG",
    name: "Loakan Intl Airport",
    Address: "Baguio,PHILIPPINES",
  },
  {
    code: "BAH",
    name: "Bahrain Intl Airport",
    Address: "Muharraq,BAHRAIN",
  },
  {
    code: "GYD",
    name: "Heydar Aliyev Intl Airport",
    Address: "Baku,AZERBAIJAN",
  },
  {
    code: "ZXT",
    name: "Zabrat Intl Airport",
    Address: "Baku,AZERBAIJAN",
  },
  { code: "BAK", name: "Baku Intl Airport", Address: "Baku,AZERBAIJAN" },
  { code: "BAL", name: "Batman Intl Airport", Address: "Batman,TURKEY" },
  {
    code: "BAO",
    name: "Udorn Intl Airport",
    Address: "Ban Mak Khaen,THAILAND",
  },
  {
    code: "BAQ",
    name: "E Cortissoz Intl Airport",
    Address: "Barranquilla,COLOMBIA",
  },
  {
    code: "BAS",
    name: "Balalae Intl Airport",
    Address: "Balalae,SOLOMON ISLANDS",
  },
  {
    code: "BAT",
    name: "Barretos Intl Airport",
    Address: "Barretos,BRAZIL",
  },
  { code: "BAU", name: "Bauru Intl Airport", Address: "Bauru,BRAZIL" },
  { code: "BAV", name: "Baotou Intl Airport", Address: "Baotou,CHINA" },
  {
    code: "BAX",
    name: "Barnaul Intl Airport",
    Address: "Barnaul,RUSSIA",
  },
  {
    code: "BAY",
    name: "Baia Mare Intl Airport",
    Address: "Baia Mare,ROMANIA",
  },
  {
    code: "BBA",
    name: "Teniente Vidal Intl Airport",
    Address: "Balmaceda,CHILE",
  },
  { code: "BBH", name: "Barth Intl Airport", Address: "Barth,GERMANY" },
  {
    code: "BBI",
    name: "Biju Patnaik Intl Airport",
    Address: "Bhubaneswar,INDIA",
  },
  { code: "BBJ", name: "Bitburg Air Base", Address: "Bitburg,GERMANY" },
  {
    code: "BBK",
    name: "Kasane Intl Airport",
    Address: "Kasane,BOTSWANA",
  },
  { code: "BBN", name: "Bario Intl Airport", Address: "Bario,MALAYSIA" },
  {
    code: "BBR",
    name: "Baillif Intl Airport",
    Address: "Basse Terre,GUADELOUPE",
  },
  {
    code: "BBW",
    name: "Broken Bow Municipal",
    Address: "Broken Bow,UNITED STATES",
  },
  {
    code: "BCB",
    name: "Virginia Tech Intl Airport",
    Address: "Blacksburg,UNITED STATES",
  },
  {
    code: "BCD",
    name: "Bacolod Intl Airport",
    Address: "Bacolod,PHILIPPINES",
  },
  {
    code: "BCE",
    name: "Bryce Intl Airport",
    Address: "Bryce,UNITED STATES",
  },
  {
    code: "BCH",
    name: "English Madeira Intl Airport",
    Address: "Baucau,TIMOR-LESTE",
  },
  {
    code: "BCI",
    name: "Barcaldine Intl Airport",
    Address: "Barcaldine,AUSTRALIA",
  },
  { code: "BCM", name: "Bacau Intl Airport", Address: "Bacau,ROMANIA" },
  {
    code: "BCN",
    name: "Barcelona Intl Airport",
    Address: "Barcelona,SPAIN",
  },
  { code: "BDA", name: "Bermuda Intl ", Address: "Bermuda,BERMUDA" },
  {
    code: "BDB",
    name: "Bundaberg Intl Airport",
    Address: "Bundaberg,AUSTRALIA",
  },
  {
    code: "BDD",
    name: "Badu Island Intl Airport",
    Address: "Badu Island,AUSTRALIA",
  },
  {
    code: "BDJ",
    name: "Sjamsudin Noor Intl Airport",
    Address: "Banjarmasin,INDONESIA",
  },
  {
    code: "BDM",
    name: "Bandirma Intl Airport",
    Address: "Bandirma Intl Airport,TURKEY",
  },
  {
    code: "BDO",
    name: "Husein Sastranegara Intl Airport",
    Address: "Bandung,INDONESIA",
  },
  {
    code: "BDQ",
    name: "Vadodara Intl Airport",
    Address: "Vadodara,INDIA",
  },
  {
    code: "BDR",
    name: "Sikorsky Memorial Intl Airport",
    Address: "Bridgeport,UNITED STATES",
  },
  {
    code: "BDS",
    name: "Papola Casale Intl Airport",
    Address: "Brindisi,ITALY",
  },
  {
    code: "BDU",
    name: "Bardufoss Intl Airport",
    Address: "Bardufoss,NORWAY",
  },
  {
    code: "BEB",
    name: "Benbecula Intl Airport",
    Address: "Benbecula,UNITED KINGDOM",
  },
  {
    code: "BED",
    name: "Bedford Intl Airport",
    Address: "Bedford,UNITED STATES",
  },
  {
    code: "BJY",
    name: "Batajnica Intl Airport",
    Address: "Belgrade,SERBIA",
  },
  {
    code: "BEG",
    name: "Belgrade Beograd Intl Airport",
    Address: "Belgrade,SERBIA",
  },
  {
    code: "BEH",
    name: "Ross Field",
    Address: "Benton Harbor,UNITED STATES",
  },
  {
    code: "BEL",
    name: "Val De Cans Intl Airport",
    Address: "Belem,BRAZIL",
  },
  {
    code: "BEN",
    name: "Benina Intl Airport",
    Address: "Benghazi,LIBYAN ARAB JAMAHIRIYA",
  },
  {
    code: "BEQ",
    name: "Honington Intl Airport",
    Address: "Bury St Edmunds,UNITED KINGDOM",
  },
  { code: "BML", name: "Berlin Intl Airport", Address: "Berlin,GERMANY" },
  {
    code: "SXF",
    name: "Schoenefeld Intl Airport",
    Address: "Berlin,GERMANY",
  },
  { code: "TXL", name: "Tegel Intl Airport", Address: "Berlin,GERMANY" },
  { code: "BES", name: "Guipavas Intl Airport", Address: "Brest,FRANCE" },
  {
    code: "BET",
    name: "Bethel Intl Airport",
    Address: "Bethel,UNITED STATES",
  },
  {
    code: "BEU",
    name: "Bedourie Intl Airport",
    Address: "Bedourie,AUSTRALIA",
  },
  {
    code: "BEW",
    name: "Beira Intl Airport",
    Address: "Beira,MOZAMBIQUE",
  },
  { code: "BEY", name: "Beirut Intl Airport", Address: "Beirut,LEBANON" },
  { code: "BEZ", name: "Beru Intl Airport", Address: "Beru,KIRIBATI" },
  {
    code: "BFD",
    name: "Bradford Regional Intl Airport",
    Address: "Bradford,UNITED STATES",
  },
  {
    code: "BFE",
    name: "Bielefeld Intl Airport",
    Address: "Bielefeld,GERMANY",
  },
  {
    code: "BFF",
    name: "Scottsbluff Municipal",
    Address: "Scottsbluff,UNITED STATES",
  },
  {
    code: "BFG",
    name: "Bullfrog Basin Intl Airport",
    Address: "Bullfrog Basin,UNITED STATES",
  },
  { code: "BFJ", name: "Ba Intl Airport", Address: "Ba City,FIJI" },
  {
    code: "BFL",
    name: "Meadows Field",
    Address: "Bakersfield,UNITED STATES",
  },
  {
    code: "BFN",
    name: "Bloemfontein Intl ",
    Address: "Bloemfontein,SOUTH AFRICA",
  },
  {
    code: "BFP",
    name: "Beaver Falls Intl Airport",
    Address: "Beaver Falls,UNITED STATES",
  },
  {
    code: "BFR",
    name: "Virgil I Grissom Municipal",
    Address: "Bedford,UNITED STATES",
  },
  {
    code: "BFS",
    name: "Belfast Intl Airport",
    Address: "Belfast,UNITED KINGDOM",
  },
  {
    code: "BHD",
    name: "Belfast City Intl Airport",
    Address: "Belfast,UNITED KINGDOM",
  },
  {
    code: "BFT",
    name: "Beaufort County Intl Airport",
    Address: "Beaufort,UNITED STATES",
  },
  {
    code: "BFV",
    name: "Buri Ram Intl Airport",
    Address: "Buri Ram,THAILAND",
  },
  {
    code: "BGA",
    name: "Palo Negro Intl Airport",
    Address: "Bucaramanga,COLOMBIA",
  },
  {
    code: "BGC",
    name: "Braganca Intl Airport",
    Address: "Braganca,PORTUGAL",
  },
  {
    code: "BGF",
    name: "Bangui Intl Airport",
    Address: "Bangui,CENTRAL AFRICAN REPUBLIC",
  },
  {
    code: "BGI",
    name: "Grantley Adams Intl Airport",
    Address: "Bridgetown,BARBADOS",
  },
  {
    code: "BGM",
    name: "Edwin A Link Field",
    Address: "Binghamton,UNITED STATES",
  },
  { code: "XKN", name: "Finse Rail Station", Address: "Bergen,NORWAY" },
  {
    code: "BGO",
    name: "Flesland Intl Airport",
    Address: "Bergen,NORWAY",
  },
  { code: "XVK", name: "Voss Rail Station", Address: "Bergen,NORWAY" },
  {
    code: "BGR",
    name: "Bangor Intl Airport",
    Address: "Bangor,UNITED STATES",
  },
  {
    code: "BGV",
    name: "Bento Goncalves Intl Airport",
    Address: "Bento Goncalves,BRAZIL",
  },
  { code: "BGW", name: "Baghdad Intl Airport", Address: "Baghdad,IRAQ" },
  {
    code: "BGY",
    name: "Orio Al Serio Intl Airport",
    Address: "Bergamo,ITALY",
  },
  {
    code: "BHB",
    name: "Hancock County",
    Address: "Bar Harbor,UNITED STATES",
  },
  {
    code: "BHE",
    name: "Woodbourne Intl Airport",
    Address: "Blenheim,NEW ZEALAND",
  },
  {
    code: "BHI",
    name: "Commandante Intl Airport",
    Address: "Bahia Blanca,ARGENTINA",
  },
  { code: "BHJ", name: "Rudra Mata Intl Airport", Address: "Bhuj,INDIA" },
  {
    code: "BHK",
    name: "Bukhara Intl Airport",
    Address: "Bukhara,UZBEKISTAN",
  },
  {
    code: "BHL",
    name: "Bahia De Los Angeles Intl Airport",
    Address: "Bahia De Los Angeles,MEXICO",
  },
  {
    code: "BHM",
    name: "Birmingham Intl Airport",
    Address: "Birmingham,UNITED STATES",
  },
  {
    code: "BHO",
    name: "Raja Bhoj Intl Airport",
    Address: "Bhopal,INDIA",
  },
  {
    code: "BHQ",
    name: "Broken Hill Intl Airport",
    Address: "Broken Hill,AUSTRALIA",
  },
  {
    code: "BHS",
    name: "Raglan Intl Airport",
    Address: "Bathurst,AUSTRALIA",
  },
  {
    code: "BHU",
    name: "Bhavnagar Intl Airport",
    Address: "Bhavnagar,INDIA",
  },
  {
    code: "BHV",
    name: "Bahawalpur Intl Airport",
    Address: "Bahawalpur,PAKISTAN",
  },
  {
    code: "XVB",
    name: "Stafford Rail Station",
    Address: "Birmingham,UNITED KINGDOM",
  },
  {
    code: "XNV",
    name: "Nuneaton Rail Station",
    Address: "Birmingham,UNITED KINGDOM",
  },
  {
    code: "QQN",
    name: "Birmingham - New Street Rail Service",
    Address: "Birmingham,UNITED KINGDOM",
  },
  {
    code: "BHX",
    name: "Birmingham Intl Airport",
    Address: "Birmingham,UNITED KINGDOM",
  },
  { code: "BHY", name: "Beihai Intl Airport", Address: "Beihai,CHINA" },
  {
    code: "PLU",
    name: "Pampulha Intl Airport",
    Address: "Belo Horizonte,BRAZIL",
  },
  {
    code: "CNF",
    name: "Tancredo Neves Intl Airport",
    Address: "Belo Horizonte,BRAZIL",
  },
  { code: "BIA", name: "Poretta Intl Airport", Address: "Bastia,FRANCE" },
  {
    code: "BID",
    name: "Block Island Municipal",
    Address: "Block Island,UNITED STATES",
  },
  {
    code: "BIH",
    name: "Bishop Intl Airport",
    Address: "Bishop,UNITED STATES",
  },
  {
    code: "BII",
    name: "Enyu Airfield",
    Address: "Bikini Atoll,MARSHALL ISLANDS",
  },
  { code: "BIK", name: "Mokmer Intl Airport", Address: "Biak,INDONESIA" },
  { code: "BIL", name: "Logan Field", Address: "Billings,UNITED STATES" },
  { code: "BIM", name: "Bimini Intl Airport", Address: "Bimini,BAHAMAS" },
  { code: "NSB", name: "North Seaplane Base", Address: "Bimini,BAHAMAS" },
  { code: "BIO", name: "Bilbao Intl Airport", Address: "Bilbao,SPAIN" },
  {
    code: "BIQ",
    name: "Bayonne Anglet Intl Airport",
    Address: "Biarritz,FRANCE",
  },
  {
    code: "BIS",
    name: "Bismarck Municipal Intl Airport",
    Address: "Bismarck,UNITED STATES",
  },
  {
    code: "BIY",
    name: "Bisho Intl Airport",
    Address: "Bisho,SOUTH AFRICA",
  },
  { code: "BJA", name: "Bejaia Intl Airport", Address: "Bejaia,ALGERIA" },
  {
    code: "BJC",
    name: "Jeffco Intl Airport",
    Address: "Broomfield,UNITED STATES",
  },
  {
    code: "BJF",
    name: "Batsfjord Intl Airport",
    Address: "Batsfjord,NORWAY",
  },
  {
    code: "BJI",
    name: "Bemidji Municipal Intl Airport",
    Address: "Bemidji,UNITED STATES",
  },
  { code: "BJL", name: "Yundum Intl Airport", Address: "Banjul,GAMBIA" },
  {
    code: "BJM",
    name: "Bujumbura Intl Airport",
    Address: "Bujumbura,BURUNDI",
  },
  {
    code: "BJR",
    name: "Bahar Dar Intl Airport",
    Address: "Bahar Dar,ETHIOPIA",
  },
  { code: "NAY", name: "Nanyuan Intl Airport", Address: "Beijing,CHINA" },
  {
    code: "PEK",
    name: "Beijing Capital Intl Airport",
    Address: "Beijing,CHINA",
  },
  { code: "BJX", name: "Del Bajio Intl Airport", Address: "Leon,MEXICO" },
  {
    code: "BJZ",
    name: "Talaveral La Real Intl Airport",
    Address: "Badajoz,SPAIN",
  },
  { code: "BKB", name: "Bikaner Intl Airport", Address: "Bikaner,INDIA" },
  {
    code: "BKG",
    name: "Branson Regional Intl Airport",
    Address: "Branson,UNITED STATES",
  },
  {
    code: "BKI",
    name: "Kota Kinabalu Intl Airport",
    Address: "Kota Kinabalu,MALAYSIA",
  },
  {
    code: "DMK",
    name: "Don Mueang Intl Airport",
    Address: "Bangkok,THAILAND",
  },
  {
    code: "BKK",
    name: "Suvarnabhumi Intl Airport",
    Address: "Bangkok,THAILAND",
  },
  {
    code: "BKM",
    name: "Bakalalan Intl Airport",
    Address: "Bakalalan,MALAYSIA",
  },
  { code: "BKO", name: "Senou Intl Airport", Address: "Bamako,MALI" },
  {
    code: "BKQ",
    name: "Blackall Intl Airport",
    Address: "Blackall,AUSTRALIA",
  },
  {
    code: "BKS",
    name: "Padangkemiling Intl Airport",
    Address: "Bengkulu,INDONESIA",
  },
  {
    code: "BKW",
    name: "Raleigh Cty Memorial",
    Address: "Beckley,UNITED STATES",
  },
  {
    code: "BKX",
    name: "Brookings Municipal",
    Address: "Brookings,UNITED STATES",
  },
  {
    code: "BLA",
    name: "Gen J A Anzoategui Intl Airport",
    Address: "Barcelona,VENEZUELA",
  },
  {
    code: "XXO",
    name: "Leksand Rail Station",
    Address: "Borlange,SWEDEN",
  },
  {
    code: "XXU",
    name: "Hedemora Rail Station",
    Address: "Borlange,SWEDEN",
  },
  {
    code: "XYB",
    name: "Borlange Railway Service",
    Address: "Borlange,SWEDEN",
  },
  { code: "BLE", name: "Dala Intl Airport", Address: "Borlange,SWEDEN" },
  {
    code: "BLF",
    name: "Mercer County",
    Address: "Bluefield,UNITED STATES",
  },
  {
    code: "BLG",
    name: "Belaga Intl Airport",
    Address: "Belaga,MALAYSIA",
  },
  {
    code: "BLH",
    name: "Blythe Intl Airport",
    Address: "Blythe,UNITED STATES",
  },
  {
    code: "BLI",
    name: "Bellingham Intl Airport",
    Address: "Bellingham,UNITED STATES",
  },
  { code: "BLJ", name: "Batna Intl Airport", Address: "Batna,ALGERIA" },
  {
    code: "XQL",
    name: "Lancaster Rail Station",
    Address: "Blackpool,UNITED KINGDOM",
  },
  {
    code: "BLK",
    name: "Blackpool Intl Airport",
    Address: "Blackpool,UNITED KINGDOM",
  },
  {
    code: "BLL",
    name: "Billund Intl Airport",
    Address: "Billund,DENMARK",
  },
  { code: "BLQ", name: "Guglielmo Marconi", Address: "Bologna,ITALY" },
  {
    code: "BLR",
    name: "Bengaluru Intl Airport",
    Address: "Bangalore,INDIA",
  },
  {
    code: "BLT",
    name: "Blackwater Intl Airport",
    Address: "Blackwater,AUSTRALIA",
  },
  {
    code: "BLV",
    name: "Scott AFB Mid America",
    Address: "Belleville,UNITED STATES",
  },
  { code: "BLX", name: "Belluno Intl Airport", Address: "Belluno,ITALY" },
  {
    code: "BLZ",
    name: "Chileka Intl Airport",
    Address: "Blantyre,MALAWI",
  },
  {
    code: "BME",
    name: "Broome Intl Airport",
    Address: "Broome,AUSTRALIA",
  },
  {
    code: "BMG",
    name: "Monroe City Intl Airport",
    Address: "Bloomington,UNITED STATES",
  },
  {
    code: "BMI",
    name: "Normal Intl Airport",
    Address: "Bloomington,UNITED STATES",
  },
  {
    code: "BMP",
    name: "Brampton Island Intl Airport",
    Address: "Brampton Island,AUSTRALIA",
  },
  {
    code: "BMV",
    name: "Phung Duc Intl Airport",
    Address: "Banmethuot,VIET NAM",
  },
  {
    code: "BNA",
    name: "Nashville Intl Airport",
    Address: "Nashville,UNITED STATES",
  },
  {
    code: "BND",
    name: "Bandar Abbas Intl Airport",
    Address: "Bandar Abbas,IRAN",
  },
  {
    code: "BNE",
    name: "Brisbane Intl Airport",
    Address: "Brisbane,AUSTRALIA",
  },
  { code: "BNJ", name: "Bonn Railroad Station", Address: "Bonn,GERMANY" },
  {
    code: "BNK",
    name: "Ballina Intl Airport",
    Address: "Ballina,AUSTRALIA",
  },
  {
    code: "BNN",
    name: "Bronnoy Intl Airport",
    Address: "Bronnoysund,NORWAY",
  },
  {
    code: "BNO",
    name: "Burns Municipal Intl Airport",
    Address: "Burns,UNITED STATES",
  },
  {
    code: "BNU",
    name: "Blumenau Intl Airport",
    Address: "Blumenau,BRAZIL",
  },
  {
    code: "BNX",
    name: "Banja Luka Intl Airport",
    Address: "Banja Luka,BOSNIA AND HERZEGOVINA",
  },
  {
    code: "BOB",
    name: "Motu Mute Intl Airport",
    Address: "Bora Bora,FRENCH POLYNESIA",
  },
  {
    code: "BOC",
    name: "Bocas Del Toro Intl Airport",
    Address: "Bocas Del Toro,PANAMA",
  },
  { code: "ZFQ", name: "Gare de Bordeaux", Address: "Bordeaux,FRANCE" },
  {
    code: "BOD",
    name: "Bordeaux Intl Airport",
    Address: "Bordeaux,FRANCE",
  },
  {
    code: "BOG",
    name: "Eldorado Intl Airport",
    Address: "Bogota,COLOMBIA",
  },
  {
    code: "BOH",
    name: "Bournemouth Intl Airport",
    Address: "Bournemouth,UNITED KINGDOM",
  },
  {
    code: "BOI",
    name: "Boise Municipal Intl Airport  Gowen Field",
    Address: "Boise,UNITED STATES",
  },
  {
    code: "BOJ",
    name: "Bourgas Intl Airport",
    Address: "Bourgas,BULGARIA",
  },
  {
    code: "BOK",
    name: "Brookings State Intl Airport",
    Address: "Brookings,UNITED STATES",
  },
  { code: "BOM", name: "Chhatrapati Shivaji", Address: "Mumbai,INDIA" },
  {
    code: "BON",
    name: "Flamingo Field",
    Address: "Bonaire,NETHERLANDS ANTILLES",
  },
  { code: "BOO", name: "Bodo Intl Airport", Address: "Bodo,NORWAY" },
  { code: "ZXM", name: "Rognan Rail Station", Address: "Bodo,NORWAY" },
  {
    code: "BOS",
    name: "Logan Intl Airport",
    Address: "Boston,UNITED STATES",
  },
  {
    code: "BOU",
    name: "Bourges Intl Airport",
    Address: "Bourges,FRANCE",
  },
  {
    code: "BOW",
    name: "Bartow Intl Airport",
    Address: "Bartow,UNITED STATES",
  },
  {
    code: "BOX",
    name: "Borroloola Intl Airport",
    Address: "Borroloola,AUSTRALIA",
  },
  {
    code: "BOY",
    name: "Borgo Intl Airport",
    Address: "Bobo Dioulasso,BURKINA FASO",
  },
  {
    code: "BPN",
    name: "Sepingan Intl Airport",
    Address: "Balikpapan,INDONESIA",
  },
  {
    code: "BPS",
    name: "Porto Seguro Intl Airport",
    Address: "Porto Seguro,BRAZIL",
  },
  {
    code: "BPT",
    name: "Jefferson Cty Intl Airport",
    Address: "Beaumont,UNITED STATES",
  },
  {
    code: "BQB",
    name: "Busselton Intl Airport",
    Address: "Busselton,AUSTRALIA",
  },
  {
    code: "BQK",
    name: "Gylnco Jet Port",
    Address: "Brunswick,UNITED STATES",
  },
  {
    code: "BQL",
    name: "Boulia Intl Airport",
    Address: "Boulia,AUSTRALIA",
  },
  {
    code: "BQN",
    name: "Borinquen Municipal Intl Airport",
    Address: "Aguadilla,UNITED STATES",
  },
  {
    code: "BQS",
    name: "Blagoveshchensk Intl Airport",
    Address: "Blagoveshchensk,RUSSIA",
  },
  { code: "BQT", name: "Brest Intl Airport", Address: "Brest,BELARUS" },
  {
    code: "BRA",
    name: "Barreiras Intl Airport",
    Address: "Barreiras,BRAZIL",
  },
  {
    code: "BRC",
    name: "San Carlos De Bariloche Intl Airport",
    Address: "San Carlos Bariloche,ARGENTINA",
  },
  {
    code: "BRD",
    name: "Crowwing Cty Intl Airport",
    Address: "Brainerd,UNITED STATES",
  },
  { code: "BRE", name: "Bremen Intl Airport", Address: "Bremen,GERMANY" },
  {
    code: "BRF",
    name: "Bradford Rail Station",
    Address: "Bradford,UNITED KINGDOM",
  },
  { code: "BRI", name: "Bari Intl Airport", Address: "Bari,ITALY" },
  {
    code: "BRK",
    name: "Bourke Intl Airport",
    Address: "Bourke,AUSTRALIA",
  },
  {
    code: "BRL",
    name: "Burlington Municipal Intl Airport",
    Address: "Burlington,UNITED STATES",
  },
  {
    code: "BRM",
    name: " Intl Airport  Barquisimeto",
    Address: "Barquisimeto,VENEZUELA",
  },
  {
    code: "BRN",
    name: "Belp Intl Airport",
    Address: "Berne,SWITZERLAND",
  },
  {
    code: "ZDJ",
    name: "Berne Railroad Station",
    Address: "Berne,SWITZERLAND",
  },
  {
    code: "BRO",
    name: "South Padre Island Intl Airport",
    Address: "Brownsville,UNITED STATES",
  },
  {
    code: "BRQ",
    name: "Turnay Intl Airport",
    Address: "Brno,CZECH REPUBLIC",
  },
  {
    code: "BRR",
    name: "North Bay Intl Airport",
    Address: "Barra,UNITED KINGDOM",
  },
  {
    code: "BRS",
    name: "Bristol Intl Airport",
    Address: "Bristol,UNITED KINGDOM",
  },
  {
    code: "BRT",
    name: "Bathurst Island Intl Airport",
    Address: "Bathurst Isl,AUSTRALIA",
  },
  {
    code: "CRL",
    name: "Brussels South Charleroi Intl Airport",
    Address: "Brussels,BELGIUM",
  },
  {
    code: "ZYR",
    name: "Midi Railway Station",
    Address: "Brussels,BELGIUM",
  },
  {
    code: "ZYZ",
    name: "Antwerp Berchem Rail Station",
    Address: "Brussels,BELGIUM",
  },
  {
    code: "BRU",
    name: "Brussels National Intl Airport",
    Address: "Brussels,BELGIUM",
  },
  {
    code: "BRV",
    name: "Bremerhaven Intl Airport",
    Address: "Bremerhaven,GERMANY",
  },
  { code: "BRW", name: "Barrow WBAS", Address: "Barrow,UNITED STATES" },
  {
    code: "BRX",
    name: "Barahona Intl Airport",
    Address: "Barahona,DOMINICAN REPUBLIC",
  },
  {
    code: "BSB",
    name: "Brasilia Intl Airport",
    Address: "Brasilia,BRAZIL",
  },
  { code: "BSD", name: "Baoshan Intl Airport", Address: "Baoshan,CHINA" },
  {
    code: "BSH",
    name: "Brighton Intl Airport",
    Address: "Brighton,UNITED KINGDOM",
  },
  {
    code: "BSI",
    name: "Blairsville Intl Airport",
    Address: "Blairsville,UNITED STATES",
  },
  {
    code: "BSJ",
    name: "Bairnsdale Intl Airport",
    Address: "Bairnsdale,AUSTRALIA",
  },
  { code: "BSK", name: "Biskra Intl Airport", Address: "Biskra,ALGERIA" },
  {
    code: "BSL",
    name: "Basel Euro Intl Airport  Swiss",
    Address: "Basel,SWITZERLAND",
  },
  {
    code: "BSQ",
    name: "Bisbee Municipal Intl Airport",
    Address: "Bisbee,UNITED STATES",
  },
  { code: "BSR", name: "Basra Intl Airport", Address: "Basra,IRAQ" },
  {
    code: "BTH",
    name: "Hang Nadim Intl Airport",
    Address: "Batam,INDONESIA",
  },
  {
    code: "BTJ",
    name: "Blang Bintang Intl Airport",
    Address: "Banda Aceh,INDONESIA",
  },
  { code: "BTK", name: "Bratsk Intl Airport", Address: "Bratsk,RUSSIA" },
  {
    code: "BTL",
    name: "WK Kellogg Regional",
    Address: "Battle Creek,UNITED STATES",
  },
  {
    code: "BTM",
    name: "Bert Mooney Intl Airport",
    Address: "Butte,UNITED STATES",
  },
  {
    code: "EBR",
    name: "Baton Rouge Downtown Intl Airport",
    Address: "Baton Rouge,UNITED STATES",
  },
  {
    code: "BTR",
    name: "Ryan Intl Airport",
    Address: "Baton Rouge,UNITED STATES",
  },
  {
    code: "ZRG",
    name: "Bratislava Bus Station",
    Address: "Bratislava,SLOVAKIA",
  },
  {
    code: "BTS",
    name: "Ivanka Intl Airport",
    Address: "Bratislava,SLOVAKIA",
  },
  {
    code: "BTT",
    name: "Bettles Intl Airport",
    Address: "Bettles,UNITED STATES",
  },
  {
    code: "BTU",
    name: "Bintulu Intl Airport",
    Address: "Bintulu,MALAYSIA",
  },
  {
    code: "BTV",
    name: "Burlington Intl Airport",
    Address: "Burlington,UNITED STATES",
  },
  {
    code: "YEI",
    name: "Yenisehir Intl Airport",
    Address: "Bursa,TURKEY",
  },
  { code: "BTZ", name: "Bursa Intl Airport", Address: "Bursa,TURKEY" },
  {
    code: "BUC",
    name: "Burketown Intl Airport",
    Address: "Burketown,AUSTRALIA",
  },
  {
    code: "BUD",
    name: "Ferihegy Intl Airport",
    Address: "Budapest,HUNGARY",
  },
  {
    code: "EZE",
    name: "Ministro Pistarini",
    Address: "Buenos Aires,ARGENTINA",
  },
  {
    code: "AEP",
    name: "Jorge Newbery",
    Address: "Buenos Aires,ARGENTINA",
  },
  {
    code: "BUF",
    name: "Greater Buffalo Intl Airport",
    Address: "Buffalo,UNITED STATES",
  },
  { code: "OTP", name: "Otopeni Intl ", Address: "Bucharest,ROMANIA" },
  {
    code: "BBU",
    name: "Baneasa Intl Airport",
    Address: "Bucharest,ROMANIA",
  },
  {
    code: "BUN",
    name: "Buenaventura Intl Airport",
    Address: "Buenaventura,COLOMBIA",
  },
  {
    code: "BUQ",
    name: "Bulawayo Intl Airport",
    Address: "Bulawayo,ZIMBABWE",
  },
  {
    code: "BUR",
    name: "Burbank Glendale Pasadena Intl Airport",
    Address: "Burbank,UNITED STATES",
  },
  { code: "BUS", name: "Batumi Intl Airport", Address: "Batumi,GEORGIA" },
  {
    code: "BUY",
    name: "Bunbury Intl Airport",
    Address: "Bunbury,AUSTRALIA",
  },
  { code: "BUZ", name: "Bushehr Intl Airport", Address: "Bushehr,IRAN" },
  {
    code: "BVB",
    name: "Boa Vista Intl Airport",
    Address: "Boa Vista,BRAZIL",
  },
  {
    code: "BVC",
    name: "Rabil Intl Airport",
    Address: "Boa Vista,CAPE VERDE",
  },
  {
    code: "BVE",
    name: "Laroche Intl Airport",
    Address: "Brive La Gaill,FRANCE",
  },
  {
    code: "BVH",
    name: "Vilhena Intl Airport",
    Address: "Vilhena,BRAZIL",
  },
  {
    code: "BVI",
    name: "Birdsville Intl Airport",
    Address: "Birdsville,AUSTRALIA",
  },
  {
    code: "BVO",
    name: "Bartlesville Municipal Intl Airport",
    Address: "Bartlesville,UNITED STATES",
  },
  { code: "BVR", name: "Esperadinha", Address: "Brava,CAPE VERDE" },
  {
    code: "BVX",
    name: "Batesville Municipal",
    Address: "Batesville,UNITED STATES",
  },
  {
    code: "BVY",
    name: "Beverly Municipal Intl Airport",
    Address: "Beverly,UNITED STATES",
  },
  {
    code: "BWC",
    name: "Brawley Intl Airport",
    Address: "Brawley,UNITED STATES",
  },
  {
    code: "BWD",
    name: "Brownwood Municipal Intl Airport",
    Address: "Brownwood,UNITED STATES",
  },
  {
    code: "BWE",
    name: "Braunschweig Intl Airport",
    Address: "Braunschweig,GERMANY",
  },
  {
    code: "BWF",
    name: "Walney Island",
    Address: "Barrow In Furness,UNITED KINGDOM",
  },
  {
    code: "BWG",
    name: "Warren Cty Intl Airport",
    Address: "Bowling Green,UNITED STATES",
  },
  {
    code: "BWI",
    name: "Baltimore Washington Intl Airport",
    Address: "Baltimore,UNITED STATES",
  },
  {
    code: "APG",
    name: "Phillips Army Air Field",
    Address: "Baltimore,UNITED STATES",
  },
  {
    code: "BWK",
    name: "Bol Intl Airport",
    Address: "Bol Intl Airport,CROATIA",
  },
  {
    code: "BWN",
    name: "Brunei Intl Airport",
    Address: "Bandar Seri Begawan,BRUNEI DARUSSALAM",
  },
  {
    code: "BWO",
    name: "Balakovo Intl Airport",
    Address: "Balakovo Intl Airport,RUSSIA",
  },
  {
    code: "BWQ",
    name: "Brewarrina Intl Airport",
    Address: "Brewarrina,AUSTRALIA",
  },
  {
    code: "BWT",
    name: "Burnie Wynyard Intl Airport",
    Address: "Burnie,AUSTRALIA",
  },
  {
    code: "BWU",
    name: "Bankstown Aerodrome",
    Address: "Bankstown,AUSTRALIA",
  },
  { code: "BJV", name: "Milas Intl Airport", Address: "Bodrum,TURKEY" },
  {
    code: "BXS",
    name: "Borrego Valley Intl Airport",
    Address: "Borrego Springs,UNITED STATES",
  },
  {
    code: "BXU",
    name: "Butuan Intl Airport",
    Address: "Butuan,PHILIPPINES",
  },
  { code: "BYF", name: "Albert Intl Airport", Address: "Meaulte,FRANCE" },
  {
    code: "BYI",
    name: "Burley Intl Airport",
    Address: "Burley,UNITED STATES",
  },
  {
    code: "BYR",
    name: "Laeso Intl Airport",
    Address: "Laeso Island,DENMARK",
  },
  {
    code: "BYU",
    name: "Bindlacher Berg Intl Airport",
    Address: "Bayreuth,GERMANY",
  },
  {
    code: "BYW",
    name: "Blakely Is Municipal",
    Address: "Blakely Island,UNITED STATES",
  },
  { code: "BZC", name: "Buzios Intl Airport", Address: "Buzios,BRAZIL" },
  {
    code: "TZA",
    name: "Belize City Municipal",
    Address: "Belize City,BELIZE",
  },
  {
    code: "BZE",
    name: "Belize Intl Airport",
    Address: "Belize City,BELIZE",
  },
  {
    code: "BZG",
    name: "Bydgoszcz Intl Airport",
    Address: "Bydgoszcz,POLAND",
  },
  {
    code: "BZH",
    name: "Bumi Hills Intl Airport",
    Address: "Bumi Hills,ZIMBABWE",
  },
  { code: "BZI", name: "Balikesir", Address: "Balikesir,TURKEY" },
  {
    code: "MQJ",
    name: "Merkez Intl Airport",
    Address: "Balikesir,TURKEY",
  },
  {
    code: "BZN",
    name: "Gallatin Field",
    Address: "Bozeman,UNITED STATES",
  },
  { code: "BZO", name: "Bolzano Intl Airport", Address: "Bolzano,ITALY" },
  {
    code: "BZR",
    name: "Beziers Vias Intl Airport",
    Address: "Beziers,FRANCE",
  },
  {
    code: "BZT",
    name: "Brazoria County Intl Airport",
    Address: "Brazoria,UNITED STATES",
  },
  {
    code: "BZV",
    name: "Maya Maya Intl Airport",
    Address: "Brazzaville,CONGO",
  },
  {
    code: "CAC",
    name: "Cascavel Intl Airport",
    Address: "Cascavel,BRAZIL",
  },
  {
    code: "CAD",
    name: "Wexford County Intl Airport",
    Address: "Cadillac,UNITED STATES",
  },
  {
    code: "OGB",
    name: "Orangeburg Municpal",
    Address: "Columbia,UNITED STATES",
  },
  {
    code: "CAE",
    name: "Columbia Metro Intl Airport",
    Address: "Columbia,UNITED STATES",
  },
  { code: "CAG", name: "Elmas Intl Airport", Address: "Cagliari,ITALY" },
  { code: "CAI", name: "Cairo Intl Airport", Address: "Cairo,EGYPT" },
  {
    code: "CAK",
    name: "Akron Canton Regional Intl Airport",
    Address: "Akron,UNITED STATES",
  },
  {
    code: "CAL",
    name: "Machrihanish Intl Airport",
    Address: "Campbelltown,UNITED KINGDOM",
  },
  {
    code: "CAM",
    name: "Choreti Intl Airport",
    Address: "Camiri,BOLIVIA",
  },
  {
    code: "CAN",
    name: "Baiyun Intl Airport",
    Address: "Guangzhou,CHINA",
  },
  {
    code: "CAP",
    name: "Cap Haitien Numicipal",
    Address: "Cap Haitien,HAITI",
  },
  {
    code: "CAR",
    name: "Caribou Municipal Intl Airport",
    Address: "Caribou,UNITED STATES",
  },
  {
    code: "CMN",
    name: "Mohamed V Intl Airport",
    Address: "Casablanca,MOROCCO",
  },
  {
    code: "CAS",
    name: "Anfa Intl Airport",
    Address: "Casablanca,MOROCCO",
  },
  {
    code: "CAU",
    name: "Caruaru Intl Airport",
    Address: "Caruaru,BRAZIL",
  },
  {
    code: "CAW",
    name: "Bartolomeu Lisandro Intl Airport",
    Address: "Campos,BRAZIL",
  },
  {
    code: "XPF",
    name: "Penrith Rail Station",
    Address: "Carlisle,UNITED KINGDOM",
  },
  {
    code: "CAX",
    name: "Carlisle Intl Airport",
    Address: "Carlisle,UNITED KINGDOM",
  },
  {
    code: "CAY",
    name: "Rochambeau Intl Airport",
    Address: "Cayenne,FRENCH GUIANA",
  },
  { code: "CAZ", name: "Cobar Intl Airport", Address: "Cobar,AUSTRALIA" },
  {
    code: "CBB",
    name: "J Wilsterman Intl Airport",
    Address: "Cochabamba,BOLIVIA",
  },
  {
    code: "CBE",
    name: "Cumberland Municipal Intl Airport",
    Address: "Cumberland,UNITED STATES",
  },
  {
    code: "CBF",
    name: "Council Bluffs Municipal Intl Airport",
    Address: "Council Bluffs,UNITED STATES",
  },
  {
    code: "CBG",
    name: "Cambridge Intl Airport",
    Address: "Cambridge,UNITED KINGDOM",
  },
  {
    code: "CBK",
    name: "Colby Municipal Intl Airport",
    Address: "Colby,UNITED STATES",
  },
  {
    code: "CBL",
    name: "Ciudad Bolivar Intl Airport",
    Address: "Ciudad Bolivar,VENEZUELA",
  },
  {
    code: "CBN",
    name: "Penggung Intl Airport",
    Address: "Cirebon,INDONESIA",
  },
  {
    code: "CBO",
    name: "Awang Intl Airport",
    Address: "Cotabato,PHILIPPINES",
  },
  {
    code: "CBP",
    name: "Coimbra Intl Airport",
    Address: "Coimbra,PORTUGAL",
  },
  {
    code: "CBR",
    name: "Canberra Intl Airport",
    Address: "Canberra,AUSTRALIA",
  },
  {
    code: "CBS",
    name: "Oro Negro Intl Airport",
    Address: "Cabimas,VENEZUELA",
  },
  {
    code: "CCC",
    name: "Cayo Coco Intl Airport",
    Address: "Cayo Coco,CUBA",
  },
  {
    code: "CCE",
    name: "Grand Case Intl Airport",
    Address: "Saint Martin,GUADELOUPE",
  },
  { code: "CCF", name: "Salvaza", Address: "Carcassonne,FRANCE" },
  {
    code: "CCJ",
    name: "Kozhikode Intl Airport",
    Address: "Kozhikode,INDIA",
  },
  {
    code: "CCK",
    name: "Cocos Islands Intl Airport",
    Address: "Cocos Islands,COCOS (KEELING) ISLANDS",
  },
  {
    code: "CCM",
    name: "Criciuma Intl Airport",
    Address: "Criciuma,BRAZIL",
  },
  {
    code: "CCP",
    name: "Carriel Sur Intl Airport",
    Address: "Concepcion,CHILE",
  },
  {
    code: "CCR",
    name: "Buchanan Field",
    Address: "Concord,UNITED STATES",
  },
  {
    code: "CCS",
    name: "Simon Bolivar Intl Airport",
    Address: "Caracas,VENEZUELA",
  },
  {
    code: "CCU",
    name: "Netaji Subhas Chandra Bose Intl ",
    Address: "Kolkata,INDIA",
  },
  {
    code: "CCV",
    name: "Craig Cove Intl Airport",
    Address: "Craig Cove,VANUATU",
  },
  {
    code: "CDA",
    name: "Cooinda Intl Airport",
    Address: "Cooinda,AUSTRALIA",
  },
  {
    code: "CDC",
    name: "Cedar City Municipal",
    Address: "Cedar City,UNITED STATES",
  },
  {
    code: "CDD",
    name: "Cauquira Intl Airport",
    Address: "Cauquira,HONDURAS",
  },
  {
    code: "CDH",
    name: "Harrell Fieldsandro Intl Airport",
    Address: "Camden,UNITED STATES",
  },
  {
    code: "CDI",
    name: "Cachoeiro De Itapemirim Intl Airport",
    Address: "Cachoeiro De Itapemirim,BRAZIL",
  },
  {
    code: "CDR",
    name: "Chadron Intl Airport",
    Address: "Chadron,UNITED STATES",
  },
  {
    code: "CDV",
    name: "Mudhole Smith Intl Airport",
    Address: "Cordova,UNITED STATES",
  },
  {
    code: "CDW",
    name: "Caldwell Wright Intl Airport",
    Address: "Caldwell,UNITED STATES",
  },
  { code: "CEB", name: "Cebu Intl ", Address: "Cebu,PHILIPPINES" },
  {
    code: "CEC",
    name: "Crescent City Municipal Intl Airport",
    Address: "Crescent City,UNITED STATES",
  },
  {
    code: "CED",
    name: "Ceduna Intl Airport",
    Address: "Ceduna,AUSTRALIA",
  },
  {
    code: "CEE",
    name: "Cherepovets Intl Airport",
    Address: "Cherepovets Intl Airport,RUSSIA",
  },
  {
    code: "XRC",
    name: "Runcorn Rail Station",
    Address: "Chester,UNITED KINGDOM",
  },
  {
    code: "CEG",
    name: "Chester Intl Airport",
    Address: "Chester,UNITED KINGDOM",
  },
  {
    code: "CEH",
    name: "Chelinda Intl Airport",
    Address: "Chelinda,MALAWI",
  },
  {
    code: "CEI",
    name: "Chaing Rai Intl Airport",
    Address: "Chiang Rai,THAILAND",
  },
  {
    code: "CEK",
    name: "Chelyabinsk Intl Airport",
    Address: "Chelyabinsk,RUSSIA",
  },
  {
    code: "CEN",
    name: "Ciudad Obregon Intl Airport",
    Address: "Ciudad Obregon,MEXICO",
  },
  {
    code: "CEQ",
    name: "Mandelieu Intl Airport",
    Address: "Cannes,FRANCE",
  },
  {
    code: "CER",
    name: "Maupertius Intl Airport",
    Address: "Cherbourg,FRANCE",
  },
  {
    code: "CES",
    name: "Cessnock Intl Airport",
    Address: "Cessnock,AUSTRALIA",
  },
  {
    code: "CET",
    name: "Le Pontreau Intl Airport",
    Address: "Cholet,FRANCE",
  },
  {
    code: "CEU",
    name: "Clemson Oconee Cty Intl Airport",
    Address: "Clemson,UNITED STATES",
  },
  {
    code: "CEY",
    name: "Calloway Cty Intl Airport",
    Address: "Murray,UNITED STATES",
  },
  {
    code: "CEZ",
    name: "Montezuma County",
    Address: "Cortez,UNITED STATES",
  },
  {
    code: "CFB",
    name: "Cabo Frio Intl Airport",
    Address: "Cabo Frio,BRAZIL",
  },
  {
    code: "CFE",
    name: "Aulnat Intl Airport",
    Address: "Clermont Ferrand,FRANCE",
  },
  {
    code: "CFG",
    name: "Cienfuegos Intl Airport",
    Address: "Cienfuegos,CUBA",
  },
  { code: "CFK", name: "Chlef Intl Airport", Address: "Chlef,ALGERIA" },
  {
    code: "CFN",
    name: "Donegal Intl Airport",
    Address: "Donegal,IRELAND",
  },
  {
    code: "CFQ",
    name: "Creston Intl Airport",
    Address: "Creston,CANADA",
  },
  { code: "CFR", name: "Carpiquet Intl Airport", Address: "Caen,FRANCE" },
  {
    code: "CFS",
    name: "Coffs Harbour Intl Airport",
    Address: "Coffs Harbour,AUSTRALIA",
  },
  {
    code: "CFU",
    name: "I Kapodistrias Intl Airport",
    Address: "Kerkyra,GREECE",
  },
  {
    code: "CGA",
    name: "Craig Seaplane Base",
    Address: "Craig,UNITED STATES",
  },
  {
    code: "CGB",
    name: "Marechal Rondon Intl Airport",
    Address: "Cuiaba,BRAZIL",
  },
  { code: "CGD", name: "Changde Intl Airport", Address: "Changde,CHINA" },
  {
    code: "CGI",
    name: "Cape Girardeau Municipal Intl Airport",
    Address: "Cape Girardeau,UNITED STATES",
  },
  {
    code: "QKL",
    name: "Cologne Railroad Station",
    Address: "Cologne,GERMANY",
  },
  {
    code: "CGN",
    name: "Koeln Bonn Intl Airport",
    Address: "Cologne,GERMANY",
  },
  {
    code: "CGO",
    name: "Zhengzhou Intl Airport",
    Address: "Zhengzhou,CHINA",
  },
  {
    code: "CGP",
    name: "Patenga Intl Airport",
    Address: "Chittagong,BANGLADESH",
  },
  {
    code: "CGQ",
    name: "Changchun Intl Airport",
    Address: "Changchun,CHINA",
  },
  { code: "CGR", name: "Internacional", Address: "Campogrande,BRAZIL" },
  {
    code: "CGY",
    name: "Lumbia Intl Airport",
    Address: "Cagayan,PHILIPPINES",
  },
  {
    code: "CHA",
    name: "Chattanooga Lovell Fld",
    Address: "Chattanooga,UNITED STATES",
  },
  {
    code: "CHC",
    name: "Christchurch Intl Airport",
    Address: "Christchurch,NEW ZEALAND",
  },
  {
    code: "CHD",
    name: "Williams AFB Intl Airport",
    Address: "Chandler,UNITED STATES",
  },
  {
    code: "PWK",
    name: "Pal Waukee Intl Airport",
    Address: "Chicago,UNITED STATES",
  },
  {
    code: "RFD",
    name: "Chicago Rockford Intl Airport",
    Address: "Chicago,UNITED STATES",
  },
  {
    code: "DPA",
    name: "Dupage County Intl Airport",
    Address: "Chicago,UNITED STATES",
  },
  {
    code: "ORD",
    name: "O'Hare Intl Airport",
    Address: "Chicago,UNITED STATES",
  },
  { code: "MDW", name: "Midway", Address: "Chicago,UNITED STATES" },
  {
    code: "CHO",
    name: "Charlottesville Albemarle Intl Airport",
    Address: "Charlottesville,UNITED STATES",
  },
  { code: "CHQ", name: "Souda Intl Airport", Address: "Chania,GREECE" },
  {
    code: "CHR",
    name: "Chateauroux Intl Airport",
    Address: "Chateauroux,FRANCE",
  },
  {
    code: "CHS",
    name: "Charleston Intl Airport",
    Address: "Charleston,UNITED STATES",
  },
  { code: "CHW", name: "Jiuquan Intl Airport", Address: "Jiuquan,CHINA" },
  {
    code: "CHX",
    name: "Changuinola Intl Airport",
    Address: "Changuinola,PANAMA",
  },
  {
    code: "CIC",
    name: "Chico Municipal Intl Airport",
    Address: "Chico,UNITED STATES",
  },
  {
    code: "CID",
    name: "Cedar Rapids Municipal Intl Airport",
    Address: "Cedar Rapids,UNITED STATES",
  },
  { code: "CIG", name: "Craig Moffat", Address: "Craig,UNITED STATES" },
  {
    code: "CIL",
    name: "Melsing Creek Intl Airport",
    Address: "Council,UNITED STATES",
  },
  {
    code: "CIO",
    name: "Mcal Lopez Intl Airport",
    Address: "Concepcion,PARAGUAY",
  },
  {
    code: "CIT",
    name: "Shimkent Intl Airport",
    Address: "Shimkent,KAZAKHSTAN",
  },
  {
    code: "CIW",
    name: "Canouan Island Minicipal",
    Address: "Canovan Island,DOMINICA",
  },
  {
    code: "CIX",
    name: "Cornel Ruiz Intl Airport",
    Address: "Chiclayo,PERU",
  },
  { code: "CIY", name: "Comiso Intl Airport", Address: "Comiso,ITALY" },
  {
    code: "CJB",
    name: "Peelamedu Intl Airport",
    Address: "Coimbatore,INDIA",
  },
  { code: "CJC", name: "El Loa Intl Airport", Address: "Calama,CHILE" },
  {
    code: "CJI",
    name: "Ciudad Real City Intl Airport",
    Address: "Ciudad Real City,SPAIN",
  },
  {
    code: "CJJ",
    name: "Cheongju Intl Airport",
    Address: "Cheongju,KOREA, REPUBLIC OF",
  },
  {
    code: "CJM",
    name: "Chumphon Intl Airport",
    Address: "Chumphon,THAILAND",
  },
  {
    code: "CJN",
    name: "El Cajon Intl Airport",
    Address: "El Cajon,UNITED STATES",
  },
  {
    code: "CJS",
    name: "Intl Abraham Gonzalez",
    Address: "Ciudad Juarez,MEXICO",
  },
  {
    code: "CJU",
    name: "Jeju Intl Airport",
    Address: "Jeju City,KOREA, REPUBLIC OF",
  },
  {
    code: "CKB",
    name: "Clarksburg Benedum Intl Airport",
    Address: "Clarksburg,UNITED STATES",
  },
  {
    code: "CKG",
    name: "Chongqing Intl Airport",
    Address: "Chongqing,CHINA",
  },
  {
    code: "CKH",
    name: "Chokurdakh Intl Airport",
    Address: "Chokurdakh Intl Airport,RUSSIA",
  },
  {
    code: "CKM",
    name: "Fletcher Field",
    Address: "Clarksdale,UNITED STATES",
  },
  {
    code: "CKS",
    name: "Carajas Intl Airport",
    Address: "Carajas,BRAZIL",
  },
  {
    code: "CKV",
    name: "Outlaw Field",
    Address: "Clarksville,UNITED STATES",
  },
  {
    code: "CKY",
    name: "Conakry Intl Airport",
    Address: "Conakry,GUINEA",
  },
  {
    code: "CKZ",
    name: "Canakkale Intl Airport",
    Address: "Canakkale,TURKEY",
  },
  {
    code: "CLC",
    name: "Metroport",
    Address: "Clear Lake City,UNITED STATES",
  },
  {
    code: "CLD",
    name: "Carlsbad Intl Airport",
    Address: "Carlsbad,UNITED STATES",
  },
  {
    code: "CGF",
    name: "Cuyahoga County Intl Airport",
    Address: "Cleveland,UNITED STATES",
  },
  {
    code: "CLE",
    name: "Hopkins Intl Airport",
    Address: "Cleveland,UNITED STATES",
  },
  {
    code: "BKL",
    name: "Burke Lakefront Intl Airport",
    Address: "Cleveland,UNITED STATES",
  },
  { code: "CLJ", name: "Napoca Intl Airport", Address: "Cluj,ROMANIA" },
  {
    code: "CLL",
    name: "Easterwood Field",
    Address: "College Station,UNITED STATES",
  },
  {
    code: "CLM",
    name: "William Fairchild Intl Airport",
    Address: "Port Angeles,UNITED STATES",
  },
  {
    code: "CLO",
    name: "Alfonso Bonella Aragon Intl Airport",
    Address: "Cali,COLOMBIA",
  },
  { code: "CLQ", name: "Colima Intl Airport", Address: "Colima,MEXICO" },
  {
    code: "CLT",
    name: "Charlotte Douglas Intl Airport",
    Address: "Charlotte,UNITED STATES",
  },
  {
    code: "CLU",
    name: "Columbus Municipal Intl Airport",
    Address: "Columbus,UNITED STATES",
  },
  {
    code: "CLW",
    name: "Clearwater Executive Airpark",
    Address: "Clearwater,UNITED STATES",
  },
  {
    code: "CLY",
    name: "Ste Catherine Intl Airport",
    Address: "Calvi,FRANCE",
  },
  {
    code: "CMA",
    name: "Cunnamulla Intl Airport",
    Address: "Cunnamulla,AUSTRALIA",
  },
  {
    code: "CMB",
    name: "Bandaranaike Intl Airport",
    Address: "Colombo,SRI LANKA",
  },
  {
    code: "CME",
    name: "Ciudad Del Carmen Intl Airport",
    Address: "Ciudad Del Carmen,MEXICO",
  },
  {
    code: "CMF",
    name: "Chambery Aix Les Bains Intl Airport",
    Address: "Chambery,FRANCE",
  },
  {
    code: "CMG",
    name: "Internacional Corumba",
    Address: "Corumba,BRAZIL",
  },
  {
    code: "OSU",
    name: "Ohio State Univ Intl Airport",
    Address: "Columbus,UNITED STATES",
  },
  {
    code: "LCK",
    name: "Rickenbacker Intl Airport",
    Address: "Columbus,UNITED STATES",
  },
  {
    code: "CMH",
    name: "Port Columbus Intl Airport",
    Address: "Columbus,UNITED STATES",
  },
  {
    code: "CMI",
    name: "Univ Of Illinois Willard Intl Airport",
    Address: "Champaign,UNITED STATES",
  },
  {
    code: "CMQ",
    name: "Clermont Intl Airport",
    Address: "Clermont,AUSTRALIA",
  },
  {
    code: "CMR",
    name: "Colmar Houssen Intl Airport",
    Address: "Colmar,FRANCE",
  },
  {
    code: "CMV",
    name: "Coromandel Intl Airport",
    Address: "Coromandel,NEW ZEALAND",
  },
  {
    code: "CMW",
    name: "Ign Agramonte Intl Airport",
    Address: "Camaguey,CUBA",
  },
  {
    code: "CMX",
    name: "Houghton Cty Memorial Intl Airport",
    Address: "Houghton,UNITED STATES",
  },
  {
    code: "CMY",
    name: "Camp Mccoy Aaf",
    Address: "Sparta,UNITED STATES",
  },
  {
    code: "CNB",
    name: "Coonamble Intl Airport",
    Address: "Coonamble,AUSTRALIA",
  },
  {
    code: "CNC",
    name: "Coconut Island Intl Airport",
    Address: "Coconut Island,AUSTRALIA",
  },
  {
    code: "CND",
    name: "Kogalniceanu Intl Airport",
    Address: "Constanta,ROMANIA",
  },
  { code: "CNG", name: "Parvaud Intl Airport", Address: "Cognac,FRANCE" },
  {
    code: "CNJ",
    name: "Cloncurry Intl Airport",
    Address: "Cloncurry,AUSTRALIA",
  },
  {
    code: "CNM",
    name: "Carlsbad Intl Airport",
    Address: "Carlsbad,UNITED STATES",
  },
  {
    code: "CNQ",
    name: "Camba Punta Intl Airport",
    Address: "Corrientes,ARGENTINA",
  },
  {
    code: "CNS",
    name: "Cairns Intl Airport",
    Address: "Cairns,AUSTRALIA",
  },
  {
    code: "CNX",
    name: "Chiang Mai Intl Airport",
    Address: "Chiang Mai,THAILAND",
  },
  { code: "CNY", name: "Moab Municipal", Address: "Moab,UNITED STATES" },
  {
    code: "COC",
    name: "Concordia Intl Airport",
    Address: "Concordia,ARGENTINA",
  },
  {
    code: "COD",
    name: "Yellowstone Regional Intl Airport",
    Address: "Cody,UNITED STATES",
  },
  {
    code: "COE",
    name: "Coeur d Alene Municipal Intl Airport",
    Address: "Coeur D Alene,UNITED STATES",
  },
  {
    code: "COF",
    name: "Patrick AFB",
    Address: "Cocoa Metro Area,UNITED STATES",
  },
  {
    code: "COI",
    name: "Merrit Island Intl Airport",
    Address: "Cocoa Metro Area,UNITED STATES",
  },
  {
    code: "COJ",
    name: "Coonabarabran Intl Airport",
    Address: "Coonabarabrn,AUSTRALIA",
  },
  { code: "COK", name: "Kochi Intl Airport", Address: "Kochi,INDIA" },
  {
    code: "CON",
    name: "Concord Intl Airport",
    Address: "Concord,UNITED STATES",
  },
  { code: "COO", name: "Cotonou Intl Airport", Address: "Cotonou,BENIN" },
  {
    code: "COR",
    name: "Pajas Blanco Intl Airport",
    Address: "Cordoba,ARGENTINA",
  },
  {
    code: "COS",
    name: "Colorado Springs Municipal Intl Airport",
    Address: "Colorado Springs,UNITED STATES",
  },
  {
    code: "COU",
    name: "Columbia Regional",
    Address: "Columbia,UNITED STATES",
  },
  {
    code: "CPC",
    name: "Chapelco Intl Airport",
    Address: "Chapelco,ARGENTINA",
  },
  {
    code: "CPD",
    name: "Coober Pedy Intl Airport",
    Address: "Coober Pedy,AUSTRALIA",
  },
  {
    code: "CPE",
    name: "Campeche Intl Airport",
    Address: "Campeche,MEXICO",
  },
  {
    code: "ZGH",
    name: "Copenhagen Rail Station",
    Address: "Copenhagen,DENMARK",
  },
  {
    code: "CPH",
    name: "Copenhagen Intl Airport",
    Address: "Copenhagen,DENMARK",
  },
  {
    code: "CPO",
    name: "Chamonate Intl Airport",
    Address: "Copiapo,CHILE",
  },
  { code: "CPQ", name: " Intl  Campinas", Address: "Campinas,BRAZIL" },
  {
    code: "CPR",
    name: "Natrona Cty Intl Airport",
    Address: "Casper,UNITED STATES",
  },
  {
    code: "CPT",
    name: "Cape Town Intl ",
    Address: "Cape Town,SOUTH AFRICA",
  },
  {
    code: "CPV",
    name: "Joao Suassuana Intl Airport",
    Address: "Campina Grande,BRAZIL",
  },
  {
    code: "CPX",
    name: "Culebra Intl Airport",
    Address: "Culebra,UNITED STATES",
  },
  {
    code: "CQD",
    name: "Shahre Kord Intl Airport",
    Address: "Shahre Kord,IRAN",
  },
  {
    code: "CQM",
    name: "Ciudad Real Central Intl Airport",
    Address: "Ciudad Real,SPAIN",
  },
  {
    code: "CRA",
    name: "Craiova Intl Airport",
    Address: "Craiova,ROMANIA",
  },
  {
    code: "CRD",
    name: "Comodoro Rivadavia Intl Airport",
    Address: "Comodoro Rivadavia,ARGENTINA",
  },
  {
    code: "CRK",
    name: "Clark Field Intl Airport",
    Address: "Luzon Island,PHILIPPINES",
  },
  {
    code: "CRP",
    name: "Corpus Christi Intl Airport",
    Address: "Corpus Christi,UNITED STATES",
  },
  { code: "CRV", name: "Crotone Intl Airport", Address: "Crotone,ITALY" },
  {
    code: "CRW",
    name: "Yeager Intl Airport",
    Address: "Charleston,UNITED STATES",
  },
  {
    code: "CRX",
    name: "Roscoe Turner Intl Airport",
    Address: "Corinth,UNITED STATES",
  },
  {
    code: "CRZ",
    name: "Turkmenabad Intl Airport",
    Address: "Turkmenabad,TURKMENISTAN",
  },
  { code: "CSF", name: "Creil Intl Airport", Address: "Creil,FRANCE" },
  {
    code: "CSG",
    name: "Columbus Metro Ft Benning Intl Airport",
    Address: "Columbus,UNITED STATES",
  },
  {
    code: "CSI",
    name: "Casino Intl Airport",
    Address: "Casino,AUSTRALIA",
  },
  {
    code: "SBP",
    name: "San Luis Obispo County Intl Airport",
    Address: "San Luis Obispo,UNITED STATES",
  },
  {
    code: "CSL",
    name: "O Sullivan Army Air Field",
    Address: "San Luis Obispo,UNITED STATES",
  },
  {
    code: "CLK",
    name: "Clinton Municipal Intl Airport",
    Address: "Clinton,UNITED STATES",
  },
  {
    code: "CSM",
    name: "Sherman Intl Airport",
    Address: "Clinton,UNITED STATES",
  },
  {
    code: "CSN",
    name: "Carson Intl Airport",
    Address: "Carson City,UNITED STATES",
  },
  {
    code: "CSU",
    name: "Santa Cruz Do Sul Intl Airport",
    Address: "Santa Cruz Do Sul,BRAZIL",
  },
  {
    code: "CSX",
    name: "Changsha Intl Airport",
    Address: "Changsha,CHINA",
  },
  {
    code: "CSY",
    name: "Cheboksary Intl Airport",
    Address: "Cheboksary,RUSSIA",
  },
  {
    code: "CTA",
    name: "Fontanarossa Intl Airport",
    Address: "Catania,ITALY",
  },
  {
    code: "CTC",
    name: "Choya Intl Airport",
    Address: "Catamarca,ARGENTINA",
  },
  { code: "CTD", name: "Chitre Airfield", Address: "Chitre,PANAMA" },
  {
    code: "CTG",
    name: "Rafael Nunez Intl Airport",
    Address: "Cartagena,COLOMBIA",
  },
  {
    code: "CTL",
    name: "Charleville Intl Airport",
    Address: "Charleville,AUSTRALIA",
  },
  { code: "CTM", name: "Chetumal Intl ", Address: "Chetumal,MEXICO" },
  {
    code: "CTN",
    name: "Cooktown Intl Airport",
    Address: "Cooktown,AUSTRALIA",
  },
  { code: "CTU", name: "Chengdu Intl Airport", Address: "Chengdu,CHINA" },
  {
    code: "CTW",
    name: "Cottonwood Intl Airport",
    Address: "Cottonwood,UNITED STATES",
  },
  {
    code: "CUA",
    name: "Ciudad Constitucion Intl Airport",
    Address: "Ciudad Constitucion,MEXICO",
  },
  {
    code: "CUC",
    name: "Camilo Dazo Intl Airport",
    Address: "Cucuta,COLOMBIA",
  },
  { code: "CUE", name: "Cuenca Intl Airport", Address: "Cuenca,ECUADOR" },
  { code: "CUF", name: "Levaldigi Intl Airport", Address: "Cuneo,ITALY" },
  { code: "CUG", name: "Cudal Intl Airport", Address: "Cudal,AUSTRALIA" },
  {
    code: "CUL",
    name: "Fedl De Bachigualato Intl Airport",
    Address: "Culiacan,MEXICO",
  },
  {
    code: "CUM",
    name: "Antonio Jose De Sucre",
    Address: "Cumana,VENEZUELA",
  },
  {
    code: "CUN",
    name: "Cancun Aeropuerto Internacional",
    Address: "Cancun,MEXICO",
  },
  { code: "CUQ", name: "Coen Intl Airport", Address: "Coen,AUSTRALIA" },
  {
    code: "CUR",
    name: "Areopuerto Hato Intl Airport",
    Address: "Curacao,NETHERLANDS ANTILLES",
  },
  {
    code: "CUS",
    name: "Columbus Municipal",
    Address: "Columbus,UNITED STATES",
  },
  {
    code: "CUU",
    name: "Chihuahua Intl Airport",
    Address: "Chihuahua,MEXICO",
  },
  {
    code: "CUZ",
    name: "Tte Velazco Astete Intl Airport",
    Address: "Cuzco,PERU",
  },
  {
    code: "CVF",
    name: "Courchevel Intl Airport",
    Address: "Courchevel,FRANCE",
  },
  {
    code: "LUK",
    name: "Cincinnati Municipal Intl Airport",
    Address: "Cincinnati,UNITED STATES",
  },
  {
    code: "CVG",
    name: "Cincinnati No Kentucky Intl Airport",
    Address: "Cincinnati,UNITED STATES",
  },
  {
    code: "CVJ",
    name: "Cuernavaca Intl Airport",
    Address: "Cuernavaca,MEXICO",
  },
  {
    code: "CVM",
    name: "Ciudad Victoria Intl Airport",
    Address: "Ciudad Victoria,MEXICO",
  },
  {
    code: "CVN",
    name: "Clovis Intl Airport",
    Address: "Clovis,UNITED STATES",
  },
  {
    code: "QWY",
    name: "Albany Bus Service",
    Address: "Corvallis,UNITED STATES",
  },
  {
    code: "CVO",
    name: "Corvallis Municipal Intl Airport",
    Address: "Corvallis,UNITED STATES",
  },
  {
    code: "CVQ",
    name: "Carnarvon Intl Airport",
    Address: "Carnarvon,AUSTRALIA",
  },
  {
    code: "CVT",
    name: "Baginton Intl Airport",
    Address: "Coventry,UNITED KINGDOM",
  },
  {
    code: "CVU",
    name: "Corvo Intl Airport",
    Address: "Corvo Island,PORTUGAL",
  },
  {
    code: "CWB",
    name: "Afonso Pena Intl Airport",
    Address: "Curitiba,BRAZIL",
  },
  {
    code: "CWC",
    name: "Chernovtsy Intl Airport",
    Address: "Chernovtsy,UKRAINE",
  },
  {
    code: "CWI",
    name: "Clinton Municipal",
    Address: "Clinton,UNITED STATES",
  },
  {
    code: "CWL",
    name: "Cardiff Wales Intl Airport",
    Address: "Cardiff,UNITED KINGDOM",
  },
  { code: "CWT", name: "Cowra Intl Airport", Address: "Cowra,AUSTRALIA" },
  { code: "CXI", name: "Cassidy", Address: "Christmas Island,KIRIBATI" },
  {
    code: "CXJ",
    name: "Campo Dos Bugres Intl Airport",
    Address: "Caxias Do Sul,BRAZIL",
  },
  {
    code: "CXL",
    name: "Calexico Intl Airport",
    Address: "Calexico,UNITED STATES",
  },
  {
    code: "CXO",
    name: "Montgomery Co Intl Airport",
    Address: "Conroe,UNITED STATES",
  },
  {
    code: "CXP",
    name: "Tunggul Wulung Intl Airport",
    Address: "Cilacap,INDONESIA",
  },
  {
    code: "CXR",
    name: "Cam Ranh Intl Airport",
    Address: "Cam Ranh,VIET NAM",
  },
  {
    code: "CYB",
    name: "Gerrard Smith Intl Airport",
    Address: "Cayman Brac,CAYMAN ISLANDS",
  },
  {
    code: "CYO",
    name: "Cayo Largo Del Sur Intl Airport",
    Address: "Cayo Largo Del Sur,CUBA",
  },
  {
    code: "CYR",
    name: "Colonia Intl Airport",
    Address: "Colonia,URUGUAY",
  },
  {
    code: "CYS",
    name: "Cheyenne Intl Airport",
    Address: "Cheyenne,UNITED STATES",
  },
  {
    code: "CZA",
    name: "Chichen Itza Intl Airport",
    Address: "Chichen Itza,MEXICO",
  },
  { code: "CZE", name: "Coro Intl Airport", Address: "Coro,VENEZUELA" },
  {
    code: "CZL",
    name: "Ain El Bey Intl Airport",
    Address: "Constantine,ALGERIA",
  },
  {
    code: "CZM",
    name: "Aeropuerto Intl  De Cozumel",
    Address: "Cozumel,MEXICO",
  },
  {
    code: "CZS",
    name: "Campo Intl Airport",
    Address: "Cruzeiro Do Sul,BRAZIL",
  },
  {
    code: "CZX",
    name: "Changzhou Intl Airport",
    Address: "Changzhou,CHINA",
  },
  {
    code: "DAB",
    name: "Daytona Beach Regional Intl Airport",
    Address: "Daytona Beach,UNITED STATES",
  },
  {
    code: "DAC",
    name: "Hazrat Shahjalal Intl Airport",
    Address: "Dhaka,BANGLADESH",
  },
  {
    code: "DAD",
    name: "Da Nang Intl Airport",
    Address: "Da Nang,VIET NAM",
  },
  {
    code: "DAK",
    name: "Dakhla Intl Airport",
    Address: "Dakhla Oasis,EGYPT",
  },
  { code: "DAM", name: "Damascus Intl ", Address: "Damascus,SYRIA" },
  {
    code: "DAN",
    name: "Danville Municipal",
    Address: "Danville,UNITED STATES",
  },
  {
    code: "DAR",
    name: "Es Salaam Intl ",
    Address: "Dar Es Salaam,TANZANIA",
  },
  { code: "DAT", name: "Datong Intl Airport", Address: "Datong,CHINA" },
  {
    code: "DAV",
    name: "Enrique Malek Intl Airport",
    Address: "David,PANAMA",
  },
  {
    code: "DAY",
    name: "Dayton Intl Airport",
    Address: "Dayton,UNITED STATES",
  },
  {
    code: "DBN",
    name: "Dublin Municipal Intl Airport",
    Address: "Dublin,UNITED STATES",
  },
  { code: "DBO", name: "Dubbo Intl Airport", Address: "Dubbo,AUSTRALIA" },
  {
    code: "DBQ",
    name: "Dubuque Municipal Intl Airport",
    Address: "Dubuque,UNITED STATES",
  },
  {
    code: "DBV",
    name: "Dubrovnik Intl Airport",
    Address: "Dubrovnik,CROATIA",
  },
  { code: "DBY", name: "Dalby", Address: "Dalby,AUSTRALIA" },
  {
    code: "DCM",
    name: "Mazamet Intl Airport",
    Address: "Castres,FRANCE",
  },
  {
    code: "DCU",
    name: "Pyor Intl Airport",
    Address: "Decatur,UNITED STATES",
  },
  {
    code: "DDC",
    name: "Dodge City Municipal Intl Airport",
    Address: "Dodge City,UNITED STATES",
  },
  { code: "DDG", name: "Dandong Intl Airport", Address: "Dandong,CHINA" },
  {
    code: "DDI",
    name: "Daydream Island Intl Airport",
    Address: "Daydream Island,AUSTRALIA",
  },
  {
    code: "DEB",
    name: "Debrecen Intl Airport",
    Address: "Debrecen,HUNGARY",
  },
  {
    code: "DEC",
    name: "Decatur Municipal",
    Address: "Decatur,UNITED STATES",
  },
  {
    code: "DED",
    name: "Jolly Grant Intl Airport",
    Address: "Dehra Dun,INDIA",
  },
  {
    code: "DEH",
    name: "Decorah Municipal",
    Address: "Decorah,UNITED STATES",
  },
  {
    code: "DEL",
    name: "Delhi Indira Gandhi Intl ",
    Address: "Delhi,INDIA",
  },
  {
    code: "APA",
    name: "Centennial Intl Airport",
    Address: "Denver,UNITED STATES",
  },
  {
    code: "DEN",
    name: "Denver Intl Airport",
    Address: "Denver,UNITED STATES",
  },
  {
    code: "QWM",
    name: "Longmont Bus Service",
    Address: "Denver,UNITED STATES",
  },
  {
    code: "DEZ",
    name: "Al Jafrah Intl Airport",
    Address: "Deirezzor,SYRIA",
  },
  {
    code: "DFI",
    name: "Defiance Memorial Intl Airport",
    Address: "Defiance,UNITED STATES",
  },
  {
    code: "AFW",
    name: "Fort Worth Alliance Intl Airport",
    Address: "Dallas,UNITED STATES",
  },
  {
    code: "DFW",
    name: "Dallas Ft Worth Intl ",
    Address: "Dallas,UNITED STATES",
  },
  {
    code: "ADS",
    name: "Addison Intl Airport",
    Address: "Dallas,UNITED STATES",
  },
  { code: "DAL", name: "Love Field", Address: "Dallas,UNITED STATES" },
  {
    code: "DGE",
    name: "Mudgee Intl Airport",
    Address: "Mudgee,AUSTRALIA",
  },
  {
    code: "DGM",
    name: "Dongguan Intl Airport",
    Address: "Dongguan,CHINA",
  },
  {
    code: "DGO",
    name: "Guadalupe Victoria Intl Airport",
    Address: "Durango,MEXICO",
  },
  {
    code: "DGP",
    name: "Daugavpils Intl Airport",
    Address: "Daugavpils,LATVIA",
  },
  {
    code: "DGT",
    name: "Dumaguete Intl Airport",
    Address: "Dumaguete,PHILIPPINES",
  },
  { code: "DHA", name: "Dhahran Intl ", Address: "Dhahran,SAUDI ARABIA" },
  {
    code: "DHM",
    name: "Gaggal Intl Airport",
    Address: "Dharamsala,INDIA",
  },
  {
    code: "DHN",
    name: "Dothan Municipal",
    Address: "Dothan,UNITED STATES",
  },
  {
    code: "DIB",
    name: "Mohanbari Intl Airport",
    Address: "Dibrugarh,INDIA",
  },
  {
    code: "DIE",
    name: "Antsiranana Arrachart Intl Airport",
    Address: "Diegosuarez,MADAGASCAR",
  },
  { code: "DIG", name: "Diging Intl Airport", Address: "Diqing,CHINA" },
  { code: "DIJ", name: "Longvic Intl Airport", Address: "Dijon,FRANCE" },
  {
    code: "DIK",
    name: "Dickinson Municipal",
    Address: "Dickinson,UNITED STATES",
  },
  {
    code: "DIL",
    name: "Comoro Intl Airport",
    Address: "Dili,TIMOR-LESTE",
  },
  {
    code: "DIN",
    name: "Gialam Intl Airport",
    Address: "Dien Bien Phu,VIET NAM",
  },
  {
    code: "DIR",
    name: "Aba Tenna D Yilma Intl Airport",
    Address: "Dire Dawa,ETHIOPIA",
  },
  { code: "DIU", name: "Diu Intl Airport", Address: "Diu In,INDIA" },
  {
    code: "DIY",
    name: "Diyarbakir Intl Airport",
    Address: "Diyarbak\u0131r,TURKEY",
  },
  {
    code: "DJB",
    name: "Sultan Taha Syarifudin Intl Airport",
    Address: "Jambi,INDONESIA",
  },
  { code: "DJE", name: "Melita Intl Airport", Address: "Djerba,TUNISIA" },
  {
    code: "DJJ",
    name: "Sentani Intl Airport",
    Address: "Jayapura,INDONESIA",
  },
  {
    code: "DKI",
    name: "Dunk Island Intl Airport",
    Address: "Dunk Island,AUSTRALIA",
  },
  {
    code: "DKR",
    name: "Leopold Sedar Senghor Intl Airport",
    Address: "Dakar,SENEGAL",
  },
  {
    code: "DKS",
    name: "Dikson Intl Airport",
    Address: "Dikson Intl Airport,RUSSIA",
  },
  {
    code: "DLA",
    name: "Douala Intl Airport",
    Address: "Douala,CAMEROON",
  },
  { code: "DLC", name: "Dalian Intl Airport", Address: "Dalian,CHINA" },
  { code: "DLD", name: "Dagali Intl Airport", Address: "Geilo,NORWAY" },
  { code: "DLE", name: "Tavaux Intl Airport", Address: "Dole,FRANCE" },
  {
    code: "DLG",
    name: "Dillingham Municipal Intl Airport",
    Address: "Dillingham,UNITED STATES",
  },
  { code: "DLH", name: "Duluth Intl ", Address: "Duluth,UNITED STATES" },
  { code: "DLI", name: "Lienkhang", Address: "Dalat,VIET NAM" },
  {
    code: "DLL",
    name: "Dillon Intl Airport",
    Address: "Dillon,UNITED STATES",
  },
  {
    code: "DLM",
    name: "Dalaman Intl Airport",
    Address: "Dalaman,TURKEY",
  },
  {
    code: "DLS",
    name: "The Dalles Municipal Intl Airport",
    Address: "The Dalles,UNITED STATES",
  },
  { code: "DLU", name: "Dali Intl Airport", Address: "Dali City,CHINA" },
  { code: "DMB", name: "ZHAMBYL", Address: "ZHAMBYL,KAZAKHSTAN" },
  {
    code: "DMD",
    name: "Doomadgee Intl Airport",
    Address: "Doomadgee,AUSTRALIA",
  },
  {
    code: "DMM",
    name: "King Fahad Intl Airport",
    Address: "Dammam,SAUDI ARABIA",
  },
  {
    code: "DMO",
    name: "Sedalia Memorial Intl Airport",
    Address: "Sedalia,UNITED STATES",
  },
  {
    code: "ZDU",
    name: "Dundee ScotRail",
    Address: "Dundee,UNITED KINGDOM",
  },
  {
    code: "DND",
    name: "Riverside Park Intl Airport",
    Address: "Dundee,UNITED KINGDOM",
  },
  {
    code: "DNH",
    name: "Dunhuang Intl Airport",
    Address: "Dunhuang,CHINA",
  },
  {
    code: "DNK",
    name: "Dnepropetrovsk Intl Airport",
    Address: "Dnepropetrovsk,UKRAINE",
  },
  {
    code: "DNM",
    name: "Denham Intl Airport",
    Address: "Denham,AUSTRALIA",
  },
  {
    code: "DNQ",
    name: "Denilinquin Intl Airport",
    Address: "Deniliquin,AUSTRALIA",
  },
  {
    code: "DNR",
    name: "Pleurtuit Intl Airport",
    Address: "Dinard,FRANCE",
  },
  {
    code: "DNV",
    name: "Vermillion Cty",
    Address: "Danville,UNITED STATES",
  },
  { code: "DNZ", name: "Cardak Intl Airport", Address: "Denizli,TURKEY" },
  {
    code: "DOC",
    name: "Dornoch Intl Airport",
    Address: "Dornoch,UNITED KINGDOM",
  },
  {
    code: "DOH",
    name: "Hamad International Airport",
    Address: "Doha,QATAR",
  },
  {
    code: "DIA",
    name: "Doha International Airport",
    Address: "Doha,QATAR",
  },
  {
    code: "DOK",
    name: "Donetsk Intl Airport",
    Address: "Donetsk,UKRAINE",
  },
  {
    code: "DOL",
    name: "Saint Gatien Intl Airport",
    Address: "Deauville,FRANCE",
  },
  {
    code: "DCF",
    name: "Canefield Intl Airport",
    Address: "Dominica,DOMINICA",
  },
  {
    code: "DOM",
    name: "Melville Hall Intl Airport",
    Address: "Dominica,DOMINICA",
  },
  {
    code: "DVX",
    name: "Delaware Airpark",
    Address: "Dover,UNITED STATES",
  },
  { code: "DOV", name: "Dover AFB", Address: "Dover,UNITED STATES" },
  { code: "DOX", name: "Dongara", Address: "Dongara,AUSTRALIA" },
  { code: "DPE", name: "Dieppe Intl Airport", Address: "Dieppe,FRANCE" },
  {
    code: "DPL",
    name: "Dipolog Intl Airport",
    Address: "Dipolog,PHILIPPINES",
  },
  {
    code: "DPO",
    name: "Devonport Intl Airport",
    Address: "Devonport,AUSTRALIA",
  },
  {
    code: "DPS",
    name: "Ngurah Rai Intl Airport",
    Address: "Denpasar Bali,INDONESIA",
  },
  { code: "DRB", name: "Derby Intl Airport", Address: "Derby,AUSTRALIA" },
  {
    code: "DRK",
    name: "Drake Bay Intl Airport",
    Address: "Drake Bay Intl Airport,COSTA RICA",
  },
  {
    code: "DRO",
    name: "Durango La Plata Cty Intl Airport",
    Address: "Durango,UNITED STATES",
  },
  {
    code: "AMK",
    name: "Animas Airpark",
    Address: "Durango,UNITED STATES",
  },
  {
    code: "DRS",
    name: "Dresden Intl Airport",
    Address: "Dresden,GERMANY",
  },
  {
    code: "DRT",
    name: " Intl  Del Rio",
    Address: "Del Rio,UNITED STATES",
  },
  {
    code: "DRW",
    name: "Darwin Intl Airport",
    Address: "Darwin,AUSTRALIA",
  },
  {
    code: "DSA",
    name: "Finningley Intl Airport",
    Address: "Doncaster,UNITED KINGDOM",
  },
  {
    code: "DSD",
    name: "La Desirade Intl Airport",
    Address: "La Desirade,GUADELOUPE",
  },
  {
    code: "DSI",
    name: "Destin Intl Airport",
    Address: "Destin,UNITED STATES",
  },
  {
    code: "DSM",
    name: "Des Moines Municipal Intl Airport",
    Address: "Des Moines,UNITED STATES",
  },
  {
    code: "DTM",
    name: "Wickede Dortmund Intl Airport",
    Address: "Dortmund,GERMANY",
  },
  {
    code: "UIZ",
    name: "Berz Macomb Intl Airport",
    Address: "Detroit,UNITED STATES",
  },
  {
    code: "YIP",
    name: "Willow Run Intl Airport",
    Address: "Detroit,UNITED STATES",
  },
  {
    code: "MTC",
    name: "Selfridge Air Natl Guard",
    Address: "Detroit,UNITED STATES",
  },
  {
    code: "DET",
    name: "Detroit City Apt",
    Address: "Detroit,UNITED STATES",
  },
  {
    code: "DTW",
    name: "Detroit Metro Wayne Cnty Intl Airport",
    Address: "Detroit,UNITED STATES",
  },
  { code: "DUB", name: "Dublin Intl Airport", Address: "Dublin,IRELAND" },
  {
    code: "DUC",
    name: "Halliburton Intl Airport",
    Address: "Duncan,UNITED STATES",
  },
  {
    code: "DUD",
    name: "Momona Intl Airport",
    Address: "Dunedin,NEW ZEALAND",
  },
  {
    code: "DUG",
    name: "Bisbee Douglas Intl ",
    Address: "Douglas,UNITED STATES",
  },
  {
    code: "DUJ",
    name: "Dubois Jefferson Cty Intl Airport",
    Address: "Dubois,UNITED STATES",
  },
  {
    code: "DUQ",
    name: "Duncan/Quam Rail Station",
    Address: "Duncan,CANADA",
  },
  { code: "DUR", name: "Durban Intl ", Address: "Durban,SOUTH AFRICA" },
  {
    code: "VIR",
    name: "Virginia Intl Airport",
    Address: "Durban,SOUTH AFRICA",
  },
  {
    code: "NRN",
    name: "Niederrhein Intl Airport",
    Address: "Dusseldorf,GERMANY",
  },
  {
    code: "QDU",
    name: "Dusseldorf Rail Station",
    Address: "Dusseldorf,GERMANY",
  },
  {
    code: "DUS",
    name: "Dusseldorf Intl Airport",
    Address: "Dusseldorf,GERMANY",
  },
  {
    code: "MGL",
    name: "Moenchen Gi Dus Exp",
    Address: "Dusseldorf,GERMANY",
  },
  {
    code: "DUT",
    name: "Emergency Field",
    Address: "Dutch Harbor,UNITED STATES",
  },
  {
    code: "DVL",
    name: "Devils Lake Intl Airport",
    Address: "Devils Lake,UNITED STATES",
  },
  {
    code: "DVN",
    name: "Davenport Intl Airport",
    Address: "Davenport,UNITED STATES",
  },
  {
    code: "DVO",
    name: "Mati Intl Airport",
    Address: "Davao,PHILIPPINES",
  },
  {
    code: "DWD",
    name: "Dawadmi Intl Airport",
    Address: "Dawadmi Intl Airport,SAUDI ARABIA",
  },
  {
    code: "SHJ",
    name: "Sharjah Intl Airport",
    Address: "Sharjah,UNITED ARAB EMIRATES",
  },
  {
    code: "XNB",
    name: "Dubai Bus Station Intl Airport",
    Address: "Dubai,UNITED ARAB EMIRATES",
  },
  {
    code: "DXB",
    name: "Dubai Intl Airport",
    Address: "Dubai,UNITED ARAB EMIRATES",
  },
  {
    code: "DXR",
    name: "Danbury Municipal Intl Airport",
    Address: "Danbury,UNITED STATES",
  },
  { code: "DYG", name: "Dayong Intl Airport", Address: "Dayong,CHINA" },
  {
    code: "DYL",
    name: "Doylestown Intl Airport",
    Address: "Doylestown,UNITED STATES",
  },
  { code: "DYR", name: "Anadyr Intl Airport", Address: "Anadyr,RUSSIA" },
  {
    code: "DYU",
    name: "Dushanbe Intl Airport",
    Address: "Dushanbe,TAJIKISTAN",
  },
  {
    code: "DZA",
    name: "Dzaoudzi Intl Airport",
    Address: "Dzaoudzi,MAYOTTE",
  },
  { code: "DZN", name: "ZHEZKAZGAN", Address: "ZHEZKAZGAN,KAZAKHSTAN" },
  {
    code: "EAA",
    name: "Eagle Intl Airport",
    Address: "Eagle,UNITED STATES",
  },
  {
    code: "EAM",
    name: "Nejran Intl Airport",
    Address: "Nejran,SAUDI ARABIA",
  },
  { code: "MHL", name: "Mulhouse", Address: "Mulhouse Basel,FRANCE" },
  {
    code: "MLH",
    name: "Mulhouse Euro Intl Airport  French",
    Address: "Mulhouse Basel,FRANCE",
  },
  {
    code: "ZBA",
    name: "Basel/Mullhouse Rail Service",
    Address: "Mulhouse Basel,FRANCE",
  },
  {
    code: "ZDH",
    name: "Basel/Mullhouse SBB Rail Service",
    Address: "Mulhouse Basel,FRANCE",
  },
  {
    code: "EAR",
    name: "Kearney Municipal Arrpt",
    Address: "Kearney,UNITED STATES",
  },
  {
    code: "EAS",
    name: "San Sebastian Intl Airport",
    Address: "San Sebastian,SPAIN",
  },
  {
    code: "EAT",
    name: "Pangborn Memorial Fld",
    Address: "Wenatchee,UNITED STATES",
  },
  {
    code: "EAU",
    name: "Claire Municipal Intl Airport",
    Address: "Eau Claire,UNITED STATES",
  },
  {
    code: "EBA",
    name: "Marina Di Campo Intl Airport",
    Address: "Elba Island,ITALY",
  },
  {
    code: "EBB",
    name: "Entebbe Intl Airport",
    Address: "Entebbe,UGANDA",
  },
  {
    code: "EBJ",
    name: "Esbjerg Intl Airport",
    Address: "Esbjerg,DENMARK",
  },
  { code: "EBL", name: "Erbil Intl Airport", Address: "Erbil,IRAQ" },
  {
    code: "EBO",
    name: "Ebon Intl Airport",
    Address: "Ebon,MARSHALL ISLANDS",
  },
  {
    code: "EBU",
    name: "Boutheon Intl Airport",
    Address: "St Etienne,FRANCE",
  },
  {
    code: "ECG",
    name: "Elizabeth Municipal Cgas",
    Address: "Elizabeth City,UNITED STATES",
  },
  {
    code: "ECH",
    name: "Echuca Intl Airport",
    Address: "Echuca,AUSTRALIA",
  },
  { code: "ECN", name: "Ercan Intl Airport", Address: "Ercan,CYPRUS" },
  {
    code: "EDE",
    name: "Edenton Municipal Intl Airport",
    Address: "Edenton,UNITED STATES",
  },
  {
    code: "ZXE",
    name: "Edinburgh ScotRail",
    Address: "Edinburgh,UNITED KINGDOM",
  },
  {
    code: "EDI",
    name: "Edinburgh Intl Airport",
    Address: "Edinburgh,UNITED KINGDOM",
  },
  { code: "EDL", name: "Eldoret Intl Airport", Address: "Eldoret,KENYA" },
  {
    code: "EDM",
    name: "Les Ajoncs Intl Airport",
    Address: "La Roche,FRANCE",
  },
  {
    code: "EDO",
    name: "Edremit Intl Airport",
    Address: "Edremit,TURKEY",
  },
  {
    code: "EDR",
    name: "Edward River Intl Airport",
    Address: "Edward River,AUSTRALIA",
  },
  {
    code: "EED",
    name: "Needles Intl Airport",
    Address: "Needles,UNITED STATES",
  },
  {
    code: "EEN",
    name: "Dilliant Hopkins Intl Airport",
    Address: "Keene,UNITED STATES",
  },
  {
    code: "EFL",
    name: "Argostoli Intl Airport",
    Address: "Kefalonia,GREECE",
  },
  {
    code: "EGC",
    name: "Roumaniere Intl Airport",
    Address: "Bergerac,FRANCE",
  },
  {
    code: "ZBV",
    name: "Beaver Creek Van Service",
    Address: "Eagle,UNITED STATES",
  },
  {
    code: "QBF",
    name: "Vail Van Service",
    Address: "Eagle,UNITED STATES",
  },
  {
    code: "EGE",
    name: "Eagle County Intl Airport",
    Address: "Eagle,UNITED STATES",
  },
  {
    code: "EGM",
    name: "Sege Intl Airport",
    Address: "Sege,SOLOMON ISLANDS",
  },
  { code: "EGN", name: "Geneina Intl Airport", Address: "Geneina,SUDAN" },
  {
    code: "EGO",
    name: "Belgorod Intl Airport",
    Address: "Belgorod,RUSSIA",
  },
  {
    code: "EGP",
    name: "Maverick County Intl Airport",
    Address: "Eagle Pass,UNITED STATES",
  },
  {
    code: "EGS",
    name: "Egilsstadir Intl Airport",
    Address: "Egilsstadir,ICELAND",
  },
  {
    code: "EGV",
    name: "Eagle River Union Intl Airport",
    Address: "Eagle River,UNITED STATES",
  },
  {
    code: "EHL",
    name: "El Bolson Intl Airport",
    Address: "El Bolson,ARGENTINA",
  },
  {
    code: "EHT",
    name: "Rentschler Intl Airport",
    Address: "East Hartford,UNITED STATES",
  },
  {
    code: "EIB",
    name: "Eisenach Intl Airport",
    Address: "Eisenach,GERMANY",
  },
  {
    code: "EIE",
    name: "Eniseysk Intl Airport",
    Address: "Eniseysk,RUSSIA",
  },
  {
    code: "ZYE",
    name: "Eindhoven Rail Station",
    Address: "Eindhoven,NETHERLANDS",
  },
  {
    code: "EIN",
    name: "Welschap Intl Airport",
    Address: "Eindhoven,NETHERLANDS",
  },
  {
    code: "EIS",
    name: "Beef Island Intl Airport",
    Address: "Beef Island,VIRGIN ISLANDS, BRITISH",
  },
  {
    code: "EJA",
    name: "Variguies Intl Airport",
    Address: "Barrancabermeja,COLOMBIA",
  },
  {
    code: "EKI",
    name: "Elkhart Municipal Intl Airport",
    Address: "Elkhart,UNITED STATES",
  },
  {
    code: "EKN",
    name: "Randolph County Intl Airport",
    Address: "Elkins,UNITED STATES",
  },
  {
    code: "EKO",
    name: "J C Harris Field",
    Address: "Elko,UNITED STATES",
  },
  {
    code: "EKT",
    name: "Eskilstuna Intl Airport",
    Address: "Eskilstuna,SWEDEN",
  },
  {
    code: "XFJ",
    name: "Eskilstuna Rail Station",
    Address: "Eskilstuna,SWEDEN",
  },
  {
    code: "EKX",
    name: "Addington Field",
    Address: "Elizabethtown,UNITED STATES",
  },
  {
    code: "ELD",
    name: "Goodwin Field",
    Address: "El Dorado,UNITED STATES",
  },
  {
    code: "ELH",
    name: "North Eleuthera Intl ",
    Address: "North Eleuthera,BAHAMAS",
  },
  {
    code: "ELK",
    name: "Elk City Municipal",
    Address: "Elk City,UNITED STATES",
  },
  {
    code: "ELM",
    name: "Elmira Corning Regional Intl Airport",
    Address: "Elmira,UNITED STATES",
  },
  {
    code: "ELP",
    name: "El Paso Intl Airport",
    Address: "El Paso,UNITED STATES",
  },
  {
    code: "BIF",
    name: "Biggs Army Air Field",
    Address: "El Paso,UNITED STATES",
  },
  {
    code: "ELQ",
    name: "Gassim Intl Airport",
    Address: "Gassim,SAUDI ARABIA",
  },
  {
    code: "ELS",
    name: "East London Intl Airport",
    Address: "East London,SOUTH AFRICA",
  },
  { code: "ELY", name: "Yelland Field", Address: "Ely NV,UNITED STATES" },
  {
    code: "EMD",
    name: "Emerald Intl Airport",
    Address: "Emerald,AUSTRALIA",
  },
  {
    code: "EMT",
    name: "El Monte Intl Airport",
    Address: "El Monte,UNITED STATES",
  },
  {
    code: "ENA",
    name: "Kenai Municipal Intl Airport",
    Address: "Kenai,UNITED STATES",
  },
  { code: "ENC", name: "Essey Intl Airport", Address: "Nancy,FRANCE" },
  { code: "ENH", name: "Enshi Intl Airport", Address: "Enshi,CHINA" },
  {
    code: "ENK",
    name: "St Angelo Intl Airport",
    Address: "Enniskillen,IRELAND",
  },
  {
    code: "ENL",
    name: "Centralia Municipal Intl Airport",
    Address: "Centralia,UNITED STATES",
  },
  {
    code: "ENS",
    name: "Twente Intl Airport",
    Address: "Enschede,NETHERLANDS",
  },
  {
    code: "ENW",
    name: "Kenosha Municipal Intl Airport",
    Address: "Kenosha,UNITED STATES",
  },
  { code: "ENY", name: "Yanan Intl Airport", Address: "Yanan,CHINA" },
  {
    code: "EOI",
    name: "Eday Intl Airport",
    Address: "Eday,UNITED KINGDOM",
  },
  {
    code: "EOK",
    name: "Keokuk Intl Airport",
    Address: "Keokuk,UNITED STATES",
  },
  {
    code: "EPL",
    name: "Mirecourt Intl Airport",
    Address: "Epinal,FRANCE",
  },
  {
    code: "EPR",
    name: "Esperance Intl Airport",
    Address: "Esperance,AUSTRALIA",
  },
  {
    code: "EPS",
    name: "El Portillo Intl Airport",
    Address: "El Portillo Samana,DOMINICAN REPUBLIC",
  },
  { code: "EPU", name: "Parnu Intl Airport", Address: "Parnu,ESTONIA" },
  {
    code: "EQS",
    name: "Esquel Intl Airport",
    Address: "Esquel,ARGENTINA",
  },
  {
    code: "ERC",
    name: "Erzincan Intl Airport",
    Address: "Erzincan,TURKEY",
  },
  { code: "ERF", name: "Erfurt Intl Airport", Address: "Erfurt,GERMANY" },
  {
    code: "ERH",
    name: "Errachidia Intl Airport",
    Address: "Errachidia,MOROCCO",
  },
  { code: "ERI", name: "Erie Intl ", Address: "Erie,UNITED STATES" },
  {
    code: "ERV",
    name: "Kerrville Intl Airport",
    Address: "Kerrville,UNITED STATES",
  },
  {
    code: "ERZ",
    name: "Erzurum Intl Airport",
    Address: "Erzurum,TURKEY",
  },
  {
    code: "ESC",
    name: "Delta County",
    Address: "Escanaba,UNITED STATES",
  },
  {
    code: "ESD",
    name: "Eastsound Orcas Is Intl Airport",
    Address: "East Sound,UNITED STATES",
  },
  {
    code: "ESE",
    name: "Ensenada Intl Airport",
    Address: "Ensenada,MEXICO",
  },
  {
    code: "ESK",
    name: "Eskisehir Intl Airport",
    Address: "Eskisehir Intl Airport,TURKEY",
  },
  { code: "ESL", name: "Elista Intl Airport", Address: "Elista,RUSSIA" },
  {
    code: "ESN",
    name: "Easton Municipal Intl Airport",
    Address: "Easton,UNITED STATES",
  },
  {
    code: "ESP",
    name: "Birchwood Pocono Intl Airport",
    Address: "East Stroudsburg,UNITED STATES",
  },
  {
    code: "ESR",
    name: "El Salvador Intl Airport",
    Address: "El Salvador,CHILE",
  },
  { code: "ESS", name: "Essen Intl Airport", Address: "Essen,GERMANY" },
  {
    code: "ESU",
    name: "Essaouira Intl Airport",
    Address: "Essaouira,MOROCCO",
  },
  {
    code: "ETB",
    name: "West Bend Intl Airport",
    Address: "West Bend,UNITED STATES",
  },
  { code: "ETH", name: "Elat Intl Airport", Address: "Elat,ISRAEL" },
  {
    code: "ETS",
    name: "Enterprise Municipal",
    Address: "Enterprise,UNITED STATES",
  },
  {
    code: "ETZ",
    name: "Metz Nancy Lorraine",
    Address: "Metz Nancy,FRANCE",
  },
  {
    code: "XZI",
    name: "Railway Station Metz",
    Address: "Metz Nancy,FRANCE",
  },
  { code: "EUF", name: "Weedon Field", Address: "Eufaula,UNITED STATES" },
  {
    code: "EUG",
    name: "Eugene Intl Airport",
    Address: "Eugene,UNITED STATES",
  },
  {
    code: "EUN",
    name: "Hassan I Intl Airport",
    Address: "El Aaiun,MOROCCO",
  },
  {
    code: "EUX",
    name: "Roosevelt Field",
    Address: "St Eustatius,NETHERLANDS ANTILLES",
  },
  {
    code: "EVE",
    name: "Evenes Intl Airport",
    Address: "Harstad Narvik,NORWAY",
  },
  { code: "EVG", name: "Sveg Intl Airport", Address: "Sveg,SWEDEN" },
  {
    code: "EVM",
    name: "Eveleth Virginia Municipal Intl Airport",
    Address: "Eveleth,UNITED STATES",
  },
  {
    code: "EVN",
    name: "Yerevan Intl Airport",
    Address: "Yerevan,ARMENIA",
  },
  {
    code: "EVV",
    name: "Evansville Regional Intl Airport",
    Address: "Evansville,UNITED STATES",
  },
  {
    code: "EVW",
    name: "Evanston Intl Airport",
    Address: "Evanston,UNITED STATES",
  },
  { code: "EVX", name: "Evreux Intl Airport", Address: "Evreux,FRANCE" },
  {
    code: "EWB",
    name: "New Bedford Municipal",
    Address: "New Bedford,UNITED STATES",
  },
  {
    code: "EWK",
    name: "Newton City County Intl Airport",
    Address: "Newton,UNITED STATES",
  },
  {
    code: "EWN",
    name: "Craven County Regional Intl Airport",
    Address: "New Bern,UNITED STATES",
  },
  {
    code: "EWR",
    name: "Newark Liberty Intl Airport",
    Address: "Newark,UNITED STATES",
  },
  {
    code: "EXI",
    name: "Excursion Inlet Municipal",
    Address: "Excursion Inlet,UNITED STATES",
  },
  {
    code: "EXM",
    name: "Exmouth Gulf",
    Address: "Exmouth Gulf,AUSTRALIA",
  },
  {
    code: "EXT",
    name: "Exeter Intl Airport",
    Address: "Exeter,UNITED KINGDOM",
  },
  {
    code: "EYW",
    name: "Key West Intl ",
    Address: "Key West,UNITED STATES",
  },
  { code: "EZS", name: "Elazig Intl Airport", Address: "Elazig,TURKEY" },
  {
    code: "FAB",
    name: "Farnborough Intl Airport",
    Address: "Farnborough Hampshire,UNITED KINGDOM",
  },
  {
    code: "FAE",
    name: "Faeroe Intl Airport",
    Address: "Faeroe Islands,DENMARK",
  },
  {
    code: "FAI",
    name: "Fairbanks Intl Airport",
    Address: "Fairbanks,UNITED STATES",
  },
  {
    code: "FAJ",
    name: "Fajardo Intl Airport",
    Address: "Fajardo,UNITED STATES",
  },
  { code: "FAO", name: "Faro Intl Airport", Address: "Faro,PORTUGAL" },
  {
    code: "FAR",
    name: "Hector Intl Airport",
    Address: "Fargo,UNITED STATES",
  },
  {
    code: "FAT",
    name: "Fresno Air Terminal",
    Address: "Fresno,UNITED STATES",
  },
  {
    code: "FCH",
    name: "Fresno Chandler Intl Airport",
    Address: "Fresno,UNITED STATES",
  },
  {
    code: "FAY",
    name: "Fayetteville Municipal",
    Address: "Fayetteville,UNITED STATES",
  },
  {
    code: "FBD",
    name: "Faizabad Intl Airport",
    Address: "Faizabad,AFGHANISTAN",
  },
  {
    code: "FBM",
    name: "Luano",
    Address: "Lubumbashi,CONGO, THE DEMOCRATIC REPUBLIC OF THE",
  },
  {
    code: "FCA",
    name: "Glacier Park Intl ",
    Address: "Kalispell,UNITED STATES",
  },
  {
    code: "FCB",
    name: "Ficksburg Sentra Oes",
    Address: "Ficksburg,SOUTH AFRICA",
  },
  {
    code: "FCN",
    name: "Cuxhaven Nordholz Intl Airport",
    Address: "Cuxhaven,GERMANY",
  },
  {
    code: "FCY",
    name: "Forrest City Municipal Intl Airport",
    Address: "Forrest City,UNITED STATES",
  },
  {
    code: "FDE",
    name: "Bringeland Intl Airport",
    Address: "Forde,NORWAY",
  },
  {
    code: "FDF",
    name: "Lamentin Intl Airport",
    Address: "Ft De France,MARTINIQUE",
  },
  {
    code: "FDH",
    name: "Friedrichshafen Lowenthal",
    Address: "Friedrichshafen,GERMANY",
  },
  {
    code: "FDK",
    name: "Frederick Municipal",
    Address: "Frederick,UNITED STATES",
  },
  { code: "FEG", name: "Fergana", Address: "Fergana,UZBEKISTAN" },
  {
    code: "FEN",
    name: "Fernando De Noronha Intl Airport",
    Address: "Fernando De Noronha,BRAZIL",
  },
  { code: "FEZ", name: "Fez Intl Airport", Address: "Fez Ma,MOROCCO" },
  {
    code: "FFM",
    name: "Fergus Falls Municipal",
    Address: "Fergus Falls,UNITED STATES",
  },
  {
    code: "FHU",
    name: "Sierra Vista Municipal",
    Address: "Ft Huachuca,UNITED STATES",
  },
  {
    code: "FIE",
    name: "Fair Isle Intl Airport",
    Address: "Fair Isle,UNITED KINGDOM",
  },
  {
    code: "FIH",
    name: "Kinshasa Intl Airport",
    Address: "Kinshasa,CONGO, THE DEMOCRATIC REPUBLIC OF THE",
  },
  {
    code: "NLO",
    name: "N Dolo Intl Airport",
    Address: "Kinshasa,CONGO, THE DEMOCRATIC REPUBLIC OF THE",
  },
  {
    code: "FJR",
    name: "Fujairah Intl Airport",
    Address: "Al Fujairah,UNITED ARAB EMIRATES",
  },
  {
    code: "FKB",
    name: "Soellingen Intl Airport",
    Address: "Karlsruhe Baden Baden,GERMANY",
  },
  {
    code: "FKL",
    name: "Chess Lamberton Intl Airport",
    Address: "Franklin,UNITED STATES",
  },
  {
    code: "FKS",
    name: "Fukushima Intl Airport",
    Address: "Fukushima,JAPAN",
  },
  {
    code: "FLB",
    name: "Cangapara Intl Airport",
    Address: "Floriano,BRAZIL",
  },
  {
    code: "FLC",
    name: "Falls Creek Intl Airport",
    Address: "Falls Creek,AUSTRALIA",
  },
  {
    code: "FLF",
    name: "Schaferhaus Intl Airport",
    Address: "Flensburg,GERMANY",
  },
  {
    code: "FLG",
    name: "Flagstaff Intl Airport",
    Address: "Flagstaff,UNITED STATES",
  },
  {
    code: "FXE",
    name: "Ft Lauderdale Excutive",
    Address: "Ft Lauderdale,UNITED STATES",
  },
  {
    code: "FLL",
    name: "Ft Lauderdale Hollywood Intl Airport",
    Address: "Ft Lauderdale,UNITED STATES",
  },
  {
    code: "BCT",
    name: "Boca Raton Public",
    Address: "Ft Lauderdale,UNITED STATES",
  },
  {
    code: "FLN",
    name: "Hercilio Luz Intl Airport",
    Address: "Florianopolis,BRAZIL",
  },
  {
    code: "FLO",
    name: "Gilbert Field",
    Address: "Florence,UNITED STATES",
  },
  {
    code: "FLP",
    name: "Flippin Intl Airport",
    Address: "Flippin,UNITED STATES",
  },
  {
    code: "FLR",
    name: "Amerigo Vespucci Intl Airport",
    Address: "Florence,ITALY",
  },
  {
    code: "FLW",
    name: "Aerodromo Das Flores",
    Address: "Santa Cruz Flores,PORTUGAL",
  },
  {
    code: "FMA",
    name: "El Pucu Intl Airport",
    Address: "Formosa,ARGENTINA",
  },
  { code: "FMH", name: "Otis AFB", Address: "Falmouth,UNITED STATES" },
  {
    code: "FMM",
    name: "Allgaeu Intl Airport",
    Address: "Memmingen Allgaeu,GERMANY",
  },
  {
    code: "FMN",
    name: "Four Corners Regional Intl Airport",
    Address: "Farmington,UNITED STATES",
  },
  {
    code: "FMO",
    name: "Muenster Intl Airport",
    Address: "Muenster,GERMANY",
  },
  {
    code: "FMS",
    name: "Fort Madison Municipal",
    Address: "Fort Madison,UNITED STATES",
  },
  {
    code: "RSW",
    name: "Regional Southwest Intl Airport",
    Address: "Fort Myers,UNITED STATES",
  },
  {
    code: "FNA",
    name: "Lungi Intl Airport",
    Address: "Freetown,SIERRA LEONE",
  },
  {
    code: "FNC",
    name: "Madeira Intl Airport",
    Address: "Funchal,PORTUGAL",
  },
  { code: "FNI", name: "Nimes Intl Airport", Address: "Nimes,FRANCE" },
  {
    code: "FNJ",
    name: "Sunan Intl Airport",
    Address: "Pyongyang,KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
  },
  {
    code: "QWF",
    name: "Fort Collins Bus Service",
    Address: "Ft Collins,UNITED STATES",
  },
  {
    code: "FNL",
    name: "Municipal Intl Airport",
    Address: "Ft Collins,UNITED STATES",
  },
  {
    code: "QWH",
    name: "Loveland Bus Service",
    Address: "Ft Collins,UNITED STATES",
  },
  {
    code: "FNT",
    name: "Bishop Intl Airport",
    Address: "Flint,UNITED STATES",
  },
  {
    code: "FOB",
    name: "Fort Bragg Intl Airport",
    Address: "Fort Bragg,UNITED STATES",
  },
  { code: "FOC", name: "Fuzhou Intl Airport", Address: "Fuzhou,CHINA" },
  {
    code: "FOD",
    name: "Ft Dodge Municipal",
    Address: "Fort Dodge,UNITED STATES",
  },
  {
    code: "FOG",
    name: "Gino Lisa Intl Airport",
    Address: "Foggia,ITALY",
  },
  {
    code: "FOK",
    name: "Suffolk County Intl Airport",
    Address: "Westhampton,UNITED STATES",
  },
  {
    code: "FOR",
    name: "Pinto Martines Intl Airport",
    Address: "Fortaleza,BRAZIL",
  },
  { code: "FOT", name: "Forster", Address: "Forster,AUSTRALIA" },
  {
    code: "FPO",
    name: "Grand Bahama Intl Airport",
    Address: "Freeport,BAHAMAS",
  },
  {
    code: "FPR",
    name: "St Lucie County Intl Airport",
    Address: "Fort Pierce,UNITED STATES",
  },
  {
    code: "HHN",
    name: "Hahn Intl Airport",
    Address: "Frankfurt,GERMANY",
  },
  {
    code: "QGV",
    name: "Neu Isenburg Intl Airport",
    Address: "Frankfurt,GERMANY",
  },
  {
    code: "ZRB",
    name: "Frankfurt HBF Railway Service",
    Address: "Frankfurt,GERMANY",
  },
  {
    code: "FRF",
    name: "Rhein-Main Air Force Base",
    Address: "Frankfurt,GERMANY",
  },
  { code: "FRA", name: "Frankfurt Intl ", Address: "Frankfurt,GERMANY" },
  {
    code: "FRD",
    name: "Friday Harbor Intl Airport",
    Address: "Friday Harbor,UNITED STATES",
  },
  {
    code: "FRG",
    name: "Republic Intl Airport",
    Address: "Farmingdale,UNITED STATES",
  },
  {
    code: "FRH",
    name: "French Lick Municipal Intl Airport",
    Address: "French Lick,UNITED STATES",
  },
  { code: "FRJ", name: "Frejus Intl Airport", Address: "Frejus,FRANCE" },
  {
    code: "FRL",
    name: "Luigi Ridolfi Intl Airport",
    Address: "Forli,ITALY",
  },
  {
    code: "FRM",
    name: "Fairmont Municipal",
    Address: "Fairmont,UNITED STATES",
  },
  { code: "FRO", name: "Flora Intl Airport", Address: "Floro,NORWAY" },
  {
    code: "FRR",
    name: "Warren County Intl Airport",
    Address: "Front Royal,UNITED STATES",
  },
  {
    code: "FRS",
    name: "Flores Intl Airport",
    Address: "Flores,GUATEMALA",
  },
  {
    code: "FRU",
    name: "Bishkek Intl Airport",
    Address: "Bishkek,KYRGYZSTAN",
  },
  {
    code: "FRW",
    name: "Francistown Intl Airport",
    Address: "Francistown,BOTSWANA",
  },
  { code: "FRZ", name: "Fritzlar Airbase", Address: "Fritzlar,GERMANY" },
  {
    code: "FSC",
    name: "Sud Corse Intl Airport",
    Address: "Figari,FRANCE",
  },
  {
    code: "FSD",
    name: "Regional Joe Foss Field",
    Address: "Sioux Falls,UNITED STATES",
  },
  {
    code: "FSM",
    name: "Ft Smith Municipal",
    Address: "Ft Smith,UNITED STATES",
  },
  {
    code: "FSP",
    name: "St Pierre Intl Airport",
    Address: "St Pierre,CANADA",
  },
  {
    code: "FST",
    name: "Pecos County Intl Airport",
    Address: "Fort Stockton,UNITED STATES",
  },
  {
    code: "FTE",
    name: "El Calafate Intl Airport",
    Address: "El Calafate,ARGENTINA",
  },
  {
    code: "FTU",
    name: "Marillac Intl Airport",
    Address: "Fort Dauphin,MADAGASCAR",
  },
  {
    code: "FTW",
    name: "Meacham Field",
    Address: "Ft Worth,UNITED STATES",
  },
  {
    code: "FUE",
    name: "Fuerteventura Intl Airport",
    Address: "Puerto Del Rosario,SPAIN",
  },
  { code: "FUG", name: "Fuyang Aprt", Address: "Fuyang,CHINA" },
  { code: "FUJ", name: "Fukue Intl Airport", Address: "Fukue,JAPAN" },
  { code: "FUK", name: "Itazuke Intl Airport", Address: "Fukuoka,JAPAN" },
  {
    code: "FUL",
    name: "Fullerton Municipal Intl Airport",
    Address: "Fullerton,UNITED STATES",
  },
  {
    code: "FUN",
    name: "Funafuti Intl Airport",
    Address: "Funafuti,TUVALU",
  },
  { code: "FWA", name: "Baer Field", Address: "Ft Wayne,UNITED STATES" },
  {
    code: "FWM",
    name: "Fort William Intl Airport",
    Address: "Fort William,UNITED KINGDOM",
  },
  {
    code: "FYV",
    name: "Fayetteville Municipal Intl Airport",
    Address: "Fayetteville,UNITED STATES",
  },
  {
    code: "XNA",
    name: "Northwest Arkansas Regional Intl Airport",
    Address: "Fayetteville,UNITED STATES",
  },
  {
    code: "GAD",
    name: "Gadsden Municipal",
    Address: "Gadsden,UNITED STATES",
  },
  {
    code: "GAI",
    name: "Montgomery Cty Intl Airport",
    Address: "Gaithersburg,UNITED STATES",
  },
  {
    code: "GAJ",
    name: "Yamagata Intl Airport",
    Address: "Yamagata,JAPAN",
  },
  {
    code: "GAL",
    name: "Galena Intl Airport",
    Address: "Galena,UNITED STATES",
  },
  {
    code: "GAN",
    name: "Gan Intl Airport",
    Address: "Gan Island,MALDIVES",
  },
  {
    code: "GAR",
    name: "Garaina Intl Airport",
    Address: "Garaina,PAPUA NEW GUINEA",
  },
  {
    code: "GAT",
    name: "Tallard Intl Airport",
    Address: "Gap France,FRANCE",
  },
  {
    code: "GAU",
    name: "Lokpriya Gopinath Bordoloi Intl Airport",
    Address: "Guwahati,INDIA",
  },
  { code: "GAY", name: "Gaya Intl Airport", Address: "Gaya,INDIA" },
  {
    code: "GBD",
    name: "Greate Bend Municipal",
    Address: "Great Bend,UNITED STATES",
  },
  {
    code: "GBE",
    name: "Gaborone Intl Airport",
    Address: "Gaborone,BOTSWANA",
  },
  {
    code: "GBG",
    name: "Galesburg Intl Airport",
    Address: "Galesburg,UNITED STATES",
  },
  {
    code: "GBK",
    name: "Gbangbatok Intl Airport",
    Address: "Gbangbatok,SIERRA LEONE",
  },
  {
    code: "GBN",
    name: "San Giovanni Rotondo Intl Airport",
    Address: "San Giovanni Rotondo,ITALY",
  },
  {
    code: "GBQ",
    name: "Muharraq Intl Airport",
    Address: "Muharraq Town,BAHRAIN",
  },
  {
    code: "GBR",
    name: "Great Barrington Intl Airport",
    Address: "Great Barrington,UNITED STATES",
  },
  {
    code: "GBZ",
    name: "Great Barrier Island Intl Airport",
    Address: "Great Barrier Island,NEW ZEALAND",
  },
  {
    code: "GCC",
    name: "Campbell Cty Intl Airport",
    Address: "Gillette,UNITED STATES",
  },
  {
    code: "GCI",
    name: "Guernsey Intl Airport",
    Address: "Guernsey,UNITED KINGDOM",
  },
  {
    code: "GCK",
    name: "Garden City Municipal",
    Address: "Garden City,UNITED STATES",
  },
  {
    code: "GCM",
    name: "Owen Roberts Intl Airport",
    Address: "Grand Cayman Island,CAYMAN ISLANDS",
  },
  {
    code: "GCN",
    name: "Grand Canyon Natl Park Intl Airport",
    Address: "Grand Canyon,UNITED STATES",
  },
  {
    code: "GCV",
    name: "Gravatai Intl Airport",
    Address: "Gravatai,BRAZIL",
  },
  {
    code: "GCY",
    name: "Municipal Greeneville Intl Airport",
    Address: "Greeneville,UNITED STATES",
  },
  {
    code: "GDG",
    name: "Magdagachi Intl Airport",
    Address: "Magdagachi Intl Airport,RUSSIA",
  },
  {
    code: "GDL",
    name: "Miguel Hidalgo Intl Airport",
    Address: "Guadalajara,MEXICO",
  },
  { code: "GDN", name: "Rebiechowo", Address: "Gdansk,POLAND" },
  {
    code: "GDT",
    name: "Grand Turk Is Intl Airport",
    Address: "Grand Turk,TURKS AND CAICOS ISLANDS",
  },
  {
    code: "GDX",
    name: "Magadan Intl Airport",
    Address: "Magadan,RUSSIA",
  },
  {
    code: "GDZ",
    name: "Gelendzhik Intl Airport .",
    Address: "Gelendzhik Intl Airport .,RUSSIA",
  },
  {
    code: "GED",
    name: "Sussex County Intl Airport",
    Address: "Georgetown,UNITED STATES",
  },
  { code: "SFF", name: "Felts Field", Address: "Spokane,UNITED STATES" },
  {
    code: "GEG",
    name: "Spokane Intl Airport",
    Address: "Spokane,UNITED STATES",
  },
  {
    code: "GEO",
    name: "Cheddi Jagan Intl ",
    Address: "Georgetown,GUYANA",
  },
  {
    code: "GER",
    name: "Rafael Cabrera Intl Airport",
    Address: "Nueva Gerona,CUBA",
  },
  {
    code: "GES",
    name: "Buayan Intl Airport",
    Address: "General Santos,PHILIPPINES",
  },
  {
    code: "GET",
    name: "Geraldton Intl Airport",
    Address: "Geraldton,AUSTRALIA",
  },
  {
    code: "GEV",
    name: "Gallivare Intl Airport",
    Address: "Gallivare,SWEDEN",
  },
  {
    code: "GEX",
    name: "Geelong Intl Airport",
    Address: "Geelong,AUSTRALIA",
  },
  {
    code: "GFD",
    name: "Pope Field Intl Airport",
    Address: "Greenfield,UNITED STATES",
  },
  {
    code: "GFF",
    name: "Griffith Intl Airport",
    Address: "Griffith,AUSTRALIA",
  },
  {
    code: "GFK",
    name: "Grand Forks Mark Andrews Intl ",
    Address: "Grand Forks,UNITED STATES",
  },
  {
    code: "GFL",
    name: "Warren County",
    Address: "Glens Falls,UNITED STATES",
  },
  {
    code: "GFN",
    name: "Grafton Intl Airport",
    Address: "Grafton,AUSTRALIA",
  },
  {
    code: "GGG",
    name: "Greg County Intl Airport",
    Address: "Longview,UNITED STATES",
  },
  {
    code: "GGT",
    name: "George Town Intl Airport",
    Address: "George Town,BAHAMAS",
  },
  {
    code: "GGW",
    name: " Intl  Glasgow",
    Address: "Glasgow,UNITED STATES",
  },
  {
    code: "GHA",
    name: "Noumerate Intl Airport",
    Address: "Ghardaia,ALGERIA",
  },
  {
    code: "GHB",
    name: "Governors Harbour Municipal Intl Airport",
    Address: "Governor S Harbour,BAHAMAS",
  },
  {
    code: "GIB",
    name: "North Front Intl Airport",
    Address: "Gibraltar,GIBRALTAR",
  },
  {
    code: "GIC",
    name: "Boigu Island Intl Airport",
    Address: "Boigu Island,AUSTRALIA",
  },
  {
    code: "GIF",
    name: "Gilbert Fld",
    Address: "Winter Haven,UNITED STATES",
  },
  {
    code: "GIL",
    name: "Gilgit Intl Airport",
    Address: "Gilgit,PAKISTAN",
  },
  {
    code: "GIR",
    name: "Girardot Intl Airport",
    Address: "Girardot,COLOMBIA",
  },
  {
    code: "GIS",
    name: "Gisborne Intl Airport",
    Address: "Gisborne,NEW ZEALAND",
  },
  { code: "GIZ", name: "Gizan Aprt", Address: "Gizan,SAUDI ARABIA" },
  {
    code: "GJT",
    name: "Walker Field Intl Airport",
    Address: "Grand Junction,UNITED STATES",
  },
  {
    code: "GKA",
    name: "Goroka Intl Airport",
    Address: "Goroka,PAPUA NEW GUINEA",
  },
  { code: "GKD", name: "GOKCEADA", Address: "GOKCEADA,TURKEY" },
  {
    code: "GKT",
    name: "Gatlinburg Intl Airport",
    Address: "Gatlinburg,UNITED STATES",
  },
  {
    code: "PIK",
    name: "Prestwick Intl Airport",
    Address: "Glasgow,UNITED KINGDOM",
  },
  {
    code: "ZGG",
    name: "Glasgow ScotRail",
    Address: "Glasgow,UNITED KINGDOM",
  },
  {
    code: "GLA",
    name: "Glasgow Intl ",
    Address: "Glasgow,UNITED KINGDOM",
  },
  {
    code: "GLD",
    name: "Goodland Municipal",
    Address: "Goodland,UNITED STATES",
  },
  {
    code: "GLE",
    name: "Gainesville Municipal Intl Airport",
    Address: "Gainesville,UNITED STATES",
  },
  {
    code: "GLH",
    name: "Greenville Municipal",
    Address: "Greenville,UNITED STATES",
  },
  {
    code: "GLI",
    name: "Glen Innes Intl Airport",
    Address: "Glen Innes,AUSTRALIA",
  },
  {
    code: "XKZ",
    name: "Vinstra Rail Station",
    Address: "Gol City,NORWAY",
  },
  {
    code: "XGP",
    name: "Dombas Rail Station",
    Address: "Gol City,NORWAY",
  },
  {
    code: "GLL",
    name: "Klanten Intl Airport",
    Address: "Gol City,NORWAY",
  },
  {
    code: "GLO",
    name: "Staverton Intl Airport",
    Address: "Gloucester,UNITED KINGDOM",
  },
  {
    code: "GLR",
    name: "Otsego Intl Airport",
    Address: "Gaylord,UNITED STATES",
  },
  {
    code: "GLS",
    name: "Scholes Field",
    Address: "Galveston,UNITED STATES",
  },
  {
    code: "GLT",
    name: "Gladstone Intl Airport",
    Address: "Gladstone,AUSTRALIA",
  },
  {
    code: "GLW",
    name: "Glasgow Municipal Intl Airport",
    Address: "Glasgow,UNITED STATES",
  },
  { code: "GME", name: "Gomel Intl Airport", Address: "Gomel,BELARUS" },
  {
    code: "GMN",
    name: "Greymouth Intl Airport",
    Address: "Greymouth,NEW ZEALAND",
  },
  {
    code: "GMR",
    name: "Gambier Island Intl Airport",
    Address: "Gambier Island,FRENCH POLYNESIA",
  },
  {
    code: "GMZ",
    name: "La Gomera Intl Airport",
    Address: "San Sebastian De La Gomera,SPAIN",
  },
  { code: "GNA", name: "Grodna Intl Airport", Address: "Grodna,BELARUS" },
  {
    code: "GNB",
    name: "Saint Geoirs Intl Airport",
    Address: "Grenoble,FRANCE",
  },
  { code: "GND", name: "Port Saline Intl ", Address: "Grenada,DOMINICA" },
  {
    code: "GNS",
    name: "Gunungsitoli Intl Airport",
    Address: "Gunungsitoli,INDONESIA",
  },
  {
    code: "GNT",
    name: "Grants Milan Municipal Intl Airport",
    Address: "Grants,UNITED STATES",
  },
  {
    code: "GNV",
    name: "Gainesville Regional",
    Address: "Gainesville,UNITED STATES",
  },
  { code: "GOA", name: "Christoforo Colombo", Address: "Genoa,ITALY" },
  { code: "GOH", name: "Nuuk Intl Airport", Address: "Nuuk,GREENLAND" },
  { code: "GOI", name: "Dabolim Intl Airport", Address: "Goa In,INDIA" },
  {
    code: "GOJ",
    name: "Nizhniy Novgorod Intl Airport",
    Address: "Nizhniy Novgorod,RUSSIA",
  },
  {
    code: "GON",
    name: "Groton New London Intl Airport",
    Address: "New London,UNITED STATES",
  },
  {
    code: "GOO",
    name: "Goondiwindi Intl Airport",
    Address: "Goondiwindi,AUSTRALIA",
  },
  {
    code: "GOP",
    name: "Gorakhpur Intl Airport",
    Address: "Gorakhpur,INDIA",
  },
  { code: "GOQ", name: "Golmud Intl Airport", Address: "Golmud,CHINA" },
  {
    code: "GSE",
    name: "Saeve Intl Airport",
    Address: "Gothenburg,SWEDEN",
  },
  {
    code: "GOT",
    name: "Landvetter Intl Airport",
    Address: "Gothenburg,SWEDEN",
  },
  { code: "XWL", name: "Gothenburg Rail", Address: "Gothenburg,SWEDEN" },
  {
    code: "GOU",
    name: "Garoua Intl Airport",
    Address: "Garoua,CAMEROON",
  },
  {
    code: "GOV",
    name: "Nhulunbuy Intl Airport",
    Address: "Gove,AUSTRALIA",
  },
  { code: "GPA", name: "Araxos Intl Airport", Address: "Patras,GREECE" },
  {
    code: "GPS",
    name: "Baltra Intl Airport",
    Address: "Galapagos Is,ECUADOR",
  },
  {
    code: "GPT",
    name: "Biloxi Regional Intl Airport",
    Address: "Gulfport,UNITED STATES",
  },
  {
    code: "GPZ",
    name: "Itasca County",
    Address: "Grand Rapids,UNITED STATES",
  },
  {
    code: "GQQ",
    name: "Galion Municipal Intl Airport",
    Address: "Galion,UNITED STATES",
  },
  {
    code: "GRB",
    name: "Austin Straubel Fld",
    Address: "Green Bay,UNITED STATES",
  },
  {
    code: "GRI",
    name: "Hall Cty Regional",
    Address: "Grand Island,UNITED STATES",
  },
  {
    code: "GRJ",
    name: "George Intl Airport",
    Address: "George,SOUTH AFRICA",
  },
  {
    code: "GRO",
    name: "Costa Brava Intl Airport",
    Address: "Gerona,SPAIN",
  },
  {
    code: "GRQ",
    name: "Eelde Intl Airport",
    Address: "Groningen,NETHERLANDS",
  },
  {
    code: "GRR",
    name: "Kent County Intl ",
    Address: "Grand Rapids,UNITED STATES",
  },
  {
    code: "GRS",
    name: "Baccarini Intl Airport",
    Address: "Grosseto,ITALY",
  },
  { code: "GRV", name: "Groznyj", Address: "Groznyj,RUSSIA" },
  {
    code: "GRW",
    name: "Graciosa Intl Airport",
    Address: "Graciosa Island,PORTUGAL",
  },
  { code: "GRX", name: "Granada Intl Airport", Address: "Granada,SPAIN" },
  {
    code: "GRZ",
    name: "Thalerhof Intl Airport",
    Address: "Graz,AUSTRIA",
  },
  {
    code: "GSB",
    name: "Seymour Johnson AFB",
    Address: "Goldsboro,UNITED STATES",
  },
  {
    code: "GSO",
    name: "Piedmont Triad Intl ",
    Address: "Greensboro,UNITED STATES",
  },
  {
    code: "SPA",
    name: "Downtown Memorial",
    Address: "Greenville,UNITED STATES",
  },
  {
    code: "GSP",
    name: "Greenville Spartanburg Intl Airport",
    Address: "Greenville,UNITED STATES",
  },
  {
    code: "GMU",
    name: "Greenville Downtown Intl Airport",
    Address: "Greenville,UNITED STATES",
  },
  {
    code: "GST",
    name: "Gustavus Intl Airport",
    Address: "Glacier Bay,UNITED STATES",
  },
  {
    code: "GSY",
    name: "Binbrook Intl Airport",
    Address: "Grimsby,UNITED KINGDOM",
  },
  {
    code: "GTE",
    name: "Alyangula Intl Airport",
    Address: "Groote Eylandt,AUSTRALIA",
  },
  {
    code: "GTF",
    name: "Great Falls Intl Airport",
    Address: "Great Falls,UNITED STATES",
  },
  {
    code: "GTI",
    name: "Guettin Intl Airport",
    Address: "Guettin,GERMANY",
  },
  {
    code: "GTY",
    name: "Gettysburg Intl Airport",
    Address: "Gettysburg,UNITED STATES",
  },
  {
    code: "GUA",
    name: "La Aurora Intl Airport",
    Address: "Guatemala City,GUATEMALA",
  },
  {
    code: "GUC",
    name: "Gunnison Cty",
    Address: "Gunnison,UNITED STATES",
  },
  {
    code: "GUF",
    name: "Edwards Intl Airport",
    Address: "Gulf Shores,UNITED STATES",
  },
  {
    code: "GUH",
    name: "Gunnedah Intl Airport",
    Address: "Gunnedah,AUSTRALIA",
  },
  { code: "UAM", name: "Anderson Air Force Base", Address: "Guam,GUAM" },
  { code: "GUM", name: "Antonio B Won Pat Intl ", Address: "Guam,GUAM" },
  {
    code: "GUP",
    name: "Gallup Municipal",
    Address: "Gallup,UNITED STATES",
  },
  {
    code: "GUR",
    name: "Gurney Intl Airport",
    Address: "Alotau,PAPUA NEW GUINEA",
  },
  {
    code: "GUT",
    name: "Guetersloh Intl Airport",
    Address: "Gutersloh,GERMANY",
  },
  {
    code: "GUW",
    name: "Atyrau Intl Airport",
    Address: "Atyrau,KAZAKHSTAN",
  },
  {
    code: "GUY",
    name: "Guymon Municipal Intl Airport",
    Address: "Guymon,UNITED STATES",
  },
  { code: "GVA", name: "Geneve Cointrin", Address: "Geneva,SWITZERLAND" },
  {
    code: "GVR",
    name: "Governador Valadares Intl Airport",
    Address: "Governador Valadares,BRAZIL",
  },
  { code: "XFU", name: "Tierp Rail Station", Address: "Gavle,SWEDEN" },
  {
    code: "GVX",
    name: "Sandviken Intl Airport",
    Address: "Gavle,SWEDEN",
  },
  { code: "QYU", name: "Gavle Rail Station", Address: "Gavle,SWEDEN" },
  { code: "GWE", name: "Gweru Intl Airport", Address: "Gweru,ZIMBABWE" },
  { code: "GWL", name: "Gwalior Intl Airport", Address: "Gwalior,INDIA" },
  {
    code: "GWO",
    name: "Leflore Intl Airport",
    Address: "Greenwood,UNITED STATES",
  },
  {
    code: "GWS",
    name: "Glenwood Springs Intl Airport",
    Address: "Glenwood Springs,UNITED STATES",
  },
  {
    code: "GWT",
    name: "Westerland Intl Airport",
    Address: "Westerland,GERMANY",
  },
  {
    code: "GWY",
    name: "Carnmore Intl Airport",
    Address: "Galway,IRELAND",
  },
  {
    code: "GXQ",
    name: "Teniente Vidal Intl Airport",
    Address: "Coyhaique,CHILE",
  },
  {
    code: "GXY",
    name: "Weld County Regional Intl Airport",
    Address: "Greeley,UNITED STATES",
  },
  {
    code: "GYE",
    name: "Simon Bolivar Intl Airport",
    Address: "Guayaquil,ECUADOR",
  },
  {
    code: "GYI",
    name: "Gisenyi Intl Airport",
    Address: "Gisenyi,RWANDA",
  },
  {
    code: "GYM",
    name: "Gen Jose M Yanez Intl Airport",
    Address: "Guaymas,MEXICO",
  },
  { code: "GYN", name: "Santa Genoveva", Address: "Goiania,BRAZIL" },
  {
    code: "GYP",
    name: "Gympie Intl Airport",
    Address: "Gympie,AUSTRALIA",
  },
  {
    code: "GYR",
    name: "Litchfield Goodyear Intl Airport",
    Address: "Goodyear,UNITED STATES",
  },
  {
    code: "GYS",
    name: "Guang Yuan Intl Airport",
    Address: "Guang Yuan,CHINA",
  },
  {
    code: "GYY",
    name: "Gary Intl Airport",
    Address: "Gary,UNITED STATES",
  },
  {
    code: "GZA",
    name: "Gaza Intl Airport",
    Address: "Gaza,PALESTINIAN TERRITORY, OCCUPIED",
  },
  { code: "GZM", name: "Gozo Intl Airport", Address: "Gozo,MALTA" },
  {
    code: "GZO",
    name: "Gizo Intl Airport",
    Address: "Gizo,SOLOMON ISLANDS",
  },
  {
    code: "GZP",
    name: "Gazipasa Intl Airport",
    Address: "Gazipasa,TURKEY",
  },
  {
    code: "GZT",
    name: "Gaziantep Intl Airport",
    Address: "Gaziantep,TURKEY",
  },
  {
    code: "HAC",
    name: "Hachijo Jima Intl Airport",
    Address: "Hachijo Jima,JAPAN",
  },
  {
    code: "HAD",
    name: "Halmstad Intl Airport",
    Address: "Halmstad,SWEDEN",
  },
  {
    code: "XYM",
    name: "Falkenberg Rail Station",
    Address: "Halmstad,SWEDEN",
  },
  {
    code: "ZVR",
    name: "Hanover HBF Railway Service",
    Address: "Hanover,GERMANY",
  },
  {
    code: "HAJ",
    name: "Hanover Intl Airport",
    Address: "Hanover,GERMANY",
  },
  { code: "HAK", name: "Haikou Intl Airport", Address: "Haikou,CHINA" },
  {
    code: "ZMB",
    name: "Hamburg Railway Service",
    Address: "Hamburg,GERMANY",
  },
  {
    code: "HAM",
    name: "Fuhlsbuettel Intl Airport",
    Address: "Hamburg,GERMANY",
  },
  {
    code: "LBC",
    name: "Blankensee Intl Airport",
    Address: "Hamburg,GERMANY",
  },
  { code: "HAN", name: "Noibai Intl Airport", Address: "Hanoi,VIET NAM" },
  {
    code: "HAP",
    name: "Long Island Intl Airport",
    Address: "Long Island,AUSTRALIA",
  },
  {
    code: "MDT",
    name: "Harrisburg Intl ",
    Address: "Harrisburg,UNITED STATES",
  },
  {
    code: "HAS",
    name: "Hail Intl Airport",
    Address: "Hail,SAUDI ARABIA",
  },
  {
    code: "HAU",
    name: "Karmoy Intl Airport",
    Address: "Haugesund,NORWAY",
  },
  {
    code: "HAV",
    name: "Jose Marti Intl Airport",
    Address: "Havana,CUBA",
  },
  {
    code: "HBA",
    name: "Hobart Intl Airport",
    Address: "Hobart,AUSTRALIA",
  },
  {
    code: "HBE",
    name: "Borg El Arab Intl Airport",
    Address: "Borg El Arab,EGYPT",
  },
  {
    code: "HBG",
    name: "Bobby L Chain Municipal",
    Address: "Hattiesburg,UNITED STATES",
  },
  {
    code: "HBI",
    name: "Harbour Island Intl Airport",
    Address: "Harbour Island,BAHAMAS",
  },
  {
    code: "HBT",
    name: "Hafr Albatin Intl Airport",
    Address: "Hafr Albatin,SAUDI ARABIA",
  },
  { code: "HBX", name: "Hubli Intl Airport", Address: "Hubli,INDIA" },
  {
    code: "HCN",
    name: "Hengchun Intl Airport",
    Address: "Hengchun,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "HCQ",
    name: "Halls Creek Intl Airport",
    Address: "Halls Creek,AUSTRALIA",
  },
  {
    code: "HDB",
    name: "Heidelberg Intl Airport",
    Address: "Heidelberg,GERMANY",
  },
  { code: "HDD", name: "Hyderabad", Address: "Hyderabad,PAKISTAN" },
  {
    code: "HDF",
    name: "Heringsdorf Intl Airport",
    Address: "Heringsdorf,GERMANY",
  },
  {
    code: "HDN",
    name: "Hayden Intl Airport",
    Address: "Hayden,UNITED STATES",
  },
  {
    code: "HDS",
    name: "Hoedspruit Intl Airport",
    Address: "Hoedspruit,SOUTH AFRICA",
  },
  {
    code: "HDY",
    name: "Hat Yai Intl Airport",
    Address: "Hat Yai,THAILAND",
  },
  {
    code: "HEA",
    name: "Herat Intl Airport",
    Address: "Herat,AFGHANISTAN",
  },
  { code: "HEH", name: "Heho Intl Airport", Address: "Heho,MYANMAR" },
  {
    code: "HEI",
    name: "Heide Intl Airport",
    Address: "Heide Buesum,GERMANY",
  },
  {
    code: "HEL",
    name: "Helsinki Intl Airport",
    Address: "Helsinki,FINLAND",
  },
  {
    code: "HER",
    name: "N Kazantzakis Intl Airport",
    Address: "Heraklion,GREECE",
  },
  { code: "HET", name: "Hohhot Intl Airport", Address: "Hohhot,CHINA" },
  {
    code: "HEZ",
    name: "Natchez Adams Cty",
    Address: "Natchez,UNITED STATES",
  },
  { code: "HFA", name: "Haifa Intl Airport", Address: "Haifa,ISRAEL" },
  {
    code: "BDL",
    name: "Bradley Intl Airport",
    Address: "Hartford,UNITED STATES",
  },
  {
    code: "HFD",
    name: "Brainard Intl Airport",
    Address: "Hartford,UNITED STATES",
  },
  { code: "HFE", name: "Hefei Intl Airport", Address: "Hefei,CHINA" },
  {
    code: "HFN",
    name: "Hornafjordur Intl Airport",
    Address: "Hornafjordur,ICELAND",
  },
  {
    code: "HFT",
    name: "Hammerfest Intl Airport",
    Address: "Hammerfest,NORWAY",
  },
  {
    code: "HGA",
    name: "Hargeisa Intl Airport",
    Address: "Hargeisa,SOMALIA",
  },
  {
    code: "HGD",
    name: "Hughenden Intl Airport",
    Address: "Hughenden,AUSTRALIA",
  },
  {
    code: "HGH",
    name: "Hangzhou Intl Airport",
    Address: "Hangzhou,CHINA",
  },
  {
    code: "HGL",
    name: "Helgoland Intl Airport",
    Address: "Helgoland,GERMANY",
  },
  {
    code: "HGN",
    name: "Mae Hong Son Intl Airport",
    Address: "Mae Hongson,THAILAND",
  },
  {
    code: "HGR",
    name: "Washington Cty Regional",
    Address: "Hagerstown,UNITED STATES",
  },
  {
    code: "HGU",
    name: "Kagamuga Intl Airport",
    Address: "Mt Hagen,PAPUA NEW GUINEA",
  },
  {
    code: "HHA",
    name: "Changsha Huanghua Intl Airport",
    Address: "Huanghua,CHINA",
  },
  {
    code: "HHE",
    name: "Hachinohe Intl Airport",
    Address: "Hachinohe,JAPAN",
  },
  {
    code: "HHH",
    name: "Hilton Head Municipal",
    Address: "Hilton Head,UNITED STATES",
  },
  {
    code: "HHQ",
    name: "Hua Hin Intl Airport",
    Address: "Hua Hin,THAILAND",
  },
  {
    code: "HHR",
    name: "Hawthorne Intl Airport",
    Address: "Hawthorne,UNITED STATES",
  },
  {
    code: "HIB",
    name: "Hibbing Chisolm Intl Airport",
    Address: "Hibbing,UNITED STATES",
  },
  {
    code: "HID",
    name: "Horn Island Intl Airport",
    Address: "Horn Island,AUSTRALIA",
  },
  {
    code: "HIE",
    name: "Mt washington Regional Intl Airport",
    Address: "Whitefield,UNITED STATES",
  },
  {
    code: "HII",
    name: "Lake Havasu City Municipal Intl Airport",
    Address: "Lake Havasu Cty,UNITED STATES",
  },
  {
    code: "HIJ",
    name: "Hiroshima Intl Airport",
    Address: "Hiroshima,JAPAN",
  },
  {
    code: "HIN",
    name: "Sacheon Intl Airport",
    Address: "Chinju,KOREA, REPUBLIC OF",
  },
  {
    code: "HIO",
    name: "Portland Hillsboro Intl Airport",
    Address: "Hillsboro,UNITED STATES",
  },
  {
    code: "HIR",
    name: "Henderson Intl Airport",
    Address: "Honiara,SOLOMON ISLANDS",
  },
  {
    code: "HIS",
    name: "Hayman Island Intl Airport",
    Address: "Hayman Island,AUSTRALIA",
  },
  {
    code: "HJR",
    name: "Khajuraho Intl Airport",
    Address: "Khajuraho,INDIA",
  },
  {
    code: "HKA",
    name: "Blytheville Municipal Intl Airport",
    Address: "Blytheville,UNITED STATES",
  },
  {
    code: "HKB",
    name: "Healy Lake Intl Airport",
    Address: "Healy Lake,UNITED STATES",
  },
  {
    code: "HKD",
    name: "Hakodate Intl Airport",
    Address: "Hakodate,JAPAN",
  },
  {
    code: "HKG",
    name: "Hong Kong Intl ",
    Address: "Hong Kong,HONG KONG",
  },
  {
    code: "HKK",
    name: "Hokitika Intl Airport",
    Address: "Hokitika,NEW ZEALAND",
  },
  {
    code: "HKN",
    name: "Hoskins Intl Airport",
    Address: "Hoskins,PAPUA NEW GUINEA",
  },
  {
    code: "HKT",
    name: "Phuket Intl Airport",
    Address: "Phuket,THAILAND",
  },
  {
    code: "HKY",
    name: "Hickory Municipal",
    Address: "Hickory,UNITED STATES",
  },
  {
    code: "HLB",
    name: "Hillenbrand",
    Address: "Batesville,UNITED STATES",
  },
  { code: "HLD", name: "Hailer Intl Airport", Address: "Hailar,CHINA" },
  {
    code: "HLF",
    name: "Hultsfred Intl Airport",
    Address: "Hultsfred,SWEDEN",
  },
  {
    code: "HLM",
    name: "Park Township",
    Address: "Holland,UNITED STATES",
  },
  {
    code: "HLN",
    name: "Helena Municipal",
    Address: "Helena,UNITED STATES",
  },
  {
    code: "HLS",
    name: "St Helens Intl Airport",
    Address: "St Helens,AUSTRALIA",
  },
  {
    code: "HLW",
    name: "Hluhluwe Intl Airport",
    Address: "Hluhluwe,SOUTH AFRICA",
  },
  {
    code: "HLY",
    name: "Holyhead Rail Station",
    Address: "Holyhead,UNITED KINGDOM",
  },
  {
    code: "HLZ",
    name: "Hamilton Intl Airport",
    Address: "Hamilton,NEW ZEALAND",
  },
  {
    code: "HMA",
    name: "Khanty Mansiysk Intl Airport",
    Address: "Khanty Mansiysk,RUSSIA",
  },
  {
    code: "HMB",
    name: "Mubarak Intl Airport",
    Address: "Mubarak Intl Airport,EGYPT",
  },
  {
    code: "HME",
    name: "Oued Irara Intl Airport",
    Address: "Hassi Messaoud,ALGERIA",
  },
  {
    code: "HMJ",
    name: "(HMJ) Khmelnitskiy Intl Airport , UA",
    Address: "(HMJ) Khmelnitskiy Intl Airport , UA,UKRAINE",
  },
  {
    code: "HMO",
    name: "Gen Ignacio Pesqueira Garcia Intl Airport",
    Address: "Hermosillo,MEXICO",
  },
  { code: "HMR", name: "Hamar Intl Airport", Address: "Hamar,NORWAY" },
  { code: "HMV", name: "Hemavan Flyg", Address: "Hemavan,SWEDEN" },
  {
    code: "HNA",
    name: "Hanamaki Intl Airport",
    Address: "Morioka,JAPAN",
  },
  {
    code: "HNB",
    name: "Huntingburg Municipal",
    Address: "Huntingburg,UNITED STATES",
  },
  {
    code: "HNH",
    name: "Hoonah Municipal Intl Airport",
    Address: "Hoonah,UNITED STATES",
  },
  {
    code: "HIK",
    name: "Hickam Air Force Base",
    Address: "Honolulu,UNITED STATES",
  },
  {
    code: "HNL",
    name: "Honolulu Intl ",
    Address: "Honolulu,UNITED STATES",
  },
  { code: "HNM", name: "Hana Municipal", Address: "Hana,UNITED STATES" },
  {
    code: "HNS",
    name: "Haines Municipal Intl Airport",
    Address: "Haines,UNITED STATES",
  },
  {
    code: "HNY",
    name: "Hengyang Intl Airport",
    Address: "Hengyang,CHINA",
  },
  {
    code: "HOB",
    name: "Lea County Intl Airport",
    Address: "Hobbs,UNITED STATES",
  },
  {
    code: "HOD",
    name: "Hodeidah Intl Airport",
    Address: "Hodeidah,YEMEN",
  },
  {
    code: "HOF",
    name: "Alahsa Intl Airport",
    Address: "Hofuf,SAUDI ARABIA",
  },
  {
    code: "HOG",
    name: "Frank Pias Intl Airport",
    Address: "Holguin,CUBA",
  },
  {
    code: "HOI",
    name: "Hao Island Intl Airport",
    Address: "Hao Island,FRENCH POLYNESIA",
  },
  {
    code: "HOM",
    name: "Homer Municipal Intl Airport",
    Address: "Homer,UNITED STATES",
  },
  {
    code: "HON",
    name: "Huron Municipal",
    Address: "Huron,UNITED STATES",
  },
  {
    code: "HOP",
    name: "Hopkinsville Christian Country Intl Airport",
    Address: "Hopkinsville,UNITED STATES",
  },
  {
    code: "HOQ",
    name: "Hof Pirk Intl Airport",
    Address: "Hof De,GERMANY",
  },
  { code: "HOR", name: "Horta Intl Airport", Address: "Horta,PORTUGAL" },
  {
    code: "HOT",
    name: "Memorial Field",
    Address: "Hot Springs,UNITED STATES",
  },
  {
    code: "IAH",
    name: "George Bush Intercontinental",
    Address: "Houston,UNITED STATES",
  },
  { code: "IWS", name: "West Houston", Address: "Houston,UNITED STATES" },
  {
    code: "HOU",
    name: "Houston Hobby Intl Airport",
    Address: "Houston,UNITED STATES",
  },
  {
    code: "DWH",
    name: "David Wayne Hooks Intl Airport",
    Address: "Houston,UNITED STATES",
  },
  {
    code: "EFD",
    name: "Ellington Field",
    Address: "Houston,UNITED STATES",
  },
  {
    code: "HOV",
    name: "Hovden Intl Airport",
    Address: "Orsta Volda,NORWAY",
  },
  {
    code: "HPA",
    name: "Salote Pilolevu Intl Airport",
    Address: "Ha Apai,TONGA",
  },
  {
    code: "HPH",
    name: "Catbi Intl Airport",
    Address: "Haiphong,VIET NAM",
  },
  {
    code: "HPN",
    name: "Westchester County Intl Airport",
    Address: "White Plains,UNITED STATES",
  },
  {
    code: "HPV",
    name: "Princeville Intl Airport",
    Address: "Princeville,UNITED STATES",
  },
  {
    code: "HPY",
    name: "Baytown Intl Airport",
    Address: "Baytown,UNITED STATES",
  },
  { code: "HRB", name: "Harbin Intl Airport", Address: "Harbin,CHINA" },
  {
    code: "HRE",
    name: "Harare Intl Airport",
    Address: "Harare,ZIMBABWE",
  },
  {
    code: "HRG",
    name: "Hurghada Intl Airport",
    Address: "Hurghada,EGYPT",
  },
  {
    code: "HRK",
    name: "Kharkov Intl Airport",
    Address: "Kharkov,UKRAINE",
  },
  {
    code: "HRL",
    name: "Rio Grande Valley Intl Airport",
    Address: "Harlingen,UNITED STATES",
  },
  {
    code: "HRO",
    name: "Boone County Intl Airport",
    Address: "Harrison,UNITED STATES",
  },
  {
    code: "HRS",
    name: "Harrismith Intl Airport",
    Address: "Harrismith,SOUTH AFRICA",
  },
  {
    code: "XTK",
    name: "Thirsk Rail Station",
    Address: "Harrogate,UNITED KINGDOM",
  },
  {
    code: "HRT",
    name: "Linton On Ouse",
    Address: "Harrogate,UNITED KINGDOM",
  },
  {
    code: "HRZ",
    name: "Horizontina Intl Airport",
    Address: "Horizontina,BRAZIL",
  },
  { code: "HSG", name: "Saga Intl Airport", Address: "Saga,JAPAN" },
  {
    code: "HSI",
    name: "Hastings Municipal",
    Address: "Hastings,UNITED STATES",
  },
  {
    code: "HSL",
    name: "Huslia Intl Airport",
    Address: "Huslia,UNITED STATES",
  },
  {
    code: "HSN",
    name: "Zhoushan Intl Airport",
    Address: "Zhoushan,CHINA",
  },
  {
    code: "HSP",
    name: "Ingalls Field",
    Address: "Hot Springs,UNITED STATES",
  },
  {
    code: "HST",
    name: "Homestead Municipal",
    Address: "Homestead,UNITED STATES",
  },
  {
    code: "HSV",
    name: "Huntsville Intl Airport",
    Address: "Huntsville,UNITED STATES",
  },
  {
    code: "HSZ",
    name: "Hsinchun Intl Airport",
    Address: "Hsinchun,TAIWAN, PROVINCE OF CHINA",
  },
  { code: "HTA", name: "Chita Intl Airport", Address: "Chita,RUSSIA" },
  {
    code: "HTF",
    name: "Hatfield Intl Airport",
    Address: "Hatfield,UNITED KINGDOM",
  },
  {
    code: "HTG",
    name: "Khatanga Intl Airport",
    Address: "Khatanga Intl Airport,RUSSIA",
  },
  {
    code: "HTI",
    name: "Hamilton Island Intl Airport",
    Address: "Hamilton Island,AUSTRALIA",
  },
  {
    code: "HTO",
    name: "East Hampton Intl Airport",
    Address: "East Hampton,UNITED STATES",
  },
  {
    code: "HTS",
    name: "Tri State Milton Intl Airport",
    Address: "Huntington,UNITED STATES",
  },
  {
    code: "PMH",
    name: "Portsmith Regional",
    Address: "Huntington,UNITED STATES",
  },
  {
    code: "HTV",
    name: "Huntsville Intl Airport",
    Address: "Huntsville,UNITED STATES",
  },
  { code: "HTY", name: "Hatay Intl Airport", Address: "Hatay,TURKEY" },
  {
    code: "HUC",
    name: "Humacao Intl Airport",
    Address: "Humacao,UNITED STATES",
  },
  {
    code: "HUF",
    name: "Hulman Field",
    Address: "Terre Haute,UNITED STATES",
  },
  {
    code: "HUH",
    name: "Huahine Intl Airport",
    Address: "Huahine,FRENCH POLYNESIA",
  },
  {
    code: "HUI",
    name: "Phu Bai Intl Airport",
    Address: "Hu PG,VIET NAM",
  },
  {
    code: "HUL",
    name: "Houlton Intl ",
    Address: "Houlton,UNITED STATES",
  },
  {
    code: "HUM",
    name: "Terrebonne Intl Airport",
    Address: "Houma,UNITED STATES",
  },
  {
    code: "HUN",
    name: "Hualien Intl Airport",
    Address: "Hualien,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "HUS",
    name: "Hughes Municipal Intl Airport",
    Address: "Hughes,UNITED STATES",
  },
  {
    code: "HUT",
    name: "Hutchinson Municipal",
    Address: "Hutchinson,UNITED STATES",
  },
  { code: "HUU", name: "Huanuco Intl Airport", Address: "Huanuco,PERU" },
  {
    code: "HUV",
    name: "Hudiksvall Intl Airport",
    Address: "Hudiksvall,SWEDEN",
  },
  {
    code: "HUX",
    name: "Bahia De Huatulco Intl Airport",
    Address: "Santa Cruz Huatulco,MEXICO",
  },
  {
    code: "HUY",
    name: "Humberside Intl Airport",
    Address: "Humberside,UNITED KINGDOM",
  },
  { code: "HUZ", name: "Huizhou Intl Airport", Address: "Huizhou,CHINA" },
  { code: "HVB", name: "Hervey Bay", Address: "Hervey Bay,AUSTRALIA" },
  {
    code: "HVE",
    name: "Intermediate Intl Airport",
    Address: "Hanksville,UNITED STATES",
  },
  {
    code: "HVG",
    name: "Valan Intl Airport",
    Address: "Honningsvag,NORWAY",
  },
  {
    code: "HVN",
    name: "Tweed New Haven Intl Airport",
    Address: "New Haven,UNITED STATES",
  },
  { code: "HVR", name: "City County", Address: "Havre,UNITED STATES" },
  {
    code: "HWD",
    name: "Hayward Air Terminal",
    Address: "Hayward,UNITED STATES",
  },
  {
    code: "HWN",
    name: "Hwange Intl Airport",
    Address: "Hwange,ZIMBABWE",
  },
  {
    code: "HYA",
    name: "Barnstable Cty Intl Airport",
    Address: "Hyannis,UNITED STATES",
  },
  {
    code: "HYD",
    name: "Rajiv Gandhi Intl Airport",
    Address: "Shamshabad,INDIA",
  },
  {
    code: "HYN",
    name: "Huangyan Intl Airport",
    Address: "Huangyan,CHINA",
  },
  {
    code: "HYR",
    name: "Hayward Municipal",
    Address: "Hayward,UNITED STATES",
  },
  { code: "HYS", name: "Hays Municipal", Address: "Hays,UNITED STATES" },
  {
    code: "HZB",
    name: "Merville Calonne Intl Airport",
    Address: "Hazebrouck,FRANCE",
  },
  {
    code: "HZG",
    name: "Hanzhong Intl Airport",
    Address: "Hanzhong,CHINA",
  },
  {
    code: "HZK",
    name: "Husavik Intl Airport",
    Address: "Husavik,ICELAND",
  },
  {
    code: "IAA",
    name: "Igarka Intl Airport",
    Address: "Igarka Intl Airport,RUSSIA",
  },
  {
    code: "IAG",
    name: "Niagara Falls Intl Airport",
    Address: "Niagara Falls,UNITED STATES",
  },
  {
    code: "IAR",
    name: "Yaroslavl Intl Airport",
    Address: "Yaroslavl,RUSSIA",
  },
  { code: "IAS", name: "Iasi Intl Airport", Address: "Iasi,ROMANIA" },
  {
    code: "IBE",
    name: "Ibague Intl Airport",
    Address: "Ibague,COLOMBIA",
  },
  { code: "IBZ", name: "Ibiza Intl Airport", Address: "Ibiza,SPAIN" },
  {
    code: "ICT",
    name: "Mid Continent Intl Airport",
    Address: "Wichita,UNITED STATES",
  },
  {
    code: "IDA",
    name: "Fanning Field",
    Address: "Idaho Falls,UNITED STATES",
  },
  {
    code: "IDI",
    name: "Indiana County Intl Airport",
    Address: "Indiana,UNITED STATES",
  },
  {
    code: "IDR",
    name: "Devi Ahilya Bai Holkar Intl Airport",
    Address: "Indore,INDIA",
  },
  {
    code: "IEG",
    name: "Babimost Intl Airport",
    Address: "Zielona,POLAND",
  },
  { code: "IEV", name: "Zhulhany Intl Airport", Address: "Kiev,UKRAINE" },
  { code: "KBP", name: "Borispol Intl Airport", Address: "Kiev,UKRAINE" },
  {
    code: "IFJ",
    name: "Isafjordur Intl Airport",
    Address: "Isafjordur,ICELAND",
  },
  { code: "IFN", name: "Isfahan Intl Airport", Address: "Isfahan,IRAN" },
  {
    code: "IFO",
    name: "Ivano Frankovsk Intl Airport",
    Address: "Ivano Frankovsk,UKRAINE",
  },
  {
    code: "IFP",
    name: "Laughlin Bullhead Intl Airport",
    Address: "Bullhead City,UNITED STATES",
  },
  {
    code: "IGD",
    name: "I\u011fd\u0131r Havaliman\u0131",
    Address: "I\u011fd\u0131r Havaliman\u0131,TURKEY",
  },
  {
    code: "IGH",
    name: "Ingham Intl Airport",
    Address: "Ingham,AUSTRALIA",
  },
  {
    code: "IGM",
    name: "Mohave County",
    Address: "Kingman,UNITED STATES",
  },
  { code: "IGR", name: "Iguazu Intl ", Address: "Iguazu,ARGENTINA" },
  {
    code: "IGU",
    name: "Cataratas Intl Airport",
    Address: "Iguassu Falls,BRAZIL",
  },
  {
    code: "IJK",
    name: "Izhevsk Intl Airport",
    Address: "Izhevsk,RUSSIA",
  },
  {
    code: "IJX",
    name: "Jacksonville Municipal Intl Airport",
    Address: "Jacksonville,UNITED STATES",
  },
  {
    code: "IKK",
    name: "Greater Kankakee Intl Airport",
    Address: "Kankakee,UNITED STATES",
  },
  {
    code: "IKS",
    name: "Tiksi Intl Airport",
    Address: "Tiksi Intl Airport,RUSSIA",
  },
  {
    code: "IKT",
    name: "Irkutsk Intl Airport",
    Address: "Irkutsk,RUSSIA",
  },
  { code: "GRK", name: "Gray AAF", Address: "Killeen,UNITED STATES" },
  {
    code: "ILE",
    name: "Killeen Municipal",
    Address: "Killeen,UNITED STATES",
  },
  { code: "ILF", name: "Ilford Rail Station", Address: "Ilford,CANADA" },
  {
    code: "ILG",
    name: "Greater Wilmington New Castle",
    Address: "Wilmington,UNITED STATES",
  },
  {
    code: "ILM",
    name: "New Hanover Cty Intl Airport",
    Address: "Wilmington,UNITED STATES",
  },
  {
    code: "ILO",
    name: "Mandurriao Intl Airport",
    Address: "Iloilo,PHILIPPINES",
  },
  {
    code: "ILP",
    name: "Ile Des Pins Intl Airport",
    Address: "Ile Des Pins,NEW CALEDONIA",
  },
  {
    code: "ILY",
    name: "Islay Intl Airport",
    Address: "Islay,UNITED KINGDOM",
  },
  {
    code: "ILZ",
    name: "Zilina Intl Airport",
    Address: "Zilina,SLOVAKIA",
  },
  { code: "IMF", name: "Tulihal Intl Airport", Address: "Imphal,INDIA" },
  {
    code: "IMP",
    name: "Imperatriz Intl Airport",
    Address: "Imperatriz,BRAZIL",
  },
  {
    code: "IMT",
    name: "Ford Intl Airport",
    Address: "Iron Mountain,UNITED STATES",
  },
  {
    code: "INA",
    name: "Inta Intl Airport",
    Address: "Inta Intl Airport,RUSSIA",
  },
  {
    code: "INC",
    name: "Yinchuan Intl Airport",
    Address: "Yinchuan,CHINA",
  },
  {
    code: "IND",
    name: "Indianapolis Intl Airport",
    Address: "Indianapolis,UNITED STATES",
  },
  { code: "INI", name: "Nis Intl Airport", Address: "Nis Rs,SERBIA" },
  {
    code: "INL",
    name: "Intl Falls Intl Airport",
    Address: " Intl  Falls,UNITED STATES",
  },
  {
    code: "INN",
    name: "Kranebitten Intl Airport",
    Address: "Innsbruck,AUSTRIA",
  },
  {
    code: "INT",
    name: "Smith Reynolds Intl Airport",
    Address: "Winston-Salem,UNITED STATES",
  },
  {
    code: "INU",
    name: "Nauru Intl Airport",
    Address: "Nauru Island,NAURU",
  },
  {
    code: "ZIV",
    name: "Inverness ScotRail Station",
    Address: "Inverness,UNITED KINGDOM",
  },
  {
    code: "INV",
    name: "Inverness Intl Airport",
    Address: "Inverness,UNITED KINGDOM",
  },
  {
    code: "INW",
    name: "Winslow Municipal",
    Address: "Winslow,UNITED STATES",
  },
  {
    code: "IOA",
    name: "Ioannina Intl Airport",
    Address: "Ioannina,GREECE",
  },
  {
    code: "IOM",
    name: "Ronaldsway Intl Airport",
    Address: "Isle Of Man,UNITED KINGDOM",
  },
  {
    code: "IOS",
    name: "Eduardo Gomes Intl Airport",
    Address: "Ilheus,BRAZIL",
  },
  {
    code: "IOW",
    name: "Iowa City Municipal Intl Airport",
    Address: "Iowa City,UNITED STATES",
  },
  {
    code: "IPC",
    name: "Mataveri Intl Airport",
    Address: "Easter Island,CHILE",
  },
  { code: "IPH", name: "Ipoh Intl Airport", Address: "Ipoh,MALAYSIA" },
  {
    code: "IPI",
    name: "San Luis Intl Airport",
    Address: "Ipiales,COLOMBIA",
  },
  {
    code: "IPL",
    name: "Imperial County",
    Address: "El Centro,UNITED STATES",
  },
  {
    code: "IPN",
    name: "Usiminas Intl Airport",
    Address: "Ipatinga,BRAZIL",
  },
  {
    code: "IPT",
    name: "Williamsport Lycoming Municipal",
    Address: "Williamsport,UNITED STATES",
  },
  {
    code: "IPW",
    name: "Ipswitch Rail Station",
    Address: "Ipswich,UNITED KINGDOM",
  },
  {
    code: "IQQ",
    name: "Cavancha Chucumata Intl Airport",
    Address: "Iquique,CHILE",
  },
  {
    code: "IQT",
    name: "C F Secada Intl Airport",
    Address: "Iquitos,PERU",
  },
  {
    code: "IRG",
    name: "Lockhart River Intl Airport",
    Address: "Lockhart,AUSTRALIA",
  },
  {
    code: "IRJ",
    name: "La Rioja Intl Airport",
    Address: "La Rioja,ARGENTINA",
  },
  {
    code: "IRK",
    name: "Kirksville Municipal",
    Address: "Kirksville,UNITED STATES",
  },
  {
    code: "IRS",
    name: "Kirsch Municipal",
    Address: "Sturgis,UNITED STATES",
  },
  {
    code: "ISA",
    name: "Mount Isa Intl Airport",
    Address: "Mount Isa,AUSTRALIA",
  },
  { code: "ISB", name: "Islamabad Intl ", Address: "Islamabad,PAKISTAN" },
  {
    code: "ISC",
    name: "St Marys Intl Airport",
    Address: "Isles Of Scilly,UNITED KINGDOM",
  },
  {
    code: "TSO",
    name: "Tresco Intl Airport",
    Address: "Isles Of Scilly,UNITED KINGDOM",
  },
  {
    code: "ISE",
    name: "Isparta Suleyman Demirel Intl Airport",
    Address: "Isparta,TURKEY",
  },
  {
    code: "ISG",
    name: "Ishigaki Intl Airport",
    Address: "Ishigaki,JAPAN",
  },
  { code: "ISH", name: "Ischia Intl Airport", Address: "Ischia,ITALY" },
  {
    code: "ISJ",
    name: "Isla Mujeres Intl Airport",
    Address: "Isla Mujeres,MEXICO",
  },
  {
    code: "ISM",
    name: "Kissimmee Municipal Intl Airport",
    Address: "Kissimmee,UNITED STATES",
  },
  {
    code: "ISN",
    name: "Sloulin Field Intl Airport",
    Address: "Williston,UNITED STATES",
  },
  {
    code: "ISO",
    name: "East Reg Jetport Stallings",
    Address: "Kinston,UNITED STATES",
  },
  {
    code: "ISP",
    name: "Long Island Macarthur Intl Airport",
    Address: "Islip,UNITED STATES",
  },
  {
    code: "ISQ",
    name: "Schoolcraft County Intl Airport",
    Address: "Manistique,UNITED STATES",
  },
  {
    code: "ISS",
    name: "Wiscasset Intl Airport",
    Address: "Wiscasset,UNITED STATES",
  },
  {
    code: "IST",
    name: "Ataturk Intl Airport",
    Address: "Istanbul,TURKEY",
  },
  {
    code: "SAW",
    name: "Sabiha Gokcen Intl Airport",
    Address: "Istanbul,TURKEY",
  },
  {
    code: "ISU",
    name: "Sulaimaniyah Intl Airport",
    Address: "Sulaimaniyah,IRAQ",
  },
  {
    code: "ISW",
    name: "Alexander Field",
    Address: "Wisconsin Rapids,UNITED STATES",
  },
  {
    code: "ITH",
    name: "Tomkins County",
    Address: "Ithaca,UNITED STATES",
  },
  {
    code: "ITO",
    name: "Hilo Hawaii Intl ",
    Address: "Hilo,UNITED STATES",
  },
  {
    code: "ITP",
    name: "Itaperuna Intl Airport",
    Address: "Itaperuna,BRAZIL",
  },
  {
    code: "IUE",
    name: "Hanan Intl Airport",
    Address: "Niue Island,NIUE",
  },
  {
    code: "IVC",
    name: "Invercargill Intl Airport",
    Address: "Invercargill,NEW ZEALAND",
  },
  { code: "IVL", name: "Ivalo Intl Airport", Address: "Ivalo,FINLAND" },
  {
    code: "IVR",
    name: "Inverell Intl Airport",
    Address: "Inverell,AUSTRALIA",
  },
  {
    code: "IWA",
    name: "Ivanovo Intl Airport",
    Address: "Ivanovo,RUSSIA",
  },
  {
    code: "IWD",
    name: "Ironwood Intl Airport",
    Address: "Ironwood,UNITED STATES",
  },
  { code: "IWJ", name: "Iwami Intl Airport", Address: "Iwami,JAPAN" },
  {
    code: "IXA",
    name: "Singerbhil Intl Airport",
    Address: "Agartala,INDIA",
  },
  {
    code: "IXB",
    name: "Bagdogra Intl Airport",
    Address: "Bagdogra,INDIA",
  },
  {
    code: "IXC",
    name: "Chandigarh Intl Airport",
    Address: "Chandigarh,INDIA",
  },
  {
    code: "IXD",
    name: "Bamrauli Intl Airport",
    Address: "Allahabad,INDIA",
  },
  { code: "IXE", name: "Bajpe Intl Airport", Address: "Mangalore,INDIA" },
  { code: "IXG", name: "Sambre Intl Airport", Address: "Belgaum,INDIA" },
  {
    code: "IXI",
    name: "Lilabari Intl Airport",
    Address: "Lilabari,INDIA",
  },
  { code: "IXJ", name: "Satwari Intl Airport", Address: "Jammu,INDIA" },
  {
    code: "IXL",
    name: "Leh Kushok Bakula Rimpochee Intl Airport",
    Address: "Leh IN,INDIA",
  },
  { code: "IXM", name: "Madurai Intl Airport", Address: "Madurai,INDIA" },
  {
    code: "IXP",
    name: "Pathankot Intl Airport",
    Address: "Pathankot,INDIA",
  },
  {
    code: "IXR",
    name: "Birsa Munda Intl Airport",
    Address: "Ranchi,INDIA",
  },
  {
    code: "IXS",
    name: "Kumbhigram Intl Airport",
    Address: "Silchar,INDIA",
  },
  {
    code: "IXU",
    name: "Chikkalthana Intl Airport",
    Address: "Aurangabad,INDIA",
  },
  { code: "IXY", name: "Kandla Intl Airport", Address: "Kandla,INDIA" },
  {
    code: "IXZ",
    name: "Veer Savarkar Intl Airport",
    Address: "Port Blair,INDIA",
  },
  {
    code: "IYK",
    name: "Kern Cty Intl Airport",
    Address: "Inyokern,UNITED STATES",
  },
  {
    code: "ADB",
    name: "Adnan Menderes Intl Airport",
    Address: "Izmir,TURKEY",
  },
  { code: "IZO", name: "Izumo Intl Airport", Address: "Izumo,JAPAN" },
  {
    code: "IZT",
    name: "Ixtepec Intl Airport",
    Address: "Ixtepec,MEXICO",
  },
  {
    code: "JAB",
    name: "Jabiru Intl Airport",
    Address: "Jabiru,AUSTRALIA",
  },
  {
    code: "JAC",
    name: "Jackson Hole Intl Airport",
    Address: "Jackson,UNITED STATES",
  },
  {
    code: "JAI",
    name: "Sanganeer Intl Airport",
    Address: "Jaipur,INDIA",
  },
  { code: "JAL", name: "Jalapa Intl Airport", Address: "Jalapa,MEXICO" },
  {
    code: "HKS",
    name: "Hawkins Field",
    Address: "Jackson,UNITED STATES",
  },
  {
    code: "JAN",
    name: "Jackson Evers Intl Airport",
    Address: "Jackson,UNITED STATES",
  },
  {
    code: "JAP",
    name: "Punta Renes Intl Airport",
    Address: "Punta Renes,COSTA RICA",
  },
  {
    code: "JAV",
    name: "Ilulissat Intl Airport",
    Address: "Ilulissat,GREENLAND",
  },
  {
    code: "JAX",
    name: "Jacksonville Intl Airport",
    Address: "Jacksonville,UNITED STATES",
  },
  {
    code: "NIP",
    name: "Jacksonville Nas",
    Address: "Jacksonville,UNITED STATES",
  },
  {
    code: "NZC",
    name: "Cecil Field Nas",
    Address: "Jacksonville,UNITED STATES",
  },
  {
    code: "CRG",
    name: "Craig Municipal Intl Airport",
    Address: "Jacksonville,UNITED STATES",
  },
  {
    code: "JBQ",
    name: "Dr Joaquin Balaguer",
    Address: "Higuero,DOMINICAN REPUBLIC",
  },
  {
    code: "JBR",
    name: "Jonesboro Municipal",
    Address: "Jonesboro,UNITED STATES",
  },
  {
    code: "JCB",
    name: "Joacaba Intl Airport",
    Address: "Joacaba,BRAZIL",
  },
  {
    code: "JCK",
    name: "Julia Creek Intl Airport",
    Address: "Julia Creek,AUSTRALIA",
  },
  {
    code: "JDF",
    name: "Francisco De Assis Intl Airport",
    Address: "Juiz De Fora,BRAZIL",
  },
  { code: "JDH", name: "Jodhpur Intl Airport", Address: "Jodhpur,INDIA" },
  {
    code: "JDO",
    name: "Regional Do Cariri Intl Airport",
    Address: "Juazeiro Do Norte,BRAZIL",
  },
  {
    code: "JDZ",
    name: "Jingdezhen Intl Airport",
    Address: "Jingdezhen,CHINA",
  },
  { code: "JED", name: "Jeddah Intl ", Address: "Jeddah,SAUDI ARABIA" },
  {
    code: "JEF",
    name: "Jefferson City Memorial",
    Address: "Jefferson City,UNITED STATES",
  },
  {
    code: "JEG",
    name: "Aasiaat Intl Airport",
    Address: "Aasiaat,GREENLAND",
  },
  {
    code: "JER",
    name: "States Intl Airport",
    Address: "Jersey,UNITED KINGDOM",
  },
  {
    code: "JGA",
    name: "Govardhanpur Intl Airport",
    Address: "Jamnagar,INDIA",
  },
  {
    code: "JGN",
    name: "Jiayuguan Intl Airport",
    Address: "Jiayuguan,CHINA",
  },
  {
    code: "JGS",
    name: "Jing Gang Shan Intl Airport",
    Address: "Jian,CHINA",
  },
  {
    code: "JHB",
    name: "Sultan Ismail Intl Airport",
    Address: "Johor Bahru,MALAYSIA",
  },
  {
    code: "XWP",
    name: "Hassleholm Rail Station",
    Address: "Helsingborg,SWEDEN",
  },
  {
    code: "XYH",
    name: "Helsingborg Railway service",
    Address: "Helsingborg,SWEDEN",
  },
  {
    code: "JHG",
    name: "Jinghong Intl Airport",
    Address: "Jinghong,CHINA",
  },
  {
    code: "JHM",
    name: "Kapalua Intl Airport",
    Address: "Kapalua,UNITED STATES",
  },
  {
    code: "JHS",
    name: "Sisimiut Intl Airport",
    Address: "Sisimiut,GREENLAND",
  },
  {
    code: "JHW",
    name: "Chautauqua Cty Intl Airport",
    Address: "Jamestown,UNITED STATES",
  },
  {
    code: "JIB",
    name: "Ambouli Intl Airport",
    Address: "Djibouti,DJIBOUTI",
  },
  {
    code: "JIK",
    name: "Ikaria Intl Airport",
    Address: "Ikaria Island,GREECE",
  },
  { code: "JIL", name: "Jilin Intl Airport", Address: "Jilin,CHINA" },
  { code: "JIM", name: "Jimma Intl Airport", Address: "Jimma,ETHIOPIA" },
  {
    code: "JIU",
    name: "Jiujiang Intl Airport",
    Address: "Jiujiang,CHINA",
  },
  {
    code: "JJN",
    name: "Jinjiang Intl Airport",
    Address: "Jinjiang,CHINA",
  },
  {
    code: "JJU",
    name: "Qaqortoq Intl Airport",
    Address: "Qaqortoq,GREENLAND",
  },
  {
    code: "XYC",
    name: "Herrljunga Rail Station",
    Address: "Jonkoping,SWEDEN",
  },
  {
    code: "XYF",
    name: "Falkoping Rail Station",
    Address: "Jonkoping,SWEDEN",
  },
  {
    code: "JKG",
    name: "Axamo Intl Airport",
    Address: "Jonkoping,SWEDEN",
  },
  { code: "JKH", name: "Chios Intl Airport", Address: "Chios,GREECE" },
  {
    code: "JKL",
    name: "Kalymnos Intl Airport",
    Address: "Kalymnos Island,GREECE",
  },
  {
    code: "HLP",
    name: "Halim Perdana Kusuma Intl Airport",
    Address: "Jakarta,INDONESIA",
  },
  {
    code: "CGK",
    name: "Soekarno Hatta Intl ",
    Address: "Jakarta,INDONESIA",
  },
  {
    code: "JKV",
    name: "Cherokee County Intl Airport",
    Address: "Jacksonville,UNITED STATES",
  },
  {
    code: "JLN",
    name: "Joplin Municipal Intl Airport",
    Address: "Joplin,UNITED STATES",
  },
  {
    code: "JMC",
    name: "Marin County Intl Airport",
    Address: "Sausalito,UNITED STATES",
  },
  {
    code: "JMK",
    name: "Mykonos Greece Intl Airport",
    Address: "Mykonos,GREECE",
  },
  {
    code: "JMS",
    name: "Jamestown Intl Airport",
    Address: "Jamestown,UNITED STATES",
  },
  {
    code: "HLA",
    name: "Lanseria Intl Airport",
    Address: "Johannesburg,SOUTH AFRICA",
  },
  {
    code: "QRA",
    name: "Randgermiston Intl Airport",
    Address: "Johannesburg,SOUTH AFRICA",
  },
  {
    code: "GCJ",
    name: "Grand Central Intl Airport",
    Address: "Johannesburg,SOUTH AFRICA",
  },
  {
    code: "JNB",
    name: "O R Tambo Intl Airport",
    Address: "Johannesburg,SOUTH AFRICA",
  },
  {
    code: "JNN",
    name: "Nanortalik Intl Airport",
    Address: "Nanortalik,GREENLAND",
  },
  { code: "JNS", name: "Narsaq Heleport", Address: "Narsaq,GREENLAND" },
  {
    code: "JNU",
    name: "Juneau Intl Airport",
    Address: "Juneau,UNITED STATES",
  },
  { code: "JNX", name: "Naxos Intl Airport", Address: "Naxos,GREECE" },
  { code: "JNZ", name: "Jinzhou Intl Airport", Address: "Jinzhou,CHINA" },
  {
    code: "JOE",
    name: "Joensuu Intl Airport",
    Address: "Joensuu,FINLAND",
  },
  {
    code: "JOG",
    name: "Adisutjipto Intl Airport",
    Address: "Yogyakarta,INDONESIA",
  },
  {
    code: "JOI",
    name: "Cubatao Intl Airport",
    Address: "Joinville,BRAZIL",
  },
  {
    code: "JOK",
    name: "Yoshkar-Ola Intl Airport",
    Address: "Yoshkar-Ola Intl Airport,RUSSIA",
  },
  {
    code: "JOT",
    name: "Joliet Park District Intl Airport",
    Address: "Joliet,UNITED STATES",
  },
  {
    code: "JPA",
    name: "Castro Pinto Intl Airport",
    Address: "Joao Pessoa,BRAZIL",
  },
  {
    code: "JPR",
    name: "Ji Parana Intl Airport",
    Address: "Ji Parana,BRAZIL",
  },
  {
    code: "JQA",
    name: "Qaarsut Intl Airport",
    Address: "Qaarsut,GREENLAND",
  },
  { code: "JRH", name: "Rowriah Intl Airport", Address: "Jorhat,INDIA" },
  {
    code: "JRO",
    name: "Kilimanjaro Intl Airport",
    Address: "Kilimanjaro,TANZANIA",
  },
  {
    code: "JRS",
    name: "Atarot Intl Airport",
    Address: "Jerusalem,ISRAEL",
  },
  {
    code: "JSA",
    name: "Jaisalmer Intl Airport",
    Address: "Jaisalmer,INDIA",
  },
  {
    code: "JSI",
    name: "Skiathos Intl Airport",
    Address: "Skiathos,GREECE",
  },
  {
    code: "XEZ",
    name: "Sodertalje S Rail Station",
    Address: "Sodertalje,SWEDEN",
  },
  {
    code: "JST",
    name: "Johnstown Cambria Intl Airport",
    Address: "Johnstown,UNITED STATES",
  },
  {
    code: "JSU",
    name: "Maniitsoq Heleport",
    Address: "Maniitsoq,GREENLAND",
  },
  {
    code: "JSY",
    name: "Syros Island Intl Airport",
    Address: "Syros Island,GREECE",
  },
  {
    code: "JTR",
    name: "Santorini Intl Airport",
    Address: "Thira Island,GREECE",
  },
  {
    code: "JTY",
    name: "Astypalaia Intl Airport",
    Address: "Astypalaia Island,GREECE",
  },
  { code: "JUB", name: "Juba Intl Airport", Address: "Juba,SUDAN" },
  { code: "JUI", name: "Juist Intl Airport", Address: "Juist,GERMANY" },
  {
    code: "JUJ",
    name: "El Cadillal Intl Airport",
    Address: "Jujuy,ARGENTINA",
  },
  { code: "JUL", name: "Juliaca Intl Airport", Address: "Juliaca,PERU" },
  {
    code: "JUO",
    name: "Jurado Intl Airport",
    Address: "Jurado,COLOMBIA",
  },
  { code: "JUZ", name: "Juzhou Intl Airport", Address: "Juzhou,CHINA" },
  {
    code: "JVL",
    name: "Rock County Intl Airport",
    Address: "Beloit,UNITED STATES",
  },
  { code: "JWN", name: "Zanjan Intl Airport", Address: "Zanjan,IRAN" },
  {
    code: "JXN",
    name: "Jackson Reynolds Municipal",
    Address: "Jackson,UNITED STATES",
  },
  {
    code: "JYV",
    name: "Jyvaskyla Intl Airport",
    Address: "Jyvaskyla,FINLAND",
  },
  {
    code: "JZH",
    name: "Jiu Zhai Huang Long Intl Airport",
    Address: "Songpan,CHINA",
  },
  {
    code: "KAB",
    name: "Kariba Intl Airport",
    Address: "Kariba Dam,ZIMBABWE",
  },
  {
    code: "KAE",
    name: "Kake Intl Airport",
    Address: "Kake,UNITED STATES",
  },
  {
    code: "KAJ",
    name: "Kajaani Intl Airport",
    Address: "Kajaani,FINLAND",
  },
  {
    code: "KAN",
    name: "Aminu Kano Intl Airport",
    Address: "Kano,NIGERIA",
  },
  { code: "KAO", name: "Kuusamo", Address: "Kuusamo,FINLAND" },
  {
    code: "KAT",
    name: "Kaitaia Intl Airport",
    Address: "Kaitaia,NEW ZEALAND",
  },
  {
    code: "KBJ",
    name: "Kings Canyon Intl Airport",
    Address: "Kings Canyon,AUSTRALIA",
  },
  {
    code: "KBL",
    name: "Khwaja Rawash Intl Airport",
    Address: "Kabul,AFGHANISTAN",
  },
  {
    code: "KBR",
    name: "Pengkalan Chepa",
    Address: "Kota Bharu,MALAYSIA",
  },
  { code: "KBV", name: "Krabi Intl Airport", Address: "Krabi,THAILAND" },
  {
    code: "KBZ",
    name: "Kaikoura Intl Airport",
    Address: "Kaikoura,NEW ZEALAND",
  },
  {
    code: "KCE",
    name: "Collinsville Intl Airport",
    Address: "Collinsville,AUSTRALIA",
  },
  {
    code: "KCH",
    name: "Kuching Intl Airport",
    Address: "Kuching,MALAYSIA",
  },
  {
    code: "OJC",
    name: "Johnson Executive Intl Airport",
    Address: "Kansas City,UNITED STATES",
  },
  {
    code: "KCK",
    name: "Fairfax Municipal Intl Airport",
    Address: "Kansas City,UNITED STATES",
  },
  {
    code: "KCM",
    name: "Kahramanmaras Intl Airport",
    Address: "Kahramanmaras,TURKEY",
  },
  { code: "KCO", name: "Kocaeli", Address: "Kocaeli,TURKEY" },
  {
    code: "KCP",
    name: "Kamenets Podolski Intl Airport",
    Address: "Kamenets Podolski,UKRAINE",
  },
  { code: "KCZ", name: "Kochi Intl Airport", Address: "Kochi,JAPAN" },
  {
    code: "KDH",
    name: "Kandahar Intl Airport",
    Address: "Kandahar Intl Airport,AFGHANISTAN",
  },
  {
    code: "KEJ",
    name: "Kemerovo Intl Airport",
    Address: "Kemerovo,RUSSIA",
  },
  { code: "KEL", name: "Holtenau Intl Airport", Address: "Kiel,GERMANY" },
  {
    code: "KEM",
    name: "Kemi Tornio Intl Airport",
    Address: "Kemi,FINLAND",
  },
  { code: "KER", name: "Kerman Intl Airport", Address: "Kerman,IRAN" },
  {
    code: "KFS",
    name: "Kastamonu Intl Airport",
    Address: "Kastamonu,TURKEY",
  },
  {
    code: "KGC",
    name: "Kingscote Intl Airport",
    Address: "Kingscote,AUSTRALIA",
  },
  {
    code: "KGD",
    name: "Kaliningrad Intl Airport",
    Address: "Kaliningrad,RUSSIA",
  },
  {
    code: "KGF",
    name: "Karaganda Intl Airport",
    Address: "Karaganda,KAZAKHSTAN",
  },
  {
    code: "KGI",
    name: "Kalgoorlie Intl Airport",
    Address: "Kalgoorlie,AUSTRALIA",
  },
  { code: "KGL", name: "Kigali Intl Airport", Address: "Kigali,RWANDA" },
  {
    code: "KGP",
    name: "Kogalym Intl Airport",
    Address: "Kogalym Intl Airport,RUSSIA",
  },
  { code: "KGS", name: "Kos Intl Airport", Address: "Kos Gr,GREECE" },
  {
    code: "KHD",
    name: "Khorramabad Intl Airport",
    Address: "Khorramabad,IRAN",
  },
  { code: "KHG", name: "Kashi Intl Airport", Address: "Kashi,CHINA" },
  {
    code: "KHH",
    name: "Kaohsiung Intl ",
    Address: "Kaohsiung,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "KHI",
    name: "Quaid E Azam Intl ",
    Address: "Karachi,PAKISTAN",
  },
  {
    code: "KHN",
    name: "Nanchang Intl Airport",
    Address: "Nanchang,CHINA",
  },
  {
    code: "KHV",
    name: "Novyy Intl Airport",
    Address: "Khabarovsk,RUSSIA",
  },
  {
    code: "KIC",
    name: "Mesa Del Rey Intl Airport",
    Address: "King City,UNITED STATES",
  },
  {
    code: "XYU",
    name: "Solvesborg Rail Station",
    Address: "Kristianstad,SWEDEN",
  },
  {
    code: "KID",
    name: "Kristianstad Intl Airport",
    Address: "Kristianstad,SWEDEN",
  },
  {
    code: "KIH",
    name: "Kish Island Intl Airport",
    Address: "Kish Island,IRAN",
  },
  { code: "KIJ", name: "Niigata Intl Airport", Address: "Niigata,JAPAN" },
  {
    code: "KIM",
    name: "Kimberley Intl Airport",
    Address: "Kimberley,SOUTH AFRICA",
  },
  {
    code: "KIN",
    name: "Norman Manly Intl Airport",
    Address: "Kingston,JAMAICA",
  },
  {
    code: "KTP",
    name: "Tinson Intl Airport",
    Address: "Kingston,JAMAICA",
  },
  {
    code: "KIR",
    name: "Kerry County Intl Airport",
    Address: "Kerry County,IRELAND",
  },
  { code: "KIS", name: "Kisumu Intl Airport", Address: "Kisumu,KENYA" },
  {
    code: "KIT",
    name: "Kithira Intl Airport",
    Address: "Kithira,GREECE",
  },
  {
    code: "KIV",
    name: "Chisinau Intl Airport",
    Address: "Chisinau,MOLDOVA, REPUBLIC OF",
  },
  {
    code: "KJA",
    name: "Krasnojarsk Intl Airport",
    Address: "Krasnoyarsk,RUSSIA",
  },
  {
    code: "KJK",
    name: "Kortrijk Intl Airport",
    Address: "Kortrijk,BELGIUM",
  },
  {
    code: "KKC",
    name: "Khon Kaen Intl Airport",
    Address: "Khon Kaen,THAILAND",
  },
  {
    code: "KKE",
    name: "Kerikeri Intl Airport",
    Address: "Kerikeri,NEW ZEALAND",
  },
  {
    code: "KKJ",
    name: "Kokura Intl Airport",
    Address: "Kita Kyushu,JAPAN",
  },
  {
    code: "KKN",
    name: "Hoeybuktmoen Intl Airport",
    Address: "Kirkenes,NORWAY",
  },
  {
    code: "KKT",
    name: "Kentland Intl Airport",
    Address: "Kentland,UNITED STATES",
  },
  { code: "KLD", name: "Migalovo Intl Airport", Address: "Tver,RUSSIA" },
  {
    code: "KLF",
    name: "Kaluga Grabtsevo Intl Airport INA",
    Address: "Kaluga Grabtsevo Intl Airport INA,RUSSIA",
  },
  {
    code: "KLG",
    name: "Kalskag Municipal Intl Airport",
    Address: "Kalskag,UNITED STATES",
  },
  {
    code: "KLH",
    name: "Kolhapur Intl Airport",
    Address: "Kolhapur,INDIA",
  },
  {
    code: "KLJ",
    name: "Klaipeda Intl Airport",
    Address: "Klaipeda,LITHUANIA",
  },
  {
    code: "KLO",
    name: "Kalibo Intl Airport",
    Address: "Kalibo,PHILIPPINES",
  },
  { code: "KLR", name: "Kalmar Intl Airport", Address: "Kalmar,SWEDEN" },
  {
    code: "KLS",
    name: "Kelso Longview Regional Intl Airport",
    Address: "Kelso,UNITED STATES",
  },
  {
    code: "KLT",
    name: "Kaiserslautern Rail Station",
    Address: "Kaiserslautern,GERMANY",
  },
  {
    code: "KLU",
    name: "Klagenfurt Intl Airport",
    Address: "Klagenfurt,AUSTRIA",
  },
  {
    code: "KLV",
    name: "Karlovy Vary Intl Airport",
    Address: "Karlovy Vary,CZECH REPUBLIC",
  },
  {
    code: "KLW",
    name: "Klawock Intl Airport",
    Address: "Klawock,UNITED STATES",
  },
  {
    code: "KLX",
    name: "Kalamata Intl Airport",
    Address: "Kalamata,GREECE",
  },
  { code: "KMG", name: "Kunming Intl Airport", Address: "Kunming,CHINA" },
  {
    code: "KMH",
    name: "Kuruman Intl Airport",
    Address: "Kuruman,SOUTH AFRICA",
  },
  {
    code: "KMI",
    name: "Miyazaki Intl Airport",
    Address: "Miyazaki,JAPAN",
  },
  {
    code: "KMJ",
    name: "Kumamoto Intl Airport",
    Address: "Kumamoto,JAPAN",
  },
  { code: "KMQ", name: "Komatsu Intl Airport", Address: "Komatsu,JAPAN" },
  { code: "KMS", name: "Sunyani", Address: "Kumasi,GHANA" },
  {
    code: "KMW",
    name: "Kostroma Intl Airport",
    Address: "Kostroma,RUSSIA",
  },
  {
    code: "KMX",
    name: "Khamis Mushait Intl Airport",
    Address: "Khamis Mushait,SAUDI ARABIA",
  },
  {
    code: "KNA",
    name: "Vina Del Mar Intl Airport",
    Address: "Vina Del Mar,CHILE",
  },
  {
    code: "KNF",
    name: "Marham Raf",
    Address: "Kings Lynn,UNITED KINGDOM",
  },
  {
    code: "KNH",
    name: "Shang Yi Intl Airport",
    Address: "Kinmen,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "KNS",
    name: "King Island Intl Airport",
    Address: "King Island,AUSTRALIA",
  },
  {
    code: "KNT",
    name: "Kennett Municipal Intl Airport",
    Address: "Kennett,UNITED STATES",
  },
  { code: "KNU", name: "Chakeri Intl Airport", Address: "Kanpur,INDIA" },
  {
    code: "KNX",
    name: "Kununurra Intl Airport",
    Address: "Kununurra,AUSTRALIA",
  },
  {
    code: "KOA",
    name: "Keahole Intl Airport",
    Address: "Kona,UNITED STATES",
  },
  {
    code: "KOB",
    name: "Koutaba Intl Airport",
    Address: "Koutaba,CAMEROON",
  },
  {
    code: "KOC",
    name: "Koumac Intl Airport",
    Address: "Koumac,NEW CALEDONIA",
  },
  {
    code: "KOI",
    name: "Kirkwall Intl Airport",
    Address: "Kirkwall,UNITED KINGDOM",
  },
  {
    code: "KOJ",
    name: "Kagoshima Intl Airport",
    Address: "Kagoshima,JAPAN",
  },
  {
    code: "KOK",
    name: "Kokkola Intl Airport",
    Address: "Kokkola,FINLAND",
  },
  {
    code: "KOP",
    name: "Nakhon Phanom Intl Airport",
    Address: "Nakhon Phanom,THAILAND",
  },
  {
    code: "KOR",
    name: "Kokoro Intl Airport",
    Address: "Kokoro,PAPUA NEW GUINEA",
  },
  {
    code: "KOS",
    name: "Sihanoukville Intl Airport",
    Address: "Sihanoukville,CAMBODIA",
  },
  { code: "KOV", name: "KOKSHETAU", Address: "KOKSHETAU,KAZAKHSTAN" },
  { code: "KOW", name: "Ganzhou Intl Airport", Address: "Ganzhou,CHINA" },
  {
    code: "KPD",
    name: "King Of Prussia Intl Airport",
    Address: "King Of Prussia,UNITED STATES",
  },
  {
    code: "KPO",
    name: "Pohang Intl Airport",
    Address: "Pohang,KOREA, REPUBLIC OF",
  },
  {
    code: "KPS",
    name: "Kempsey Intl Airport",
    Address: "Kempsey,AUSTRALIA",
  },
  {
    code: "KRB",
    name: "Karumba Intl Airport",
    Address: "Karumba,AUSTRALIA",
  },
  {
    code: "KRF",
    name: "Kramfors Intl Airport",
    Address: "Kramfors,SWEDEN",
  },
  { code: "KRK", name: "John Paul Ii Intl ", Address: "Krakow,POLAND" },
  { code: "KRL", name: "Korla Intl Airport", Address: "Korla,CHINA" },
  { code: "KRN", name: "Kiruna Intl Airport", Address: "Kiruna,SWEDEN" },
  {
    code: "KRO",
    name: "Kurgan Intl Airport",
    Address: "Kurgan Intl Airport,RUSSIA",
  },
  { code: "KRP", name: "Karup Intl Airport", Address: "Karup,DENMARK" },
  {
    code: "KRR",
    name: "Krasnodar Intl Airport",
    Address: "Krasnodar,RUSSIA",
  },
  {
    code: "XGD",
    name: "Arendal Rail Station",
    Address: "Kristiansand,NORWAY",
  },
  {
    code: "XKR",
    name: "Kristiansand Rail Station",
    Address: "Kristiansand,NORWAY",
  },
  {
    code: "KRS",
    name: "Kjevik Intl Airport",
    Address: "Kristiansand,NORWAY",
  },
  { code: "KRT", name: "Civil Intl Airport", Address: "Khartoum,SUDAN" },
  {
    code: "KRW",
    name: "Turkmanbashi Intl Airport",
    Address: "Turkmanbashi,TURKMENISTAN",
  },
  { code: "KRY", name: "Karamay Intl Airport", Address: "Karamay,CHINA" },
  { code: "KSC", name: "Barca Intl Airport", Address: "Kosice,SLOVAKIA" },
  {
    code: "KSD",
    name: "Karlstad Intl Airport",
    Address: "Karlstad,SWEDEN",
  },
  {
    code: "KSF",
    name: "Kassel Calden Intl Airport",
    Address: "Kassel,GERMANY",
  },
  {
    code: "KSH",
    name: "Kermanshah Intl Airport",
    Address: "Kermanshah,IRAN",
  },
  {
    code: "KSN",
    name: "Kostanay Intl Airport",
    Address: "Kostanay,KAZAKHSTAN",
  },
  {
    code: "KSO",
    name: "Aristoteles Intl Airport",
    Address: "Kastoria,GREECE",
  },
  {
    code: "KSQ",
    name: "Karshi Intl Airport",
    Address: "Karshi Intl Airport,UZBEKISTAN",
  },
  {
    code: "KSR",
    name: "SANDY RIVER",
    Address: "SANDY RIVER,UNITED STATES",
  },
  {
    code: "XGI",
    name: "Andalsnes Rail Station",
    Address: "Kristiansund,NORWAY",
  },
  {
    code: "KSU",
    name: "Kvernberget Intl Airport",
    Address: "Kristiansund,NORWAY",
  },
  {
    code: "KSW",
    name: "Kiryat Shmona Intl Airport",
    Address: "Kiryat Shmona,ISRAEL",
  },
  { code: "KSY", name: "Kars Intl Airport", Address: "Kars,TURKEY" },
  {
    code: "KSZ",
    name: "Kotlas Intl Airport",
    Address: "Kotlas Intl Airport,RUSSIA",
  },
  {
    code: "KTA",
    name: "Karratha Intl Airport",
    Address: "Karratha,AUSTRALIA",
  },
  {
    code: "KTE",
    name: "Kertech Intl Airport",
    Address: "Kerteh,MALAYSIA",
  },
  {
    code: "KTM",
    name: "Tribuvan Intl Airport",
    Address: "Kathmandu,NEPAL",
  },
  {
    code: "KTN",
    name: "Ketchikan Intl Airport",
    Address: "Ketchikan,UNITED STATES",
  },
  {
    code: "KTR",
    name: "Tindal Intl Airport",
    Address: "Katherine,AUSTRALIA",
  },
  {
    code: "KTS",
    name: "Brevig Mission Intl Airport",
    Address: "Brevig Mission,UNITED STATES",
  },
  {
    code: "KTT",
    name: "Kittila Intl Airport",
    Address: "Kittila,FINLAND",
  },
  { code: "KTW", name: "Pyrzowice", Address: "Katowice,POLAND" },
  {
    code: "KUA",
    name: "Kuantan Intl Airport",
    Address: "Kuantan,MALAYSIA",
  },
  {
    code: "KUB",
    name: "Kuala Belait Intl Airport",
    Address: "Kuala Belait,BRUNEI DARUSSALAM",
  },
  { code: "KUD", name: "Kudat Intl Airport", Address: "Kudat,MALAYSIA" },
  { code: "KUF", name: "Samara Intl Airport", Address: "Samara,RUSSIA" },
  { code: "KUH", name: "Kushiro Intl Airport", Address: "Kushiro,JAPAN" },
  {
    code: "KUL",
    name: "Kuala Lumpur Intl Airport",
    Address: "Kuala Lumpur,MALAYSIA",
  },
  {
    code: "SZB",
    name: "Sultan Abdul Aziz Shah Intl Airport",
    Address: "Kuala Lumpur,MALAYSIA",
  },
  {
    code: "KUN",
    name: "Kaunas Intl Airport",
    Address: "Kaunas,LITHUANIA",
  },
  { code: "KUO", name: "Kuopio Intl Airport", Address: "Kuopio,FINLAND" },
  {
    code: "KUS",
    name: "Kulusuk Intl Airport",
    Address: "Kulusuk,GREENLAND",
  },
  {
    code: "KUT",
    name: "Kutaisi Intl Airport",
    Address: "Kutaisi Intl Airport,GEORGIA",
  },
  { code: "KUU", name: "Bhuntar Intl Airport", Address: "Kulu,INDIA" },
  {
    code: "KUV",
    name: "Kunsan Intl Airport",
    Address: "Kunsan,KOREA, REPUBLIC OF",
  },
  { code: "KVA", name: "Megas Alexandros", Address: "Kavalla,GREECE" },
  { code: "KVB", name: "Skovde Intl Airport", Address: "Skovde,SWEDEN" },
  { code: "KVD", name: "Ganja", Address: "Ganja,AZERBAIJAN" },
  {
    code: "KVG",
    name: "Kavieng Intl Airport",
    Address: "Kavieng,PAPUA NEW GUINEA",
  },
  {
    code: "KVK",
    name: "Kirovsk-Apatity Intl Airport",
    Address: "Kirovsk-Apatity Intl Airport,RUSSIA",
  },
  {
    code: "KVX",
    name: "Pobedilovo Intl Airport",
    Address: "Kirov,RUSSIA",
  },
  {
    code: "KWA",
    name: "Kwajalein Intl Airport",
    Address: "Kwajalein,MARSHALL ISLANDS",
  },
  { code: "KWE", name: "Guiyang Intl Airport", Address: "Guiyang,CHINA" },
  {
    code: "KWG",
    name: "Krivoy Rog Intl Airport",
    Address: "Krivoy Rog,UKRAINE",
  },
  { code: "KWI", name: "Kuwait Intl Airport", Address: "Kuwait,KUWAIT" },
  {
    code: "KWJ",
    name: "Kwangju Intl Airport",
    Address: "Kwangju,KOREA, REPUBLIC OF",
  },
  { code: "KWL", name: "Guilin Intl Airport", Address: "Guilin,CHINA" },
  {
    code: "KXK",
    name: "Komsomolsk Na Amure Intl Airport",
    Address: "Komsomolsk Na Amure,RUSSIA",
  },
  { code: "KYA", name: "Konya Intl Airport", Address: "Konya,TURKEY" },
  {
    code: "KYL",
    name: "Port Largo Intl Airport",
    Address: "Key Largo,UNITED STATES",
  },
  {
    code: "KYN",
    name: "Milton Keynes Rail Station",
    Address: "Milton Keynes,UNITED KINGDOM",
  },
  { code: "KYS", name: "KAYES", Address: "KAYES,MALI" },
  {
    code: "KYZ",
    name: "Kyzyl Intl Airport",
    Address: "Kyzyl Intl Airport,RUSSIA",
  },
  {
    code: "KZB",
    name: "Zachar Bay Sea Plane Base",
    Address: "Zachar Bay,UNITED STATES",
  },
  {
    code: "KZI",
    name: "Philippos Intl Airport",
    Address: "Kozani,GREECE",
  },
  { code: "KZN", name: "Kazan Intl Airport", Address: "Kazan,RUSSIA" },
  {
    code: "KZO",
    name: "Kzyl Orda Intl Airport",
    Address: "Kzyl Orda,KAZAKHSTAN",
  },
  {
    code: "KZR",
    name: "Zafer Intl Airport",
    Address: "K\u00fctahya Zafer Intl Airport,TURKEY",
  },
  {
    code: "KZS",
    name: "Kastelorizo Intl Airport",
    Address: "Kastelorizo,GREECE",
  },
  {
    code: "LAA",
    name: "Lamar Intl Airport",
    Address: "Lamar,UNITED STATES",
  },
  {
    code: "LAD",
    name: "Four De Fevereiro Intl Airport",
    Address: "Luanda,ANGOLA",
  },
  {
    code: "LAE",
    name: "Nadzab Intl Airport",
    Address: "Lae Pg,PAPUA NEW GUINEA",
  },
  {
    code: "LAF",
    name: "Lafayette Intl Airport",
    Address: "Lafayette,UNITED STATES",
  },
  {
    code: "LAG",
    name: "La Guaira Intl Airport",
    Address: "La Guaira Intl Airport,VENEZUELA",
  },
  { code: "LAI", name: "Servel Intl Airport", Address: "Lannion,FRANCE" },
  { code: "LAJ", name: "Lages Intl Airport", Address: "Lajes,BRAZIL" },
  {
    code: "LAL",
    name: "Lakeland Intl Airport",
    Address: "Lakeland,UNITED STATES",
  },
  {
    code: "LAM",
    name: "Los Alamos Municipal",
    Address: "Los Alamos,UNITED STATES",
  },
  {
    code: "LAN",
    name: "Lansing Intl Airport",
    Address: "Lansing,UNITED STATES",
  },
  {
    code: "LAO",
    name: "Laoag Intl Airport",
    Address: "Laoag,PHILIPPINES",
  },
  {
    code: "LAP",
    name: "Aeropuerto Gen Marquez De Leon",
    Address: "La Paz,MEXICO",
  },
  {
    code: "SAA",
    name: "Lar Shively Intl Airport",
    Address: "Laramie,UNITED STATES",
  },
  {
    code: "LAR",
    name: "General Brees Fld",
    Address: "Laramie,UNITED STATES",
  },
  {
    code: "HSH",
    name: "Henderson Sky Harbor Intl Airport",
    Address: "Las Vegas,UNITED STATES",
  },
  {
    code: "VGT",
    name: "Las Vegas North Air Terminal",
    Address: "Las Vegas,UNITED STATES",
  },
  {
    code: "LAS",
    name: "McCarran Intl ",
    Address: "Las Vegas,UNITED STATES",
  },
  {
    code: "BLD",
    name: "Boulder City Municipal Intl Airport",
    Address: "Las Vegas,UNITED STATES",
  },
  { code: "LAU", name: "Lamu Intl Airport", Address: "Lamu,KENYA" },
  {
    code: "LAW",
    name: "Lawton Municipal",
    Address: "Lawton,UNITED STATES",
  },
  {
    code: "VNY",
    name: "Van Nuys Intl Airport",
    Address: "Los Angeles,UNITED STATES",
  },
  {
    code: "SMO",
    name: "Santa Monica Municipal Intl Airport",
    Address: "Los Angeles,UNITED STATES",
  },
  {
    code: "LAX",
    name: "Los Angeles Intl Airport",
    Address: "Los Angeles,UNITED STATES",
  },
  {
    code: "LAY",
    name: "Ladysmith Intl Airport",
    Address: "Ladysmith,SOUTH AFRICA",
  },
  {
    code: "LBA",
    name: "Leeds Bradford Intl Airport",
    Address: "Leeds,UNITED KINGDOM",
  },
  {
    code: "LBB",
    name: "Lubbock Preston Smith Intl Airport",
    Address: "Lubbock,UNITED STATES",
  },
  {
    code: "LBD",
    name: "Khudzhand Intl Airport",
    Address: "LBD,TAJIKISTAN",
  },
  {
    code: "LBE",
    name: "Westmorland County",
    Address: "Latrobe,UNITED STATES",
  },
  {
    code: "LBF",
    name: "Lee Bird Field",
    Address: "North Platte,UNITED STATES",
  },
  {
    code: "LBI",
    name: "Le Sequestre Intl Airport",
    Address: "Albi,FRANCE",
  },
  {
    code: "LBL",
    name: "Liberal Municipal",
    Address: "Liberal,UNITED STATES",
  },
  {
    code: "LBP",
    name: "Long Banga Airfield Intl Airport",
    Address: "Long Banga,MALAYSIA",
  },
  { code: "LBS", name: "Labasa Intl Airport", Address: "Labasa,FIJI" },
  {
    code: "LBT",
    name: "Lumberton Intl Airport",
    Address: "Lumberton,UNITED STATES",
  },
  {
    code: "LBU",
    name: "Labuan Intl Airport",
    Address: "Labuan,MALAYSIA",
  },
  {
    code: "LBV",
    name: "Libreville Intl Airport",
    Address: "Libreville,GABON",
  },
  {
    code: "LBY",
    name: "Montoir Intl Airport",
    Address: "La Baule,FRANCE",
  },
  { code: "LCA", name: "Larnaca Intl ", Address: "Larnaca,CYPRUS" },
  { code: "LCC", name: "Galatina Intl Airport", Address: "Lecce,ITALY" },
  {
    code: "LCE",
    name: "Goloson Intl Airport",
    Address: "La Ceiba,HONDURAS",
  },
  {
    code: "LCF",
    name: "Las Vegas Intl Airport",
    Address: "Rio Dulce,GUATEMALA",
  },
  {
    code: "LCG",
    name: "La Coruna Intl Airport",
    Address: "La Coruna,SPAIN",
  },
  {
    code: "LCH",
    name: "Lake Charles Municipal",
    Address: "Lake Charles,UNITED STATES",
  },
  {
    code: "LCI",
    name: "Laconia Municipal Intl Airport",
    Address: "Laconia,UNITED STATES",
  },
  {
    code: "LCJ",
    name: "Lodz Lublinek Intl Airport",
    Address: "Lodz,POLAND",
  },
  { code: "LCV", name: "Lucca Intl Airport", Address: "Lucca,ITALY" },
  {
    code: "LDB",
    name: "Londrina Intl Airport",
    Address: "Londrina,BRAZIL",
  },
  { code: "LDE", name: "Tarbes Intl Airport", Address: "Lourdes,FRANCE" },
  {
    code: "LDG",
    name: "Leshukonskoye Intl Airport",
    Address: "Leshukonskoye Intl Airport,RUSSIA",
  },
  {
    code: "LDH",
    name: "Lord Howe Island Intl Airport",
    Address: "Lord Howe Island,AUSTRALIA",
  },
  {
    code: "LDJ",
    name: "Linden Municipal",
    Address: "Linden,UNITED STATES",
  },
  {
    code: "LDK",
    name: "Hovby Intl Airport",
    Address: "Lidkoping,SWEDEN",
  },
  {
    code: "LDU",
    name: "Lahad Datu Intl Airport",
    Address: "Lahad Datu,MALAYSIA",
  },
  {
    code: "LDX",
    name: "Saint Laurent du Maroni Intl Airport",
    Address: "Saint Laurent du Maroni,FRENCH GUIANA",
  },
  {
    code: "LDY",
    name: "Eglinton Intl Airport",
    Address: "Londonderry,UNITED KINGDOM",
  },
  {
    code: "LEA",
    name: "Learmonth Intl Airport",
    Address: "Learmonth,AUSTRALIA",
  },
  {
    code: "CNH",
    name: "Claremont Municipal",
    Address: "Lebanon,UNITED STATES",
  },
  {
    code: "LEB",
    name: "Lebanon Regional",
    Address: "Lebanon,UNITED STATES",
  },
  {
    code: "LEC",
    name: "Chapada Diamantina Intl Airport",
    Address: "Lencois,BRAZIL",
  },
  {
    code: "LED",
    name: "Pulkovo Intl Airport",
    Address: "St Petersburg,RUSSIA",
  },
  {
    code: "RVH",
    name: "Rzhevka Intl Airport",
    Address: "St Petersburg,RUSSIA",
  },
  {
    code: "LEE",
    name: "Leesburg Municipal Intl Airport",
    Address: "Leesburg,UNITED STATES",
  },
  {
    code: "LEH",
    name: "Octeville Intl Airport",
    Address: "Le Havre,FRANCE",
  },
  { code: "LEI", name: "Almeria Intl Airport", Address: "Almeria,SPAIN" },
  {
    code: "LEJ",
    name: "Leipzig Intl Airport",
    Address: "Leipzig,GERMANY",
  },
  { code: "LEN", name: "Leon Intl Airport", Address: "Leon,SPAIN" },
  {
    code: "LEQ",
    name: "St Just Intl Airport",
    Address: "Lands End,UNITED KINGDOM",
  },
  {
    code: "LER",
    name: "Leinster Intl Airport",
    Address: "Leinster,AUSTRALIA",
  },
  {
    code: "LES",
    name: "Lesobeng Intl Airport",
    Address: "Lesobeng,LESOTHO",
  },
  {
    code: "LET",
    name: "General A V Cobo Intl Airport",
    Address: "Leticia,COLOMBIA",
  },
  {
    code: "LEW",
    name: "Auburn Lewiston Intl Airport",
    Address: "Lewiston,UNITED STATES",
  },
  {
    code: "LEX",
    name: "Blue Grass Field",
    Address: "Lexington,UNITED STATES",
  },
  {
    code: "LEY",
    name: "Lelystad Intl Airport",
    Address: "Lelystad,NETHERLANDS",
  },
  {
    code: "LFK",
    name: "Angelina Cty Intl Airport",
    Address: "Lufkin,UNITED STATES",
  },
  {
    code: "LFT",
    name: "Lafayette Municipal",
    Address: "Lafayette,UNITED STATES",
  },
  { code: "LFW", name: "Lome Intl Airport", Address: "Lome,TOGO" },
  {
    code: "LGB",
    name: "Long Beach Municipal",
    Address: "Long Beach,UNITED STATES",
  },
  {
    code: "LGC",
    name: "Calloway Intl Airport",
    Address: "La Grange,UNITED STATES",
  },
  { code: "LGG", name: "Bierset Intl Airport", Address: "Liege,BELGIUM" },
  {
    code: "LGI",
    name: "Deadmans Cay Intl Airport",
    Address: "Deadmans Cay,BAHAMAS",
  },
  {
    code: "LGK",
    name: "Langkawi Intl Airport",
    Address: "Langkawi,MALAYSIA",
  },
  {
    code: "LGL",
    name: "Long Lellang Intl Airport",
    Address: "Long Lellang,MALAYSIA",
  },
  {
    code: "LGP",
    name: "Legaspi Intl Airport",
    Address: "Legaspi,PHILIPPINES",
  },
  {
    code: "LGS",
    name: "Malargue Intl Airport",
    Address: "Malargue,ARGENTINA",
  },
  {
    code: "LGU",
    name: "Logan Intl Airport",
    Address: "Logan,UNITED STATES",
  },
  { code: "LHA", name: "Lahr Intl Airport", Address: "Lahr,GERMANY" },
  {
    code: "LHE",
    name: "Lahore Intl Airport",
    Address: "Lahore,PAKISTAN",
  },
  {
    code: "LHG",
    name: "Lightning Ridge Intl Airport",
    Address: "Lightning Ridge,AUSTRALIA",
  },
  { code: "LHW", name: "Lanzhou Intl Airport", Address: "Lanzhou,CHINA" },
  {
    code: "LIA",
    name: "Lianping Intl Airport",
    Address: "Lianping,CHINA",
  },
  {
    code: "LIF",
    name: "Lifou Intl Airport",
    Address: "Lifou,NEW CALEDONIA",
  },
  {
    code: "LIG",
    name: "Bellegarde Intl Airport",
    Address: "Limoges,FRANCE",
  },
  {
    code: "LIH",
    name: "Lihue Municipal",
    Address: "Lihue,UNITED STATES",
  },
  {
    code: "XDB",
    name: "Europe Railway Service",
    Address: "Lille,FRANCE",
  },
  { code: "LIL", name: "Lesquin Intl Airport", Address: "Lille,FRANCE" },
  {
    code: "XFA",
    name: "Lille Flanders Rail Service",
    Address: "Lille,FRANCE",
  },
  {
    code: "LIM",
    name: "Jorge Chavez Intl Airport",
    Address: "Lima,PERU",
  },
  {
    code: "LIO",
    name: "Limon Intl Airport",
    Address: "Limon,COSTA RICA",
  },
  {
    code: "LIR",
    name: "Liberia Intl Airport",
    Address: "Liberia,COSTA RICA",
  },
  { code: "LIS", name: "Lisboa", Address: "Lisbon,PORTUGAL" },
  {
    code: "LIT",
    name: "Little Rock Regional",
    Address: "Little Rock,UNITED STATES",
  },
  {
    code: "LJG",
    name: "Lijiang Intl Airport",
    Address: "Lijiang City,CHINA",
  },
  {
    code: "LJN",
    name: "Lake Jackson Intl Airport",
    Address: "Lake Jackson,UNITED STATES",
  },
  {
    code: "LJU",
    name: "Brnik Intl Airport",
    Address: "Ljubljana,SLOVENIA",
  },
  {
    code: "LKG",
    name: "Lokichoggio Intl Airport",
    Address: "Lokichoggio,KENYA",
  },
  {
    code: "LKH",
    name: "Long Akah Intl Airport",
    Address: "Long Akah,MALAYSIA",
  },
  { code: "LKL", name: "Banak Intl Airport", Address: "Lakselv,NORWAY" },
  { code: "LKN", name: "Leknes Intl Airport", Address: "Leknes,NORWAY" },
  { code: "LKO", name: "Amausi Intl Airport", Address: "Lucknow,INDIA" },
  { code: "LLA", name: "Kallax Intl Airport", Address: "Lulea,SWEDEN" },
  {
    code: "LLE",
    name: "Municipal Intl Airport",
    Address: "Malelane,SOUTH AFRICA",
  },
  {
    code: "LLF",
    name: "Ling Ling Intl Airport",
    Address: "Ling Ling,CHINA",
  },
  {
    code: "LLK",
    name: "Lankaran Intl Airport",
    Address: "Lankaran Intl Airport,AZERBAIJAN",
  },
  {
    code: "LLU",
    name: "Alluitsup Paa Intl Airport",
    Address: "Alluitsup Paa,GREENLAND",
  },
  {
    code: "LLW",
    name: "Lilongwe Intl Airport",
    Address: "Lilongwe,MALAWI",
  },
  {
    code: "LLY",
    name: "Burlington Country Intl Airport",
    Address: "Mount Holly,UNITED STATES",
  },
  { code: "LME", name: "Arnage Intl Airport", Address: "Le Mans,FRANCE" },
  {
    code: "LMM",
    name: "Federal Los Mochis Intl Airport",
    Address: "Los Mochis,MEXICO",
  },
  {
    code: "LMN",
    name: "Limbang Intl Airport",
    Address: "Limbang,MALAYSIA",
  },
  {
    code: "LMP",
    name: "Lampedusa Intl Airport",
    Address: "Lampedusa,ITALY",
  },
  {
    code: "LMS",
    name: "Louisville Winston County Intl Airport",
    Address: "Louisville,UNITED STATES",
  },
  {
    code: "LMT",
    name: "Kingsley Field",
    Address: "Klamath Falls,UNITED STATES",
  },
  {
    code: "LNK",
    name: "Lincoln Municipal",
    Address: "Lincoln,UNITED STATES",
  },
  {
    code: "LNO",
    name: "Leonora Intl Airport",
    Address: "Leonora,AUSTRALIA",
  },
  {
    code: "LNS",
    name: "Lancaster Intl Airport",
    Address: "Lancaster,UNITED STATES",
  },
  {
    code: "LNV",
    name: "Lihir Island Intl Airport",
    Address: "Lihir Island,PAPUA NEW GUINEA",
  },
  {
    code: "LNY",
    name: "Lanai Intl Airport",
    Address: "Lanai,UNITED STATES",
  },
  {
    code: "LNZ",
    name: "Hoersching Intl Airport",
    Address: "Linz,AUSTRIA",
  },
  { code: "LOE", name: "Loei Intl Airport", Address: "Loei,THAILAND" },
  {
    code: "LOH",
    name: "Aeropuerto Camilo Enriquez",
    Address: "Loja,ECUADOR",
  },
  {
    code: "LOM",
    name: "Francisco P V Y R",
    Address: "Lagos De Moreno,MEXICO",
  },
  {
    code: "QQP",
    name: "London - Paddington Rail Service",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "QQS",
    name: "QQS St Pancras Intl  Rail St",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "QQU",
    name: "London - Euston Rail Service",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "QQW",
    name: "London - Waterloo Rail Service",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "XQE",
    name: "XQE Ebbsfleet Kent Rail St",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "ZEP",
    name: "London - Victoria Railway Station",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "LGW",
    name: "Gatwick Intl Airport",
    Address: "London,UNITED KINGDOM",
  },
  { code: "LHR", name: "Heathrow", Address: "London,UNITED KINGDOM" },
  {
    code: "QQK",
    name: "London - Kings Cross Rail Service",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "LCY",
    name: "London City Intl Airport",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "STN",
    name: "Stansted Intl Airport",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "QQH",
    name: "Harwich Rail Station",
    Address: "London,UNITED KINGDOM",
  },
  {
    code: "LOS",
    name: "Murtala Muhammed Intl Airport",
    Address: "Lagos,NIGERIA",
  },
  {
    code: "LOV",
    name: "Monclova Intl Airport",
    Address: "Monclova,MEXICO",
  },
  {
    code: "LOZ",
    name: "Corbin London Intl Airport",
    Address: "London,UNITED STATES",
  },
  {
    code: "LPA",
    name: "Aeropuerto De Gran Canaria",
    Address: "Gran Canaria,SPAIN",
  },
  {
    code: "LPB",
    name: "El Alto Intl Airport",
    Address: "La Paz,BOLIVIA",
  },
  {
    code: "LPG",
    name: "La Plata Intl Airport",
    Address: "La Plata,ARGENTINA",
  },
  {
    code: "LPH",
    name: "Lochgilphead Heliport",
    Address: "Lochgilphead,UNITED KINGDOM",
  },
  { code: "LPI", name: "Saab Intl Airport", Address: "Linkoping,SWEDEN" },
  {
    code: "LPK",
    name: "Lipetsk Intl Airport",
    Address: "Lipetsk Intl Airport,RUSSIA",
  },
  {
    code: "LPL",
    name: "Liverpool Intl Airport",
    Address: "Liverpool,UNITED KINGDOM",
  },
  {
    code: "ZLS",
    name: "Liverpool Street Station",
    Address: "Liverpool,UNITED KINGDOM",
  },
  {
    code: "XWN",
    name: "Warrington B Q Rail Station",
    Address: "Liverpool,UNITED KINGDOM",
  },
  {
    code: "LPO",
    name: "La Porte Intl Airport",
    Address: "La Porte,UNITED STATES",
  },
  {
    code: "LPP",
    name: "Lappeenranta Intl Airport",
    Address: "Lappeenranta,FINLAND",
  },
  {
    code: "LPQ",
    name: "Louangphrabang Intl Airport",
    Address: "Louangphrabang,LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  {
    code: "LPS",
    name: "Lopez Island Intl Airport",
    Address: "Lopez Island,UNITED STATES",
  },
  {
    code: "LPT",
    name: "Lampang Intl Airport",
    Address: "Lampang,THAILAND",
  },
  {
    code: "LPX",
    name: "Liepaya Intl Airport",
    Address: "Liepaja,LATVIA",
  },
  { code: "LPY", name: "Loudes Intl Airport", Address: "Le Puy,FRANCE" },
  { code: "LRA", name: "Larisa Intl Airport", Address: "Larisa,GREECE" },
  { code: "LRD", name: "Laredo Intl ", Address: "Laredo,UNITED STATES" },
  {
    code: "LRE",
    name: "Longreach Intl Airport",
    Address: "Longreach,AUSTRALIA",
  },
  {
    code: "LRH",
    name: "Laleu Intl Airport",
    Address: "La Rochelle,FRANCE",
  },
  {
    code: "LRM",
    name: "La Romana Intl Airport",
    Address: "La Romana,DOMINICAN REPUBLIC",
  },
  { code: "LRS", name: "Leros Intl Airport", Address: "Leros,GREECE" },
  {
    code: "LRT",
    name: "Lann Bihoue Intl Airport",
    Address: "Lorient,FRANCE",
  },
  {
    code: "LRU",
    name: "Las Cruces Crawford Intl Airport",
    Address: "Las Cruces,UNITED STATES",
  },
  { code: "LSC", name: "La Florida", Address: "La Serena,CHILE" },
  {
    code: "LSE",
    name: "La Crosse Municipal",
    Address: "La Crosse,UNITED STATES",
  },
  {
    code: "LSI",
    name: "Sumburgh Intl Airport",
    Address: "Lerwick,UNITED KINGDOM",
  },
  {
    code: "LWK",
    name: "Tingwall Intl Airport",
    Address: "Lerwick,UNITED KINGDOM",
  },
  {
    code: "LSN",
    name: "Los Banos Intl Airport",
    Address: "Los Banos,UNITED STATES",
  },
  {
    code: "LSO",
    name: "Talmont Intl Airport",
    Address: "Les Sables,FRANCE",
  },
  {
    code: "LSP",
    name: "Josefa Camejo Intl Airport",
    Address: "Las Piedras,VENEZUELA",
  },
  {
    code: "LSQ",
    name: "Maria Dolores Intl Airport",
    Address: "Los Angeles,CHILE",
  },
  {
    code: "LST",
    name: "Launceston Intl Airport",
    Address: "Launceston,AUSTRALIA",
  },
  {
    code: "LSY",
    name: "Lismore Intl Airport",
    Address: "Lismore,AUSTRALIA",
  },
  { code: "LTA", name: "Letaba Aprt", Address: "Tzaneen,SOUTH AFRICA" },
  { code: "LTK", name: "Hmelmin Intl Airport", Address: "Latakia,SYRIA" },
  {
    code: "LTN",
    name: "Luton Intl Airport",
    Address: "Luton,UNITED KINGDOM",
  },
  { code: "LTO", name: "Loreto Intl Airport", Address: "Loreto,MEXICO" },
  {
    code: "LTQ",
    name: "Le Touquet Intl Airport",
    Address: "Le Touquet,FRANCE",
  },
  {
    code: "AXS",
    name: "Altus Municipal Intl Airport",
    Address: "Altus,UNITED STATES",
  },
  {
    code: "LTT",
    name: "La Mole Intl Airport",
    Address: "St Tropez,FRANCE",
  },
  { code: "LTU", name: "Latur Intl Airport", Address: "Latur,INDIA" },
  {
    code: "LTX",
    name: "Cotapaxi Intl Airport",
    Address: "Latacunga,ECUADOR",
  },
  {
    code: "LUD",
    name: "Luderitz Intl Airport",
    Address: "Luderitz,NAMIBIA",
  },
  {
    code: "LUG",
    name: "Agno Intl Airport",
    Address: "Lugano,SWITZERLAND",
  },
  {
    code: "LUH",
    name: "Ludhiana Intl Airport",
    Address: "Ludhiana,INDIA",
  },
  {
    code: "PIB",
    name: "Hattiesburg Laurel Regional Intl Airport",
    Address: "Laurel,UNITED STATES",
  },
  {
    code: "LUL",
    name: "Hesler Noble Field",
    Address: "Laurel,UNITED STATES",
  },
  { code: "LUN", name: "Lusaka Intl Airport", Address: "Lusaka,ZAMBIA" },
  {
    code: "LUP",
    name: "Kalaupapa Municipal",
    Address: "Kalaupapa,UNITED STATES",
  },
  {
    code: "LUQ",
    name: "San Luis Cty Intl Airport",
    Address: "San Luis,ARGENTINA",
  },
  {
    code: "LUX",
    name: "Luxembourg Intl Airport",
    Address: "Luxembourg,LUXEMBOURG",
  },
  {
    code: "LVI",
    name: "Livingstone Intl Airport",
    Address: "Livingstone,ZAMBIA",
  },
  {
    code: "LVK",
    name: "Bron Intl Airport",
    Address: "Livermore,UNITED STATES",
  },
  {
    code: "LVM",
    name: "Mission Field",
    Address: "Livingston,UNITED STATES",
  },
  {
    code: "LVO",
    name: "Laverton Intl Airport",
    Address: "Laverton,AUSTRALIA",
  },
  {
    code: "LVS",
    name: "Las Vegas Intl Airport",
    Address: "Las Vegas,UNITED STATES",
  },
  {
    code: "TCC",
    name: "Tucumcari Intl Airport",
    Address: "Las Vegas,UNITED STATES",
  },
  {
    code: "LWB",
    name: "Greenbrier Valley Intl Airport",
    Address: "Lewisburg,UNITED STATES",
  },
  {
    code: "LWC",
    name: "Lawrence Municipal",
    Address: "Lawrence,UNITED STATES",
  },
  {
    code: "LWM",
    name: "Lawrence Intl Airport",
    Address: "Lawrence,UNITED STATES",
  },
  { code: "LWO", name: "Snilow Intl Airport", Address: "Lvov,UKRAINE" },
  {
    code: "LWS",
    name: "Lewiston Nez Pierce Intl Airport",
    Address: "Lewiston,UNITED STATES",
  },
  {
    code: "LWT",
    name: "Lewistown Municipal",
    Address: "Lewistown,UNITED STATES",
  },
  { code: "LWY", name: "Lawas Intl Airport", Address: "Lawas,MALAYSIA" },
  { code: "LXA", name: "Lhasa Intl Airport", Address: "Lhasa,CHINA" },
  {
    code: "LXN",
    name: "Jim Kelley Field",
    Address: "Lexington,UNITED STATES",
  },
  { code: "LXR", name: "Luxor Intl Airport", Address: "Luxor,EGYPT" },
  { code: "LXS", name: "Limnos Intl Airport", Address: "Limnos,GREECE" },
  {
    code: "SEB",
    name: "Sebha Intl Airport",
    Address: "Sebha Intl Airport,LIBYAN ARAB JAMAHIRIYA",
  },
  { code: "LYA", name: "Luoyang Intl Airport", Address: "Luoyang,CHINA" },
  {
    code: "LYB",
    name: "Little Cayman Intl Airport",
    Address: "Little Cayman,CAYMAN ISLANDS",
  },
  {
    code: "LYC",
    name: "Hedlunda Intl Airport",
    Address: "Lycksele,SWEDEN",
  },
  {
    code: "LYG",
    name: "Lianyungang Intl Airport",
    Address: "Lianyungang,CHINA",
  },
  {
    code: "LYH",
    name: "Lynchburg Municipal",
    Address: "Lynchburg,UNITED STATES",
  },
  { code: "LYI", name: "Linyi Intl Airport", Address: "Linyi,CHINA" },
  {
    code: "LYP",
    name: "Faisalabad Intl Airport",
    Address: "Faisalabad,PAKISTAN",
  },
  {
    code: "LYR",
    name: "Svalbard Intl Airport",
    Address: "Longyearbyen,NORWAY",
  },
  {
    code: "LYS",
    name: "Lyon Saint Exupery Intl Airport",
    Address: "Lyon,FRANCE",
  },
  { code: "XYD", name: "Lyon Rail Station", Address: "Lyon,FRANCE" },
  { code: "LYN", name: "Bron Intl Airport", Address: "Lyon,FRANCE" },
  {
    code: "LYU",
    name: "Ely Municipal Intl Airport",
    Address: "Ely Mn,UNITED STATES",
  },
  {
    code: "LYX",
    name: "Lydd Intl Airport",
    Address: "Lydd,UNITED KINGDOM",
  },
  {
    code: "LZC",
    name: "Lazaro Cardenas Intl Airport",
    Address: "Lazaro Cardenas Michoacan,MEXICO",
  },
  { code: "LZH", name: "Liuzhou Intl Airport", Address: "Liuzhou,CHINA" },
  { code: "LZO", name: "Luzhou Intl Airport", Address: "Luzhou,CHINA" },
  {
    code: "LZR",
    name: "Lizard Island Intl Airport",
    Address: "Lizard Island,AUSTRALIA",
  },
  { code: "MAA", name: "Chennai Intl Airport", Address: "Chennai,INDIA" },
  { code: "MAB", name: "Maraba Intl Airport", Address: "Maraba,BRAZIL" },
  { code: "MAD", name: "Barajas Intl Airport", Address: "Madrid,SPAIN" },
  {
    code: "MAE",
    name: "Madera Municipal Intl Airport",
    Address: "Madera,UNITED STATES",
  },
  {
    code: "MAF",
    name: "Midland Intl Airport",
    Address: "Midland,UNITED STATES",
  },
  {
    code: "MAG",
    name: "Madang Intl Airport",
    Address: "Madang,PAPUA NEW GUINEA",
  },
  { code: "MAH", name: "Aerop De Menorca", Address: "Menorca,SPAIN" },
  {
    code: "MAJ",
    name: "Amata Kabua Intl Airport",
    Address: "Majuro,MARSHALL ISLANDS",
  },
  { code: "MAK", name: "Malakal Intl Airport", Address: "Malakal,SUDAN" },
  {
    code: "MAM",
    name: "Servando Canales Intl Airport",
    Address: "Matamoros,MEXICO",
  },
  {
    code: "QQM",
    name: "Manchester - Piccadilly Rail Station",
    Address: "Manchester,UNITED KINGDOM",
  },
  {
    code: "XVC",
    name: "Crewe Rail Station",
    Address: "Manchester,UNITED KINGDOM",
  },
  {
    code: "MAN",
    name: "Manchester Intl ",
    Address: "Manchester,UNITED KINGDOM",
  },
  {
    code: "MAO",
    name: "Intl Intl Airport  Eduardo Gomes",
    Address: "Manaus,BRAZIL",
  },
  {
    code: "MAQ",
    name: "Mae Sot Intl Airport",
    Address: "Mae Sot,THAILAND",
  },
  {
    code: "MAR",
    name: "La Chinita Intl Airport",
    Address: "Maracaibo,VENEZUELA",
  },
  {
    code: "MAS",
    name: "Momote Intl Airport",
    Address: "Manus Island,PAPUA NEW GUINEA",
  },
  {
    code: "MAZ",
    name: "Eugenio M De Hostos Intl Airport",
    Address: "Mayaguez,UNITED STATES",
  },
  { code: "MBA", name: "Moi Intl ", Address: "Mombasa,KENYA" },
  {
    code: "MBD",
    name: "Mmabatho Intl Airport",
    Address: "Mmabatho,SOUTH AFRICA",
  },
  {
    code: "MBH",
    name: "Maryborough Intl Airport",
    Address: "Maryborough,AUSTRALIA",
  },
  {
    code: "MBJ",
    name: "Sangster Intl Airport",
    Address: "Montego Bay,JAMAICA",
  },
  {
    code: "MBL",
    name: "Manistee Intl Airport",
    Address: "Manistee,UNITED STATES",
  },
  {
    code: "MBS",
    name: "Saginaw Intl Airport",
    Address: "Saginaw,UNITED STATES",
  },
  {
    code: "MBT",
    name: "Murfreesboro Municipal Intl Airport",
    Address: "Masbate,PHILIPPINES",
  },
  {
    code: "MBW",
    name: "Moorabbin Intl Airport",
    Address: "Moorabbin,AUSTRALIA",
  },
  {
    code: "MBX",
    name: "Maribor Intl Airport",
    Address: "Maribor,SLOVENIA",
  },
  {
    code: "MCE",
    name: "Merced Municipal Intl Airport",
    Address: "Merced,UNITED STATES",
  },
  {
    code: "MCK",
    name: "McCook Municipal",
    Address: "McCook,UNITED STATES",
  },
  {
    code: "MCM",
    name: "Hel De Monte Carlo Intl Airport",
    Address: "Monte Carlo,MONACO",
  },
  { code: "MCN", name: "Lewis B Wilson", Address: "Macon,UNITED STATES" },
  { code: "MCP", name: "Macapa Intl Airport", Address: "Macapa,BRAZIL" },
  {
    code: "MCQ",
    name: "Miskolc Intl Airport",
    Address: "Miskolc,HUNGARY",
  },
  { code: "MCT", name: "Seeb Intl ", Address: "Muscat,OMAN" },
  {
    code: "MCU",
    name: "Gueret Intl Airport",
    Address: "Montlucon,FRANCE",
  },
  {
    code: "MCV",
    name: "McArthur River Intl Airport",
    Address: "McArthur River,AUSTRALIA",
  },
  {
    code: "MCW",
    name: "Mason City Municipal",
    Address: "Mason City,UNITED STATES",
  },
  {
    code: "MCX",
    name: "Makhachkala Intl Airport",
    Address: "Makhachkala Intl Airport,RUSSIA",
  },
  {
    code: "MCY",
    name: "Maroochydore Intl Airport",
    Address: "Maroochydore,AUSTRALIA",
  },
  {
    code: "MCZ",
    name: "Palmeres Intl Airport",
    Address: "Maceio,BRAZIL",
  },
  {
    code: "MDC",
    name: "Samratulang Intl Airport",
    Address: "Manado,INDONESIA",
  },
  {
    code: "EOH",
    name: "Enrique Olaya Herrara",
    Address: "Medellin,COLOMBIA",
  },
  {
    code: "MDE",
    name: "Jose Marie Cordova",
    Address: "Medellin,COLOMBIA",
  },
  {
    code: "MDG",
    name: "Mudanjiang Intl Airport",
    Address: "Mudanjiang,CHINA",
  },
  {
    code: "MDH",
    name: "Southern Illinois Intl Airport",
    Address: "Carbondale,UNITED STATES",
  },
  {
    code: "MDI",
    name: "Makurdi Intl Airport",
    Address: "Makurdi,NIGERIA",
  },
  {
    code: "MDL",
    name: "Annisaton Intl Airport",
    Address: "Mandalay,MYANMAR",
  },
  {
    code: "MDQ",
    name: "Mar Del Plata Intl Airport",
    Address: "Mar Del Plata,ARGENTINA",
  },
  {
    code: "MDU",
    name: "Mendi Intl Airport",
    Address: "Mendi,PAPUA NEW GUINEA",
  },
  {
    code: "MDY",
    name: "Sand Island Field",
    Address: "Midway Island,UNITED STATES MINOR OUTLYING ISLANDS",
  },
  {
    code: "MDZ",
    name: "El Plumerillo Intl Airport",
    Address: "Mendoza,ARGENTINA",
  },
  { code: "MEA", name: "Macae Intl Airport", Address: "Macae,BRAZIL" },
  { code: "MEC", name: "Manta Intl Airport", Address: "Manta,ECUADOR" },
  {
    code: "MED",
    name: "Prince Mohammad Bin Abdulaziz Intl Airport",
    Address: "Madinah,SAUDI ARABIA",
  },
  { code: "MEH", name: "Mehamn Intl Airport", Address: "Mehamn,NORWAY" },
  { code: "MEI", name: "Key Field", Address: "Meridian,UNITED STATES" },
  {
    code: "MEB",
    name: "Essendon Intl Airport",
    Address: "Melbourne,AUSTRALIA",
  },
  {
    code: "AVV",
    name: "Avalon Intl Airport",
    Address: "Melbourne,AUSTRALIA",
  },
  {
    code: "MEL",
    name: "Tullamarine Intl Airport",
    Address: "Melbourne,AUSTRALIA",
  },
  {
    code: "AWM",
    name: "West Memphis Municipal Intl Airport",
    Address: "Memphis,UNITED STATES",
  },
  {
    code: "NQA",
    name: "Memphis Naval Air Station",
    Address: "Memphis,UNITED STATES",
  },
  {
    code: "MEM",
    name: "Memphis Intl ",
    Address: "Memphis,UNITED STATES",
  },
  {
    code: "MEO",
    name: "Dare County Regional Intl Airport",
    Address: "Manteo,UNITED STATES",
  },
  {
    code: "MES",
    name: "Polonia Intl Airport",
    Address: "Medan,INDONESIA",
  },
  {
    code: "MEU",
    name: "Monte Dourado Intl Airport",
    Address: "Monte Dourado,BRAZIL",
  },
  {
    code: "MEV",
    name: "Douglas County Intl Airport",
    Address: "Minden,UNITED STATES",
  },
  {
    code: "MEX",
    name: "Benito Juarez Intl Airport",
    Address: "Mexico City,MEXICO",
  },
  {
    code: "MEZ",
    name: "Messina Intl Airport",
    Address: "Messina,SOUTH AFRICA",
  },
  {
    code: "MFD",
    name: "Mansfield Municipal",
    Address: "Mansfield,UNITED STATES",
  },
  { code: "MFE", name: "Miller Intl ", Address: "McAllen,UNITED STATES" },
  {
    code: "MFH",
    name: "Mesquite Intl Airport",
    Address: "Mesquite,UNITED STATES",
  },
  {
    code: "MFI",
    name: "Marshfield Municipal Intl Airport",
    Address: "Marshfield,UNITED STATES",
  },
  {
    code: "MFK",
    name: "Matsu Intl Airport",
    Address: "Matsu,TAIWAN, PROVINCE OF CHINA",
  },
  { code: "MFM", name: "Macau Intl Airport", Address: "Macau,MACAO" },
  {
    code: "MFN",
    name: "Milford Sound Intl Airport",
    Address: "Milford Sound,NEW ZEALAND",
  },
  {
    code: "MFR",
    name: "Medford Jackson Cty",
    Address: "Medford,UNITED STATES",
  },
  {
    code: "MFT",
    name: "Machu Picchu Intl Airport",
    Address: "Machu Picchu,PERU",
  },
  {
    code: "MGA",
    name: "Augusto C Sandino",
    Address: "Managua,NICARAGUA",
  },
  {
    code: "MGB",
    name: "Mount Gambier Intl Airport",
    Address: "Mt Gambier,AUSTRALIA",
  },
  {
    code: "MGC",
    name: "Michigan City Intl Airport",
    Address: "Michigan City,UNITED STATES",
  },
  {
    code: "MGE",
    name: "Dobbins Air Force Base",
    Address: "Marietta,UNITED STATES",
  },
  {
    code: "MGF",
    name: "Regional De Maringa Intl Airport",
    Address: "Maringa,BRAZIL",
  },
  {
    code: "MGH",
    name: "Margate Intl Airport",
    Address: "Margate,SOUTH AFRICA",
  },
  {
    code: "MGJ",
    name: "Orange County Intl Airport",
    Address: "Montgomery,UNITED STATES",
  },
  {
    code: "MGM",
    name: "Dannelly Field",
    Address: "Montgomery,UNITED STATES",
  },
  {
    code: "MGQ",
    name: "Mogadishu Intl Airport",
    Address: "Mogadishu,SOMALIA",
  },
  {
    code: "MGR",
    name: "Moultrie Intl Airport",
    Address: "Moultrie,UNITED STATES",
  },
  {
    code: "MGV",
    name: "Margaret River Station Intl Airport",
    Address: "Margaret River Station,AUSTRALIA",
  },
  {
    code: "MGW",
    name: "Morgantown Municipal",
    Address: "Morgantown,UNITED STATES",
  },
  { code: "MHD", name: "Mashad Intl Airport", Address: "Mashad,IRAN" },
  {
    code: "MHE",
    name: "Mitchell Municipal",
    Address: "Mitchell,UNITED STATES",
  },
  {
    code: "MHG",
    name: "Mannheim Neuostheim",
    Address: "Mannheim Germany,GERMANY",
  },
  {
    code: "MHH",
    name: "Marsh Harbour Intl Airport",
    Address: "Marsh Harbour,BAHAMAS",
  },
  {
    code: "MHK",
    name: "Manhattan Municipal Intl Airport",
    Address: "Manhattan,UNITED STATES",
  },
  {
    code: "MHQ",
    name: "Mariehamn Intl Airport",
    Address: "Mariehamn,FINLAND",
  },
  {
    code: "MHT",
    name: "Manchester Intl Airport",
    Address: "Manchester,UNITED STATES",
  },
  {
    code: "MHU",
    name: "Mount Hotham Intl Airport",
    Address: "Mount Hotham,AUSTRALIA",
  },
  {
    code: "MHV",
    name: "Kern County Intl Airport",
    Address: "Mojave,UNITED STATES",
  },
  {
    code: "MHZ",
    name: "Mildenhall Intl Airport",
    Address: "Mildenhall,UNITED KINGDOM",
  },
  {
    code: "OPF",
    name: "Opa Locka Intl Airport",
    Address: "Miami,UNITED STATES",
  },
  { code: "MIA", name: "Miami Intl ", Address: "Miami,UNITED STATES" },
  {
    code: "MPB",
    name: "Mpb Seaplane Base",
    Address: "Miami,UNITED STATES",
  },
  {
    code: "TMB",
    name: "Tamiami Intl Airport",
    Address: "Miami,UNITED STATES",
  },
  { code: "MID", name: "Merida Intl ", Address: "Merida,MEXICO" },
  { code: "MIE", name: "Johnson Field", Address: "Muncie,UNITED STATES" },
  {
    code: "MIG",
    name: "Mian Yang Intl Airport",
    Address: "Mian Yang,CHINA",
  },
  { code: "MII", name: "Dr Gastao Vidigal", Address: "Marilia,BRAZIL" },
  { code: "MIK", name: "Mikkeli Aprt", Address: "Mikkeli,FINLAND" },
  { code: "MXP", name: "Malpensa Intl Airport", Address: "Milan,ITALY" },
  {
    code: "XIK",
    name: "Milan Central Station Railway",
    Address: "Milan,ITALY",
  },
  { code: "SWK", name: "Segrate Intl Airport", Address: "Milan,ITALY" },
  { code: "LIN", name: "Linate Intl Airport", Address: "Milan,ITALY" },
  {
    code: "MIM",
    name: "Merimbula Intl Airport",
    Address: "Merimbula,AUSTRALIA",
  },
  {
    code: "MIR",
    name: "Habib Bourguiba Intl ",
    Address: "Monastir,TUNISIA",
  },
  {
    code: "MIV",
    name: "Millville Intl Airport",
    Address: "Millville,UNITED STATES",
  },
  {
    code: "MIW",
    name: "Marshalltown Municipal",
    Address: "Marshalltown,UNITED STATES",
  },
  {
    code: "MJD",
    name: "Mohenjodaro Intl Airport",
    Address: "Mohenjodaro,PAKISTAN",
  },
  {
    code: "MJF",
    name: "Kjaerstad Intl Airport",
    Address: "Mosjoen,NORWAY",
  },
  { code: "MJI", name: "MITIGA", Address: "Maji,ETHIOPIA" },
  {
    code: "MJK",
    name: "Shark Bay Intl Airport",
    Address: "Monkey Mia,AUSTRALIA",
  },
  {
    code: "MJN",
    name: "Amborovy Intl Airport",
    Address: "Majunga,MADAGASCAR",
  },
  {
    code: "MJR",
    name: "Miramar Intl Airport",
    Address: "Miramar,ARGENTINA",
  },
  {
    code: "MJT",
    name: "Mytilene Intl Airport",
    Address: "Mytilene,GREECE",
  },
  {
    code: "MJU",
    name: "Mamuju Intl Airport",
    Address: "Mamuju,INDONESIA",
  },
  {
    code: "MJV",
    name: "San Javier Intl Airport",
    Address: "Murcia,SPAIN",
  },
  { code: "MJW", name: "Mahenye Airfield", Address: "Mahenye,ZIMBABWE" },
  { code: "MJZ", name: "Mirnyj Intl Airport", Address: "Mirnyj,RUSSIA" },
  {
    code: "MKA",
    name: "Marianske Lazne Intl Airport",
    Address: "Marianske Lazne,CZECH REPUBLIC",
  },
  {
    code: "MCI",
    name: "Kansas City Intl ",
    Address: "Kansas City,UNITED STATES",
  },
  {
    code: "JCI",
    name: "Johnson Industrial Intl Airport",
    Address: "Kansas City,UNITED STATES",
  },
  {
    code: "MKC",
    name: "Kansas City Municipal Intl Airport",
    Address: "Kansas City,UNITED STATES",
  },
  {
    code: "MKE",
    name: "General Mitchell Intl Airport",
    Address: "Milwaukee,UNITED STATES",
  },
  {
    code: "MKG",
    name: "Muskegon Cty Intl ",
    Address: "Muskegon,UNITED STATES",
  },
  {
    code: "MKK",
    name: "Molokai Intl Airport",
    Address: "Hoolehua,UNITED STATES",
  },
  { code: "MKL", name: "McKellar Fld", Address: "Jackson,UNITED STATES" },
  { code: "MKM", name: "Mukah Intl Airport", Address: "Mukah,MALAYSIA" },
  { code: "MKO", name: "Davis Field", Address: "Muskogee,UNITED STATES" },
  {
    code: "MKR",
    name: "Meekatharra Intl Airport",
    Address: "Meekathara,AUSTRALIA",
  },
  {
    code: "MKT",
    name: "Mankato Municipal Intl Airport",
    Address: "Mankato,UNITED STATES",
  },
  {
    code: "MKW",
    name: "Rendani Intl Airport",
    Address: "Manokwari,INDONESIA",
  },
  { code: "MKX", name: "Mukalla Intl Airport", Address: "Mukalla,YEMEN" },
  {
    code: "MKY",
    name: "Mackay Intl Airport",
    Address: "Mackay,AUSTRALIA",
  },
  {
    code: "MKZ",
    name: "Batu Berendum Intl Airport",
    Address: "Malacca,MALAYSIA",
  },
  { code: "MLA", name: "Luqa Intl Airport", Address: "Malta,MALTA" },
  {
    code: "MLB",
    name: "Melbourne Regional",
    Address: "Melbourne,UNITED STATES",
  },
  {
    code: "MLC",
    name: "McAlester Municipal Intl Airport",
    Address: "McAlester,UNITED STATES",
  },
  { code: "MLE", name: "Male Intl Airport", Address: "Male,MALDIVES" },
  {
    code: "MLG",
    name: "Malang Intl Airport",
    Address: "Malang,INDONESIA",
  },
  {
    code: "MLI",
    name: "Quad City Intl Airport",
    Address: "Moline,UNITED STATES",
  },
  {
    code: "MLM",
    name: "Michoacan Municipal Intl Airport",
    Address: "Morelia,MEXICO",
  },
  { code: "MLN", name: "Melilla Intl Airport", Address: "Melilla,SPAIN" },
  { code: "MLO", name: "Milos Intl Airport", Address: "Milos,GREECE" },
  {
    code: "MLS",
    name: "Miles City Municipal Intl Airport",
    Address: "Miles City,UNITED STATES",
  },
  {
    code: "MLT",
    name: "Millinocket Intl Airport",
    Address: "Millinocket,UNITED STATES",
  },
  {
    code: "MLU",
    name: "Monroe Regional",
    Address: "Monroe,UNITED STATES",
  },
  { code: "ROB", name: "Roberts Intl ", Address: "Monrovia,LIBERIA" },
  {
    code: "MLW",
    name: "Sprigg Payne Intl Airport",
    Address: "Monrovia,LIBERIA",
  },
  {
    code: "MLX",
    name: "Malatya Intl Airport",
    Address: "Malatya,TURKEY",
  },
  { code: "MLZ", name: "Melo Intl Airport", Address: "Melo,URUGUAY" },
  { code: "XFP", name: "Malmo Railway Service", Address: "Malmo,SWEDEN" },
  {
    code: "XFR",
    name: "Malmo South Railway Service",
    Address: "Malmo,SWEDEN",
  },
  {
    code: "MMA",
    name: "Malmo Metropolitan Area Intl Airport",
    Address: "Malmo,SWEDEN",
  },
  { code: "MMX", name: "Sturup Intl Airport", Address: "Malmo,SWEDEN" },
  {
    code: "MMB",
    name: "Memanbetsu Intl Airport",
    Address: "Memambetsu,JAPAN",
  },
  {
    code: "MMC",
    name: "Ciudad Mante Intl Airport",
    Address: "Ciudad Mante,MEXICO",
  },
  {
    code: "MME",
    name: "Durham Tees Valley Intl Airport",
    Address: "Teesside,UNITED KINGDOM",
  },
  {
    code: "XVG",
    name: "Darlington Rail Station",
    Address: "Teesside,UNITED KINGDOM",
  },
  {
    code: "XNO",
    name: "Northallerton Rail Station",
    Address: "Teesside,UNITED KINGDOM",
  },
  {
    code: "MMG",
    name: "Mount Magnet Intl Airport",
    Address: "Mount Magnet,AUSTRALIA",
  },
  {
    code: "MMH",
    name: "Mammoth Lakes Municipal",
    Address: "Mammoth Lakes,UNITED STATES",
  },
  {
    code: "MMI",
    name: "McMinn County Intl Airport",
    Address: "Athens,UNITED STATES",
  },
  {
    code: "MMJ",
    name: "Matsumoto Intl Airport",
    Address: "Matsumoto,JAPAN",
  },
  {
    code: "MMK",
    name: "Murmansk Intl Airport",
    Address: "Murmansk,RUSSIA",
  },
  {
    code: "MML",
    name: "Marshall Municipal",
    Address: "Marshall,UNITED STATES",
  },
  {
    code: "MMM",
    name: "Middlemount Intl Airport",
    Address: "Middlemount,AUSTRALIA",
  },
  {
    code: "MMO",
    name: "Vila Do Maio Intl Airport",
    Address: "Maio,CAPE VERDE",
  },
  {
    code: "MMU",
    name: "Morristown Intl Airport",
    Address: "Morristown,UNITED STATES",
  },
  {
    code: "MMY",
    name: "Hirara Intl Airport",
    Address: "Miyako Jima,JAPAN",
  },
  {
    code: "MNI",
    name: "Bramble Intl Airport",
    Address: "Montserrat,DOMINICA",
  },
  {
    code: "MNK",
    name: "Maiana Intl Airport",
    Address: "Maiana,KIRIBATI",
  },
  {
    code: "MNL",
    name: "Ninoy Aquino Intl ",
    Address: "Manila,PHILIPPINES",
  },
  {
    code: "MNM",
    name: "Menominee County",
    Address: "Menominee,UNITED STATES",
  },
  {
    code: "MNO",
    name: "Manono Intl Airport",
    Address: "Manono,CONGO, THE DEMOCRATIC REPUBLIC OF THE",
  },
  { code: "MNQ", name: "Monto Intl Airport", Address: "Monto,AUSTRALIA" },
  {
    code: "MNZ",
    name: "Manassas Intl Airport",
    Address: "Manassas,UNITED STATES",
  },
  {
    code: "EZF",
    name: "Shannon Intl Airport",
    Address: "Manassas,UNITED STATES",
  },
  {
    code: "MOB",
    name: "Mobile Municipal",
    Address: "Mobile,UNITED STATES",
  },
  {
    code: "MOC",
    name: "Montes Claros Intl Airport",
    Address: "Montes Claros,BRAZIL",
  },
  {
    code: "MOD",
    name: "Harry Sham Fld",
    Address: "Modesto,UNITED STATES",
  },
  {
    code: "MOI",
    name: "Mitiaro Island Intl Airport",
    Address: "Mitiaro Island,COOK ISLANDS",
  },
  { code: "MOL", name: "Aro Intl Airport", Address: "Molde,NORWAY" },
  {
    code: "MON",
    name: "Mount Cook Intl Airport",
    Address: "Mount Cook,NEW ZEALAND",
  },
  {
    code: "MOP",
    name: "Mt Pleasant Municipal",
    Address: "Mount Pleasant,UNITED STATES",
  },
  {
    code: "MOQ",
    name: "Morondava Intl Airport",
    Address: "Morondava,MADAGASCAR",
  },
  { code: "MOT", name: "Minot Intl ", Address: "Minot,UNITED STATES" },
  {
    code: "MOU",
    name: "Mountain Village Intl Airport",
    Address: "Mountain Village,UNITED STATES",
  },
  {
    code: "MOV",
    name: "Moranbah Intl Airport",
    Address: "Moranbah,AUSTRALIA",
  },
  {
    code: "DME",
    name: "Domodedovo Intl Airport",
    Address: "Moscow,RUSSIA",
  },
  { code: "VKO", name: "Vnukovo Intl Airport", Address: "Moscow,RUSSIA" },
  {
    code: "SVO",
    name: "Sheremetyevo Intl Airport",
    Address: "Moscow,RUSSIA",
  },
  {
    code: "MOZ",
    name: "Temae Intl Airport",
    Address: "Moorea,FRENCH POLYNESIA",
  },
  { code: "MPA", name: "Mpacha Aerodrome", Address: "Mpache,NAMIBIA" },
  {
    code: "MPH",
    name: "Malay Intl Airport",
    Address: "Caticlan,PHILIPPINES",
  },
  {
    code: "MPK",
    name: "Mokpo Intl Airport",
    Address: "Mokpo,KOREA, REPUBLIC OF",
  },
  {
    code: "MPL",
    name: "Frejorgues Intl Airport",
    Address: "Montpellier,FRANCE",
  },
  { code: "MPM", name: "Maputo Intl ", Address: "Maputo,MOZAMBIQUE" },
  {
    code: "MPN",
    name: "Mount Pleasant Intl Airport",
    Address: "Mount Pleasant,FALKLAND ISLANDS (MALVINAS)",
  },
  { code: "MPQ", name: "Maan Intl Airport", Address: "Maan,JORDAN" },
  {
    code: "MPR",
    name: "McPherson Intl Airport",
    Address: "McPherson,UNITED STATES",
  },
  {
    code: "MPV",
    name: "E F Knapp Intl Airport",
    Address: "Montpelier,UNITED STATES",
  },
  {
    code: "MPW",
    name: "Mariupol Intl Airport",
    Address: "Mariupol,UKRAINE",
  },
  {
    code: "MQF",
    name: "Magnitogorsk Intl Airport",
    Address: "Magnitogorsk,RUSSIA",
  },
  {
    code: "MQK",
    name: "San Matias Intl Airport",
    Address: "San Matias,BOLIVIA",
  },
  {
    code: "MQL",
    name: "Mildura Intl Airport",
    Address: "Mildura,AUSTRALIA",
  },
  { code: "MQM", name: "Mardin Intl Airport", Address: "Mardin,TURKEY" },
  { code: "MQN", name: "Rossvoll", Address: "Mo I Rana,NORWAY" },
  {
    code: "MQS",
    name: "Mustique Intl Airport",
    Address: "Mustique,DOMINICA",
  },
  {
    code: "MQT",
    name: "Sawyer Intl Airport",
    Address: "Marquette,UNITED STATES",
  },
  {
    code: "MQY",
    name: "Smyrna Intl Airport",
    Address: "Smyrna,UNITED STATES",
  },
  {
    code: "MQZ",
    name: "Margaret River Intl Airport",
    Address: "Margaret River,AUSTRALIA",
  },
  {
    code: "MRA",
    name: "Misurata",
    Address: "Misurata,LIBYAN ARAB JAMAHIRIYA",
  },
  {
    code: "MRB",
    name: "Martinsburgh Regional Intl Airport",
    Address: "Martinsburg,UNITED STATES",
  },
  {
    code: "MRC",
    name: "Maury Country Intl Airport",
    Address: "Columbia,UNITED STATES",
  },
  {
    code: "MRD",
    name: "Alberto Carnevalli Intl Airport",
    Address: "Merida,VENEZUELA",
  },
  {
    code: "MRE",
    name: "Mara Lodges Intl Airport",
    Address: "Mara Lodges,KENYA",
  },
  {
    code: "MRO",
    name: "Masterton Intl Airport",
    Address: "Masterton,NEW ZEALAND",
  },
  {
    code: "XRF",
    name: "Marseille Rail Station",
    Address: "Marseille,FRANCE",
  },
  {
    code: "MRS",
    name: "Marseille Provence Intl Airport",
    Address: "Marseille,FRANCE",
  },
  {
    code: "MRU",
    name: "Plaisance Intl Airport t",
    Address: "Mauritius,MAURITIUS",
  },
  {
    code: "MRV",
    name: "Mineralnye Vody Intl Airport",
    Address: "Mineralnye Vody,RUSSIA",
  },
  {
    code: "MRX",
    name: "Mahshahr Intl Airport",
    Address: "Mahshahr Intl Airport,IRAN",
  },
  {
    code: "MRY",
    name: "Monterey Peninsula",
    Address: "Monterey,UNITED STATES",
  },
  { code: "MRZ", name: "Moree Intl Airport", Address: "Moree,AUSTRALIA" },
  {
    code: "MSB",
    name: "Marigot Seaplane Base",
    Address: "Marigot St Martin,GUADELOUPE",
  },
  { code: "MSC", name: "Falcon Field", Address: "Mesa,UNITED STATES" },
  {
    code: "MSE",
    name: "Kent Intl Airport",
    Address: "Manston,UNITED KINGDOM",
  },
  { code: "MSJ", name: "Misawa Intl Airport", Address: "Misawa,JAPAN" },
  {
    code: "MSL",
    name: "Muscle Shoals Intl Airport",
    Address: "Muscle Shoals,UNITED STATES",
  },
  {
    code: "MSN",
    name: "Dane County Regional",
    Address: "Madison,UNITED STATES",
  },
  {
    code: "MSO",
    name: "Missoula Intl ",
    Address: "Missoula,UNITED STATES",
  },
  {
    code: "MIC",
    name: "Crystal Intl Airport",
    Address: "Minneapolis,UNITED STATES",
  },
  {
    code: "FCM",
    name: "Flying Cloud Intl Airport",
    Address: "Minneapolis,UNITED STATES",
  },
  {
    code: "MSP",
    name: "Minneapolis St Paul Intl ",
    Address: "Minneapolis,UNITED STATES",
  },
  { code: "MHP", name: "Minsk Intl  1", Address: "Minsk,BELARUS" },
  { code: "MSQ", name: "Minsk Intl  2", Address: "Minsk,BELARUS" },
  { code: "MSR", name: "Mus Intl Airport", Address: "Mus Tr,TURKEY" },
  {
    code: "MSS",
    name: "Richards Field",
    Address: "Massena,UNITED STATES",
  },
  {
    code: "MST",
    name: "Maastricht Aachen Intl Airport",
    Address: "Maastricht,NETHERLANDS",
  },
  {
    code: "ZYT",
    name: "Maastricht Rail Station",
    Address: "Maastricht,NETHERLANDS",
  },
  {
    code: "MSU",
    name: "Moshoeshoe Intl Airport",
    Address: "Maseru,LESOTHO",
  },
  {
    code: "MSV",
    name: "Catskills Sulivan",
    Address: "Monticello,UNITED STATES",
  },
  {
    code: "MSY",
    name: "Louis Armstrong Intl Airport",
    Address: "New Orleans,UNITED STATES",
  },
  {
    code: "NEW",
    name: "New Lakefront Intl Airport",
    Address: "New Orleans,UNITED STATES",
  },
  {
    code: "MTH",
    name: "Marathon Intl Airport",
    Address: "Marathon,UNITED STATES",
  },
  {
    code: "MTJ",
    name: "Montrose County",
    Address: "Montrose,UNITED STATES",
  },
  {
    code: "MTL",
    name: "Maitland Intl Airport",
    Address: "Maitland,AUSTRALIA",
  },
  { code: "MTO", name: "Coles County", Address: "Mattoon,UNITED STATES" },
  {
    code: "MTP",
    name: "Sky Portal Intl Airport",
    Address: "Montauk,UNITED STATES",
  },
  {
    code: "MTR",
    name: "S Jeronimo Intl Airport",
    Address: "Monteria,COLOMBIA",
  },
  {
    code: "MTS",
    name: "Matsapha Intl Airport",
    Address: "Manzini,SWAZILAND",
  },
  {
    code: "MTT",
    name: "Minatitlan Municipal Intl Airport",
    Address: "Minatitlan,MEXICO",
  },
  {
    code: "MTW",
    name: "Manitowoc Municipal Intl Airport",
    Address: "Manitowoc,UNITED STATES",
  },
  {
    code: "MTY",
    name: "Escobedo Intl Airport",
    Address: "Monterrey,MEXICO",
  },
  {
    code: "NTR",
    name: "Aeropuerto Del Norte",
    Address: "Monterrey,MEXICO",
  },
  {
    code: "MUA",
    name: "Munda Intl Airport",
    Address: "Munda,SOLOMON ISLANDS",
  },
  { code: "MUB", name: "Maun Intl Airport", Address: "Maun,BOTSWANA" },
  {
    code: "ZMU",
    name: "Munich HBF Railway Service",
    Address: "Munich,GERMANY",
  },
  { code: "MUC", name: "Munich Intl Airport", Address: "Munich,GERMANY" },
  {
    code: "MUE",
    name: "Waimea Intl Airport",
    Address: "Kamuela,UNITED STATES",
  },
  {
    code: "MUN",
    name: "Quiriquire Intl Airport",
    Address: "Maturin,VENEZUELA",
  },
  {
    code: "MUO",
    name: "Mountain Home AFB",
    Address: "Mountain Home,UNITED STATES",
  },
  {
    code: "MUR",
    name: "Marudi Intl Airport",
    Address: "Marudi,MALAYSIA",
  },
  {
    code: "MUX",
    name: "Multan Intl Airport",
    Address: "Multan,PAKISTAN",
  },
  {
    code: "MUZ",
    name: "Musoma Intl Airport",
    Address: "Musoma,TANZANIA",
  },
  {
    code: "MVB",
    name: "Franceville Mvengue Intl Airport",
    Address: "Franceville Mvengue,GABON",
  },
  {
    code: "MVC",
    name: "Monroe County Intl Airport",
    Address: "Monroeville,UNITED STATES",
  },
  {
    code: "MVD",
    name: "Carrasco Intl Airport",
    Address: "Montevideo,URUGUAY",
  },
  {
    code: "MVF",
    name: "Dixsept Rosado Intl Airport",
    Address: "Mossoro,BRAZIL",
  },
  {
    code: "MVN",
    name: "Mt Vernon Outland Intl Airport",
    Address: "Mt Vernon,UNITED STATES",
  },
  {
    code: "MVQ",
    name: "Mogilev Intl Airport",
    Address: "Mogilev,BELARUS",
  },
  { code: "MVR", name: "Salam Intl Airport", Address: "Maroua,CAMEROON" },
  { code: "MVV", name: "Megeve Intl Airport", Address: "Megeve,FRANCE" },
  {
    code: "MVY",
    name: "Dukes County",
    Address: "Martha S Vineyard,UNITED STATES",
  },
  {
    code: "MVZ",
    name: "Ft Victoria Intl Airport",
    Address: "Masvingo,ZIMBABWE",
  },
  {
    code: "MWA",
    name: "Williamson County",
    Address: "Marion,UNITED STATES",
  },
  {
    code: "MWH",
    name: "Grant County",
    Address: "Moses Lake,UNITED STATES",
  },
  {
    code: "MWO",
    name: "Hook Field",
    Address: "Middletown,UNITED STATES",
  },
  {
    code: "MWZ",
    name: "Mwanza Intl Airport",
    Address: "Mwanza,TANZANIA",
  },
  {
    code: "MXC",
    name: "San Juan County Intl Airport",
    Address: "Monticello,UNITED STATES",
  },
  {
    code: "MXL",
    name: "Rodolfg Sachez Taboada",
    Address: "Mexicali,MEXICO",
  },
  {
    code: "MXN",
    name: "Morlaix Intl Airport",
    Address: "Morlaix,FRANCE",
  },
  { code: "MXS", name: "Maota Intl Airport", Address: "Maota,SAMOA" },
  { code: "MXX", name: "Mora Intl Airport", Address: "Mora,SWEDEN" },
  {
    code: "MXY",
    name: "McCarthy Intl Airport",
    Address: "Mccarthy,UNITED STATES",
  },
  { code: "MXZ", name: "Meixian Intl Airport", Address: "Meixian,CHINA" },
  {
    code: "MYA",
    name: "Moruya Intl Airport",
    Address: "Moruya,AUSTRALIA",
  },
  {
    code: "MYC",
    name: "Maracay Intl Airport",
    Address: "Maracay,VENEZUELA",
  },
  { code: "MYD", name: "Malindi Intl Airport", Address: "Malindi,KENYA" },
  {
    code: "MYJ",
    name: "Matsuyama Intl Airport",
    Address: "Matsuyama,JAPAN",
  },
  {
    code: "MYL",
    name: "Mccall Intl Airport",
    Address: "Mccall,UNITED STATES",
  },
  {
    code: "MYP",
    name: "Mary Intl Airport",
    Address: "Mary,TURKMENISTAN",
  },
  { code: "MYQ", name: "Mysore Intl Airport", Address: "Mysore,INDIA" },
  {
    code: "MYR",
    name: "Myrtle Beach Jetway",
    Address: "Myrtle Beach,UNITED STATES",
  },
  {
    code: "CRE",
    name: "Grand Strand Intl Airport",
    Address: "Myrtle Beach,UNITED STATES",
  },
  {
    code: "GGE",
    name: "George Town Intl Airport",
    Address: "Myrtle Beach,UNITED STATES",
  },
  {
    code: "MYV",
    name: "Yuba County Intl Airport",
    Address: "Marysville,UNITED STATES",
  },
  {
    code: "MYW",
    name: "Mtwara Intl Airport",
    Address: "Mtwara,TANZANIA",
  },
  { code: "MYY", name: "Miri Intl Airport", Address: "Miri,MALAYSIA" },
  {
    code: "MZG",
    name: "Makung Intl Airport",
    Address: "Makung,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "MZH",
    name: "Merzifon Intl Airport",
    Address: "Merzifon,TURKEY",
  },
  { code: "MZI", name: "Mopti Intl Airport", Address: "Mopti,MALI" },
  {
    code: "MZL",
    name: "Santaguida Intl Airport",
    Address: "Manizales,COLOMBIA",
  },
  { code: "MZM", name: "Frescaty Intl Airport", Address: "Metz,FRANCE" },
  {
    code: "MZO",
    name: "Sierra Maestra Intl Airport",
    Address: "Manzanillo,CUBA",
  },
  {
    code: "MZR",
    name: "Mazar I Sharif Intl Airport",
    Address: "Mazar I Sharif,AFGHANISTAN",
  },
  {
    code: "MZT",
    name: "Buelina Intl Airport",
    Address: "Mazatlan,MEXICO",
  },
  { code: "MZV", name: "Mulu Intl Airport", Address: "Mulu,MALAYSIA" },
  {
    code: "NAA",
    name: "Narrabri Intl Airport",
    Address: "Narrabri,AUSTRALIA",
  },
  {
    code: "NAC",
    name: "Naracoorte Intl Airport",
    Address: "Naracoorte,AUSTRALIA",
  },
  {
    code: "NAG",
    name: "Dr Ambedkar Intl Airport",
    Address: "Nagpur,INDIA",
  },
  { code: "NAJ", name: "Nakhichevan", Address: "Nakhichevan,AZERBAIJAN" },
  {
    code: "NAK",
    name: "Nakhon Ratchasima Intl Airport",
    Address: "Nakhon Ratchasima,THAILAND",
  },
  {
    code: "NAL",
    name: "Nalchik Intl Airport",
    Address: "Nalchik,RUSSIA",
  },
  {
    code: "NAM",
    name: "Namlea Intl Airport",
    Address: "Namlea,INDONESIA",
  },
  { code: "NAN", name: "Nadi Intl ", Address: "Nadi,FIJI" },
  {
    code: "NAO",
    name: "Nanchong Intl Airport",
    Address: "Nanchong,CHINA",
  },
  {
    code: "NAP",
    name: "Capodichino Intl Airport",
    Address: "Naples,ITALY",
  },
  { code: "NAR", name: "Nare Intl Airport", Address: "Nare,COLOMBIA" },
  {
    code: "WZY",
    name: "Seaplane Base Intl Airport",
    Address: "Nassau,BAHAMAS",
  },
  {
    code: "PID",
    name: "Paradise Island Intl Airport",
    Address: "Nassau,BAHAMAS",
  },
  { code: "NAS", name: "Nassau Intl ", Address: "Nassau,BAHAMAS" },
  {
    code: "NAT",
    name: "Augusto Severo Intl Airport",
    Address: "Natal,BRAZIL",
  },
  {
    code: "NAV",
    name: "Nevsehir Intl Airport",
    Address: "Nevsehir,TURKEY",
  },
  {
    code: "NAW",
    name: "Narathiwat Intl Airport",
    Address: "Narathiwat,THAILAND",
  },
  {
    code: "NBC",
    name: "Nijnekamsk Intl Airport",
    Address: "Naberevnye Chelny,RUSSIA",
  },
  {
    code: "NBE",
    name: "Hammamet Intl Airport",
    Address: "Hammamet,TUNISIA",
  },
  { code: "WIL", name: "Wilson Intl Airport", Address: "Nairobi,KENYA" },
  { code: "NBO", name: "Jomo Kenyatta Intl ", Address: "Nairobi,KENYA" },
  {
    code: "NCA",
    name: "North Caicos Municipal Intl Airport",
    Address: "North Caicos,TURKS AND CAICOS ISLANDS",
  },
  {
    code: "NCE",
    name: "Cote D Azur Intl Airport",
    Address: "Nice,FRANCE",
  },
  {
    code: "NCL",
    name: "Newcastle Intl Airport",
    Address: "Newcastle,UNITED KINGDOM",
  },
  {
    code: "XVU",
    name: "Durham Rail Station",
    Address: "Newcastle,UNITED KINGDOM",
  },
  {
    code: "NCS",
    name: "Newcastle Intl Airport",
    Address: "Newcastle,SOUTH AFRICA",
  },
  { code: "NCU", name: "Nukus", Address: "Nukus,UZBEKISTAN" },
  {
    code: "NCY",
    name: "Annecy Meythet Intl Airport",
    Address: "Annecy,FRANCE",
  },
  {
    code: "NDB",
    name: "Nouadhibou Intl Airport",
    Address: "Nouadhibou,MAURITANIA",
  },
  { code: "NDG", name: "Qiqihar Intl Airport", Address: "Qiqihar,CHINA" },
  {
    code: "NDJ",
    name: "N Djamena Intl Airport",
    Address: "N Djamena,CHAD",
  },
  {
    code: "NDK",
    name: "Namdrik Intl Airport",
    Address: "Namdrik,MARSHALL ISLANDS",
  },
  { code: "NDR", name: "Nador Intl Airport", Address: "Nador,MOROCCO" },
  {
    code: "NDY",
    name: "Sanday Intl Airport",
    Address: "Sanday,UNITED KINGDOM",
  },
  {
    code: "NEF",
    name: "Neftekamsk Intl Airport",
    Address: "Neftekamsk Intl Airport,RUSSIA",
  },
  { code: "NEG", name: "Negril Intl Airport", Address: "Negril,JAMAICA" },
  {
    code: "NER",
    name: "Neryungri Chulman",
    Address: "Neryungri Chulman,RUSSIA",
  },
  {
    code: "NEV",
    name: "Nevis Intl Airport",
    Address: "Nevis,SAINT KITTS AND NEVIS",
  },
  {
    code: "NFG",
    name: "Nefteyugansk Intl Airport",
    Address: "Nefteyugansk,RUSSIA",
  },
  {
    code: "FLX",
    name: "Fallon Municipal Intl Airport",
    Address: "Fallon,UNITED STATES",
  },
  { code: "NGA", name: "Young Intl Airport", Address: "Young,AUSTRALIA" },
  { code: "NGB", name: "Ningbo Intl Airport", Address: "Ningbo,CHINA" },
  {
    code: "NGE",
    name: "N Gaoundere Intl Airport",
    Address: "N Gaoundere,CAMEROON",
  },
  {
    code: "NKM",
    name: "Nagoya Komaki Intl Airport",
    Address: "Nagoya,JAPAN",
  },
  {
    code: "NGO",
    name: "Chubu Centrair Intl Airport",
    Address: "Nagoya,JAPAN",
  },
  {
    code: "NGS",
    name: "Nagasaki Intl Airport",
    Address: "Nagasaki,JAPAN",
  },
  {
    code: "NHA",
    name: "Nha Trang Intl Airport",
    Address: "Nha Trang,VIET NAM",
  },
  {
    code: "NHZ",
    name: "Naval Air Station",
    Address: "Brunswick,UNITED STATES",
  },
  { code: "NIM", name: "Niamey Intl Airport", Address: "Niamey,NIGER" },
  {
    code: "NJC",
    name: "Nizhnevartovsk Intl Airport",
    Address: "Nizhnevartovsk,RUSSIA",
  },
  {
    code: "NJF",
    name: "Al Najaf Intl Airport",
    Address: "Al Najaf Intl Airport,IRAQ",
  },
  {
    code: "NKC",
    name: "Nouakchott Intl Airport",
    Address: "Nouakchott,MAURITANIA",
  },
  { code: "NKG", name: "Nanjing Intl Airport", Address: "Nanjing,CHINA" },
  { code: "NLA", name: "Ndola Intl Airport", Address: "N Dola,ZAMBIA" },
  {
    code: "NLC",
    name: "Nas Reeves Field",
    Address: "Lemoore,UNITED STATES",
  },
  {
    code: "NLD",
    name: "Quetzalcoatl Intl ",
    Address: "Nuevo Laredo,MEXICO",
  },
  {
    code: "NLK",
    name: "Norfolk Island Intl Airport",
    Address: "Norfolk Island,NORFOLK ISLAND",
  },
  {
    code: "NLP",
    name: "Nelspruit Intl Airport",
    Address: "Nelspruit,SOUTH AFRICA",
  },
  {
    code: "MQP",
    name: "Kruger Mpumalanga Intl Airport",
    Address: "Nelspruit,SOUTH AFRICA",
  },
  {
    code: "NLV",
    name: "Nikolaev Intl Airport",
    Address: "Nikolaev,UKRAINE",
  },
  {
    code: "NMA",
    name: "Namangrad Intl Airport",
    Address: "Namangrad,UZBEKISTAN",
  },
  { code: "NNG", name: "Nanning Intl Airport", Address: "Nanning,CHINA" },
  {
    code: "NNM",
    name: "Naryan-Mar Intl Airport",
    Address: "Naryan Mar,RUSSIA",
  },
  {
    code: "NNR",
    name: "Connemara Intl Airport",
    Address: "Spiddal,IRELAND",
  },
  { code: "NNT", name: "Nan Intl Airport", Address: "Nan Th,THAILAND" },
  { code: "NOA", name: "Nowra Intl Airport", Address: "Nowra,AUSTRALIA" },
  {
    code: "NOB",
    name: "Nosara Beach Intl Airport",
    Address: "Nosara Beach,COSTA RICA",
  },
  { code: "NOC", name: "Knock Intl ", Address: "Knock,IRELAND" },
  {
    code: "NOG",
    name: "Nogales Intl Airport",
    Address: "Nogales,MEXICO",
  },
  {
    code: "NOJ",
    name: "Nojabrxsk Intl Airport",
    Address: "Nojabrxsk,RUSSIA",
  },
  {
    code: "NOS",
    name: "Fascene Intl Airport",
    Address: "Nossi Be,MADAGASCAR",
  },
  {
    code: "NOT",
    name: "Novato Intl Airport",
    Address: "Novato,UNITED STATES",
  },
  {
    code: "NOU",
    name: "Tontouta Intl Airport",
    Address: "Noumea,NEW CALEDONIA",
  },
  {
    code: "GEA",
    name: "Magenta Intl Airport",
    Address: "Noumea,NEW CALEDONIA",
  },
  {
    code: "NOZ",
    name: "Novokuznetsk Intl Airport",
    Address: "Novokuznetsk,RUSSIA",
  },
  {
    code: "NPE",
    name: "Hawkes Bay Intl Airport",
    Address: "Napier Hastings,NEW ZEALAND",
  },
  {
    code: "NPL",
    name: "New Plymouth Intl Airport",
    Address: "New Plymouth,NEW ZEALAND",
  },
  {
    code: "NPT",
    name: "Newport State Intl Airport",
    Address: "Newport,UNITED STATES",
  },
  {
    code: "NQI",
    name: "Naval Air Station Intl Airport",
    Address: "Kingsville,UNITED STATES",
  },
  {
    code: "NQN",
    name: "Neuquen Intl Airport",
    Address: "Neuquen,ARGENTINA",
  },
  {
    code: "EMA",
    name: "East Midlands Intl Airport",
    Address: "Nottingham Uk,UNITED KINGDOM",
  },
  {
    code: "NQT",
    name: "Nottingham Intl Airport",
    Address: "Nottingham Uk,UNITED KINGDOM",
  },
  {
    code: "NQY",
    name: "Newquay Civil Intl Airport",
    Address: "Newquay,UNITED KINGDOM",
  },
  {
    code: "NRA",
    name: "Narrandera Intl Airport",
    Address: "Narrandera,AUSTRALIA",
  },
  {
    code: "XYK",
    name: "Norrkoping Railway Service",
    Address: "Norrkoping,SWEDEN",
  },
  {
    code: "NRK",
    name: "Kungsangen Intl Airport",
    Address: "Norrkoping,SWEDEN",
  },
  {
    code: "NRL",
    name: "North Ronaldsay Intl Airport",
    Address: "North Ronaldsay,UNITED KINGDOM",
  },
  { code: "NSA", name: "Noosa Intl Airport", Address: "Noosa,AUSTRALIA" },
  {
    code: "NSE",
    name: "Whiting Field Nas",
    Address: "Milton,UNITED STATES",
  },
  {
    code: "NSK",
    name: "Norilsk Intl Airport",
    Address: "Norilsk,RUSSIA",
  },
  {
    code: "NSM",
    name: "Norseman Intl Airport",
    Address: "Norseman,AUSTRALIA",
  },
  {
    code: "NSN",
    name: "Nelson Intl Airport",
    Address: "Nelson,NEW ZEALAND",
  },
  { code: "NSO", name: "Scone Intl Airport", Address: "Scone,AUSTRALIA" },
  {
    code: "NST",
    name: "Nakhon Si Thammarat Intl Airport",
    Address: "Nakhon Si Thammarat,THAILAND",
  },
  {
    code: "NTB",
    name: "Notodden Intl Airport",
    Address: "Notodden,NORWAY",
  },
  { code: "QJZ", name: "Nantes Rail Station", Address: "Nantes,FRANCE" },
  { code: "NTE", name: "Nantes Atlantique", Address: "Nantes,FRANCE" },
  { code: "NTG", name: "Nantong Intl Airport", Address: "Nantong,CHINA" },
  {
    code: "NTL",
    name: "Williamtown Intl Airport",
    Address: "Newcastle,AUSTRALIA",
  },
  {
    code: "BEO",
    name: "Belmont Intl Airport",
    Address: "Newcastle,AUSTRALIA",
  },
  {
    code: "NTN",
    name: "Normanton Intl Airport",
    Address: "Normanton,AUSTRALIA",
  },
  {
    code: "NTO",
    name: "Santo Antao Intl Airport",
    Address: "Santo Antao,CAPE VERDE",
  },
  {
    code: "NTY",
    name: "Pilansberg Intl Airport",
    Address: "Sun City,SOUTH AFRICA",
  },
  {
    code: "NUE",
    name: "Nuremberg Intl Airport",
    Address: "Nuremberg,GERMANY",
  },
  {
    code: "ZAQ",
    name: "Nuremberg Rail Station",
    Address: "Nuremberg,GERMANY",
  },
  {
    code: "NUL",
    name: "Nulato Intl Airport",
    Address: "Nulato,UNITED STATES",
  },
  {
    code: "NUQ",
    name: "Moffett Field",
    Address: "Mountain View,UNITED STATES",
  },
  {
    code: "NUR",
    name: "Nullarbor Intl Airport",
    Address: "Nullarbor,AUSTRALIA",
  },
  {
    code: "NUX",
    name: "Novy Urengoy Intl Airport",
    Address: "Novy Urengoy,RUSSIA",
  },
  { code: "NVK", name: "Framnes Intl Airport", Address: "Narvik,NORWAY" },
  {
    code: "NVR",
    name: "Yurievo Intl Airport",
    Address: "Novgorod,RUSSIA",
  },
  { code: "NVS", name: "Nevers Intl Airport", Address: "Nevers,FRANCE" },
  {
    code: "NVT",
    name: "Navegantes Intl Airport",
    Address: "Navegantes,BRAZIL",
  },
  {
    code: "NWI",
    name: "Norwich Intl Airport",
    Address: "Norwich,UNITED KINGDOM",
  },
  {
    code: "NYA",
    name: "Nyagan Intl Airport",
    Address: "Nyagan Intl Airport,RUSSIA",
  },
  { code: "LGA", name: "La Guardia", Address: "New York,UNITED STATES" },
  { code: "NYE", name: "Nyeri Intl Airport", Address: "Nyeri,KENYA" },
  { code: "NYK", name: "Nanyuki Intl Airport", Address: "Nanyuki,KENYA" },
  {
    code: "NYM",
    name: "Nadym Intl Airport",
    Address: "Nadym Intl Airport,RUSSIA",
  },
  {
    code: "NYN",
    name: "Nyngan Intl Airport",
    Address: "Nyngan,AUSTRALIA",
  },
  {
    code: "NYU",
    name: "Nyaung U Intl Airport",
    Address: "Nyaung,MYANMAR",
  },
  {
    code: "OAG",
    name: "Springhill Intl Airport",
    Address: "Orange,AUSTRALIA",
  },
  {
    code: "OAJ",
    name: "Albert J Ellis",
    Address: "Jacksonville,UNITED STATES",
  },
  {
    code: "OAK",
    name: "Metro Oakland Intl Airport",
    Address: "Oakland,UNITED STATES",
  },
  { code: "OAL", name: "Cacoal Intl Airport", Address: "Cacoal,BRAZIL" },
  {
    code: "OAM",
    name: "Oamaru Intl Airport",
    Address: "Oamaru,NEW ZEALAND",
  },
  {
    code: "OAX",
    name: "Xoxocotlan Intl Airport",
    Address: "Oaxaca,MEXICO",
  },
  {
    code: "OBE",
    name: "Okeechobee County Intl Airport",
    Address: "Okeechobee,UNITED STATES",
  },
  {
    code: "OBN",
    name: "Connel Intl Airport",
    Address: "Oban,UNITED KINGDOM",
  },
  { code: "OBO", name: "Obihiro Intl Airport", Address: "Obihiro,JAPAN" },
  {
    code: "OBS",
    name: "Vals Lanas Intl Airport",
    Address: "Aubenas,FRANCE",
  },
  {
    code: "OCA",
    name: "Ocean Reef Intl Airport",
    Address: "Ocean Reef,UNITED STATES",
  },
  {
    code: "OCE",
    name: "Ocean City Intl Airport",
    Address: "Ocean City,UNITED STATES",
  },
  {
    code: "OCF",
    name: "Taylor Field Intl Airport",
    Address: "Ocala,UNITED STATES",
  },
  {
    code: "OCJ",
    name: "Boscobel Intl Airport",
    Address: "Ocho Rios,JAMAICA",
  },
  {
    code: "OCN",
    name: "Oceanside Municipal Intl Airport",
    Address: "Oceanside,UNITED STATES",
  },
  { code: "ODB", name: "Cordoba Intl Airport", Address: "Cordoba,SPAIN" },
  {
    code: "ZBQ",
    name: "Odense Railroad Station",
    Address: "Odense,DENMARK",
  },
  { code: "ZIM", name: "Odense Bus Service", Address: "Odense,DENMARK" },
  { code: "ODE", name: "Odense Intl Airport", Address: "Odense,DENMARK" },
  {
    code: "ODN",
    name: "Long Seridan Intl Airport",
    Address: "Long Seridan,MALAYSIA",
  },
  {
    code: "ODS",
    name: "Central Intl Airport",
    Address: "Odessa,UKRAINE",
  },
  {
    code: "ODW",
    name: "Oak Harbour Municipal",
    Address: "Oak Harbor,UNITED STATES",
  },
  {
    code: "OEA",
    name: "Oneal Intl Airport",
    Address: "Vincennes,UNITED STATES",
  },
  {
    code: "OEL",
    name: "Oryol Yuzhny Intl Airport",
    Address: "Oryol Yuzhny Intl Airport,RUSSIA",
  },
  {
    code: "OER",
    name: "Ornskoldsvik Intl Airport",
    Address: "Ornskoldsvik,SWEDEN",
  },
  {
    code: "OFK",
    name: "Karl Stefan Fld",
    Address: "Norfolk,UNITED STATES",
  },
  {
    code: "OGA",
    name: "Searle Field",
    Address: "Ogallala,UNITED STATES",
  },
  {
    code: "OGD",
    name: "Ogden Municipal",
    Address: "Ogden,UNITED STATES",
  },
  {
    code: "OGG",
    name: "Kahului Intl Airport",
    Address: "Maui,UNITED STATES",
  },
  {
    code: "OGS",
    name: "Ogdensburg Municipal",
    Address: "Ogdensburg,UNITED STATES",
  },
  { code: "OGZ", name: "VLADIKAVKAZ", Address: "VLADIKAVKAZ,RUSSIA" },
  {
    code: "OHD",
    name: "Ohrid Intl Airport",
    Address: "Ohrid,MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
  },
  {
    code: "OHH",
    name: "Okha Intl Airport",
    Address: "Okha Intl Airport,RUSSIA",
  },
  {
    code: "OHO",
    name: "Okhotsk Intl Airport",
    Address: "Okhotsk Intl Airport,RUSSIA",
  },
  {
    code: "OIA",
    name: "Ourilandia Intl Airport",
    Address: "Ourilandia,BRAZIL",
  },
  { code: "OIM", name: "Oshima Intl Airport", Address: "Oshima,JAPAN" },
  { code: "OIT", name: "Oita Intl Airport", Address: "Oita,JAPAN" },
  { code: "OKA", name: "Naha Field", Address: "Okinawa,JAPAN" },
  {
    code: "PWA",
    name: "Wiley Post Intl Airport",
    Address: "Oklahoma City,UNITED STATES",
  },
  {
    code: "DWN",
    name: "Downtown Airpark",
    Address: "Oklahoma City,UNITED STATES",
  },
  {
    code: "OKC",
    name: "Will Rogers World Intl Airport",
    Address: "Oklahoma City,UNITED STATES",
  },
  {
    code: "OKE",
    name: "Okino Erabu Intl Airport",
    Address: "Okino Erabu,JAPAN",
  },
  { code: "OKJ", name: "Okayama Intl Airport", Address: "Okayama,JAPAN" },
  {
    code: "OKK",
    name: "Kokomo Municipal",
    Address: "Kokomo,UNITED STATES",
  },
  {
    code: "OKT",
    name: "Oktyabrsky Intl Airport",
    Address: "Oktyabrsky Intl Airport,RUSSIA",
  },
  { code: "OKY", name: "Oakey Intl Airport", Address: "Oakey,AUSTRALIA" },
  {
    code: "OLB",
    name: "Costa Smeralda Intl Airport",
    Address: "Olbia,ITALY",
  },
  {
    code: "OLK",
    name: "Fuerte Olimpo Intl Airport",
    Address: "Fuerte Olimpo,PARAGUAY",
  },
  {
    code: "OLM",
    name: "Olympia Intl Airport",
    Address: "Olympia,UNITED STATES",
  },
  {
    code: "OLP",
    name: "Olympic Dam Intl Airport",
    Address: "Olympic Dam,AUSTRALIA",
  },
  {
    code: "OLS",
    name: " Intl Airport",
    Address: "Nogales,UNITED STATES",
  },
  {
    code: "OLU",
    name: "Columbus Intl Airport",
    Address: "Columbus,UNITED STATES",
  },
  {
    code: "MIQ",
    name: "Millard Intl Airport",
    Address: "Omaha,UNITED STATES",
  },
  {
    code: "OMA",
    name: "Eppley Airfield",
    Address: "Omaha,UNITED STATES",
  },
  {
    code: "OMD",
    name: "Oranjemund Intl Airport",
    Address: "Oranjemund,NAMIBIA",
  },
  {
    code: "OME",
    name: "Nome Intl Airport",
    Address: "Nome,UNITED STATES",
  },
  {
    code: "OMO",
    name: "Mostar Intl Airport",
    Address: "Mostar,BOSNIA AND HERZEGOVINA",
  },
  { code: "OMR", name: "Oradea Intl Airport", Address: "Oradea,ROMANIA" },
  { code: "OMS", name: "Omsk Intl Airport", Address: "Omsk,RUSSIA" },
  {
    code: "OND",
    name: "Ondangwa Intl Airport",
    Address: "Ondangwa,NAMIBIA",
  },
  {
    code: "ONG",
    name: "Mornington Intl Airport",
    Address: "Mornington,AUSTRALIA",
  },
  {
    code: "ONH",
    name: "Oneonta Municpal",
    Address: "Oneonta,UNITED STATES",
  },
  {
    code: "ONJ",
    name: "Odate Noshiro Intl Airport",
    Address: "Odate Noshiro,JAPAN",
  },
  {
    code: "ONM",
    name: "Socorro Municipal Intl Airport",
    Address: "Socorro,UNITED STATES",
  },
  {
    code: "ONP",
    name: "Newport Municipal Intl Airport",
    Address: "Newport,UNITED STATES",
  },
  {
    code: "ONQ",
    name: "Zonguldak Intl Airport",
    Address: "Zonguldak,TURKEY",
  },
  {
    code: "ONT",
    name: "Ontario Intl ",
    Address: "Ontario,UNITED STATES",
  },
  {
    code: "OOL",
    name: "Gold Coast Intl Airport",
    Address: "Gold Coast,AUSTRALIA",
  },
  { code: "OOM", name: "Cooma Intl Airport", Address: "Cooma,AUSTRALIA" },
  {
    code: "OPA",
    name: "Kopasker Intl Airport",
    Address: "Kopasker,ICELAND",
  },
  { code: "OPO", name: "Porto Intl Airport", Address: "Porto,PORTUGAL" },
  {
    code: "XWR",
    name: "Orebro Bofors Railway Service",
    Address: "Orebro Bofors,SWEDEN",
  },
  {
    code: "ORB",
    name: "Orebro Bofors Intl Airport",
    Address: "Orebro Bofors,SWEDEN",
  },
  {
    code: "XWM",
    name: "Hallsberg Rail Station",
    Address: "Orebro Bofors,SWEDEN",
  },
  {
    code: "ORE",
    name: "Orleans Intl Airport",
    Address: "Orleans,FRANCE",
  },
  {
    code: "ORF",
    name: "Norfolk Intl Airport",
    Address: "Norfolk,UNITED STATES",
  },
  {
    code: "ORH",
    name: "Worcester Intl Airport",
    Address: "Worcester,UNITED STATES",
  },
  { code: "ORK", name: "Cork Intl Airport", Address: "Cork,IRELAND" },
  {
    code: "ORL",
    name: "Herndon Intl Airport",
    Address: "Orlando,UNITED STATES",
  },
  {
    code: "MCO",
    name: "Orlando Intl Airport",
    Address: "Orlando,UNITED STATES",
  },
  {
    code: "ORM",
    name: "Northampton Rail Station",
    Address: "Northampton,UNITED KINGDOM",
  },
  { code: "ORN", name: "Es Senia", Address: "Oran,ALGERIA" },
  {
    code: "ORS",
    name: "Waterport Intl Airport",
    Address: "Orpheus Island,AUSTRALIA",
  },
  { code: "ITM", name: "Itami Intl Airport", Address: "Osaka,JAPAN" },
  { code: "KIX", name: "Kansai Intl Airport", Address: "Osaka,JAPAN" },
  {
    code: "OSD",
    name: "Froesoe Intl Airport",
    Address: "Ostersund,SWEDEN",
  },
  {
    code: "OSH",
    name: "Wittman Field",
    Address: "Oshkosh,UNITED STATES",
  },
  { code: "OSI", name: "Osijek Intl Airport", Address: "Osijek,CROATIA" },
  { code: "OSK", name: "Oskarshamn", Address: "Oskarshamn,SWEDEN" },
  { code: "OSL", name: "Oslo Intl Airport", Address: "Oslo,NORWAY" },
  {
    code: "TRF",
    name: "Sandefjord Intl Airport",
    Address: "Oslo,NORWAY",
  },
  {
    code: "RYG",
    name: "Moss Rygge Intl Airport",
    Address: "Oslo,NORWAY",
  },
  { code: "XZO", name: "Oslo Central Station", Address: "Oslo,NORWAY" },
  { code: "XND", name: "Drammen Rail Station", Address: "Oslo,NORWAY" },
  { code: "XKQ", name: "Sarpsborg Rail Station", Address: "Oslo,NORWAY" },
  { code: "XKM", name: "Moss Rail Station", Address: "Oslo,NORWAY" },
  {
    code: "XKI",
    name: "Lillestrom Rail Station",
    Address: "Oslo,NORWAY",
  },
  {
    code: "XKF",
    name: "Fredrikstad Rail Station",
    Address: "Oslo,NORWAY",
  },
  { code: "XKE", name: "Rena Rail Station", Address: "Oslo,NORWAY" },
  { code: "XKD", name: "Halden Rail Station", Address: "Oslo,NORWAY" },
  { code: "XKB", name: "Kongsberg Rail Station", Address: "Oslo,NORWAY" },
  { code: "XGU", name: "Asker Rail Station", Address: "Oslo,NORWAY" },
  { code: "OSM", name: "Mosul Intl Airport", Address: "Mosul,IRAQ" },
  {
    code: "OSR",
    name: "Mosnov Intl Airport",
    Address: "Ostrava,CZECH REPUBLIC",
  },
  { code: "OSS", name: "Osh Intl Airport", Address: "Osh,KYRGYZSTAN" },
  {
    code: "OST",
    name: "Ostend Intl Airport",
    Address: "Ostende,BELGIUM",
  },
  { code: "OSY", name: "Namsos Intl Airport", Address: "Namsos,NORWAY" },
  {
    code: "OTG",
    name: "Worthington Intl Airport",
    Address: "Worthington,UNITED STATES",
  },
  {
    code: "OTH",
    name: "North Bend Municipal",
    Address: "North Bend,UNITED STATES",
  },
  {
    code: "OTM",
    name: "Industrial Intl Airport",
    Address: "Ottumwa,UNITED STATES",
  },
  {
    code: "OTS",
    name: "Ancortes Intl Airport",
    Address: "Ancortes,UNITED STATES",
  },
  {
    code: "OTZ",
    name: "Ralph Wien Memorial",
    Address: "Kotzebue,UNITED STATES",
  },
  {
    code: "OUA",
    name: "Ouagadougou Intl Airport",
    Address: "Ouagadougou,BURKINA FASO",
  },
  {
    code: "OUD",
    name: "Les Angades Intl Airport",
    Address: "Oujda,MOROCCO",
  },
  {
    code: "OUH",
    name: "Oudtshoorn Intl Airport",
    Address: "Oudtshoorn,SOUTH AFRICA",
  },
  { code: "OUL", name: "Oulu Intl Airport", Address: "Oulu,FINLAND" },
  {
    code: "OUZ",
    name: "Zouerate Intl Airport",
    Address: "Zouerate,MAURITANIA",
  },
  {
    code: "OVB",
    name: "Tolmachevo Intl Airport",
    Address: "Novosibirsk,RUSSIA",
  },
  {
    code: "OVD",
    name: "Asturias Intl Airport",
    Address: "Asturias,SPAIN",
  },
  {
    code: "OVS",
    name: "Sovetsky Intl Airport",
    Address: "Boscobel,UNITED STATES",
  },
  {
    code: "OWA",
    name: "Owatonna Intl Airport",
    Address: "Owatonna,UNITED STATES",
  },
  {
    code: "OWB",
    name: "Daviess County Intl Airport",
    Address: "Owensboro,UNITED STATES",
  },
  {
    code: "OWD",
    name: "Norwood Ma Intl Airport",
    Address: "Norwood,UNITED STATES",
  },
  {
    code: "OXB",
    name: " Intl Airport  Osvaldo Viera",
    Address: "Bissau,GUINEA-BISSAU",
  },
  {
    code: "OXC",
    name: "Waterbury Oxford Intl Airport",
    Address: "Oxford,UNITED STATES",
  },
  {
    code: "OXF",
    name: "Kidlington Intl Airport",
    Address: "Oxford,UNITED KINGDOM",
  },
  {
    code: "BZZ",
    name: "Brize Norton Raf Station",
    Address: "Oxford,UNITED KINGDOM",
  },
  {
    code: "OXR",
    name: "Oxnard Ventura Intl Airport",
    Address: "Oxnard,UNITED STATES",
  },
  {
    code: "OYO",
    name: "Tres Arroyos Intl Airport",
    Address: "Tres Arroyos,ARGENTINA",
  },
  {
    code: "OZC",
    name: "Labo Intl Airport",
    Address: "Labo Intl Airport,PHILIPPINES",
  },
  {
    code: "OZH",
    name: "Zaporozhye Intl Airport",
    Address: "Zaporozhe,UKRAINE",
  },
  { code: "OZZ", name: "Ourzazate", Address: "Ourzazate,MOROCCO" },
  {
    code: "PAD",
    name: "Paderborn Intl Airport",
    Address: "Paderborn,GERMANY",
  },
  {
    code: "PAE",
    name: "Snohomish Cty Intl Airport",
    Address: "Everett,UNITED STATES",
  },
  {
    code: "PAH",
    name: "Barkley Regional",
    Address: "Paducah,UNITED STATES",
  },
  {
    code: "PAO",
    name: "Palo Alto Intl Airport",
    Address: "Palo Alto,UNITED STATES",
  },
  {
    code: "PAP",
    name: "Mais Gate Intl Airport",
    Address: "Port Au Prince,HAITI",
  },
  { code: "ORY", name: "Orly Intl Airport", Address: "Paris,FRANCE" },
  {
    code: "JDP",
    name: "Issy Les Moulineaux Intl Airport",
    Address: "Paris,FRANCE",
  },
  {
    code: "LBG",
    name: "Le Bourget Intl Airport",
    Address: "Paris,FRANCE",
  },
  {
    code: "CDG",
    name: "Charles De Gaulle Intl Airport",
    Address: "Paris,FRANCE",
  },
  {
    code: "BVA",
    name: "Beauvais Tille Intl Airport",
    Address: "Paris,FRANCE",
  },
  {
    code: "XPG",
    name: "Gare du Nord Railway Station",
    Address: "Paris,FRANCE",
  },
  { code: "XCR", name: "Vatry Intl Airport", Address: "Paris,FRANCE" },
  {
    code: "PAS",
    name: "Paros Community Intl Airport",
    Address: "Paros,GREECE",
  },
  {
    code: "PAT",
    name: "Jai Prakash Narayan Intl Airport",
    Address: "Patna,INDIA",
  },
  {
    code: "PAZ",
    name: "Tajin Intl Airport",
    Address: "Poza Rica,MEXICO",
  },
  {
    code: "PBC",
    name: "Huejostingo Intl Airport",
    Address: "Puebla,MEXICO",
  },
  {
    code: "PBD",
    name: "Porbandar Intl Airport",
    Address: "Porbandar,INDIA",
  },
  {
    code: "PBF",
    name: "Pine Bluff Intl Airport",
    Address: "Pine Bluff,UNITED STATES",
  },
  {
    code: "PBG",
    name: "Plattsburgh Intl Airport",
    Address: "Plattsburgh,UNITED STATES",
  },
  { code: "PBH", name: "Paro Intl Airport", Address: "Paro,BHUTAN" },
  {
    code: "LNA",
    name: "Palm Beach County Intl Airport",
    Address: "West Palm Beach,UNITED STATES",
  },
  {
    code: "PBI",
    name: "Palm Beach Intl Airport",
    Address: "West Palm Beach,UNITED STATES",
  },
  {
    code: "PBM",
    name: "Zanderij Intl Airport",
    Address: "Paramaribo,SURINAME",
  },
  {
    code: "ORG",
    name: "Zorg En Hoop Intl Airport",
    Address: "Paramaribo,SURINAME",
  },
  {
    code: "PBO",
    name: "Paraburdoo Intl Airport",
    Address: "Paraburdoo,AUSTRALIA",
  },
  {
    code: "PBR",
    name: "Puerto Barrios Intl Airport",
    Address: "Puerto Barrios,GUATEMALA",
  },
  {
    code: "PBZ",
    name: "Plettenberg Bay Intl Airport",
    Address: "Plettenberg Bay,SOUTH AFRICA",
  },
  {
    code: "PCD",
    name: "Prairie Du Chien Municipal Intl Airport",
    Address: "Prairie Du Chien,UNITED STATES",
  },
  {
    code: "PCJ",
    name: "Puerto La Victoria Intl Airport",
    Address: "Puerto La Victoria,PARAGUAY",
  },
  {
    code: "PCL",
    name: "Capitan Rolden Intl Airport",
    Address: "Pucallpa,PERU",
  },
  {
    code: "PCM",
    name: "Playa Del Carmen Intl Airport",
    Address: "Playa Del Carmen,MEXICO",
  },
  { code: "PCN", name: "Koromiko", Address: "Picton,NEW ZEALAND" },
  {
    code: "PCT",
    name: "Princeton Intl Airport",
    Address: "Princeton,UNITED STATES",
  },
  {
    code: "PDG",
    name: "Tabing Intl Airport",
    Address: "Padang,INDONESIA",
  },
  {
    code: "PDL",
    name: "Nordela Intl Airport",
    Address: "Ponta Delgada,PORTUGAL",
  },
  {
    code: "PDP",
    name: "Cap Curbelo Intl Airport",
    Address: "Punta Del Este,URUGUAY",
  },
  {
    code: "PDS",
    name: "Piedras Negras Intl Airport",
    Address: "Piedras Negras,MEXICO",
  },
  {
    code: "PDT",
    name: "Pendleton Municipal",
    Address: "Pendleton,UNITED STATES",
  },
  {
    code: "PDU",
    name: "Paysandu Intl Airport",
    Address: "Paysandu,URUGUAY",
  },
  {
    code: "PDV",
    name: "Plovdiv Intl Airport",
    Address: "Plovdiv,BULGARIA",
  },
  {
    code: "PDX",
    name: "Portland Intl Airport",
    Address: "Portland,UNITED STATES",
  },
  {
    code: "PED",
    name: "Pardubice Intl Airport",
    Address: "Pardubice,CZECH REPUBLIC",
  },
  { code: "PEE", name: "Perm Intl Airport", Address: "Perm,RUSSIA" },
  {
    code: "PEF",
    name: "Peenemuende Intl Airport",
    Address: "Peenemuende,GERMANY",
  },
  {
    code: "PEG",
    name: "Sant Egidio Intl Airport",
    Address: "Perugia,ITALY",
  },
  {
    code: "PEI",
    name: "Matecana Intl Airport",
    Address: "Pereira,COLOMBIA",
  },
  {
    code: "PEJ",
    name: "Peschiei Intl Airport",
    Address: "Peschiei,ITALY",
  },
  {
    code: "PEM",
    name: "Puerto Maldonado Intl Airport",
    Address: "Puerto Maldonado,PERU",
  },
  {
    code: "PEN",
    name: "Penang Intl Airport",
    Address: "Penang,MALAYSIA",
  },
  { code: "PER", name: "Perth Intl Airport", Address: "Perth,AUSTRALIA" },
  {
    code: "PES",
    name: "Petrozavodsk Intl Airport",
    Address: "Petrozavodsk,RUSSIA",
  },
  {
    code: "PET",
    name: "Pelotas Federal Intl Airport",
    Address: "Pelotas,BRAZIL",
  },
  { code: "PEV", name: "Pecs Intl Airport", Address: "Pecs,HUNGARY" },
  {
    code: "PEW",
    name: "Peshawar Intl Airport",
    Address: "Peshawar,PAKISTAN",
  },
  {
    code: "PEX",
    name: "Pechora Intl Airport",
    Address: "Pechora Intl Airport,RUSSIA",
  },
  { code: "PEZ", name: "Penza Intl Airport", Address: "Penza,RUSSIA" },
  {
    code: "PFB",
    name: "Passo Fundo Intl Airport",
    Address: "Passo Fundo,BRAZIL",
  },
  {
    code: "ECP",
    name: "Northwest Florida Beaches Intl Airport",
    Address: "Panama City,UNITED STATES",
  },
  {
    code: "PFN",
    name: "Bay County Intl Airport",
    Address: "Panama City,UNITED STATES",
  },
  { code: "PFO", name: "Paphos Intl Airport", Address: "Paphos,CYPRUS" },
  {
    code: "PFQ",
    name: "Parsabad Intl Airport",
    Address: "Parsabad Intl Airport,IRAN",
  },
  {
    code: "PGA",
    name: "Page Intl Airport",
    Address: "Page,UNITED STATES",
  },
  {
    code: "PGD",
    name: "Charlotte County",
    Address: "Punta Gorda,UNITED STATES",
  },
  {
    code: "PGF",
    name: "Llabanere Intl Airport",
    Address: "Perpignan,FRANCE",
  },
  {
    code: "PGH",
    name: "Pantnagar Intl Airport",
    Address: "Pantnagar,INDIA",
  },
  {
    code: "PGL",
    name: "Jackson Cnty",
    Address: "Pascagoula,UNITED STATES",
  },
  {
    code: "PGO",
    name: "Stevens Field Intl Airport",
    Address: "Pagosa Springs,UNITED STATES",
  },
  {
    code: "PGU",
    name: "Ala'Marvdasht Intl Airport",
    Address: "Ala'Marvdasht Intl Airport,IRAN",
  },
  {
    code: "PGV",
    name: "Pitt Greenville Intl Airport",
    Address: "Greenville,UNITED STATES",
  },
  {
    code: "PGX",
    name: "Perigueux Intl Airport",
    Address: "Perigueux,FRANCE",
  },
  {
    code: "PHB",
    name: "Santos Dumont Intl Airport",
    Address: "Parnaiba,BRAZIL",
  },
  {
    code: "PHC",
    name: "Port Harcourt Intl Airport",
    Address: "Port Harcourt,NIGERIA",
  },
  {
    code: "PHG",
    name: "Port Harcourt City Intl Airport",
    Address: "Port Harcourt,NIGERIA",
  },
  {
    code: "PHE",
    name: "Port Hedland Intl Airport",
    Address: "Port Hedland,AUSTRALIA",
  },
  {
    code: "PHF",
    name: "Williamsburg Intl Airport",
    Address: "Newport News,UNITED STATES",
  },
  {
    code: "PHH",
    name: "Phan Thiet Intl Airport",
    Address: "Phan Thiet,VIET NAM",
  },
  {
    code: "PNE",
    name: "N Philadelphia",
    Address: "Philadelphia,UNITED STATES",
  },
  {
    code: "PHL",
    name: "Philadelphia Intl Airport",
    Address: "Philadelphia,UNITED STATES",
  },
  {
    code: "PHN",
    name: "St Clair County Intl Airport",
    Address: "Port Huron,UNITED STATES",
  },
  {
    code: "PHS",
    name: "Phitsanulok Intl Airport",
    Address: "Phitsanulok,THAILAND",
  },
  {
    code: "PHT",
    name: "Henry County Intl Airport",
    Address: "Paris,UNITED STATES",
  },
  {
    code: "PHW",
    name: "Phalaborwa Intl Airport",
    Address: "Phalaborwa,SOUTH AFRICA",
  },
  {
    code: "PHX",
    name: "Sky Harbor Intl Airport",
    Address: "Phoenix,UNITED STATES",
  },
  {
    code: "AZA",
    name: "Williams Gateway Intl Airport",
    Address: "Phoenix,UNITED STATES",
  },
  {
    code: "DVT",
    name: "Phoenix Deer Valley Intl Airport",
    Address: "Phoenix,UNITED STATES",
  },
  {
    code: "PIA",
    name: "Greater Peoria Intl Airport",
    Address: "Peoria,UNITED STATES",
  },
  {
    code: "PIH",
    name: "Pocatello Municipal Intl Airport",
    Address: "Pocatello,UNITED STATES",
  },
  {
    code: "PIR",
    name: "Pierre Municipal",
    Address: "Pierre,UNITED STATES",
  },
  {
    code: "XOP",
    name: "Poitiers Rail Station",
    Address: "Poitiers,FRANCE",
  },
  { code: "PIS", name: "Biard Intl Airport", Address: "Poitiers,FRANCE" },
  {
    code: "AGC",
    name: "Allegheny Cty Intl Airport",
    Address: "Pittsburgh,UNITED STATES",
  },
  {
    code: "PIT",
    name: "Pittsburgh Intl Airport",
    Address: "Pittsburgh,UNITED STATES",
  },
  { code: "PIU", name: "Piura Intl Airport", Address: "Piura,PERU" },
  {
    code: "PIW",
    name: "Pikwitonei Rail Station",
    Address: "Pikwitonei,CANADA",
  },
  {
    code: "PIX",
    name: "Pico Intl Airport",
    Address: "Pico Island,PORTUGAL",
  },
  {
    code: "PIZ",
    name: "Dew Station Intl Airport",
    Address: "Point Lay,UNITED STATES",
  },
  { code: "PJA", name: "Pajala Intl Airport", Address: "Pajala,SWEDEN" },
  {
    code: "PJB",
    name: "Payson Intl Airport",
    Address: "Payson,UNITED STATES",
  },
  {
    code: "PKB",
    name: "Wood County",
    Address: "Parkersburg,UNITED STATES",
  },
  {
    code: "PKC",
    name: "Petropavlovsk Kamchatskiy Intl Airport",
    Address: "Petropavlovsk Kamchatskiy,RUSSIA",
  },
  {
    code: "PKD",
    name: "Park Rapids Municipal Intl Airport",
    Address: "Park Rapids,UNITED STATES",
  },
  {
    code: "PKE",
    name: "Parkes Intl Airport",
    Address: "Parkes,AUSTRALIA",
  },
  {
    code: "PKG",
    name: "Pangkor Intl Airport",
    Address: "Pangkor,MALAYSIA",
  },
  {
    code: "PKH",
    name: "Alexion Intl Airport",
    Address: "Portoheli,GREECE",
  },
  {
    code: "PKJ",
    name: "Playa Grand Intl Airport",
    Address: "Playa Grande,GUATEMALA",
  },
  {
    code: "PKN",
    name: "Pangkalanbuun Intl Airport",
    Address: "Pangkalanbun,INDONESIA",
  },
  { code: "PKR", name: "Pokhara Intl Airport", Address: "Pokhara,NEPAL" },
  {
    code: "PKU",
    name: "Simpang Tiga Intl Airport",
    Address: "Pekanbaru,INDONESIA",
  },
  { code: "PKV", name: "Pskov Intl Airport", Address: "Pskov,RUSSIA" },
  {
    code: "PKW",
    name: "Selebi Phikwe Intl Airport",
    Address: "Selebi Phikwe,BOTSWANA",
  },
  {
    code: "PKZ",
    name: "Pakse Intl Airport",
    Address: "Pakse,LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  {
    code: "PLD",
    name: "Playa Samara Intl Airport",
    Address: "Playa Samara,COSTA RICA",
  },
  {
    code: "PLH",
    name: "Roborough Intl Airport",
    Address: "Plymouth,UNITED KINGDOM",
  },
  {
    code: "PLJ",
    name: "Placencia Intl Airport",
    Address: "Placencia,BELIZE",
  },
  {
    code: "PLK",
    name: "M Graham Clark Intl Airport",
    Address: "Point Lookout,UNITED STATES",
  },
  {
    code: "PLM",
    name: "Mahmud Badaruddin Li Intl Airport",
    Address: "Palembang,INDONESIA",
  },
  {
    code: "PLN",
    name: "Emmet Cty Intl Airport",
    Address: "Pellston,UNITED STATES",
  },
  {
    code: "PLO",
    name: "Port Lincoln Intl Airport",
    Address: "Port Lincoln,AUSTRALIA",
  },
  { code: "PLP", name: "LA PALMA", Address: "LA PALMA,PANAMA" },
  {
    code: "PLQ",
    name: "Palanga Intl Airport",
    Address: "Palanga,LITHUANIA",
  },
  {
    code: "PLS",
    name: "Providenciales Intl ",
    Address: "Providenciales,TURKS AND CAICOS ISLANDS",
  },
  {
    code: "PLV",
    name: "Poltava Intl Airport",
    Address: "Poltava,UKRAINE",
  },
  {
    code: "PLW",
    name: "Mutiara Intl Airport",
    Address: "Palu,INDONESIA",
  },
  {
    code: "PLX",
    name: "SEMIPALATINSK",
    Address: "SEMIPALATINSK,KAZAKHSTAN",
  },
  {
    code: "PLY",
    name: "Plymouth Municipal Intl Airport",
    Address: "Plymouth,UNITED STATES",
  },
  {
    code: "PLZ",
    name: "Port Elizabeth Intl Airport",
    Address: "Port Elizabeth,SOUTH AFRICA",
  },
  {
    code: "PMC",
    name: "Tepual Intl Airport",
    Address: "Puerto Montt,CHILE",
  },
  {
    code: "PMD",
    name: "La Palmdale Regional Intl Airport",
    Address: "Palmdale,UNITED STATES",
  },
  {
    code: "PME",
    name: "Portsmouth Intl Airport",
    Address: "Portsmouth,UNITED KINGDOM",
  },
  { code: "PMF", name: "Parma Intl Airport", Address: "Parma,ITALY" },
  { code: "PMG", name: " Intl ", Address: "Ponta Pora,BRAZIL" },
  {
    code: "PMI",
    name: "Palma Mallorca Intl Airport",
    Address: "Palma Mallorca,SPAIN",
  },
  {
    code: "PMK",
    name: "Palm Island Intl Airport",
    Address: "Palm Island,AUSTRALIA",
  },
  {
    code: "PMO",
    name: "Punta Raisi Intl Airport",
    Address: "Palermo,ITALY",
  },
  {
    code: "PMR",
    name: "Palmerston North Intl Airport",
    Address: "Palmerston,NEW ZEALAND",
  },
  { code: "PMS", name: "Palmyra Intl Airport", Address: "Palmyra,SYRIA" },
  {
    code: "PMV",
    name: "Delcaribe Gen S Marino Intl Airport",
    Address: "Porlamar,VENEZUELA",
  },
  { code: "PMW", name: "Palmas Intl Airport", Address: "Palmas,BRAZIL" },
  {
    code: "PMY",
    name: "El Tehuelche Intl Airport",
    Address: "Puerto Madryn,ARGENTINA",
  },
  { code: "PMZ", name: "Palmar Sur", Address: "Palmar,COSTA RICA" },
  {
    code: "PNA",
    name: "Pamplona Noain Intl Airport",
    Address: "Pamplona,SPAIN",
  },
  {
    code: "PNC",
    name: "Ponca City Municipal Intl Airport",
    Address: "Ponca City,UNITED STATES",
  },
  {
    code: "PNG",
    name: "Paranagua Municipal",
    Address: "Paranagua,BRAZIL",
  },
  {
    code: "PNH",
    name: "Pochentong Intl Airport",
    Address: "Phnom Penh,CAMBODIA",
  },
  {
    code: "PNI",
    name: "Pohnpei Intl Airport",
    Address: "Pohnpei,MICRONESIA, FEDERATED STATES OF",
  },
  {
    code: "PNJ",
    name: "Sha He Kou Intl Airport",
    Address: "Penglai,CHINA",
  },
  {
    code: "PNK",
    name: "Supadio Intl Airport",
    Address: "Pontianak,INDONESIA",
  },
  {
    code: "PNL",
    name: "Pantelleria Intl Airport",
    Address: "Pantelleria,ITALY",
  },
  {
    code: "PNO",
    name: "Pinotepa Nacional Intl Airport",
    Address: "Pinotepa Nacional,MEXICO",
  },
  {
    code: "PNP",
    name: "Girua Intl Airport",
    Address: "Popondetta,PAPUA NEW GUINEA",
  },
  { code: "PNQ", name: "Lohegaon Intl Airport", Address: "Pune,INDIA" },
  {
    code: "PNR",
    name: "Pointe Noire Intl Airport",
    Address: "Pointe Noire,CONGO",
  },
  {
    code: "PNS",
    name: "Pensacola Regional Municipal",
    Address: "Pensacola,UNITED STATES",
  },
  {
    code: "PNT",
    name: "Teniente Julio Gallardo Intl Airport",
    Address: "Puerto Natales,CHILE",
  },
  {
    code: "PNX",
    name: "Grayson County Intl Airport",
    Address: "Sherman,UNITED STATES",
  },
  {
    code: "PNZ",
    name: "Petrolina Internacional Intl Airport",
    Address: "Petrolina,BRAZIL",
  },
  {
    code: "POA",
    name: "Porto Alegre Intl Airport",
    Address: "Porto Alegre,BRAZIL",
  },
  {
    code: "POC",
    name: "Brackett Field",
    Address: "La Verne,UNITED STATES",
  },
  {
    code: "POE",
    name: "Fort Polk Intl Airport",
    Address: "Fort Polk,UNITED STATES",
  },
  {
    code: "POF",
    name: "Earl Fields Memorial",
    Address: "Poplar Bluff,UNITED STATES",
  },
  {
    code: "POG",
    name: "Port Gentil Intl Airport",
    Address: "Port Gentil,GABON",
  },
  {
    code: "POL",
    name: "Pemba Intl Airport",
    Address: "Pemba,MOZAMBIQUE",
  },
  {
    code: "POM",
    name: "Jackson Field",
    Address: "Port Moresby,PAPUA NEW GUINEA",
  },
  {
    code: "POP",
    name: "La Union Intl Airport",
    Address: "Puerto Plata,DOMINICAN REPUBLIC",
  },
  { code: "POR", name: "Pori Intl Airport", Address: "Pori,FINLAND" },
  {
    code: "POS",
    name: "Piarco Intl Airport",
    Address: "Port Of Spain,TRINIDAD AND TOBAGO",
  },
  {
    code: "POU",
    name: "Dutchess County",
    Address: "Poughkeepsie,UNITED STATES",
  },
  {
    code: "POW",
    name: "Portoroz Intl Airport",
    Address: "Portoroz,SLOVENIA",
  },
  { code: "POX", name: "Cormeille En Vexin", Address: "Pontoise,FRANCE" },
  { code: "POZ", name: "Lawica Intl Airport", Address: "Poznan,POLAND" },
  {
    code: "PPB",
    name: "A De Barros",
    Address: "Presidente Prudente,BRAZIL",
  },
  {
    code: "PPE",
    name: "Puerto Penasco Municipal",
    Address: "Puerto Penasco,MEXICO",
  },
  {
    code: "PPG",
    name: "Pago Pago Intl Airport",
    Address: "Pago Pago,AMERICAN SAMOA",
  },
  {
    code: "PPI",
    name: "Port Porie Intl Airport",
    Address: "Port Pirie,AUSTRALIA",
  },
  {
    code: "PPK",
    name: "Petropavlovsk Intl Airport",
    Address: "Petropavlovsk,KAZAKHSTAN",
  },
  { code: "PPL", name: "Phaplu Intl Airport", Address: "Phaplu,NEPAL" },
  {
    code: "PPM",
    name: "Pompano Beach Intl Airport",
    Address: "Pompano Beach,UNITED STATES",
  },
  {
    code: "PPN",
    name: "Machangara Intl Airport",
    Address: "Popayan,COLOMBIA",
  },
  {
    code: "PPP",
    name: "Whitsunday Coast Intl Airport",
    Address: "Proserpine,AUSTRALIA",
  },
  {
    code: "PPQ",
    name: "Paraparaumu Intl Airport",
    Address: "Paraparaumu,NEW ZEALAND",
  },
  {
    code: "PPS",
    name: "Puerto Princesa Intl Airport",
    Address: "Puerto Princesa,PHILIPPINES",
  },
  {
    code: "PPT",
    name: "Intl Tahiti Faaa",
    Address: "Papeete,FRENCH POLYNESIA",
  },
  {
    code: "PPW",
    name: "Papa Westray Intl Airport",
    Address: "Papa Westray,UNITED KINGDOM",
  },
  {
    code: "PQC",
    name: "Duong Dang Intl Airport",
    Address: "Phu Quoc,VIET NAM",
  },
  {
    code: "PQI",
    name: "Northern Maine Regional",
    Address: "Presque Isle,UNITED STATES",
  },
  {
    code: "PQM",
    name: "Palenque Intl Airport",
    Address: "Palenque,MEXICO",
  },
  {
    code: "PQQ",
    name: "Port Macquarie Intl Airport",
    Address: "Pt Macquarie,AUSTRALIA",
  },
  {
    code: "PRA",
    name: "Parana Intl Airport",
    Address: "Parana,ARGENTINA",
  },
  {
    code: "PRB",
    name: "Paso Robles Cty",
    Address: "Paso Robles,UNITED STATES",
  },
  {
    code: "PRC",
    name: "Prescott Municipal",
    Address: "Prescott,UNITED STATES",
  },
  {
    code: "PRG",
    name: "Ruzyne Intl Airport",
    Address: "Prague,CZECH REPUBLIC",
  },
  { code: "PRH", name: "Phrae Intl Airport", Address: "Phrae,THAILAND" },
  {
    code: "PRI",
    name: "Praslin Island Intl Airport",
    Address: "Praslin Island,SEYCHELLES",
  },
  {
    code: "PRM",
    name: "Portimao Intl Airport",
    Address: "Portimao,PORTUGAL",
  },
  {
    code: "PRN",
    name: "Pristina Intl Airport",
    Address: "Pristina,KOSOVO",
  },
  {
    code: "PRP",
    name: "Propriano Intl Airport",
    Address: "Propriano,FRANCE",
  },
  {
    code: "PRX",
    name: "Paris Cox Field Intl Airport",
    Address: "Paris,UNITED STATES",
  },
  {
    code: "PRY",
    name: "Wonderboom Intl Airport",
    Address: "Pretoria,SOUTH AFRICA",
  },
  {
    code: "HPR",
    name: "Central Hpr Intl Airport",
    Address: "Pretoria,SOUTH AFRICA",
  },
  {
    code: "PSA",
    name: "Gal Galilei Intl Airport",
    Address: "Pisa,ITALY",
  },
  {
    code: "PSB",
    name: "Midstate Intl Airport",
    Address: "Philipsburg,UNITED STATES",
  },
  {
    code: "PSC",
    name: "Tri Cities Intl Airport",
    Address: "Pasco,UNITED STATES",
  },
  {
    code: "PSE",
    name: "Mercedita Intl Airport",
    Address: "Ponce,UNITED STATES",
  },
  {
    code: "PSF",
    name: "Pittsfield Municipal",
    Address: "Pittsfield,UNITED STATES",
  },
  {
    code: "PSG",
    name: "Petersburg Municipal",
    Address: "Petersburg,UNITED STATES",
  },
  {
    code: "PSK",
    name: "New River Valley Intl Airport",
    Address: "Dublin,UNITED STATES",
  },
  {
    code: "PSL",
    name: "Perth Intl Airport",
    Address: "Perth,UNITED KINGDOM",
  },
  {
    code: "PSM",
    name: "Portsmouth Pease Intl Airport",
    Address: "Portsmouth,UNITED STATES",
  },
  { code: "PSO", name: "Cano Intl Airport", Address: "Pasto,COLOMBIA" },
  {
    code: "PSP",
    name: "Palm Springs Municipal",
    Address: "Palm Springs,UNITED STATES",
  },
  { code: "PSR", name: "Liberi Intl Airport", Address: "Pescara,ITALY" },
  {
    code: "PSS",
    name: "Posadas Intl Airport",
    Address: "Posadas,ARGENTINA",
  },
  {
    code: "PTB",
    name: "Petersburg Municipal Intl Airport",
    Address: "Petersburg,UNITED STATES",
  },
  {
    code: "PTF",
    name: "Malololailai Intl Airport",
    Address: "Malololailai,FIJI",
  },
  {
    code: "PTG",
    name: "Pietersburg Intl Airport",
    Address: "Pietersburg,SOUTH AFRICA",
  },
  {
    code: "PTI",
    name: "Port Douglas Intl Airport",
    Address: "Port Douglas,AUSTRALIA",
  },
  {
    code: "PTJ",
    name: "Portland Intl Airport",
    Address: "Portland,AUSTRALIA",
  },
  {
    code: "PTK",
    name: "Oakland Pontiac Intl Airport",
    Address: "Pontiac,UNITED STATES",
  },
  {
    code: "PTO",
    name: "Pato Branco Municipal",
    Address: "Pato Branco,BRAZIL",
  },
  {
    code: "PTP",
    name: "Le Raizet Intl Airport",
    Address: "Pointe A Pitre,GUADELOUPE",
  },
  {
    code: "PTS",
    name: "Atkinson Municipal Intl Airport",
    Address: "Pittsburg,UNITED STATES",
  },
  {
    code: "PTW",
    name: "Pottstown Limerick",
    Address: "Pottstown,UNITED STATES",
  },
  {
    code: "PTY",
    name: "Tocumen Intl Airport",
    Address: "Panama City,PANAMA",
  },
  {
    code: "PAC",
    name: "Paitilla Intl Airport",
    Address: "Panama City,PANAMA",
  },
  {
    code: "PUB",
    name: "Pueblo Intl Airport",
    Address: "Pueblo,UNITED STATES",
  },
  {
    code: "TAD",
    name: "Las Animas Intl Airport",
    Address: "Pueblo,UNITED STATES",
  },
  {
    code: "PUC",
    name: "Carbon Cty Municipal Intl Airport",
    Address: "Price,UNITED STATES",
  },
  { code: "PUF", name: "Uzein Intl Airport", Address: "Pau Fr,FRANCE" },
  {
    code: "PUG",
    name: "Port Augusta Intl Airport",
    Address: "Port Augusta,AUSTRALIA",
  },
  {
    code: "PUJ",
    name: "Punta Cana Intl Airport",
    Address: "Punta Cana,DOMINICAN REPUBLIC",
  },
  {
    code: "PUQ",
    name: "Presidente Ibanez Intl Airport",
    Address: "Punta Arenas,CHILE",
  },
  {
    code: "PUS",
    name: "Kimhae Intl Airport",
    Address: "Busan,KOREA, REPUBLIC OF",
  },
  {
    code: "PUW",
    name: "Pullman Moscow Intl Airport",
    Address: "Pullman,UNITED STATES",
  },
  {
    code: "PUX",
    name: "Puerto Varas Intl Airport",
    Address: "Puerto Varas,CHILE",
  },
  { code: "PUY", name: "Pula Intl Airport", Address: "Pula,CROATIA" },
  {
    code: "PVC",
    name: "Provincetown Municipal",
    Address: "Provincetown,UNITED STATES",
  },
  {
    code: "PVD",
    name: "T F Green St Intl Airport",
    Address: "Providence,UNITED STATES",
  },
  {
    code: "PVF",
    name: "Placerville Intl Airport",
    Address: "Placerville,UNITED STATES",
  },
  {
    code: "PVH",
    name: "Belmonte Intl Airport",
    Address: "Porto Velho,BRAZIL",
  },
  { code: "PVK", name: "Aktion Intl Airport", Address: "Preveza,GREECE" },
  {
    code: "PVR",
    name: "Ordaz Intl Airport",
    Address: "Puerto Vallarta,MEXICO",
  },
  {
    code: "PVS",
    name: "Provideniya Bay Intl Airport",
    Address: "Provideniya Bay Intl Airport,RUSSIA",
  },
  {
    code: "PVU",
    name: "Provo Intl Airport",
    Address: "Provo,UNITED STATES",
  },
  {
    code: "PVW",
    name: "Hale County Intl Airport",
    Address: "Plainview,UNITED STATES",
  },
  {
    code: "PVZ",
    name: "Casement Intl Airport",
    Address: "Painesville,UNITED STATES",
  },
  {
    code: "PWE",
    name: "Pevek Intl Airport",
    Address: "Pevek Intl Airport,RUSSIA",
  },
  {
    code: "PWM",
    name: "Portland Intl  Jetport",
    Address: "Portland,UNITED STATES",
  },
  {
    code: "PWQ",
    name: "Pavlodar Intl Airport",
    Address: "Pavlodar,KAZAKHSTAN",
  },
  {
    code: "PWT",
    name: "Bremerton Municipal",
    Address: "Bremerton,UNITED STATES",
  },
  {
    code: "PXM",
    name: "Puerto Escondido Municipal",
    Address: "Puerto Escondido,MEXICO",
  },
  {
    code: "PXO",
    name: "Porto Santo Intl Airport",
    Address: "Porto Santo,PORTUGAL",
  },
  {
    code: "PXU",
    name: "Pleiku Intl Airport",
    Address: "Pleiku,VIET NAM",
  },
  {
    code: "PYJ",
    name: "Polyarny Intl Airport",
    Address: "Polyarny Intl Airport,RUSSIA",
  },
  {
    code: "PYM",
    name: "Plymouth Intl Airport",
    Address: "Plymouth,UNITED STATES",
  },
  {
    code: "PYX",
    name: "Pattaya Intl Airport",
    Address: "Pattaya,THAILAND",
  },
  { code: "PYY", name: "Pai Intl Airport", Address: "Pai City,THAILAND" },
  {
    code: "PZB",
    name: "Pietermaritzburg Intl Airport",
    Address: "Pietermaritzburg,SOUTH AFRICA",
  },
  {
    code: "PZE",
    name: "Penzance Intl Airport",
    Address: "Penzance,UNITED KINGDOM",
  },
  {
    code: "PZI",
    name: "Pan Zhi Hua Bao An Ying Intl Airport",
    Address: "Panzhihua,CHINA",
  },
  {
    code: "PZO",
    name: "Puerto Ordaz Intl Airport",
    Address: "Puerto Ordaz,VENEZUELA",
  },
  {
    code: "PZU",
    name: "Port Sudan New Intl Airport",
    Address: "Port Sudan,SUDAN",
  },
  {
    code: "PZY",
    name: "Piestany Intl Airport",
    Address: "Piestany,SLOVAKIA",
  },
  {
    code: "ZYM",
    name: "Arnhem Rail Station",
    Address: "Arnhem,NETHERLANDS",
  },
  {
    code: "QBC",
    name: "Bella Colla Municipal",
    Address: "Bella Coola,CANADA",
  },
  {
    code: "QCE",
    name: "Copper Mountain Van Service",
    Address: "Copper Mountain,UNITED STATES",
  },
  {
    code: "QDH",
    name: "Ashford Intl  Rail Station",
    Address: "Ashford,UNITED KINGDOM",
  },
  {
    code: "XGM",
    name: "Grantham Rail Station",
    Address: "Leicester,UNITED KINGDOM",
  },
  { code: "QFQ", name: "Harbour Intl Airport", Address: "Maloy,NORWAY" },
  {
    code: "QHV",
    name: "Novo Hamburgo Intl Airport",
    Address: "Novo Hamburgo,BRAZIL",
  },
  { code: "QIG", name: "Iguatu Intl Airport", Address: "Iguatu,BRAZIL" },
  {
    code: "QKB",
    name: "Breckenridge Van Service",
    Address: "Breckenridge,UNITED STATES",
  },
  {
    code: "QKS",
    name: "Keystone Van Service",
    Address: "Keystone,UNITED STATES",
  },
  {
    code: "QLE",
    name: "Leeton Intl Airport",
    Address: "Leeton,AUSTRALIA",
  },
  {
    code: "QNT",
    name: "Niteroi Intl Airport",
    Address: "Niteroi,BRAZIL",
  },
  {
    code: "QQX",
    name: "Bath Rail Service",
    Address: "Bath,UNITED KINGDOM",
  },
  {
    code: "QQY",
    name: "York Rail Station",
    Address: "York,UNITED KINGDOM",
  },
  {
    code: "QRO",
    name: "Queretaro Intl Airport",
    Address: "Queretaro,MEXICO",
  },
  { code: "QRV", name: "Arras Intl Airport", Address: "Arras,FRANCE" },
  { code: "QSF", name: "Setif Intl Airport", Address: "Setif,ALGERIA" },
  {
    code: "QXB",
    name: "Aix en Provence Rail Station",
    Address: "Aix En Provence,FRANCE",
  },
  {
    code: "QYH",
    name: "Hengelo Railway Service",
    Address: "Hengelo,NETHERLANDS",
  },
  {
    code: "QYM",
    name: "Amersfoort Railway Service",
    Address: "Amersfoort,NETHERLANDS",
  },
  {
    code: "QYO",
    name: "Olsztyn Intl Airport",
    Address: "Olsztyn,POLAND",
  },
  {
    code: "QYX",
    name: "Uppsala Railway Service",
    Address: "Uppsala,SWEDEN",
  },
  {
    code: "RAB",
    name: "Lakunai Intl Airport",
    Address: "Rabaul,PAPUA NEW GUINEA",
  },
  {
    code: "RAC",
    name: "Horlick Intl Airport",
    Address: "Racine,UNITED STATES",
  },
  { code: "RAI", name: "Francisco Mendes", Address: "Praia,CAPE VERDE" },
  {
    code: "RAJ",
    name: "Rajkot Civil Intl Airport",
    Address: "Rajkot,INDIA",
  },
  {
    code: "RAK",
    name: "Menara Intl Airport",
    Address: "Marrakech,MOROCCO",
  },
  {
    code: "RAL",
    name: "Riverside Municipal",
    Address: "Riverside,UNITED STATES",
  },
  { code: "RAO", name: "Leite Lopes", Address: "Ribeirao Preto,BRAZIL" },
  {
    code: "RAP",
    name: "Rapid City Regional Intl Airport",
    Address: "Rapid City,UNITED STATES",
  },
  {
    code: "RAR",
    name: "Rarotonga Intl Airport",
    Address: "Rarotonga,COOK ISLANDS",
  },
  {
    code: "RAT",
    name: "Raduzhny Intl Airport",
    Address: "Raduzhny Intl Airport,RUSSIA",
  },
  {
    code: "RAY",
    name: "Rothesay Heliport",
    Address: "Rothesay,UNITED KINGDOM",
  },
  { code: "RBA", name: "Sale Intl Airport", Address: "Rabat,MOROCCO" },
  {
    code: "RBF",
    name: "Big Bear City Intl Airport",
    Address: "Big Bear City,UNITED STATES",
  },
  {
    code: "RBG",
    name: "Roseburg Municipal",
    Address: "Roseburg,UNITED STATES",
  },
  { code: "RBM", name: "Wallmuhle", Address: "Straubing,GERMANY" },
  {
    code: "RBR",
    name: "Pres Medici Intl Airport",
    Address: "Rio Branco,BRAZIL",
  },
  {
    code: "RBW",
    name: "Walterboro Municipal Intl Airport",
    Address: "Walterboro,UNITED STATES",
  },
  {
    code: "RCB",
    name: "Richards Bay Intl Airport",
    Address: "Richards Bay,SOUTH AFRICA",
  },
  {
    code: "RCE",
    name: "Roche Harbor Intl Airport",
    Address: "Roche Harbor,UNITED STATES",
  },
  {
    code: "RCO",
    name: "St Agnant Intl Airport",
    Address: "Rochefort,FRANCE",
  },
  {
    code: "RCU",
    name: "Rio Cuarto Intl Airport",
    Address: "Rio Cuarto,ARGENTINA",
  },
  {
    code: "RDD",
    name: "Redding Municipal Intl Airport",
    Address: "Redding,UNITED STATES",
  },
  {
    code: "RBL",
    name: "Flight Service Station",
    Address: "Redding,UNITED STATES",
  },
  {
    code: "RDG",
    name: "Reading Municipal",
    Address: "Reading,UNITED STATES",
  },
  {
    code: "RDM",
    name: "Roberts Field",
    Address: "Redmond,UNITED STATES",
  },
  {
    code: "RDN",
    name: "Lts Pulau Redang Intl Airport",
    Address: "Redang,MALAYSIA",
  },
  {
    code: "RDU",
    name: "Raleigh Durham Intl Airport",
    Address: "Raleigh,UNITED STATES",
  },
  { code: "RDZ", name: "Marcillac", Address: "Rodez,FRANCE" },
  { code: "REC", name: "Recife Intl Airport", Address: "Recife,BRAZIL" },
  {
    code: "REG",
    name: "Tito Menniti Intl Airport",
    Address: "Reggio Calabria,ITALY",
  },
  {
    code: "KEF",
    name: "Reykjavik Keflavik Intl Airport",
    Address: "Reykjavik,ICELAND",
  },
  {
    code: "RKV",
    name: "Reykjavik Domestic Intl Airport",
    Address: "Reykjavik,ICELAND",
  },
  {
    code: "REL",
    name: "Trelew Intl Airport",
    Address: "Trelew,ARGENTINA",
  },
  {
    code: "REN",
    name: "Orenburg Intl Airport",
    Address: "Orenburg,RUSSIA",
  },
  {
    code: "REP",
    name: "Siem Reap Intl Airport",
    Address: "Siem Reap,CAMBODIA",
  },
  {
    code: "RER",
    name: "Base Aerea Del Sur Intl Airport",
    Address: "Retalhuleu,GUATEMALA",
  },
  {
    code: "RES",
    name: "Resistencia Intl Airport",
    Address: "Resistencia,ARGENTINA",
  },
  { code: "REU", name: "Reus Intl Airport", Address: "Reus,SPAIN" },
  {
    code: "REX",
    name: "General Lucio Blanco Intl Airport",
    Address: "Reynosa,MEXICO",
  },
  {
    code: "ZRF",
    name: "Peoria Rockford Bus Terminal",
    Address: "Rockford,UNITED STATES",
  },
  {
    code: "ZRK",
    name: "Van Galder Bus Terminal",
    Address: "Rockford,UNITED STATES",
  },
  {
    code: "RFP",
    name: "Raiatea Intl Airport",
    Address: "Raiatea,FRENCH POLYNESIA",
  },
  {
    code: "RFR",
    name: "Rio Frio Intl Airport",
    Address: "Rio Frio,COSTA RICA",
  },
  {
    code: "RGA",
    name: "Rio Grande Intl Airport",
    Address: "Rio Grande,ARGENTINA",
  },
  {
    code: "RGI",
    name: "Rangiroa Intl Airport",
    Address: "Rangiroa Island,FRENCH POLYNESIA",
  },
  {
    code: "RGL",
    name: "Rio Gallegos Internacional Intl Airport",
    Address: "Rio Gallegos,ARGENTINA",
  },
  { code: "RGN", name: "Mingaladon", Address: "Yangon,MYANMAR" },
  {
    code: "RGR",
    name: "Ranger Municipal Intl Airport",
    Address: "Ranger,UNITED STATES",
  },
  {
    code: "RHE",
    name: "Champagne Intl Airport",
    Address: "Reims,FRANCE",
  },
  {
    code: "RHI",
    name: "Oneida County",
    Address: "Rhinelander,UNITED STATES",
  },
  {
    code: "RHN",
    name: "Rosh Pina Intl Airport",
    Address: "Rosh Pina,NAMIBIA",
  },
  {
    code: "RHO",
    name: "Diagoras Intl Airport",
    Address: "Rhodes,GREECE",
  },
  {
    code: "RIA",
    name: "Base Aerea Intl Airport",
    Address: "Santa Maria,BRAZIL",
  },
  {
    code: "RIC",
    name: "Richmond Intl Airport",
    Address: "Richmond,UNITED STATES",
  },
  {
    code: "RIF",
    name: "Reynolds Municipal Intl Airport",
    Address: "Richfield,UNITED STATES",
  },
  {
    code: "RIG",
    name: "Rio Grande Intl Airport",
    Address: "Rio Grande,BRAZIL",
  },
  {
    code: "RIL",
    name: "Garfield Cty Intl Airport",
    Address: "Rifle,UNITED STATES",
  },
  {
    code: "GIG",
    name: "Rio Internacional",
    Address: "Rio De Janeiro,BRAZIL",
  },
  {
    code: "SDU",
    name: "Santos Dumont Intl Airport",
    Address: "Rio De Janeiro,BRAZIL",
  },
  {
    code: "RIW",
    name: "Riverton Municipal",
    Address: "Riverton,UNITED STATES",
  },
  { code: "RIX", name: "Riga Intl Airport", Address: "Riga,LATVIA" },
  {
    code: "RIY",
    name: "Riyan Mukalla Intl Airport",
    Address: "Riyan,YEMEN",
  },
  {
    code: "RJA",
    name: "Rajahmundry Intl Airport",
    Address: "Rajahmundry,INDIA",
  },
  { code: "RJK", name: "Rijeka Intl Airport", Address: "Rijeka,CROATIA" },
  {
    code: "RJL",
    name: "Agoncillo Intl Airport",
    Address: "Logrono,SPAIN",
  },
  {
    code: "RKC",
    name: "Yreka Intl Airport",
    Address: "Yreka,UNITED STATES",
  },
  {
    code: "RKD",
    name: "Rockland Intl Airport",
    Address: "Rockland,UNITED STATES",
  },
  {
    code: "RKE",
    name: "Roskilde Intl Airport",
    Address: "Roskilde,DENMARK",
  },
  {
    code: "RKH",
    name: "Rock Hill York County Intl Airport",
    Address: "Rock Hill,UNITED STATES",
  },
  {
    code: "RKP",
    name: "Aransas County Intl Airport",
    Address: "Rockport,UNITED STATES",
  },
  {
    code: "RKS",
    name: "Rock Springs Municipal",
    Address: "Rock Springs,UNITED STATES",
  },
  {
    code: "RKT",
    name: "Ras Al Khaimah Intl Airport",
    Address: "Ras Al Khaimah,UNITED ARAB EMIRATES",
  },
  {
    code: "RLA",
    name: "National Intl Airport",
    Address: "Rolla,UNITED STATES",
  },
  {
    code: "RLD",
    name: "Richland Intl Airport",
    Address: "Richland,UNITED STATES",
  },
  {
    code: "RLG",
    name: "Laage Intl Airport",
    Address: "Rostock Laage,GERMANY",
  },
  { code: "RMA", name: "Roma Intl Airport", Address: "Roma,AUSTRALIA" },
  {
    code: "RME",
    name: "Griffiss Air Force Base",
    Address: "Rome,UNITED STATES",
  },
  {
    code: "RMF",
    name: "Marsa Alam Intl Airport",
    Address: "Marsa Alam,EGYPT",
  },
  {
    code: "RMG",
    name: "Richard B Russell Intl Airport",
    Address: "Rome,UNITED STATES",
  },
  { code: "RMI", name: "Miramare Intl Airport", Address: "Rimini,ITALY" },
  {
    code: "RMK",
    name: "Renmark Intl Airport",
    Address: "Renmark,AUSTRALIA",
  },
  {
    code: "RMS",
    name: "Ramsteim Intl Airport",
    Address: "Ramstein,GERMANY",
  },
  {
    code: "XXY",
    name: "Ronneby Railway Service",
    Address: "Ronneby,SWEDEN",
  },
  {
    code: "XYO",
    name: "Karlshamn Rail Station",
    Address: "Ronneby,SWEDEN",
  },
  {
    code: "RNB",
    name: "Kallinge Intl Airport",
    Address: "Ronneby,SWEDEN",
  },
  {
    code: "RNE",
    name: "Renaison Intl Airport",
    Address: "Roanne,FRANCE",
  },
  {
    code: "RNH",
    name: "New Richmond Municipal",
    Address: "New Richmond,UNITED STATES",
  },
  {
    code: "RNN",
    name: "Bornholm Intl Airport",
    Address: "Bornholm,DENMARK",
  },
  {
    code: "RNO",
    name: "Reno Tahoe Intl Airport",
    Address: "Reno,UNITED STATES",
  },
  {
    code: "RNS",
    name: "Saint Jacques Intl Airport",
    Address: "Rennes,FRANCE",
  },
  { code: "ZFJ", name: "Gare de Rennes", Address: "Rennes,FRANCE" },
  {
    code: "RNT",
    name: "Renton Intl Airport",
    Address: "Renton,UNITED STATES",
  },
  {
    code: "ROA",
    name: "Roanoke Regional Intl Airport",
    Address: "Roanoke,UNITED STATES",
  },
  {
    code: "ROC",
    name: "Monroe Cty Intl Airport  New York",
    Address: "Rochester,UNITED STATES",
  },
  {
    code: "ROG",
    name: "Rogers Intl Airport",
    Address: "Rogers,UNITED STATES",
  },
  {
    code: "ROI",
    name: "Roi Et Intl Airport",
    Address: "Roi Et,THAILAND",
  },
  {
    code: "ROK",
    name: "Rockhampton Intl Airport",
    Address: "Rockhampton,AUSTRALIA",
  },
  { code: "CIA", name: "Ciampino Intl Airport", Address: "Rome,ITALY" },
  { code: "FCO", name: "Fiumicino Intl Airport", Address: "Rome,ITALY" },
  {
    code: "RON",
    name: "Rondon Intl Airport",
    Address: "Rondon,COLOMBIA",
  },
  {
    code: "ROO",
    name: "Rondonopolis Intl Airport",
    Address: "Rondonopolis,BRAZIL",
  },
  {
    code: "ROP",
    name: "Rota Intl Airport",
    Address: "Rota,NORTHERN MARIANA ISLANDS",
  },
  { code: "ROR", name: "Airai Intl Airport", Address: "Koror,PALAU" },
  {
    code: "ROS",
    name: "Fisherton Intl Airport",
    Address: "Rosario,ARGENTINA",
  },
  {
    code: "ROT",
    name: "Rotorua Intl Airport",
    Address: "Rotorua,NEW ZEALAND",
  },
  { code: "ROV", name: "Rostov Intl Airport", Address: "Rostov,RUSSIA" },
  {
    code: "ROW",
    name: "Industrial Aircenter",
    Address: "Roswell,UNITED STATES",
  },
  {
    code: "RPN",
    name: "Rosh Pina Intl Airport",
    Address: "Rosh Pina,ISRAEL",
  },
  { code: "RPR", name: "Raipur Intl Airport", Address: "Raipur,INDIA" },
  {
    code: "RRG",
    name: "Rodrigues Island Intl Airport",
    Address: "Rodrigues Island,MAURITIUS",
  },
  {
    code: "RRO",
    name: "Sorrento Intl Airport",
    Address: "Sorrento,ITALY",
  },
  { code: "RRS", name: "Roros Intl Airport", Address: "Roros,NORWAY" },
  {
    code: "RSA",
    name: "Santa Rosa Intl Airport",
    Address: "Santa Rosa,ARGENTINA",
  },
  {
    code: "RSD",
    name: "S Eleuthera Intl Airport",
    Address: "Rock Sound,BAHAMAS",
  },
  {
    code: "RSH",
    name: "Russian Seaplane Base",
    Address: "Russian Mission,UNITED STATES",
  },
  {
    code: "RSN",
    name: "Ruston Regional Intl Airport",
    Address: "Ruston,UNITED STATES",
  },
  {
    code: "RST",
    name: "Rochester Municipal",
    Address: "Rochester,UNITED STATES",
  },
  {
    code: "RSU",
    name: "Yeosu Intl Airport",
    Address: "Yeosu,KOREA, REPUBLIC OF",
  },
  {
    code: "RTB",
    name: "Roatan Intl Airport",
    Address: "Roatan,HONDURAS",
  },
  {
    code: "QRH",
    name: "Rotterdam Central Rail Station",
    Address: "Rotterdam,NETHERLANDS",
  },
  {
    code: "RTM",
    name: "Rotterdam Intl Airport",
    Address: "Rotterdam,NETHERLANDS",
  },
  { code: "RTN", name: "Crews Fld", Address: "Raton,UNITED STATES" },
  {
    code: "RTS",
    name: "Rottnest Island Intl Airport",
    Address: "Rottnest,AUSTRALIA",
  },
  {
    code: "RTW",
    name: "Saratov Intl Airport",
    Address: "Saratov,RUSSIA",
  },
  { code: "OSW", name: "Orsk Intl Airport", Address: "Orsk,RUSSIA" },
  {
    code: "RUH",
    name: "King Khaled Intl Airport",
    Address: "Riyadh,SAUDI ARABIA",
  },
  {
    code: "RUI",
    name: "Ruidoso Municipal Intl Airport",
    Address: "Ruidoso,UNITED STATES",
  },
  {
    code: "RUN",
    name: "Gillot Intl Airport",
    Address: "Reunion Island,REUNION",
  },
  {
    code: "RUT",
    name: "Rutland Intl Airport",
    Address: "Rutland,UNITED STATES",
  },
  { code: "RUY", name: "Copan Intl Airport", Address: "Copan,HONDURAS" },
  {
    code: "RVK",
    name: "Ryumsjoen Intl Airport",
    Address: "Roervik,NORWAY",
  },
  {
    code: "RVN",
    name: "Rovaniemi Intl Airport",
    Address: "Rovaniemi,FINLAND",
  },
  {
    code: "RVR",
    name: "Green River Intl Airport",
    Address: "Green River,UNITED STATES",
  },
  {
    code: "RVT",
    name: "Ravensthorpe Intl Airport",
    Address: "Ravensthorpe,AUSTRALIA",
  },
  {
    code: "RWI",
    name: "Wilson Intl Airport",
    Address: "Rocky Mount,UNITED STATES",
  },
  {
    code: "RWL",
    name: "Rawlins Municipal",
    Address: "Rawlins,UNITED STATES",
  },
  { code: "RWN", name: "Rovno Intl Airport", Address: "Rovno,UKRAINE" },
  { code: "RWS", name: "Sumare Intl Airport", Address: "Sumare,BRAZIL" },
  {
    code: "RXS",
    name: "Roxas City Intl Airport",
    Address: "Roxas City,PHILIPPINES",
  },
  {
    code: "RYB",
    name: "Rybinsk Staroselye",
    Address: "Rybinsk Staroselye,RUSSIA",
  },
  {
    code: "RZE",
    name: "Jasionka Intl Airport",
    Address: "Rzeszow,POLAND",
  },
  { code: "RZN", name: "Ryazan Intl Airport", Address: "Ryazan,RUSSIA" },
  {
    code: "RZZ",
    name: "Halifax Cty Intl Airport",
    Address: "Roanoke Rapids,UNITED STATES",
  },
  {
    code: "SAB",
    name: "Juancho Yraus Quinl",
    Address: "Saba Island,NETHERLANDS ANTILLES",
  },
  {
    code: "SMF",
    name: "Sacramento Intl ",
    Address: "Sacramento,UNITED STATES",
  },
  {
    code: "MHR",
    name: "Mather Air Force Base",
    Address: "Sacramento,UNITED STATES",
  },
  {
    code: "SAC",
    name: "Sacramento Executive Intl Airport",
    Address: "Sacramento,UNITED STATES",
  },
  {
    code: "SAF",
    name: "Santa Fe Municipal",
    Address: "Santa Fe,UNITED STATES",
  },
  { code: "SAH", name: "Sanaa Intl Airport", Address: "Sanaa,YEMEN" },
  {
    code: "SAK",
    name: "Saudarkrokur Intl Airport",
    Address: "Saudarkrokur,ICELAND",
  },
  {
    code: "SAL",
    name: "Comalapa Intl Airport",
    Address: "San Salvador,EL SALVADOR",
  },
  {
    code: "SAM",
    name: "Salamo Intl Airport",
    Address: "Salamo,PAPUA NEW GUINEA",
  },
  {
    code: "SDM",
    name: "Brown Field Municipal",
    Address: "San Diego,UNITED STATES",
  },
  {
    code: "MYF",
    name: "Montgomery Field",
    Address: "San Diego,UNITED STATES",
  },
  {
    code: "SEE",
    name: "Gillespie Field",
    Address: "San Diego,UNITED STATES",
  },
  {
    code: "SAN",
    name: "Lindbergh Intl Airport",
    Address: "San Diego,UNITED STATES",
  },
  {
    code: "GRU",
    name: "Guarulhos Intl Airport",
    Address: "Sao Paulo,BRAZIL",
  },
  {
    code: "CGH",
    name: "Congonhas Intl Airport",
    Address: "Sao Paulo,BRAZIL",
  },
  {
    code: "VCP",
    name: "Viracopos Intl Airport",
    Address: "Sao Paulo,BRAZIL",
  },
  {
    code: "SAP",
    name: "Ramon Villeda Morales Intl Airport",
    Address: "San Pedro Sula,HONDURAS",
  },
  {
    code: "SAR",
    name: "Sparta Community Intl Airport",
    Address: "Sparta,UNITED STATES",
  },
  {
    code: "SSF",
    name: "Stinson Municipal Intl Airport",
    Address: "San Antonio,UNITED STATES",
  },
  {
    code: "SAT",
    name: "San Antonio Intl ",
    Address: "San Antonio,UNITED STATES",
  },
  {
    code: "SAV",
    name: "Savannah Intl Airport",
    Address: "Savannah,UNITED STATES",
  },
  { code: "SAY", name: "Siena Intl Airport", Address: "Siena,ITALY" },
  {
    code: "SAZ",
    name: "Sasstown Intl Airport",
    Address: "Sasstown,LIBERIA",
  },
  {
    code: "SBA",
    name: "Santa Barbara Intl Airport",
    Address: "Santa Barbara,UNITED STATES",
  },
  {
    code: "SBH",
    name: "St Jean Intl Airport",
    Address: "St Barthelemy,GUADELOUPE",
  },
  {
    code: "SBK",
    name: "Tremuson Armor Intl Airport",
    Address: "St Brieuc,FRANCE",
  },
  {
    code: "SBM",
    name: "Sheboygan Intl Airport",
    Address: "Sheboygan,UNITED STATES",
  },
  {
    code: "SBN",
    name: "Michiana Regional Intl Airport",
    Address: "South Bend,UNITED STATES",
  },
  {
    code: "SBS",
    name: "Steamboat Intl Airport",
    Address: "Steamboat Springs,UNITED STATES",
  },
  {
    code: "SBD",
    name: "San Bernardino Intl Airport",
    Address: "San Bernardino,UNITED STATES",
  },
  {
    code: "SBU",
    name: "Springbok Intl Airport",
    Address: "Springbok,SOUTH AFRICA",
  },
  { code: "SBW", name: "Sibu Intl Airport", Address: "Sibu,MALAYSIA" },
  {
    code: "SBY",
    name: "Wicomico Regional Intl Airport",
    Address: "Salisbury Ocean City,UNITED STATES",
  },
  { code: "SBZ", name: "Sibiu Intl Airport", Address: "Sibiu,ROMANIA" },
  {
    code: "SCC",
    name: "Prudhoe Bay Deadhorse Intl Airport",
    Address: "Prudhoe Bay Deadhorse,UNITED STATES",
  },
  {
    code: "SCE",
    name: "University Park Intl Airport",
    Address: "State College,UNITED STATES",
  },
  {
    code: "SCF",
    name: "Scottsdale Municipal Intl Airport",
    Address: "Scottsdale,UNITED STATES",
  },
  {
    code: "SCH",
    name: "Schenectady County Intl Airport",
    Address: "Schenectady,UNITED STATES",
  },
  {
    code: "SCI",
    name: "Aeropuerto Santo Domingo",
    Address: "San Crystobal,VENEZUELA",
  },
  {
    code: "SCK",
    name: "Stockton Metro Intl Airport",
    Address: "Stockton,UNITED STATES",
  },
  { code: "ULC", name: "Los Cerrillos", Address: "Santiago,CHILE" },
  {
    code: "SCL",
    name: "Arturo Merino Benitez",
    Address: "Santiago,CHILE",
  },
  {
    code: "QFZ",
    name: "Saarbruecken Rail Station",
    Address: "Saarbruecken,GERMANY",
  },
  {
    code: "SCN",
    name: "Ensheim Intl Airport",
    Address: "Saarbruecken,GERMANY",
  },
  {
    code: "SCO",
    name: "Aktau Intl Airport",
    Address: "Aktau,KAZAKHSTAN",
  },
  {
    code: "SCQ",
    name: "Santiago Intl Airport",
    Address: "Santiago De Compostela,SPAIN",
  },
  {
    code: "SCU",
    name: "Antonio Maceo Intl Airport",
    Address: "Santiago,CUBA",
  },
  {
    code: "SCV",
    name: "Salcea Intl Airport",
    Address: "Suceava,ROMANIA",
  },
  {
    code: "SCW",
    name: "Syktyvkar Intl Airport",
    Address: "Syktyvkar,RUSSIA",
  },
  {
    code: "SCX",
    name: "Salina Cruz Intl Airport",
    Address: "Salina Cruz,MEXICO",
  },
  {
    code: "SCY",
    name: "San Cristobal Intl Airport",
    Address: "San Cristobal,ECUADOR",
  },
  {
    code: "SDB",
    name: "Langebaanweg Intl Airport",
    Address: "Saldanha Bay,SOUTH AFRICA",
  },
  {
    code: "SDE",
    name: "Santiago Des Estero Intl Airport",
    Address: "Santiago Del Estero,ARGENTINA",
  },
  {
    code: "LOU",
    name: "Bowman Field Intl Airport",
    Address: "Louisville,UNITED STATES",
  },
  {
    code: "SDF",
    name: "Louisville Intl Airport",
    Address: "Louisville,UNITED STATES",
  },
  { code: "SDJ", name: "Sendai Intl Airport", Address: "Sendai,JAPAN" },
  {
    code: "SDK",
    name: "Sandakan Intl Airport",
    Address: "Sandakan,MALAYSIA",
  },
  {
    code: "XXZ",
    name: "Sundsvall Rail Service",
    Address: "Sundsvall,SWEDEN",
  },
  {
    code: "SDL",
    name: "Sundsvall Intl Airport",
    Address: "Sundsvall,SWEDEN",
  },
  { code: "SDN", name: "Anda Intl Airport", Address: "Sandane,NORWAY" },
  {
    code: "SDP",
    name: "Sand Point Intl Airport",
    Address: "Sand Point,UNITED STATES",
  },
  {
    code: "SDQ",
    name: "Las Americas Intl Airport",
    Address: "Santo Domingo,DOMINICAN REPUBLIC",
  },
  {
    code: "HEX",
    name: "Herrera Intl Airport",
    Address: "Santo Domingo,DOMINICAN REPUBLIC",
  },
  {
    code: "SDR",
    name: "Santander Intl Airport",
    Address: "Santander,SPAIN",
  },
  {
    code: "SDX",
    name: "Sedona Intl Airport",
    Address: "Sedona,UNITED STATES",
  },
  {
    code: "SDY",
    name: "Sindey Richland Municipal Intl Airport",
    Address: "Sidney,UNITED STATES",
  },
  {
    code: "SCS",
    name: "Scatsta Intl Airport",
    Address: "Shetland Islands Area,UNITED KINGDOM",
  },
  {
    code: "SEA",
    name: "Seattle Tacoma Intl Airport",
    Address: "Seattle,UNITED STATES",
  },
  {
    code: "BFI",
    name: "Seattle Boeing Field",
    Address: "Seattle,UNITED STATES",
  },
  {
    code: "KEH",
    name: "Kenmore Air Harbor",
    Address: "Seattle,UNITED STATES",
  },
  {
    code: "LKE",
    name: "Lake Union Seaplane Base",
    Address: "Seattle,UNITED STATES",
  },
  {
    code: "SEF",
    name: "Air Terminal Intl Airport",
    Address: "Sebring,UNITED STATES",
  },
  {
    code: "ICN",
    name: "Incheon Intl Airport",
    Address: "Seoul,KOREA, REPUBLIC OF",
  },
  {
    code: "GMP",
    name: "Gimpo Intl Airport",
    Address: "Seoul,KOREA, REPUBLIC OF",
  },
  {
    code: "SEN",
    name: "Southend Municipal Intl Airport",
    Address: "Southend,UNITED KINGDOM",
  },
  {
    code: "SEP",
    name: "Clark Field",
    Address: "Stephenville,UNITED STATES",
  },
  { code: "SEW", name: "Siwa Intl Airport", Address: "Siwa,EGYPT" },
  {
    code: "SEY",
    name: "Selibaby Intl Airport",
    Address: "Selibaby,MAURITANIA",
  },
  {
    code: "SEZ",
    name: "Seychelles Intl Airport",
    Address: "Mahe Island,SEYCHELLES",
  },
  { code: "SFA", name: "El Maou Intl Airport", Address: "Sfax,TUNISIA" },
  {
    code: "SFB",
    name: "Orlando Sanford Intl Airport",
    Address: "Sanford,UNITED STATES",
  },
  {
    code: "SFD",
    name: "Las Flecheras",
    Address: "San Fernando De Apure,VENEZUELA",
  },
  {
    code: "SFE",
    name: "San Fernando Intl Airport",
    Address: "San Fernando,PHILIPPINES",
  },
  {
    code: "SFG",
    name: "Esperance Intl Airport",
    Address: "St Martin,NETHERLANDS ANTILLES",
  },
  {
    code: "SFH",
    name: "San Felipe Intl Airport",
    Address: "San Felipe,MEXICO",
  },
  {
    code: "SFJ",
    name: "Sondre Stromfjord Intl Airport",
    Address: "Kangerlussuaq,GREENLAND",
  },
  {
    code: "SFL",
    name: "Sao Filipe Intl Airport",
    Address: "Sao Filipe,CAPE VERDE",
  },
  {
    code: "SFM",
    name: "Sanford Regional Intl Airport",
    Address: "Sanford,UNITED STATES",
  },
  {
    code: "SFN",
    name: "Santa Fe Intl Airport",
    Address: "Santa Fe,ARGENTINA",
  },
  {
    code: "SFO",
    name: "San Francisco Intl Airport",
    Address: "San Francisco,UNITED STATES",
  },
  {
    code: "SFQ",
    name: "Sanli Urfa Intl Airport",
    Address: "Sanli Urfa,TURKEY",
  },
  {
    code: "GNY",
    name: "Guney Anadolu Intl Airport",
    Address: "Sanli Urfa,TURKEY",
  },
  {
    code: "SFS",
    name: "Subic Bay Intl Airport",
    Address: "Subic Bay,PHILIPPINES",
  },
  {
    code: "SFT",
    name: "Skelleftea Intl Airport",
    Address: "Skelleftea,SWEDEN",
  },
  {
    code: "SFX",
    name: "San Felix Intl Airport",
    Address: "San Felix,VENEZUELA",
  },
  {
    code: "CEF",
    name: "Westover Metro",
    Address: "Springfield,UNITED STATES",
  },
  {
    code: "SFZ",
    name: "Smithfield Intl Airport",
    Address: "Smithfield,UNITED STATES",
  },
  { code: "SGC", name: "Surgut Intl Airport", Address: "Surgut,RUSSIA" },
  {
    code: "SGD",
    name: "Sonderborg Intl Airport",
    Address: "Sonderborg,DENMARK",
  },
  {
    code: "SGE",
    name: "Siegerland Intl Airport",
    Address: "Siegen,GERMANY",
  },
  {
    code: "SGF",
    name: "Springfield Branson Regional Intl Airport",
    Address: "Springfield,UNITED STATES",
  },
  {
    code: "SGN",
    name: "Tan Son Nhut Intl Airport",
    Address: "Ho Chi Minh City,VIET NAM",
  },
  {
    code: "SGO",
    name: "St George Intl Airport",
    Address: "St George,AUSTRALIA",
  },
  {
    code: "SGR",
    name: "Hull Field Intl Airport",
    Address: "Sugar Land,UNITED STATES",
  },
  {
    code: "SGT",
    name: "Stuttgart Municipal",
    Address: "Stuttgart,UNITED STATES",
  },
  {
    code: "SGU",
    name: "Saint George Municipal Intl Airport",
    Address: "St George,UNITED STATES",
  },
  {
    code: "SGY",
    name: "Skagway Municipal Intl Airport",
    Address: "Skagway,UNITED STATES",
  },
  {
    code: "SHA",
    name: "Hongqiao Intl Airport",
    Address: "Shanghai,CHINA",
  },
  { code: "PVG", name: "Pudong Intl Airport", Address: "Shanghai,CHINA" },
  {
    code: "SHB",
    name: "Nakashibetsu Intl Airport",
    Address: "Nakashibetsu,JAPAN",
  },
  {
    code: "SHD",
    name: "Shenandoah Valley Intl Airport",
    Address: "Staunton,UNITED STATES",
  },
  {
    code: "SHE",
    name: "Shenyang Intl Airport",
    Address: "Shenyang,CHINA",
  },
  {
    code: "SHO",
    name: "Seolak Intl Airport",
    Address: "Sokcho,KOREA, REPUBLIC OF",
  },
  {
    code: "SHP",
    name: "Qinhuangdao Intl Airport",
    Address: "Qinhuangdao,CHINA",
  },
  {
    code: "SHR",
    name: "Sheridan Cty Intl Airport",
    Address: "Sheridan,UNITED STATES",
  },
  { code: "SHS", name: "Shashi Intl Airport", Address: "Shashi,CHINA" },
  { code: "SHT", name: "Shepparton", Address: "Shepparton,AUSTRALIA" },
  {
    code: "SHV",
    name: "Shreveport Regional Intl Airport",
    Address: "Shreveport,UNITED STATES",
  },
  {
    code: "BAD",
    name: "Barksdale Air Force Base",
    Address: "Shreveport,UNITED STATES",
  },
  {
    code: "SHW",
    name: "Sharurah Intl Airport",
    Address: "Sharurah,SAUDI ARABIA",
  },
  {
    code: "SHY",
    name: "Shinyanga Intl Airport",
    Address: "Shinyanga,TANZANIA",
  },
  { code: "XIY", name: "Xianyang Intl Airport", Address: "Xi An,CHINA" },
  { code: "SIA", name: "Xiguan Intl Airport", Address: "Xi An,CHINA" },
  { code: "NOP", name: "Sinop Intl Airport", Address: "Sinop,TURKEY" },
  {
    code: "SID",
    name: "Amilcar Cabral Intl Airport",
    Address: "Sal Island,CAPE VERDE",
  },
  {
    code: "SIG",
    name: "SIG Isla Grande Intl Airport",
    Address: "Isla Grande,UNITED STATES",
  },
  {
    code: "SIN",
    name: "Changi Intl Airport",
    Address: "Singapore,SINGAPORE",
  },
  {
    code: "SIP",
    name: "Simferopol Intl Airport",
    Address: "Simferopol,UKRAINE",
  },
  { code: "SIR", name: "Sion Intl Airport", Address: "Sion,SWITZERLAND" },
  {
    code: "SIT",
    name: "Sitka Intl Airport",
    Address: "Sitka,UNITED STATES",
  },
  {
    code: "SIX",
    name: "Singleton Intl Airport",
    Address: "Singleton,AUSTRALIA",
  },
  {
    code: "SJC",
    name: "San Jose Intl Airport",
    Address: "San Jose,UNITED STATES",
  },
  {
    code: "ZSM",
    name: "Santa Clara Bus Service",
    Address: "San Jose,UNITED STATES",
  },
  {
    code: "ZJO",
    name: "San Jose Bus Service",
    Address: "San Jose,UNITED STATES",
  },
  {
    code: "RHV",
    name: "Reid Hillview Of Santa Clara County",
    Address: "San Jose,UNITED STATES",
  },
  {
    code: "SJD",
    name: "Los Cabos Intl Airport",
    Address: "San Jose Cabo,MEXICO",
  },
  {
    code: "SJF",
    name: "St John Island Intl Airport",
    Address: "St John,UNITED STATES",
  },
  {
    code: "SJJ",
    name: "Butmir Intl Airport",
    Address: "Sarajevo,BOSNIA AND HERZEGOVINA",
  },
  {
    code: "SJK",
    name: "Sao Jose Dos Campos Intl Airport",
    Address: "Sao Jose Dos Campos,BRAZIL",
  },
  {
    code: "SJO",
    name: "Juan Santa Maria Intl ",
    Address: "San Jose,COSTA RICA",
  },
  {
    code: "SYQ",
    name: "Tobias Bolanos Intl ",
    Address: "San Jose,COSTA RICA",
  },
  {
    code: "SJP",
    name: "Sao Jose Do Rio Preto",
    Address: "Sao Jose Do Rio Preto,BRAZIL",
  },
  {
    code: "SJT",
    name: "Mathis Field",
    Address: "San Angelo,UNITED STATES",
  },
  {
    code: "SJU",
    name: "Luiz Munoz Marin Intl ",
    Address: "San Juan,UNITED STATES",
  },
  {
    code: "SJW",
    name: "Daguocun Intl Airport",
    Address: "Shijiazhuang,CHINA",
  },
  {
    code: "SJY",
    name: "Ilmajoki Intl Airport",
    Address: "Seinajoki,FINLAND",
  },
  {
    code: "SJZ",
    name: "Sao Jorge Intl Airport",
    Address: "Sao Jorge Island,PORTUGAL",
  },
  {
    code: "SKB",
    name: "Robert L Bradshaw Intl Airport",
    Address: "St Kitts,SAINT KITTS AND NEVIS",
  },
  {
    code: "SKD",
    name: "Samarkand Intl Airport",
    Address: "Samarkand,UZBEKISTAN",
  },
  { code: "XKK", name: "Larvik Rail Station", Address: "Skien,NORWAY" },
  {
    code: "XKP",
    name: "Porsgrunn Rail Station",
    Address: "Skien,NORWAY",
  },
  { code: "SKE", name: "Skien Intl Airport", Address: "Skien,NORWAY" },
  {
    code: "SKG",
    name: "Makedonia Intl Airport",
    Address: "Thessaloniki,GREECE",
  },
  {
    code: "SKL",
    name: "Broadford Intl Airport",
    Address: "Isle Of Skye Hebrides Islands,UNITED KINGDOM",
  },
  {
    code: "SKN",
    name: "Skagen Intl Airport",
    Address: "Stokmarknes,NORWAY",
  },
  {
    code: "SKP",
    name: "Skopje Intl Airport",
    Address: "Skopje,MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
  },
  {
    code: "SKS",
    name: "Skrydstrup Intl Airport",
    Address: "Skrydstrup,DENMARK",
  },
  {
    code: "SKT",
    name: "Sialkot Intl Airport",
    Address: "Sialkot,PAKISTAN",
  },
  { code: "SKU", name: "Skiros Intl Airport", Address: "Skiros,GREECE" },
  {
    code: "SKX",
    name: "Saransk Intl Airport",
    Address: "Saransk,RUSSIA",
  },
  {
    code: "SKY",
    name: "Griffing Sandusky Intl Airport",
    Address: "Sandusky,UNITED STATES",
  },
  {
    code: "SLA",
    name: "General Belgrano Intl Airport",
    Address: "Salta,ARGENTINA",
  },
  {
    code: "SLC",
    name: "Salt Lake City Intl Airport",
    Address: "Salt Lake City,UNITED STATES",
  },
  { code: "SLD", name: "Sliac Intl Airport", Address: "Sliac,SLOVAKIA" },
  { code: "SLE", name: "McNary Field", Address: "Salem,UNITED STATES" },
  {
    code: "SLK",
    name: "Adirondack Intl Airport",
    Address: "Saranac Lake,UNITED STATES",
  },
  { code: "SLL", name: "Salalah Intl Airport", Address: "Salalah,OMAN" },
  {
    code: "SLM",
    name: "Matacan Intl Airport",
    Address: "Salamanca,SPAIN",
  },
  {
    code: "SLN",
    name: "Salina Municipal",
    Address: "Salina,UNITED STATES",
  },
  {
    code: "SLP",
    name: "San Luis Potosi Municipal Intl Airport",
    Address: "San Luis Potosi,MEXICO",
  },
  {
    code: "SLT",
    name: "Salida Intl Airport",
    Address: "Salida,UNITED STATES",
  },
  {
    code: "SLU",
    name: "Vigie Field Intl Airport",
    Address: "St Lucia,SAINT LUCIA",
  },
  { code: "SLV", name: "Simla Intl Airport", Address: "Simla,INDIA" },
  {
    code: "SLW",
    name: "Saltillo Intl Airport",
    Address: "Saltillo,MEXICO",
  },
  {
    code: "SLY",
    name: "Salehard Intl Airport",
    Address: "Salekhard,RUSSIA",
  },
  { code: "SLZ", name: "Mal Cunha Machado", Address: "Sao Luiz,BRAZIL" },
  {
    code: "SMA",
    name: "Vilo Do Porto Intl Airport",
    Address: "Santa Maria,PORTUGAL",
  },
  {
    code: "SME",
    name: "Pulaski County Intl Airport",
    Address: "Somerset,UNITED STATES",
  },
  { code: "SMI", name: "Samos Intl Airport", Address: "Samos,GREECE" },
  {
    code: "SML",
    name: "Stella Maris Intl Airport",
    Address: "Stella Maris,BAHAMAS",
  },
  {
    code: "SMP",
    name: "Stockholm Intl Airport",
    Address: "Stockholm,PAPUA NEW GUINEA",
  },
  { code: "SMR", name: "Simon Bolivar", Address: "Santa Marta,COLOMBIA" },
  {
    code: "SMS",
    name: "St Marie Intl Airport",
    Address: "Saint Marie,MADAGASCAR",
  },
  {
    code: "SMV",
    name: "Samedan Intl Airport",
    Address: "St Moritz,SWITZERLAND",
  },
  {
    code: "SMX",
    name: "Santa Maria Public Intl Airport",
    Address: "Santa Maria,UNITED STATES",
  },
  {
    code: "LPC",
    name: "Lompoc Intl Airport",
    Address: "Santa Maria,UNITED STATES",
  },
  { code: "NYI", name: "Sunyani", Address: "Sunyani,GHANA" },
  {
    code: "SNA",
    name: "John Wayne Intl Airport",
    Address: "Santa Ana,UNITED STATES",
  },
  {
    code: "SNE",
    name: "Preguica Intl Airport",
    Address: "Sao Nicolau,CAPE VERDE",
  },
  {
    code: "SNF",
    name: "San Felipe Intl Airport",
    Address: "San Felipe,VENEZUELA",
  },
  { code: "SNH", name: "Stanthorpe", Address: "Stanthorpe,AUSTRALIA" },
  {
    code: "SNI",
    name: "R E Murray Intl Airport",
    Address: "Sinoe,LIBERIA",
  },
  {
    code: "SNL",
    name: "Shawnee Intl Airport",
    Address: "Shawnee,UNITED STATES",
  },
  {
    code: "SNN",
    name: "Shannon Intl Airport",
    Address: "Shannon,IRELAND",
  },
  {
    code: "SNO",
    name: "Sakon Nakhon Intl Airport",
    Address: "Sakon Nakhon,THAILAND",
  },
  {
    code: "SNQ",
    name: "San Quintin Intl Airport",
    Address: "San Quintin,MEXICO",
  },
  {
    code: "SNR",
    name: "Montoir Intl Airport",
    Address: "St Nazaire,FRANCE",
  },
  {
    code: "SNS",
    name: "Salinas Intl Airport",
    Address: "Salinas,UNITED STATES",
  },
  {
    code: "SNU",
    name: "Santa Clara Intl Airport",
    Address: "Santa Clara,CUBA",
  },
  {
    code: "SNY",
    name: "Sidney Municipal",
    Address: "Sidney,UNITED STATES",
  },
  {
    code: "SOB",
    name: "Saarmelleek Balaton Intl Airport",
    Address: "Saarmelleek,HUNGARY",
  },
  {
    code: "SOC",
    name: "Adi Sumarno Intl Airport",
    Address: "Solo,INDONESIA",
  },
  {
    code: "SOD",
    name: "Sorocaba Intl Airport",
    Address: "Sorocaba,BRAZIL",
  },
  {
    code: "SOF",
    name: "Sofia Vrazhdebna Intl Airport",
    Address: "Sofia,BULGARIA",
  },
  {
    code: "SOG",
    name: "Haukasen Intl Airport",
    Address: "Sogndal,NORWAY",
  },
  {
    code: "SOI",
    name: "South Molle Island Intl Airport",
    Address: "South Molle,AUSTRALIA",
  },
  {
    code: "SOM",
    name: "El Tigre Intl Airport",
    Address: "San Tome,VENEZUELA",
  },
  {
    code: "SON",
    name: "Pekoa Intl Airport",
    Address: "Espiritu Santo,VANUATU",
  },
  {
    code: "SOO",
    name: "Soderhamn Intl Airport",
    Address: "Soderhamn,SWEDEN",
  },
  {
    code: "SOP",
    name: "Pinehurst Intl Airport",
    Address: "Pinehurst,UNITED STATES",
  },
  {
    code: "SOQ",
    name: "Jefman Intl Airport",
    Address: "Sorong,INDONESIA",
  },
  {
    code: "SOU",
    name: "Southampton Intl Airport",
    Address: "Southampton,UNITED KINGDOM",
  },
  {
    code: "SOV",
    name: "Seldovia Intl Airport",
    Address: "Seldovia,UNITED STATES",
  },
  {
    code: "SOW",
    name: "Show Low Intl Airport",
    Address: "Show Low,UNITED STATES",
  },
  {
    code: "SOY",
    name: "Stronsay Intl Airport",
    Address: "Stronsay,UNITED KINGDOM",
  },
  {
    code: "SPC",
    name: "La Palma Intl Airport",
    Address: "Santa Cruz,SPAIN",
  },
  {
    code: "SPF",
    name: "Black Hills Clyde Ice Field",
    Address: "Spearfish,UNITED STATES",
  },
  {
    code: "SPG",
    name: "Whitted Intl Airport",
    Address: "St Petersburg,UNITED STATES",
  },
  {
    code: "SPI",
    name: "Capital Intl Airport",
    Address: "Springfield,UNITED STATES",
  },
  { code: "SPJ", name: "Sparta Intl Airport", Address: "Sparta,GREECE" },
  { code: "OKD", name: "Okadama Intl Airport", Address: "Sapporo,JAPAN" },
  { code: "CTS", name: "Chitose Intl Airport", Address: "Sapporo,JAPAN" },
  {
    code: "SPM",
    name: "Spangdahlem Air Base",
    Address: "Spangdahlem,GERMANY",
  },
  {
    code: "SPN",
    name: "Saipan Intl ",
    Address: "Saipan,NORTHERN MARIANA ISLANDS",
  },
  {
    code: "SPR",
    name: "San Pedro Intl Airport",
    Address: "San Pedro,BELIZE",
  },
  {
    code: "SPS",
    name: "Wichita Falls Municipal Intl Airport",
    Address: "Wichita Falls,UNITED STATES",
  },
  { code: "SPU", name: "Split Intl Airport", Address: "Split,CROATIA" },
  {
    code: "SPW",
    name: "Spencer Municipal",
    Address: "Spencer,UNITED STATES",
  },
  {
    code: "SPZ",
    name: "Springdale Municipal Intl Airport",
    Address: "Springdale,UNITED STATES",
  },
  {
    code: "SQA",
    name: "Santa Ynez Intl Airport",
    Address: "Santa Ynez,UNITED STATES",
  },
  {
    code: "SQH",
    name: "Na San Intl Airport",
    Address: "Son La,VIET NAM",
  },
  {
    code: "SQI",
    name: "Whiteside Cty Municipal",
    Address: "Sterling,UNITED STATES",
  },
  {
    code: "SQL",
    name: "San Carlos Intl Airport",
    Address: "San Carlos,UNITED STATES",
  },
  {
    code: "SQO",
    name: "Gunnarn Intl Airport",
    Address: "Storuman,SWEDEN",
  },
  { code: "SRE", name: "Sucre Intl Airport", Address: "Sucre,BOLIVIA" },
  {
    code: "SRG",
    name: "Achmad Uani Intl Airport",
    Address: "Semarang,INDONESIA",
  },
  {
    code: "SRL",
    name: "Santa Rosalia Intl Airport",
    Address: "Santa Rosalia,MEXICO",
  },
  {
    code: "SRN",
    name: "Strahan Intl Airport",
    Address: "Strahan,AUSTRALIA",
  },
  { code: "SRP", name: "Stord Intl Airport", Address: "Stord,NORWAY" },
  {
    code: "SRQ",
    name: "Sarasota Bradenton Intl Airport",
    Address: "Sarasota,UNITED STATES",
  },
  {
    code: "SRU",
    name: "Skypark Intl Airport",
    Address: "Santa Cruz,UNITED STATES",
  },
  {
    code: "SRZ",
    name: "El Trompillo Intl Airport",
    Address: "Santa Cruz,BOLIVIA",
  },
  {
    code: "VVI",
    name: "Viru Viru Intl Airport",
    Address: "Santa Cruz,BOLIVIA",
  },
  {
    code: "SSA",
    name: "Luis E Magalhaes Intl Airport",
    Address: "Salvador,BRAZIL",
  },
  {
    code: "SSG",
    name: "Santa Isabel Intl Airport",
    Address: "Malabo,EQUATORIAL GUINEA",
  },
  {
    code: "SSH",
    name: "Ophira Intl Airport",
    Address: "Sharm El Sheik,EGYPT",
  },
  {
    code: "SSI",
    name: "McKinnon Intl Airport",
    Address: "St Simons Is,UNITED STATES",
  },
  {
    code: "SSJ",
    name: "Stokka Intl Airport",
    Address: "Sandnessjoen,NORWAY",
  },
  {
    code: "CIU",
    name: "Chippewa Cnty Intl ",
    Address: "Sault Ste Marie,UNITED STATES",
  },
  {
    code: "SSQ",
    name: "La Sarre Rail Station",
    Address: "La Sarre,CANADA",
  },
  {
    code: "SZF",
    name: "Carsamba Intl Airport",
    Address: "Samsun,TURKEY",
  },
  { code: "SSZ", name: "Santos Intl Airport", Address: "Santos,BRAZIL" },
  {
    code: "STB",
    name: "Las Delicias Intl Airport",
    Address: "Santa Barbara,VENEZUELA",
  },
  {
    code: "STC",
    name: "Saint Cloud Municipal",
    Address: "Saint Cloud,UNITED STATES",
  },
  {
    code: "STD",
    name: "Mayor Humberto Vivas Guerrero Intl Airport",
    Address: "Santo Domingo,VENEZUELA",
  },
  {
    code: "STE",
    name: "Stevens Point Municipal",
    Address: "Stevens Point,UNITED STATES",
  },
  {
    code: "STI",
    name: "Santiago Municipal",
    Address: "Santiago,DOMINICAN REPUBLIC",
  },
  {
    code: "STJ",
    name: "Rosecrans Memorial Intl Airport",
    Address: "St Joseph,UNITED STATES",
  },
  {
    code: "SUS",
    name: "Spirit Of St Louis Intl Airport",
    Address: "St Louis,UNITED STATES",
  },
  {
    code: "CPS",
    name: "East St Louis",
    Address: "St Louis,UNITED STATES",
  },
  {
    code: "STL",
    name: "Lambert St Louis Intl ",
    Address: "St Louis,UNITED STATES",
  },
  {
    code: "STM",
    name: "Eduardo Gomes Intl Airport",
    Address: "Santarem,BRAZIL",
  },
  {
    code: "XEW",
    name: "Flemingsberg Rail Station",
    Address: "Stockholm,SWEDEN",
  },
  {
    code: "ARN",
    name: "Arlanda Intl Airport",
    Address: "Stockholm,SWEDEN",
  },
  {
    code: "XEV",
    name: "Stockholm Rail Station",
    Address: "Stockholm,SWEDEN",
  },
  {
    code: "NYO",
    name: "Skavsta Intl Airport",
    Address: "Stockholm,SWEDEN",
  },
  {
    code: "VST",
    name: "Hasslo Intl Airport",
    Address: "Stockholm,SWEDEN",
  },
  {
    code: "BMA",
    name: "Bromma Intl Airport",
    Address: "Stockholm,SWEDEN",
  },
  {
    code: "STP",
    name: "Downtown St Paul Intl Airport",
    Address: "St Paul,UNITED STATES",
  },
  {
    code: "STR",
    name: "Stuttgart Intl Airport",
    Address: "Stuttgart,GERMANY",
  },
  {
    code: "ZWS",
    name: "Stuttgart Railway Service",
    Address: "Stuttgart,GERMANY",
  },
  {
    code: "STS",
    name: "Sonoma Cty Intl Airport",
    Address: "Santa Rosa,UNITED STATES",
  },
  {
    code: "STT",
    name: "Cyril E King Intl Airport",
    Address: "St Thomas,UNITED STATES",
  },
  {
    code: "STW",
    name: "Stavropol Intl Airport",
    Address: "Stavropol,RUSSIA",
  },
  {
    code: "STX",
    name: "Alexander Hamilton Intl Airport",
    Address: "St Croix,UNITED STATES",
  },
  { code: "STY", name: "Salto Intl Airport", Address: "Salto,URUGUAY" },
  { code: "SUA", name: "Witham Field", Address: "Stuart,UNITED STATES" },
  {
    code: "SUB",
    name: "Juanda Intl Airport",
    Address: "Surabaya,INDONESIA",
  },
  {
    code: "SUE",
    name: "Door Country Intl Airport",
    Address: "Sturgeon Bay,UNITED STATES",
  },
  {
    code: "SUF",
    name: "S Eufemia Intl Airport",
    Address: "Lamezia Terme,ITALY",
  },
  { code: "SUH", name: "Sur Intl Airport", Address: "Sur Om,OMAN" },
  {
    code: "SUM",
    name: "Sumter Municipal Intl Airport",
    Address: "Sumter,UNITED STATES",
  },
  {
    code: "SUN",
    name: "Friedman Memorial",
    Address: "Sun Valley,UNITED STATES",
  },
  { code: "SUU", name: "Travis AFB", Address: "Fairfield,UNITED STATES" },
  { code: "SUV", name: "Nausori Intl Airport", Address: "Suva,FIJI" },
  {
    code: "SUW",
    name: "Richard I Bong Intl Airport",
    Address: "Superior,UNITED STATES",
  },
  {
    code: "SUX",
    name: "Sioux Gateway Intl Airport",
    Address: "Sioux City,UNITED STATES",
  },
  {
    code: "SVB",
    name: "Sambava Intl Airport",
    Address: "Sambava,MADAGASCAR",
  },
  {
    code: "SVC",
    name: "Grant County Intl Airport",
    Address: "Silver City,UNITED STATES",
  },
  {
    code: "SVD",
    name: "Arnos Vale Intl Airport",
    Address: "St Vincent,SAINT VINCENT AND THE GRENADINES",
  },
  {
    code: "ZWC",
    name: "Stavanger Rail Station",
    Address: "Stavanger,NORWAY",
  },
  { code: "XOQ", name: "Sira Rail Station", Address: "Stavanger,NORWAY" },
  {
    code: "XKC",
    name: "Sandnes Rail Station",
    Address: "Stavanger,NORWAY",
  },
  { code: "SVG", name: "Sola Intl Airport", Address: "Stavanger,NORWAY" },
  {
    code: "SVH",
    name: "Statesville Municipal Intl Airport",
    Address: "Statesville,UNITED STATES",
  },
  { code: "SVJ", name: "Helle", Address: "Svolvaer,NORWAY" },
  {
    code: "SVL",
    name: "Savonlinna Intl Airport",
    Address: "Savonlinna,FINLAND",
  },
  {
    code: "SVQ",
    name: "San Pablo Intl Airport",
    Address: "Sevilla,SPAIN",
  },
  {
    code: "SVU",
    name: "Savusavu Intl Airport",
    Address: "Savusavu,FIJI",
  },
  {
    code: "SVX",
    name: "Koltsovo Intl Airport",
    Address: "Ekaterinburg,RUSSIA",
  },
  {
    code: "SVZ",
    name: "San Antonio Intl Airport",
    Address: "San Antonio,VENEZUELA",
  },
  { code: "SWA", name: "Shantou Intl Airport", Address: "Shantou,CHINA" },
  {
    code: "SWD",
    name: "Seward Intl Airport",
    Address: "Seward,UNITED STATES",
  },
  {
    code: "SWF",
    name: "Stewart Intl Airport",
    Address: "Newburgh,UNITED STATES",
  },
  {
    code: "SWH",
    name: "Swan Hill Intl Airport",
    Address: "Swan Hill,AUSTRALIA",
  },
  {
    code: "SWI",
    name: "Swindon Rail Station",
    Address: "Swindon,UNITED KINGDOM",
  },
  {
    code: "XWS",
    name: "Swindon Rail Station",
    Address: "Swindon,UNITED KINGDOM",
  },
  {
    code: "SWO",
    name: "Searcy Fld",
    Address: "Stillwater,UNITED STATES",
  },
  {
    code: "SWS",
    name: "Fairwood Comm",
    Address: "Swansea,UNITED KINGDOM",
  },
  {
    code: "SWT",
    name: "Strezhevoy Intl Airport",
    Address: "Strezhevoy Intl Airport,RUSSIA",
  },
  {
    code: "SWU",
    name: "Su Won Intl Airport",
    Address: "Su Won,KOREA, REPUBLIC OF",
  },
  {
    code: "SXB",
    name: "Enzheim Intl Airport",
    Address: "Strasbourg,FRANCE",
  },
  {
    code: "XER",
    name: "Strasbourg Bus Service",
    Address: "Strasbourg,FRANCE",
  },
  { code: "SXE", name: "Sale Intl Airport", Address: "Sale,AUSTRALIA" },
  { code: "SXL", name: "Collooney", Address: "Sligo,IRELAND" },
  {
    code: "SXM",
    name: "Princ Juliana Intl Airport",
    Address: "St Maarten,NETHERLANDS ANTILLES",
  },
  {
    code: "SXR",
    name: "Srinagar Intl Airport",
    Address: "Srinagar,INDIA",
  },
  {
    code: "SXZ",
    name: "Siirt Intl Airport",
    Address: "Siirt Intl Airport,TURKEY",
  },
  {
    code: "RSE",
    name: "Au Rose Bay Intl Airport",
    Address: "Sydney,AUSTRALIA",
  },
  {
    code: "SYD",
    name: "Sydney Kingsford Smith Intl Airport",
    Address: "Sydney,AUSTRALIA",
  },
  {
    code: "LBH",
    name: "Palm Beach Intl Airport",
    Address: "Sydney,AUSTRALIA",
  },
  { code: "SYO", name: "Shonai Intl Airport", Address: "Shonai,JAPAN" },
  {
    code: "SYR",
    name: "Hancock Intl ",
    Address: "Syracuse,UNITED STATES",
  },
  { code: "SYX", name: "Sanya Intl Airport", Address: "Sanya,CHINA" },
  {
    code: "SYY",
    name: "Stornoway Intl Airport",
    Address: "Stornoway,UNITED KINGDOM",
  },
  { code: "SYZ", name: "Shiraz Intl Airport", Address: "Shiraz,IRAN" },
  {
    code: "SZC",
    name: "Guanacaste Intl Airport",
    Address: "Santa Cruz,COSTA RICA",
  },
  {
    code: "SZD",
    name: "Sheffield City Intl Airport",
    Address: "Sheffield,UNITED KINGDOM",
  },
  {
    code: "SZG",
    name: "W A Mozart Intl Airport",
    Address: "Salzburg,AUSTRIA",
  },
  {
    code: "SZJ",
    name: "Siguanea Intl Airport",
    Address: "Siguanea,CUBA",
  },
  {
    code: "SZK",
    name: "Skukuza Intl Airport",
    Address: "Skukuza,SOUTH AFRICA",
  },
  {
    code: "SZO",
    name: "Shanzhou Intl Airport",
    Address: "Shanzhou,CHINA",
  },
  {
    code: "SZP",
    name: "Santa Paula Intl Airport",
    Address: "Santa Paula,UNITED STATES",
  },
  {
    code: "SZR",
    name: "Stara Zagora Intl Airport",
    Address: "Stara Zagora,BULGARIA",
  },
  {
    code: "SZT",
    name: "San Cristobal Intl Airport",
    Address: "San Cristobal De Las Casas,MEXICO",
  },
  { code: "SZV", name: "Suzhou Intl Airport", Address: "Suzhou,CHINA" },
  {
    code: "SZX",
    name: "Shenzhen Intl Airport",
    Address: "Shenzhen,CHINA",
  },
  { code: "SZY", name: "Mazury Intl Airport", Address: "Szymany,POLAND" },
  {
    code: "SZZ",
    name: "Goleniow Intl Airport",
    Address: "Szczecin,POLAND",
  },
  {
    code: "TAB",
    name: "Crown Point Intl Airport",
    Address: "Tobago,TRINIDAD AND TOBAGO",
  },
  {
    code: "TAC",
    name: "D Z Romualdez Intl Airport",
    Address: "Tacloban,PHILIPPINES",
  },
  {
    code: "TAE",
    name: "Daegu Intl Airport",
    Address: "Daegu,KOREA, REPUBLIC OF",
  },
  {
    code: "TAG",
    name: "Tagbilaran Intl Airport",
    Address: "Tagbilaran,PHILIPPINES",
  },
  { code: "TAH", name: "Tanna Intl Airport", Address: "Tanna,VANUATU" },
  {
    code: "TAK",
    name: "Takamatsu Intl Airport",
    Address: "Takamatsu,JAPAN",
  },
  {
    code: "TAL",
    name: "Ralph Calhoun Intl Airport",
    Address: "Tanana,UNITED STATES",
  },
  {
    code: "TAM",
    name: "General F Javier Mina",
    Address: "Tampico,MEXICO",
  },
  { code: "TAO", name: "Liuting Intl Airport", Address: "Qingdao,CHINA" },
  {
    code: "TAP",
    name: "Tapachula Intl Airport",
    Address: "Tapachula,MEXICO",
  },
  {
    code: "TAR",
    name: "M A Grottag Intl Airport",
    Address: "Taranto,ITALY",
  },
  {
    code: "TAS",
    name: "Vostochny Intl Airport",
    Address: "Tashkent,UZBEKISTAN",
  },
  {
    code: "TAT",
    name: "Poprad Tatry Intl Airport",
    Address: "Poprad Tatry,SLOVAKIA",
  },
  { code: "TAY", name: "Tartu Intl Airport", Address: "Tartu,ESTONIA" },
  {
    code: "TAZ",
    name: "Dashoguz Intl Airport",
    Address: "Dashoguz,TURKMENISTAN",
  },
  {
    code: "TBB",
    name: "Tuy Hoa Intl Airport",
    Address: "Tuy Hoa,VIET NAM",
  },
  {
    code: "TBG",
    name: "Tabubil Intl Airport",
    Address: "Tububil,PAPUA NEW GUINEA",
  },
  {
    code: "TBJ",
    name: "Tabarka Intl Airport",
    Address: "Tabarka,TUNISIA",
  },
  {
    code: "TBN",
    name: "Forney Field",
    Address: "Ft Leonard Wood,UNITED STATES",
  },
  {
    code: "TBO",
    name: "Tabora Intl Airport",
    Address: "Tabora,TANZANIA",
  },
  { code: "TBP", name: "Tumbes Intl Airport", Address: "Tumbes,PERU" },
  {
    code: "TBS",
    name: "Novo Alexeyevka Intl Airport",
    Address: "Tbilisi,GEORGIA",
  },
  {
    code: "TBT",
    name: "Tabatinga Intl Airport",
    Address: "Tabatinga,BRAZIL",
  },
  { code: "TBU", name: "Tongatapu Intl ", Address: "Nuku Alofa,TONGA" },
  { code: "TBW", name: "Tambov Intl Airport", Address: "Tambov,RUSSIA" },
  { code: "TBZ", name: "Tabriz Intl Airport", Address: "Tabriz,IRAN" },
  {
    code: "TCA",
    name: "Tennant Creek Intl Airport",
    Address: "Tennant Creek,AUSTRALIA",
  },
  {
    code: "TCB",
    name: "Treasure Cay Intl Airport",
    Address: "Treasure Cay,BAHAMAS",
  },
  { code: "TCE", name: "Tulcea Intl Airport", Address: "Tulcea,ROMANIA" },
  {
    code: "TFS",
    name: "Reina Sofia Intl Airport",
    Address: "Tenerife,SPAIN",
  },
  {
    code: "TFN",
    name: "Tenerife Norte Los Rodeos Intl Airport",
    Address: "Tenerife,SPAIN",
  },
  {
    code: "TCL",
    name: "Van De Graff Intl Airport",
    Address: "Tuscaloosa,UNITED STATES",
  },
  {
    code: "TCN",
    name: "Tehuacan Intl Airport",
    Address: "Tehuacan,MEXICO",
  },
  {
    code: "TCP",
    name: "Ras An Naqb Intl Airport",
    Address: "Taba,EGYPT",
  },
  {
    code: "TCR",
    name: "Tuticorin Intl Airport",
    Address: "Tuticorin,INDIA",
  },
  {
    code: "TCU",
    name: "Thaba Nchu Intl Airport",
    Address: "Thaba Nchu,SOUTH AFRICA",
  },
  {
    code: "TCW",
    name: "Tocumwal Intl Airport",
    Address: "Tocumwal,AUSTRALIA",
  },
  {
    code: "TDD",
    name: "Trinidad Intl Airport",
    Address: "Trinidad,BOLIVIA",
  },
  {
    code: "TDK",
    name: "Taldy Kurgan Intl Airport",
    Address: "Taldy Kurgan,KAZAKHSTAN",
  },
  {
    code: "TDL",
    name: "Tandil Intl Airport",
    Address: "Tandil,ARGENTINA",
  },
  { code: "TDX", name: "Trat Intl Airport", Address: "Trat,THAILAND" },
  { code: "TEA", name: "Tela Intl Airport", Address: "Tela,HONDURAS" },
  {
    code: "TEB",
    name: "Teterboro Intl Airport",
    Address: "Teterboro,UNITED STATES",
  },
  {
    code: "TEC",
    name: "Telemaco Borba Intl Airport",
    Address: "Telemaco Borba,BRAZIL",
  },
  {
    code: "TED",
    name: "Thisted Intl Airport",
    Address: "Thisted,DENMARK",
  },
  { code: "TEN", name: "Tongren Intl Airport", Address: "Tongren,CHINA" },
  { code: "TEQ", name: "Corlu Intl Airport", Address: "Corlu,TURKEY" },
  {
    code: "TER",
    name: "Lajes Intl Airport",
    Address: "Terceira,PORTUGAL",
  },
  {
    code: "TET",
    name: "Matunda Intl Airport",
    Address: "Tete,MOZAMBIQUE",
  },
  {
    code: "TEU",
    name: "Manapouri Intl Airport",
    Address: "Te Anau,NEW ZEALAND",
  },
  {
    code: "TEX",
    name: "Telluride Municipal Intl Airport",
    Address: "Telluride,UNITED STATES",
  },
  { code: "TFF", name: "Tefe Intl Airport", Address: "Tefe,BRAZIL" },
  {
    code: "TGD",
    name: "Golubovci Intl Airport",
    Address: "Podgorica,MONTENEGRO",
  },
  {
    code: "TGG",
    name: "Sultan Mahmood Intl Airport",
    Address: "Kuala Terengganu,MALAYSIA",
  },
  {
    code: "TGM",
    name: "Tirgu Mures Intl Airport",
    Address: "Tirgu Mures,ROMANIA",
  },
  {
    code: "TGN",
    name: "La Trobe Traralgon Intl Airport",
    Address: "Traralgon,AUSTRALIA",
  },
  {
    code: "TGO",
    name: "Tongliao Intl Airport",
    Address: "Tongliao,CHINA",
  },
  { code: "TGT", name: "Tanga Intl Airport", Address: "Tanga,TANZANIA" },
  {
    code: "TGU",
    name: "Toncontin Intl Airport",
    Address: "Tegucigalpa,HONDURAS",
  },
  {
    code: "TGZ",
    name: "Llano San Juan Intl Airport",
    Address: "Tuxtla Gutierrez,MEXICO",
  },
  {
    code: "THA",
    name: "Northern Intl Airport",
    Address: "Tullahoma,UNITED STATES",
  },
  { code: "THC", name: "Tchien Intl Airport", Address: "Tchien,LIBERIA" },
  {
    code: "THE",
    name: "Teresina Intl Airport",
    Address: "Teresina,BRAZIL",
  },
  {
    code: "THG",
    name: "Thangool Intl Airport",
    Address: "Thangool,AUSTRALIA",
  },
  {
    code: "THN",
    name: "Trollhattan Intl Airport",
    Address: "Trollhattan,SWEDEN",
  },
  {
    code: "THP",
    name: "Hot Springs",
    Address: "Thermopolis,UNITED STATES",
  },
  { code: "THR", name: "Mehrabad Intl Airport", Address: "Teheran,IRAN" },
  {
    code: "IKA",
    name: "Imam Khomeini Intl Airport",
    Address: "Teheran,IRAN",
  },
  {
    code: "THS",
    name: "Sukhothai Intl Airport",
    Address: "Sukhothai,THAILAND",
  },
  {
    code: "THV",
    name: "York Intl Airport",
    Address: "York,UNITED STATES",
  },
  { code: "TIA", name: "Rinas Intl Airport", Address: "Tirana,ALBANIA" },
  {
    code: "TIF",
    name: "Taif Intl Airport",
    Address: "Taif,SAUDI ARABIA",
  },
  {
    code: "TIG",
    name: "Tingwon Intl Airport",
    Address: "Tingwon,PAPUA NEW GUINEA",
  },
  {
    code: "TIH",
    name: "Tikehau Intl Airport",
    Address: "Tikehau,FRENCH POLYNESIA",
  },
  {
    code: "TIJ",
    name: "Gen Abelardo Rodriguez",
    Address: "Tijuana,MEXICO",
  },
  {
    code: "TIM",
    name: "Timika Intl Airport",
    Address: "Timika,INDONESIA",
  },
  {
    code: "TIP",
    name: "Tripoli Intl Airport",
    Address: "Tripoli,LIBYAN ARAB JAMAHIRIYA",
  },
  {
    code: "TIQ",
    name: "Tinian Intl Airport",
    Address: "Tinian,NORTHERN MARIANA ISLANDS",
  },
  {
    code: "TIR",
    name: "Tirupati Intl Airport",
    Address: "Tirupati,INDIA",
  },
  {
    code: "TIS",
    name: "Thursday Island Intl Airport",
    Address: "Thursday Island,AUSTRALIA",
  },
  {
    code: "TIU",
    name: "Timaru Intl Airport",
    Address: "Timaru,NEW ZEALAND",
  },
  {
    code: "TIV",
    name: "Tivat Intl Airport",
    Address: "Tivat,MONTENEGRO",
  },
  {
    code: "TIW",
    name: "Tacoma Industrial Intl Airport",
    Address: "Tacoma,UNITED STATES",
  },
  {
    code: "TIX",
    name: "Space Center Executive Intl Airport",
    Address: "Titusville,UNITED STATES",
  },
  {
    code: "TIZ",
    name: "Tari Intl Airport",
    Address: "Tari,PAPUA NEW GUINEA",
  },
  { code: "TJA", name: "Tarija Intl Airport", Address: "Tarija,BOLIVIA" },
  {
    code: "TJK",
    name: "TOKAT HAVALIMANI",
    Address: "TOKAT HAVALIMANI,TURKEY",
  },
  { code: "TJM", name: "Tyumen Intl Airport", Address: "Tyumen,RUSSIA" },
  {
    code: "TJQ",
    name: "Bulutumbang Intl Airport",
    Address: "Tanjung Pandan,INDONESIA",
  },
  {
    code: "TKF",
    name: "Truckee Intl Airport",
    Address: "Truckee,UNITED STATES",
  },
  {
    code: "TKG",
    name: "Branti Intl Airport",
    Address: "Bandar Lampung,INDONESIA",
  },
  {
    code: "TKJ",
    name: "Tok Intl Airport",
    Address: "Tok Ak,UNITED STATES",
  },
  {
    code: "TKK",
    name: "Truk Intl Airport",
    Address: "Truk,MICRONESIA, FEDERATED STATES OF",
  },
  {
    code: "TKM",
    name: "El Peten Intl Airport",
    Address: "Tikal,GUATEMALA",
  },
  {
    code: "TKN",
    name: "Tokunoshima Intl Airport",
    Address: "Tokunoshima,JAPAN",
  },
  {
    code: "TKQ",
    name: "Kigoma Intl Airport",
    Address: "Kigoma,TANZANIA",
  },
  {
    code: "TKS",
    name: "Tokushima Intl Airport",
    Address: "Tokushima,JAPAN",
  },
  { code: "TKU", name: "Turku Intl Airport", Address: "Turku,FINLAND" },
  { code: "TLC", name: "Morelos Intl Airport", Address: "Toluca,MEXICO" },
  {
    code: "TLD",
    name: "Limpopo Valley Intl Airport",
    Address: "Tuli Block,BOTSWANA",
  },
  {
    code: "TLE",
    name: "Tulear Intl Airport",
    Address: "Tulear,MADAGASCAR",
  },
  {
    code: "TLH",
    name: "Tallahassee Municipal",
    Address: "Tallahassee,UNITED STATES",
  },
  {
    code: "TLL",
    name: "Ulemiste Intl Airport",
    Address: "Tallinn,ESTONIA",
  },
  {
    code: "TLM",
    name: "Zenata Intl Airport",
    Address: "Tlemcen,ALGERIA",
  },
  {
    code: "TLN",
    name: "Le Palyvestre Intl Airport",
    Address: "Toulon,FRANCE",
  },
  { code: "TLR", name: "Mefford Field", Address: "Tulare,UNITED STATES" },
  {
    code: "TLS",
    name: "Blagnac Intl Airport",
    Address: "Toulouse,FRANCE",
  },
  {
    code: "TLV",
    name: "Ben Gurion Intl Airport",
    Address: "Tel Aviv,ISRAEL",
  },
  { code: "SDV", name: "Dov Intl Airport", Address: "Tel Aviv,ISRAEL" },
  {
    code: "TMA",
    name: "Henry Tift Myers Intl Airport",
    Address: "Tifton,UNITED STATES",
  },
  {
    code: "TMG",
    name: "Tomanggong Intl Airport",
    Address: "Tomanggong,MALAYSIA",
  },
  { code: "TML", name: "Tamale Intl Airport", Address: "Tamale,GHANA" },
  {
    code: "TMM",
    name: "Tamatave Intl Airport",
    Address: "Tamatave,MADAGASCAR",
  },
  {
    code: "TMP",
    name: "Tampere Pirkkala Intl Airport",
    Address: "Tampere,FINLAND",
  },
  {
    code: "TMS",
    name: "Sao Tome Intl Airport",
    Address: "Sao Tome Is,SAO TOME AND PRINCIPE",
  },
  {
    code: "TMT",
    name: "Trombetas Intl Airport",
    Address: "Trombetas,BRAZIL",
  },
  {
    code: "TMU",
    name: "Tambor Intl Airport",
    Address: "Tambor,COSTA RICA",
  },
  {
    code: "TMW",
    name: "Tamworth Intl Airport",
    Address: "Tamworth,AUSTRALIA",
  },
  {
    code: "TMZ",
    name: "Thames Intl Airport",
    Address: "Thames,NEW ZEALAND",
  },
  { code: "TNA", name: "Jinan Intl Airport", Address: "Jinan,CHINA" },
  {
    code: "TND",
    name: "Trinidad CU Intl Airport",
    Address: "Trinidad,CUBA",
  },
  {
    code: "TNE",
    name: "Tanega Shima Intl Airport",
    Address: "Tanega Shima,JAPAN",
  },
  {
    code: "TNF",
    name: "Toussus Le Noble Intl Airport",
    Address: "Toussus Le Noble,FRANCE",
  },
  {
    code: "TNG",
    name: "Boukhalef Intl Airport",
    Address: "Tangier,MOROCCO",
  },
  {
    code: "TNN",
    name: "Tainan Intl Airport",
    Address: "Tainan,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "TNO",
    name: "Tamarindo Intl Airport",
    Address: "Tamarindo,COSTA RICA",
  },
  {
    code: "TNR",
    name: "Ivato Intl Airport",
    Address: "Tananarive,MADAGASCAR",
  },
  {
    code: "TNU",
    name: "Municipal Newton Intl Airport",
    Address: "Newton,UNITED STATES",
  },
  {
    code: "TOB",
    name: "Tobruk Intl Airport",
    Address: "Tobruk,LIBYAN ARAB JAMAHIRIYA",
  },
  {
    code: "TOD",
    name: "Tioman Intl Airport",
    Address: "Tioman,MALAYSIA",
  },
  { code: "TOE", name: "Tozeur Intl Airport", Address: "Tozeur,TUNISIA" },
  { code: "TOF", name: "Tomsk Intl Airport", Address: "Tomsk,RUSSIA" },
  {
    code: "TOG",
    name: "Togiak Village Intl Airport",
    Address: "Togiak,UNITED STATES",
  },
  { code: "TOH", name: "Torres Airstrip", Address: "Torres,VANUATU" },
  {
    code: "TOL",
    name: "Toledo Express Intl Airport",
    Address: "Toledo,UNITED STATES",
  },
  {
    code: "TOP",
    name: "Phillip Billard Intl Airport",
    Address: "Topeka,UNITED STATES",
  },
  { code: "FOE", name: "Forbes Field", Address: "Topeka,UNITED STATES" },
  {
    code: "TOR",
    name: "Torrington Minicipal Intl Airport",
    Address: "Torrington,UNITED STATES",
  },
  {
    code: "TOS",
    name: "Tromso Langnes Intl Airport",
    Address: "Tromso,NORWAY",
  },
  {
    code: "RAD",
    name: "Road Town Intl Airport",
    Address: "Tortola Westend,VIRGIN ISLANDS, BRITISH",
  },
  { code: "TOW", name: "Toledo Intl Airport", Address: "Toledo,BRAZIL" },
  {
    code: "TOX",
    name: "Tobolsk Intl Airport",
    Address: "Tobolsk,RUSSIA",
  },
  { code: "TOY", name: "Toyama Intl Airport", Address: "Toyama,JAPAN" },
  { code: "TPA", name: "Tampa Intl ", Address: "Tampa,UNITED STATES" },
  {
    code: "TPF",
    name: "Peter O Knight Intl Airport",
    Address: "Tampa,UNITED STATES",
  },
  {
    code: "PIE",
    name: "St Petersburg Clearwater Intl Airport",
    Address: "Tampa,UNITED STATES",
  },
  {
    code: "TPE",
    name: "Taiwan Taoyuan Intl Airport",
    Address: "Taipei,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "TSA",
    name: "Sung Shan Intl Airport",
    Address: "Taipei,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "TPH",
    name: "Tonopah Intl Airport",
    Address: "Tonopah,UNITED STATES",
  },
  {
    code: "TPL",
    name: "Temple Intl Airport",
    Address: "Temple,UNITED STATES",
  },
  { code: "TPQ", name: "Tepic Intl Airport", Address: "Tepic,MEXICO" },
  { code: "TPR", name: "Tom Price", Address: "Tom Price,AUSTRALIA" },
  { code: "TPS", name: "Birgi Intl Airport", Address: "Trapani,ITALY" },
  {
    code: "TQR",
    name: "San Domino Island Intl Airport",
    Address: "San Domino Island,ITALY",
  },
  {
    code: "TRC",
    name: "Francisco Sarabia Intl Airport",
    Address: "Torreon,MEXICO",
  },
  {
    code: "XOD",
    name: "Oppdal Rail Station",
    Address: "Trondheim,NORWAY",
  },
  {
    code: "XZT",
    name: "Trondheim Rail Station",
    Address: "Trondheim,NORWAY",
  },
  {
    code: "TRD",
    name: "Trondheim Vaernes Intl Airport",
    Address: "Trondheim,NORWAY",
  },
  {
    code: "TRE",
    name: "Tiree Intl Airport",
    Address: "Tiree,UNITED KINGDOM",
  },
  {
    code: "TRG",
    name: "Tauranga Intl Airport",
    Address: "Tauranga,NEW ZEALAND",
  },
  {
    code: "TRI",
    name: "Tri City Regional Intl Airport",
    Address: "Bristol,UNITED STATES",
  },
  {
    code: "TRK",
    name: "Tarakan Intl Airport",
    Address: "Tarakan,INDONESIA",
  },
  {
    code: "TRL",
    name: "Terrell Field Intl Airport",
    Address: "Terrell,UNITED STATES",
  },
  {
    code: "TRN",
    name: "Torino Caselle Intl Airport",
    Address: "Turin,ITALY",
  },
  { code: "TRO", name: "Taree Intl Airport", Address: "Taree,AUSTRALIA" },
  {
    code: "TRS",
    name: "Ronchi Dei Legionari Intl Airport",
    Address: "Trieste,ITALY",
  },
  {
    code: "TRU",
    name: "Trujillo Intl Airport",
    Address: "Trujillo,PERU",
  },
  {
    code: "TRV",
    name: "Thiruvananthapuram Intl Airport",
    Address: "Thiruvananthapuram,INDIA",
  },
  {
    code: "TRW",
    name: "Bonriki Intl Airport",
    Address: "Tarawa,KIRIBATI",
  },
  {
    code: "TRZ",
    name: "Tiruchirapally Civil Intl Airport",
    Address: "Tiruchirappali,INDIA",
  },
  {
    code: "TSE",
    name: "Astana Intl Airport",
    Address: "Astana,KAZAKHSTAN",
  },
  { code: "TSF", name: "Treviso Intl Airport", Address: "Treviso,ITALY" },
  {
    code: "TSJ",
    name: "Tsushima Intl Airport",
    Address: "Tsushima,JAPAN",
  },
  {
    code: "TSM",
    name: "Taos Intl Airport",
    Address: "Taos,UNITED STATES",
  },
  { code: "TSN", name: "Tianjin Intl Airport", Address: "Tianjin,CHINA" },
  {
    code: "TSR",
    name: "Timisoara Intl Airport",
    Address: "Timisoara,ROMANIA",
  },
  { code: "TST", name: "Trang Intl Airport", Address: "Trang,THAILAND" },
  {
    code: "TSV",
    name: "Townsville Intl Airport",
    Address: "Townsville,AUSTRALIA",
  },
  { code: "TTB", name: "Arbatax Intl Airport", Address: "Tortoli,ITALY" },
  {
    code: "TTD",
    name: "Troutdale Intl Airport",
    Address: "Troutdale,UNITED STATES",
  },
  { code: "TTJ", name: "Tottori Intl Airport", Address: "Tottori,JAPAN" },
  {
    code: "TTN",
    name: "Trenton Mercer Intl Airport",
    Address: "Trenton,UNITED STATES",
  },
  {
    code: "TRX",
    name: "Trenton Intl Airport",
    Address: "Trenton,UNITED STATES",
  },
  {
    code: "TTQ",
    name: "Tortuquero Intl Airport",
    Address: "Tortuquero,COSTA RICA",
  },
  {
    code: "TTT",
    name: "Taitung Intl Airport",
    Address: "Taitung,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "TUC",
    name: "Benjamin Matienzo Intl Airport",
    Address: "Tucuman,ARGENTINA",
  },
  {
    code: "TUF",
    name: "Saint Symphorien Intl Airport",
    Address: "Tours,FRANCE",
  },
  { code: "XSH", name: "Tours Rail Station", Address: "Tours,FRANCE" },
  {
    code: "TUG",
    name: "Tuguegarao Intl Airport",
    Address: "Tuguegarao,PHILIPPINES",
  },
  {
    code: "TUI",
    name: "Turaif Intl Airport",
    Address: "Turaif,SAUDI ARABIA",
  },
  {
    code: "TUK",
    name: "Turbat Intl Airport",
    Address: "Turbat,PAKISTAN",
  },
  { code: "TUL", name: "Tulsa Intl ", Address: "Tulsa,UNITED STATES" },
  {
    code: "TUN",
    name: "Carthage Intl Airport",
    Address: "Tunis,TUNISIA",
  },
  {
    code: "TUO",
    name: "Taupo Intl Airport",
    Address: "Taupo,NEW ZEALAND",
  },
  {
    code: "TUP",
    name: "C D Lemons Municipal Intl Airport",
    Address: "Tupelo,UNITED STATES",
  },
  {
    code: "TUR",
    name: "Tucurui Intl Airport",
    Address: "Tucurui,BRAZIL",
  },
  {
    code: "TUS",
    name: "Tucson Intl Airport",
    Address: "Tucson,UNITED STATES",
  },
  {
    code: "TUU",
    name: "Tabuk Intl Airport",
    Address: "Tabuk,SAUDI ARABIA",
  },
  { code: "TUY", name: "Tulum Intl Airport", Address: "Tulum,MEXICO" },
  {
    code: "TVC",
    name: "Cherry Capital Intl Airport",
    Address: "Traverse City,UNITED STATES",
  },
  {
    code: "TVF",
    name: "Thief River Falls Numicipal",
    Address: "Thief River Falls,UNITED STATES",
  },
  {
    code: "TVL",
    name: "Lake Tahoe Intl Airport",
    Address: "Lake Tahoe,UNITED STATES",
  },
  { code: "TVU", name: "Matei Intl Airport", Address: "Taveuni,FIJI" },
  {
    code: "TWA",
    name: "Twin Hills Intl Airport",
    Address: "Twin Hills,UNITED STATES",
  },
  {
    code: "TWB",
    name: "Toowoomba Intl Airport",
    Address: "Toowoomba,AUSTRALIA",
  },
  {
    code: "TWF",
    name: "Twin Falls City County Intl Airport",
    Address: "Twin Falls,UNITED STATES",
  },
  { code: "TWU", name: "Tawau Intl Airport", Address: "Tawau,MALAYSIA" },
  {
    code: "TXG",
    name: "Taichung Intl Airport",
    Address: "Taichung,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "RMQ",
    name: "Ching Chuan Kang",
    Address: "Taichung,TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "TXK",
    name: "Texarkana Municipal",
    Address: "Texarkana,UNITED STATES",
  },
  { code: "TXN", name: "Tunxi Intl Airport", Address: "Tunxi,CHINA" },
  {
    code: "TYD",
    name: "Tynda Intl Airport",
    Address: "Tynda Intl Airport,RUSSIA",
  },
  { code: "TYN", name: "Taiyuan Intl Airport", Address: "Taiyuan,CHINA" },
  { code: "HND", name: "Haneda Intl Airport", Address: "Tokyo,JAPAN" },
  { code: "NRT", name: "Narita", Address: "Tokyo,JAPAN" },
  { code: "TYR", name: "Pounds Field", Address: "Tyler,UNITED STATES" },
  {
    code: "TYS",
    name: "McGhee Tyson Intl Airport",
    Address: "Knoxville,UNITED STATES",
  },
  {
    code: "TZL",
    name: "Tuzla Intl Airport",
    Address: "Tuzla,BOSNIA AND HERZEGOVINA",
  },
  {
    code: "TZN",
    name: "South Andros Intl Airport",
    Address: "South Andros,BAHAMAS",
  },
  {
    code: "TZX",
    name: "Trabzon Intl Airport",
    Address: "Trabzon,TURKEY",
  },
  { code: "UA", name: "UDJ", Address: "UDJ,UKRAINE" },
  {
    code: "UAC",
    name: "San Luis Rio Colorado Municipal",
    Address: "San Luis Rio Colorado,MEXICO",
  },
  {
    code: "UAK",
    name: "Narsarsuaq Intl Airport",
    Address: "Narsarsuaq,GREENLAND",
  },
  {
    code: "UAQ",
    name: "San Juan Intl Airport",
    Address: "San Juan,ARGENTINA",
  },
  { code: "UAS", name: "Samburu Airstrip", Address: "Samburu,KENYA" },
  {
    code: "UBA",
    name: "Uberaba Intl Airport",
    Address: "Uberaba,BRAZIL",
  },
  { code: "UBJ", name: "Ube Intl Airport", Address: "Ube Jp,JAPAN" },
  {
    code: "UBP",
    name: "Muang Ubon Intl Airport",
    Address: "Ubon Ratchathani,THAILAND",
  },
  {
    code: "UBS",
    name: "Lowndes Cty Intl Airport",
    Address: "Columbus,UNITED STATES",
  },
  {
    code: "GTR",
    name: "Golden Intl Airport",
    Address: "Columbus,UNITED STATES",
  },
  {
    code: "UCA",
    name: "Oneida County Intl Airport",
    Address: "Utica,UNITED STATES",
  },
  { code: "UCK", name: "Lutsk Intl Airport", Address: "Lutsk,UKRAINE" },
  {
    code: "UCT",
    name: "Ukhta Intl Airport",
    Address: "Ukhta Intl Airport,RUSSIA",
  },
  {
    code: "UDD",
    name: "Bermuda Dunes Intl Airport",
    Address: "Palm Desert,UNITED STATES",
  },
  {
    code: "UDE",
    name: "Volkel Intl Airport",
    Address: "Uden,NETHERLANDS",
  },
  {
    code: "UDI",
    name: "Eduardo Gomes Airprt",
    Address: "Uberlandia,BRAZIL",
  },
  {
    code: "UDJ",
    name: "Uzhhorod Intl Airport",
    Address: "Uzhhorod Intl Airport,UKRAINE",
  },
  {
    code: "UDN",
    name: "Campoformido Intl Airport",
    Address: "Udine,ITALY",
  },
  {
    code: "UDR",
    name: "Maharana Pratap Intl Airport",
    Address: "Udaipur,INDIA",
  },
  {
    code: "UEE",
    name: "Queenstown Intl Airport",
    Address: "Queenstown,AUSTRALIA",
  },
  {
    code: "UEO",
    name: "Kumejima Intl Airport",
    Address: "Kume Jima,JAPAN",
  },
  { code: "UFA", name: "Ufa Intl Airport", Address: "Ufa RU,RUSSIA" },
  {
    code: "UGC",
    name: "Urgench Intl Airport",
    Address: "Urgench,UZBEKISTAN",
  },
  {
    code: "UGN",
    name: "Memorial Intl Airport",
    Address: "Waukegan,UNITED STATES",
  },
  {
    code: "UHE",
    name: "Uherske Hradiste Kunovice Intl Airport",
    Address: "Uherske Hradiste,CZECH REPUBLIC",
  },
  {
    code: "UIH",
    name: "Qui Nhon Intl Airport",
    Address: "Qui Nhon,VIET NAM",
  },
  { code: "UII", name: "Utila Intl Airport", Address: "Utila,HONDURAS" },
  {
    code: "UIK",
    name: "Ust-Ilimsk Intl Airport",
    Address: "Ust-Ilimsk Intl Airport,RUSSIA",
  },
  { code: "UIN", name: "Baldwin Field", Address: "Quincy,UNITED STATES" },
  {
    code: "UIO",
    name: "Mariscal Intl Airport",
    Address: "Quito,ECUADOR",
  },
  {
    code: "UIP",
    name: "Pluguffan Intl Airport",
    Address: "Quimper,FRANCE",
  },
  {
    code: "UIR",
    name: "Quirindi Intl Airport",
    Address: "Quirindi,AUSTRALIA",
  },
  { code: "UKB", name: "Kobe Intl Airport", Address: "Kobe,JAPAN" },
  {
    code: "UKI",
    name: "Ukiah Intl Airport",
    Address: "Ukiah,UNITED STATES",
  },
  {
    code: "UKK",
    name: "Ust Kamenogorsk Intl Airport",
    Address: "Ust Kamenogorsk,KAZAKHSTAN",
  },
  {
    code: "UKS",
    name: "Belbek Intl Airport",
    Address: "Sevastopol,UKRAINE",
  },
  {
    code: "UKT",
    name: "Upper Bucks Intl Airport",
    Address: "Quakertown,UNITED STATES",
  },
  {
    code: "UKX",
    name: "Ust-Kut Intl Airport",
    Address: "Ust-Kut Intl Airport,RUSSIA",
  },
  { code: "UKY", name: "Kyoto Intl Airport", Address: "Kyoto,JAPAN" },
  {
    code: "ULM",
    name: "New Ulm Intl Airport",
    Address: "New Ulm,UNITED STATES",
  },
  {
    code: "ULN",
    name: "Buyant Uhaa Intl Airport",
    Address: "Ulaanbaatar,MONGOLIA",
  },
  {
    code: "ULP",
    name: "Quilpie Intl Airport",
    Address: "Quilpie,AUSTRALIA",
  },
  {
    code: "ULV",
    name: "Ulyanovsk Baratayeveka Intl Airport",
    Address: "Baratayeveka,RUSSIA",
  },
  {
    code: "ULY",
    name: "Ulyanovsk Intl Airport",
    Address: "Ulyanovsk,RUSSIA",
  },
  { code: "UME", name: "Umea Intl Airport", Address: "Umea,SWEDEN" },
  {
    code: "UMU",
    name: "Ernesto Geisel Intl Airport",
    Address: "Umuarama,BRAZIL",
  },
  { code: "UMY", name: "Sumy Intl Airport", Address: "Sumy,UKRAINE" },
  { code: "UNA", name: "Una Intl Airport", Address: "Una BR,BRAZIL" },
  {
    code: "UNI",
    name: "Union Island Intl Airport",
    Address: "Union Island,SAINT VINCENT AND THE GRENADINES",
  },
  {
    code: "UNK",
    name: "Unalakleet Intl Airport",
    Address: "Unalakleet,UNITED STATES",
  },
  {
    code: "UNN",
    name: "Ranong Intl Airport",
    Address: "Ranong,THAILAND",
  },
  {
    code: "UNT",
    name: "Baltasound Intl Airport",
    Address: "Unst,UNITED KINGDOM",
  },
  {
    code: "UOX",
    name: "University Oxford",
    Address: "University Oxford,UNITED STATES",
  },
  {
    code: "UPG",
    name: "Hasanudin Intl Airport",
    Address: "Ujung Pandang,INDONESIA",
  },
  {
    code: "UPN",
    name: "Uruapan Intl Airport",
    Address: "Uruapan,MEXICO",
  },
  {
    code: "URA",
    name: "Uralsk Intl Airport",
    Address: "Uralsk,KAZAKHSTAN",
  },
  { code: "URC", name: "Urumqi Intl Airport", Address: "Urumqi,CHINA" },
  {
    code: "URE",
    name: "Kuressaare Intl Airport",
    Address: "Kuressaare,ESTONIA",
  },
  {
    code: "URG",
    name: "Ruben Berta Intl Airport",
    Address: "Uruguaina,BRAZIL",
  },
  {
    code: "URJ",
    name: "Urai Intl Airport",
    Address: "Urai Intl Airport,RUSSIA",
  },
  { code: "URO", name: "Boos Intl Airport", Address: "Rouen,FRANCE" },
  { code: "URS", name: "Kursk Intl Airport", Address: "Kursk,RUSSIA" },
  {
    code: "URT",
    name: "Surat Thani Intl Airport",
    Address: "Surat Thani,THAILAND",
  },
  {
    code: "URY",
    name: "Gurayat Intl Airport",
    Address: "Gurayat,SAUDI ARABIA",
  },
  {
    code: "USH",
    name: "Islas Malvinas Intl Airport",
    Address: "Ushuaia,ARGENTINA",
  },
  { code: "USK", name: "Usinsk Intl Airport", Address: "Usinsk,RUSSIA" },
  {
    code: "USL",
    name: "Useless Loop Intl Airport",
    Address: "Useless Loop,AUSTRALIA",
  },
  {
    code: "USM",
    name: "Koh Samui Intl Airport",
    Address: "Koh Samui,THAILAND",
  },
  {
    code: "USN",
    name: "Ulsan Intl Airport",
    Address: "Ulsan,KOREA, REPUBLIC OF",
  },
  { code: "USQ", name: "Usak Intl Airport", Address: "Usak,TURKEY" },
  {
    code: "USS",
    name: "Sancti Spiritus Intl Airport",
    Address: "Sancti Spiritus,CUBA",
  },
  {
    code: "UST",
    name: "St Augustine Intl Airport",
    Address: "St Augustine,UNITED STATES",
  },
  {
    code: "UTA",
    name: "Mutare Intl Airport",
    Address: "Mutare,ZIMBABWE",
  },
  {
    code: "ZYU",
    name: "Utrecht Rail Station",
    Address: "Utrecht,NETHERLANDS",
  },
  {
    code: "UTH",
    name: "Udon Thani Intl Airport",
    Address: "Udon Thani,THAILAND",
  },
  {
    code: "UTN",
    name: "Upington Intl Airport",
    Address: "Upington,SOUTH AFRICA",
  },
  {
    code: "UTP",
    name: "U Tapao Intl Airport",
    Address: "U Tapao,THAILAND",
  },
  {
    code: "UTT",
    name: "Umtata Intl Airport",
    Address: "Umtata,SOUTH AFRICA",
  },
  {
    code: "UTW",
    name: "Queenstown Intl Airport",
    Address: "Queenstown,SOUTH AFRICA",
  },
  {
    code: "UUA",
    name: "Bugulma Intl Airport",
    Address: "Bugulma Intl Airport,RUSSIA",
  },
  {
    code: "UUD",
    name: "Ulan Ude Intl Airport",
    Address: "Ulan Ude,RUSSIA",
  },
  {
    code: "UUS",
    name: "Yuzhno Sakhalinsk Intl Airport",
    Address: "Yuzhno Sakhalinsk,RUSSIA",
  },
  { code: "UVA", name: "Garner Field", Address: "Uvalde,UNITED STATES" },
  {
    code: "UVF",
    name: "Hewanorra Intl Airport",
    Address: "Vieux Fort St Lucia,SAINT LUCIA",
  },
  { code: "UVL", name: "Kharga Intl Airport", Address: "Kharga,EGYPT" },
  { code: "WIE", name: "Air Base", Address: "Wiesbaden,GERMANY" },
  { code: "UYL", name: "Nyala Intl Airport", Address: "Nyala,SUDAN" },
  { code: "VAA", name: "Vaasa Intl Airport", Address: "Vaasa,FINLAND" },
  { code: "XHK", name: "Gare de Valence TGV", Address: "Valence,FRANCE" },
  {
    code: "VAF",
    name: "Chabeuil Intl Airport",
    Address: "Valence,FRANCE",
  },
  {
    code: "VAG",
    name: "Maj Brig Trompowsky Intl Airport",
    Address: "Varginha,BRAZIL",
  },
  {
    code: "VAI",
    name: "Vanimo Intl Airport",
    Address: "Vanimo,PAPUA NEW GUINEA",
  },
  { code: "VAN", name: "Van Intl Airport", Address: "Van TR,TURKEY" },
  { code: "VAR", name: "Varna", Address: "Varna,BULGARIA" },
  { code: "VAS", name: "Sivas Intl Airport", Address: "Sivas,TURKEY" },
  {
    code: "VAV",
    name: "Lupepau U Intl Airport",
    Address: "Vava U,TONGA",
  },
  { code: "VAW", name: "Vardoe Luftan", Address: "Vardoe,NORWAY" },
  { code: "VBY", name: "Visby Intl Airport", Address: "Visby,SWEDEN" },
  {
    code: "VCA",
    name: "Can Tho Intl Airport",
    Address: "Can Tho,VIET NAM",
  },
  {
    code: "VCE",
    name: "Marco Polo Intl Airport",
    Address: "Venice,ITALY",
  },
  { code: "VCL", name: "Chulai Intl Airport", Address: "Tamky,VIET NAM" },
  {
    code: "VCT",
    name: "Victoria Regional Intl Airport",
    Address: "Victoria,UNITED STATES",
  },
  {
    code: "VCV",
    name: "George AFB",
    Address: "Victorville,UNITED STATES",
  },
  { code: "VDA", name: "Ovda Intl Airport", Address: "Ovda,ISRAEL" },
  {
    code: "VDB",
    name: "Valdres Intl Airport",
    Address: "Fagernes,NORWAY",
  },
  {
    code: "VDC",
    name: "Vitoria Da Conquista Intl Airport",
    Address: "Vitoria Da Conquista,BRAZIL",
  },
  { code: "VDE", name: "Hierro Intl Airport", Address: "Valverde,SPAIN" },
  {
    code: "VDI",
    name: "Vidalia Municipal Intl Airport",
    Address: "Vidalia,UNITED STATES",
  },
  {
    code: "VDM",
    name: "Viedma Intl Airport",
    Address: "Viedma,ARGENTINA",
  },
  { code: "VDS", name: "Vadso", Address: "Vadso,NORWAY" },
  {
    code: "VDZ",
    name: "Valdez Municipal Intl Airport",
    Address: "Valdez,UNITED STATES",
  },
  { code: "VEJ", name: "Vejle Intl Airport", Address: "Vejle,DENMARK" },
  {
    code: "VEL",
    name: "Vernal Municipal",
    Address: "Vernal,UNITED STATES",
  },
  {
    code: "VER",
    name: "Las Bajadas General Heriberto Jara",
    Address: "Veracruz,MEXICO",
  },
  {
    code: "VEY",
    name: "Vestmannaeyjar Intl Airport",
    Address: "Vestmannaeyjar,ICELAND",
  },
  {
    code: "VFA",
    name: "Victoria Falls Intl Airport",
    Address: "Victoria Falls,ZIMBABWE",
  },
  {
    code: "VGA",
    name: "Vijayawada Intl Airport",
    Address: "Vijayawada,INDIA",
  },
  {
    code: "VGD",
    name: "Vologda Intl Airport",
    Address: "Vologda,RUSSIA",
  },
  { code: "VGO", name: "Vigo Intl Airport", Address: "Vigo,SPAIN" },
  {
    code: "VHM",
    name: "Vilhelmina Intl Airport",
    Address: "Vilhelmina,SWEDEN",
  },
  {
    code: "VHN",
    name: "Culberson Cty Intl Airport",
    Address: "Van Horn,UNITED STATES",
  },
  { code: "VHY", name: "Charmeil Intl Airport", Address: "Vichy,FRANCE" },
  {
    code: "VIB",
    name: "Villa Constitucion Intl Airport",
    Address: "Villa Constitucion,MEXICO",
  },
  { code: "VIC", name: "Vicenza Intl Airport", Address: "Vicenza,ITALY" },
  {
    code: "VDD",
    name: "Vienna Danubepier Intl Airport",
    Address: "Vienna,AUSTRIA",
  },
  { code: "VIE", name: "Vienna Intl Airport", Address: "Vienna,AUSTRIA" },
  { code: "VIF", name: "Vieste Intl Airport", Address: "Vieste,ITALY" },
  {
    code: "VIG",
    name: "El Vigia Intl Airport",
    Address: "El Vigia,VENEZUELA",
  },
  {
    code: "VII",
    name: "Vinh City Intl Airport",
    Address: "Vinh City,VIET NAM",
  },
  {
    code: "VIJ",
    name: "Virgin Gorda Intl Airport",
    Address: "Virgin Gorda,VIRGIN ISLANDS, BRITISH",
  },
  { code: "VIL", name: "Dakhla Intl Airport", Address: "Dakhla,MOROCCO" },
  {
    code: "VIN",
    name: "Vinnitsa Intl Airport",
    Address: "Vinnitsa,UKRAINE",
  },
  {
    code: "VIS",
    name: "Visalia Municipal",
    Address: "Visalia,UNITED STATES",
  },
  { code: "VIT", name: "Vitoria Intl Airport", Address: "Vitoria,SPAIN" },
  {
    code: "VIX",
    name: "Eurico Sales Intl Airport",
    Address: "Vitoria,BRAZIL",
  },
  {
    code: "VJI",
    name: "Virginia Highlands Intl Airport",
    Address: "Abingdon,UNITED STATES",
  },
  {
    code: "VKG",
    name: "Rach Gia Intl Airport",
    Address: "Rach Gia,VIET NAM",
  },
  {
    code: "VKS",
    name: "Vicksburg Intl Airport",
    Address: "Vicksburg,UNITED STATES",
  },
  {
    code: "VKT",
    name: "Vorkuta Intl Airport",
    Address: "Vorkuta,RUSSIA",
  },
  {
    code: "VLC",
    name: "Valencia Intl Airport",
    Address: "Valencia,SPAIN",
  },
  {
    code: "VLD",
    name: "Valdosta Regional",
    Address: "Valdosta,UNITED STATES",
  },
  {
    code: "VLG",
    name: "Villa Gesell Intl Airport",
    Address: "Villa Gesell,ARGENTINA",
  },
  {
    code: "VLI",
    name: "Bauerfield Intl Airport",
    Address: "Port Vila,VANUATU",
  },
  {
    code: "VLL",
    name: "Valladolid Intl Airport",
    Address: "Valladolid,SPAIN",
  },
  {
    code: "VLN",
    name: "Valenica Intl Airport",
    Address: "Valencia,VENEZUELA",
  },
  {
    code: "VLO",
    name: "Stolport Intl Airport",
    Address: "Vallejo,UNITED STATES",
  },
  {
    code: "VLU",
    name: "Velikiye Luki Intl Airport",
    Address: "Velikiye Luki Intl Airport,RUSSIA",
  },
  {
    code: "VLV",
    name: "Carvajal Intl Airport",
    Address: "Valera,VENEZUELA",
  },
  {
    code: "VLY",
    name: "Anglesey Intl Airport",
    Address: "Anglesey,UNITED KINGDOM",
  },
  {
    code: "VME",
    name: "Villa Mercedes Intl Airport",
    Address: "Villa Mercedes,ARGENTINA",
  },
  { code: "VMI", name: "Inc Intl Airport", Address: "Vallemi,PARAGUAY" },
  {
    code: "VNC",
    name: "Venice Municipal Intl Airport",
    Address: "Venice,UNITED STATES",
  },
  { code: "VNE", name: "Muecon Intl Airport", Address: "Vannes,FRANCE" },
  {
    code: "VNO",
    name: "Vilnius Intl Airport",
    Address: "Vilnius,LITHUANIA",
  },
  {
    code: "VNS",
    name: "Lal Bahadur Shastri Intl Airport",
    Address: "Varanasi,INDIA",
  },
  {
    code: "VNT",
    name: "Ventspils Intl Airport",
    Address: "Ventspils,LATVIA",
  },
  {
    code: "VNX",
    name: "Vilanculos Intl Airport",
    Address: "Vilanculos,MOZAMBIQUE",
  },
  {
    code: "VOG",
    name: "Volgograd Intl Airport",
    Address: "Volgograd,RUSSIA",
  },
  {
    code: "VOL",
    name: "Nea Anchialos Intl Airport",
    Address: "Volos,GREECE",
  },
  {
    code: "VOZ",
    name: "Voronezh Intl Airport",
    Address: "Voronezh,RUSSIA",
  },
  {
    code: "VPS",
    name: "Ft Walton Beach Intl Airport",
    Address: "Valparaiso,UNITED STATES",
  },
  {
    code: "EGI",
    name: "Duke Field",
    Address: "Valparaiso,UNITED STATES",
  },
  {
    code: "VPZ",
    name: "Porter County",
    Address: "Valparaiso,UNITED STATES",
  },
  {
    code: "VQS",
    name: "Vieques Intl Airport",
    Address: "Vieques,UNITED STATES",
  },
  {
    code: "VRA",
    name: "Juan Gualberto Gomez Intl Airport",
    Address: "Varadero,CUBA",
  },
  {
    code: "VRB",
    name: "Vero Beach Municipal Intl Airport",
    Address: "Vero Beach,UNITED STATES",
  },
  { code: "VRK", name: "Varkaus", Address: "Varkaus,FINLAND" },
  {
    code: "VRL",
    name: "Vila Real Intl Airport",
    Address: "Vila Real,PORTUGAL",
  },
  { code: "VRN", name: "Verona Intl Airport", Address: "Verona,ITALY" },
  {
    code: "VBS",
    name: "Montichiari Intl Airport",
    Address: "Verona,ITALY",
  },
  { code: "VRO", name: "Kawama Intl Airport", Address: "Matanzas,CUBA" },
  {
    code: "VSA",
    name: "Capt Carlos Rovirosa Perez",
    Address: "Villahermosa,MEXICO",
  },
  { code: "VSE", name: "Viseu Intl Airport", Address: "Viseu,PORTUGAL" },
  {
    code: "VSG",
    name: "Lugansk Intl Airport",
    Address: "Lugansk,UKRAINE",
  },
  { code: "XYX", name: "Sala Rail Station", Address: "Vasteras,SWEDEN" },
  {
    code: "VTB",
    name: "Vitebsk Intl Airport",
    Address: "Vitebsk,BELARUS",
  },
  {
    code: "VTE",
    name: "Wattay Intl Airport",
    Address: "Vientiane,LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  {
    code: "VTZ",
    name: "Vishakhapatnam",
    Address: "Vishakhapatanam,INDIA",
  },
  {
    code: "VUP",
    name: "Valledupar Intl Airport",
    Address: "Valleduper,COLOMBIA",
  },
  {
    code: "VUS",
    name: "Velikiy Ustyug Intl Airport",
    Address: "Velikiy Ustyug Intl Airport,RUSSIA",
  },
  {
    code: "VVO",
    name: "Vladivostok Intl Airport",
    Address: "Vladivostok,RUSSIA",
  },
  {
    code: "VXE",
    name: "San Pedro Intl Airport",
    Address: "Sao Vicente,CAPE VERDE",
  },
  { code: "VXO", name: "Vaxjo Intl Airport", Address: "Vaxjo,SWEDEN" },
  {
    code: "VYD",
    name: "Vryheid Intl Airport",
    Address: "Vryheid,SOUTH AFRICA",
  },
  {
    code: "VYS",
    name: "Illinois Valley Regional Intl Airport",
    Address: "Peru,UNITED STATES",
  },
  {
    code: "WAG",
    name: "Wanganui Intl Airport",
    Address: "Wanganui,NEW ZEALAND",
  },
  {
    code: "WAL",
    name: "Wallops Intl Airport",
    Address: "Chincoteague,UNITED STATES",
  },
  {
    code: "DCA",
    name: "Ronald Reagan National Intl Airport",
    Address: "Washington,UNITED STATES",
  },
  {
    code: "IAD",
    name: "Washington Dulles Intl ",
    Address: "Washington,UNITED STATES",
  },
  {
    code: "WAT",
    name: "Waterford Intl Airport",
    Address: "Waterford,IRELAND",
  },
  { code: "WAW", name: "Warsaw Intl Airport", Address: "Warsaw,POLAND" },
  {
    code: "WAY",
    name: "Green County Intl Airport",
    Address: "Waynesburg,UNITED STATES",
  },
  {
    code: "WAZ",
    name: "Warwick Intl Airport",
    Address: "Warwick,AUSTRALIA",
  },
  {
    code: "WBU",
    name: "Boulder Municipal Intl Airport",
    Address: "Boulder,UNITED STATES",
  },
  {
    code: "WDG",
    name: "Woodring Municipal",
    Address: "Enid,UNITED STATES",
  },
  {
    code: "WDH",
    name: "Hosea Kutako Intl Airport",
    Address: "Windhoek,NAMIBIA",
  },
  { code: "ERS", name: "Eros Intl Airport", Address: "Windhoek,NAMIBIA" },
  {
    code: "WEA",
    name: "Parker County Intl Airport",
    Address: "Weatherford,UNITED STATES",
  },
  { code: "WEF", name: "Weifang Intl Airport", Address: "Weifang,CHINA" },
  { code: "WEH", name: "Weihai Arpu", Address: "Weihai,CHINA" },
  { code: "WEI", name: "Weipa Intl Airport", Address: "Weipa,AUSTRALIA" },
  {
    code: "WEL",
    name: "Welkom Intl Airport",
    Address: "Welkom,SOUTH AFRICA",
  },
  {
    code: "WEW",
    name: "Wee Waa Intl Airport",
    Address: "Wee Waa,AUSTRALIA",
  },
  {
    code: "WEX",
    name: "Castlebridge Intl Airport",
    Address: "Wexford,IRELAND",
  },
  {
    code: "WGA",
    name: "Forrest Hill Intl Airport",
    Address: "Wagga Wagga,AUSTRALIA",
  },
  {
    code: "WGE",
    name: "Walgett Intl Airport",
    Address: "Walgett,AUSTRALIA",
  },
  {
    code: "WGO",
    name: "Winchester Intl Airport",
    Address: "Winchester,UNITED STATES",
  },
  { code: "WGT", name: "Wangaratta", Address: "Wangaratta,AUSTRALIA" },
  {
    code: "WHK",
    name: "Whakatane Intl Airport",
    Address: "Whakatane,NEW ZEALAND",
  },
  {
    code: "WHL",
    name: "Welshpool Intl Airport",
    Address: "Welshpool,AUSTRALIA",
  },
  {
    code: "WIC",
    name: "Wick Intl Airport",
    Address: "Wick,UNITED KINGDOM",
  },
  {
    code: "WJF",
    name: "Williams J Fox Intl Airport",
    Address: "Lancaster,UNITED STATES",
  },
  {
    code: "RZH",
    name: "Quartz Hill Intl Airport",
    Address: "Lancaster,UNITED STATES",
  },
  {
    code: "WKA",
    name: "Wanaka Intl Airport",
    Address: "Wanaka,NEW ZEALAND",
  },
  {
    code: "WKJ",
    name: "Hokkaido Intl Airport",
    Address: "Wakkanai,JAPAN",
  },
  {
    code: "WLD",
    name: "Arkansas City Intl Airport",
    Address: "Winfield,UNITED STATES",
  },
  {
    code: "WLG",
    name: "Wellington Intl Airport",
    Address: "Wellington,NEW ZEALAND",
  },
  {
    code: "WLK",
    name: "Selawik Intl Airport",
    Address: "Selawik,UNITED STATES",
  },
  {
    code: "WMB",
    name: "Warrnambool Intl Airport",
    Address: "Warrnambool,AUSTRALIA",
  },
  {
    code: "WMC",
    name: "Winnemucca Municipal Intl Airport",
    Address: "Winnemucca,UNITED STATES",
  },
  {
    code: "WMH",
    name: "Mountain Home Intl Airport",
    Address: "Mountain Home,UNITED STATES",
  },
  {
    code: "WMI",
    name: "Warsaw-Modlin Mazovia Intl Airport",
    Address: "Warsaw-Modlin Mazovia Intl Airport,POLAND",
  },
  { code: "WNP", name: "Naga Intl Airport", Address: "Naga,PHILIPPINES" },
  {
    code: "WNR",
    name: "Windorah Intl Airport",
    Address: "Windorah,AUSTRALIA",
  },
  {
    code: "WNS",
    name: "Nawabshah Intl Airport",
    Address: "Nawabshah,PAKISTAN",
  },
  { code: "WNZ", name: "Wenzhou Intl Airport", Address: "Wenzhou,CHINA" },
  {
    code: "WOL",
    name: "Wollongong Intl Airport",
    Address: "Wollongong,AUSTRALIA",
  },
  {
    code: "WPA",
    name: "Puerto Aisen Intl Airport",
    Address: "Puerto Aisen,CHILE",
  },
  {
    code: "WRE",
    name: "Whangarei Intl Airport",
    Address: "Whangarei,NEW ZEALAND",
  },
  {
    code: "WRG",
    name: "Wrangell Seaplane Base",
    Address: "Wrangell,UNITED STATES",
  },
  {
    code: "WRL",
    name: "Worland Municipal",
    Address: "Worland,UNITED STATES",
  },
  { code: "WRO", name: "Strachowice", Address: "Wroclaw,POLAND" },
  {
    code: "WRY",
    name: "Westray Intl Airport",
    Address: "Westray,UNITED KINGDOM",
  },
  {
    code: "WST",
    name: "Westerly Municipal",
    Address: "Westerly,UNITED STATES",
  },
  {
    code: "WSY",
    name: "Whitsunday Airstrip",
    Address: "Airlie Beach,AUSTRALIA",
  },
  {
    code: "WSZ",
    name: "Westport Intl Airport",
    Address: "Westport,NEW ZEALAND",
  },
  { code: "WUH", name: "Wuhan Intl Airport", Address: "Wuhan,CHINA" },
  {
    code: "WUN",
    name: "Wiluna Intl Airport",
    Address: "Wiluna,AUSTRALIA",
  },
  {
    code: "WUS",
    name: "Wuyishan Intl Airport",
    Address: "Wuyishan,CHINA",
  },
  { code: "WUU", name: "Wau Intl Airport", Address: "Wau City,SUDAN" },
  { code: "WUX", name: "Wuxi Intl Airport", Address: "Wuxi,CHINA" },
  {
    code: "WVB",
    name: "Rooikop Intl Airport",
    Address: "Walvis Bay,NAMIBIA",
  },
  {
    code: "WVI",
    name: "Watsonville Municipal Intl Airport",
    Address: "Watsonville,UNITED STATES",
  },
  {
    code: "WVL",
    name: "Robert La Fleur Intl Airport",
    Address: "Waterville,UNITED STATES",
  },
  {
    code: "WVN",
    name: "Wilhelmshaven Intl Airport",
    Address: "Wilhelmshaven,GERMANY",
  },
  {
    code: "WWD",
    name: "Cape May Intl Airport",
    Address: "Cape May,UNITED STATES",
  },
  {
    code: "WWK",
    name: "Boram Intl Airport",
    Address: "Wewak,PAPUA NEW GUINEA",
  },
  {
    code: "WWR",
    name: "West Woodward Intl Airport",
    Address: "Woodward,UNITED STATES",
  },
  {
    code: "WWT",
    name: "Newtok Intl Airport",
    Address: "Newtok,UNITED STATES",
  },
  {
    code: "WXF",
    name: "Wether Field Raf",
    Address: "Braintree,UNITED KINGDOM",
  },
  { code: "WXN", name: "Wanxian Intl Airport", Address: "Wanxian,CHINA" },
  {
    code: "WYA",
    name: "Whyalla Intl Airport",
    Address: "Whyalla,AUSTRALIA",
  },
  {
    code: "WYN",
    name: "Wyndham Intl Airport",
    Address: "Wyndham,AUSTRALIA",
  },
  {
    code: "WYS",
    name: "West Yellowstone Intl Airport",
    Address: "West Yellowstone,UNITED STATES",
  },
  { code: "XAL", name: "Alamos Intl Airport", Address: "Alamos,MEXICO" },
  {
    code: "XAP",
    name: "Chapeco Intl Airport",
    Address: "Chapeco,BRAZIL",
  },
  {
    code: "XAW",
    name: "Capreol Rail Station",
    Address: "Capreol,CANADA",
  },
  {
    code: "XAZ",
    name: "Campbellton Rail Station",
    Address: "Campbellton,CANADA",
  },
  {
    code: "XBR",
    name: "Brockville Intl Airport",
    Address: "Brockville,CANADA",
  },
  {
    code: "XBW",
    name: "Killineq Intl Airport",
    Address: "Killineq,CANADA",
  },
  {
    code: "XCH",
    name: "Christmas Island Intl Airport",
    Address: "Christmas Island,CHRISTMAS ISLAND",
  },
  {
    code: "XCI",
    name: "Chambord Rail Station",
    Address: "Chambord,CANADA",
  },
  {
    code: "XCM",
    name: "Chatham Intl Airport",
    Address: "Chatham,CANADA",
  },
  { code: "XCO", name: "Colac Intl Airport", Address: "Colac,AUSTRALIA" },
  {
    code: "XDK",
    name: "Dunkerque Intl Airport",
    Address: "Dunkerque,FRANCE",
  },
  {
    code: "XDL",
    name: "Chandler Rail Station",
    Address: "Chandler,CANADA",
  },
  {
    code: "XDM",
    name: "Drummondville Rail Station",
    Address: "Drummondville,CANADA",
  },
  {
    code: "XDO",
    name: "Grande-Riviere Rail Station",
    Address: "Grande Riviere,CANADA",
  },
  { code: "XDU", name: "Hervey Rail Station", Address: "Hervey,CANADA" },
  {
    code: "XED",
    name: "Railway Service",
    Address: "Disneyland Paris Rail,FRANCE",
  },
  {
    code: "XEE",
    name: "Lac Edouard Rail Station",
    Address: "Lac Edouard,CANADA",
  },
  {
    code: "XEH",
    name: "Ladysmith Rail Station",
    Address: "Ladysmith,CANADA",
  },
  {
    code: "XEJ",
    name: "Langford Rail Station",
    Address: "Langford,CANADA",
  },
  {
    code: "XEK",
    name: "Melville Rail Station",
    Address: "Melville,CANADA",
  },
  {
    code: "XEL",
    name: "New Carlisle Rail Station",
    Address: "New Carlisle,CANADA",
  },
  {
    code: "XEM",
    name: "New Richmond Rail Station",
    Address: "New Richmond,CANADA",
  },
  {
    code: "XFD",
    name: "Stratford Rail Station",
    Address: "Stratford,CANADA",
  },
  { code: "XFE", name: "Parent Rail Station", Address: "Parent,CANADA" },
  { code: "XFG", name: "Perce Rail Station", Address: "Perce,CANADA" },
  {
    code: "XFI",
    name: "Port-Daniel Rail Station",
    Address: "Port Daniel,CANADA",
  },
  {
    code: "XFK",
    name: "Senneterre Rail Station",
    Address: "Senneterre,CANADA",
  },
  {
    code: "XFL",
    name: "Shawinigan Rail Station",
    Address: "Shawinigan,CANADA",
  },
  {
    code: "XFM",
    name: "Shawnigan Rail Station",
    Address: "Shawnigan,CANADA",
  },
  {
    code: "XFN",
    name: "Xiangfan Intl Airport",
    Address: "Xiangfan,CHINA",
  },
  {
    code: "XFO",
    name: "Taschereau Rail Station",
    Address: "Taschereau,CANADA",
  },
  {
    code: "XFQ",
    name: "Weymont Rail Station",
    Address: "Weymont,CANADA",
  },
  {
    code: "XFS",
    name: "Alexandria Rail Station",
    Address: "Alexandria,CANADA",
  },
  {
    code: "XFV",
    name: "Brantford Rail Station",
    Address: "Brantford,CANADA",
  },
  { code: "XGC", name: "Lund C Rail Station", Address: "Lund C,SWEDEN" },
  {
    code: "XGJ",
    name: "Cobourg Rail Station",
    Address: "Cobourg,CANADA",
  },
  { code: "XGK", name: "Coteau Rail Station", Address: "Coteau,CANADA" },
  {
    code: "XGR",
    name: "Kangiqsualujjuaq Intl Airport",
    Address: "Kangiqsualujjuaq,CANADA",
  },
  {
    code: "XGW",
    name: "Gananoque Rail Station",
    Address: "Gananoque,CANADA",
  },
  {
    code: "XGY",
    name: "Grimsby Rail Station",
    Address: "Grimsby,CANADA",
  },
  {
    code: "XHF",
    name: "Honefoss Rail Station",
    Address: "Honefoss,NORWAY",
  },
  {
    code: "XHM",
    name: "Georgetown Rail Station",
    Address: "Georgetown,CANADA",
  },
  { code: "XHQ", name: "Hopa Artvin", Address: "Hopa,TURKEY" },
  {
    code: "XHS",
    name: "Chemainus Rail Station",
    Address: "Chemainus,CANADA",
  },
  { code: "XIA", name: "Guelph Rail Station", Address: "Guelph,CANADA" },
  {
    code: "XIB",
    name: "Ingersoll Rail Station",
    Address: "Ingersoll,CANADA",
  },
  { code: "XIC", name: "Xichang Intl Airport", Address: "Xichang,CHINA" },
  {
    code: "XID",
    name: "Maxville Rail Station",
    Address: "Maxville,CANADA",
  },
  {
    code: "XIF",
    name: "Napanee Rail Station",
    Address: "Napanee,CANADA",
  },
  {
    code: "XII",
    name: "Prescott Rail Station",
    Address: "Prescott,CANADA",
  },
  {
    code: "XIM",
    name: "Saint Hyacinthe Rail Station",
    Address: "Saint Hyacinthe,CANADA",
  },
  {
    code: "XIO",
    name: "St Marys Rail Station",
    Address: "St Marys,CANADA",
  },
  {
    code: "XIP",
    name: "Woodstock Rail Station",
    Address: "Woodstock,CANADA",
  },
  {
    code: "XJL",
    name: "Joliette Rail Station",
    Address: "Joliette,CANADA",
  },
  {
    code: "XJQ",
    name: "Jonquiere Rail Station",
    Address: "Jonquiere,CANADA",
  },
  {
    code: "XKH",
    name: "Xieng Khouang Intl Airport",
    Address: "Xieng Khouang,LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  {
    code: "XKJ",
    name: "Steinkjer Rail Station",
    Address: "Steinkjer,NORWAY",
  },
  {
    code: "XKG",
    name: "Grong Rail Station",
    Address: "Steinkjer,NORWAY",
  },
  {
    code: "XKV",
    name: "Sackville Rail Station",
    Address: "Sackville,CANADA",
  },
  {
    code: "XLP",
    name: "Matapedia Rail Station",
    Address: "Matapedia,CANADA",
  },
  {
    code: "XLV",
    name: "Niagara Falls Rail Station",
    Address: "Niagara Falls,CANADA",
  },
  {
    code: "XLY",
    name: "Aldershot Rail Station",
    Address: "Aldershot,CANADA",
  },
  { code: "XLZ", name: "Truro Rail Station", Address: "Truro,CANADA" },
  {
    code: "XMB",
    name: "M'Bahiakro Intl Airport",
    Address: "M'Bahiakro,COTE D'IVOIRE",
  },
  { code: "XMN", name: "Xiamen Intl Airport", Address: "Xiamen,CHINA" },
  {
    code: "XMY",
    name: "Yam Island Intl Airport",
    Address: "Yam Island,AUSTRALIA",
  },
  {
    code: "XNM",
    name: "Nottingham Rail Station",
    Address: "Nottingham,UNITED KINGDOM",
  },
  { code: "XNN", name: "Xining Intl Airport", Address: "Xining,CHINA" },
  { code: "XNT", name: "Xingtai Intl Airport", Address: "Xingtai,CHINA" },
  {
    code: "XOK",
    name: "Oakville Rail Station",
    Address: "Oakville,CANADA",
  },
  {
    code: "XON",
    name: "Carleton Rail Station",
    Address: "Carleton,CANADA",
  },
  { code: "XOR", name: "Otta Rail Station", Address: "Otta,NORWAY" },
  {
    code: "XPB",
    name: "Parksville Rail Station",
    Address: "Parksville,CANADA",
  },
  {
    code: "XPH",
    name: "Port Hope Rail Station",
    Address: "Port Hope,CANADA",
  },
  {
    code: "XPK",
    name: "Pukatawagan Rail Station",
    Address: "Pukatawagan,CANADA",
  },
  {
    code: "XPL",
    name: "Palmerola Air Base",
    Address: "Comayagua,HONDURAS",
  },
  {
    code: "XPN",
    name: "Brampton Rail Station",
    Address: "Brampton,CANADA",
  },
  {
    code: "XPT",
    name: "Preston Rail Station",
    Address: "Preston,UNITED KINGDOM",
  },
  {
    code: "XPX",
    name: "Pointe-aux-Trembles Rail Station",
    Address: "Pointe Aux Trembles,CANADA",
  },
  {
    code: "XQG",
    name: "Berwick Rail Station",
    Address: "Berwick Upon Tweed,UNITED KINGDOM",
  },
  {
    code: "XQP",
    name: "Quepos Intl Airport",
    Address: "Quepos,COSTA RICA",
  },
  {
    code: "XQU",
    name: "Qualicum Intl Airport",
    Address: "Qualicum,CANADA",
  },
  {
    code: "XRD",
    name: "Rail Station Egersund",
    Address: "Egersund,NORWAY",
  },
  {
    code: "XRG",
    name: "Rugeley Rail Station",
    Address: "Rugeley,UNITED KINGDOM",
  },
  {
    code: "XRP",
    name: "Pine Ridge Rail Station",
    Address: "Riviere A Pierre,CANADA",
  },
  {
    code: "XRU",
    name: "Rugby Rail Station",
    Address: "Rugby,UNITED KINGDOM",
  },
  {
    code: "XRY",
    name: "La Parra Intl Airport",
    Address: "Jerez De La Frontera,SPAIN",
  },
  {
    code: "XSC",
    name: "South Caicos Intl Airport",
    Address: "South Caicos,TURKS AND CAICOS ISLANDS",
  },
  {
    code: "XSR",
    name: "Salisbury Rail Station",
    Address: "Salisbury,UNITED KINGDOM",
  },
  {
    code: "XSS",
    name: "Soissons Railway Station",
    Address: "Soissons,FRANCE",
  },
  {
    code: "XTG",
    name: "Thargomindah Intl Airport",
    Address: "Thargomindah,AUSTRALIA",
  },
  {
    code: "XTY",
    name: "Strathroy Rail Station",
    Address: "Strathroy,CANADA",
  },
  {
    code: "XUG",
    name: "Holmestrand Railway",
    Address: "Holmestrand,NORWAY",
  },
  { code: "XUZ", name: "Xuzhou Intl Airport", Address: "Xuzhou,CHINA" },
  {
    code: "XVA",
    name: "Stockport Rail Station",
    Address: "Stockport,UNITED KINGDOM",
  },
  {
    code: "XVH",
    name: "Peterborough Rail Station",
    Address: "Peterborough,UNITED KINGDOM",
  },
  {
    code: "XVJ",
    name: "Stevenage Rail Station",
    Address: "Stevenage,UNITED KINGDOM",
  },
  {
    code: "XVV",
    name: "Belleville Rail Station",
    Address: "Belleville,CANADA",
  },
  {
    code: "XVW",
    name: "Belleville Rail Station",
    Address: "Wolverhampton,UNITED KINGDOM",
  },
  {
    code: "XWA",
    name: "Watford Rail Station",
    Address: "Watford,CANADA",
  },
  {
    code: "XWD",
    name: "Wakefield Westgate Rail Station",
    Address: "Wakefield Westgate,UNITED KINGDOM",
  },
  { code: "XWF", name: "Falun Railway Service", Address: "Falun,SWEDEN" },
  { code: "XJF", name: "Falun Bus Service", Address: "Falun,SWEDEN" },
  {
    code: "XWH",
    name: "Stroke on Trent Rail Station",
    Address: "Stoke On Trent,UNITED KINGDOM",
  },
  {
    code: "XWV",
    name: "Varberg Rail Station",
    Address: "Varberg,SWEDEN",
  },
  {
    code: "XWY",
    name: "Wyoming Rail Station",
    Address: "Wyoming,CANADA",
  },
  {
    code: "XXA",
    name: "Alvesta Railway Service",
    Address: "Alvesta,SWEDEN",
  },
  {
    code: "XXD",
    name: "Degerfors Rail Station",
    Address: "Degerfors,SWEDEN",
  },
  {
    code: "XXL",
    name: "Lillehammer Rail Station",
    Address: "Lille Hammer,NORWAY",
  },
  { code: "XXM", name: "Mjolby Rail Station", Address: "Mjolby,SWEDEN" },
  {
    code: "XYN",
    name: "Kristinehamn Rail Station",
    Address: "Kristinehamn,SWEDEN",
  },
  {
    code: "XYP",
    name: "Railway Service",
    Address: "Avesta Krylbo,SWEDEN",
  },
  { code: "XYY", name: "Arvika Rail Station", Address: "Arvika,SWEDEN" },
  {
    code: "XYZ",
    name: "Harnosand Rail Station",
    Address: "Harnosand,SWEDEN",
  },
  {
    code: "XZB",
    name: "Casselman Rail Station",
    Address: "Casselman,CANADA",
  },
  {
    code: "XZC",
    name: "Glencoe Rail Station",
    Address: "Glencoe,CANADA",
  },
  {
    code: "XZD",
    name: "Kongsvinger Rail Station",
    Address: "Kongsvinger,NORWAY",
  },
  { code: "XZJ", name: "Off Line Point", Address: "Rail,JAPAN" },
  {
    code: "XZK",
    name: "Amherst Rail Station",
    Address: "Amherst,CANADA",
  },
  { code: "XZP", name: "Off Line Point", Address: "Pass,CANADA" },
  {
    code: "YAG",
    name: "Fort Frances Municipal",
    Address: "Fort Frances,CANADA",
  },
  {
    code: "YAK",
    name: "Yakutat Intl Airport",
    Address: "Yakutat,UNITED STATES",
  },
  {
    code: "YAM",
    name: "Sault Ste Marie Intl Airport",
    Address: "Sault Ste Marie,CANADA",
  },
  {
    code: "NSI",
    name: "Nsimalen Intl Airport",
    Address: "Yaounde,CAMEROON",
  },
  {
    code: "YAO",
    name: "Yaounde Intl Airport",
    Address: "Yaounde,CAMEROON",
  },
  {
    code: "YAP",
    name: "Yap Intl Airport",
    Address: "Yap Fm,MICRONESIA, FEDERATED STATES OF",
  },
  {
    code: "YAT",
    name: "Attawapiskat Intl Airport",
    Address: "Attawapiskat,CANADA",
  },
  {
    code: "YAY",
    name: "St Anthony Intl Airport",
    Address: "St Anthony,CANADA",
  },
  { code: "YAZ", name: "Tofino Intl Airport", Address: "Tofino,CANADA" },
  { code: "YBA", name: "Banff Intl Airport", Address: "Banff,CANADA" },
  {
    code: "YBB",
    name: "Kugaaruk Intl Airport",
    Address: "Pelly Bay,CANADA",
  },
  {
    code: "YBC",
    name: "Baie Comeau Intl Airport",
    Address: "Baie Comeau,CANADA",
  },
  {
    code: "YBD",
    name: "New Westminster Rail Station",
    Address: "New Westminster,CANADA",
  },
  {
    code: "YBG",
    name: "Bagotville Intl Airport",
    Address: "Bagotville,CANADA",
  },
  {
    code: "YBK",
    name: "Baker Lake Intl Airport",
    Address: "Baker Lake,CANADA",
  },
  {
    code: "YBL",
    name: "Campbell River Municipal",
    Address: "Campbell River,CANADA",
  },
  {
    code: "YHH",
    name: "Harbor Intl Airport",
    Address: "Campbell River,CANADA",
  },
  { code: "YBP", name: "Yibin Intl Airport", Address: "Yibin,CHINA" },
  {
    code: "YBR",
    name: "Brandon Municipal Intl Airport",
    Address: "Brandon,CANADA",
  },
  {
    code: "YBW",
    name: "Bedwell Harbor Intl Airport",
    Address: "Bedwell Harbor,CANADA",
  },
  {
    code: "YBX",
    name: "Blanc Sablon Intl Airport",
    Address: "Blanc Sablon,CANADA",
  },
  {
    code: "YCA",
    name: "Courtenay Rail Station",
    Address: "Courtenay,CANADA",
  },
  {
    code: "YCB",
    name: "Cambridge Bay Intl Airport",
    Address: "Cambridge Bay,CANADA",
  },
  {
    code: "YCC",
    name: "Cornwall Regional Intl Airport",
    Address: "Cornwall,CANADA",
  },
  {
    code: "ZNA",
    name: "Harbour Seaplane Base",
    Address: "Nanaimo,CANADA",
  },
  {
    code: "YCD",
    name: "Nanaimo Intl Airport",
    Address: "Nanaimo,CANADA",
  },
  {
    code: "YCG",
    name: "Ralph West Intl Airport",
    Address: "Castlegar,CANADA",
  },
  {
    code: "XEY",
    name: "Newcastle Rail Station",
    Address: "Miramichi,CANADA",
  },
  {
    code: "YCH",
    name: "Miramichi Intl Airport",
    Address: "Miramichi,CANADA",
  },
  { code: "YCK", name: "Colville Municipal", Address: "Colville,CANADA" },
  {
    code: "YCL",
    name: "Charlo Municipal Intl Airport",
    Address: "Charlo,CANADA",
  },
  {
    code: "YCM",
    name: "St Catharines Rail Station",
    Address: "St Catherines,CANADA",
  },
  {
    code: "YCN",
    name: "Cochrane Rail Station",
    Address: "Cochrane,CANADA",
  },
  {
    code: "YCO",
    name: "Kugluktuk Intl Airport",
    Address: "Kugluktuk Coppermine,CANADA",
  },
  {
    code: "YCS",
    name: "Chesterfield Inlet Intl Airport",
    Address: "Chesterfield Inlet,CANADA",
  },
  {
    code: "YCU",
    name: "Yun Cheng Intl Airport",
    Address: "Yun Cheng,CHINA",
  },
  {
    code: "YCW",
    name: "Chilliwack Intl Airport",
    Address: "Chilliwack,CANADA",
  },
  { code: "YCY", name: "Clyde River", Address: "Clyde River,CANADA" },
  {
    code: "YCZ",
    name: "Fairmont Springs Intl Airport",
    Address: "Fairmont Springs,CANADA",
  },
  {
    code: "YDA",
    name: "Dawson City Intl Airport",
    Address: "Dawson City,CANADA",
  },
  {
    code: "YDF",
    name: "Deer Lake Municipal",
    Address: "Deer Lake,CANADA",
  },
  {
    code: "YDL",
    name: "Dease Lake Intl Airport",
    Address: "Dease Lake,CANADA",
  },
  {
    code: "YDN",
    name: "Dauphin Rail Station",
    Address: "Dauphin,CANADA",
  },
  { code: "YDP", name: "Nain Intl Airport", Address: "Nain,CANADA" },
  {
    code: "YDQ",
    name: "Dawson Creek Intl Airport",
    Address: "Dawson Creek,CANADA",
  },
  {
    code: "YXD",
    name: "Edmonton Municipal Intl Airport",
    Address: "Edmonton,CANADA",
  },
  { code: "YED", name: "Namao Field", Address: "Edmonton,CANADA" },
  {
    code: "YEG",
    name: "Edmonton Intl Airport",
    Address: "Edmonton,CANADA",
  },
  {
    code: "XZL",
    name: "Edmonton Rail Station",
    Address: "Edmonton,CANADA",
  },
  {
    code: "YEB",
    name: "Bar River Intl Airport",
    Address: "Bar River,CANADA",
  },
  {
    code: "YEH",
    name: "Asaloyeh Intl Airport",
    Address: "Asaloyeh,IRAN",
  },
  { code: "YEK", name: "Arviat Intl Airport", Address: "Arviat,CANADA" },
  {
    code: "YEL",
    name: "Elliot Lake Intl Airport",
    Address: "Elliot Lake,CANADA",
  },
  {
    code: "YEN",
    name: "Estevan Intl Airport",
    Address: "Estevan,CANADA",
  },
  {
    code: "YER",
    name: "Fort Severn Intl Airport",
    Address: "Fort Severn,CANADA",
  },
  { code: "YET", name: "Edson Intl Airport", Address: "Edson,CANADA" },
  {
    code: "YEV",
    name: "Inuvik Mike Zubko Intl Airport",
    Address: "Inuvik,CANADA",
  },
  { code: "YEY", name: "Amos Rail Station", Address: "Amos,CANADA" },
  {
    code: "YFB",
    name: "Iqaluit Intl Airport",
    Address: "Iqaluit,CANADA",
  },
  {
    code: "YFC",
    name: "Fredericton Municipal",
    Address: "Fredericton,CANADA",
  },
  {
    code: "YFO",
    name: "Flin Flon Municipal Intl Airport",
    Address: "Flin Flon,CANADA",
  },
  {
    code: "YFX",
    name: "Fox Harbour Intl Airport",
    Address: "Fox Harbour,CANADA",
  },
  { code: "YGJ", name: "Miho Intl Airport", Address: "Yonago,JAPAN" },
  {
    code: "YGK",
    name: "Norman Rodgers Intl Airport",
    Address: "Kingston,CANADA",
  },
  {
    code: "XEG",
    name: "Kingston Rail Station",
    Address: "Kingston,CANADA",
  },
  {
    code: "YGL",
    name: "La Grande Municipal Intl Airport",
    Address: "La Grande,CANADA",
  },
  {
    code: "YGP",
    name: "Gaspe Municipal Intl Airport",
    Address: "Gaspe,CANADA",
  },
  { code: "XDD", name: "Gaspe Rail Station", Address: "Gaspe,CANADA" },
  {
    code: "YGR",
    name: "House Harbour Intl Airport",
    Address: "Iles De Madeleine,CANADA",
  },
  {
    code: "YGT",
    name: "Igloolik Intl Airport",
    Address: "Igloolik,CANADA",
  },
  {
    code: "YGV",
    name: "Havre St Pierre Municipal Intl Airport",
    Address: "Havre St Pierre,CANADA",
  },
  {
    code: "YGW",
    name: "Kuujjuaraapik Intl Airport",
    Address: "Poste De La Baleine,CANADA",
  },
  { code: "YGX", name: "Gillam Rail Station", Address: "Gillam,CANADA" },
  {
    code: "YHA",
    name: "Port Hope Simpson Intl Airport",
    Address: "Port Hope Simpson,CANADA",
  },
  {
    code: "YHB",
    name: "Hudson Bay Rail Station",
    Address: "Hudson Bay,CANADA",
  },
  { code: "YHD", name: "Dryden Municipal", Address: "Dryden,CANADA" },
  { code: "YHE", name: "Hope Intl Airport", Address: "Hope,CANADA" },
  {
    code: "YHF",
    name: "Hearst Municipal Intl Airport",
    Address: "Hearst,CANADA",
  },
  {
    code: "YHG",
    name: "Charlottetown Municipal Intl Airport",
    Address: "Charlottetown,CANADA",
  },
  {
    code: "YHK",
    name: "Gjoa Haven Intl Airport",
    Address: "Gjoa Haven,CANADA",
  },
  { code: "YHM", name: "Civic Intl Airport", Address: "Hamilton,CANADA" },
  {
    code: "YHN",
    name: "Hornepayne Rail Station",
    Address: "Hornepayne,CANADA",
  },
  {
    code: "YHO",
    name: "Hopedale Intl Airport",
    Address: "Hopedale,CANADA",
  },
  {
    code: "YHR",
    name: "Chevery Intl Airport",
    Address: "Chevery,CANADA",
  },
  {
    code: "YHS",
    name: "Sechelt Intl Airport",
    Address: "Sechelt,CANADA",
  },
  {
    code: "YHY",
    name: "Hay River Municipal Intl Airport",
    Address: "Hay River,CANADA",
  },
  { code: "YHZ", name: "Halifax Intl ", Address: "Halifax,CANADA" },
  {
    code: "XDG",
    name: "Halifax Rail Station",
    Address: "Halifax,CANADA",
  },
  {
    code: "YIB",
    name: "Atikokan Municipal Intl Airport",
    Address: "Atikokan,CANADA",
  },
  {
    code: "YIF",
    name: "Pakuashipi Intl Airport",
    Address: "Pakuashipi,CANADA",
  },
  { code: "YIH", name: "Yichang Intl Airport", Address: "Yichang,CHINA" },
  {
    code: "YIK",
    name: "Ivujivik Intl Airport",
    Address: "Ivujivik,CANADA",
  },
  {
    code: "YIO",
    name: "Pond Inlet Intl Airport",
    Address: "Pond Inlet,CANADA",
  },
  { code: "YIW", name: "Yiwu Intl Airport", Address: "Yiwu,CHINA" },
  { code: "YJA", name: "Jasper Intl Airport", Address: "Jasper,CANADA" },
  { code: "XDH", name: "Jasper Rail Station", Address: "Jasper,CANADA" },
  {
    code: "YJT",
    name: "Stephenville Municipal",
    Address: "Stephenville,CANADA",
  },
  {
    code: "YKA",
    name: "Davie Fulton Intl Airport",
    Address: "Kamloops,CANADA",
  },
  {
    code: "YKF",
    name: "Kitchener Waterloo Regional",
    Address: "Kitchener,CANADA",
  },
  {
    code: "YKG",
    name: "Kangirsuk Intl Airport",
    Address: "Kangirsuk,CANADA",
  },
  {
    code: "YKL",
    name: "Schefferville Intl Airport",
    Address: "Schefferville,CANADA",
  },
  {
    code: "YKM",
    name: "Yakima Terminal Intl Airport",
    Address: "Yakima,UNITED STATES",
  },
  {
    code: "YKN",
    name: "Chan Gurney Municipal",
    Address: "Yankton,UNITED STATES",
  },
  {
    code: "YKQ",
    name: "Waskaganish Intl Airport",
    Address: "Waskaganish,CANADA",
  },
  {
    code: "YKS",
    name: "Yakutsk Intl Airport",
    Address: "Yakutsk,RUSSIA",
  },
  {
    code: "YKU",
    name: "Chisasibi Intl Airport",
    Address: "Chisasibi,CANADA",
  },
  {
    code: "YKX",
    name: "Kirkland Lake Municipal Intl Airport",
    Address: "Kirkland,CANADA",
  },
  {
    code: "YLC",
    name: "Kimmirut Lake Harbour Intl Airport",
    Address: "Kimmirut Lake Harbour,CANADA",
  },
  {
    code: "YLD",
    name: "Chapleau Rail Station",
    Address: "Chapleau,CANADA",
  },
  {
    code: "YLI",
    name: "Ylivieska Intl Airport",
    Address: "Ylivieska,FINLAND",
  },
  {
    code: "YLJ",
    name: "Meadow Lake Intl Airport",
    Address: "Meadow Lake,CANADA",
  },
  {
    code: "YLL",
    name: "Lloydminster Intl Airport",
    Address: "Lloydminster,CANADA",
  },
  {
    code: "YLQ",
    name: "La Tuque Rail Station",
    Address: "La Tuque,CANADA",
  },
  { code: "YLW", name: "Ellison Field", Address: "Kelowna,CANADA" },
  {
    code: "YLY",
    name: "Langley Regional Intl Airport",
    Address: "Langley,CANADA",
  },
  {
    code: "YMB",
    name: "Merritt Intl Airport",
    Address: "Merritt,CANADA",
  },
  { code: "YME", name: "Matane Intl Airport", Address: "Matane,CANADA" },
  {
    code: "YMG",
    name: "Manitouwadge Municipal Intl Airport",
    Address: "Manitouwadge,CANADA",
  },
  {
    code: "YMH",
    name: "Marys Harbour Intl Airport",
    Address: "Marys Harbour,CANADA",
  },
  { code: "YMI", name: "Minaki Rail Station", Address: "Minaki,CANADA" },
  {
    code: "YMM",
    name: "Ft McMurray Municipal Intl Airport",
    Address: "Ft McMurray,CANADA",
  },
  {
    code: "YMN",
    name: "Makkovik Intl Airport",
    Address: "Makkovik,CANADA",
  },
  {
    code: "YMO",
    name: "Moosonee Intl Airport",
    Address: "Moosonee,CANADA",
  },
  {
    code: "XLM",
    name: "Saint Lambert Rail Station",
    Address: "Montreal,CANADA",
  },
  {
    code: "YMX",
    name: "Mirabel Intl Airport",
    Address: "Montreal,CANADA",
  },
  {
    code: "YMY",
    name: "Montreal Downtown Rail Station",
    Address: "Montreal,CANADA",
  },
  {
    code: "YHU",
    name: "St Hubert Intl Airport",
    Address: "Montreal,CANADA",
  },
  {
    code: "XAX",
    name: "Dorval Rail Station",
    Address: "Montreal,CANADA",
  },
  {
    code: "YUL",
    name: "Pierre Elliott Trudeau Intl Airport",
    Address: "Montreal,CANADA",
  },
  {
    code: "YMT",
    name: "Chibougamau Intl Airport",
    Address: "Chibougamau,CANADA",
  },
  {
    code: "YNA",
    name: "Natashquan Intl Airport",
    Address: "Natashquan,CANADA",
  },
  {
    code: "YNB",
    name: "Yanbo Intl Airport",
    Address: "Yanbo,SAUDI ARABIA",
  },
  {
    code: "YND",
    name: "Gatineau Hull Municipal Intl Airport",
    Address: "Gatineau Hull,CANADA",
  },
  {
    code: "YNG",
    name: "Youngstown Municipal",
    Address: "Youngstown,UNITED STATES",
  },
  { code: "YNJ", name: "Yanji Intl Airport", Address: "Yanji,CHINA" },
  {
    code: "YNN",
    name: "Yandi Intl Airport",
    Address: "Yandicoogina,AUSTRALIA",
  },
  {
    code: "YNP",
    name: "Natuashish Intl Airport",
    Address: "Natuashish,CANADA",
  },
  { code: "YNT", name: "Laishan Intl Airport", Address: "Yantai,CHINA" },
  {
    code: "YNZ",
    name: "Yancheng Intl Airport",
    Address: "Yancheng,CHINA",
  },
  {
    code: "YOD",
    name: "Cold Lake Intl Airport",
    Address: "Cold Lake,CANADA",
  },
  {
    code: "YOJ",
    name: "Footner Lake Intl Airport",
    Address: "High Level,CANADA",
  },
  {
    code: "YOO",
    name: "Oshawa Municipal Intl Airport",
    Address: "Oshawa,CANADA",
  },
  {
    code: "YOP",
    name: "Rainbow Lake Intl Airport",
    Address: "Rainbow Lake,CANADA",
  },
  {
    code: "YOS",
    name: "Billy Bishop Regional Intl Airport",
    Address: "Owen Sound,CANADA",
  },
  {
    code: "YOT",
    name: "Yotvata Intl Airport",
    Address: "Yotvata,ISRAEL",
  },
  { code: "YOW", name: "Ottawa Intl Airport", Address: "Ottawa,CANADA" },
  { code: "XDS", name: "Ottawa Rail Station", Address: "Ottawa,CANADA" },
  {
    code: "YPA",
    name: "Prince Albert Municipal Intl Airport",
    Address: "Prince Albert,CANADA",
  },
  {
    code: "YPB",
    name: "Port Alberni Intl Airport",
    Address: "Port Alberni,CANADA",
  },
  {
    code: "YPD",
    name: "Parry Sound Municipal Intl Airport",
    Address: "Parry Sound,CANADA",
  },
  {
    code: "YPE",
    name: "Municipal Peace River",
    Address: "Peace River,CANADA",
  },
  {
    code: "YPF",
    name: "Esquimalt Rail Station",
    Address: "Esquimalt,CANADA",
  },
  {
    code: "YPG",
    name: "Portage La Prairie Rail Station",
    Address: "Portage La Prairie,CANADA",
  },
  {
    code: "YPH",
    name: "Inukjuak Intl Airport",
    Address: "Inukjuak,CANADA",
  },
  {
    code: "YPJ",
    name: "Aupaluk Intl Airport",
    Address: "Aupaluk,CANADA",
  },
  {
    code: "YPL",
    name: "Pickle Lake Intl Airport",
    Address: "Pickle Lake,CANADA",
  },
  {
    code: "YPM",
    name: "Pikangikum Intl Airport",
    Address: "St Pierre,CANADA",
  },
  {
    code: "YPN",
    name: "Port Menier Intl Airport",
    Address: "Port Menier,CANADA",
  },
  {
    code: "YPQ",
    name: "Peterborough Intl Airport",
    Address: "Peterborough,CANADA",
  },
  {
    code: "YPR",
    name: "Digby Island Intl Airport",
    Address: "Prince Rupert,CANADA",
  },
  {
    code: "XDW",
    name: "Prince Rupert Rail Station",
    Address: "Prince Rupert,CANADA",
  },
  {
    code: "ZSW",
    name: "Seal Cove Intl Airport",
    Address: "Prince Rupert,CANADA",
  },
  {
    code: "YPS",
    name: "Port Hawkesbury Intl Airport",
    Address: "Port Hawkesbury,CANADA",
  },
  {
    code: "YPW",
    name: "Westview Intl Airport",
    Address: "Powell River,CANADA",
  },
  {
    code: "YPX",
    name: "Povungnituk Intl Airport",
    Address: "Puvirnituq,CANADA",
  },
  {
    code: "YPZ",
    name: "Burns Lake Rail Station",
    Address: "Burns Lake,CANADA",
  },
  {
    code: "YQA",
    name: "Muskoka Intl Airport",
    Address: "Muskoka,CANADA",
  },
  {
    code: "XLJ",
    name: "Quebec City Rail Station",
    Address: "Quebec,CANADA",
  },
  { code: "XFZ", name: "Charny Rail Station", Address: "Quebec,CANADA" },
  { code: "YQB", name: "Quebec Intl ", Address: "Quebec,CANADA" },
  {
    code: "XFY",
    name: "Sainte-Foy Rail Station",
    Address: "Quebec,CANADA",
  },
  { code: "XLK", name: "Levis Rail Station", Address: "Quebec,CANADA" },
  {
    code: "YQC",
    name: "Quaqtaq Intl Airport",
    Address: "Quaqtaq,CANADA",
  },
  {
    code: "XDZ",
    name: "The Pas Rail Station",
    Address: "The Pas,CANADA",
  },
  {
    code: "YQD",
    name: "The Pas Municipal Intl Airport",
    Address: "The Pas,CANADA",
  },
  {
    code: "XEC",
    name: "Windsor Rail Station",
    Address: "Windsor,CANADA",
  },
  {
    code: "YQG",
    name: "Windsor Intl Airport",
    Address: "Windsor,CANADA",
  },
  {
    code: "YQH",
    name: "Watson Lake Intl Airport",
    Address: "Watson Lake,CANADA",
  },
  { code: "YQI", name: "Municipal Yarmouth", Address: "Yarmouth,CANADA" },
  { code: "YQK", name: "Kenora Intl Airport", Address: "Kenora,CANADA" },
  {
    code: "YQL",
    name: "Lethbridge Intl Airport",
    Address: "Lethbridge,CANADA",
  },
  { code: "YQM", name: "Moncton Municipal", Address: "Moncton,CANADA" },
  {
    code: "XDP",
    name: "Moncton Rail Station",
    Address: "Moncton,CANADA",
  },
  { code: "YQN", name: "Nakina Rail Station", Address: "Nakina,CANADA" },
  {
    code: "YQQ",
    name: "Royal Canadian Air Force Station",
    Address: "Comox,CANADA",
  },
  { code: "YQR", name: "Regina Municipal", Address: "Regina,CANADA" },
  {
    code: "YMJ",
    name: "Moose Jaw Intl Airport",
    Address: "Regina,CANADA",
  },
  {
    code: "YQS",
    name: "Pembroke Area Municipal Intl Airport",
    Address: "St Thomas,CANADA",
  },
  {
    code: "YQT",
    name: "Thunder Bay Intl Airport",
    Address: "Thunder Bay,CANADA",
  },
  {
    code: "YQU",
    name: "Grande Prairie Intl Airport",
    Address: "Grande Prairie,CANADA",
  },
  {
    code: "YQV",
    name: "Yorkton Intl Airport",
    Address: "Yorkton,CANADA",
  },
  {
    code: "YQW",
    name: "North Battleford Intl Airport",
    Address: "North Battleford,CANADA",
  },
  { code: "YQX", name: "Gander Intl ", Address: "Gander,CANADA" },
  { code: "YQY", name: "Sydney Intl Airport", Address: "Sydney,CANADA" },
  {
    code: "YQZ",
    name: "Quesnel Intl Airport",
    Address: "Quesnel,CANADA",
  },
  {
    code: "YRB",
    name: "Resolute Intl Airport",
    Address: "Resolute,CANADA",
  },
  {
    code: "YRF",
    name: "Cartwright Intl Airport",
    Address: "Cartwright,CANADA",
  },
  {
    code: "YRG",
    name: "Rigolet Intl Airport",
    Address: "Rigolet,CANADA",
  },
  {
    code: "YRI",
    name: "Riviere Du Loup Intl Airport",
    Address: "Riviere Du Loup,CANADA",
  },
  {
    code: "YRJ",
    name: "Roberval Intl Airport",
    Address: "Roberval,CANADA",
  },
  { code: "YRL", name: "Federal Red Lake", Address: "Red Lake,CANADA" },
  {
    code: "YRQ",
    name: "Three Rivers Intl Airport",
    Address: "Trois Rivieres,CANADA",
  },
  {
    code: "YRT",
    name: "Rankin Inlet Intl Airport",
    Address: "Rankin Inlet,CANADA",
  },
  {
    code: "YSB",
    name: "Sudbury Municipal Intl Airport",
    Address: "Sudbury,CANADA",
  },
  {
    code: "XDY",
    name: "Sudbury Junction Rail Station",
    Address: "Sudbury,CANADA",
  },
  {
    code: "YSC",
    name: "Sherbrooke Intl Airport",
    Address: "Sherbrooke,CANADA",
  },
  {
    code: "YSH",
    name: "Smith Falls Rail Station",
    Address: "Smith Falls,CANADA",
  },
  { code: "YSJ", name: "St John Municipal", Address: "St John,CANADA" },
  {
    code: "YSK",
    name: "Sanikiluaq Intl Airport",
    Address: "Sanikiluaq,CANADA",
  },
  {
    code: "YSL",
    name: "Edmunston Intl Airport",
    Address: "St Leonard,CANADA",
  },
  {
    code: "YSM",
    name: "Ft Smith Municipal Intl Airport",
    Address: "Ft Smith,CANADA",
  },
  {
    code: "YSN",
    name: "Salmon Arm Municipal",
    Address: "Salmon Arm,CANADA",
  },
  {
    code: "YSO",
    name: "Postville Intl Airport",
    Address: "Postville,CANADA",
  },
  {
    code: "YSP",
    name: "Marathon Municipal Intl Airport",
    Address: "Marathon,CANADA",
  },
  {
    code: "YST",
    name: "St Therese Pt Municipal",
    Address: "St Theris Point,CANADA",
  },
  { code: "YTA", name: "Pembroke And Area", Address: "Pembroke,CANADA" },
  {
    code: "YTD",
    name: "Thicket Portage Rail Station",
    Address: "Thicket Portage,CANADA",
  },
  {
    code: "YTE",
    name: "Cape Dorset Intl Airport",
    Address: "Cape Dorset,CANADA",
  },
  {
    code: "YTF",
    name: "Alma Municipal Intl Airport",
    Address: "Alma,CANADA",
  },
  {
    code: "YTH",
    name: "Thompson Intl Airport",
    Address: "Thompson,CANADA",
  },
  {
    code: "YTJ",
    name: "Terrace Bay Municipal Intl Airport",
    Address: "Terrace Bay,CANADA",
  },
  {
    code: "YTL",
    name: "Big Trout Intl Airport",
    Address: "Big Trout,CANADA",
  },
  {
    code: "YTM",
    name: " Intl Airport",
    Address: "Mont Tremblant,CANADA",
  },
  {
    code: "YBZ",
    name: "Toronto Downtown Rail Station",
    Address: "Toronto,CANADA",
  },
  {
    code: "YYZ",
    name: "Lester B Pearson Intl ",
    Address: "Toronto,CANADA",
  },
  {
    code: "YTZ",
    name: "Toronto City Centre Intl Airport",
    Address: "Toronto,CANADA",
  },
  {
    code: "YKZ",
    name: "Buttonville Intl Airport",
    Address: "Toronto,CANADA",
  },
  {
    code: "XLQ",
    name: "Guildwood Rail Station",
    Address: "Toronto,CANADA",
  },
  {
    code: "YTQ",
    name: "Tasiujaq Intl Airport",
    Address: "Tasiujaq,CANADA",
  },
  {
    code: "YTR",
    name: "Trenton Municipal Intl Airport",
    Address: "Trenton,CANADA",
  },
  {
    code: "YTS",
    name: "Timmins Municipal Intl Airport",
    Address: "Timmins,CANADA",
  },
  {
    code: "YUD",
    name: "Umiujaq Intl Airport",
    Address: "Umiujaq,CANADA",
  },
  { code: "YUM", name: "Yuma Intl ", Address: "Yuma,UNITED STATES" },
  {
    code: "YUX",
    name: "Hall Beach Intl Airport",
    Address: "Hall Beach,CANADA",
  },
  {
    code: "YUY",
    name: "Rouyn Noranda Intl Airport",
    Address: "Rouyn Noranda,CANADA",
  },
  { code: "YVA", name: "Iconi Intl Airport", Address: "Moroni,COMOROS" },
  {
    code: "HAH",
    name: "Prince Said Ibrahim In",
    Address: "Moroni,COMOROS",
  },
  {
    code: "YVB",
    name: "Municipal Bonaventure",
    Address: "Bonaventure,CANADA",
  },
  {
    code: "YVE",
    name: "Vernon Municipal Intl Airport",
    Address: "Vernon,CANADA",
  },
  {
    code: "YVG",
    name: "Vermilion Intl Airport",
    Address: "Vermilion,CANADA",
  },
  {
    code: "YVM",
    name: "Qikiqtarjuaq Intl Airport",
    Address: "Qikiqtarjuaq,CANADA",
  },
  {
    code: "YVO",
    name: "Val d Or Municipal Intl Airport",
    Address: "Val D Or,CANADA",
  },
  {
    code: "YVP",
    name: "Kuujjuaq Intl Airport",
    Address: "Kuujjuaq,CANADA",
  },
  {
    code: "YVQ",
    name: "Norman Wells Municipal Intl Airport",
    Address: "Norman Wells,CANADA",
  },
  {
    code: "YVR",
    name: "Vancouver Intl Airport",
    Address: "Vancouver,CANADA",
  },
  {
    code: "YDT",
    name: "Boundary Bay Intl Airport",
    Address: "Vancouver,CANADA",
  },
  {
    code: "CXH",
    name: "Coal Harbor Sea Plane Intl Airport",
    Address: "Vancouver,CANADA",
  },
  {
    code: "XEA",
    name: "Vancouver Rail Station",
    Address: "Vancouver,CANADA",
  },
  {
    code: "YVZ",
    name: "Deer Lake Intl Airport",
    Address: "Deer Lake,CANADA",
  },
  {
    code: "YWB",
    name: "Kangiqsujuaq Intl Airport",
    Address: "Kangiqsujuaq,CANADA",
  },
  {
    code: "XEF",
    name: "Winnipeg Rail Station",
    Address: "Winnipeg,CANADA",
  },
  {
    code: "YWG",
    name: "Winnipeg Intl Airport",
    Address: "Winnipeg,CANADA",
  },
  { code: "YWK", name: "Wabush Intl Airport", Address: "Wabush,CANADA" },
  {
    code: "YWL",
    name: "Williams Lake Municipal",
    Address: "Williams Lake,CANADA",
  },
  {
    code: "YWR",
    name: "White River Rail Station",
    Address: "White River,CANADA",
  },
  {
    code: "YWS",
    name: "Whistler Intl Airport",
    Address: "Whistler,CANADA",
  },
  {
    code: "YXC",
    name: "Cranbrook Municipal",
    Address: "Cranbrook,CANADA",
  },
  {
    code: "YKY",
    name: "Kindersley Intl Airport",
    Address: "Saskatoon,CANADA",
  },
  {
    code: "YXE",
    name: "Saskatoon Municipal",
    Address: "Saskatoon,CANADA",
  },
  {
    code: "YXH",
    name: "Medicine Hat Intl Airport",
    Address: "Medicine Hat,CANADA",
  },
  {
    code: "YXJ",
    name: "Ft St John Municipal Intl Airport",
    Address: "Ft St John,CANADA",
  },
  {
    code: "YXK",
    name: "Rimouski Municipal Intl Airport",
    Address: "Rimouski,CANADA",
  },
  {
    code: "YXL",
    name: "Sioux Lookout Intl Airport",
    Address: "Sioux Lookout,CANADA",
  },
  {
    code: "YXN",
    name: "Whale Cove Intl Airport",
    Address: "Whale Cove,CANADA",
  },
  {
    code: "YXP",
    name: "Pangnirtung Intl Airport",
    Address: "Pangnirtung,CANADA",
  },
  {
    code: "XDV",
    name: "Prince George Rail Station",
    Address: "Prince George,CANADA",
  },
  {
    code: "YXS",
    name: "Prince George Municipal",
    Address: "Prince George,CANADA",
  },
  {
    code: "YXT",
    name: "Terrace Municipal Intl Airport",
    Address: "Terrace,CANADA",
  },
  { code: "XDQ", name: "London Rail Station", Address: "London,CANADA" },
  { code: "YXU", name: "London Municipal", Address: "London,CANADA" },
  {
    code: "YXX",
    name: "Abbotsford Intl Airport",
    Address: "Abbotsford,CANADA",
  },
  {
    code: "YXY",
    name: "Whitehorse Intl Airport",
    Address: "Whitehorse,CANADA",
  },
  { code: "YXZ", name: "Municipal Wawa", Address: "Wawa,CANADA" },
  {
    code: "YYB",
    name: "North Bay Municipal",
    Address: "North Bay,CANADA",
  },
  {
    code: "YYC",
    name: "Calgary Intl Airport",
    Address: "Calgary,CANADA",
  },
  {
    code: "YQF",
    name: "Red Deer Intl Airport",
    Address: "Calgary,CANADA",
  },
  { code: "YYD", name: "Smithers Municipal", Address: "Smithers,CANADA" },
  {
    code: "YYE",
    name: "Ft Nelson Municipal Intl Airport",
    Address: "Fort Nelson,CANADA",
  },
  {
    code: "YYF",
    name: "Penticton Municipal Intl Airport",
    Address: "Penticton,CANADA",
  },
  {
    code: "YYG",
    name: "Charlottetown Municipal",
    Address: "Charlottetown,CANADA",
  },
  {
    code: "YYH",
    name: "Taloyoak Intl Airport",
    Address: "Taloyoak,CANADA",
  },
  { code: "YYI", name: "Rivers Rail Station", Address: "Rivers,CANADA" },
  {
    code: "YYJ",
    name: "Victoria Intl Airport",
    Address: "Victoria,CANADA",
  },
  {
    code: "YWH",
    name: "Inner Harbor Sea Plane Intl Airport",
    Address: "Victoria,CANADA",
  },
  {
    code: "YYL",
    name: "Lynn Lake Rail Station",
    Address: "Lynn Lake,CANADA",
  },
  {
    code: "YYN",
    name: "Swift Current Intl Airport",
    Address: "Swift Current,CANADA",
  },
  {
    code: "XAD",
    name: "Churchill Rail Station",
    Address: "Churchill,CANADA",
  },
  {
    code: "YYQ",
    name: "Churchill Intl Airport",
    Address: "Churchill,CANADA",
  },
  {
    code: "YYR",
    name: "Municipal Goose Bay",
    Address: "Goose Bay,CANADA",
  },
  {
    code: "YYT",
    name: "St Johns Intl Airport",
    Address: "St Johns,CANADA",
  },
  {
    code: "YYU",
    name: "Japuskasing Municipal Intl Airport",
    Address: "Kapuskasing,CANADA",
  },
  {
    code: "YYW",
    name: "Armstrong Rail Station",
    Address: "Armstromg,CANADA",
  },
  {
    code: "YYY",
    name: "Mont Joli Intl Airport",
    Address: "Mont Joli,CANADA",
  },
  {
    code: "YZA",
    name: "Ashcroft Rail Station",
    Address: "Ashcroft,CANADA",
  },
  {
    code: "YZF",
    name: "Yellowknife Intl Airport",
    Address: "Yellowknife,CANADA",
  },
  {
    code: "YZG",
    name: "Salluit Intl Airport",
    Address: "Salluit,CANADA",
  },
  {
    code: "YZP",
    name: "Federal Intl Airport",
    Address: "Sandspit,CANADA",
  },
  { code: "YZR", name: "Sarnia Intl Airport", Address: "Sarnia,CANADA" },
  { code: "XDX", name: "Sarina Rail Station", Address: "Sarnia,CANADA" },
  {
    code: "YZS",
    name: "Coral Harbour Intl Airport",
    Address: "Coral Harbour,CANADA",
  },
  {
    code: "YZT",
    name: "Port Hardy Municipal",
    Address: "Port Hardy,CANADA",
  },
  {
    code: "YZV",
    name: "Sept Iles Municipal Intl Airport",
    Address: "Sept Iles,CANADA",
  },
  {
    code: "YZX",
    name: "Greenwood Intl Airport",
    Address: "Greenwood,CANADA",
  },
  { code: "YZZ", name: "Trail Intl Airport", Address: "Trail,CANADA" },
  { code: "ZAD", name: "Zadar Intl Airport", Address: "Zadar,CROATIA" },
  { code: "ZAG", name: "Zagreb Intl Airport", Address: "Zagreb,CROATIA" },
  { code: "ZAL", name: "Pichoy", Address: "Valdivia,CHILE" },
  {
    code: "ZAO",
    name: "Laberandie Intl Airport",
    Address: "Cahors,FRANCE",
  },
  {
    code: "ZAZ",
    name: "Zaragoza Intl Airport",
    Address: "Zaragoza,SPAIN",
  },
  { code: "ZBD", name: "Jiang Men Ferry", Address: "Jiang Men,CHINA" },
  {
    code: "ZBF",
    name: "Bathurst Intl Airport",
    Address: "Bathurst,CANADA",
  },
  {
    code: "ZBL",
    name: "Biloela Intl Airport",
    Address: "Biloela,AUSTRALIA",
  },
  {
    code: "ZBM",
    name: "Bromont Regional Intl Airport",
    Address: "Bromont,CANADA",
  },
  {
    code: "ZBW",
    name: "Atibaia Intl Airport",
    Address: "Atibaia,BRAZIL",
  },
  {
    code: "ZBZ",
    name: "Xin Hui Ferry Intl Airport",
    Address: "Xin Hui,CHINA",
  },
  {
    code: "ZCL",
    name: "Zacatecas Intl Airport",
    Address: "Zacatecas,MEXICO",
  },
  {
    code: "ZCO",
    name: "Manquehue Intl Airport",
    Address: "Temuco,CHILE",
  },
  { code: "ZCU", name: "She Kou Ferry", Address: "She Kou,CHINA" },
  {
    code: "ZEC",
    name: "Secunda Intl Airport",
    Address: "Secunda,SOUTH AFRICA",
  },
  {
    code: "ZFI",
    name: "Chesterfield Bus Station",
    Address: "Chesterfield,UNITED KINGDOM",
  },
  {
    code: "ZFV",
    name: "Philadelphia Rail Station",
    Address: "Philadelphia Rail Station,UNITED STATES",
  },
  {
    code: "ZGS",
    name: "Gethsemanie Intl Airport",
    Address: "Gethsemanie,CANADA",
  },
  {
    code: "ZHA",
    name: "Zhanjiang Intl Airport",
    Address: "Zhangjiang,CHINA",
  },
  { code: "ZHO", name: "Houston Bus Station", Address: "Houston,CANADA" },
  {
    code: "ZIG",
    name: "Ziguinchor Intl Airport",
    Address: "Ziguinchor,SENEGAL",
  },
  {
    code: "ZIH",
    name: "Zihuatanejo Intl ",
    Address: "Zihuatanejo,MEXICO",
  },
  {
    code: "ZJN",
    name: "Swan River Municipal Intl Airport",
    Address: "Swan River,CANADA",
  },
  {
    code: "ZKG",
    name: "Kegaska Intl Airport",
    Address: "Kegaska,CANADA",
  },
  {
    code: "ZLO",
    name: "Manzanillo Intl Airport",
    Address: "Manzanillo,MEXICO",
  },
  {
    code: "ZLT",
    name: "La Tabatiere Intl Airport",
    Address: "La Tabatiere,CANADA",
  },
  {
    code: "ZNE",
    name: "Newman Intl Airport",
    Address: "Newman,AUSTRALIA",
  },
  {
    code: "IGS",
    name: "Ingolstadt Intl Airport",
    Address: "Ingolstadt,GERMANY",
  },
  {
    code: "ZNZ",
    name: "Kisauni Intl Airport",
    Address: "Zanzibar,TANZANIA",
  },
  {
    code: "ZOS",
    name: "Canal Balo Intl Airport",
    Address: "Osorno,CHILE",
  },
  { code: "ZPC", name: "Pucon Intl Airport", Address: "Pucon,CHILE" },
  {
    code: "ZPE",
    name: "Osnabrueck Rail Service",
    Address: "Osnabruck,GERMANY",
  },
  {
    code: "ZPP",
    name: "Rail Station Reutlingen",
    Address: "Reutlingen,GERMANY",
  },
  { code: "ZQF", name: "Trier Intl Airport", Address: "Trier,GERMANY" },
  {
    code: "ZQN",
    name: "Frankton Intl Airport",
    Address: "Queenstown,NEW ZEALAND",
  },
  {
    code: "ZQS",
    name: "Queen Charlotte Island Intl Airport",
    Address: "Queen Charlotte Island,CANADA",
  },
  {
    code: "ZQW",
    name: "Zweibruecken Intl Airport",
    Address: "Zweibruecken,GERMANY",
  },
  {
    code: "ZRH",
    name: "Zurich Intl Airport",
    Address: "Zurich,SWITZERLAND",
  },
  {
    code: "ZSA",
    name: "San Salvador Intl Airport",
    Address: "San Salvador,BAHAMAS",
  },
  {
    code: "ZSE",
    name: "St Pierre Dela Reunion Intl Airport",
    Address: "St Pierre Dela Reunion,REUNION",
  },
  {
    code: "ZSG",
    name: "Sonneberg Railway Station",
    Address: "Sonneberg,GERMANY",
  },
  {
    code: "ZSJ",
    name: "Sandy Lake Intl Airport",
    Address: "Sandy Lake,CANADA",
  },
  {
    code: "SZW",
    name: "Parchim Intl Airport",
    Address: "Schwerin,GERMANY",
  },
  {
    code: "ZTB",
    name: "Tete A La Baleine Intl Airport",
    Address: "Tete A La Baleine,CANADA",
  },
  {
    code: "ZTH",
    name: "Zakinthos Intl Airport",
    Address: "Zakinthos,GREECE",
  },
  { code: "ZUH", name: "Zhuhai Intl Airport", Address: "Zhuhai,CHINA" },
  {
    code: "ZUM",
    name: "Churchhill Falls Intl Airport",
    Address: "Churchhill Falls,CANADA",
  },
  {
    code: "ZVD",
    name: "Drangedal Rail Station",
    Address: "Drangedal,NORWAY",
  },
  { code: "ZXO", name: "Fauske Rail Station", Address: "Fauske,NORWAY" },
  { code: "ZXU", name: "Rygge Rail Station", Address: "Rygge,NORWAY" },
  { code: "ZXX", name: "Rade Rail Station", Address: "Rade,NORWAY" },
  { code: "ZYI", name: "Zunyi Intl Airport", Address: "Zunyi,CHINA" },
  {
    code: "ZYL",
    name: "Sylhet Osmany Intl Airport",
    Address: "Sylhet,BANGLADESH",
  },
  {
    code: "ZYP",
    name: "New York Penn STN",
    Address: "New York Penn STN,UNITED STATES",
  },
  {
    code: "ZYV",
    name: "Vegarshei Rail Station",
    Address: "Vegarshei,NORWAY",
  },
  {
    code: "ZYW",
    name: "Sandvika Rail Station",
    Address: "Sandvika,NORWAY",
  },
  {
    code: "ZYY",
    name: "Marnardal Rail Station",
    Address: "Marnardal,NORWAY",
  },
  {
    code: "ZZV",
    name: "Zanesville Intl Airport",
    Address: "Zanesville,UNITED STATES",
  },
  {
    code: "DWC",
    name: "Al Maktoum Intl Airport",
    Address: "Dubai,United Arab Emirates",
  },
  {
    code: "NYC",
    name: "New York,United States of America",
    Address: "New York City-All Airports",
  },
];
export default flightData;
