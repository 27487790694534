import { Box, Button, Container, TextField, Typography } from '@mui/material'
import React from 'react'

const ChangePassword = () => {
  return (
    <>
    <Box sx={{background:'var(--white)'  ,borderRadius:"5px",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",py:2,mt:2}}>
<Container>
    <Box sx={{borderBottom:"1px solid var(--primary-color)",pb:2}}>
        <Typography sx={{color:'var(--primary-color)',fontSize:"21px",fontWeight:'500'}}> Change Password</Typography>
    </Box>
<Box sx={{mt:3,"& label.Mui-focused": {
      color: 'var(--primary-color)'
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor:'var(--primary-color)'
        }
      }}}>
<TextField type='password'  id="outlined-basic" placeholder='Password' label="Password" variant="outlined"  sx={{width:'100%', }}/>
<TextField  type='password' id="outlined-basic" placeholder='New Password' label="New Password" variant="outlined"  sx={{width:'100%',mt:3}}/>
<TextField  type='password' id="outlined-basic" placeholder='Confirm Password' label="Confirm New Password" variant="outlined"  sx={{width:'100%',mt:3}}/>
    </Box>
    <Box sx={{textAlign:'right',mt:2}}>
        <Button variant='contained' sx={{width:"100%",fontWeight:"500",color:'var(--white)',boxShadow:'none',bgcolor:"var(--primary-color)",textTransform:'capitalize'}}>Submit</Button>
    </Box>
</Container>
    </Box>
    
    </>
  )
}

export default ChangePassword