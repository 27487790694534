import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../../images/loader/Render.gif';
import { format } from 'date-fns';
import commaNumber from 'comma-number';
import { AdminHeader } from '../AdminHeader/AdminHeader';

const AgetnLadger = () => {
  const location = useLocation();
  const agentId = location?.state;
  const [isLoaded, setIsLoaded] = useState(false);
  const [ladgerData, setLadgerData] = useState([]);
  useEffect(() => {
    fetch(
      `https://api.flyjatt.com/v1/Report/ClientLedger.php?all&agentId=${agentId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoaded(true);
        setLadgerData(data);
      });
  }, [agentId]);
  if (!isLoaded) {
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '70vh',
          width: '70vw',
          marginInline: 'auto',
        }}
      >
        <Box
          style={{
            width: '30%',
            height: '30%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: '100%',
              objectFit: 'center',
            }}
          />
        </Box>
      </Box>
    );
  }
  return (
    <div>
      <AdminHeader />
      <Container>
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            color: 'var(--secondary-color)',
            fontSize: '24px',
            fontWeight: '500',
          }}
        >
          Agent Ledger
        </Typography>
        <Box
          sx={{
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '0px',
              background: 'var(--secondary-color)',
            },
            height: 'calc(100vh - 210px)',
            overflowY: 'scroll',
            table: {
              width: '100%',
              position: 'relative',
              borderCollapse: 'collapse',
            },
            th: {
              position: 'sticky',
              top: 0,
              zIndex: '999',
              background: 'var(--primary-color)',
              color: 'white',
              border: '1px solid #ddd',
              padding: '8px 12px',
              fontSize: '14px',
              fontWeight: '500',
            },
            td: {
              textAlign: 'center',
              verticalAlign: 'middle',
              border: '1px solid #ddd !important',
              padding: '5px !important',
              fontSize: '12px',
            },
            'tr:nth-child(even)': {
              background: '#f2f2f2',
            },
            'tr:hover': {
              background: '#ddd',
            },
          }}
        >
          <table>
            <tr>
              <th>Type</th>
              <th>TransactionId</th>
              <th>Date</th>
              <th>Remarks</th>
              <th>Amount</th>
              <th>Balance</th>
            </tr>
            <tbody>
              {ladgerData
                ? ladgerData?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {item?.deposit > '0' ? (
                          <button
                            style={{
                              border: 'none',
                              outline: 'none',
                              padding: '5px',
                              color: '#fff',
                              width: '100%',
                              backgroundColor: '#177c33',
                              borderRadius: '4px',
                            }}
                          >
                            Deposit
                          </button>
                        ) : item?.purchase > '0' ? (
                          <button
                            style={{
                              border: 'none',
                              outline: 'none',
                              padding: '5px',
                              color: '#fff',
                              borderRadius: '4px',
                              width: '100%',
                              backgroundColor: '#ed1c24',
                            }}
                          >
                            Purchase
                          </button>
                        ) : item?.void > '0' ? (
                          <button
                            style={{
                              border: 'none',
                              outline: 'none',
                              padding: '5px',
                              color: '#fff',
                              width: '100%',
                              backgroundColor: '#235f83',
                              borderRadius: '4px',
                            }}
                          >
                            Void
                          </button>
                        ) : item?.refund > '0' ? (
                          <button
                            style={{
                              border: 'none',
                              outline: 'none',
                              padding: '5px',
                              color: '#fff',
                              width: '100%',
                              backgroundColor: '#7a64ff',
                              borderRadius: '4px',
                            }}
                          >
                            Refund
                          </button>
                        ) : item?.other > '0' ? (
                          <button
                            style={{
                              width: '100%',
                              border: 'none',
                              outline: 'none',
                              padding: '5px',
                              color: '#fff',
                              backgroundColor: 'green',
                              borderRadius: '4px',
                            }}
                          >
                            Credit
                          </button>
                        ) : item?.other < '0' ? (
                          <button
                            style={{
                              width: '100%',
                              border: 'none',
                              outline: 'none',
                              padding: '5px',
                              color: '#fff',
                              backgroundColor: 'red',
                              borderRadius: '4px',
                            }}
                          >
                            Debit
                          </button>
                        ) : item?.servicefee > '0' ? (
                          <button
                            style={{
                              border: 'none',
                              outline: 'none',
                              padding: '5px',
                              color: '#fff',
                              width: '100%',
                              backgroundColor: '#0b8668',
                              borderRadius: '4px',
                            }}
                          >
                            Service Fee
                          </button>
                        ) : (
                          <button
                            style={{
                              border: 'none',
                              outline: 'none',
                              padding: '5px',
                              color: '#fff',
                              width: '100%',
                              backgroundColor: 'orange',
                              borderRadius: '4px',
                            }}
                          >
                            Rejected
                          </button>
                        )}
                      </td>
                      <td>{item?.reference}</td>
                      <td>
                        {item?.created_at
                          ? format(
                              new Date(item?.created_at),
                              'dd MMM yyyy hh:mm a'
                            )
                          : 'Transaction Date'}
                      </td>
                      <td>{item?.details || '-'}</td>
                      <td>
                        {item?.deposit > '0'
                          ? '+' + commaNumber(Number(item?.deposit))
                          : item?.purchase > '0'
                          ? '-' + commaNumber(Number(item?.purchase))
                          : item?.returnMoney > '0'
                          ? '+' + commaNumber(Number(item?.returnMoney))
                          : item?.loan > '0'
                          ? '+' + commaNumber(Number(item?.loan))
                          : item?.void > '0'
                          ? '+' + commaNumber(Number(item?.void))
                          : item?.refund > '0'
                          ? '+' + commaNumber(Number(item?.refund))
                          : item?.reissue > '0'
                          ? '+' + commaNumber(Number(item?.reissue))
                          : item?.others > '0'
                          ? '+' + commaNumber(Number(item?.others))
                          : item?.servicefee > '0'
                          ? '+' + commaNumber(Number(item?.servicefee))
                          : ''}
                        {''}
                        AED
                      </td>

                      <td>{commaNumber(item?.lastAmount) || 'Balance'} AED</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </Box>
      </Container>
    </div>
  );
};

export default AgetnLadger;
