/* eslint-disable jsx-a11y/no-distracting-elements */
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { Container } from '@mui/system';
import commaNumber from 'comma-number';
import React, { useEffect, useState } from 'react';
import { GiTwoCoins } from 'react-icons/gi';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import useAuthentication from '../../hooks/useAuthentication';
import balanceIcon from '../../images/balance.png';
import Logo from '../../images/logo/logo.png';
import './Header.css';
import axios from 'axios';

const Header = () => {
  const user = secureLocalStorage.getItem('user-info');
  const uid = user?.uid;
  const apiUrl = 'https://api-b2b.projectota.com';
  const apiEndpoint = '/agent/notice/all';
  const { logout } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);
  const [marqueText, setMarqueText] = useState('');
  // todo: for mobile device
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  useEffect(() => {
    const url = `https://api-b2b.projectota.com/agent/myaccount/${uid}`;
    const config = {
      method: 'GET',
      header: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    };
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => setBalance(data?.balance))
      .catch((err) => console.log(err.message));

    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}${apiEndpoint}`);
        setMarqueText(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [balance, uid]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClickAway = () => {};

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          position: 'relative',
          overflowX: 'hidden',
          // background:
          //   'linear-gradient(90deg, rgba(0, 30, 27, 0.55) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%)',

          backgroundImage:
            'linear-gradient(to top, #0e7268a1, #ffffff)',
          // boxShadow: "rgba(9, 152, 138, 0.473) 0px 50px 100px -20px, rgba(9, 152, 138, 0.473) 0px 30px 60px -30px"
        }}
      >
        <Container>
          <Box
            sx={{
              height: 'fit-content',
              width: '100%',
              alignItems: 'center',
              display: { xs: 'none', sm: 'none', md: 'flex' },
            }}
          >
            {/* //todo:Logo  */}
            <Box
              sx={{
                width: { md: '15%', sm: '50%', xs: '50%' },
                // background: 'var(--white)',
                height: '100%',
                display: 'flex',
                justifyContent: {
                  lg: 'start',
                  md: 'start',
                  sm: 'center',
                  xs: 'center',
                },
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/user/dashboard')}
            >
              <Box
                sx={{
                  height: '80px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    height: '100%',
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: '85%',
                height: '60px',
                display: { md: 'flex', sm: 'none', xs: 'none' },
                alignItems: 'center',
                justifyContent: 'end',
                gap: '10px',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  background: 'rgba(9, 152, 138, 0.473)',
                  height: '35px',
                  borderRadius: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  px: 2,
                  color: 'var(--black)',
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                <img src={balanceIcon} alt="balance" width="18px" />
                <Box pt={0.2}>&nbsp;{`${commaNumber(balance || 0)} AED`}</Box>
              </Box>
            </Box>
            {/* //todo:Normal Mobile Navbar */}
            <Box
              sx={{
                width: '80%',
                height: '60px',
                display: { md: 'none', sm: 'flex', xs: 'flex' },
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Open Menu">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>
                    {/* {user?.user?.name[0].toUpperCase() || ""} */}TF
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Box
                    sx={{
                      color: 'var(--secondary-color)',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      fontSize: '17px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Tooltip title={`Balance: ${commaNumber(balance)}AED`}>
                      <Box>
                        <GiTwoCoins style={{ fontSize: '30px' }} />
                      </Box>
                    </Tooltip>
                    <Box>{commaNumber(balance)}AED</Box>
                    <NavLink
                      to="/adddeposite"
                      style={{
                        color: 'var(--secondary-color)',
                        cursor: 'pointer',
                      }}
                    >
                      <Tooltip title="Add Money">
                        <AddIcon style={{ fontSize: '30px' }} />
                      </Tooltip>
                    </NavLink>
                  </Box>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Button
                    style={{
                      background: 'var(--mateBlack)',
                      color: 'var(--white)',
                      fontWeight: 400,
                      padding: '10px',
                      cursor: 'pointer',
                      width: '100%',
                      zIndex: '999',
                    }}
                    onClick={() => {
                      logout();
                    }}
                  >
                    <LogoutIcon />
                    Logout
                  </Button>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {/* {location.pathname === '/user/dashboard' && (
            <Box
              sx={{
                overflowX: 'hidden',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <marquee
                style={{
                  background: 'var(--secondary-color)',
                  color: 'var(--white)',
                  padding: '6px 0',
                  margin: '10px 0px 0px',
                  borderRadius: '20px',
                  fontSize: '14px',
                }}
              >
                {marqueText && marqueText?.map((item) => item.notice)}
              </marquee>
            </Box>
          )} */}
        </Container>
      </Box>
    </ClickAwayListener>
  );
};

export default Header;
