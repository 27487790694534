import { Box, Typography, Stack, Grid, Container } from "@mui/material";
import React, { useState } from "react";
import HotelFakeData from "./HotelFakeData";
import HotelSearchResult from "./HotelSearchResult";
import Header from "../../Header/Header";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

const HotelDataLoad = () => {
  const location = useLocation();
  const { fromSendData, departureDate, returningDate, adultCount, childCount } =
    location.state;
  const [hotelData, setHotelData] = useState([]);
  const { isLoading, refetch } = useQuery(
    "Travel-calendar",
    () =>
      fetch(
        `https://api.flyjatt.com/v1/Hotel/searchhotel.php?location=${fromSendData}&indate=${departureDate}&outdate=${returningDate}&adult=${adultCount}&child=${childCount}`
      ).then((res) => res.json()),
    {
      onSuccess: (data) => {
        setHotelData(data);
      },
      onError: (err) => {
        console.log(err.message);
      },
    }
  );

  if (isLoading) {
    return "Loading...";
  }
  console.log(hotelData);
  return (
    <Box>
      <Header />
      <Container>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2.7} md={2.7}>
            <Box border="2px solid gray"> Hotel Filter </Box>
          </Grid>
          <Grid item xs={12} sm={9.3} md={9.3}>
            <Box>
              {hotelData?.map((hotelData) => (
                <Box
                  mb={1.5}
                  sx={{
                    bgcolor: "var(--white)",
                    p: { xs: 1, sm: 1, md: 2 },
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                    borderRadius: "10px",
                  }}
                >
                  <HotelSearchResult hotelData={hotelData} />
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HotelDataLoad;
