/* eslint-disable no-restricted-globals */
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import { format } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import FlightSearchBox from '../../components/FlightSearchBox/FlightSearchBox';
import Header from '../../components/Header/Header';
import SessionTimer from '../../components/Shared/SessionTimer/SessionTimer';
import SingleFlight from '../../components/SingleFlight/SingleFlight';
import Search from '../../images/undraw/undraw_web_search_re_efla.svg';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import FilterLoader from './../../components/Loader/FilterLoader';
import ResultLoader from './../../components/Loader/ResultLoader';
import { debounce } from 'lodash';
import AirlinesFilter from '../../components/AirlinesFilter/AirlinesFilter';
import HeaderSlider from '../../components/AirlineSlider/HeaderSlider';
import CustomPagination from './CustomPagination';
const modalStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { lg: 'fit-content', md: 'fit-content', sm: 'fit-content', xs: '95%' },
  height: 'fit-content',
  padding: { xs: '5px', sm: '10px', md: '20px' },
  background: '#fff',
  borderRadius: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
};

const RoundSearchResult = () => {
  const [quotetionArr, setQuotetionArr] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [filterPageIndex, setFilterPageIndex] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [selectedStops, setSelectedStops] = useState([]);
  const [selectedAirlins, setSelectedAirlins] = useState([]);
  const [selectedRefundable, setSelectedRefundable] = useState([]);
  const [selectedDepartTime, setSelectedDepartTime] = useState({
    name: '',
    type: '',
    startTime: '',
    endTime: '',
  });
  const [selectedArrivalTime, setSelectedArrivalTime] = useState({
    name: '',
    type: '',
    startTime: '',
    endTime: '',
  });
  const [selectedBackDepartTime, setSelectedBackDepartTime] = useState({
    name: '',
    type: '',
    startTime: '',
    endTime: '',
  });
  const [selectedBackArrivalTime, setSelectedBackArrivalTime] = useState({
    name: '',
    type: '',
    startTime: '',
    endTime: '',
  });

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [selectedLayover, setSelectedLayover] = useState([]);

  // --------------new Sate end --------------------------------
  // --------------functionality start  --------------------------------

  const handleResetData = useCallback(() => {
    setPageIndex(0);
    setFilterPageIndex(null);
    setSelectedProvider([]);
    setSelectedAirlins([]);
    setSelectedStops([]);
    setSelectedRefundable([]);
    setSelectedDepartTime({ name: '', type: '', startTime: '', endTime: '' });
    setSelectedArrivalTime({ name: '', type: '', startTime: '', endTime: '' });
    setSelectedBackDepartTime({
      name: '',
      type: '',
      startTime: '',
      endTime: '',
    });
    setSelectedBackArrivalTime({
      name: '',
      type: '',
      startTime: '',
      endTime: '',
    });
    setSelectedLayover([]);
    setFilterData(data);
  }, [data]);
  // --------------func end --------------------------------

  const navigate = useNavigate();
  const location = useLocation();
  const requiredSearchData =
    location.state !== null
      ? location.state
      : secureLocalStorage.getItem('search-data');

  const {
    fromSendData,
    toSendData,
    departureDate,
    returningDate,
    adultCount,
    childCount,
    infant,
    tripType,
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    className,
    directFlightOnly,
    kids,
    brandedFare,
    extraBaggage,
    currency,
    connectionValue,
    airlineValue,
    nonStop,
  } = requiredSearchData;

  const user = secureLocalStorage.getItem('user-info');
  const uid = user?.uid;
  const multicity = secureLocalStorage.getItem('multi-city');
  const [multiCitySearchData, setMultiCitySearchData] = useState({
    adultcount: adultCount,
    childcount: childCount,
    infantcount: infant,
    cabinclass: className || 'Y',
    kidscount: kids,
    connection: connectionValue,
    currency,
    brandedfare: brandedFare || true,
    extrabaggage: extraBaggage || true,
    directflight: nonStop || false,
    airline: airlineValue || '',
    segments: [
      {
        id: 0,
        openFrom: false,
        depfrom: fromSendData.trim(),
        depFromText: fromSearchText.trim(),
        arrto: 'DXB',
        arrToText: 'Dubai Intl Airport [DXB]',
        openTo: false,
        depdate: new Date().toLocaleDateString('sv'),
        openDate: false,
        open: false,
      },
    ],
  });

  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });

  const [type, setType] = React.useState('flight');
  const [value, setValue] = React.useState(tripType);
  const [roundWayFromSearchText, setRoundWayFromSearchText] =
    useState(fromSearchText);
  const [roundWayToSearchText, setRoundWayToSearchText] =
    useState(toSearchText);
  const now = useRef(new Date(departureDate));
  const returnNow = useRef(new Date(returningDate));
  const [from, setFrom] = useState(now.current);
  const [to, setTo] = useState(returnNow.current);
  const [fromSearchDate, setFromSearchDate] = useState(departureDate);
  const [toSearchDate, setToSearchDate] = useState(returningDate);

  const [roundWayFaddress, setRoundWayFaddress] = useState(faddress);
  const [roundWayToAddress, setRoundWayToAddress] = useState(toAddress);
  const [roundWayFromSendData, setRoundWayFromSendData] =
    useState(fromSendData);
  const [roundWayToSendData, setRoundWayToSendData] = useState(toSendData);
  const [roundWayAdultCount, setRoundWayAdultCount] = useState(adultCount);
  const [roundWayChildCount, setRoundWayChildCount] = useState(childCount);
  const [roundWayInfant, setRoundWayInfant] = useState(infant);
  const [result, setResult] = useState(adultCount + childCount + infant);
  const [roundWayClassName, setRoundWayClassName] = useState(className);
  const [isPrevClicked, setIsPrevCliked] = useState(false);
  const [isNextClicked, setIsNextCliked] = useState(false);
  const [directFlight, setDirectFlight] = useState(directFlightOnly);
  //end

  //CM Box States
  const [customerFare, setCustomerFare] = useState(true);
  const [agentFarePrice, setAgentFarePrice] = useState(true);
  const [commisionFarePrice, setCommisionFarePrice] = useState(true);
  //todo:end

  //todo:all flight and nxt and previous day data states
  const [data2, setData2] = useState([]);
  const [noData, setNoData] = useState('No Data');
  const [modifyOpen, setModifyOpen] = useState(false);
  const modifyHandleOpen = () => setModifyOpen(true);
  const modifyHandleClose = () => setModifyOpen(false);
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect
  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change
  // todo:Sets the state of the const for the given page and state.
  const [page, setPage] = useState(1);
  let size = 30;
  // todo: previous day and next day date variables
  let tomorrowDepartureDate = new Date(fromSearchDate);
  let tomorrowReturnDate = new Date(toSearchDate);
  tomorrowDepartureDate.setDate(tomorrowDepartureDate.getDate() + 1);
  tomorrowReturnDate.setDate(tomorrowReturnDate.getDate() + 1);
  let yesterdayDepartureDate = new Date(fromSearchDate);
  let yesterdayReturnDate = new Date(toSearchDate);
  yesterdayDepartureDate.setDate(yesterdayDepartureDate.getDate() - 1);
  yesterdayReturnDate.setDate(yesterdayReturnDate.getDate() - 1);
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setData2(data?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  // get the target element to toggle

  //todo All Flight Data for Today

  useEffect(() => {
    let unSubscribed = false;
    setIsPrevCliked(false);
    setIsNextCliked(false);
    setIsLoaded(false);
    modifyHandleClose();
    // let url = "https://api.flyjatt.com/v1/Flight/airsearch.php";

    let url = `https://api-b2b.projectota.com/agent/flight/search/${uid}`;

    let body = {
      adultcount: adultCount,
      childcount: childCount,
      infantcount: infant,
      brandedfare: brandedFare || true,
      extrabaggage: extraBaggage || true,
      directflight: nonStop || false,
      airline: airlineValue || '',
      connection: directFlightOnly ? 1 : '',
      cabinclass: roundWayClassName || 'Y',
      segments: [
        {
          depfrom: `${fromSendData?.replace(/\s+/g, '')}`,
          arrto: toSendData,
          depdate: `${new Date(departureDate).toLocaleDateString('sv')}`,
        },
        {
          depfrom: toSendData,
          arrto: `${fromSendData?.replace(/\s+/g, '')}`,
          depdate: `${new Date(returningDate).toLocaleDateString('sv')}`,
        },
      ],
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api-b2b.projectota.com/agent/flight/search/${uid}`,
          {
            method: 'POST', // specifying the method
            headers: {
              'Content-Type': 'application/json', // setting the headers if needed
              // add other headers if necessary
            },
            body: JSON.stringify(body),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.length > 0) {
          setData(data);
          setFilterData(data);
          setData2(data);
          setIsLoaded(true);
        }
        // handle the data
      } catch (error) {
        console.error('Error fetching data:', error);
        Swal.fire({
          imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          confirmButtonText: 'Search Again...',
          confirmButtonColor: 'var(--primary-color)',
        }).then(function () {
          navigate(-1);
        });
      }
    };

    fetchData();
  }, [
    changeState,
    departureDate,
    returningDate,
    fromSendData,
    toSendData,
    adultCount,
    childCount,
    infant,
    navigate,
    size,
    tripType,
  ]);

  useEffect(() => {
    handleResetData();
  }, [data]);
  console.log(data);
  // ----------------Functionality start ----------------
  // uniqueLayover
  const uniqueLayover = data
    ? Array.from(
        new Set(
          data
            ?.filter((item) => item.AllLegsInfo[0].Segments.length > 1)
            .map((item) => ({
              name:
                item.AllLegsInfo[0].Segments.length > 2
                  ? item.AllLegsInfo[0].Segments[2].DepAirPort
                  : item.AllLegsInfo[0].Segments[1].DepAirPort,
              code:
                item.AllLegsInfo[0].Segments.length > 2
                  ? item.AllLegsInfo[0].Segments[2].DepFrom
                  : item.AllLegsInfo[0].Segments[1].DepFrom,
            }))
            .map((entry) => JSON.stringify(entry))
        )
      ).map((str) => JSON.parse(str))
    : [];
  console.log(uniqueLayover);
  // ----------------filter-------------

  // price Slider
  // let arr = [];
  // data.map((item) => {
  //   arr.push(item.NetFare);
  //   return arr;
  // });

  // const maxPrice = Math.max(...arr);
  // const minPrice = Math.min(...arr);
  // const [selectPrice, setSelectPrice] = useState([minPrice, maxPrice]);

  const handleSelection = (name, setSelected) => {
    setSelected((prevSelected) => {
      const isSelected = prevSelected.includes(name);
      return isSelected
        ? prevSelected.filter((item) => item !== name)
        : [...prevSelected, name];
    });

    setFilterPageIndex(name); // 2 or 3.. move page after filter setdata
  };

  // const handleChangePrice = (event, newPrice) => {
  //   handleSelection(newPrice, setSelectPrice);
  // };

  const handleProvider = (name) => {
    handleSelection(name, setSelectedProvider);
  };
  const handleStops = (name) => {
    handleSelection(name, setSelectedStops);
  };
  const handleAirLine = (name) => {
    handleSelection(name, setSelectedAirlins);
  };
  const handleRefundable = (name) => {
    handleSelection(name, setSelectedRefundable);
  };
  // Date Filter
  const handleDepartTime = useCallback(
    (type, name, startTime, endTime) => {
      const startDate = new Date(`1970-01-01T${startTime}`);
      const endDate = new Date(`1970-01-01T${endTime}`);
      switch (type) {
        case 'Depart':
          setSelectedDepartTime(
            name === selectedDepartTime.name
              ? { name: '', type: '', startTime: '', endTime: '' }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        case 'Arrival':
          setSelectedArrivalTime(
            name === selectedArrivalTime.name
              ? { name: '', type: '', startTime: '', endTime: '' }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        // for roundway
        case 'returnDepart':
          setSelectedBackDepartTime(
            name === selectedBackDepartTime.name
              ? { name: '', type: '', startTime: '', endTime: '' }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        case 'returnArrival':
          setSelectedBackArrivalTime(
            name === selectedBackArrivalTime.name
              ? { name: '', type: '', startTime: '', endTime: '' }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        default:
          break;
      }
    },
    [
      selectedDepartTime,
      selectedArrivalTime,
      selectedBackDepartTime,
      selectedBackArrivalTime,
    ]
  );

  const handleLayover = (name) => {
    handleSelection(name, setSelectedLayover);
  };
  const filter = () => {
    let updateData = data; // Assuming 'data' is your original dataset
    // if (selectPrice.length > 0) {
    //   updateData = updateData.filter((item) =>
    //     selectPrice.includes(item.NetFare)
    //   );
    // }
    if (selectedProvider.length > 0) {
      updateData = updateData.filter((item) =>
        selectedProvider.includes(item.System)
      );
    }
    if (selectedStops.length > 0) {
      updateData = updateData.filter((item) =>
        selectedStops.includes(item.AllLegsInfo[0].Segments.length)
      );
    }
    if (selectedAirlins.length > 0) {
      updateData = updateData.filter((item) =>
        selectedAirlins.includes(item.Carrier)
      );
    }
    if (selectedRefundable.length > 0) {
      updateData = updateData.filter((item) =>
        selectedRefundable.includes(item.Refundable)
      );
    }

    if (
      selectedDepartTime.type === 'Depart' &&
      selectedDepartTime.name.length > 0
    ) {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[0][0].DepTime.slice(
            11,
            19
          )}`
        );
        return (
          time >= selectedDepartTime.startTime &&
          time <= selectedDepartTime.endTime
        );
      });
    }

    if (selectedArrivalTime.type === 'Arrival') {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[0][
            item.AllLegsInfo[0].Segments[0].length - 1
          ].ArrTime.slice(11, 19)}`
        );
        return (
          time >= selectedArrivalTime.startTime &&
          time <= selectedArrivalTime.endTime
        );
      });
    }

    if (selectedBackDepartTime.type === 'returnDepart') {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[
            item.AllLegsInfo[0].Segments.length - 1
          ][
            item.AllLegsInfo[0].Segments[
              item.AllLegsInfo[0].Segments.length - 1
            ].length - 1
          ].DepTime.slice(11, 19)}`
        );
        return (
          time >= selectedBackDepartTime.startTime &&
          time <= selectedBackDepartTime.endTime
        );
      });
    }

    if (selectedBackArrivalTime.type === 'returnArrival') {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[
            item.AllLegsInfo[0].Segments.length - 1
          ][
            item.AllLegsInfo[0].Segments[
              item.AllLegsInfo[0].Segments.length - 1
            ].length - 1
          ].ArrTime.slice(11, 19)}`
        );
        return (
          time >= selectedBackArrivalTime.startTime &&
          time <= selectedBackArrivalTime.endTime
        );
      });
    }

    if (selectedLayover.length > 0) {
      updateData = updateData.filter((item) => {
        const segments = item.AllLegsInfo[0].Segments;
        const DepFrom =
          (segments.length === 2 && segments[1].DepFrom) ||
          (segments.length > 2 && segments[2].DepFrom);
        return selectedLayover.includes(DepFrom);
      });
    }
    setFilterData(updateData);
  };

  const itemsPerPage = innerWidth > 600 ? 20 : 10;
  const startIndex = filterPageIndex ? 0 : pageIndex;

  const pageData =
    isLoaded &&
    (filterData || []).slice(
      startIndex * itemsPerPage,
      startIndex * itemsPerPage + itemsPerPage
    );

  const gotoPage = useCallback((value) => {
    setFilterPageIndex(0);
    setPageIndex(value);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  const pageLength = innerWidth > 600 ? 20 : 10;
  const pageCount = useMemo(
    () => Math.ceil(filterData.length / pageLength),
    [filterData.length]
  );
  const canPreviousPage = useMemo(() => pageIndex > 0, [pageIndex]);
  const canNextPage = useMemo(
    () => pageIndex < pageCount - 1,
    [pageIndex, pageCount]
  );

  const debouncedFilter = debounce(filter, 300);
  useEffect(() => {
    debouncedFilter();
    return () => {
      debouncedFilter.cancel();
    };
  }, [
    selectedProvider,
    selectedRefundable,
    selectedAirlins,
    selectedLayover,
    selectedStops,
    selectedDepartTime,
    selectedArrivalTime,
    selectedBackDepartTime,
    selectedBackArrivalTime,
    data,
  ]);

  // slider shorting
  const uniqueCarriers = Array.from(
    new Set(data?.filter((item) => item.Carrier).map((item) => item.Carrier))
  );
  const flightresults = uniqueCarriers.map((carrier) => {
    const filteredData = data?.filter((item) => item.Carrier === carrier);
    const minPrice = Math.min(
      ...filteredData.map((item) => parseInt(item.GrossFare))
    );
    const carrierName = filteredData[0].CarrierName;
    // change this property after add
    return {
      code: carrier,
      name: carrierName,
      price: minPrice.toString(),
    };
  });

  const totalFlight = data?.length;

  return (
    <Box>
      <Header />
      <Box mt={{ xs: 12, sm: 10, md: 2 }}>
        <Container sx={{ position: 'relative', px: { xs: 1, sm: 2 } }}>
          <Grid container justifyContent="space-between" columnSpacing={2}>
            {/* //todo: filter*/}
            <Grid
              item
              md={2.7}
              sx={{
                display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
              }}
            >
              {isLoaded ? (
                <AirlinesFilter
                  flightData={data}
                  tripType={tripType}
                  selectedProvider={selectedProvider}
                  handleProvider={handleProvider}
                  handleRefundable={handleRefundable}
                  handleAirLine={handleAirLine}
                  selectedAirlins={selectedAirlins}
                  handleStops={handleStops}
                  selectedStops={selectedStops}
                  selectedRefundable={selectedRefundable}
                  selectedLayover={selectedLayover}
                  handleDepartTime={handleDepartTime}
                  selectedDepartTime={selectedDepartTime}
                  selectedArrivalTime={selectedArrivalTime}
                  selectedBackDepartTime={selectedBackDepartTime}
                  selectedBackArrivalTime={selectedBackArrivalTime}
                  handleLayover={handleLayover}
                  uniqueLayover={uniqueLayover}
                  handleResetData={handleResetData}
                  uniqueCarriers={flightresults}
                />
              ) : (
                <FilterLoader />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9.3}>
              {/* //todo: Flight Search Result section */}
              <Box
                sx={{
                  bgcolor: 'var(--bgcolor)',
                  overflow: 'hidden',
                  px: { xs: 1, md: 2 },
                  py: { xs: 1, md: 1.5 },
                  borderRadius: '5px',
                  mb: isLoaded ? 2 : '',
                }}
              >
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      sx={{
                        color: 'var(--secondary)',
                        fontSize: '13px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {format(new Date(fromSearchDate), 'dd MMM yyyy')}
                      &nbsp;
                      <OpenInFullIcon
                        sx={{
                          color: 'var(--secondary)',
                          transform: 'rotate(45deg)',
                          fontSize: { xs: 10, md: 14 },
                        }}
                      />
                      &nbsp;
                      {format(new Date(toSearchDate), 'dd MMM yyyy')} (
                      {fromSendData}&nbsp;
                      <OpenInFullIcon
                        sx={{
                          color: 'var(--secondary)',
                          transform: 'rotate(45deg)',
                          fontSize: { xs: 10, md: 14 },
                        }}
                      />
                      &nbsp;
                      {toSendData})
                    </Typography>
                    <Typography
                      sx={{
                        color: 'var(--secondary-color)',
                        fontSize: '13px',
                      }}
                    >
                      Total passenger {adultCount + childCount + infant}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'space-between', md: 'end' },
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Tooltip title="Session Time">
                      <Button
                        size="small"
                        style={{
                          width: 'fit-content',
                          border: '1.2px solid var(--primary-color)',
                          color: 'var(--primary-color)',
                        }}
                      >
                        <SessionTimer />
                      </Button>
                    </Tooltip>
                    <Button
                      size="small"
                      onClick={modifyHandleOpen}
                      style={{
                        backgroundColor: 'var(--primary-color)',
                        color: 'var(--white)',
                        padding: '5px 10px',
                        cursor: 'pointer',
                        fontSize: 14,
                        textTransform: 'capitalize',
                      }}
                    >
                      Modify Search
                    </Button>
                  </Grid>
                </Grid>
                <Modal open={modifyOpen} onClose={modifyHandleClose}>
                  <Container>
                    <Box sx={modalStyle}>
                      <FlightSearchBox
                        options={options}
                        setOptions={setOptions}
                        type={type}
                        setType={setType}
                        value={value}
                        setValue={setValue}
                        fromSearchText={roundWayFromSearchText}
                        setFromSearchText={setRoundWayFromSearchText}
                        toSearchText={roundWayToSearchText}
                        setToSearchText={setRoundWayToSearchText}
                        from={from}
                        setFrom={setFrom}
                        to={to}
                        setTo={setTo}
                        faddress={roundWayFaddress}
                        setfaddress={setRoundWayFaddress}
                        toAddress={roundWayToAddress}
                        setToAddress={setRoundWayToAddress}
                        fromSendData={roundWayFromSendData}
                        setFromSendData={setRoundWayFromSendData}
                        toSendData={roundWayToSendData}
                        setToSendData={setRoundWayToSendData}
                        adultCount={roundWayAdultCount}
                        setAdultCount={setRoundWayAdultCount}
                        childCount={roundWayChildCount}
                        setChildCount={setRoundWayChildCount}
                        infant={roundWayInfant}
                        setInfant={setRoundWayInfant}
                        result={result}
                        setResult={setResult}
                        className={roundWayClassName}
                        setClassName={setRoundWayClassName}
                        changeState={changeState}
                        setChangeState={setChangeState}
                        changeFrom={changeFrom}
                        setChangeFrom={setChangeFrom}
                        searchData={multiCitySearchData}
                        setSearchData={setMultiCitySearchData}
                        directFlightOnly={directFlight}
                        setDirectFlightOnly={setDirectFlight}
                      />
                    </Box>
                  </Container>
                </Modal>
              </Box>

              <Grid container>
                {/* //todo:Search Result Section */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {isLoaded ? (
                    <Box>
                      <HeaderSlider
                        uniqueCarriers={flightresults}
                        selectedAirlins={selectedAirlins}
                        handleAirLine={handleAirLine}
                      />

                      {!filterData?.length > 0 ? (
                        <Box
                          sx={{
                            height: '50Vh',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'var(--crimson)',
                          }}
                        >
                          No Flight Available
                        </Box>
                      ) : (
                        <Box mt={2}>
                          {pageData.map((data, index) => {
                            return (
                              <SingleFlight
                                key={index}
                                flightData={data}
                                tripType={tripType}
                                adultCount={adultCount}
                                childCount={childCount}
                                infant={infant}
                                from={fromSendData}
                                to={toSendData}
                                fromAddress={faddress}
                                toAddress={toAddress}
                                agentFarePrice={agentFarePrice}
                                setAgentFarePrice={setAgentFarePrice}
                                commisionFarePrice={commisionFarePrice}
                                setCommisionFarePrice={setCommisionFarePrice}
                                customerFare={customerFare}
                                setCustomerFare={setCustomerFare}
                                quotetionArr={quotetionArr}
                                setQuotetionArr={setQuotetionArr}
                              />
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <ResultLoader count={8} />
                  )}
                </Grid>
                {/* //todo: Pagination section */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      width: '100%',
                      my: 3,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Box sx={{ mt: 2 }}>
                      <CustomPagination
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box>
        {quotetionArr?.length ? (
          <Box
            sx={{
              position: 'fixed',
              bottom: '0',
              width: '100%',
              background: 'var(--secondary-color)',
              padding: '10px 0px',
              boxShadow: '0px -1px 5px 0px rgba(0,0,0,0.75)',
            }}
          >
            <Container>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  size="small"
                  onClick={() => {
                    navigate('/user/quotationsend', {
                      state: {
                        quotetionArr,
                        adultCount,
                        childCount,
                        infant,
                        tripType,
                      },
                    });
                  }}
                  sx={{
                    textTransform: 'capitalize',
                    background: 'var(--primary-color)',
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: '500',
                    padding: '5px 10px',
                    '&:hover': {
                      background: 'var(--primary-color)',
                    },
                  }}
                >
                  Make Quotation {`(${quotetionArr?.length}) Selected`}
                </Button>
              </Box>
            </Container>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default RoundSearchResult;
