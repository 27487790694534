import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Pagination,
  Typography,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import Loader from '../../images/loader/Render.gif';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import moment from 'moment';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2564b8',
      darker: '#2564b8',
    },
  },
});

const AgentDeposit = () => {
  const location = useLocation();
  const uid = location?.state;
  const [isLoaded, setIsLoaded] = useState(false);
  const [depositData, setDepositData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const size = 15;

  const handlePageChange = (event, value) => {
    setPage(value);
    setDepositData(depositData.slice((value - 1) * size, value * size));
  };

  useEffect(() => {
    const fetchQueuesData = () => {
      setIsLoaded(true);

      fetch(`https://api-b2b.projectota.com/agent/deposit/${uid}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          const count = data?.length || 0;
          const pageNumber = Math.ceil(count / size);
          setPageCount(pageNumber);
          setDepositData(data);
        })
        .catch((error) => console.error('Error fetching data:', error))
        .finally(() => setIsLoaded(false));
    };

    fetchQueuesData();
  }, [uid, size]);

  return (
    <div>
      <AdminHeader />
      <Container>
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            color: 'var(--secondary-color)',
            fontSize: '24px',
            fontWeight: '500',
          }}
        >
          Agent Deposit
        </Typography>
        {isLoaded ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '70vh',
            }}
          >
            <Box
              style={{
                width: '30%',
                height: '30%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={Loader}
                alt="loader"
                style={{
                  width: '100%',
                  objectFit: 'center',
                }}
              />
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '0px',
                  background: 'var(--secondary-color)',
                },
                height: 'calc(100vh - 210px)',
                overflowY: 'scroll',
                table: {
                  width: '100%',
                  position: 'relative',
                  borderCollapse: 'collapse',
                },
                th: {
                  position: 'sticky',
                  top: 0,
                  zIndex: '999',
                  background: 'var(--primary-color)',
                  color: 'white',
                  border: '1px solid #ddd',
                  padding: '8px 12px',
                  fontSize: '14px',
                  fontWeight: '500',
                },
                td: {
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  border: '1px solid #ddd !important',
                  padding: '5px !important',
                  fontSize: '12px',
                },
                'tr:nth-child(even)': {
                  background: '#f2f2f2',
                },
                'tr:hover': {
                  background: '#ddd',
                },
              }}
            >
              <table>
                <tr>
                  <th>RefId</th>
                  <th>Status</th>
                  <th>Type </th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Attachment</th>
                  <th>Remarks</th>
                </tr>

                <tbody>
                  {depositData
                    ? depositData?.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.depositId}</td>
                          <td>
                            {data?.status ? (
                              <Typography
                                sx={{ fontSize: '12px', textAlign: 'center' }}
                                className={`${data?.status
                                  ?.toLowerCase()
                                  ?.split(' ')
                                  ?.join('-')}-btn`}
                              >
                                {data?.status}
                              </Typography>
                            ) : (
                              'Status'
                            )}
                          </td>
                          <td>{data?.paymentway}</td>
                          <td>{data?.amount}</td>
                          <td>
                            {moment(data?.created_at)?.format('DD MMM YYYY')}
                          </td>
                          <td>
                            <a
                              href={data?.attachment}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: 'black' }}
                            >
                              View
                            </a>
                          </td>
                          <td>
                            {data?.remarks === '' ? 'N/A' : data?.remarks}{' '}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </Box>
            <Box
              sx={{
                width: '100%',
                py: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  size="small"
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </ThemeProvider>
            </Box>
          </>
        )}
      </Container>
    </div>
  );
};

export default AgentDeposit;
