/* eslint-disable jsx-a11y/no-distracting-elements */
import { Box, Button, Container, Grid, Tab } from '@mui/material';
import React, { useState } from 'react';
import Header from '../Header/Header';
import HomeSearchBox from '../HomeSearchBox/HomeSearchBox';
import { MoreDeals } from '../MoreDeals/MoreDeals';
import { RecentSearch } from '../RecentSearch/RecentSearch';
import ServiceSection from '../ServiceSection/ServiceSection';
import { TrendingDeals } from '../TrendingDeals/TrendingDeals';
import dashboards from '../../images/dashboard-bg.jpg';
import FlightIcon from '@mui/icons-material/Flight';
import DownloadIcon from '@mui/icons-material/Download';

import './Dashboard.css';
import Marque from '../Header/Marque';
import { useNavigate } from 'react-router-dom';
import NewHeader from '../Header/NewHeader';
import Footer from '../Footer/Footer';

const item = [
  { name: 'Search pad', value: 'flight', icon: 'FlightIcon' },
  { name: 'PNR Imports', value: 'pnr', icon: 'DownloadIcon' },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const [system, setSystem] = useState('flight');
  const [type, setType] = useState('flight');
  const [importData, setImportData] = useState({});
  const handleSystemChange = (newValue) => {
    setSystem(newValue);
  };
  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };
  const handleChange = (e) => {
    setImportData({ ...importData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event, newValue) => {
    navigate('/user/pnrimport', {
      state: {
        system: importData?.system,
        pnr: importData?.pnr,
      },
    });
  };
  return (
    <Box>
  
      {/* <Header /> */}
   
      <Box
        sx={{
         
          
          // backgroundImage: `url(${dashboards})`,
          // backgroundSize: 'cover',
          // backgroundPosition: 'center',
          // background: 'rgb(9,152,138)',
          // background:
          //   'linear-gradient(0deg, rgba(9,152,138,0.969625350140056) 40%, rgba(255,255,255,1) 100%)',
          // 'linear-gradient(0deg, rgba(9,152,138,155) 0%, rgba(255,255,255,1) 100%)',
          width: '100%',
          height: '100vh',
        }}
      >
        <Marque />
        <Box sx={{ borderRadius:"20px",paddingBottom:"328px" ,pt:8,mx:4,background:"linear-gradient(rgba(7, 152, 137, 0.8), rgba(7, 152, 137, 0.8)),url(https://png.pngtree.com/background/20230524/original/pngtree-big-plane-flying-over-clouds-at-sunset-picture-image_2706406.jpg)",backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center"}} mt={{ xs: 12, md: 3}}>
          <Container >
            <Box
              sx={{
                // boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                p: { xs: 1, sm: 2 },
                borderRadius: '10px',
                width: 'fit-content',
                background:"rgba(7, 152, 137, 0.5)",
              }}
            >
              <Grid container spacing={2}>
                {item.map((button) => (
                  <Grid item key={button.value}>
                    <Box
                      onClick={() => handleSystemChange(button.value)}
                      sx={{
                        cursor: 'pointer',
                        textTransform: 'capitalize',
                        fontWeight: 500,
                        fontSize: 14,
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        py: 0.5,
                        px: 1,
                        border:
                          system === button.value
                            ? '1px solid var(--primary-color)'
                            : '1px solid var(--white)',
                        bgcolor:
                          system === button.value ? 'var(--white)' : '',
                        color:
                          system === button.value
                            ? 'var(--black)'
                            : 'var(--white)',
                        '&:hover': {
                          bgcolor:
                            system === button.value
                              ? 'var(--primary-color)'
                              : '',
                        },
                        width: '160px',
                      }}
                    >
                      {button.value === 'flight' ? (
                        <>
                          <FlightIcon
                            sx={{
                              border:
                                system === button.value
                                  ? '1px solid var(--white)'
                                  : '1px solid var(--white)',
                              borderRadius: '50%',
                              // bgcolor: 'var(--primary-color)',
                              p: 0.5,
                              fontSize: 35,
                            }}
                          />
                          &nbsp;&nbsp;&nbsp;
                          {button.name}
                        </>
                      ) : (
                        <>
                          <DownloadIcon
                            sx={{
                              border:
                                system === button.value
                                  ? '1px solid var(--white)'
                                  : '1px solid var(--white)',
                              borderRadius: '50%',
                              // bgcolor: 'var(--primary-color)',
                              p: 0.5,
                              fontSize: 35,
                            }}
                          />
                          &nbsp;&nbsp;&nbsp;{button.name}
                        </>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            
          </Container>
          <Container>
            {system === 'pnr' && (
              <Box
                sx={{
                  boxShadow:
                    ' rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                  p: { xs: 1, sm: 3, md: 3 },
                  my: 3,
                  borderRadius: '10px',
                  height: 'fit-content',
                  width: { xs: '100%', sm: '550px', md: '600', lg: '600px' },
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    // alignItems="end"
                    justifyContent={'start'}
                  >
                    <Grid style={{ position: 'relative' }} item xs={12} sm={6}>
                      <Box className="custom-selects">
                        <select
                          onChange={handleChange}
                          value={importData?.system}
                          name="system"
                          required
                        >
                          <option value="">Select System</option>
                          <option
                            value="sabre"
                            style={{
                              fontSize: '14px',
                              padding: '10px',
                              width: '100%',
                              outline: 'none',
                            }}
                          >
                            Sabre
                          </option>
                        </select>
                      </Box>
                    </Grid>

                    <Grid style={{ position: 'relative' }} item xs={12} sm={6}>
                      <input
                        required
                        name="pnr"
                        placeholder="Enter PNR"
                        style={{
                          width: '100%',
                          border: 'none',
                          borderRadius: '4px',
                          padding: '10px 15px',
                          outline: 'none',
                          color: 'var(--secondary-color)',
                          fontWeight: 500,
                        }}
                        type="text"
                        value={importData?.pnr}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'end',
                        gap: '5px',
                      }}
                    >
                      <Button
                        sx={{
                          background: 'var(--primary-color)',
                          color: 'white',
                          padding: '7px 20px !important',
                          fontSize: '12px !important',
                          width: '100%',
                          '&:hover': {
                            background: 'var(--primary-color)',
                            color: 'white',
                          },
                        }}
                        type="submit"
                      >
                        PROCEED
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            )}
          </Container>
          {system === 'flight' && (
            <Box>
              <HomeSearchBox
                type={type}
                setType={setType}
                handleTypeChange={handleTypeChange}
              />
            </Box>
          )}
          
        </Box>
        <Footer/>
      </Box>
      {/* <RecentSearch /> */}
      {/* <ServiceSection /> */}
      {/* <TrendingDeals /> */}
      {/* <MoreDeals /> */}
     
    </Box>
  );
};

export default Dashboard;
