import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminMarkup.css';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import ServerDown from '../../images/undraw/undraw_server_down_s-4-lk.svg';
import Loader from '../../images/loader/Render.gif';

import Swal from 'sweetalert2';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import AirlineMarkup from './AirlineMarkup';
import MarkupDataTable from './MarkupDataTable';

const AdminMarkup = () => {
  const navigate = useNavigate();
  const [markupData, setMarupData] = useState({});
  const [value, setValue] = useState('api');
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [markUpData, setMarkUpData] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleMarkupData = (e) => {
    setMarupData({ ...markupData, [e.target.name]: e.target.value });
  };

  // Handle a page change.

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://api.flyjatt.com/v1/MarkUp/index.php?${value}`;
    const config = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        markuptype: markupData?.markupType,
        markup: markupData?.amount,
      }),
    };
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'success') {
          Swal.fire({
            icon: 'success',
            title: data?.message,
            confirmButtonText: 'ok',
            confirmButtonColor: 'var(--primary-color)',
          }).then(() => {
            setRefetch(!refetch);
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: data?.message,
            confirmButtonText: 'ok',
            confirmButtonColor: 'var(--primary-color)',
          }).then(() => {
            navigate(0);
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          icon: 'error',
          title: 'Markup Failed!',
          confirmButtonText: 'ok',
          confirmButtonColor: 'var(--primary-color)',
        }).then(() => {
          navigate(0);
        });
      });
  };

  useEffect(() => {
    const url1 = 'https://api.flyjatt.com/v1/MarkUp/index.php?all';
    fetch(url1)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setMarkUpData(data?.data);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: 'Failed! to Fetch',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Try Another Time',
        }).then(() => {
          setIsLoading(true);
          navigate('/dashboard');
        });
      });
  }, [refetch]);
  if (isLoading) {
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '70vh',
          width: '70vw',
          marginInline: 'auto',
        }}
      >
        <Box
          style={{
            width: '50%',
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: '100%',
              objectFit: 'center',
            }}
          />
          Loading ...
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box>
          {/* <AdminHeader /> */}
          <Typography
            my={2}
            style={{
              color: 'var(--mateBlack)',
              fontSize: '22px',
              fontWeight: '500',
            }}
          >
            Add Markup
          </Typography>
          <Box>
            <TabContext value={value}>
              <Box
                sx={{
                  width: '100%',
                  height: { md: '100%', sm: '100%', xs: '100%' },
                  display: 'flex',
                  justifyContent: {
                    md: 'space-between',
                    sm: 'start',
                    xs: 'start',
                  },
                  alignItems: 'center',
                  opacity: '1',
                  '& .MuiTabs-flexContainer': {
                    flexWrap: 'wrap',
                    gap: '5px',
                  },
                  '.MuiTabs-root': {
                    minHeight: 'fit-content',
                  },
                  '& button': {
                    opacity: '1',
                    background: 'var(--third-color)',
                    color: 'var(--secondary-color)',
                    width: 'fit-content',
                    minHeight: 'fit-content',
                    padding: '10px 5px',
                    fontSize: '12px',
                    borderRadius: '4px',
                  },
                  '& button.Mui-selected,& button.Mui-selected >svg': {
                    background: 'var(--secondary-color)',
                    color: 'var(--white) !important',
                  },
                  '& .MuiBadge-badge': {
                    fontSize: '10px',
                    right: 6,
                    top: 2,
                  },
                }}
              >
                <TabList
                  onChange={handleChange}
                  color="#fff"
                  indicatorColor="none"
                >
                  {/* <Tab label="Api" value="api" />
                  <Tab label="Airlines" value="airlines" />
                  <Tab label="Airlines" value="airlines" />
                  <Tab label="Refund" value="refund" />
                  <Tab label="Reissue" value="reissue" />
                  <Tab label="Void" value="void" /> */}
                </TabList>
              </Box>
              {/* //todo:transaction part */}
              <TabPanel value="api" style={{ padding: '10px 0' }}>
                <Box>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4} md={4}>
                        <select
                          style={{
                            width: '100%',
                            padding: '5px 10px',
                            outline: 'none',
                            border: '1px solid var(--secondary-color)',
                            borderRadius: '4px !important',
                          }}
                          name="markupType"
                          value={markupData?.markupType || ''}
                          onChange={handleMarkupData}
                        >
                          <option value="">Markup Type</option>
                          <option value="amount">Amount</option>
                          <option value="percent">Percent(%)</option>
                        </select>
                      </Grid>

                      <Grid item xs={12} sm={4} md={4}>
                        <input
                          name="amount"
                          value={markupData?.amount || ''}
                          type="text"
                          placeholder="Amount"
                          required
                          style={{
                            width: '100%',
                            padding: '5px 10px',
                            outline: 'none',
                            border: '1px solid var(--secondary-color)',
                            borderRadius: '4px !important',
                          }}
                          onChange={handleMarkupData}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <button
                          type="submit"
                          style={{
                            width: '100%',
                            background: 'var(--secondary-color)',
                            color: 'white',
                            outline: 'none',
                            padding: '5px 10px',
                            borderRadius: '4px',
                            fontSize: '14px',
                            border: '1px solid var(--secondary-color)',
                          }}
                        >
                          Submit
                        </button>
                      </Grid>
                    </Grid>
                  </form>
                  <MarkupDataTable
                    markUpData={markUpData}
                    isLoading={isLoading}
                    Loader={Loader}
                  />
                </Box>
              </TabPanel>
              {/* <TabPanel value="airlines" style={{ padding: '10px 0' }}>
                <AirlineMarkup />
              </TabPanel>
              <TabPanel value="flight" style={{ padding: '10px 0' }}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4}>
                      <select
                        style={{
                          width: '100%',
                          padding: '5px 10px',
                          outline: 'none',
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px !important',
                        }}
                        name="markupType"
                        value={markupData?.markupType || ''}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ''}
                        type="text"
                        placeholder="Amount"
                        required
                        style={{
                          width: '100%',
                          padding: '5px 10px',
                          outline: 'none',
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px !important',
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: '100%',
                          background: 'var(--secondary-color)',
                          color: 'white',
                          outline: 'none',
                          padding: '5px 10px',
                          borderRadius: '4px',
                          fontSize: '14px',
                          border: '1px solid var(--secondary-color)',
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form>
                <MarkupDataTable
                  markUpData={markUpData}
                  isLoading={isLoading}
                  Loader={Loader}
                />
              </TabPanel>

              <TabPanel value="refund" style={{ padding: '10px 0' }}>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      '& input': {
                        width: '100%',
                        outline: 'none',
                        border: '1px solid var(--secondary-color)',
                        borderRadius: '4px !important',
                      },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <select
                        style={{
                          width: '100%',
                          padding: '5px 10px',
                          outline: 'none',
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px !important',
                        }}
                        name="markupType"
                        value={markupData?.markupType || ''}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ''}
                        type="text"
                        placeholder="Amount"
                        required
                        style={{
                          width: '100%',
                          padding: '5px 10px',
                          outline: 'none',
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px !important',
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: '100%',
                          background: 'var(--secondary-color)',
                          color: 'white',
                          outline: 'none',
                          padding: '5px 10px',
                          borderRadius: '4px',
                          fontSize: '14px',
                          border: '1px solid var(--secondary-color)',
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form>
                <MarkupDataTable
                  markUpData={markUpData}
                  isLoading={isLoading}
                  Loader={Loader}
                />
              </TabPanel>
              <TabPanel value="reissue" style={{ padding: '10px 0' }}>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      '& input': {
                        width: '100%',
                        outline: 'none',
                        border: '1px solid var(--secondary-color)',
                        borderRadius: '4px !important',
                      },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <select
                        style={{
                          width: '100%',
                          padding: '5px 10px',
                          outline: 'none',
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px !important',
                        }}
                        name="markupType"
                        value={markupData?.markupType || ''}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ''}
                        type="text"
                        placeholder="Amount"
                        required
                        style={{
                          width: '100%',
                          padding: '5px 10px',
                          outline: 'none',
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px !important',
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: '100%',
                          background: 'var(--secondary-color)',
                          color: 'white',
                          outline: 'none',
                          padding: '5px 10px',
                          borderRadius: '4px',
                          fontSize: '14px',
                          border: '1px solid var(--secondary-color)',
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form>
                <MarkupDataTable
                  markUpData={markUpData}
                  isLoading={isLoading}
                  Loader={Loader}
                />
              </TabPanel>
              <TabPanel value="void" style={{ padding: '10px 0' }}>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      '& input': {
                        width: '100%',
                        outline: 'none',
                        border: '1px solid var(--secondary-color)',
                        borderRadius: '4px !important',
                      },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <select
                        style={{
                          width: '100%',
                          padding: '5px 10px',
                          outline: 'none',
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px !important',
                        }}
                        name="markupType"
                        value={markupData?.markupType || ''}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ''}
                        type="text"
                        placeholder="Amount"
                        required
                        style={{
                          width: '100%',
                          padding: '5px 10px',
                          outline: 'none',
                          border: '1px solid var(--secondary-color)',
                          borderRadius: '4px !important',
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: '100%',
                          background: 'var(--secondary-color)',
                          color: 'white',
                          outline: 'none',
                          padding: '5px 10px',
                          borderRadius: '4px',
                          fontSize: '14px',
                          border: '1px solid var(--secondary-color)',
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form>
                <MarkupDataTable
                  markUpData={markUpData}
                  isLoading={isLoading}
                  Loader={Loader}
                />
              </TabPanel> */}
            </TabContext>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminMarkup;
