import { Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import './CashTab.css';
import { useEffect } from 'react';

const CashTab = ({ value }) => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem('user-info');
  const agentId = user?.agentId;
  const uid = user?.uid;
  const agentName = user?.company;
  const [isLoading, setIsLoading] = useState(true);
  const [sender, setSender] = useState(agentName || '');
  const [reciver, setReciver] = useState('Fast Track Travel & Tourism');
  const [reference, setReference] = useState('');
  const [amount, setAmount] = useState('');
  const [file, setFile] = useState(null);
  const [bankDetails, setBankDetails] = useState([]);
  const [depositTo, setDepositTo] = useState('');
  const [bankSender, setBankSender] = useState('');

  useEffect(() => {
    fetch(`https://api-b2b.projectota.com/banklist/all`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Network response was not ok, status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => setBankDetails(data))
      .catch((error) => {
        // Handle the error, you can log it or show a user-friendly message
        console.error('Error fetching bank details:', error.message);
      });
  }, []);

  const onChangeFile = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  let body;
  const handleFileUpload = async (fileUpload) => {
    const formData = new FormData();
    formData.append('file', fileUpload);

    const url = `https://api-b2b.projectota.com/agent/upload/deposit/${uid}`;

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    try {
      const res = await axios.post(url, formData, config);

      if (res?.data?.status === 'success') {
        const attachment = res?.data?.fileurl;
        body = {
          agentId: agentId,
          amount: amount,
          sender: value === 'banktransfer' ? bankSender : sender,
          receiver: value === 'banktransfer' ? depositTo : reciver,
          paymentway: value === 'banktransfer' ? 'Bank' : 'Cash',
          ref: value === 'banktransfer' ? 'Bank' : reference,
          attachment: attachment,
          created_at: new Date(),
          updated_at: new Date(),
        };
      }
    } catch (error) {
      console.error('File upload error:', error);
      throw error; // Handle the error appropriately in the calling function
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (file) {
      setIsLoading(true);

      // First, upload the file
      handleFileUpload(file)
        .then(() => {
          // Now, after the file upload is complete, proceed with the second API call

          return axios.post(
            `https://api-b2b.projectota.com/agent/deposit/${uid}`,
            body,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            Swal.fire({
              icon: 'success',
              title: 'Deposit Successfully!',
              html: `For any query, please contact us at <strong>contact@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
              button: 'Done!',
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            throw new Error('Unexpected response status');
          }
        })
        .catch((err) => {
          console.error(err);

          Swal.fire({
            icon: 'error',
            title: 'Deposit Failed!',
            html: `For any query, please contact us at <strong>contact@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
            button: 'Done!',
          }).then(() => {
            setIsLoading(true);
          });
        })
        .finally(() => {
          e.target.reset();
        });
    }
  };

  return (
    <Box sx={{ mt: '10px' }}>
      <form onSubmit={handleSubmit}>
        <Box className="passengerInput1">
          <Grid container spacing={2}>
            {value === 'banktransfer' && (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography>
                    Deposit From (Account Number)*
                    <Typography component={'span'} sx={{ color: 'red' }}>
                      *
                    </Typography>
                  </Typography>
                  <Box style={{ marginTop: '5px' }}>
                    <input
                      required
                      type="text"
                      placeholder="Account Number"
                      value={bankSender}
                      onChange={(e) => setBankSender(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography>
                    Deposit To *
                    <Typography component={'span'} sx={{ color: 'red' }}>
                      *
                    </Typography>
                  </Typography>
                  <Box style={{ marginTop: '5px' }}>
                    <select
                      style={{ marginTop: '5px' }}
                      id="afterSelect"
                      onChange={(e) => setDepositTo(e.target.value)}
                      required
                    >
                      <>
                        <option value="">Deposit To A/C</option>
                        {bankDetails?.map((bankName) => (
                          <option
                            value={`${bankName?.bankname} ${bankName?.accountname}`}
                          >
                            {`${bankName?.bankname} ${bankName?.accountname}
                      (${bankName?.accountnumber})`}
                          </option>
                        ))}
                      </>
                    </select>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography>
                    Transaction Id*
                    <Typography component={'span'} sx={{ color: 'red' }}>
                      *
                    </Typography>
                  </Typography>
                  <Box style={{ marginTop: '5px' }}>
                    <input
                      required
                      type="text"
                      placeholder="Transaction Id"
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                    />
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Enter Amount
                <Typography component={'span'} sx={{ color: 'red' }}>
                  *
                </Typography>
              </Typography>
              <Box style={{ marginTop: '5px' }}>
                <input
                  required
                  type="number"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Box>
            </Grid>
            {value !== 'banktransfer' && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  Reference
                  <Typography component={'span'} sx={{ color: 'red' }}>
                    *
                  </Typography>
                </Typography>
                <Box style={{ marginTop: '5px' }}>
                  <input
                    required
                    type="text"
                    placeholder="Reference"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                  />
                </Box>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Attachment Copy
                <Typography component={'span'} sx={{ color: 'red' }}>
                  *
                </Typography>
              </Typography>
              <Box className="input-File1">
                <input
                  required
                  style={{
                    borderRadius: '4px',
                    backgroundColor: 'var(--primary-color)',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px',
                    boxSizing: 'border-box',
                  }}
                  onChange={onChangeFile}
                  className="customFileType"
                  type="file"
                  title="Choose a Image png and jpg please"
                  accept="image/*"
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ width: { xs: '90%', sm: '60%', md: '35%' }, mt: 5 }}>
            <Grid sx={{ display: 'flex', alignItems: 'end', my: 3 }}>
              <Button
                type="submit"
                sx={{
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  borderRadius: '4px',
                  background: 'var(--primary-color)',
                  color: 'var(--white)',
                  width: '100%',
                  '&:hover': {
                    background: 'var(--primary-color)',
                    color: 'var(--white)',
                  },
                }}
              >
                {isLoading ? (
                  'Send Deposit Request'
                ) : (
                  <CircularProgress
                    size="1.5rem"
                    sx={{
                      color: '#fff',
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default CashTab;
