const Airlines = [
  {
    code: 'AA',
    name: 'American Airlines',
    nameBangla: 'আমেরিকান এয়ারলাইন্স',
  },
  {
    code: '2G',
    name: 'CargoItalia (alternate)',
    nameBangla: '',
  },
  {
    code: '3K',
    name: 'Jetstar Asia Airways',
    nameBangla: '',
  },
  {
    code: 'CO',
    name: 'Continental Airlines',
    nameBangla: '',
  },
  {
    code: 'DL',
    name: 'Delta Air Lines',
    nameBangla: 'ডেল্টা এয়ার লাইনস',
  },
  {
    code: 'NW',
    name: 'Northwest Airlines (alternate site)',
    nameBangla: '',
  },
  {
    code: 'AC',
    name: 'Air Canada',
    nameBangla: 'এয়ার কানাডা এয়ারলাইন',
  },
  {
    code: 'UA',
    name: 'United Airlines',
    nameBangla: 'ইউনাইটেড এয়ারলাইন্স',
  },
  {
    code: 'CP',
    name: 'Canadian Airlines Int´l',
    nameBangla: '',
  },
  {
    code: 'LH',
    name: 'Lufthansa Cargo AG',
    nameBangla: 'লুফথানসা এয়ারলাইন',
  },
  {
    code: 'FX',
    name: 'Fedex',
    nameBangla: '',
  },
  {
    code: 'AS',
    name: 'Alaska Airlines',
    nameBangla: '',
  },
  {
    code: 'US',
    name: 'US Airways',
    nameBangla: '',
  },
  {
    code: 'RG',
    name: 'VARIG Brazilian Airlines',
    nameBangla: '',
  },
  {
    code: 'KA',
    name: 'Dragonair',
    nameBangla: '',
  },
  {
    code: 'LA',
    name: 'LAN Chile',
    nameBangla: '',
  },
  {
    code: 'TP',
    name: 'TAP Air Portugal',
    nameBangla: '',
  },
  {
    code: 'CY',
    name: 'Cyprus Airways',
    nameBangla: '',
  },
  {
    code: 'OA',
    name: 'Olympic Airways',
    nameBangla: '',
  },
  {
    code: 'EI',
    name: 'Aer Lingus Cargo',
    nameBangla: '',
  },
  {
    code: 'AZ',
    name: 'Alitalia',
    nameBangla: '',
  },
  {
    code: 'AF',
    name: 'Air France',
    nameBangla: 'এয়ার ফ্রান্স এয়ারলাইন',
  },
  {
    code: ' IC',
    name: 'Indian Airlines',
    nameBangla: '',
  },
  {
    code: 'HM',
    name: 'Air Seychelles',
    nameBangla: '',
  },
  {
    code: 'OK',
    name: 'Czech Airlines',
    nameBangla: '',
  },
  {
    code: 'SV',
    name: 'Saudi Arabian Airlines',
    nameBangla: 'সৌদিয়া এয়ারলাইন',
  },
  {
    code: 'RB',
    name: 'Syrian Arab Airlines',
    nameBangla: '',
  },
  {
    code: 'ET',
    name: 'Ethiopian Airlines',
    nameBangla: '',
  },
  {
    code: 'GF',
    name: 'Gulf Air',
    nameBangla: 'গালফ এয়ার',
  },
  {
    code: ' IB',
    name: 'Iberia',
    nameBangla: '',
  },
  {
    code: 'ME',
    name: 'Middle East Airlines',
    nameBangla: '',
  },
  {
    code: 'MS',
    name: 'Egyptair',
    nameBangla: 'ইজিপ্ট এয়ার ',
  },
  {
    code: 'PR',
    name: 'Philippine Airlines',
    nameBangla: '',
  },
  {
    code: 'AF',
    name: 'Air France',
    nameBangla: '',
  },
  {
    code: ' LO',
    name: 'LOT Polish Airlines',
    nameBangla: '',
  },
  {
    code: 'QF',
    name: 'Qantas Airways',
    nameBangla: 'কান্তাস এয়ারলাইন',
  },
  {
    code: 'SN',
    name: 'Brussels Airlines',
    nameBangla: '',
  },
  {
    code: 'SA',
    name: 'South African Airways',
    nameBangla: '',
  },
  {
    code: 'NZ',
    name: 'Air New Zealand',
    nameBangla: '',
  },
  {
    code: 'IT',
    name: 'Kingfisher Airlines',
    nameBangla: '',
  },
  {
    code: 'KD',
    name: 'KD Avia',
    nameBangla: '',
  },
  {
    code: 'IR',
    name: 'Iran Air',
    nameBangla: '',
  },
  {
    code: 'AI',
    name: 'Air India',
    nameBangla: '',
  },
  {
    code: 'AY',
    name: 'Finnair',
    nameBangla: 'ফিনায়ার এয়ারলাইন',
  },
  {
    code: 'BW',
    name: 'Caribbean Airlines',
    nameBangla: '',
  },
  {
    code: 'FI',
    name: 'Icelandair',
    nameBangla: '',
  },
  {
    code: 'CK',
    name: 'China Cargo Airlines',
    nameBangla: '',
  },
  {
    code: 'LY',
    name: 'EL AL',
    nameBangla: '',
  },
  {
    code: ' JU',
    name: 'JAT Airways',
    nameBangla: '',
  },
  {
    code: 'SK',
    name: 'SAS-Scandinavian Airlines System',
    nameBangla: '',
  },
  {
    code: 'SK',
    name: 'SAS-Scandinavian Airlines System',
    nameBangla: '',
  },
  {
    code: ' DT',
    name: 'TAAG Angola Airlines',
    nameBangla: '',
  },
  {
    code: 'LM',
    name: 'Air ALM',
    nameBangla: '',
  },
  {
    code: ' AH',
    name: 'Air Algerie',
    nameBangla: '',
  },
  {
    code: 'BA',
    name: 'British Airways',
    nameBangla: 'ব্রিটিশ এয়ারওয়েজ',
  },
  {
    code: 'GA',
    name: 'Garuda Indonesia',
    nameBangla: '',
  },
  {
    code: 'MP',
    name: 'Martinair Cargo',
    nameBangla: '',
  },
  {
    code: 'JL',
    name: 'Japan Airlines',
    nameBangla: '',
  },
  {
    code: ' LR',
    name: 'LACSA Airlines of Costa Rica',
    nameBangla: '',
  },
  {
    code: 'AM',
    name: 'Aeromexico Cargo',
    nameBangla: '',
  },
  {
    code: ' LI',
    name: 'LIAT Airlines',
    nameBangla: '',
  },
  {
    code: 'AT',
    name: 'Royal Air Maroc',
    nameBangla: '',
  },
  {
    code: 'LN',
    name: 'Libyan Airlines',
    nameBangla: '',
  },
  {
    code: 'QR',
    name: 'Qatar Airways',
    nameBangla: 'কাতার এয়ারওয়েজের',
  },
  {
    code: ' CX',
    name: 'Cathay Pacific Airways',
    nameBangla: '',
  },
  {
    code: '3V',
    name: 'TNT Airways',
    nameBangla: '',
  },
  {
    code: 'JP',
    name: 'Adria Airways',
    nameBangla: '',
  },
  {
    code: 'CV',
    name: 'Cargolux Airlines',
    nameBangla: '',
  },
  {
    code: 'EK',
    name: 'Emirates',
    nameBangla: 'এমিরেটস এয়ারলাইন',
  },
  {
    code: 'KE',
    name: 'Korean Air',
    nameBangla: 'কোরিয়ান এয়ার',
  },
  {
    code: ' MA',
    name: 'Malev Hungarian Airlines',
    nameBangla: '',
  },
  {
    code: 'RG',
    name: 'VARIG Brazilian Airlines',
    nameBangla: '',
  },
  {
    code: 'JI',
    name: 'Jade Cargo International',
    nameBangla: '',
  },
  {
    code: 'JM',
    name: 'Air Jamaica',
    nameBangla: '',
  },
  {
    code: 'TA',
    name: 'TACA',
    nameBangla: '',
  },
  {
    code: ' NH',
    name: 'ANA All Nippon Cargo',
    nameBangla: '',
  },
  {
    code: 'PK',
    name: 'Pakistan Int´l Airlines',
    nameBangla: '',
  },
  {
    code: 'TG',
    name: 'Thai Airways',
    nameBangla: 'থাই এয়ারওয়েজ',
  },
  {
    code: 'KU',
    name: 'Kuwait Airways',
    nameBangla: 'কুয়েত এয়ারওয়েজ',
  },
  {
    code: 'CM',
    name: 'Copa Airlines Cargo',
    nameBangla: '',
  },
  {
    code: 'NG',
    name: 'Lauda Air',
    nameBangla: '',
  },
  {
    code: 'JD',
    name: 'Japan Air System',
    nameBangla: '',
  },
  {
    code: 'TK',
    name: 'Turkish Airlines',
    nameBangla: 'তুর্কি এয়ারলাইনস',
  },
  {
    code: 'BD',
    name: 'British Midland Airways',
    nameBangla: '',
  },
  {
    code: 'MK',
    name: 'Air Mauritius',
    nameBangla: '',
  },
  {
    code: 'OS',
    name: 'Austrian Cargo',
    nameBangla: '',
  },
  {
    code: 'MD',
    name: 'Air Madagascar',
    nameBangla: '',
  },
  {
    code: 'EF',
    name: 'Far Eastern Air Transport',
    nameBangla: '',
  },
  {
    code: 'LT',
    name: 'LTU (Leisure Cargo)',
    nameBangla: '',
  },
  {
    code: 'TL',
    name: 'Trans Mediterranean Airways',
    nameBangla: '',
  },
  {
    code: 'K4',
    name: 'Kalitta Air',
    nameBangla: '',
  },
  {
    code: 'LD',
    name: 'Air Hong Kong',
    nameBangla: '',
  },
  {
    code: 'CI',
    name: 'China Airlines',
    nameBangla: '',
  },
  {
    code: '5S',
    name: 'Global Aviation and Services',
    nameBangla: '',
  },
  {
    code: 'OO',
    name: 'Sky West Airlines',
    nameBangla: '',
  },
  {
    code: 'WE',
    name: 'Centurion Air Cargo',
    nameBangla: '',
  },
  {
    code: 'SC',
    name: 'Shandong Airlines (Chinese)',
    nameBangla: '',
  },
  {
    code: 'RF',
    name: 'Florida West International Airways',
    nameBangla: '',
  },
  {
    code: 'NC',
    name: 'Northern Air Cargo',
    nameBangla: '',
  },
  {
    code: 'C8',
    name: 'Cargolux Italia',
    nameBangla: '',
  },
  {
    code: '5Y',
    name: 'Atlas Air',
    nameBangla: '',
  },
  {
    code: 'KX',
    name: 'Cayman Airways',
    nameBangla: '',
  },
  {
    code: 'A3',
    name: 'Aegean Airlines',
    nameBangla: '',
  },
  {
    code: 'PO',
    name: 'Polar Air Cargo',
    nameBangla: '',
  },
  {
    code: 'JW',
    name: 'Arrow Air',
    nameBangla: '',
  },
  {
    code: '5X',
    name: 'UPS Air Cargo',
    nameBangla: '',
  },
  {
    code: ' N8',
    name: 'National Air Cargo',
    nameBangla: '',
  },
  {
    code: 'S7',
    name: 'Siberia Airlines',
    nameBangla: '',
  },
  {
    code: 'ER',
    name: 'DHL Aviation/DHL Airways',
    nameBangla: '',
  },
  {
    code: 'KC',
    name: 'Air Astana',
    nameBangla: '',
  },
  {
    code: 'ZH',
    name: 'Shenzhen Airlines (Chinese)',
    nameBangla: '',
  },
  {
    code: 'SU',
    name: 'Aeroflot',
    nameBangla: '',
  },
  {
    code: 'WN',
    name: 'Southwest Airlines',
    nameBangla: '',
  },
  {
    code: 'A2',
    name: 'Cielos Airlines',
    nameBangla: '',
  },
  {
    code: 'M3',
    name: 'ABSA Aerolinhas Brasileiras',
    nameBangla: '',
  },
  {
    code: 'M2',
    name: 'Mario’s Ai',
    nameBangla: '',
  },
  {
    code: 'XQ',
    name: 'Sun Express',
    nameBangla: '',
  },
  {
    code: 'PS',
    name: 'Ukraine Int´l Airlines',
    nameBangla: '',
  },
  {
    code: ' 9U',
    name: 'Air Moldova',
    nameBangla: '',
  },
  {
    code: '7C',
    name: 'Coyne Airways',
    nameBangla: '',
  },
  {
    code: 'RU',
    name: 'AirBridge Cargo',
    nameBangla: '',
  },
  {
    code: '9W',
    name: 'Jet Airways',
    nameBangla: '',
  },
  {
    code: 'UL',
    name: 'SriLankan Cargo',
    nameBangla: 'শ্রীলঙ্কান এয়ারলাইন্স',
  },
  {
    code: 'UY',
    name: 'Cameroon Airlines',
    nameBangla: '',
  },
  {
    code: 'QY',
    name: 'DHL Aviation / European Air Transport',
    nameBangla: '',
  },
  {
    code: 'SQ',
    name: 'Singapore Airlines',
    nameBangla: 'সিঙ্গাপুর এয়ারলাইন্স',
  },
  {
    code: 'FB',
    name: 'Bulgaria Air',
    nameBangla: '',
  },
  {
    code: 'GL',
    name: 'Air Greenland',
    nameBangla: '',
  },
  {
    code: 'IY',
    name: 'Yemenia Yemen Airways',
    nameBangla: '',
  },
  {
    code: 'KM',
    name: 'Air Malta',
    nameBangla: '',
  },
  {
    code: 'PX',
    name: 'Air Niugini',
    nameBangla: '',
  },
  {
    code: 'BT',
    name: 'Air Baltic',
    nameBangla: '',
  },
  {
    code: 'BI',
    name: 'Royal Brunei Airlines',
    nameBangla: '',
  },
  {
    code: 'NX',
    name: 'Air Macau',
    nameBangla: '',
  },
  {
    code: 'BR',
    name: 'Eva Airways',
    nameBangla: '',
  },
  {
    code: '5C',
    name: 'CAL Cargo Air Lines',
    nameBangla: '',
  },
  {
    code: 'KQ',
    name: 'Kenya Airways',
    nameBangla: '',
  },
  {
    code: 'MB',
    name: 'MNG Airlines',
    nameBangla: '',
  },
  {
    code: 'LX',
    name: 'Swiss Airlines',
    nameBangla: 'সুইস ইন্টারন্যাশনাল এয়ার লাইনস',
  },
  {
    code: 'QT',
    name: 'Tampa Airlines',
    nameBangla: '',
  },
  {
    code: 'MF',
    name: 'Xiamen Airlines',
    nameBangla: '',
  },
  {
    code: 'SP',
    name: 'SATA Air Acores',
    nameBangla: '',
  },
  {
    code: 'VN',
    name: 'Vietnam Airlines',
    nameBangla: '',
  },
  {
    code: 'SM',
    name: 'Avient',
    nameBangla: '',
  },
  {
    code: 'J2',
    name: 'Azerbaijan Airlines',
    nameBangla: '',
  },
  {
    code: 'FM',
    name: 'Shanghai Airlines',
    nameBangla: '',
  },
  {
    code: 'MU',
    name: 'China Eastern Airlines',
    nameBangla: '',
  },
  {
    code: 'CZ',
    name: 'China Southern Airlines',
    nameBangla: '',
  },
  {
    code: 'GD',
    name: 'Grandstar Cargo',
    nameBangla: '',
  },
  {
    code: 'AE',
    name: 'Mandarin Airlines',
    nameBangla: '',
  },
  {
    code: ' M6',
    name: 'Amerijet International',
    nameBangla: '',
  },
  {
    code: 'S6',
    name: 'SAC South American Airways',
    nameBangla: '',
  },
  {
    code: 'F4',
    name: 'Shanghai Airlines Cargo',
    nameBangla: '',
  },
  {
    code: 'OU',
    name: 'Croatia Airlines',
    nameBangla: '',
  },
  {
    code: 'N8',
    name: 'Hong Kong Airlines',
    nameBangla: '',
  },
  {
    code: 'FK',
    name: 'Africa West',
    nameBangla: '',
  },
  {
    code: 'EV',
    name: 'Atlantic Southeast Airlines',
    nameBangla: '',
  },
  {
    code: 'MY',
    name: 'MASAir',
    nameBangla: '',
  },
  {
    code: 'VV',
    name: 'Aerosvit',
    nameBangla: '',
  },
  {
    code: 'Y8',
    name: 'Yangtze River Express Airlines',
    nameBangla: '',
  },
  {
    code: '6R',
    name: 'AeroUnion',
    nameBangla: '',
  },
  {
    code: '3U',
    name: 'Sichuan Airlines',
    nameBangla: '',
  },
  {
    code: 'HU',
    name: 'Hainan Airlines (Chinese)',
    nameBangla: '',
  },
  {
    code: 'DE',
    name: 'Condor Flugdienst',
    nameBangla: '',
  },
  {
    code: 'OH',
    name: 'Comair',
    nameBangla: '',
  },
  {
    code: 'B1',
    name: 'TAB Cargo',
    nameBangla: '',
  },
  {
    code: ' QN',
    name: 'Air Armenia',
    nameBangla: '',
  },
  {
    code: 'UZ',
    name: 'Buraq Air Transport (Arabic only)',
    nameBangla: '',
  },
  {
    code: 'VS',
    name: 'Virgin Atlantic',
    nameBangla: '',
  },
  {
    code: 'KZ',
    name: 'Nippon Cargo Airlines',
    nameBangla: '',
  },
  {
    code: 'KZ',
    name: 'Nippon Cargo Airlines',
    nameBangla: '',
  },
  {
    code: 'JJ',
    name: 'TAM Brazilian Airlines',
    nameBangla: '',
  },
  {
    code: '7I',
    name: 'Insel Air Cargo',
    nameBangla: '',
  },
  {
    code: 'OV',
    name: 'Estonian Air',
    nameBangla: '',
  },
  {
    code: 'QO',
    name: 'Aeromexpress Cargo',
    nameBangla: '',
  },
  {
    code: 'OZ',
    name: 'Asiana Airlines',
    nameBangla: 'এশিয়ানা এয়ারলাইন্স',
  },
  {
    code: ' IJ',
    name: 'Great Wall Airlines',
    nameBangla: '',
  },
  {
    code: 'UX',
    name: 'Air Europa Cargo',
    nameBangla: '',
  },
  {
    code: 'BG',
    name: 'Biman Bangladesh',
    nameBangla: 'বিমান বাংলাদেশ এয়ারলাইন্স',
  },
  {
    code: 'CA',
    name: 'Air China',
    nameBangla: '',
  },
  {
    code: 'H1',
    name: 'Hahn Air',
    nameBangla: 'হ্যান এয়ার',
  },
  {
    code: 'FZ',
    name: 'Fly Dubai',
    nameBangla: 'ফ্লাই দুবাই',
  },
  {
    code: 'EY',
    name: 'Etihad Airways',
    nameBangla: 'ইতিহাদ এয়ারওয়েজের',
  },
  {
    code: 'OD',
    name: 'Malindo Air',
    nameBangla: 'মালিন্দো এয়ার',
  },
  {
    code: 'UK',
    name: 'Vistara',
    nameBangla: 'বিস্তারা এয়ারলাইন',
  },
  {
    code: 'WY',
    name: 'Oman Air',
    nameBangla: 'ওমান এয়ার',
  },
  {
    code: 'H9',
    name: 'Himalaya Airlines',
    nameBangla: 'হিমালয় এয়ারলাইন্স',
  },
  {
    code: 'PG',
    name: 'Bangkok Airways',
    nameBangla: 'ব্যাংকক এয়ারওয়েজ',
  },
  {
    code: 'MH',
    name: 'Malaysia Airlines',
    nameBangla: 'মালয়েশিয়া এয়ারলাইন্স',
  },
  {
    code: 'RJ',
    name: 'Royal Jordanian',
    nameBangla: 'রয়্যাল জর্ডানিয়ান এয়ারলাইন্স',
  },
  {
    code: 'KL',
    name: 'KLM Royal Dutch Airlines',
    nameBangla: 'কেএলএম এয়ারলাইন',
  },
  {
    code: 'B6',
    name: 'JetBlue Airline',
    nameBangla: 'জেটব্লু এয়ারওয়েজ',
  },
  {
    code: 'VS',
    name: 'Virgin Atlantic Airway',
    nameBangla: '',
  },
  {
    code: 'ET',
    name: 'Ethiopian Airlines',
    nameBangla: 'ইথিওপিয়ান এয়ারলাইন্স',
  },
  {
    code: 'AS',
    name: 'Alaska Airlines',
    nameBangla: 'আলাস্কা এয়ারলাইন্স',
  },
  {
    code: 'NH',
    name: 'All Nippon Airways',
    nameBangla: 'সমস্ত নিপ্পন এয়ারওয়েজ',
  },
  {
    code: '6E',
    name: 'IndiGo',
    nameBangla: 'ইন্ডিগো বাংলা',
  },
  {
    code: 'VQ',
    name: 'NOVOAIR',
    nameBangla: 'নভোএয়ার',
  },
  {
    code: 'BS',
    name: 'USBangla Airlines',
    nameBangla: ' ইউএস বাংলা এয়ারলাইন্স',
  },
  {
    code: 'J9',
    name: 'Jazeera Airways',
    nameBangla: 'জাজিরা এয়ারওয়েজ',
  },
  {
    code: 'SG',
    name: 'SpiceJet',
    nameBangla: 'স্পাইসজেট',
  },
  {
    code: 'G9',
    name: 'Air Arabia',
    nameBangla: 'এয়ার এরাবিয়া',
  },
  {
    code: '3L',
    name: 'InterSky',
    nameBangla: 'ইন্টারস্কাই',
  },
];

export default Airlines;
