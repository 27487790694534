import { Grid, Box, Typography, Tooltip, Stack } from "@mui/material";
import React from "react";
import Transit from "./Transit";
import moment from "moment";
import CancelIcon from "@mui/icons-material/Cancel";
import LuggageIcon from "@mui/icons-material/Luggage";

import LiquorIcon from "@mui/icons-material/Liquor";
import TvIcon from "@mui/icons-material/Tv";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import DashboardIcon from "@mui/icons-material/Dashboard";
import UsbIcon from "@mui/icons-material/Usb";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import WifiIcon from "@mui/icons-material/Wifi";
const FlightLayout = ({
  flightData,
  allData,
  index,
  arr,
  handleBox,
  checked,
  disabled,
  icon,
}) => {
  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
      <Grid item xs={5} sm={2.5} md={2.5}>
        <Box>
          <Box
            sx={{
              width: { xs: "30px", md: "40px" },
              height: { xs: "30px", md: "40px" },
            }}
          >
            <img
              src={
                flightData?.Segments[0]?.MarketingCarrier === "XY"
                  ? `https://airlineimages.s3.ap-southeast-1.amazonaws.com/128/XY.png`
                  : `https://flyjatt-b2b.sgp1.cdn.digitaloceanspaces.com/airlines/logo/${flightData?.Segments[0]?.MarketingCarrier?.toLowerCase()}.webp`
              }
              alt={`${flightData?.Segments[0]?.MarketingCarrier}`}
              width="100%"
              height="100%"
            />
          </Box>

          <Tooltip title={`${flightData?.Segments[0]?.MarketingCarrierName}`}>
            <Typography
              fontSize={{ xs: "10px", md: "13px" }}
              sx={{
                width: "100%",
                cursor: "pointer",
                color: "var(--primary-color)",
                fontWeight: 500,
              }}
              noWrap
            >
              {`${flightData?.Segments[0]?.MarketingCarrierName}`}
            </Typography>
          </Tooltip>
          <Typography
            sx={{
              display: arr.length - 1 === index ? "unset" : "none",
            }}
          >
            {allData?.Refundable === true ? (
              <Typography
                sx={{
                  color: "var(--gray)",
                  fontSize: { xs: "10px", md: "12px" },
                }}
              >
                Refundable
              </Typography>
            ) : (
              <Typography
                sx={{
                  color: "var(--gray)",
                  fontSize: { xs: "10px", md: "12px" },
                }}
              >
                Non Refundable
              </Typography>
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={7} sm={3.5} md={3.5}>
        <Box sx={{ position: "relative" }}>
          {index === 0 ? (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                display: { xs: "flex", sm: "none" },
                justifyContent: "end",
              }}
            >
              {icon ? (
                <CancelIcon onClick={() => handleBox(allData)} />
              ) : (
                <div className="custom-checkbox-air">
                  <input
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={() => handleBox(allData)}
                  />
                </div>
              )}
            </Box>
          ) : (
            ""
          )}
          <Typography sx={{ fontSize: 12, color: "var(--gray)" }}>
            From
          </Typography>
          <Stack direction="row" spacing={0.3} alignItems="center">
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "14px",
                  sm: "16px",
                },
              }}
            >
              {moment(flightData?.Segments[0]?.DepTime?.split("+")[0])?.format(
                "h:mmA"
              )}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                },
              }}
              noWrap
            >
              {" - "}
              {flightData?.Segments[0]?.DepLocation?.split(",")[0]}
            </Typography>
          </Stack>

          <Tooltip
            title={`${flightData?.Segments[0]?.DepAirPort}${", "}${
              flightData?.Segments[0]?.DepFrom
            }`}
          >
            <Typography
              sx={{
                width: "100%",
                cursor: "pointer",
                color: "var(--fontcolor)",
                fontWeight: 400,
                fontSize: {
                  xs: "12px",
                },
              }}
              noWrap
            >
              {flightData?.Segments[0]?.DepFrom}
              {" - "}
              {flightData?.Segments[0]?.DepAirPort}
            </Typography>
          </Tooltip>
        </Box>
        {/* icons */}
        <Box
          sx={{
            display: "flex",
            jusitfyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            mt: 3,
          }}
        >
          <Tooltip
            title={
              <div>
                <div>
                  {` Alcoholic Cost: ${flightData?.Segments[0]?.Amenities?.beverage?.alcoholicCost}`}
                </div>
                <div>
                  {`  Non Alcoholic Cost: ${flightData?.Segments[0]?.Amenities?.beverage?.nonAlcoholicCost}`}
                </div>
                <div>
                  {`   
          Exist: ${flightData?.Segments[0]?.Amenities?.beverage?.exists}`}
                </div>
              </div>
            }
          >
            <LiquorIcon sx={{ fontSize: "16px", color: "var(--fontcolor)" }} />
          </Tooltip>

          <Tooltip
            title={
              <div>
                <div>
                  {`Cost: ${flightData?.Segments[0]?.Amenities?.entertainment?.cost}
            `}
                </div>
                <div>
                  {`Type: ${flightData?.Segments[0]?.Amenities?.entertainment?.type}
            `}
                </div>

                <div>
                  {`Exist: ${flightData?.Segments[0]?.Amenities?.beverage?.exists}`}
                </div>
              </div>
            }
          >
            <TvIcon sx={{ fontSize: "16px", color: "var(--fontcolor)" }} />
          </Tooltip>

          <Tooltip
            title={
              <div>
                <div>
                  {`Cost: ${flightData?.Segments[0]?.Amenities?.food?.cost}
            
           `}
                </div>
                <div>
                  {` Type: ${flightData?.Segments[0]?.Amenities?.food?.type}`}
                </div>
                <div>
                  {`   
          Exist: ${flightData?.Segments[0]?.Amenities?.food?.exists}`}
                </div>
              </div>
            }
          >
            <FastfoodIcon
              sx={{ fontSize: "16px", color: "var(--fontcolor)" }}
            />
          </Tooltip>

          <Tooltip
            title={
              <div>
                <div>
                  {`Row Layout: ${flightData?.Segments[0]?.Amenities?.layout?.rowLayout}`}
                </div>

                <div>
                  {`
            
            Direct Aisle Access: ${flightData?.Segments[0]?.Amenities?.layout?.directAisleAccess}
         
            
            
            
            `}
                </div>
              </div>
            }
          >
            <DashboardIcon
              sx={{ fontSize: "16px", color: "var(--fontcolor)" }}
            />
          </Tooltip>

          <Tooltip
            title={
              <div>
                <div>
                  {`Cost: ${flightData?.Segments[0]?.Amenities?.power?.cost}`}
                </div>

                <div>
                  {`    Distribution: ${flightData?.Segments[0]?.Amenities?.power?.distribution}`}
                </div>
                <div>
                  {`
            
        
         
            Type: ${flightData?.Segments[0]?.Amenities?.power?.type}
         
            
            
            `}
                </div>
              </div>
            }
          >
            <UsbIcon sx={{ fontSize: "16px", color: "var(--fontcolor)" }} />
          </Tooltip>

          <Tooltip
            title={`
         
            Exists: ${flightData?.Segments[0]?.Amenities?.wifi?.exists}
         
            
            
            `}
          >
            <WifiIcon sx={{ fontSize: "16px", color: "var(--fontcolor)" }} />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={5} sm={2.5} md={2.5} mt={{ xs: 1.5, sm: "0" }}>
        <Transit transit={flightData} />
      </Grid>
      <Grid item xs={7} sm={3.5} md={3.5}>
        <Box position={"relative"}>
          {index === 0 ? (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                display: { xs: "none", sm: "flex" },
                justifyContent: "end",
              }}
            >
              {icon ? (
                <CancelIcon
                  sx={{
                    visibility: disabled ? "hidden" : "unset",
                    color: "crimson",
                  }}
                  onClick={() => handleBox(allData)}
                />
              ) : (
                <div className="custom-checkbox-air">
                  <input
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={() => handleBox(allData)}
                  />
                </div>
              )}
            </Box>
          ) : (
            ""
          )}

          <Typography sx={{ fontSize: 12, color: "var(--gray)" }}>
            To
          </Typography>
          <Stack direction="row" spacing={0.3} alignItems="center">
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "14px",
                  sm: "16px",
                },
              }}
            >
              {moment(
                flightData?.Segments[
                  flightData?.Segments?.length - 1
                ]?.ArrTime?.split("+")[0]
              )?.format("h:mmA")}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                },
              }}
              noWrap
            >
              {" - "}
              {
                flightData?.Segments[
                  flightData?.Segments?.length - 1
                ]?.ArrLocation.split(",")[0]
              }
            </Typography>
          </Stack>

          <Tooltip
            title={`${
              flightData?.Segments[flightData?.Segments?.length - 1]?.ArrAirPort
            }${", "}${
              flightData?.Segments[flightData?.Segments?.length - 1]?.ArrTo
            }`}
          >
            <Typography
              sx={{
                width: "100%",
                cursor: "pointer",
                color: "var(--fontcolor)",
                fontWeight: 400,
                fontSize: {
                  xs: "12px",
                },
              }}
              noWrap
            >
              {flightData?.Segments[flightData?.Segments?.length - 1]?.ArrTo}
              {" - "}
              {
                flightData?.Segments[flightData?.Segments?.length - 1]
                  ?.ArrAirPort
              }
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            jusitfyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Tooltip
            title={`Pitch: ${flightData?.Segments[0]?.Amenities?.seat?.pitch}
           
         
            Type: ${flightData?.Segments[0]?.Amenities?.seat?.type}
         
            
            
            `}
          >
            <EventSeatIcon
              sx={{ fontSize: "16px", color: "var(--fontcolor)" }}
            />
          </Tooltip>

          <Tooltip
            title={`${allData?.PriceBreakDown[0]?.Bag[0].Allowance?.weight}
            
            ${allData?.PriceBreakDown[0]?.Bag[0].Allowance?.unit}
            `}
          >
            <LuggageIcon sx={{ fontSize: "16px", color: "var(--fontcolor)" }} />
          </Tooltip>
        </Box>
        {/* ------------- */}
      </Grid>
    </Grid>
  );
};

export default React.memo(FlightLayout);
