import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Header from './Header/Header';
import Loader from '../images/loader/Render.gif';
import Swal from 'sweetalert2';
import { useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import AgentMarkup from './AgentMarkup';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vh',
  height: 'fit-content',
  background: 'white',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};
const MyAccount = () => {
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem('user-info');
  let uid = users?.uid;
  let agentId = users?.agentId;
  const [account, setAccount] = useState(null);
  const [updateAcc, setUpdateAcc] = useState(null);
  const [reFetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    fetch(`https://api-b2b.projectota.com/agent/myaccount/${uid}`)
      .then((res) => res.json())
      .then((data) => {
        setAccount(data);
        setUpdateAcc(data);
        setIsLoading(false);
      });
  }, [uid, reFetch]);

  const handleOpenModal = () => {
    setUpdateAcc(account);
    setOpen(true);
  };

  const handleChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    setUpdateAcc({ ...updateAcc, [e.target.name]: e.target.value });
  };

  const handleOnChange = async (e) => {
    // handleFileRemove();
    const file = e.target.files[0];
    setFileUpload(file);
    if (file) {
      try {
        const dataUrl = await readDataUrl(file);
        setFile(dataUrl);
      } catch (error) {
        console.error('Error reading file:', error);
      }
    }
  };

  const handleFileRemove = () => {
    setFile(null);

    // Clear the input value to trigger the change event when the same file is selected again
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  // preview image  function
  const readDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e, logo) => {
    console.log('Test', e, logo);
    let body = {
      company: updateAcc?.company,
      name: updateAcc?.name,
      phone: updateAcc?.phone,
      address: updateAcc?.address,
      password: updateAcc?.password,
      markuptype: updateAcc?.markuptype,
      markup: updateAcc?.markup,
      logo: logo ? logo : account?.logo,
    };

    window.event.preventDefault();
    setIsSubmitting(true);
    let url = `https://api-b2b.projectota.com/agent/myaccount/${uid}`;

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setOpen(false);
        e.target.reset();
        Swal.fire({
          title: 'Account Updated',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(() => {
          setRefetch(!reFetch);
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      setIsSubmitting(false);
    } catch (err) {
      setOpen(false);
      e.target.reset();
      Swal.fire({
        title: err.message,
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Try Again',
      }).then(() => {
        setRefetch(!reFetch);
      });
      setIsSubmitting(false);
    }
  };

  const handleLogoSubmit = async (e) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('file', fileUpload);

    const url = `https://api-b2b.projectota.com/agent/upload/logo/${uid}`;

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    axios.post(url, formData, config).then((res) => {
      if (res?.data?.status === 'success') {
        console.log(res?.data);
        const logo = res?.data?.fileurl;
        // handleSubmit(e, logo);
        Swal.fire({
          icon: 'success',
          title: res?.data?.status,
          html: res?.data?.message,
          confirmButtonText: 'ok',
          confirmButtonColor: 'var(--primary-color)',
        }).then(() => {
          navigate(0);
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: res?.data?.status,
          html: res?.data?.message,
          confirmButtonText: 'ok',
          confirmButtonColor: 'var(--primary-color)',
        }).then(() => {
          navigate(0);
        });
      }
    });
  };

  if (isLoading) {
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '70vh',
          width: '70vw',
          marginInline: 'auto',
        }}
      >
        <Box
          style={{
            width: '50%',
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: '100%',
              objectFit: 'center',
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      {/* <Header /> */}
      <Box sx={{mx:4}}>
        <Box sx={{ width: '100%' ,mt:3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography
                  sx={{
                    color: 'var(--secondary)',
                    fontSize: 14,
                    mb: 1,
                  }}
                >
                  Logo (Max 2MB,JPG/PNG) 200X80px
                </Typography>

                <Box
                  sx={{
                    bgcolor: 'var(--bgcolor)',
                    p: 1,
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'left',
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                  onClick={handleButtonClick}
                >
                  <Box
                    sx={{
                      pb: 2,
                      display: 'flex',
                      justifyContent: 'start',
                      position: 'relative',
                      border: '1px solid var(--primary-color)',
                    }}
                  >
                    <img
                      src={file || `${account?.logo}?t=${new Date().getTime()}`}
                      alt="File Preview"
                      style={{
                        minWidth: '200px',
                        maxWidth: '200px',
                        maxHeight: '80px',
                      }}
                    />
                  </Box>
                  <Box>
                    <div className="custom-file-input-container">
                      <input
                        id="fileInput"
                        type="file"
                        required
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleOnChange}
                      />
                    </div>
                    <EditIcon
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: '200px',
                        border: '2px solid var(--primary-color)',
                        borderRadius: '50%',
                        p: 0.5,
                        zIndex: 1,
                        bgcolor: 'var(--white)',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ py: 3 }}>
                <Button
                  onClick={() => handleLogoSubmit()}
                  style={{
                    color: 'var(--white)',
                    textTransform: 'capitalize',
                    background: 'var(--primary-color)',
                    padding: '2px 20px',
                  }}
                >
                  Update Logo
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  color: 'var(--secondary-color)',
                  fontSize: 14,
                  span: {
                    fontWeight: 500,
                  },
                }}
              >
                <Box sx={{ fontWeight: 600 }}>
                  <span>Company:</span> {account?.company}
                </Box>

                <Box>
                  <span>Name:</span> {account?.name}
                </Box>
                <Box>
                  <span>Email:</span> {account?.email}
                </Box>
                <Box>
                  <span>Phone:</span> {account?.phone}
                </Box>
                <Box>
                  <span>Address:</span> {account?.address}
                </Box>
              </Box>
              <Box sx={{ py: 3 }}>
                <Button
                  onClick={() => handleOpenModal()}
                  style={{
                    color: 'var(--white)',
                    textTransform: 'capitalize',
                    background: 'var(--primary-color)',
                    padding: '2px 20px',
                  }}
                >
                  Update Information
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box my={4}>
            <Box sx={{ border: '1px solid var(secondary-color)', p: 2 }}>
              <AgentMarkup
                account={account}
                reFetch={reFetch}
                setRefetch={setRefetch}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
            </Box>
          </Box>

          <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={style}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 5,
                  }}
                >
                  <Typography
                    sx={{
                      color: 'var(--white)',
                      fontSize: '20px',
                      textAlign: 'center',
                      bgcolor: 'var(--primary-color)',
                      width: '100%',
                      py: 0.5,
                    }}
                  >
                    Update My Profile
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      label: {
                        fontSize: '14px',
                        color: 'var(--secondary-color)',
                        fontWeight: '500',
                      },
                      input: {
                        outline: 'none',
                        width: '100%',
                        border: '1px solid var(--secondary-color)',
                        color: 'var(secondary-color)',
                        fontSize: '14px',
                        borderRadius: '4px',
                        padding: '5px 10px',
                      },
                      select: {
                        outline: 'none',
                        width: '100%',
                        border: '1px solid var(--secondary-color)',
                        color: 'var(secondary-color)',
                        fontSize: '14px',
                        borderRadius: '4px',
                        padding: '5px 10px',
                      },
                      '.MuiTypography-root': {
                        fontSize: '14px ',
                        color: 'var(--primary-color)',
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item md={4}>
                        <label htmlFor="company">
                          <span style={{ color: 'red' }}>*</span>Company
                        </label>
                        <input
                          required
                          id="company"
                          name="company"
                          type="text"
                          value={updateAcc?.company}
                          placeholder="Account Name"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <label htmlFor="Name">
                          <span style={{ color: 'red' }}>*</span>Name
                        </label>
                        <input
                          required
                          id="name"
                          name="name"
                          type="text"
                          value={updateAcc?.name}
                          placeholder="Account Name"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <label htmlFor="Phone">
                          <span style={{ color: 'red' }}>*</span>Staff Phone
                        </label>
                        <input
                          required
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          value={updateAcc?.phone}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <label htmlFor="Email">
                          <span style={{ color: 'red' }}>*</span>Email
                        </label>
                        <input
                          required
                          autoComplete="off"
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Your Email"
                          value={updateAcc?.email}
                          readOnly
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <label htmlFor="address">
                          <span style={{ color: 'red' }}>*</span>Address
                        </label>
                        <input
                          required
                          autoComplete="off"
                          id="address"
                          name="address"
                          type="address"
                          placeholder="Your address"
                          value={updateAcc?.address}
                          readOnly
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <label htmlFor="address">
                          <span style={{ color: 'red' }}>*</span>Password
                        </label>
                        <input
                          required
                          autoComplete="off"
                          id="password"
                          name="password"
                          type="text"
                          placeholder="Your password"
                          value={updateAcc?.password}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid container item justifyContent={'space-between'}>
                        <Button
                          variant="contained"
                          style={{
                            color: '#fff',
                            backgroundColor: 'var(--primary-color)',
                            outline: 'none',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            padding: '5px 20px',
                            fontSize: '14px',
                          }}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            color: '#fff',
                            backgroundColor: 'black',
                            outline: 'none',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            padding: '5px 20px',
                            fontSize: '14px',
                            marginLeft: '20px',
                          }}
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};

export default MyAccount;
