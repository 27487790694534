import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

const PassengerDetails = ({ allData }) => {
  function parseNameWithPrefix(fullName) {
    const prefixes = ['MR', 'MS', 'MSTR', 'MISS'];
    const nameArray = fullName.split(' ');
    let prefix = '';
    let givenName = '';

    for (const part of nameArray) {
      if (prefixes.includes(part)) {
        prefix = part;
      } else {
        givenName += givenName ? ` ${part}` : part;
      }
    }

    return { prefix, givenName };
  }

  // Extract relevant information and remove nesting
  const outputJSON = allData?.sabredata?.travelers?.flatMap((entry) => {
    if (entry.identityDocuments) {
      const documentsWithNumber = entry?.identityDocuments?.filter(
        (doc) => doc.documentNumber
      );
      return documentsWithNumber.map((doc) => {
        const extractedDoc = {
          prefix: entry.givenName,
          givenName: doc.givenName,
          surname: doc.surname,
          documentNumber: doc.documentNumber,
          expiryDate: doc.expiryDate,
          birthDate: doc.birthDate,
          gender: doc.gender,
          countryCode: doc.issuingCountryCode,
          type: doc.gender.includes('_') ? 'INFANT' : entry.type,
        };
        return extractedDoc;
      });
    } else {
      return [];
    }
  });
  console.log(outputJSON);
  // Custom sorting function
  const customSort = (a, b) => {
    const order = { ADULT: 1, CHILD: 2, INFANT: 3 };
    return order[a.type] - order[b.type];
  };
  // Sort the data array using the custom sorting function
  const sortedData = outputJSON?.sort(customSort);
  console.log(sortedData);
  const adultPassengerList = allData?.sabredata?.travelers?.filter(
    (passenger) => passenger.passengerCode === 'ADT'
  );
  const childPassengerList = allData?.sabredata?.travelers?.filter(
    (passenger) => passenger.passengerCode === 'C07'
  );
  const infantPassengerList = allData?.sabredata?.travelers?.filter(
    (passenger) => passenger.passengerCode === 'INF'
  );
  console.log('Adult Passenger List:', adultPassengerList);
  console.log('Child Passenger List:', childPassengerList);
  console.log('Infant Passenger List:', infantPassengerList);
  return (
    <Box className="queue-detail-passenger-detail">
      <div>
        {/* <Box>
          {infantPassengerList?.map((item, i) => {
            return (
              <Box key={i}>
                Number:
                {adultPassengerList[i].identityDocuments[1].documentNumber}
                Name:{adultPassengerList[i].identityDocuments[1].givenName}
              </Box>
            );
          })}
        </Box> */}
        <Box>
          {sortedData?.map((traveler, index) => (
            <Box key={index}>
              <Box
                p="3px 5px"
                border="1px solid #DEDEDE"
                borderRadius="4px"
                my="2px"
                display={'flex'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <h5
                  style={{
                    color: 'var(--secondary-color)',
                    fontWeight: '500',
                    fontSize: '15px',
                  }}
                >
                  {traveler?.givenName} {traveler?.surname}
                </h5>
              </Box>

              <Box
                border="1px solid #DEDEDE"
                borderRadius="4px"
                p="3px 5px"
                mb={2}
              >
                <Grid container spacing={1}>
                  <Grid item xs={4} sm={3} md={2}>
                    <h5>First Name</h5>
                    <h6>{traveler?.givenName}</h6>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <h5>Last Name</h5>
                    <h6>{traveler?.surname}</h6>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <h5>Nationality</h5>
                    <h6>{traveler?.countryCode}</h6>
                  </Grid>

                  <Grid item xs={4} sm={3} md={2}>
                    <h5>Date of Birth</h5>
                    <h6>
                      {traveler?.birthDate
                        ? format(new Date(traveler?.birthDate), 'dd MMM yyyy')
                        : 'Date of Birth'}
                    </h6>
                  </Grid>

                  <Grid item xs={4} sm={3} md={2}>
                    <h5>Gender</h5>
                    <h6>
                      {traveler?.gender?.split('_')[1] || traveler?.gender}
                    </h6>
                  </Grid>

                  <Grid item xs={4} sm={3} md={2}>
                    <h5>Pax Type</h5>
                    <h6>{traveler?.type}</h6>
                  </Grid>

                  <Grid item xs={4} sm={3} md={2}>
                    <h5>Passport Number</h5>
                    <h6>
                      {traveler?.documentNumber === '' ||
                      traveler?.documentNumber === null
                        ? 'Domestic Flight'
                        : traveler?.documentNumber?.toUpperCase()}
                    </h6>
                  </Grid>
                  <Grid item xs={4} sm={3} md={2}>
                    <h5>P. Expire Date</h5>

                    <h6>
                      {traveler?.expiryDate === '' ||
                      traveler?.expiryDate === null
                        ? 'Domestic Flight'
                        : format(new Date(traveler?.expiryDate), 'dd MMM yyyy')}
                    </h6>
                  </Grid>

                  {allData?.sabredata?.flightTickets === undefined ? null : (
                    <Grid item xs={6} sm={3} md={2}>
                      <h5>Ticket Number</h5>

                      <h6>
                        {allData?.sabredata?.flightTickets[index]?.number}
                      </h6>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          ))}
        </Box>
      </div>
    </Box>
  );
};

export default PassengerDetails;
