import { Box, Modal, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
const updateModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#fff',
  py: 3,
  px: 3,
  borderRadius: '10px',
  overflow: 'auto',
};

const FareDetails = ({ allData }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const body = {
    System: 'Sabre',
    DepDate: allData?.bookingdata?.flightdate?.slice(0, 10),
    Origin: allData?.bookingdata?.depfrom,
    Destination: allData?.bookingdata?.arrto,
    Carrier: allData?.sabredata?.flights?.[0]?.airlineCode,
    FareBasisCode:
      allData?.sabredata?.fares?.[0]?.fareConstruction?.[0]?.fareBasisCode,
  };
  const url = 'https://api-b2b.projectota.com/flight/fare/rules';

  const postData = async (url, body) => {
    try {
      setLoading(true);
      const response = await axios.post(url, body);
      console.log('Success:', response.data);
      setData(response.data);
    } catch (error) {
      if (error.response) {
        console.error('Headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received from server');
      } else {
        console.error('Error:', error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleOpen = async () => {
    postData(url, body); // Wait for the postData to complete before setting open to true
    setOpen(true);
  };
  console.log(data);
  console.log(body);

  return (
    <Box>
      <Box
        sx={{
          cursor: 'pointer',
          bgcolor: 'var(--primary-color)',
          color: 'var(--white)',
          p: '5px 20px',
          textAlign: 'center',
          mt: 2,
        }}
        onClick={handleOpen}
        disabled={open}
      >
        Fare Details
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          bgcolor="#fff"
          sx={{
            ...updateModalStyle,
            width: { xs: '95%', sm: '80%', md: '60%' },
            maxHeight: { xs: '40vh', sm: '60vh' },
            p: { xs: 2, sm: 3 },
            msOverflowY: 'scrollable',
          }}
        >
          <Typography
            sx={{
              color: '#222222',
              fontSize: '20px',
              fontWeight: 500,
              mb: '10px',
              textAlign: 'center',
            }}
          >
            Fare Policy Details
          </Typography>
          <Box my={2}>
            <Typography sx={{ color: '#dc143c' }}>Refund Policy</Typography>
            <Typography sx={{ mt: 2, fontSize: 12 }}>
              {loading ? 'Loading...' : data?.refundpolicy?._text}
            </Typography>
          </Box>
          <Box my={2}>
            <Typography sx={{ color: '#dc143c' }}>Reissue Policy</Typography>
            <Typography sx={{ mt: 2, fontSize: 12 }}>
              {loading ? 'Loading...' : data?.reissuepolicy?._text}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default FareDetails;
