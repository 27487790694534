import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import ServerDown from '../images/undraw/undraw_server_down_s-4-lk.svg';

import Swal from 'sweetalert2';

const useAuthentication = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const loginUser = async (loginData, location, navigate) => {
    try {
      setIsLoading(true);

      // Store login data in secureLocalStorage
      secureLocalStorage.setItem('state', loginData);

      // Prepare the request body
      const body = JSON.stringify({
        email: loginData.email,
        password: loginData.password,
      });

      // Choose the URL based on the location
      const url =
        location.pathname === '/admin'
          ? `https://api-b2b.projectota.com/auth/admin/signin`
          : `https://api-b2b.projectota.com/auth/signin`;

      // Make the fetch request
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        body: body,
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error(`${response.status}`);
      }

      const data = await response.json();

      setIsLoading(false);
      if (data) {
        // Handle success
        secureLocalStorage.setItem(
          location.pathname === '/admin' ? 'admin-info' : 'user-info',
          data
        );
        const destination =
          location.pathname === '/admin' ? '/dashboard' : '/user/dashboard';
        navigate(destination);
      } else {
        // Handle other cases
        secureLocalStorage.removeItem('user-info');
        secureLocalStorage.removeItem('commissionData');
        setError(data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(true);
      if (error.message.includes('401')) {
        setError(
          location.pathname === '/admin'
            ? 'Admin Wrong password'
            : 'Agent Wrong password'
        );
      } else {
        setError(
          location.pathname === '/admin' ? 'Admin not found' : 'Agent not found'
        );
      }
    }
  };

  const logout = () => {
    secureLocalStorage.removeItem('user-info');
    secureLocalStorage.removeItem('commissionData');
    secureLocalStorage.removeItem('state');
    secureLocalStorage.removeItem('admin-info');
    navigate('/');
  };

  return {
    loginUser,
    logout,
    isLoading,
    error,
  };
};

export default useAuthentication;
