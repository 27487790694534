import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Modal,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../images/loader/Render.gif';
import ReConfirm from '../../images/undraw/undraw_confirmation_re_b6q5.svg';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import './BookingDetails.css';
import secureLocalStorage from 'react-secure-storage';
import { AdminHeader } from '../AdminHeader/AdminHeader';
import AirlineNames from './AirlineNames';
import Address from './Address';
import AllPDF from './AllPDF';
import FareRules from './FareRules';

const styles = {
  button: {
    padding: '6px 20px',
    marginRight: '20px',
    color: '#fff',
    backgroundColor: 'var(--primary-color)',
    border: 'none',
    cursor: 'pointer',
  },
  buttonDisabled: {
    padding: '6px 20px',
    marginRight: '20px',
    color: '#fff',
    backgroundColor: 'var(--primary-color)',
    border: 'none',
    cursor: 'not-allowed',
  },
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const BookingDetails = () => {
  const aminInfo = secureLocalStorage.getItem('admin-info');
  let token = aminInfo?.token;
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [downExpanded, setDownExpanded] = useState('panel1');
  const handleChangeDown = (panel) => (event, newExpanded) => {
    setDownExpanded(newExpanded ? panel : false);
  };
  const { bookingUId, bookingId, agentId, companyname } = location?.state.data;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [passengerData, setPassengerData] = useState([]);
  //todo:state change
  const [state, setState] = useState(false);

  let count = 0;
  const passengerCount = (p) => {
    count = count + p;
    return count;
  };

  // Qoutation Satat start
  // all Modal State Declare
  const [modalOpen, setModalOpen] = useState({
    cIssue: false,
    cAllIissue: false,
    value: '',
    cancel: '',
    quotation: '',
  });

  const [isDisabled, setIsDisabled] = useState(false);

  // al Action buton state start ----
  const [stateChange, setStateChange] = useState(false);

  // All Action State Modal start

  const [remark, setRemark] = useState('');
  const [penalty, setPenalty] = useState(0);

  // all Action buton state end ----
  // Qoutation Satat end

  // --------------------- client information start ---------------------------

  // visa and passport copy update state
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const handleOpenUpdateModal = () => setOpenUpdateModal(true);
  const handleCloseUpdateModal = () => {
    setState((prev) => !prev);
    setOpenUpdateModal(false);
    // navigate(0);
  };

  const tripType = location?.state.tripType;

  const [flightData, setFlightData] = useState([]);
  const [fareTotal, setFareTotal] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [timeLimit, setTimeLimit] = useState([]);
  const [qeuesData, setQeuesData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const url = `https://api-b2b.projectota.com/flight/booking/details/${params?.uid}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setStateData(data?.bookingdata);
        setPassengerData(data?.passengerdata);
        setQeuesData(data);
        setFlightData(data?.sabredata?.flights);
        setFareTotal(data?.sabredata?.fares);
        setAllData(data);
        // Only String Check Regex
        const regex = /^[a-zA-Z\s.,!?']+$/;
        let state = data?.sabredata?.specialServices?.filter((oths) => {
          return oths.code === 'OTHS' && !regex.test(oths.message);
        });
        setTimeLimit(state);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error state or show an error message
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [bookingId, params?.uid, stateChange, refetch]);

  const handleMakeTicketed = (bookingId) => {
    const url = `https://api.flyjatt.com/v1/AirBooking/AirTicket.php`;
    const body = JSON.stringify({
      bookingId: bookingId,
      agentId: agentId,
    });
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === 'success') {
          Swal.fire({
            title: 'Ticketed Successful!',
            html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
            confirmButtonText: 'OK',
            cancelButtonColor: 'var(--primary-color)',
          }).then(() => {
            setIsLoading(true);
            setRefetch(true);
          });
        } else {
          throw new Error('error');
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          // imageUrl: cancelFailed,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: 'Ticketed Failed!',
          html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
          confirmButtonText: 'OK',
          cancelButtonColor: 'var(--primary-color)',
        }).then(() => {
          setIsLoading(true);
          navigate(0);
        });
      });
  };

  const cancelBooking = async () => {
    const confirmation = await Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
      title: 'Are you sure?',
      text: 'Do you want to cancel this booking?',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary-color)',
      confirmButtonText: 'Yes, Cancel it!',
      cancelButtonColor: 'crimson',
      cancelButtonText: "Don't Cancel it!",
    });

    if (confirmation.isConfirmed) {
      try {
        setOpen(false);
        setIsLoading(false);

        const url = `https://api-b2b.projectota.com/flight/booking/cancel/${params?.uid}`;
        const response = await fetch(url);
        const data = await response.json();

        if (response.ok) {
          await Swal.fire({
            imageAlt: 'Custom image',
            title: 'Booking Cancelled',
            confirmButtonColor: 'var(--primary-color)',
            confirmButtonText: 'Ok',
          }).then(function () {
            setIsLoading(true);
            navigate('/dashboard/allbooking');
          });
        } else {
          throw new Error('Failed to cancel booking');
        }
      } catch (err) {
        console.error(err);
        Swal.fire({
          title: 'Cancellation Failed!',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          setIsLoading(true);
        });
      }
    }
  };

  let reissueApprove = {
    agentId: agentId,
    reissueId: stateData?.reissueId,
    actionBy: 'Admin',
    bookingId: bookingId,
    charge: penalty,
  };

  let refundApprove = {
    agentId: agentId,
    refundId: stateData?.refundId,
    actionBy: 'Admin',
    bookingId: bookingId,
    penalty: '0',
  };

  let voidApprove = {
    agentId: agentId,
    voidId: stateData?.voidId,
    actionBy:  'Admin',
    bookingId: bookingId,
  };

  const handleApprove = (value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You Wants to ${value} Approve this Flight ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary-color)',
      cancelButtonColor: 'red',
      confirmButtonText: 'Yes, Approve it!',
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let url = `https://api.flyjatt.com/v1/Ticketed/${value}.php?approved`;

        let body = JSON.stringify(
          value === 'Reissue'
            ? reissueApprove
            : value === 'Refund'
            ? refundApprove
            : value === 'Void'
            ? voidApprove
            : null
        );
        fetch(url, {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === 'success') {
              Swal.fire({
                icon: 'success',
                title: `${data.message}`,
                html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
                confirmButtonText: 'OK',
              }).then(() => {
                setStateChange(true);
                setRefetch(true);
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: `${data.message}`,
                html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
                confirmButtonText: 'OK',
              }).then(() => {
                setStateChange(true);
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: { err },
              html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
              confirmButtonText: 'OK',
            }).then(() => {
              setStateChange(true);
            });
          });
      }
    });
  };

  let rejectBody = {
    agentId: agentId,
    bookingId: bookingId,
    actionBy: 'Admin',
    remarks: remark,
  };

  let quotationbody = {
    quotamount: penalty,
    remarks: remark,
  };

  // All action in this function approve reject
  const handleAllAction = () => {
    window.event.preventDefault();
    let issuReject = 'https://api.flyjatt.com/v1/Admin/Ticketing/ticketingReturn.php';
    let allreject = `https://api.flyjatt.com/v1/Ticketed/${modalOpen?.value}.php?${modalOpen?.cancel}`;
    let reissueRefund = `https://api-b2b.projectota.com/admin/${modalOpen?.value}/quatation/${params?.uid}`;
  
    const apiUrl =
      modalOpen?.value === 'Issue'
        ? issuReject
        : modalOpen?.cancel === 'Reject'
        ? allreject
        : reissueRefund;
  
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(
        modalOpen?.value === 'Issue' || modalOpen?.cancel === 'Reject'
          ? rejectBody
          : modalOpen?.quotation === 'quotation'
          ? quotationbody
          : ''
      ),
    })
      .then((res) => {
        setIsDisabled(true);
        setModalOpen({
          cAllIissue: false,
        });
  
        // Use the response status code for validation
        if (res.ok) {
          return res.json(); // Continue with processing the JSON data
        } else {
          throw new Error(`Error (${res.status}): Request unsuccessful`);
        }
      })
      .then((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Request successful',
          confirmButtonText: 'ok',
        }).then(() => {
          setStateChange(true);
          setRefetch(true);
        });
      })
      .catch((err) => {
        setModalOpen({
          cAllIissue: false,
        });
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: err.message,
          confirmButtonText: 'ok',
        }).then(() => {
          setStateChange(true);
        });
      });
  };
  
  

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box position={'relative'}>
          {!isLoading ? (
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={9.5}>
                <Box sx={{ marginTop: '20px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        width: 'fit-content',
                        background: 'var(--primary-color)',
                        color: 'var(--secondary-color)',
                        fontSize: '16px',
                        fontWeight: '500',
                        padding: '10px 5px',
                        borderRadius: '4px',
                      }}
                    >
                      Booking Id: {bookingId || 'bookingId'}
                    </Typography>
                    <Typography
                      sx={{
                        width: 'fit-content',
                        background: 'var(--primary-color)',
                        color: 'var(--secondary-color)',
                        fontSize: '16px',
                        fontWeight: '500',
                        padding: '10px 5px',
                        borderRadius: '4px',
                      }}
                    >
                      {stateData?.status}
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <Typography
                      sx={{
                        background: 'var(--third-color)',
                        color: 'var(--secondary-color)',
                        padding: '2px 5px',
                        borderRadius: '4px',
                      }}
                    >
                      Flight Information
                    </Typography>
                    <Box className="flight-queue-detail">
                      <Box mt={2}>
                        <table width="100%">
                          <tr>
                            <th width="15%">Flight</th>
                            <th width="23%">Departure From</th>
                            <th width="23%">Arrival To</th>
                            <th width="12%">Depart At</th>
                            <th width="12%">Arrive At</th>
                            <th width="15%">Info</th>
                          </tr>

                          {flightData?.length === 0 ? (
                            <Box>Loading...</Box>
                          ) : (
                            <tbody>
                              {flightData?.map((data, index) => (
                                <tr key={index}>
                                  <td>
                                    <AirlineNames code={data.airlineCode} />{' '}
                                    {data?.airlineCode}-{data?.flightNumber}
                                    <br />
                                    {data?.aircraftTypeName}
                                  </td>
                                  <td>
                                    ({data.fromAirportCode})
                                    <Address code={data.fromAirportCode} />
                                    {data?.departureTerminalName || null}{' '}
                                    {data?.departureGate
                                      ? `Gate: ${data?.departureGate || null}`
                                      : null}
                                  </td>
                                  <td>
                                    ({data.toAirportCode})
                                    <Address code={data.toAirportCode} />
                                    {data?.arrivalTerminalName || null}{' '}
                                    {data?.arrivalGate
                                      ? `Gate: ${data?.arrivalGate || null}`
                                      : null}
                                  </td>
                                  <td>
                                    {new Date(
                                      data?.departureDate
                                    ).toLocaleString('en-GB', {
                                      day: 'numeric',
                                      month: 'short',
                                      year: 'numeric',
                                    })}{' '}
                                    {data.departureTime?.slice(0, 5)}
                                  </td>
                                  <td>
                                    {new Date(data?.arrivalDate).toLocaleString(
                                      'en-GB',
                                      {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                      }
                                    )}{' '}
                                    {data.arrivalTime?.slice(0, 5)}
                                  </td>
                                  <td>
                                    {data?.cabinTypeName}-{data?.bookingClass}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </Box>
                    </Box>
                  </Box>
                  <Box my={2}>
                    <Typography
                      sx={{
                        background: 'var(--third-color)',
                        color: 'var(--secondary-color)',
                        padding: '2px 5px',
                        borderRadius: '4px',
                      }}
                    >
                      Price BreakDown
                    </Typography>
                    <Box mt={2} className="flight-queue-detail">
                      {fareTotal?.length === 0 ? (
                        <>Loading...</>
                      ) : (
                        <>
                          <Box mt={2}>
                            <Box>
                              <table>
                                <tr>
                                  <th width="20%">Passenger </th>
                                  <th width="20%">Base Fare </th>
                                  <th width="20%">Tax</th>
                                  <th width="20%">Sub-Total Fare</th>
                                  <th width="20%">Baggage</th>
                                </tr>

                                {fareTotal?.map((data, index) => (
                                  <tr>
                                    <td>
                                      {data?.pricedTravelerType === 'ADT'
                                        ? 'Adult'
                                        : data?.pricedTravelerType === 'INF'
                                        ? 'Infant'
                                        : 'Child'}
                                      {' X '}
                                      {data?.travelerIndices?.length || 1}
                                    </td>
                                    <td>
                                      {parseInt(data?.totals?.subtotal) *
                                        parseInt(
                                          data?.travelerIndices?.length || 1
                                        )}{' '}
                                      AED
                                    </td>
                                    <td>
                                      {parseInt(data?.totals?.taxes) *
                                        parseInt(
                                          data?.travelerIndices?.length || 1
                                        )}{' '}
                                      AED
                                    </td>
                                    <td>
                                      {parseInt(data?.totals?.total) *
                                        parseInt(
                                          data?.travelerIndices?.length || 1
                                        )}{' '}
                                      AED
                                    </td>
                                    <td>
                                      {data?.fareConstruction[0]
                                        ?.checkedBaggageAllowance
                                        ?.totalWeightInKilograms
                                        ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.totalWeightInKilograms} Kg`
                                        : null}
                                      {data?.fareConstruction[0]
                                        ?.checkedBaggageAllowance?.maximumPieces
                                        ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.maximumPieces} Peices`
                                        : null}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </Box>

                            <table className="flight-queue-detail table-data">
                              <tr>
                                <td width="20%">
                                  Total Passenger{' '}
                                  <span style={{ display: 'none' }}>
                                    {fareTotal?.map((data, index) =>
                                      passengerCount(
                                        data?.travelerIndices?.length
                                      )
                                    )}
                                  </span>
                                  {count || 1}
                                </td>
                                <td width="20%">
                                  Total{' '}
                                  {allData?.payments?.flightTotals[0]?.subtotal}{' '}
                                  AED
                                </td>
                                <td width="20%">
                                  Total{' '}
                                  {allData?.payments?.flightTotals[0]?.taxes}{' '}
                                  AED
                                </td>
                                <td width="40%">
                                  Total Amount{' '}
                                  {allData?.payments?.flightTotals[0]?.total}{' '}
                                  AED
                                </td>
                                {/* <td width="20%"></td> */}
                              </tr>
                            </table>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box my={3}>
                    <Typography
                      sx={{
                        background: 'var(--third-color)',
                        color: 'var(--secondary-color)',
                        padding: '2px 5px',
                        borderRadius: '4px',
                      }}
                    >
                      Passenger Information
                    </Typography>
                    <Box mt={2} className="queue-detail-passenger-detail">
                      <div>
                        <Box>
                          {passengerData?.map((traveler, index) => (
                            <Box>
                              <Box
                                p="3px 5px"
                                border="1px solid #DEDEDE"
                                borderRadius="4px"
                                my="2px"
                                display={'flex'}
                                justifyContent={'space-between'}
                                width={'100%'}
                              >
                                <h5
                                  style={{
                                    color: 'var(--secondary-color)',
                                    fontWeight: '500',
                                    fontSize: '15px',
                                  }}
                                >
                                  {traveler?.gender === 'Male' ? (
                                    <>
                                      {traveler?.gender === 'Male' &&
                                      traveler?.type === 'ADT' ? (
                                        <>
                                          MR {traveler?.givenname}{' '}
                                          {traveler?.surname}
                                        </>
                                      ) : (
                                        <>
                                          MSTR {traveler?.givenname}{' '}
                                          {traveler?.surname}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {traveler?.gender === 'Female' &&
                                      traveler?.type === 'ADT' ? (
                                        <>
                                          MS {traveler?.givenname}{' '}
                                          {traveler?.surname}
                                        </>
                                      ) : (
                                        <>
                                          MISS {traveler?.givenname}{' '}
                                          {traveler?.surname}
                                        </>
                                      )}
                                    </>
                                  )}
                                </h5>
                              </Box>

                              <Box
                                border="1px solid #DEDEDE"
                                borderRadius="4px"
                                p="3px 5px"
                                mb={2}
                              >
                                <Grid container spacing={1}>
                                  <Grid item xs={4} sm={3} md={2}>
                                    <h5>Title</h5>
                                    <h6>
                                      {traveler?.gender === 'Male' ? (
                                        <>
                                          {traveler?.gender === 'Male' &&
                                          traveler?.type === 'ADT' ? (
                                            <>MR</>
                                          ) : (
                                            <>MSTR</>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {traveler?.gender === 'Female' &&
                                          traveler?.type === 'ADT' ? (
                                            <>MS</>
                                          ) : (
                                            <>MISS</>
                                          )}
                                        </>
                                      )}
                                    </h6>
                                  </Grid>
                                  <Grid item xs={4} sm={3} md={2}>
                                    <h5>First Name</h5>
                                    <h6>{traveler?.givenname}</h6>
                                  </Grid>
                                  <Grid item xs={4} md={2}>
                                    <h5>Last Name</h5>
                                    <h6>{traveler?.surname}</h6>
                                  </Grid>
                                  <Grid item xs={4} md={2}>
                                    <h5>Nationality</h5>
                                    <h6>{traveler?.nationality}</h6>
                                  </Grid>

                                  <Grid item xs={4} sm={3} md={2}>
                                    <h5>Date of Birth</h5>
                                    <h6>
                                      {traveler?.dob
                                        ? format(
                                            new Date(traveler?.dob),
                                            'dd MMM yyyy'
                                          )
                                        : 'Date of Birth'}
                                    </h6>
                                  </Grid>

                                  <Grid item xs={4} sm={3} md={2}>
                                    <h5>Gender</h5>
                                    <h6>{traveler?.gender}</h6>
                                  </Grid>

                                  <Grid item xs={4} sm={3} md={2}>
                                    <h5>Pax Type</h5>
                                    <h6>
                                      {traveler?.type === 'ADT'
                                        ? 'Adult'
                                        : traveler?.type === 'CNN'
                                        ? 'Child'
                                        : 'Infat'}
                                    </h6>
                                  </Grid>

                                  <Grid item xs={4} sm={3} md={2}>
                                    <h5>Passport Number</h5>
                                    <h6>
                                      {traveler?.document === '' ||
                                      traveler?.document === null
                                        ? 'Domestic Flight'
                                        : traveler?.document?.toUpperCase()}
                                    </h6>
                                  </Grid>
                                  <Grid item xs={4} sm={3} md={2}>
                                    <h5>P. Expire Date</h5>

                                    <h6>
                                      {traveler?.expiredate === '' ||
                                      traveler?.expiredate === null
                                        ? 'Domestic Flight'
                                        : traveler?.expiredate?.toUpperCase()}
                                    </h6>
                                  </Grid>

                                  {allData?.sabredata?.flightTickets ===
                                  undefined ? null : (
                                    <Grid item xs={6} sm={3} md={2}>
                                      <h5>Ticket Number</h5>

                                      <h6>
                                        {
                                          allData?.sabredata?.flightTickets[
                                            index
                                          ]?.number
                                        }
                                      </h6>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </div>
                    </Box>
                    {/* passenger information end */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={2.5} mt={1.5}>
                <Box>
                  {stateData?.status === 'Hold' ? (
                    <Accordion
                      expanded={downExpanded === 'panel1'}
                      onChange={handleChangeDown('panel1')}
                      style={{
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ',
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8bh-content"
                        id="panelbh-header"
                      >
                        <Typography
                          style={{
                            color: 'var(--primary-color)',
                            fontFamily: 'poppins',
                            fontWeight: '500',
                            fontSize: '16px',
                          }}
                        >
                          Time Limit
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {timeLimit?.length === 0 ? (
                          'No Time Limit'
                        ) : (
                          <>
                            {timeLimit?.map((data, index) => (
                              <Box key={index}>
                                <Typography
                                  sx={{
                                    color: '#222222',
                                    fontSize: 12,
                                    my: 1,
                                    fontWeight: 500,
                                  }}
                                >
                                  {data?.message || 'No Time Limit'}
                                </Typography>
                              </Box>
                            ))}
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                </Box>
                <Box>
                  {stateData?.status === 'Issue In Processing' && (
                    <Button
                      sx={{
                        my: '5px',
                        background: 'var(--secondary-color)',
                        color: 'white',
                        width: '100%',
                        '&:hover': {
                          background: 'var(--secondary-color)',
                          color: 'white',
                        },
                      }}
                      onClick={() => handleMakeTicketed(bookingId)}
                    >
                      Make Ticketed
                    </Button>
                  )}

                  {/* -------------  */}

                  {stateData?.status === 'Issue In Processing' && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: '#fff',
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: 'Issue',
                            cancel: 'Reject',
                          });
                        }}
                      >
                        Cancel Issue Request
                      </Typography>
                    </Box>
                  )}

                  {stateData?.status === 'Reissue Requested' && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: '#fff',
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: 'Reissue',
                            // cancel: "approved",
                            quotation: 'quotation',
                          });
                        }}
                      >
                        Make Reissue Quotation
                      </Typography>
                    </Box>
                  )}

                  {stateData?.status === 'Reissue Quotation Confirm' && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: '#fff',
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: 'Reissue',
                            cancel: 'Reject',
                          });
                        }}
                      >
                        Cancel Reissue Request
                      </Typography>

                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: '#fff',
                          my: 1,
                        }}
                        onClick={(e) => {
                          handleApprove('Reissue');
                        }}
                      >
                        Make Reissued
                      </Typography>
                    </Box>
                  )}

                  {stateData?.status === 'Refund Requested' && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: '#fff',
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: 'Refund',
                            // cancel: "approved",
                            quotation: 'quotation',
                          });
                        }}
                      >
                        Make Refund Quotation
                      </Typography>
                    </Box>
                  )}

                  {stateData?.status === 'Refund Quotation Confirm' && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: '#fff',
                          my: 1,
                        }}
                        onClick={(e) => {
                          handleApprove('Refund');
                        }}
                      >
                        Make Refunded
                      </Typography>

                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: '#fff',
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: 'Refund',
                            cancel: 'Reject',
                          });
                        }}
                      >
                        Cancel Refund Request
                      </Typography>
                    </Box>
                  )}
                  {stateData?.status === 'Void In Processing' && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: '#fff',
                          my: 1,
                        }}
                        onClick={(e) => {
                          handleApprove('Void');
                        }}
                      >
                        Make Voided
                      </Typography>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: '#fff',
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: 'Void',
                            cancel: 'Reject',
                          });
                        }}
                      >
                        Cancel Void Request
                      </Typography>
                    </Box>
                  )}
                  {stateData?.status === 'Hold' && (
                    <Button
                      sx={{
                        my: '5px',
                        background: 'var(--tomato-color)',
                        color: 'white',
                        width: '100%',
                        '&:hover': {
                          background: 'var(--tomato-color)',
                          color: 'white',
                        },
                      }}
                      onClick={() => cancelBooking(bookingId)}
                    >
                      Cancel Flight
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ',
                    p: 1,
                    pb: 2,
                  }}
                >
                  <Box mb={2}>PDF Download </Box>
                  <AllPDF
                    isLoading
                    tripType={tripType}
                    bookingId={bookingId}
                    flightData={flightData}
                    fareTotal={fareTotal}
                    passengerData={passengerData}
                    allData={allData}
                    stateData={stateData}
                    qeuesData={qeuesData}
                  />
                </Box>
                <Box py={2}>
                  {/* <Accordion
                    expanded={downExpanded === 'panel12'}
                    onChange={handleChangeDown('panel12')}
                    style={{
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel8bh-content"
                      id="panelbh-header"
                    >
                      <Typography
                        style={{
                          color: '#dc143c',
                          fontFamily: 'poppins',
                          fontWeight: '500',
                          fontSize: '16px',
                        }}
                      >
                        PNR History
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {qeuesData?.activity?.map((data, index) => (
                        <Grid container key={index}>
                          <Grid item xs={1}>
                            <Box
                              width="14px"
                              height="14px"
                              bgcolor="var(--primary-color)"
                              position="relative"
                              ml={-1}
                            ></Box>
                          </Grid>
                          <Grid item mt="-3px" xs={11}>
                            <Typography
                              sx={{
                                color: 'var(--primary-color)',
                                fontSize: '16px',
                                fontWeight: 500,
                              }}
                            >
                              {data?.status}
                            </Typography>
                            <Box py={2}>
                              <Typography
                                sx={{
                                  color: '#70A5D8',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                {data?.actionBy || ''}
                                {data?.status === 'Hold' ||
                                data?.status === 'Issue In Processing' ||
                                data?.status === 'Reissue In Processing' ||
                                data?.status === 'Refund In Processing' ||
                                data?.status === 'Void In Processing' ? (
                                  <>
                                    {data?.actionBy === '' ? '' : ','}{' '}
                                    {companyname || ''}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  color: '#767676',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                }}
                              >
                                {new Date(data?.actionAt).toLocaleString(
                                  'en-GB',
                                  {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: false,
                                  }
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  color: 'crimson',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  mt: '4px',
                                }}
                              >
                                {data?.remarks === '' ||
                                data?.remarks === ' ' ? (
                                  ''
                                ) : (
                                  <>Remarks: {`${data?.remarks}`}</>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      ))}
                    </AccordionDetails>
                  </Accordion> */}
                  <Accordion
                    expanded={true}
                    onChange={handleChangeDown('panel4')}
                    style={{
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ',
                      margin: '0',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel8bh-content"
                      id="panelbh-header"
                      style={{ margin: '0' }}
                    >
                      <Typography
                        style={{
                          color: '#dc143c',
                          fontFamily: 'poppins',
                          fontWeight: '500',
                          fontSize: '16px',
                        }}
                      >
                        Fare rules
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        margin: '0',
                      }}
                    >
                      {allData?.sabredata?.fareRules === undefined ? (
                        ''
                      ) : allData?.sabredata?.fareRules?.length === 0 ? (
                        'Loading Data...'
                      ) : allData?.sabredata?.fareRules?.length >= 1 ? (
                        <Box>
                          {allData?.sabredata?.fareRules?.map((data) => (
                            <FareRules data={data} />
                          ))}
                        </Box>
                      ) : (
                        'No fare rules this flight'
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
                {/* ------------------All Modal Start Hear ------------------ */}
              </Grid>
            </Grid>
          ) : (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '50vh',
                width: '50vw',
                marginInline: 'auto',
              }}
            >
              <Box
                style={{
                  width: '50%',
                  height: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: '100%',
                    objectFit: 'center',
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          {/* Cancle ReIssue Refund Void Request Modal start */}
          <Modal
            open={modalOpen.cAllIissue}
            onClose={() =>
              setModalOpen({
                cAllIissue: false,
              })
            }
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <form onSubmit={() => handleAllAction()}>
              <Box
                sx={{ ...modalStyle, width: 500 }}
                style={{
                  border: 'none',
                  borderRadius: '5px',
                }}
              >
                <Typography
                  sx={{
                    color: '#222222',
                    fontSize: '20px',
                    fontWeight: 500,
                    mb: 1,
                    textAlign: 'center',
                  }}
                >
                  {modalOpen?.cancel === 'Reject'
                    ? `Cancel ${modalOpen?.value} Remarks`
                    : `Send Make Quotation ${modalOpen?.value}`}
                </Typography>
                {modalOpen?.cancel === 'Reject' ? (
                  <Box className="airlinePnr1" mb={2}>
                    <input
                      required
                      style={{
                        width: '100%',
                        marginLeft: '0px',
                        marginTop: '10px',
                        border: '2px solid gray',
                        borderRadius: '5px',
                        outline: 'none',
                        padding: '5px 10px',
                      }}
                      type="text"
                      placeholder="Enter remarks..."
                      name="airlinePnr"
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </Box>
                ) : (
                  <Box className="airlinePnr1" mb={2}>
                    <input
                      required
                      style={{
                        width: '60%',
                        marginLeft: '0px',
                        marginTop: '10px',
                        border: '2px solid gray',
                        borderRadius: '5px',
                        outline: 'none',
                        padding: '5px 10px',
                      }}
                      type="number"
                      placeholder={
                        modalOpen?.value === 'Refund'
                          ? 'Enter Refund Amount...'
                          : 'Enter Netcost with add charges...'
                      }
                      name="airlinePnr"
                      onChange={(e) => setPenalty(e.target.value)}
                    />
                    {modalOpen?.value === 'Reissue' && (
                      <input
                        required
                        style={{
                          width: '100%',
                          marginLeft: '0px',
                          marginTop: '10px',
                          border: '2px solid gray',
                          borderRadius: '5px',
                          outline: 'none',
                          padding: '5px 10px',
                        }}
                        type="text"
                        placeholder="Enter remarks..."
                        name="airlinePnr"
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    )}
                  </Box>
                )}

                <Box className="balance-transaction">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box className="saveBtn1">
                      <button
                        type="submit"
                        onClick={() => handleAllAction()}
                        disabled={isDisabled}
                        style={
                          isDisabled ? styles.buttonDisabled : styles.button
                        }
                      >
                        {isDisabled ? 'Wait For Response or Reload' : 'Save'}
                      </button>
                    </Box>
                    <Button
                      size="small"
                      sx={{
                        color: '#fff',
                        bgcolor: 'red',
                        '&:hover': {
                          bgcolor: 'red',
                        },
                      }}
                      onClick={() => setModalOpen({ cAllIissue: false })}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </form>
          </Modal>
          {/* Cancle Issue Request Modal end */}
        </Box>
      </Container>
    </Box>
  );
};

export default BookingDetails;
