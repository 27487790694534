
import {
  Box,
  Button,
  Container,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Loader from '../../images/loader/Render.gif';
import Swal from 'sweetalert2';
import Header from '../../components/Header/Header';
import ReConfirm from '../../images/undraw/undraw_confirmation_re_b6q5.svg';
import Invalid from '../../images/undraw/undraw_warning_re_eoyh.svg';
import CummonTable from '../../components/CommonTable/CummonTable';
import { createMRTColumnHelper } from 'material-react-table';
import axios from 'axios';

const Traveller = () => {
  const columnHelper = createMRTColumnHelper();
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem('user-info');
  let agentId = users?.uid;

  const [refetch, setRefetch] = useState(false);

  const [allData, setAllData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    columnHelper.accessor('givenname', {
      header: 'Given Name',
    }),
    columnHelper.accessor('surname', {
      header: 'Surname',
    }),
    columnHelper.accessor('gender', {
      header: 'Gender',
      size: 40,
    }),
    columnHelper.accessor('type', {
      header: 'Type',
      size: 40,
      Cell: ({ row }) =>
        `${
          row.original?.type === 'ADT'
            ? 'Adult'
            : row.original?.type === 'CNN'
            ? 'Child'
            : 'Infant'
        }`,
    }),

    columnHelper.accessor('dob', {
      header: 'Date of Birth',
      size: 20,
      Cell: ({ row }) =>
        `${
          row.original?.dob !== '' || 'undefined' || null
            ? format(new Date(row.original?.dob), 'dd MMM yy hh:mm')
            : 'Birth Date'
        }`,
    }),
    columnHelper.accessor('expiredate', {
      header: 'Expire Date',
      Cell: ({ row }) =>
        `${
          row.original?.expiredate !== '' || 'undefined' || null
            ? format(new Date(row.original?.expiredate), 'dd MMM yy hh:mm')
            : 'Expire Date'
        }`,
    }),

    columnHelper.accessor('uid', {
      header: 'Action',
      Cell: ({ row }) => (
        <button
          style={{
            background: 'var(--primary-color)',
            borderRadius: '5px',
            padding: '2px 10px',
            width: '120px',
            cursor: 'pointer',
            border: 'none',
            color: 'var(--white)',
          }}
          onClick={() => handelDelete(row.original.uid, row.original.paxId)}
        >
          Delete
        </button>
      ),
    }),
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api-b2b.projectota.com/agent/passenger/${agentId}`
        );
        setRefetch(!refetch);
        setAllData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Make sure to add any dependencies if needed

  async function handelDelete(id, paxId) {
    console.log(id);
    const result = await Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
      title: 'Are you sure?',
      text: 'You want to delete this traveler?',
      showCancelButton: true,
      confirmButtonColor: 'var(--secondary-color)',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: 'crimson',
      cancelButtonText: "Don't delete it!",
    });

    if (result.isConfirmed) {
      const url = `https://api-b2b.projectota.com/agent/passenger/${id}`;

      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (response.ok || data?.status.toLowerCase() === 'success') {
          await Swal.fire({
            title: 'Deleted this traveler',
            confirmButtonColor: 'var(--primary-color)',
            confirmButtonText: 'Ok',
          });

          setRefetch(!refetch);
        } else {
          await Swal.fire({
            imageUrl: Invalid,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: 'Custom image',
            title: `${data?.message}`,
            confirmButtonColor: 'var(--primary-color)',
            confirmButtonText: 'Ok',
          });
        }
      } catch (err) {
        await Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: err.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        });
      }
    }
  }
  if (isLoading) {
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '70vh',
          width: '70vw',
          marginInline: 'auto',
        }}
      >
        <Box
          style={{
            width: '50%',
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: '100%',
              objectFit: 'center',
            }}
          />
        </Box>
      </Box>
    );
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 10, sm: 10, md: 2 } }}>
        <Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'flex', md: 'flex' },
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: '10px',
            }}
          >
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: '24px',
                fontWeight: '500',
              }}
            >
              Travelers
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Button
                sx={{
                  fontSize: '12px',
                  textTransform: 'capitalize',
                  background: 'var(--primary-color)',
                  color: 'var(--white)',
                  '&:hover': {
                    background: 'var(--primary-color)',
                    color: 'var(--white)',
                  },
                }}
                onClick={() => navigate('/user/addtraveller')}
              >
                Add Travelers
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          p: { xs: 1, md: 2 },
          boxSizing: 'border-box',
        }}
      >
        <CummonTable data={allData} columns={columns} />
      </Box>
    </Box>
  );
};

export default Traveller;
