import { Box, Container, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Header from './../../components/Header/Header';
import Loader from '../../images/loader/Render.gif';
import { createMRTColumnHelper } from 'material-react-table';
import axios from 'axios';
import CummonTable from '../../components/CommonTable/CummonTable';
import commaNumber from 'comma-number';

const Deposite = () => {
  const columnHelper = createMRTColumnHelper();
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem('user-info');
  let agentId = users?.uid;

  const [refetch, setRefetch] = useState(false);

  const [allData, setAllData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    columnHelper.accessor('depositId', {
      header: 'Ref-Id',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      Cell: ({ row }) => (
        <Typography
          sx={{ fontSize: '12px', textAlign: 'left' }}
          className={`${row.original?.status
            ?.toLowerCase()
            ?.split(' ')
            ?.join('-')}-btn`}
        >
          {row.original?.status || 'Status'}
        </Typography>
      ),
    }),
    columnHelper.accessor('amount', {
      header: 'Amount',
      Cell: ({ row }) =>
        `${commaNumber(Number.parseInt(row.original?.amount) || 0)} AED`,
    }),

    columnHelper.accessor('attachment', {
      header: 'Attachment',
      size: 40,
      Cell: ({ row }) => (
        <Box textAlign="left">
          <a href={row.original?.attachment} target="_blank" rel="noreferrer">
            <span
              style={{
                fontSize: '14px',
                color: 'var(--secondary-color)',
              }}
            >
              View Transecript
            </span>
          </a>
        </Box>
      ),
    }),
    columnHelper.accessor('created_at', {
      header: 'Deposit Date',
      Cell: ({ row }) =>
        `${
          row.original?.created_at !== '' || 'undefined' || null
            ? format(new Date(row.original?.created_at), 'dd MMM yy hh:mm')
            : 'Birth Date'
        }`,
    }),
    columnHelper.accessor('receiver', {
      header: 'Receiver',
      size: 40,
    }),
    columnHelper.accessor('sender', {
      header: 'Sender',
      size: 40,
    }),

    columnHelper.accessor('paymentway', {
      header: 'Paymentway',
      size: 40,
    }),

    columnHelper.accessor('remarks', {
      header: 'Remarks',
      Cell: ({ row }) =>
        `${row.original?.remarks === '' ? 'N/A' : row.original?.remarks}`,
    }),
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api-b2b.projectota.com/agent/deposit/${agentId}`
        );
        setRefetch(!refetch);
        setAllData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Make sure to add any dependencies if needed
  if (isLoading) {
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '70vh',
          width: '70vw',
          marginInline: 'auto',
        }}
      >
        <Box
          style={{
            width: '50%',
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: '100%',
              objectFit: 'center',
            }}
          />
        </Box>
      </Box>
    );
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 12, md: 2 } }}>
        <Box sx={{ width: '100%' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            mb={{ xs: 1, sm: 0 }}
          >
            <Typography
              sx={{
                color: 'var(--secondary-color)',
                fontSize: { xs: 20, sm: 24 },
                fontWeight: 500,
              }}
            >
              Deposit Requests
            </Typography>
            <Box
              onClick={() => navigate('/user/adddeposit')}
              sx={{
                cursor: 'pointer',
                textTransform: 'capitalize',
                padding: '5px 25px',
                background: 'var(--primary-color)',
                color: '#FFFFFF',
                borderRadius: '4px',
                fontSize: '16px',
              }}
            >
              Add Deposit
            </Box>
          </Stack>
        </Box>
      </Container>
      <Box
        sx={{
          p: { xs: 1, md: 2 },
          boxSizing: 'border-box',
        }}
      >
        <CummonTable data={allData} columns={columns} />
      </Box>
    </Box>
  );
};

export default Deposite;
