import { Navigate, useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

const PrivateRoute = ({ children, ...rest }) => {
  const location = useLocation();
  const user = secureLocalStorage.getItem('user-info');
  const admin = secureLocalStorage.getItem('admin-info');
  // Check if there is no admin email, redirect to the admin page
  if (user === null && !admin?.email) {
    return <Navigate to="/admin" state={{ from: location }} />;
  }

  // Check if there is no user email, redirect to the sign-in page
  if (!user?.user?.email && admin?.email === null) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  // If both admin and user information exist, render the children components
  return children;
};

export default PrivateRoute;
