import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFPageDesign from './PDFPageDesign';
import { Stack, Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

const AllPDF = ({
  isLoading,
  bookingId,
  flightData,
  fareTotal,
  passengerData,
  allData,
  triptype,
  stateData,
  qeuesData,
}) => {
  let bookingwp = `Booking with price`;
  let bookingwop = `Booking without price`;
  // let agent = `Agent invoice ${flightData[0]?.fromAirportCode}-${
  //   flightData[flightData.length - 1]?.toAirportCode
  // } ${flightData?.departureDate} ${triptype || ""}`;
  // let client = `Client invoice ${flightData[0]?.fromAirportCode}-${
  //   flightData[flightData.length - 1]?.toAirportCode
  // } ${flightData?.departureDate} ${triptype || ""}`;
  let eticketp = `e-Ticket with price `;
  let eticketwop = `e-Ticket without price `;

  return (
    <div className="pdf-ancortag">
      {stateData?.Status === 'Hold' ||
      stateData?.Status === 'Cancelled' ||
      stateData?.Status === 'Issue In Processing' ? (
        <Stack direction="row" justifyContent="space-between">
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Booking Copy"
                check="1"
                triptype={triptype}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={bookingwp}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: '#fff',
                    background: 'var(--primary-color)',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontSize: '11px',
                     padding: '7px  ',
                    borderRadius: '5px',
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> With&nbsp;Price
                </a>
              )
            }
          </PDFDownloadLink>
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Booking Copy"
                check="2"
                triptype={triptype}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={bookingwop}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: '#fff',
                    background: 'var(--primary-color)',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontSize: '11px',
                     padding: '7px  ',
                    borderRadius: '5px',
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> Without&nbsp;Price
                </a>
              )
            }
          </PDFDownloadLink>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="space-between">
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="e - Ticket"
                check="1"
                triptype={triptype}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={eticketp}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: '#fff',
                    background: 'var(--primary-color)',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontSize: '11px',
                     padding: '7px  ',
                    borderRadius: '5px',
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> With&nbsp;Price
                </a>
              )
            }
          </PDFDownloadLink>
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="e - Ticket"
                check="2"
                triptype={triptype}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={eticketwop}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: '#fff',
                    background: 'var(--primary-color)',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontSize: '11px',
                     padding: '7px  ',
                    borderRadius: '5px',
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> Without&nbsp;Price
                </a>
              )
            }
          </PDFDownloadLink>
        </Stack>
      )}
    </div>
  );
};

export default AllPDF;
