import { Box } from '@mui/system';
import React, { useState } from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import NoEncryptionGmailerrorredOutlinedIcon from '@mui/icons-material/NoEncryptionGmailerrorredOutlined';
import secureLocalStorage from 'react-secure-storage';
import useAuthentication from '../../hooks/useAuthentication';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import './SignIn.css';
import Logo from '../../images/logo/logo.png';
import PhoneIcon from '@mui/icons-material/Phone';

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, password } = useParams();
  const { loginUser, isLoading, error } = useAuthentication();

  const rememberUser = secureLocalStorage.getItem('remember');
  const [loginData, setLoginData] = useState({
    email: email || rememberUser?.email || '',
    password: password || rememberUser?.password || '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(loginData, location, navigate);
    e.target.reset();
  };
  // className="bg-images"
  return (
    <Box>
      <Grid container height>
        <Grid item md={12} width="100%">
          
        <Box sx={{clipPath:' polygon(0% 0%, 100% 0%, 100% 28.64%, 50% 47.17%, 0% 65.69%)',bgcolor:"var(--primary-color)" ,height:"100vh"}}>
        <Container sx={{ p: 1 }}>
              <Stack direction="row" justifyContent="space-between">
                <Box
                  sx={{
                    height: '80px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={Logo}
                    alt="logo"
                    style={{
                      height: '100%',
                    }}
                  />
                </Box>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems="center"
                  spacing={2}
                >
                  <Typography
                    sx={{
                      color: '#222222',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 14,
                    }}
                  >
                    <PhoneIcon color="#222222" />
                    &nbsp;+971509517784
                  </Typography>
                  <Link
                    to="/signup"
                    style={{
                      textDecoration: 'none',
                      color: 'var(--black)',
                      border: '2px solid #222222',
                      padding: '5px 15px',
                      borderRadius: '20px',
                    }}
                  >
                    <span style={{ fontSize: '13px' }}>
                      Register as an Agent
                    </span>
                  </Link>
                </Stack>
              </Stack>
            </Container>
            </Box>
          <Box >
        
          
            <Box
              sx={{
               
                padding: { md: '0px', sm: '0px 10px', xs: '0px 10px' },
                position: 'absolute',
                top: { md: '50%', sm: '50%', xs: '28%' },
                left: { md: '50%', sm: '50%', xs: '0' },
              
                transform: {
                  md: 'translate(-50%, -50%)',
                  sm: 'translate(-50%, -50%)',
                  xs: 'translate(0%, 0%)',
                },
              }}
            >
              <Box
                sx={{
                  boxShadow: {
                    md: '0px 10px 10px rgba(0, 0, 0, 0.10)',
                    sm: '0px 10px 10px rgba(0, 0, 0, 0.10)',
                    xs: '0px 10px 10px rgba(0, 0, 0, 0.10)',
                  },
                  width: { xs: '300', sm: '350px', md: '500px' },
                  height: { xs: 'fit-content' },
                  background: 'var(--white)',
                  padding: '0px',
                  borderRadius: '5px',
                  
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Box
                    my={2}
                    sx={{
                      width: '100%',
                      height: '60px',
                      background: 'var(--white)',
                     
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'end',
                      borderRadius: '20px 20px 0px 0px',
                      position: 'relative',
                      marginTop: '0px',
                    }}
                  >
                    <GppGoodOutlinedIcon
                      sx={{
                        position: 'absolute',
                        top: '-10%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'white',
                        color: 'var(--primary-color)',
                        padding: '10px',
                        fontSize: '50px',
                        borderRadius: '50%',
                      }}
                    />
                    <Typography
                      style={{
                        color: 'var(--secondary-color)',
                        fontSize: '20px',
                        fontWeight: 'normal',
                        textTransform: 'capitalize',
                        fontWeight:"500"
                      }}
                    >
                      Login
                    </Typography>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    sx={{ padding: { xs: '0px 20px', md: '50px 20px' } }}
                  >
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        style={{
                          color: 'var(--secondary-color)',
                          fontSize: '14px',
                          fontWeight: 'normal',
                          textTransform: 'capitalize',
                          textAlign: 'center',
                        }}
                      >
                        Sign In to continue to the Project OTA
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Box sx={{ position: 'relative' }}>
                        <input
                          placeholder="Enter Your Email ID"
                          value={loginData.email}
                          name="email"
                          type="email"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                          onChange={handleOnChange}
                          style={{
                            height: '40px',
                            border:'none',
                            boxShadow:'0px 8px 19px rgba(0, 0, 0, 0.12)',
                            width: 'calc(100% - 20px)',
                            outline: 'none',
                            // border: '1px solid black',
                            borderRadius: '5px',
                            padding: '0px 10px',
                            color: 'var(--secondary-color)',
                            background: 'transparent',
                            fontSize: '16px',
                          }}
                        />
                        <EmailOutlinedIcon
                          sx={{
                            color: 'var(--primary-color)',
                            position: 'absolute',
                            top: '50%',
                            right: '5px',
                            transform: 'translate(-5px,-50%)',
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Box sx={{ position: 'relative' }}>
                        <input
                          placeholder="Enter Your Password"
                          required
                          name="password"
                          value={loginData.password}
                          type={showPassword ? 'text' : 'password'}
                          onChange={handleOnChange}
                          style={{
                            height: '40px',
                            width: 'calc(100% - 20px)',
                            outline: 'none',
                            border:'none',
                            boxShadow:'0px 8px 19px rgba(0, 0, 0, 0.12)',
                            // border: '1px solid black',
                            borderRadius: '5px',
                            padding: '0px 10px',
                            color: 'var(--secondary-color)',
                            background: 'transparent',
                            fontSize: '16px',
                          }}
                        />
                        {showPassword ? (
                          <LockOutlinedIcon
                            onClick={handleClickShowPassword}
                            sx={{
                              color: 'var(--primary-color)',
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translate(-5px,-50%)',
                            }}
                          />
                        ) : (
                          <NoEncryptionGmailerrorredOutlinedIcon
                            onClick={handleClickShowPassword}
                            sx={{
                              color: 'var(--primary-color)',
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translate(-5px,-50%)',
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                    {/* <Grid item md={12} sm={12} xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        rememberUser?.isChecked === true ? (
                          <Checkbox defaultChecked onChange={handleCheckBox} />
                        ) : (
                          <Checkbox onChange={handleCheckBox} />
                        )
                      }
                      label="Remember"
                    />
                  </FormGroup>
                </Grid> */}
                    <Grid item md={12} sm={12} xs={12}>
                      {/* <Box>
                        <NavLink
                          style={{ textDecoration: 'none' }}
                          to="/resetpassword"
                        >
                          <Typography
                            sx={{
                              color: 'var(--black)',
                              fontSize: '14px',
                              width: '100%',
                              textAlign: 'right',
                            }}
                          >
                            Forget Password ?
                          </Typography>
                        </NavLink>
                      </Box> */}
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Button
                        type="submit"
                        disabled={!isLoading ? true : false}
                        sx={{
                          boxShadow:'0px 8px 19px rgba(0, 0, 0, 0.12)',
                          width: '100%',
                          borderRadius: '5px',
                          background: 'var(--primary-color)',
                          color: 'var(--white)',
                          '&:hover': {
                            background: 'var(--primary-color)',
                            color: 'var(--white)',
                          },
                        }}
                      >
                        {isLoading ? (
                          `Sign In →`
                        ) : (
                          <CircularProgress size={'1.5rem'} />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  <Box mt={1} sx={{ padding: '0px 20px 20px' }}>
                    <Box>
                      <Box>
                        {error && (
                          <Alert
                            severity="error"
                            style={{
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <AlertTitle color="red" m={0} p={0}>
                              Error !
                            </AlertTitle>{' '}
                            <strong textAlign={'center'}>{error} !</strong>
                          </Alert>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          color: 'var(--black)',
                          fontSize: '14px',
                          display: 'flex',
                          gap: '5px',
                        }}
                      >
                        New User?
                        <Link
                          to="/signup"
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              color: 'var(--primary-color)',
                            }}
                          >
                            Register Now
                          </span>
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignIn;
