import { Box, Typography } from '@mui/material'
import React from 'react'

const PartialPayment = () => {
  return (
    <>
    <Box  sx={{mt:2}}>
    <Box sx={{borderBottom:"1px solid var(--primary-color)",pb:2}}>
        <Typography sx={{color:'var(--primary-color)',fontSize:"21px",fontWeight:'500'}}>  Partial Payment</Typography>
    </Box>
    <Box sx={{mt:2,display:"flex",justifyContent:'center',alignItems:'center'}}>
<Box sx={{border:"1px solid var(--primary-color)",textAlign:'left',p:3}}>
<Typography sx={{fontSize:"16px",color:'var(--secondary-color)'}}>Partial Payment Status</Typography>
<Typography sx={{fontSize:"12px",color:'red'}}>Approved</Typography>
</Box>
    </Box>
    </Box>
    </>
  )
}

export default PartialPayment