import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AddBank from './components/BankAccount/AddBank';
import Congratulation from './components/Congratulation/Congratulation';
import AuthProvider from './components/Contexts/AuthProvider';
import Dashboard from './components/Dashboard/Dashboard';
import AddDeposite from './components/Deposit/AddDeposite';
import RoundFlightUserInfo from './components/FlightUserinfo/RoundFlightUserInfo';
import MultiCitySearchResult from './components/MultiCitySearchResult/MultiCitySearchResult';
import NotFound from './components/NotFound/NotFound';
import SignIn from './components/SignIn/SignIn';
import SignUp from './components/SignUp/SignUp';
import AddTraveller from './components/Traveller/AddTraveller';
import Deposite from './pages/Deposite/Deposite';
import FlightInformation from './pages/FligthInformation/FlightInformation';
import RoundSearchResult from './pages/SearchReslut/RoundSearchResult';
import SearchResult from './pages/SearchReslut/SearchResult';
import Traveller from './pages/Traveller/Traveller';

import UserDashboardSideBar from './components/UserDashboardSideBar/UserDashboardSideBar';

import { QueryClient, QueryClientProvider } from 'react-query';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import AdminLogin from './Admin/AdminLogin/AdminLogin';
import './App.css';
import HotelDataLoad from './components/Hotel/HotelAfterSearch/HotelDataLoad';
import MulticityFlightInfo from './components/MultiCitySearchResult/MulticityFlightInfo';
import PnrImport from './components/PnrImport/PnrImport';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Queues from './components/Queues/Queues/Queues';
import ForgetPassword from './components/ResetPassword/ForgetPassword';
import SearchHistory from './components/SearchHistory/SearchHistory';
import SessionTimeout from './components/SessionTimeOut/SessionTimeOut';
import LedgerReport from './components/UserReport/LedgerReport';
import TransactionReport from './components/UserReport/TransactionReport';
import Home from './pages/Home/Home';
import AdminDashboardSideBar from './Admin/AdminDashboardSideBar/AdminDashboardSideBar';
import LiveDashboard from './Admin/LiveDashboard/LiveDashboard';
import AdminNotice from './Admin/AdminNotice/AdminNotice';
import AddNotice from './Admin/AdminNotice/AddNotice';
import AdminMarkup from './Admin/AdminMarkup/AdminMarkup';
import AgentManagement from './Admin/AgentManagement/AgentManagement';
import Agentdetails from './Admin/AgentManagement/Agentdetails';
import AgentBooking from './Admin/AgentManagement/AgentBooking';
import AgentDeposit from './Admin/AgentManagement/AgentDeposit';
import AgetnLadger from './Admin/AgentManagement/AgetnLadger';
import AgentSearch from './Admin/AgentManagement/AgentSearch';
import AllBooking from './Admin/AdminBooking/AllBooking';
import BookingDetails from './Admin/AdminBooking/BookingDetails';
import PaymentManagement from './Admin/PaymentManagement/PaymentManagement';
import AdminAccount from './Admin/AdminAccount/AdminAccount';
import AdminAddStaff from './Admin/AdminAccount/AdminAddStaff';
import AdminAddBank from './Admin/AdminAddBank/AdminAddBank';
import AdminBankAccount from './Admin/AdminAddBank/AdminBankAccount';
import Staff from './components/Staff/Staff';
import AddStaff from './components/AddStaff/AddStaff';
import MyAccount from './components/MyAccount';
import Test from './components/Loader/Test';
import MakeQuotation from './components/MakeQuotation/MakeQuotation';
import UpcommingTrips from './pages/Home/UpcommingTrips';
import Setting from './components/Setting/Setting';

const queryClient = new QueryClient();

function App() {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
         
          <BrowserRouter>
            {/* //todo set Session time out here it set 15 min 15*60*1000=900000 */}
            <SessionTimeout sessionTimeout={900000} />
            <Routes>
              {/* for agent routed  */}
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/" element={<SignIn />} />
              <Route path="/test" element={<Test />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signin/:email/:password" element={<SignIn />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/resetpassword" element={<ForgetPassword />} />
              {/* user dashboard  start */}
              <Route
                path="/user"
                element={
                  <PrivateRoute>
                    <UserDashboardSideBar />
                  </PrivateRoute>
                }
              >
                <Route path="/user/dashboard" element={<Dashboard />} />
                <Route path="/user/home" element={<Home />} />
                <Route path="/user/tripsdata" element={<UpcommingTrips />} />
                {/* After searchResult start  */}
                <Route path="/user/searchresult" element={<SearchResult />} />
                <Route path="/user/quotationsend" element={<MakeQuotation />} />
                <Route
                  path="/user/roundsearchresult"
                  element={<RoundSearchResult />}
                />
                <Route
                  path="/user/multicityaftersearch"
                  element={<MultiCitySearchResult />}
                />
                <Route
                  path="/user/multicityflightinfo"
                  element={<MulticityFlightInfo />}
                />
                {/* After searchResult end  */}
                {/* After Booking start */}
          
                <Route
                  path='/user/setting'
                  element={<Setting />}
                />
                <Route
                  path="/user/flightinformation"
                  element={<FlightInformation />}
                />
                <Route
                  path="/user/roundflightinformation"
                  element={<RoundFlightUserInfo />}
                />

                <Route
                  path="/user/congratulation/:uid/:bookingId/:tripType"
                  element={
                    <PrivateRoute>
                      <Congratulation />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/user/searchhistory"
                  element={
                    <PrivateRoute>
                      <SearchHistory />
                    </PrivateRoute>
                  }
                />
                <Route path="/user/queues" element={<Queues />} />
                <Route path="/user/pnrimport" element={<PnrImport />} />
                <Route
                  path="/user/congratulation/:uid/:bookingId/:tripType"
                  element={
                    <PrivateRoute>
                      <Congratulation />
                    </PrivateRoute>
                  }
                />

                <Route path="/user/traveller" element={<Traveller />} />
                <Route path="/user/addtraveller" element={<AddTraveller />} />
                {/* <Route path="/user/others" element={<Others />} /> */}
                {/* <Route path="/user/bankaccount" element={<BankAccount />} /> */}
                <Route path="/user/addbankaccount" element={<AddBank />} />
                {/* //todo: add staff  */}
                <Route path="/user/staff" element={<Staff />} />
                <Route path="/user/addstaff" element={<AddStaff />} />

                <Route path="/user/myaccount" element={<MyAccount />} />
                <Route path="/user/deposit" element={<Deposite />} />
                <Route path="/user/adddeposit" element={<AddDeposite />} />
                <Route
                  path="/user/transaction"
                  element={<TransactionReport />}
                />
                <Route path="/user/ledger" element={<LedgerReport />} />

                {/* Hotel Route Start -------------- */}
                <Route
                  path="/user/hotelSearchResult"
                  element={<HotelDataLoad />}
                ></Route>
                {/* Hotel End -------------- */}
              </Route>
              {/* user dashboard  end */}

              {/* for Admin  */}
              <Route path="/admin" element={<AdminLogin />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <AdminDashboardSideBar />
                  </PrivateRoute>
                }
              >
                <Route index element={<LiveDashboard />} />
                <Route path="markup" element={<AdminMarkup />} />
                <Route path="notice" element={<AdminNotice />} />
                <Route path="addnotice" element={<AddNotice />} />
                <Route path="agentmanagement" element={<AgentManagement />} />
                <Route path="agentdetails" element={<Agentdetails />} />

                <Route path="agentbooking" element={<AgentBooking />} />
                <Route path="agentdeposit" element={<AgentDeposit />} />
                <Route path="agentladger" element={<AgetnLadger />} />
                <Route path="agentsearch" element={<AgentSearch />} />

                <Route path="allbooking" element={<AllBooking />} />
                <Route
                  path="bookingdetails/:uid"
                  element={<BookingDetails />}
                />
                <Route
                  path="paymentManagement"
                  element={<PaymentManagement />}
                />
                <Route path="account" element={<AdminAccount />} />
                <Route path="addstaff" element={<AdminAddStaff />} />

                <Route path="addbank" element={<AdminAddBank />} />
                <Route path="allbank" element={<AdminBankAccount />} />
              </Route>

              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
