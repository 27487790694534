import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Box,
  Grid,
  Modal,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
} from '@mui/material';
import commaNumber from 'comma-number';
import confirm from '../../images/Icon/confirm 1.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../images/loader/Render.gif';
import Swal from 'sweetalert2';
import secureLocalStorage from 'react-secure-storage';
import { format } from 'date-fns';
import { Calendar } from 'react-date-range';
import Invalid from '../../images/undraw/undraw_warning_re_eoyh.svg';
import ReConfirm from '../../images/undraw/undraw_confirmation_re_b6q5.svg';
import Issue from '../../images/undraw/undraw_booking_re_gw4j.svg';
import './Congratulation.css';
// import AllPDF from "../Queues/Queues/QueuesDetail/AllPdf";
import Header from '../Header/Header';
import FileUploadSection from '../Shared/FileUploadSection/FileUploadSection';
import { ToWords } from 'to-words';
import flightData from '../flightData';
import FareRules from './FareRules';
import AllPDF from './ALLPDF/ALLPDF';
import AirlineNames from './AirlineNames/AirlineNames';
import Address from './Address/Address';
import axios from 'axios';
import FareDetails from './FareDetails';
import PassengerDetails from './PassengerDetails';
import moment from 'moment';
import SeatMap from './SeatMap';

const styles = {
  button: {
    padding: '6px 20px',
    marginRight: '20px',
    color: '#fff',
    backgroundColor: 'var(--primary-color)',
    border: 'none',
    cursor: 'pointer',
  },
  buttonDisabled: {
    padding: '6px 20px',
    marginRight: '20px',
    color: '#fff',
    backgroundColor: 'var(--primary-color)',
    border: 'none',
    cursor: 'not-allowed',
  },
};

const dateStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#fff',
  borderRadius: '10px',
  overflow: 'auto',
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Congratulation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [downExpanded, setDownExpanded] = useState('panel1');
  const handleChangeDown = (panel) => (event, newExpanded) => {
    setDownExpanded(newExpanded ? panel : false);
  };
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [issueLoading, setIssueLoading] = useState(false);
  const users = secureLocalStorage.getItem('user-info');
  let agentID = users?.user?.agentId;
  let agentName = users?.user?.name;
  let stafgivenname = users?.user?.name;
  let userStaffId = users?.user?.staffId;
  const [isDone, setIsDone] = useState(true);
  const [allData, setAllData] = useState([]);
  const [timeLimit, setTimeLimit] = useState([]);
  const [passengerData, setPassengerData] = useState([]);
  const [invoiceId, setInvoiceId] = useState([]);
  const [fareOpen, setFareOpen] = useState(false);
  const [fareStore, setFareStore] = useState([]);
  const [openDate, setOpenDate] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  // Modal open Reissue Refund and void
  const [modalOpen, setModalOpen] = useState({
    quotation: false,
    value: '',
  });

  //todo:state change
  const [state, setState] = useState(false);
  // todo:for multicity
  //todo: calculate total flight duration
  const calDuration = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  };
  // !end
  const [lastBalance, setLastBalance] = useState(0);
  const toWords = new ToWords();
  const voideDate = new Date(allData?.lastUpdated);
  let today = new Date().toISOString().slice(0, 10);
  // const todaydate = new Date();
  const { pnr, gds } = location.state;

  // --------------------- client information start ---------------------------

  // visa and passport copy update state

  const updateModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    py: 3,
    px: 3,
    borderRadius: '10px',
    overflow: 'auto',
  };

  const agentId = location?.state?.agentId;
  const bookingId = params?.bookingId;
  const triptype = params?.triptype?.toLocaleLowerCase();
  const body = {
    agentId,
    bookingId,
  };
  const [flightData, setFlightData] = useState([]);
  const [fareTotal, setFareTotal] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [qeuesData, setQeuesData] = useState([]);

  const [isDisabled, setIsDisabled] = useState(false);
  const [stateChange, setStateChange] = useState(false);
  const [reissueData, setReIssueData] = useState();
  const [checkBox, setCheckBox] = useState();
  const [qoutationData, setQoutationData] = useState([]);

  const [requestModal, setRequestModal] = useState({
    modal: false,
    value: '',
  });

  const url = `https://api-b2b.projectota.com/flight/booking/details/${params?.uid}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setStateData(data?.bookingdata);
        setPassengerData(data?.passengerdata);
        setQeuesData(data);
        setFlightData(data?.sabredata?.flights);
        setFareTotal(data?.sabredata?.fares);
        setInvoiceId(data?.passengerdata);
        setAllData(data);
        // Only String Check Regex
        console.log(data?.sabredata);
        const timelimitText =
          data?.sabredata.specialServices.find((item) => item.code === 'ADTK')
            .message || '';
        const regex = /\b(\d{2}[A-Za-z]{3} \d{4})\b/;
        const match = timelimitText.match(regex);
        console.log(timelimitText);
        if (match) {
          const extractedText = match[1];
          const formattedDate = moment(extractedText, 'DDMMM HHmm').format(
            'YYYY-MM-DDTHH:mm:ss'
          );
          setTimeLimit(formattedDate);
          console.log(formattedDate);
        } else {
          console.log('Pattern not found in the input string');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error state or show an error message
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [bookingId, params?.uid, stateChange, refetch]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await fetch(urlticket);
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       const data = await response.json();
  //       setInvoiceId(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       // Handle error state or show an error message
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [params?.uid, refetch]);

  const cancelBooking = async () => {
    const confirmation = await Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
      title: 'Are you sure?',
      text: 'Do you want to cancel this booking?',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary-color)',
      confirmButtonText: 'Yes, Cancel it!',
      cancelButtonColor: 'crimson',
      cancelButtonText: "Don't Cancel it!",
    });

    if (confirmation.isConfirmed) {
      try {
        setIsDone(false);
        setOpen(false);
        setIsLoading(true);

        const url = `https://api-b2b.projectota.com/flight/booking/cancel/${params?.uid}`;
        const response = await fetch(url);
        const data = await response.json();

        if (response.ok) {
          setIsDone(true);
          await Swal.fire({
            imageUrl: Issue, // Consider using a different image for cancellation
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: 'Custom image',
            title: 'Booking Cancelled',
            html: `For any query, please contact us at <strong>contact@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
            confirmButtonColor: 'var(--primary-color)',
            confirmButtonText: 'Ok',
          }).then(function () {
            navigate('/user/queues');
          });
        } else {
          throw new Error('Failed to cancel booking');
        }
      } catch (err) {
        setIsDone(true);
        console.error(err);
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: 'Cancellation Failed!',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        });
      }
    }
  };

  async function handleIssueTicket() {
    const confirmation = await Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
      title: 'Are you sure?',
      text: 'Do you want to issue this ticket?',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary-color)',
      confirmButtonText: 'Yes, Issue it!',
      cancelButtonColor: 'crimson',
      cancelButtonText: "Don't Issue it!",
    });

    if (confirmation.isConfirmed) {
      try {
        setIsDone(false);
        setOpen(false);
        setIsLoading(true);

        const url = `https://api-b2b.projectota.com/flight/ticket/issue/${params?.uid}`;
        const response = await fetch(url);
        const data = await response.json();

        if (response.ok) {
          await Swal.fire({
            imageUrl: Issue,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: 'Custom image',
            title: 'Ticket Issue Complete',
            html: `For any query, please contact us at <strong>contact@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
            confirmButtonColor: 'var(--primary-color)',
            confirmButtonText: 'Ok',
          });
          setIsDone(true);
          setOpen(true);
          setIsLoading(false);
          setRefetch(!refetch);
        } else {
          throw new Error('Failed to issue ticket');
        }
      } catch (err) {
        console.error(err);
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: err?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        });
        setIsDone(true);
        setOpen(true);
        setIsLoading(false);
        setRefetch(!refetch);
      }
    }
  }

  async function handleVoid() {
    const confirmation = await Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
      title: 'Are you sure?',
      text: 'Do you want to Void this ticket?',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary-color)',
      confirmButtonText: 'Yes, Void it!',
      cancelButtonColor: 'crimson',
      cancelButtonText: "Don't Void it!",
    });

    if (confirmation.isConfirmed) {
      try {
        setIsDone(false);
        setOpen(false);
        setIsLoading(true);

        const url = `https://api-b2b.projectota.com/flight/ticket/void/${params?.uid}`;
        const response = await fetch(url);
        const data = await response.json();

        if (response.ok) {
          await Swal.fire({
            imageUrl: Issue,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: 'Custom image',
            title: 'Ticket void has been successfully',
            html: `For any query, please contact us at <strong>contact@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
            confirmButtonColor: 'var(--primary-color)',
            confirmButtonText: 'Ok',
          }).then(function () {
            navigate('/user/queues');
          });
          setIsDone(true);
          setOpen(true);
          setIsLoading(false);
          setRefetch(!refetch);
        } else {
          throw new Error('Failed to Void ticket');
        }
      } catch (err) {
        console.error(err);
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
          title: 'Void Ticket Failed!',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        });
      }
    }
  }

  // Qoutation Show start
  const handleQutationsFetch = async (value) => {
    const body = {
      bookingId: bookingId,
      agentId: agentId,
    };
    await fetch(
      `https://api.flyjatt.com/v1/Ticketed/${value}.php?getquotadata`,

      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: JSON.stringify(body),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setQoutationData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Qoutation Show end
  // Qoutation Approve and Reject start
  const handleOption = async (value, option) => {
    // window.event.preventDefault();
    setIsDisabled(true);
    // setIsLoading(false);

    const body = {
      bookingId: bookingId,
      agentId: agentId,
      option: option,
    };

    await fetch(
      `https://api.flyjatt.com/v1/Ticketed/${value}.php?option`,

      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: JSON.stringify(body),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === 'success') {
          setModalOpen(false);
          setIsLoading(true);
          Swal.fire({
            icon: 'success',
            title: `${data?.message}`,
            html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
            confirmButtonText: 'OK',
          }).then(function () {
            setStateChange(true);
          });
        } else {
          setModalOpen(false);
          setIsLoading(true);
          Swal.fire({
            icon: 'error',
            title: 'Request Failed!',
            html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Qoutation Approve and Reject end

  let reissue = [];
  const handleCheckBox = (index) => {
    const e = window.event;
    const tempData = [...invoiceId];
    tempData[index] = { ...tempData[index], checkBox: e.target.checked };
    setCheckBox(e.target.checked);
    setInvoiceId(tempData);
  };

  const checkedData = invoiceId?.filter((data, index) => {
    if (data.checkBox === true) {
      reissue = [
        ...reissue,
        {
          name: `${data.givenname + data.surname}`,
          ticketnumber: allData?.sabredata?.flightTickets[index]?.number,
        },
      ];
    }
  });

  let reissuebodyName = reissue
    .map((data) => `${data.name} - ${data.ticketnumber}`)
    .join(',');

  // let reissuebodyTicketNo = reissue
  //   ?.map((data) => data?.ticketnumber)
  //   .join(',');

  let reissueBody = JSON.stringify({
    text: reissuebodyName,
    date: date,
  });

  let refundBody = JSON.stringify({
    text: reissuebodyName,
  });

  const handleReissue = async (e) => {
    setIsDisabled(true);
    setIsLoading(false);
    e.preventDefault();
    const reissueRefund = `https://api-b2b.projectota.com/agent/${requestModal?.value?.toLocaleLowerCase()}/request/${
      params?.uid
    }`;

    try {
      const response = await fetch(reissueRefund, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: `${requestModal?.value === 'Reissue' ? reissueBody : refundBody}`,
      });

      const data = await response.json();

      setRequestModal({ modal: false });
      setIsLoading(false);

      Swal.fire({
        icon: response.ok ? 'success' : 'error',
        title: response.ok
          ? `${requestModal?.value} Quotation success`
          : `${data?.message}`,
        html: `For any query, please contact us at <strong>contact@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
        confirmButtonText: 'OK',
      }).then(function () {
        setStateChange(true);
        setRequestModal({ modal: false });
      });
    } catch (err) {
      console.error(err);
    }

    e.target.reset();
  };

  //  all passenger total price calculation
  const adultTotalPrice =
    parseInt(allData?.adultCostBase) + parseInt(allData?.adultCostTax);
  const childTotalPrice =
    parseInt(allData?.childCostBase) + parseInt(allData?.childCostTax);
  const infantTotalPrice =
    parseInt(allData?.infantCostBase) + parseInt(allData?.infantCostTax);
  const totalPrice = adultTotalPrice + childTotalPrice + infantTotalPrice;
  const savingMoney = Math.abs(
    parseInt(allData?.grossCost) - parseInt(allData?.netCost)
  );
  //todo remove last word function

  // const handleCheckBox = (index) => {
  //   const e = window.event;
  //   const tempData = [...invoiceId];
  //   tempData[index] = { ...tempData[index], checkBox: e.target.checked };
  //   setCheckBox(e.target.checked);
  //   setInvoiceId(tempData);
  // };

  // fare details start

  const handleClickfareDetails = async () => {
    setFareOpen(true);

    const Segmentss = [
      {
        DepDate: stateData?.journeyDate,
        DepFrom: stateData?.DepFrom,
        ArrTo: stateData?.ArrTo,
        Airlines: qeuesData?.data?.flights[0]?.airlineCode || '',
      },
    ];
    let farebody = JSON.stringify({
      FareBasis: `${qeuesData?.data?.fares[0]?.fareConstruction[0]?.fareBasisCode}`,
      Segments: Segmentss,
    });

    const fareurl = 'https://api.flyjatt.com/v1/AirFareRules/TextRules.php';
    await fetch(fareurl, {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },

      body: farebody,
    })
      .then((res) => res.json())
      .then((data) => {
        setFareStore(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fare details end

  // if (!isDone) {
  //   return (
  //     <Box
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         height: '70vh',
  //         width: '70vw',
  //         marginInline: 'auto',
  //       }}
  //     >
  //       <Box
  //         style={{
  //           width: '50%',
  //           height: '50%',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <img
  //           src={Loader}
  //           alt="loader"
  //           style={{
  //             width: '100%',
  //             objectFit: 'center',
  //           }}
  //         />
  //       </Box>
  //     </Box>
  //   );
  // }

  let count = 0;
  const passengerCount = (p) => {
    count = count + p;
    return count;
  };

  return (
    <Box sx={{ overflowX: 'hidden ' }}>
      <Header />
      <Container sx={{ mt: { xs: 10, sm: 6, md: 2 } }}>
        <Box position={'relative'}>
          {!isDone || !isLoading ? (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={9.5}>
                <Box sx={{ marginTop: '20px', overflowX: 'hidden' }}>
                  {location?.state?.queues === 'queues' ||
                  stateData?.status !== 'Hold' ? null : (
                    <Box pb={1}>
                      <Typography
                        variant={{ xs: 'h6', md: 'h5' }}
                        color="green"
                      >
                        Thank You. Your Booking is Confirmed
                      </Typography>
                      {/* <Typography>
                        A Confirmation email has been sent to your provided
                        email address.
                      </Typography> */}
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        width: 'fit-content',
                        background: 'var(--primary-color)',
                        color: 'var(--white)',
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '5px 10px',
                        borderRadius: '4px',
                      }}
                    >
                      Booking Id: {bookingId || 'BookingId'}
                    </Typography>
                    <Typography
                      sx={{
                        width: 'fit-content',
                        background: '#ff6b00',
                        color: 'var(--white)',
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '5px 10px',
                        borderRadius: '4px',
                      }}
                      // className={`${stateData?.status?.toLowerCase()}-btn`}
                    >
                      {stateData?.status}
                    </Typography>
                  </Box>

                  <Box sx={{ my: 2 }}>
                    <Typography
                      sx={{
                        width: '100%',
                        color: 'var(--white)',
                        fontWeight: '500',
                        bgcolor: 'var(--primary-color)',
                        px: 2,
                        py: 1,
                      }}
                    >
                      Flight Information
                    </Typography>

                    <Box
                      className="table-wrapper"
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: '0px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: 'var(--primary-color)',
                          borderRadius: '0px',
                        },
                        overflowY: 'scroll',
                      }}
                    >
                      <table className="responsive-table congra">
                        <thead>
                          <tr>
                            <th width="15%">Flight</th>
                            <th width="23%">Departure From</th>
                            <th width="23%">Arrival To</th>
                            <th width="12%">Depart At</th>
                            <th width="12%">Arrive At</th>
                            <th width="15%">Info</th>
                          </tr>
                        </thead>
                        <tbody>
                          {flightData?.length === 0 ? (
                            <Box>Loading...</Box>
                          ) : (
                            <>
                              {flightData?.map((data, index) => (
                                <tr>
                                  <td>
                                    <AirlineNames code={data?.airlineCode} />{' '}
                                    {data?.airlineCode}-{data?.flightNumber}
                                    <br />
                                    {data?.aircraftTypeName}
                                  </td>
                                  <td>
                                    ({data.fromAirportCode}){' '}
                                    <Address code={data.fromAirportCode} />
                                    <br />
                                    {data?.departureTerminasurname || null}{' '}
                                    {data?.departureGate
                                      ? `Gate: ${data?.departureGate || null}`
                                      : null}
                                  </td>
                                  <td>
                                    ({data.toAirportCode}){' '}
                                    <Address code={data.toAirportCode} />
                                    <br />
                                    {data?.arrivalTerminasurname || null}{' '}
                                    {data?.arrivalGate
                                      ? `Gate: ${data?.arrivalGate || null}`
                                      : null}
                                  </td>
                                  <td>
                                    {data.departureDate}{' '}
                                    {data.departureTime?.slice(0, 5)}
                                  </td>
                                  <td>
                                    {data.arrivalDate}{' '}
                                    {data.arrivalTime?.slice(0, 5)}
                                  </td>
                                  <td>
                                    {data?.cabinTypeName?.toLowerCase()}-
                                    {data?.bookingClass}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                  <Box my={2}>
                    <Typography
                      sx={{
                        width: '100%',
                        color: 'var(--white)',
                        fontWeight: '500',
                        bgcolor: 'var(--primary-color)',
                        px: 2,
                        py: 1,
                      }}
                    >
                      Price BreakDown
                    </Typography>

                    <Box
                      className="table-wrapper"
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: '0px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: 'var(--primary-color)',
                          borderRadius: '0px',
                        },
                        overflowY: 'scroll',
                      }}
                    >
                      <table className="responsive-table congra">
                        <thead>
                          <tr>
                            <th width="20%">Passenger </th>
                            <th width="20%">Base Fare </th>
                            <th width="20%">Tax</th>
                            <th width="20%">Sub-Total Fare</th>
                            <th width="20%">Baggage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fareTotal?.length === 0 ? (
                            <>Loading...</>
                          ) : (
                            <>
                              {fareTotal?.map((data, index) => (
                                <tr>
                                  <td>
                                    {data?.pricedTravelerType === 'ADT'
                                      ? `Adult  X ${allData.bookingdata.adultcount}`
                                      : data?.pricedTravelerType === 'INF'
                                      ? `Infant  X  ${allData.bookingdata.infantcount}`
                                      : `Child  X ${allData.bookingdata.childcount}`}
                                  </td>
                                  <td>
                                    {parseInt(data?.totals?.subtotal) *
                                      parseInt(
                                        data?.pricedTravelerType === 'ADT'
                                          ? allData.bookingdata.adultcount || 1
                                          : data?.pricedTravelerType === 'INF'
                                          ? allData.bookingdata.infantcount || 1
                                          : allData.bookingdata.childcount || 1
                                      )}{' '}
                                    BDT
                                  </td>
                                  <td>
                                    {parseInt(data?.totals?.taxes) *
                                      parseInt(
                                        data?.pricedTravelerType === 'ADT'
                                          ? allData.bookingdata.adultcount || 1
                                          : data?.pricedTravelerType === 'INF'
                                          ? allData.bookingdata.infantcount || 1
                                          : allData.bookingdata.childcount || 1
                                      )}{' '}
                                    BDT
                                  </td>
                                  <td>
                                    {parseInt(data?.totals?.total) *
                                      parseInt(
                                        data?.pricedTravelerType === 'ADT'
                                          ? allData.bookingdata.adultcount || 1
                                          : data?.pricedTravelerType === 'INF'
                                          ? allData.bookingdata.infantcount || 1
                                          : allData.bookingdata.childcount || 1
                                      )}{' '}
                                    BDT
                                  </td>
                                  <td>
                                    {data?.fareConstruction[0]
                                      ?.checkedBaggageAllowance
                                      ?.totalWeightInKilograms
                                      ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.totalWeightInKilograms} Kg`
                                      : null}
                                    {data?.fareConstruction[0]
                                      ?.checkedBaggageAllowance?.maximumPieces
                                      ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.maximumPieces} Peices`
                                      : null}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                          <tr>
                            <td>
                              Total Passenger{' '}
                              {parseInt(allData?.bookingdata?.adultcount) +
                                parseInt(allData?.bookingdata?.infantcount) +
                                parseInt(allData?.bookingdata?.childcount)}
                            </td>
                            <td>
                              Total Fare{' '}
                              {
                                allData?.sabredata?.payments?.flightTotals[0]
                                  ?.subtotal
                              }{' '}
                              BDT
                            </td>
                            <td>
                              Total Taxes{' '}
                              {
                                allData?.sabredata?.payments?.flightTotals[0]
                                  ?.taxes
                              }{' '}
                              BDT
                            </td>
                            <td colSpan={2}>
                              Total Amount{' '}
                              {
                                allData?.sabredata?.payments?.flightTotals[0]
                                  ?.total
                              }{' '}
                              BDT
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <Typography
                      sx={{
                        width: '100%',
                        color: 'var(--white)',
                        fontWeight: '500',
                        bgcolor: 'var(--primary-color)',
                        px: 2,
                        py: 1,
                      }}
                    >
                      Passenger Information
                    </Typography>
                    <PassengerDetails allData={allData} />

                    {/* passenger information end */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={2.5} mt={1.5}>
                <Box>
                  <Box
                    sx={{
                      mt: 1,
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      p: { xs: 1, sm: 1.5 },
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'var(--primary-color)',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      PNR: {stateData?.pnr || ''}
                    </Typography>
                  </Box>
                  {stateData?.status === 'Hold' && (
                    <Box
                      sx={{
                        mt: 1,
                        mb: 2,
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        p: { xs: 1, sm: 1.5 },
                      }}
                    >
                      <Typography
                        sx={{
                          mb: 1,
                          color: 'var(--primary-color)',
                          fontWeight: 500,
                        }}
                      >
                        Time Limit
                      </Typography>
                      {timeLimit?.length === 0 ? (
                        'No Time Limit'
                      ) : (
                        <Typography sx={{ color: 'var(--red)', fontSize: 13 }}>
                          {moment(timeLimit).format('DD-MMM-YYYY hh:mm A')}
                        </Typography>
                      )}
                    </Box>
                  )}

                  {/* //todo:all buttons */}
                  {stateData?.status === 'Ticketed' ||
                  stateData?.status === 'Reissued' ? (
                    <>
                      <Box className="queues-detail-calcel-btn" my={1}>
                        <button
                          style={{
                            padding: '8px 20px',
                            color: '#fff',
                            backgroundColor: 'var(--primary-color)',
                            border: 'none',
                            cursor: 'pointer',
                            width: '100%',
                          }}
                          onClick={(e) => {
                            setRequestModal({
                              modal: true,
                              value: 'Reissue',
                            });
                          }}
                        >
                          Re-Issue
                        </button>
                      </Box>
                      {allData?.sabredata?.fareRules ===
                      undefined ? null : allData?.sabredata?.fareRules[0]
                          ?.isRefundable === true ? (
                        <Box className="queues-detail-calcel-btn" mb={1}>
                          <button
                            style={{
                              padding: '8px 20px',
                              color: '#fff',
                              backgroundColor: 'var(--primary-color)',
                              border: 'none',
                              cursor: 'pointer',
                              width: '100%',
                            }}
                            onClick={(e) => {
                              setRequestModal({
                                modal: true,
                                value: 'Refund',
                              });
                            }}
                          >
                            Refund
                          </button>
                        </Box>
                      ) : (
                        ''
                      )}

                      {stateData?.status === 'Ticketed' &&
                      today <= stateData?.updated_at ? (
                        <Box className="queues-detail-calcel-btn">
                          <button
                            style={{
                              padding: '8px 20px',
                              color: '#fff',
                              backgroundColor: 'var(--primary-color)',
                              border: 'none',
                              cursor: 'pointer',
                              width: '100%',
                            }}
                            onClick={() => handleVoid()}
                          >
                            Void
                          </button>
                        </Box>
                      ) : (
                        ''
                      )}
                    </>
                  ) : stateData?.status === 'Issue In Processing' ? (
                    <Typography
                      sx={{
                        color: '#fff',
                        bgcolor: 'var(--secondary-color)',
                        py: 1,
                        textAlign: 'center',
                      }}
                    >
                      Wait For Ticketed
                    </Typography>
                  ) : stateData?.status === 'Refund In Processing' ? (
                    <Typography
                      sx={{
                        color: '#fff',
                        bgcolor: 'var(--secondary-color)',
                        py: 1,
                        textAlign: 'center',
                      }}
                    >
                      Wait For Refunded
                    </Typography>
                  ) : stateData?.status === 'Reissue In Processing' ? (
                    <Typography
                      sx={{
                        color: '#fff',
                        bgcolor: 'var(--secondary-color)',
                        py: 1,
                        textAlign: 'center',
                      }}
                    >
                      Wait For Reissued
                    </Typography>
                  ) : stateData?.status === 'Void In Processing' ? (
                    <Typography
                      sx={{
                        color: '#fff',
                        bgcolor: 'var(--secondary-color)',
                        py: 1,
                        textAlign: 'center',
                      }}
                    >
                      Wait For Voided
                    </Typography>
                  ) : (
                    <>
                      {stateData?.status === 'Hold' && (
                        <Box>
                          <Box my={1}>
                            <button
                              style={{
                                padding: '6px',
                                width: '100%',
                                cursor: 'pointer',
                                border: 'none',
                                backgroundColor: 'var(--primary-color)',
                              }}
                              onClick={() => handleIssueTicket()}
                              disabled={!isDone ? true : false}
                            >
                              {!isDone ? 'Loading...' : 'Issue Ticket'}
                            </button>
                          </Box>
                          <Box my={1}>
                            <button
                              style={{
                                padding: '6px',
                                width: '100%',
                                cursor: 'pointer',
                                border: 'none',
                                backgroundColor: 'red',
                              }}
                              onClick={() => cancelBooking()}
                              disabled={!isDone ? true : false}
                            >
                              {!isDone ? 'Loading...' : 'Cancel Flight'}
                            </button>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                  {stateData?.status === 'Reissue Quotation Send' && (
                    <Typography
                      className="issueTicket-btn"
                      sx={{
                        bgcolor: 'var(--primary-color)',
                        color: '#fff',
                        my: 2,
                        py: 1,
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        setModalOpen({
                          quotation: 'true',
                          value: 'Reissue',
                        });
                        handleQutationsFetch('Reissue');
                      }}
                    >
                      View Quotation
                    </Typography>
                  )}
                  {stateData?.status === 'Refund Quotation Send' && (
                    <Typography
                      className="issueTicket-btn"
                      sx={{
                        bgcolor: 'var(--primary-color)',
                        color: '#fff',
                        my: 2,
                        py: 1,
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        setModalOpen({
                          quotation: 'true',
                          value: 'Refund',
                        });
                        handleQutationsFetch('Refund');
                      }}
                    >
                      View Refund Quotation
                    </Typography>
                  )}

                  <Box
                    sx={{
                      mt: 2,
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      p: { xs: 1, sm: 1.5 },
                    }}
                  >
                    <Typography
                      sx={{
                        mb: 2,
                        color: 'var(--primary-color)',
                        fontWeight: 500,
                      }}
                    >
                      PDF Download
                    </Typography>
                    <Box pb={1.5}>
                      <AllPDF
                        isLoading
                        triptype={params?.triptype}
                        uid={params?.uid}
                        bookingId={bookingId}
                        flightData={flightData}
                        fareTotal={fareTotal}
                        passengerData={passengerData}
                        allData={allData}
                        stateData={stateData}
                        qeuesData={qeuesData}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      mt: 2,
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      p: { xs: 1, sm: 1.5 },
                    }}
                  >
                    <Typography
                      sx={{
                        mb: 1,
                        color: 'var(--primary-color)',
                        fontWeight: 500,
                      }}
                    >
                      Fare Rulse
                    </Typography>
                    <Box>
                      {allData?.sabredata?.fareRules === undefined ? (
                        ''
                      ) : allData?.sabredata?.fareRules?.length === 0 ? (
                        'Loading Data...'
                      ) : allData?.sabredata?.fareRules?.length >= 1 ? (
                        <Box>
                          {allData?.sabredata?.fareRules?.map((data) => (
                            <FareRules data={data} />
                          ))}
                        </Box>
                      ) : (
                        'No fare rules this flight'
                      )}
                    </Box>
                  </Box>

                  <Box py={1}>
                    <FareDetails allData={allData} />
                  </Box>
                  <Box py={1}>
                    <SeatMap flightData={flightData} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                style={{
                  width: '50%',
                  height: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: '100%',
                    objectFit: 'center',
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>

        {/* ---------Modal Refund, Request, void Request start--------- */}

        <Box className="queues-detail-calcel-btn">
          <Modal
            open={requestModal.modal}
            onClose={() => setRequestModal({ modal: false })}
          >
            <Box
              bgcolor="#fff"
              sx={{
                ...updateModalStyle,
                width: { xs: '100%', sm: '80%', md: '40%' },
                p: { xs: 1, sm: 3 },
              }}
            >
              <Box className="modal-table">
                <Typography
                  sx={{
                    color: '#222222',
                    fontSize: '20px',
                    fontWeight: 500,
                    mb: '10px',
                  }}
                >
                  Make {requestModal.value} Request
                </Typography>

                <table width="100%">
                  <thead>
                    <tr>
                      <th width="5%">Select</th>
                      <th width="25%">Passenger&nbsp;Name</th>
                      <th width="20%">Gender</th>
                      <th width="20%">Passenger&nbsp;Type</th>
                      <th width="30%">Ticket No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {passengerData?.map((traveler, index) => (
                      <tr key={index}>
                        <td width="10px" style={{ border: 'none' }}>
                          <FormGroup
                            style={{
                              padding: '0px',
                              margin: '0px',
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{
                                    padding: '0px',
                                    margin: 'auto',
                                    marginLeft: '20px',
                                  }}
                                  onChange={() => handleCheckBox(index)}
                                />
                              }
                            />
                          </FormGroup>
                        </td>
                        <td>
                          {traveler?.gender === 'Male' ? (
                            <>
                              {traveler?.gender === 'Male' &&
                              traveler?.type === 'ADT' ? (
                                <>
                                  MR {traveler?.givenname} {traveler?.surname}
                                </>
                              ) : (
                                <>
                                  MSTR {traveler?.givenname} {traveler?.surname}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {traveler?.gender === 'Female' &&
                              traveler?.type === 'ADT' ? (
                                <>
                                  MS {traveler?.givenname} {traveler?.surname}
                                </>
                              ) : (
                                <>
                                  MISS {traveler?.givenname} {traveler?.surname}
                                </>
                              )}
                            </>
                          )}
                        </td>
                        <td>{traveler?.gender}</td>
                        <td>
                          {traveler?.type === 'ADT'
                            ? 'Adult'
                            : traveler?.type === 'CNN'
                            ? 'Child'
                            : 'Infat'}
                        </td>
                        <td>
                          {allData?.sabredata?.flightTickets === undefined
                            ? null
                            : allData?.sabredata?.flightTickets[index]
                                ?.number || 'ticket number'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems="center"
                >
                  <Grid item mt={2}>
                    {requestModal.value === 'Void' ||
                    requestModal.value === 'Refund' ? null : (
                      <Box>
                        <label htmlFor="date">Select Date</label> <br />
                        <Box>
                          <input
                            style={{
                              border: '2px solid #C4C4C4',
                              padding: '5px',
                              fontSize: '14px',
                              cursor: 'pointer',
                            }}
                            required
                            type="text"
                            name="date"
                            readOnly
                            value={format(new Date(date), 'dd MMM yyyy')}
                            onClick={() => {
                              setOpenDate((prev) => !prev);
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid item mt={4}>
                    <Box>
                      {isDisabled ? (
                        <button
                          style={
                            isDisabled ? styles.buttonDisabled : styles.button
                          }
                        >
                          Wait For Response
                        </button>
                      ) : (
                        <button
                          disabled={!checkBox === true}
                          style={
                            isDisabled ? styles.buttonDisabled : styles.button
                          }
                          onClick={
                            requestModal.value === 'Void'
                              ? handleVoid
                              : handleReissue
                          }
                        >
                          Submit
                        </button>
                      )}

                      <button
                        style={{
                          padding: '6px 20px',
                          color: '#fff',
                          backgroundColor: 'red',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        type="reset"
                        onClick={() =>
                          setRequestModal({
                            modal: false,
                          })
                        }
                      >
                        Cancel
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* </from> */}
          </Modal>
          <Modal open={openDate} onClose={() => setOpenDate(false)}>
            <Box sx={dateStyle}>
              <Calendar
                color={'#222222'}
                date={new Date(date)}
                onChange={(date) => {
                  setDate(new Date(date).toLocaleDateString('sv'));
                  setOpenDate(false);
                }}
                minDate={new Date(date)}
                months={1}
                direction="horizontal"
                // className="reissue-calendar"
                name="dashboard-calendar"
              />
            </Box>
          </Modal>
        </Box>

        {/* ---------Modal Refund, Request, void Request start--------- */}
        {/* ---------Quatiton Modla start--------- */}
        <Box>
          <Modal
            open={modalOpen.quotation}
            onClose={() =>
              setModalOpen({
                quotation: false,
              })
            }
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{ ...modalStyle, width: 500 }}
              style={{
                border: 'none',
                borderRadius: '5px',
              }}
            >
              <Typography
                sx={{
                  color: '#003566',
                  fontSize: '20px',
                  mb: 1,
                }}
              >
                <Box sx={{ fontSize: '20px', fontWeight: 500, mb: 1 }}>
                  Quotation
                </Box>

                {modalOpen?.value === 'Refund' ? (
                  <Box
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
                  >
                    Refund Service Charges: {qoutationData[0]?.quotamount}
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
                    >
                      Reissue Amount: {qoutationData[0]?.quotamount}
                    </Box>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#000',
                        mb: 5,
                      }}
                    >
                      Description: {qoutationData[0]?.quottext}
                    </Typography>
                  </Box>
                )}
              </Typography>

              <Box className="balance-transaction">
                <Stack direction="row" justifyContent="flex-end">
                  <Box>
                    {!isDisabled ? (
                      <Button
                        onClick={() => handleOption(modalOpen?.value, 'yes')}
                        size="small"
                        disabled={isDisabled}
                        style={
                          isDisabled ? styles.buttonDisabled : styles.button
                        }
                      >
                        Approve
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        disabled={isDisabled}
                        style={
                          isDisabled ? styles.buttonDisabled : styles.button
                        }
                      >
                        Wait For Response
                      </Button>
                    )}
                    {!isDisabled ? (
                      <Button
                        size="medium"
                        sx={{
                          color: '#fff',
                          bgcolor: 'red',
                          '&:hover': {
                            bgcolor: 'red',
                          },
                        }}
                        onClick={() => handleOption(modalOpen?.value, 'no')}
                      >
                        Decline
                      </Button>
                    ) : null}
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Modal>
        </Box>
        {/* ---------Quatiton Modla end--------- */}

        {/* //todo: Update Document Modal */}
        {/* <Modal open={seatOpen} onClose={() => setSeatOpen(false)}>
          <Box sx={updateModalStyle}>
            <SeatMap flightData={flightData} />
          </Box>
        </Modal> */}
      </Container>
    </Box>
  );
};

export default Congratulation;
