import React, { useEffect, useState } from 'react';

import {
  Pagination,
  Stack,
  CircularProgress,
  Grid,
  Modal,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Swal from 'sweetalert2';
import secureLocalStorage from 'react-secure-storage';
import EditIcon from '@mui/icons-material/Edit';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import SearchIcon from '@mui/icons-material/Search';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2564b8',
      darker: '#2564b8',
    },
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AccountDetails = () => {
  const adminInfo = secureLocalStorage.getItem('admin-info');
  const token = adminInfo?.token;
  const [userData, setUserData] = useState(null);

  const [open, setOpen] = useState(false);
  const [stateChanges, setStateChanges] = useState(false);

  const handleOpen = (data) => {
    setUserData(data);

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState([]);
  const [mainAgentData, setMainAgentData] = useState([]);
  //  pagination handle
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsloading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  let size = 20;
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setMainAgentData(paymentData.slice((value - 1) * size, value * size));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const apiUrl = 'https://api-b2b.projectota.com/admin';
    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the header
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setPageCount(data?.length);
        setMainAgentData(data);
        setPaymentData(data);
        setIsloading(true);
        setStateChanges(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsloading(false);
      });
  }, [page, open, stateChanges, refetch]);

  //  search functionality handle
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== '') {
      const filterData = paymentData.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setMainAgentData(filterData);
    } else if (searchInput === '') {
      setMainAgentData(paymentData);
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();

    let url = `https://api-b2b.projectota.com/admin/${userData?.uid}`;

    fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include the token in the header
      },
      body: JSON.stringify({
        firstname: userData?.firstname,
        lastname: userData?.lastname,
        password: userData?.password,
        phone: userData?.phone,
        status: userData?.status,
        role: userData?.role,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setOpen(false);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Update Successfully',
          confirmButtonText: 'OK',
        }).then(() => {
          setOpen(false);
          setRefetch(!refetch);
        });
      })
      .catch((error) => {
        console.error('Error updating data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update data. Please try again.',
          confirmButtonText: 'OK',
        }).then(() => {
          setOpen(false);
        });
      });
  };

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '24px',
            color: '#222222',
            fontFamily: 'poppins',
          }}
        >
          User Accounts
        </Typography>
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Box
            sx={{
              background: 'var(--primary-color)',
              height: '35px',
              borderRadius: '20px',
              input: {
                border: 'none',
                background: 'none',
                outline: 'none',
                float: 'left',
                padding: 0,
                color: 'white',
                fontSize: '16px',
                fontWeight: 'normal',
                transition: 'all 0.4s ease-in-out',
                lineHeight: '35px',
                width: '0px',
              },
              'input::placeholder': { color: 'white' },
              '&:hover input': {
                width: '235px',
                padding: '0 10px',
              },
              '&:hover button': {
                background: 'white',
                color: 'black',
              },
              button: {
                border: 'none',
                color: 'white',
                float: 'right',
                minHeight: 'fit-content',
                width: '35px',
                height: '35px',
                borderRadius: '50%',
                background: 'var(--primary-color)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'all 0.4s ease-in-out',
                cursor: 'pointer',
                textDecoration: 'none',
              },
            }}
          >
            <input
              type="text"
              placeholder="Search....."
              onChange={handelSearchItems}
            />
            <button>
              <SearchIcon />
            </button>
          </Box>
          <Button
            style={{
              padding: '5px 10px',
              color: 'var(--white)',
              background: 'var(--secondary-color)',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '4px',
              textTransform: 'capitalize',
              fontSize: '14px',
            }}
            onClick={() => {
              navigate('/dashboard/addstaff');
            }}
          >
            Add Account
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          mt: '5px',
          '&::-webkit-scrollbar': {
            width: '0px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '0px',
            background: 'var(--secondary-color)',
          },
          height: 'calc(100vh - 180px)',
          overflowY: 'scroll',
          table: {
            width: '100%',
            position: 'relative',
            borderCollapse: 'collapse',
          },
          th: {
            position: 'sticky',
            top: 0,
            zIndex: '999',
            background: 'var(--primary-color)',
            color: 'white',
            border: '1px solid #ddd',
            padding: '8px 12px',
            fontSize: '14px',
            fontWeight: '500',
          },
          td: {
            textAlign: 'center',
            verticalAlign: 'middle',
            border: '1px solid #ddd !important',
            padding: '5px !important',
            fontSize: '12px',
          },
          'tr:nth-child(even)': {
            background: '#f2f2f2',
          },
          'tr:hover': {
            background: '#ddd',
          },
        }}
      >
        {isLoading ? (
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Role</th>
                <th>Phone</th>
                <th>Operation</th>
              </tr>
            </thead>
            <tbody>
              {mainAgentData?.map((data, index) => (
                <tr key={index}>
                  <td>{data?.firstname}</td>
                  <td>{data?.lastname}</td>
                  <td>{data?.status}</td>
                  <td>{data?.email}</td>
                  <td>{data?.role}</td>
                  <td>{data?.phone}</td>
                  <td>
                    <EditIcon
                      style={{
                        color: 'var(--primary-color)',
                        fontSize: '22px',
                        marginRight: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleOpen(data)}
                    />
                    {/* <DeleteIcon
                      style={{
                        color: 'tomato',
                        fontSize: '22px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDelete(data?.id)}
                    /> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '38vh',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
          my: 1,
          py: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '8px',
          borderTop: '1px solid var(--primary-color)',
        }}
      >
        <Typography style={{ fontSize: '15px', color: '#222222' }}>
          Showing Results {page} - {pageCount}
        </Typography>
        <ThemeProvider theme={theme}>
          <Stack spacing={2}>
            <Pagination
              size="small"
              count={pageCount}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </ThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box mt={2}>
              <Typography
                sx={{
                  fontWeight: '500px',
                  fontSize: '23px',
                  color: '#003566',
                  textAlign: 'center',
                }}
                mb={2}
              >
                Update Admin
              </Typography>

              <form onSubmit={handleAdd}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                      >
                        First Name
                      </Typography>
                      <input
                        required
                        type="text"
                        name="firstname"
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                        placeholder="First Name"
                        value={userData?.firstname}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                        mb={1}
                      >
                        Last Name
                      </Typography>
                      <input
                        required
                        type="text"
                        name="lastname"
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                        placeholder="First Name"
                        value={userData?.lastname}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                      >
                        Email
                      </Typography>
                      <input
                        required
                        type="email"
                        name="email"
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                        placeholder="Email"
                        value={userData?.email}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                      >
                        Password
                      </Typography>
                      <input
                        required
                        type="text"
                        name="password"
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                        placeholder="password"
                        value={userData?.password}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                      >
                        Contact Number
                      </Typography>
                      <input
                        required
                        type="text"
                        name="phone"
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                        placeholder="phone"
                        value={userData?.phone}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                      >
                        Status
                      </Typography>

                      <select
                        value={userData?.status}
                        onChange={handleChange}
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                      >
                        <option>Select Status</option>
                        <option value={'active'}>Active</option>
                        <option value={'deactive'}>Deactive</option>
                      </select>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '14px',
                        }}
                      >
                        Role
                      </Typography>

                      <select
                        value={userData?.role}
                        onChange={handleChange}
                        style={{
                          border: 'none',
                          borderBottom: '1px solid var(--black)',
                          width: '100%',
                          outline: 'none',
                        }}
                      >
                        <option>Select Role</option>
                        <option value={'Admin'}>Admin</option>
                        <option value={'Accountant'}>Accountant</option>
                        <option value={'Staff'}>Staff</option>
                      </select>
                    </Box>
                  </Grid>
                </Grid>

                <Button
                  sx={{
                    background: 'var(--mateBlack)',
                    color: '#FFFFFF',
                    width: '370px',
                    mt: '4rem',
                    '&:hover': {
                      backgroundColor: 'var(--mateBlack)',
                    },
                  }}
                  type="submit"
                >
                  Update This Account
                </Button>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AccountDetails;
