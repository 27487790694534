import React, { useEffect, useState } from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import commaNumber from 'comma-number';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { useQuery } from 'react-query';
import AirlineNames from '../AirlineNames/AirlineNames';
import Address from '../Address/Address';

const PDFPageDesign = ({
  userData,
  adultTotalPrice,
  childTotalPrice,
  infantTotalPrice,
  totalPrice,
  prices,
  copy,
  check,
  companyImage,
  margin,
  discount,
  discountPrice,
  multiCityAllData,

  bookingId,
  flightData,
  fareTotal,
  passengerData,
  allData, //airRetrive api data
  triptype,
  stateData, //Queues api data
  qeuesData,
}) => {
  function parseNameWithPrefix(fullName) {
    const prefixes = ['MR', 'MS', 'MSTR', 'MISS'];
    const nameArray = fullName.split(' ');
    let prefix = '';
    let givenName = '';

    for (const part of nameArray) {
      if (prefixes.includes(part)) {
        prefix = part;
      } else {
        givenName += givenName ? ` ${part}` : part;
      }
    }

    return { prefix, givenName };
  }

  // Extract relevant information and remove nesting
  const outputJSON = allData?.sabredata?.travelers?.flatMap((entry) => {
    if (entry.identityDocuments) {
      const documentsWithNumber = entry?.identityDocuments?.filter(
        (doc) => doc.documentNumber
      );
      return documentsWithNumber.map((doc) => {
        const extractedDoc = {
          // prefix: parseNameWithPrefix(entry.givenName),
          givenName: doc.givenName,
          surname: doc.surname,
          documentNumber: doc.documentNumber,
          expiryDate: doc.expiryDate,
          birthDate: doc.birthDate,
          gender: doc.gender,
          countryCode: doc.issuingCountryCode,
          type: doc.gender.includes('_') ? 'INFANT' : entry.type,
        };
        return extractedDoc;
      });
    } else {
      return [];
    }
  });
  const customSort = (a, b) => {
    const order = { ADULT: 1, CHILD: 2, INFANT: 3 };
    return order[a.type] - order[b.type];
  };
  // Sort the data array using the custom sorting function
  const sortedData = outputJSON?.sort(customSort);

  const table = {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRightWidth: 0,
    borderBottomWidth: 0,
  };
  const tableRow = {
    margin: 'auto',
    flexDirection: 'row',
  };

  const tableCol = {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: '2px',
  };
  //Passenger Details
  const tableCosurname = {
    width: '35%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: '2px',
  };
  const tableColGender = {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: '2px',
  };
  const tableColType = {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: '2px',
  };
  const tableColNumber = {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: '2px',
  };

  const tableColfromto = {
    width: '22%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: '2px',
  };
  const tableColtime = {
    width: '11%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: '2px',
  };

  const tableColflight = {
    width: '17%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: '2px',
  };
  const tableCol2 = {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  };

  const tableCell = {
    // margin: "auto",
    margin: '3px',
    fontSize: '8px',
  };
  const tableCellInfo = {
    margin: '1px 3px',
    fontSize: '8px',
  };

  const adress = {
    fontSize: '10px',
    color: '#8b8b8b',
  };

  const users = secureLocalStorage.getItem('user-info');
  const companyLogo = users?.logo;

  return (
    <Document>
      <Page size="A4" style={{ padding: '27px 20px' }}>
        <View style={{ padding: '15px' }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: `${margin || 0}`,
            }}
          >
            <View
              style={{
                fontSize: '10px',
                display: 'flex',
                width: '200px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {companyLogo === '' || companyLogo === 'undefined' ? (
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 400,
                      paddingBottom: '5px',
                    }}
                  >
                    {users?.user?.company}
                  </Text>
                ) : (
                  <Image style={{ height: '50px' }} src={companyLogo} />
                )}
              </View>

              <Text style={adress}>{users?.address}</Text>
              <Text style={adress}>Email: {users?.email}</Text>
              <Text style={adress}>Phone: {users?.phone}</Text>
            </View>

            <View
              style={{
                color: '#D3D3D3',
                fontSize: '35px',
                fontWeight: 800,
                fontStyle: 'Poppins',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Text
                style={{ color: '#003566', opacity: '0.4', fontWeight: 'bold' }}
              >
                {copy}
              </Text>
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: '11px',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: '10px',
            }}
          >
            <Text>Reference: {bookingId || ''}</Text>

            {copy === 'e - Ticket' ? (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '11px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text>
                  Ticketed:{' '}
                  {allData?.sabredata?.timestamp
                    ? format(
                        new Date(allData?.sabredata?.timestamp),
                        'dd MMM yyyy'
                      )
                    : ''}
                </Text>
                <Text style={{ marginLeft: '15px' }}>
                  Airlines PNR:{' '}
                  {flightData === undefined
                    ? ''
                    : flightData[0]?.confirmationId}
                </Text>
              </View>
            ) : (
              <Text>
                Booking: {allData?.sabredata?.creationDetails?.creationDate}
                {allData?.sabredata?.creationDetails?.creationTime}
              </Text>
            )}
          </View>

          <View>
            <Text
              style={{
                fontSize: '12px',
                color: '#03437B',
                padding: '20px 0 10px 0',
              }}
            >
              PASSENGER DETAILS
            </Text>
            <View style={table}>
              <View style={{ margin: 'auto', flexDirection: 'row' }}>
                <View style={tableCosurname}>
                  <Text style={tableCell}>Passenger Name </Text>
                </View>
                <View style={tableColGender}>
                  <Text style={tableCell}>Gender</Text>
                </View>
                <View style={tableColType}>
                  <Text style={tableCell}>Passenger Type</Text>
                </View>
                <View style={tableColNumber}>
                  <Text style={tableCell}>
                    {copy === 'e - Ticket'
                      ? 'Ticket Number'
                      : 'Passport Number'}
                  </Text>
                </View>
              </View>
              {/* passenger Data */}
              <View>
                {sortedData?.map((data, index) => (
                  <View style={tableRow} key={index}>
                    <View style={tableCosurname}>
                      <Text style={tableCell}>
                        <Text>
                          {data?.givenName} {data?.surname}
                        </Text>
                      </Text>
                    </View>
                    <View style={tableColGender}>
                      <Text style={tableCell}>
                        {data?.gender?.split('_')[1] || data?.gender}
                      </Text>
                    </View>
                    <View style={tableColType}>
                      <Text style={tableCell}> {data?.type}</Text>
                    </View>
                    <View style={tableColNumber}>
                      <Text style={tableCell}>
                        {allData?.sabredata?.flightTickets === undefined
                          ? data.documentNumber
                          : allData?.sabredata?.flightTickets[index]?.number}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View>
            <Text
              style={{
                fontSize: '12px',
                color: '#03437B',
                padding: '20px 0 10px 0',
              }}
            >
              FLIGHT ITINERARIES
            </Text>
            <View style={table}>
              <View style={{ margin: 'auto', flexDirection: 'row' }}>
                <View style={tableColflight}>
                  <Text style={tableCell}>Flight </Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Departure From</Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Arrival To</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Depart At</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Arrive At</Text>
                </View>
                <View style={tableColflight}>
                  <Text style={tableCell}>Info</Text>
                </View>
              </View>

              {flightData?.map((data, index) => (
                <View style={tableRow} key={index}>
                  <View style={tableColflight}>
                    <Text style={tableCell}>
                      <AirlineNames code={data.airlineCode} />{' '}
                      {data?.airlineCode}-{data?.flightNumber}
                      <br />
                      {data?.aircraftTypeName}
                    </Text>
                  </View>
                  <View style={tableColfromto}>
                    <Text style={tableCell}>
                      ({data.fromAirportCode}){' '}
                      <Address code={data.fromAirportCode} />
                      <br />
                      {data?.departureTerminasurname || null}{' '}
                      {data?.departureGate
                        ? `Gate: ${data?.departureGate || null}`
                        : null}
                    </Text>
                  </View>
                  <View style={tableColfromto}>
                    <Text style={tableCell}>
                      ({data.toAirportCode}){' '}
                      <Address code={data.toAirportCode} />
                      <br />
                      {data?.arrivalTerminasurname || null}{' '}
                      {data?.arrivalGate
                        ? `Gate: ${data?.arrivalGate || null}`
                        : null}
                    </Text>
                  </View>
                  <View style={tableColtime}>
                    <Text style={tableCell}>
                      {data.departureDate} {data.departureTime?.slice(0, 5)}
                    </Text>
                  </View>
                  <View style={tableColtime}>
                    <Text style={tableCell}>
                      {data.arrivalDate} {data.arrivalTime?.slice(0, 5)}
                    </Text>
                  </View>
                  <View style={tableColflight}>
                    <Text style={tableCellInfo}>
                      {data?.cabinTypeName}-{data?.bookingClass}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          {check === '1' ? (
            <View>
              <View>
                <Text
                  style={{
                    fontSize: '12px',
                    color: '#03437B',
                    padding: '20px 0 10px 0',
                  }}
                >
                  PRICE BREAKDOWN
                </Text>
                <View style={table}>
                  <View style={{ margin: 'auto', flexDirection: 'row' }}>
                    <View style={tableCol}>
                      <Text style={tableCell}>Passenger</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Base Fare</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Tax</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Sub Total</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Baggage</Text>
                    </View>
                  </View>
                  {fareTotal?.map((data, index) => (
                    <View style={tableRow}>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {data?.pricedTravelerType === 'ADT'
                            ? 'Adult'
                            : data?.pricedTravelerType === 'INF'
                            ? 'Infant'
                            : 'Child'}
                          {' X '}
                          {data?.travelerIndices?.length || 1}
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {parseInt(data?.totals?.subtotal) *
                            parseInt(data?.travelerIndices?.length || 1)}{' '}
                          AED
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {parseInt(data?.totals?.taxes) *
                            parseInt(data?.travelerIndices?.length || 1)}{' '}
                          AED
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {parseInt(data?.totals?.total) *
                            parseInt(data?.travelerIndices?.length || 1)}{' '}
                          AED
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {data?.fareConstruction[0]?.checkedBaggageAllowance
                            ?.totalWeightInKilograms
                            ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.totalWeightInKilograms} Kg`
                            : null}
                          {data?.fareConstruction[0]?.checkedBaggageAllowance
                            ?.maximumPieces
                            ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.maximumPieces} Peices`
                            : null}
                        </Text>
                      </View>
                    </View>
                  ))}

                  <View style={tableRow}>
                    <View style={tableCol2}>
                      <Text
                        style={{
                          fontSize: '10px',
                          fontWeight: 'bold',
                          margin: '3px',
                        }}
                      >
                        Grand Total:{' '}
                        {commaNumber(
                          allData?.sabredata?.payments?.flightTotals[0]?.total
                        )}{' '}
                        AED
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '70%',
                  fontSize: '10px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '20px 0px',
                  border: '1px solid gray',
                  padding: '10px',
                }}
              >
                <View>
                  <Text style={{ marginBottom: '5px' }}>
                    Base fare total amount
                  </Text>
                  <Text style={{ marginBottom: '5px' }}>Tax</Text>
                  {copy === 'Agent Invoice' ? (
                    <View>
                      <Text style={{ marginBottom: '5px' }}>Discount</Text>
                      <Text>
                        ---------------------------------------------------------------------------------
                      </Text>
                      <Text>Agent Total Ticket Fare Amount</Text>
                    </View>
                  ) : (
                    <View>
                      <Text>
                        ---------------------------------------------------------------------------------
                      </Text>
                      <Text>Customer Total Ticket Fare Amount</Text>
                    </View>
                  )}
                </View>
                <View>
                  <Text style={{ marginBottom: '5px' }}>
                    {commaNumber(
                      allData?.sabredata?.payments?.flightTotals[0]?.subtotal
                    )}{' '}
                    AED
                  </Text>
                  <Text style={{ marginBottom: '5px' }}>
                    {commaNumber(
                      allData?.sabredata?.payments?.flightTotals[0]?.taxes
                    )}{' '}
                    AED
                  </Text>
                  <Text style={{ marginBottom: '5px' }}>
                    {commaNumber(
                      allData?.sabredata?.payments?.flightTotals[0]?.total
                    )}{' '}
                    AED
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            ''
          )}
        </View>
      </Page>
    </Document>
  );
};

export default PDFPageDesign;
